import { Step, StepLabel, Stepper } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import StepConnector from '@material-ui/core/StepConnector';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import axios from 'axios';
import { withSnackbar } from 'notistack';
// import Avatar from "@material-ui/core/Avatar";
import React, { Component } from 'react';
import { connect } from 'react-redux';
// import avatar from "../../../../images/profile_icon.png";
import ProfileCard from '../cards/profileCard';
import AlertDialog from '../dialogs/modals/alertDialog';
import Agreement from './agreement';
import Confirm from './confirm';
import customLibCandStepIcon from './customLibCandStepIcons';
import CustomLibStepIcon from './customLibStepIcon';
import FormAreaExpertise from './formAreaExpertise';
import FormExperience from './formExperience';
import FormProfessionalDetails from './formProfessionalDetails';
import FormUserDetails from './formUserDetails';
import Payment from './payment';
import { checkContactFields, checkProfessionalFields } from './profileCompletionProcess';
import SkillSet from './skillSet';
import { useEffect } from 'react';

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    '& $line': {
      backgroundColor: '#A5D6A7',
    },
  },
  completed: {
    '& $line': {
      backgroundColor: '#769a78',
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
  },
})(StepConnector);

const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
  },
  stepper: {
    justifyContent: 'space-around',
    marginTop: '10px',
  },
  stepButtons: {},
  button: {
    padding: theme.spacing(2),
    align: 'center',
  },
  deleteSkillButton: {
    padding: '10px',
    marginLeft: '50px',
    backgroundColor: '#75A478',
    color: '#FFFFFF',
  },
});

const defaultDob = new Date();

class entryWizard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contact_info: {
        name: '',
        email: '',
        secondaryemail: '',
        dob: defaultDob,
        phone: '',
        secondaryPhone: '',
        address: {
          address_line1: '',
          address_line2: '',
          city: '',
          state: '',
          postalCode: '',
          country: '',
        },
      },
      job_experience: [
        {
          yrs: '',
          job_title: '',
          description: '',
          company: '',
        },
      ],
      professional_details: {
        currentTitle: '',
        highestDegree: '',
        college: '',
        bio: '',
        yearsOfExperience: '',
        englishProficiency: '',
        jobDuties:'',
        major: '',
      },
      payment_info: {
        payPal_id: '',
        payPal_firstName: '',
        payPal_lastName: '',
        interviewRate: '',
      },
      skills: {
        skillSet: [['', '']],
        skills: [],
      },
      candidate_info: {
        preferredPosition: '',
      },
      role: '',
      expertise: [],
      activeStep: 0,
      agreement: false,
      profileCompletionPercentage: 0,
    };
  }

  // This can become an redux state object, by creating a dispatch event at the end of the registration action.
  componentDidMount() {
    const { history } = this.props;
    const { contact_info } = this.state;
    if (history.location.state) {
      const passedState = history.location.state;
      contact_info.name = passedState.name;
      contact_info.email = passedState.email;
      this.setState({
        contact_info,
        role: passedState.role,
      });
    }
  }

  // Proceed to next step
  nextStep = () => {
    const { activeStep } = this.state;
    
    if(this.state.activeStep == 4 && this.state.role==="screener" && this.state.payment_info.payPal_id.length!==17){
      
        let result = this.state.payment_info.payPal_id.length===0?`PayPal Id cannot be empty`:`PayPal Id Cannot be less than 17 characters`; 
        let payPalMessage = <span>{result}</span>
        this.props.enqueueSnackbar(payPalMessage, {
            variant: "error",
            autoHideDuration: 2500,
        });
    
    }
    else{
      this.handleCompletion(activeStep);
 
      this.setState({
        activeStep: activeStep + 1,
      });
    }
  };

  // Go back to prev step
  prevStep = () => {
    const { activeStep } = this.state;
    this.setState({
      activeStep: activeStep - 1,
    });
  };

  // uncomment out when you want to add clickability to the wizard.
  handleChangeStep = (idx) => (event) => {
    event.preventDefault();
    this.setState({
      activeStep: idx,
    });
  };

  onSubmit = (e) => {
    //e.preventDefault();
    this.updateStartedNotification();
    axios
      .put('/api/users/update', this.state)
      .then((res) => {
        this.updateFinishedNotification(res);
        this.props.history.push('/');
      })
      .catch((err) => {
        // console.log(err);
        this.updateErrorNotification(err);
        this.props.history.push('/register-wizard');
      });
  };

  handleContactInfoChange = (input) => (event) => {
    const { contact_info } = this.state;

    switch (input) {
      case 'address.address_line1':
      case 'address.address_line2':
      case 'address.city':
      case 'address.state':
      case 'address.country':
      case 'address.postalCode':
        const address = contact_info.address;
        const stateInput = input.slice(8);
        address[stateInput] = event.target.value;
        contact_info.address = address;
        break;
      case 'phone':
      case 'dob':
      case 'secondaryPhone':
        contact_info[input] = event;
        break;
      default:
        contact_info[input] = event.target.value;
        break;
    }
    // console.log(contact_info);
    this.setState({ contact_info });
  };

  handleProfessionalDetailChange = (input) => (event) => {
    const { professional_details } = this.state;

    professional_details[input] = event.target.value;
    this.setState({ professional_details });
  };

  handlePaymentInfoChange = (input) => (event) => {
    const { payment_info } = this.state;

    payment_info[input] = event.target.value;
    this.setState({ payment_info });

  };


  // Handle fields change
  handleChange = (input) => (event) => {
    event.preventDefault();
    const { candidate_info } = this.state;
    if (input === 'preferredPosition') {
      // console.log('we are here tho');
      candidate_info[input] = event.target.value;
      this.setState({ candidate_info });
    }
    this.setState({ [input]: event.target.value });
  };

  handleExpChange = () => (event) => {
    const { expertise } = this.state;
    // if (expertise.length === 5) {
    //   this.expertiseWarningNotification();
      
    // }
    // console.log(event.target.value)
    if (expertise.includes(event.target.value)) {
      const newExpertise = expertise.filter((word) => word !== event.target.value);
      this.setState({
        expertise: newExpertise,
      });
    } else {
      expertise[expertise.length] = event.target.value;
      this.setState({
        expertise,
      });
    }
  };
  handleJobExperienceChange = (idx, input) => (event) => {
    event.preventDefault();
    const { job_experience } = this.state;
    job_experience[idx][input] = event.target.value;
    // console.log(job_experience);
    this.setState({
      job_experience,
    });
  };
  handleAgreementChange = () => {
    this.setState({
      agreement: true,
    });
  };

  expertiseWarningNotification = () => {
    const message = 'You can only choose upto 5';

    this.props.enqueueSnackbar(message, {
      variant: 'warning',
      autoHideDuration: 3000,
    });
  };

  updateStartedNotification = () => {
    const message = 'We are updating your profile';

    this.props.enqueueSnackbar(message, {
      variant: 'info',
      autoHideDuration: 3000,
    });
  };

  updateFinishedNotification = (res) => {

    // if(res.data.role==="recruiter"){
    //   this.props.history.push('/recruiter-dashboard');
    // }

    
    const message = <span>you have succesfully updated {res.data.name}, You will now be redirected the homepage!</span>;

    this.props.enqueueSnackbar(message, {
      variant: 'success',
      autoHideDuration: 3000,
    });
    
  };

  updateErrorNotification = (err) => {
    const message = <span>There was an error</span>;

    this.props.enqueueSnackbar(message, {
      variant: 'error',
      autoHideDuration: 3000,
    });
  };

  exitUser = () => {
    this.props.history.push('/');
  };

  handleCompletion = (checkStep) => {
    let percentageComplete = 0;
    let fields;
    const userRole = this.state.role === 'screener';
    switch (checkStep) {
      case 0:
        // percentageComplete = this.checkContactFields(this.state.contact_info, userRole);

        percentageComplete = checkContactFields(this.state.contact_info, userRole);
        break;
      case 1:
        percentageComplete = checkProfessionalFields(this.state.professional_details, checkStep, userRole);
        break;
      case 2:
        fields = userRole ? this.state.expertise : this.state.job_experience;
        percentageComplete = this.checkFields(fields, checkStep, userRole);
        break;
      case 3:
        percentageComplete = this.checkFields(this.state.skills, checkStep, userRole);
        break;
      case 4:
        fields = userRole ? this.state.payment_info : this.state.agreement;
        percentageComplete = this.checkFields(fields, checkStep, userRole);
        break;
      case 5:
        percentageComplete = this.checkFields(this.state.agreement, checkStep, userRole);
        break;
      default:
        break;
    }

    console.log(percentageComplete);
    let newCompletionPercentage = this.state.profileCompletionPercentage + percentageComplete;

    this.setState({
      profileCompletionPercentage: newCompletionPercentage,
    });
  };

  checkFields = (fields, step, userRole) => {
    // each step will have a full percentage number based on fields, the amount of filled-in fields will be collected and the final percentage will be give.
    // check contact_info example
    const maxPoints = userRole ? 20 : 25;
    // const indivPoints = userRole ? 4 : 5;
    // const fieldsEmpty = Object.values(fields).filter(field => {
    //     return (!!field.trim() || 0 === field.trim().length)
    // }).length;

    return maxPoints;
  };

  getSteps = () => {
    const { role } = this.state;

    const screenerSteps = ['Contact', 'Professional', 'Expertise', 'Skills', 'Payment', 'Terms', 'Confirm'];

    const candidateSteps = ['Contact', 'Professional', 'Experience', 'Skills', 'Terms', 'Confirm'];

    return role === 'screener' ? screenerSteps : candidateSteps;
  };

  getStepContent = (stepIndex) => {
    const {
      contact_info,
      job_experience,
      professional_details,
      expertise,
      skills,
      agreement,
      payment_info,
      candidate_info,
      role,
    } = this.state;

    const values = {
      contact_info,
      job_experience,
      professional_details,
      expertise,
      skills,
      agreement,
      payment_info,
      candidate_info,
      role,
    };

    switch (stepIndex) {
      case 0:
        return (
          <FormUserDetails
            nextStep={this.nextStep}
            handleChange={this.handleContactInfoChange}
            handleDateChange={this.handleDateChange}
            handlePhoneChange={this.handlePhoneChange}
            values={values.contact_info}
          />
        );
      case 1:
        return (
          <FormProfessionalDetails
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            handleChange={this.handleProfessionalDetailChange}
            handleCandidateChange={this.handleChange}
            values={values.professional_details}
            candidate={role === 'candidate' ? values.candidate_info : null}
          />
        );
      case 2:
        return role === 'screener' ? (
          <FormAreaExpertise
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            handleChange={this.handleExpChange}
            expertise={values.expertise}
          />
        ) : (
          <FormExperience
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            handleChange={this.handleJobExperienceChange}
            experience={values.job_experience}
          />
        );
      case 3:
        return <SkillSet nextStep={this.nextStep} prevStep={this.prevStep} handleChange={this.handleChange} values={values.skills} />;
      case 4:
        return role === 'screener' ? (
          <Payment
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            handleChange={this.handlePaymentInfoChange}
            values={values.payment_info}
          />
        ) : (
          <Agreement
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            handleChange={this.handleAgreementChange}
            agreement={values.agreement}
          />
        );
      case 5:
        return role === 'screener' ? (
          <Agreement
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            handleChange={this.handleAgreementChange}
            agreement={values.agreement}
          />
        ) : (
          <Confirm nextStep={this.nextStep} prevStep={this.prevStep} onSubmit={this.onSubmit} values={values} />
        );
      case 6:
        return <Confirm nextStep={this.nextStep} prevStep={this.prevStep} onSubmit={this.onSubmit} values={values} />;
      default:
        return 'Unknown stepIndex';
    }
  };

  render() {
    const { classes } = this.props;
    const steps = this.getSteps();
    const { activeStep, agreement, role } = this.state;
  //  console.log(this.state);



   const checkExpertise = () => {
      console.log( this.state.activeStep)
      if (this.state.expertise.length>=6 && this.state.activeStep-1==1) {
      
        this.props.enqueueSnackbar('You can only choose upto 5', {
          variant: 'warning',
          autoHideDuration: 3000,
        });
      } else {
        if (activeStep === steps.length - 1) {
          this.onSubmit();
        } else {
          this.nextStep();
        }
      }
    };

    const checkFieldsContact = () => {
      console.log(this.state.contact_info.phone.length);
      console.log(this.state.contact_info.phone)

      if (this.state.contact_info.phone === '' || this.state.contact_info.name === '' || this.state.contact_info.phone.length <= 13) {
        this.props.enqueueSnackbar('Please fill in the required fields', {
          variant: 'warning',
          autoHideDuration: 3000,
        });
      } else {
        if (activeStep === steps.length - 1) {
          this.onSubmit();
        } else {
          this.nextStep();
        }
      }
     
    };
   

    return (
      <>
      <br/><br/>
      <div className="wizard-component">
        <ProfileCard className={classes.paper}>
          <Stepper className={classes.stepper} activeStep={activeStep} alternativeLabel connector={<ColorlibConnector />}>
            {steps.map((label, idx) => (
              <Step key={label + ` ` + idx}>
                <StepLabel StepIconComponent={role === 'screener' ? CustomLibStepIcon : customLibCandStepIcon}>
                  <Button className={classes.stepButtons} onClick={this.handleChangeStep(idx)}>
                    {label}
                  </Button>
                </StepLabel>
              </Step>
            ))}
          </Stepper>
          <div>
            {this.state.activeStep === steps.length ? (
              <div>
                <Typography className={classes.instructions}>Wizard Completed</Typography>
              </div>
            ) : (
              <>
                {this.getStepContent(activeStep)}
                <div className="d-flex flex-row justify-content-around mart-50 marl-250 mb-3">
                  <div className="flex-fill">
                    <AlertDialog handleExit={this.exitUser} />
                  </div>
                  <div className="flex-fill">
                    <Button disabled={activeStep === 0} onClick={this.prevStep} variant="outlined" color="secondary">
                      Back
                    </Button>
                  </div>
                  <div className="flex-fill">
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={activeStep==0?checkFieldsContact:checkExpertise}
                      //   onClick={activeStep === steps.length - 1 ? this.onSubmit : this.nextStep}
                      disabled={activeStep === steps.length - 2 && agreement === false}
                    >
                      {activeStep === steps.length - 1 ? 'Confirm' : 'Next'}
                    </Button>
                  </div>
                </div>
              </>
            )}
          </div>
        </ProfileCard>
      </div>
      </>
    );
  }
}

export default withSnackbar(withStyles(styles)(connect(null)(entryWizard)));
