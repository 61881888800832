import Button from "@material-ui/core/Button";
import { useSnackbar } from 'notistack';
import React from "react";

export default function ConfirmationButtons(props) {
    const { classes, salesforceIdx } = props;
    const { enqueueSnackbar } = useSnackbar();

    const handleConfirmClick = (idx) => {
        // console.log(`the interview id is ${idx}`);
        // axios
        //     .post("/salesforce/interview/statusUpdate/" + idx, { status: "Scheduled-Confirmed" })
        //     .then((response) => {
        //         console.log(response);
        //
        //     })
        //     .catch((err) => {
        //         let message = 'Something happened. please try again!';
        //         enqueueSnackbar(message, {
        //             variant: "error",
        //             autoHideDuration: 3000,
        //         });
        //     });

        // console.log('In confirm status update start');
        window.location.reload(false);
        props.statusComplete(idx, enqueueSnackbar );
    };

    return (  
        <>
            <Button variant="outlined" className={classes.confirm} onClick={() => handleConfirmClick(salesforceIdx)}>
                Complete Interview
            </Button>
            
        </>
    );
}
