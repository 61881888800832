import React, { useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import axios from "axios";
import Button from '@material-ui/core/Button';
import { withSnackbar, useSnackbar } from "notistack";
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import { useConfirm } from "material-ui-confirm";
import { Container, Grid, Paper, Box } from "@material-ui/core";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import LaunchIcon from "@mui/icons-material/Launch";


const ScreenerReview = (props) => {
    const { auth:{user} } = props;
    const [reviewList, setreviewList] = React.useState([]);
    const [screenerList, setScreenerList] = React.useState([]);
    const [recruiterList, setRecruiterList] = React.useState([]);
    const { enqueueSnackbar } = useSnackbar();
    const confirm = useConfirm();

    // const candidateid= user._id;
    // console.log(candidateid);
    //console.log(user.name)
    console.log(user);

    // const deleteReviewHandler = (id) => {
    //     // dispatch(deleteOrder(id));
    //     // var result = window.confirm("Are you sure to delete?");
    //     confirm({ description: `You want to Delete the review ` })
    //         .then(() => {
    //             axios.delete(`/api/review/reviewdelete/${id}`)
    //                 .then((res) => {
    //                     console.log(res);

    //                     //alert('Interview Deleted successfully');
    //                     let regMessage = <span>Review Deleted successfully</span>;
    //                     enqueueSnackbar(regMessage, {
    //                         variant: "info",
    //                         autoHideDuration:150000,
    //                     });
    //                     window.location.reload(false);
    //                     //window.location.reload(false);
    //                 })
    //                 .catch(err => {
    //                     console.log(err.response);
    //                     //alert('An error occured try submitting the form again');
    //                     let regMessage = <span> An error occured try submitting the again</span>;
    //                     enqueueSnackbar(regMessage, {
    //                         variant: "error",
    //                         // autoHideDuration: 3000,
    //                     });
    //                 });

    //         }).catch(() => console.log("Deleted"));

    // };

    useEffect(() => {

        axios.get("/api/review/myreviews").then((response) => {
            setreviewList(response.data);
        });
        axios.get("/api/users/screener").then((response) => {
            setScreenerList(response.data);
        });
        axios.get("/api/users/recruiter").then((response) => {
            setRecruiterList(response.data);
        });


    }, []);

    const columns = [
        {
            field: "id",
            width: -1,
            flex: -1,
            hide: true
        },
        {
            field: "reviewId",
            headerName: "Review ID",
            minWidth: 150,
            flex: 1,
      
          },
        {
            field: "recruiter",
            headerName: "Recruiter Name",
            minWidth: 250,
            flex: 0.5,
        },

        {
            field: "email",
            headerName: "Recruiter Email",
            minWidth: 250,
            flex: 0.5,

        },
        {
            field: "screener",
            headerName: "Screener Name",
            minWidth: 350,
            flex: 0.5,

        },
        {
            field: "numberRating",
            headerName: "Rating",
            minWidth: 100,
            flex: 0.5,

        },
        {
            field: "comments",
            headerName: "Comment",
            minWidth: 250,
            flex: 0.5,
        },

        // {
        //     field: "actions",
        //     flex: 0.3,
        //     headerName: "Actions",
        //     minWidth: 150,
        //     // type: "number",
        //     sortable: false,
        //     renderCell: (params) => {
        //         return (
        //             <>
        //                 {/* <Link to={`/${params.id}`}> */}
        //                 <Link to={`/review/reviewdetails/${params.getValue(params.id, "id")}`} title="Review Details" style={{ color: "#75a478" }}>
        //                     <LaunchIcon />
        //                 </Link>&nbsp;&nbsp;&nbsp;
        //                 <Link to={`/updatereview/${params.getValue(params.id, "id")}`}>
        //                     <EditIcon />
        //                 </Link>&nbsp;&nbsp;

        //                 {/* <Button onClick={() => onDelete(params.id, params.getValue(params.id, "status"))}>{ params.getValue(params.id, "status") == "Active"? "Deactivate" : "Activate" }</Button> */}
        //                 <Button
        //                     onClick={() =>
        //                         deleteReviewHandler(params.getValue(params.id, "id"))
        //                     }
        //                 >
        //                     <DeleteIcon />
        //                 </Button>

        //             </>
        //         );
        //     },

        // },

    ]

    const rows = [];

    reviewList && reviewList.forEach(item => {
        // if (item.recruiter === user._id) {
            recruiterList && recruiterList.forEach(recruiter => {
                if (item.recruiter === recruiter._id ) {
            screenerList && screenerList.forEach(User => {
                if (item.screener === User._id) {

                    rows.push({
                        id: item._id,
                        reviewId: item.reviewId,
                        recruiter: recruiter.name,
                        email: recruiter.email,
                        screener: User.name + ' - ' + User.uniqueMatchId,
                        numberRating: item.numberRating,
                        comments: item.comments,
                    })
                }
            })
        }
    })

    })

    return (
        <>
            <br /> <br /> <br /> <br /> <br /> <br />

            <br />
            {/* <Container>

                <Paper component={Box} width={1} height={700}> */}
                    
                    <div className="myList">

                        <DataGrid
                            rows={rows}
                            columns={columns}
                            pageSize={10}
                            disableSelectionOnClick
                            className="tableList"
                            autoHeight
                        />
                    </div>
                {/* </Paper>
            </Container> */}
            <br />
        </>
    )
}

ScreenerReview.propTypes = {
    auth: PropTypes.object.isRequired,
};

const msp = (state) => ({
    auth: state.auth,
});


export default (connect(msp)(ScreenerReview));