import React, { Fragment, useState, useEffect, Component } from "react";
import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from "react-redux";
import { updateRecruiter, getRecruiterDetails, UPDATE_PROFILE_RESET } from "../../actions/userActions";
import axios from "axios";
import { withSnackbar, useSnackbar } from "notistack";
import dateFormat from 'dateformat';

import DateFnsUtils from "@date-io/date-fns";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from "@material-ui/core/TextField";

import { DatePicker, MuiPickersUtilsProvider, TimePicker } from "material-ui-pickers";
import PropTypes, { string } from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { makeStyles, Divider, TextareaAutosize } from "@material-ui/core";
import { screeners, candidates, interviews } from "../../actions/userActions"
import { Link } from "react-router-dom";

const useStyles = makeStyles(theme => ({
    main: {
        width: "auto",
        display: "block", // Fix IE 11 issue.
        marginTop: theme.spacing(-2),
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        [theme.breakpoints.up(400 + theme.spacing(6))]: {
            width: "50%",
            marginLeft: "auto",
            marginRight: "auto",
        },
    },
    paper: {
        marginTop: theme.spacing(-5),
        display: "flex",
        width: "100%",
        flexDirection: "column",
        alignItems: "center",
        padding: `${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(-15)}px`,
    },
    button: {
        padding: theme.spacing(2),
        margin: 10,
        alignItems: "center",
    },

    textField: {
        marginLeft: theme.spacing(),
        marginRight: theme.spacing(),
        width: "100px",
    },

    textFieldBox: {
        marginTop: theme.spacing() * 1,
        marginLeft: theme.spacing(),
        marginRight: theme.spacing(),
        width: "500px",
    },
    select: {
        marginTop: theme.spacing() * 1,
        marginLeft: theme.spacing(),
        marginRight: theme.spacing(),
        width: "250px",
    },
    formControl: {
        margin: theme.spacing(),
        minWidth: 120,
        maxWidth: 300,
    },
}));



const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 350,
        },
    },
};



const ReviewDetails = ({ history, match, props }) => {


    const [reviewList, setreviewList] = React.useState([]);
    const [screenerList, setScreenerList] = React.useState([]);
    const [recruiterList, setRecruiterList] = React.useState([]);
    const { enqueueSnackbar } = useSnackbar();
    const reviewId = match.params.id;

    // const { auth } = props;
    // console.log(auth)

    useEffect(() => {
        axios.get("/api/review/myreviews").then((response) => {
            setreviewList(response.data);
        });
        axios.get("/api/users/screener").then((response) => {
            setScreenerList(response.data);
        });
        axios.get("/api/users/recruiter").then((response) => {
            setRecruiterList(response.data);
        });

    }, []);



    var id, rname, sname, email, numberRating, comments, sid;



    reviewList && reviewList.forEach(item => {
        if (item._id === reviewId) {

            screenerList && screenerList.forEach(User => {
                if (item.screener === User._id) {
                    id = item.reviewId;
                    // rname = recruiter.name;
                    // email = recruiter.email
                    sname = User.name;
                    sid = User.uniqueMatchId;
                    numberRating = item.numberRating;
                    comments = item.comments;

                }
            })
        }
    })




    const classes = useStyles();
    return (
        <>
            <br /><br /><br /> <br /><br /><br /><br /><br />
            <div className={classes.main}>
                <Paper className={classes.paper}>
                    <br />
                    <Typography variant="h6">Review Information<Divider /></Typography>
                    <Link to="/screener-review-list">Back</Link>
                    <form className={classes.form} >
                        <Grid container spacing={24} style={{ padding: "2em" }}>


                            <Grid item xs={12} sm={5}>
                                <div>
                                    <label>Review Id</label>
                                    <TextField
                                        disabled
                                        id="standard-disabled"
                                        value={id}
                                        variant="standard"
                                        fullWidth
                                    />

                                </div>

                            </Grid>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            {/* <Grid item xs={12} sm={5}>
                                <div>
                                    <label>Recruiter Name</label>
                                    <TextField
                                        disabled
                                        id="standard-disabled"
                                        value={rname}
                                        variant="standard"
                                        fullWidth
                                    />

                                </div>

                            </Grid>
                            <Grid item xs={12} sm={5}>
                                <div>
                                    <label>Recruiter Email</label>
                                    <TextField
                                        disabled
                                        id="standard-disabled"
                                        value={email}
                                        variant="standard"
                                        fullWidth
                                    />
                                </div>


                            </Grid>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
                            <Grid item xs={12} sm={5}>
                                <div>
                                    <label>Screener Name</label>
                                    <TextField
                                        disabled
                                        id="standard-disabled"
                                        value={sname}
                                        variant="standard"
                                        fullWidth
                                    />
                                </div>
                            </Grid>

                            <Grid item xs={12} sm={5}>
                                <div>
                                    <label>Screener Id</label>
                                    <TextField
                                        disabled
                                        id="standard-disabled"
                                        value={sid}
                                        variant="standard"
                                        fullWidth
                                    />
                                </div>
                            </Grid>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <Grid item xs={12} sm={5}>
                                <div>
                                    <label>Comment</label>
                                    <div>
                                        <TextareaAutosize
                                            disabled
                                            maxRows={5}
                                            value={comments}
                                            variant="standard"
                                        // style={{ width: "auto" }}
                                        />
                                    </div>
                                </div>


                            </Grid>

                            <Grid item xs={12} sm={5}>
                                <div>
                                    <label>Rating</label>
                                    <TextField
                                        disabled
                                        id="standard-disabled"
                                        value={numberRating}
                                        variant="standard"
                                        fullWidth
                                    />
                                </div>

                            </Grid>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <br />

                        </Grid>
                    </form>

                </Paper>
            </div>

        </>
    )
}


ReviewDetails.propTypes = {
    logoutUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
    auth: state.auth,
});
export default (connect(mapStateToProps, { logoutUser })(ReviewDetails));