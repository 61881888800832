import { Button,Divider,MenuItem } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import React, { Component } from "react";
import { connect } from "react-redux";
import NavigationTab from "./NavigationTab";
import Menu from "@material-ui/core/Menu";
import PropTypes from "prop-types";
import '../layout/Navbar.css'


class HomeNavigation extends Component {
    constructor(props) {
        super(props);
        // this.state = {
        //     left: false,
        //     anchorEl: null,
            
        // };
        this.handleClick = this.handleClick.bind(this);
    }

    // This handleClick should be used when notifications are put back in!!!
    // handleClick(e) {
    //     e.preventDefault();
    //     const nonBadgedTabs = ['Dashboard', 'Profile', 'Calendar'];
    //     const currentTab = e.currentTarget.innerText;
    //     if (nonBadgedTabs.includes(currentTab)) {
    //         this.props.updateHome(e.currentTarget.innerText);
    //     } else {
    //         this.props.updateHome(e.currentTarget.innerText.replace(/\d+/g, '').slice(0, -1));
    //     }
    // }


    // handleMenu = (event) => {
    //     this.setState({
    //         anchorEl: event.currentTarget,
    //     });
    // };

    // handleHomeClick = () => {
    //     let route = "/home";
    //     return route;
    // };

    // handleClose = () => {
    //     this.setState({
    //         anchorEl: null,
    //     });
    // };

    // onLogoutClick = (e) => {
    //     this.props.logoutUser();
    // };



    handleClick(e) {
        e.preventDefault();
        this.props.updateHome(e.currentTarget.innerText);
    }

    render() {
        const { role } = this.props.auth.user;
         //const navigationTabNames = role === 'screener' ? ["Dashboard", "Interviews", "Reviews", "Calendar", "Profile"] : ["Home", "Jobs", "Applications", "Profile"];
       let navigationTabNames = [" "];
        if(role === 'screener'){
             navigationTabNames = ["Dashboard","Interviews", "Reviews", "Calendar", "Profile"];
        }else if (role === 'recruiter') {
            navigationTabNames =["Home","Candidates","Screeners", "Interview","Feedback","Review","Profile"];
        }else if(role === 'admin'){
           navigationTabNames =["Home", "Recruiters","Candidates","Screeners","Screener Reviews"];
        } else{
            //navigationTabNames =["Home", "Jobs", "Applications","ScheduledInterviews","Profile"];
            navigationTabNames =["Home","ScheduledInterviews","Profile"];
        }
        // const { user } = this.props.auth;
        // const openMenu = Boolean(this.state.anchorEl);

        return (
            <>
               
                <Grid container justify="space-evenly"  className="grid1" >
               
                    {navigationTabNames.map((tabName, idx) => (
                       
                        <Grid item key={`tabName-${idx}`} >
                            <NavigationTab
                                isActive={tabName === this.props.homeTab}
                                tabName={tabName}
                                handleClick={() => this.handleClick}
                                
                            />             
                            <Divider light></Divider>
                            <hr className="line"></hr>
                                                 
                        </Grid>
                      
                      
                    ))}
                  
                    
                    {/* <Button>Hello</Button> */}
                   
                    {/* <Button color="inherit" onClick={this.handleMenu}>
                                {user.name ? user.name : " "}
                            </Button>
                            <Menu 
                                id="menu-appbar"
                                anchorEl={this.state.anchorEl}
                                anchorOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                                style={{ marginTop: "40px" }}
                                open={openMenu}
                                onClose={this.handleClose}
                            >
                               
                                <MenuItem component="a" href={this.handleHomeClick()}>
                                    Home
                                </MenuItem>
                                <MenuItem onClick={this.onLogoutClick}>Logout</MenuItem>
                               
                            </Menu>
                           */}
                         
                </Grid>
            </>
        );
    }
}

HomeNavigation.propTypes = {
//    logoutUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    homeTab: PropTypes.string,
   
};

const mapStateToProps = (state) => ({
    
    auth: state.auth,
    homeTab: state.homeTab,
});

export default connect(mapStateToProps)(HomeNavigation);