import React, { useState, useRef } from 'react'

// MUI Imports
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
// Slide
import Slide from "@mui/material/Slide";
import Tooltip from "@mui/material/Tooltip";
import TablePagination from "@mui/material/TablePagination";
import ViewIcon from '@mui/icons-material/RemoveRedEye';
import CloseIcon from "@mui/icons-material/Close";
import axios from 'axios';
import UserSnackbar from '../../snackBars/UserSnackbar';
import AddIcon from "@mui/icons-material/Add";
import DownloadIcon from '@mui/icons-material/Download';

const AddMultipleUsers = ({ setRefreshCount, setAddMultipleUsersClicked, finalDisplay, roleFilter, setFinalDisplay, setFileName }) => {
  // table column headers
  const columns = [
    {
      title: "Name",
      width: "26%",
    },
    {
      title: "Resume",
      width: "16%",
    },
    {
      title: "Title",
      width: "18%",
    },
    {
      title: "Total Experience",
      width: "20%",
    },
    {
      title: "Expected Salary",
      width: "18%",
    },
    {
      title: "Email Id",
      width: "28%",
    },
    {
      title: "Phone Number",
      width: "18%",
    },
    {
      title: "Skills",
      width: "20%",
    },
    {
      title: "Current Location",
      width: "20%",
    },
    {
      title: "Highest Education",
      width: "20%",
    },
    {
      title: "Percentage",
      width: "20%",
    },
    {
      title: "DOB",
      width: "15%",
    },
    {
      title: "Gender",
      width: "10%",
    },
    {
      title: "Nationality",
      width: "10%",
    },
  ];

  //session storage states
  const id = sessionStorage.getItem("id");
  const companyId = sessionStorage.getItem("companyId");

  //table states
  const profileAvatar = "https://res.cloudinary.com/df2qsnyox/image/upload/v1681133496/Default%20Profile%20Avatar/profile-avatar_ogxozq_ranghl.png";

  const [disableSaveButton, setDisableSaveButton] = useState(false)

  //snackbar states
  const [message, setMessage] = useState("")
  const [openSnackbar, setOpenSnackbar] = useState(false)

  //pagination states
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);

  //refs
  const imageRef = useRef(null)

  // Display a base64 URL inside an iframe in another window
  function debugBase64(base64URL) {
    var win = window.open();
    win.document.write('<iframe src="' + base64URL + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>');
  }

  //return base64 format of file
  const getBase64 = file => {
    return new Promise(resolve => {
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();
      // Convert the file to base64 text
      reader.readAsDataURL(file);
      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  //resume upload handler
  const handleResumeUpload = (e, item) => {
    let file = e.target.files[0];
    const fileTypes = ['doc', "docx", "pdf"];
    let fileExtension = file?.name.split('.')
    fileExtension = fileExtension[1]
    let base64File = null;
    const maxSize = 1024 * 1024 * 1; //1MB
    if (file.size > maxSize) {
      setMessage("Please upload a file less than 1MB")
      setOpenSnackbar(true)
    }
    else if (!fileTypes.includes(fileExtension)) {
      setMessage("Please upload a pdf/doc file");
      setOpenSnackbar(true)
    }
    else {
      getBase64(file)
        .then(result => {
          base64File = result;
          let temp = [...finalDisplay];
          temp = temp.map(ele => {
            if (ele.email === item.email) {
              return { ...item, resume: base64File, resumeFormat: fileExtension, viewResume: file }
            }
            return ele;
          })
          setFinalDisplay(temp)
        })
        .catch(err => {
          console.log(err);
        });
    }
  }

  //resume remove handler
  const handleRemoveResume = (item) => {
    let temp = [...finalDisplay];
    temp = temp.map(ele => {
      if (ele.email === item.email) {
        delete item["resume"]
        delete item['viewResume']
      }
      return ele;
    })
    setFinalDisplay(temp)
  }

  //image upload handler
  const handleImageUpload = (e, item) => {
    let fileCheck = e.target.files[0];
    const maxSize = 1024 * 1024; //1MB
    if (fileCheck.size > maxSize) {
      setMessage("Please upload image less than 1MB");
      setOpenSnackbar(true)
      imageRef.current.value = null;
    } else if (!fileCheck.type.startsWith("image")) {
      setMessage("Please upload an image file");
      setOpenSnackbar(true)
      e.target.files = null;
    } else {
      let base64File = null;
      getBase64(fileCheck)
        .then(result => {
          base64File = result;
          let temp = [...finalDisplay];
          temp = temp.map(ele => {
            if (ele.email === item.email) {
              return { ...item, profileImage: base64File }
            }
            return ele;
          })
          setFinalDisplay(temp)
        })
        .catch(err => {
          console.log(err);
        });

    }
  }

  //image remove handler
  const handleRemoveProfileImage = (e, item) => {
    let temp = [...finalDisplay];
    temp = temp.map(ele => {
      if (ele.email === item.email) {
        delete item["profileImage"]
      }
      return ele;
    })
    setFinalDisplay(temp)
  }

  //pagination handler
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  //submit handler for csv file
  const onSubmit = () => {

    setDisableSaveButton(true)
    if (!finalDisplay.length) {
      setMessage("Please upload a file")
      return setOpenSnackbar(true);
    }
    const formData = new FormData();
    formData.append("finalDisplay", JSON.stringify(finalDisplay))
    {
      roleFilter === "candidate" ? (axios.post(`/api/admin/internal-candidates/multiple-candidates`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((res) => {
          setMessage("Job Seekers Added Sucessfully!")
          setOpenSnackbar(true)
          setRefreshCount(prev => prev + 1)
          setTimeout(() => {
            setFileName("")
            setDisableSaveButton(false)
            setFinalDisplay([])
            setAddMultipleUsersClicked(false);
          }, 2000)

        }).catch((err) => {
          if (err.response.status === 404) {
            setMessage(err.response.data.message)
            setOpenSnackbar(true)
            setDisableSaveButton(false)
          }
        })) :
        (
          axios.post(`/api/admin/internal-screeners/multiple-screeners`, formData, {
            headers: { "Content-Type": "multipart/form-data" },
          })
            .then((res) => {
              setMessage("Screeners Added Sucessfully!")
              setOpenSnackbar(true)
              setRefreshCount(prev => prev + 1)
              setTimeout(() => {
                setFileName("")
                setDisableSaveButton(false)
                setFinalDisplay([])
                setAddMultipleUsersClicked(false);
              }, 2000)
            })
            .catch((err) => {
              if (err.response.status === 404) {
                setMessage(err.response.data.message)
                setOpenSnackbar(true)
                setDisableSaveButton(false)
              }
            })
        )
    }
  }

  return (
    <div>
      <Slide direction="left" in={true} timeout={500}>

        <Box sx={{ flexGrow: 1, background: "#faf9f6" }}>
          <div className='add-multiple-table-container'>
            <Card
              id="skills-edit"
              // className="add-recruiter-form-container "
              style={{ boxShadow: "none", borderRadius: "2px" }}
            >
              <div className="user-list">
                {/* Table Headings */}
                <div className="internal-user-list-heading">
                  {columns.map((item, index) => {
                    return (
                      <>
                        <div
                          className="header-filter"
                          style={{
                            width: `${item.width}`,
                          }}
                        >
                          <p
                            style={{
                              background: index % 2 ? "rgba(240, 97, 97, 37%)" : "rgba(240, 97, 97, 17%)",
                              color: "#283848",
                            }}
                          >
                            {item.title}
                          </p>
                        </div>
                      </>
                    );
                  })}
                </div>

                {/* Table Data */}
                <div className="internal-user-details-list">
                  {finalDisplay.length > 0 ? (
                    finalDisplay
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((item, index) => {
                        let nameLength = item.firstName?.length + item.lastName?.length;
                        {
                          return (
                            <>
                              <div
                                className={` internal-users-details ${index % 2
                                  ? ""
                                  : "alternate-recruiterlist-bg"
                                  }`}
                              >

                                <div className="internal-user-list-name">
                                  <img
                                    src={
                                      item.profileImage || profileAvatar
                                    }
                                    className="internal-user-list-profile-image image-upload-1"
                                  />
                                  {!item.profileImage ? <label className="resume-file-upload outlined-btn upload-csv-multiple upload-resume-multiple">
                                    <input type="file" accept='.jpg, .png, .jpeg' onChange={(e) => handleImageUpload(e, item)} ref={imageRef} />
                                    <AddIcon className="add-profile-image" />
                                  </label> :
                                    (
                                      <CloseIcon className="remove-profile-image" onClick={(e) => handleRemoveProfileImage(e, item)} />
                                    )}

                                  {nameLength > 23 ? (
                                    <Tooltip
                                      title={`${item.firstName} ${item.lastName}`}
                                    >
                                      <p style={{ position: 'absolute', left: '58px' }}>
                                        {item.firstName} {item.lastName}
                                      </p>
                                    </Tooltip>
                                  ) : (
                                    <p style={{ position: 'absolute', left: '58px', width: "195px" }}>
                                      {item.firstName} {item.lastName}
                                    </p>
                                  )}
                                </div>

                                <div
                                  className="internal-user-list-profileType"
                                  style={{ textAlign: "center", justifyContent: "center", gap: "5px", padding: "0px 5px", width: "16%" }}
                                >
                                  {(item?.viewResume?.name.includes('.doc') || item?.viewResume?.name.includes('.docx')) ?
                                    (<>
                                      <Tooltip title="Download">
                                        <a href={item.resume} download>
                                          <DownloadIcon
                                            fontSize="small"
                                            className='close-icon2 download-icon'
                                            // onClick={() => debugBase64(item.resume)}
                                            style={{ cursor: "pointer", color: "#2c3e50" }}
                                          />
                                        </a>
                                      </Tooltip>

                                      <Tooltip title="Remove">
                                        <CloseIcon
                                          className="close-icon2"
                                          fontSize="small"
                                          style={{ cursor: "pointer" }}
                                          onClick={(e) => handleRemoveResume(item)}
                                        />
                                      </Tooltip>
                                    </>) : item.resume ? (
                                      <>
                                        <Tooltip title="View">
                                          <ViewIcon
                                            fontSize="small"
                                            className='close-icon2'
                                            onClick={() => debugBase64(item.resume)}
                                            style={{ cursor: "pointer" }}
                                          />
                                        </Tooltip>

                                        <Tooltip title="Remove">
                                          <CloseIcon
                                            className="close-icon2"
                                            fontSize="small"
                                            style={{ cursor: "pointer" }}
                                            onClick={(e) => handleRemoveResume(item)}
                                          />
                                        </Tooltip>
                                      </>
                                    ) : <label className="resume-file-upload outlined-btn upload-csv-multiple upload-resume-multiple">
                                      <input type="file" onChange={(e) => handleResumeUpload(e, item)} />
                                      <span >Upload Resume</span>
                                    </label>}
                                </div>

                                <div
                                  className="internal-user-list-profileType"
                                  style={{ width: "18%" }}
                                >
                                  {item.title?.length > 24 ? (
                                    <Tooltip title={`${item.title}`}>
                                      <p>{item?.title}</p>
                                    </Tooltip>
                                  ) : (
                                    <p>{item?.title}</p>
                                  )}
                                </div>

                                <div
                                  className="internal-user-list-total-experience"
                                  style={{ width: "20%" }}
                                >
                                  {item.totalExperience && <p>{item.totalExperience} years</p>}
                                </div>

                                <div
                                  className="internal-user-list-salary"
                                  style={{ width: "18%" }}
                                >
                                  {item.expectedSalary && item.payType && <p>{item.expectedSalary}/{item.payType}</p>}
                                </div>

                                <div
                                  className="internal-user-list-email"
                                  style={{ width: "28%" }}
                                >
                                  {item.email?.length > 24 ? (
                                    <Tooltip title={`${item.email}`}>
                                      <p>{item.email}</p>
                                    </Tooltip>
                                  ) : (
                                    <p>{item.email}</p>
                                  )}
                                </div>

                                <div
                                  className="internal-user-list-phone "
                                  style={{ width: "18%" }}
                                >
                                  <p>{item.primaryPhone}</p>
                                </div>

                                <div
                                  className="internal-user-list-skills"
                                  style={{ width: "20%" }}
                                >
                                  <p>{item.skills}</p>
                                </div>

                                <div
                                  className="internal-user-list-location"
                                  style={{ width: "20%" }}
                                >
                                  <p>{item?.city}, {item?.state}, {item?.country}</p>
                                </div>

                                <div
                                  className="internal-user-list-education"
                                  style={{ width: "20%" }}
                                >
                                  <p>{item?.highestDegree}</p>
                                </div>

                                <div
                                  className="internal-user-list-education"
                                  style={{ width: "20%" }}
                                >
                                  <p>{item?.percentage}</p>
                                </div>

                                <div
                                  className="internal-user-list-dob"
                                  style={{ width: "15%" }}
                                >
                                  <p>{item?.dob}</p>
                                </div>

                                <div
                                  className="internal-user-list-gender"
                                  style={{ width: "10%" }}
                                >
                                  <p>{item?.gender}</p>
                                </div>

                                <div
                                  className="internal-user-list-nationality"
                                  style={{ width: "8%" }}
                                >
                                  <p>{item.nationality}</p>
                                </div>

                              </div>
                            </>
                          );
                        }
                      })
                  ) : (
                    <p>No Users</p>
                  )}
                </div>
              </div>
              <TablePagination
                id="internal-users-list-pagination"
                component="div"
                rowsPerPageOptions={[10, 25, 50, 100]}
                count={finalDisplay.length}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                variant="outlined"
              />
            </Card>

            <div className="button-container next-btn-container" >
              <button className="btn-style filled-btn filled-btn-animation" type="submit" disabled={disableSaveButton} onClick={onSubmit}>
                Save
              </button>
            </div>
          </div>
        </Box >
      </Slide>

      {/* Snackbar */}
      <UserSnackbar
        openSnackbar={openSnackbar}
        setOpenSnackbar={setOpenSnackbar}
        message={message}
      />
    </div>
  )
}

export default AddMultipleUsers