import React, { useEffect, useState } from 'react'
import axios from 'axios'

const Footer = ({ sidebarOpen }) => {
    console.log(sidebarOpen)
    const [sidebar, setSidebar] = useState(true)
    const [version, setVersion] = useState("")

    useEffect(() => {
        axios.get('/api/version/get-version')
            .then(res => {
                console.log(res)
                const { data } = res
                setVersion(data)
            })
            .catch(err => {
                console.log(err)
            })
    }, [])

    useEffect(() => {
        if (sidebarOpen === 'sidebar-close') {
            setSidebar(false)
        } else {
            setSidebar(true)
        }
    }, [sidebarOpen])


    return (
        <footer className={sidebar ? 'open-footer' : 'closed-footer'} >
            Copyright © {new Date().getFullYear()} Infomatics Corporation. All rights reserved. Confidential and Proprietary Information of Infomatics Corporation. v{version}
        </footer>
    )
}

export default Footer