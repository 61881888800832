import React, { useEffect, useState } from 'react'

//MUI imports
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { Divider, Tooltip } from "@mui/material";
import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Slide from "@mui/material/Slide";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FormGroup from '@mui/material/FormGroup';
import Chip from "@material-ui/core/Chip";
import EventIcon from '@mui/icons-material/Event';
import AddIcon from "@mui/icons-material/Add";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DeleteMessageIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { CircularProgress } from '@material-ui/core';

import Zoom from '../images/zoom.svg';
//MUI Icons
import FilterIcon from '@mui/icons-material/FilterList';
import SearchIcon from '@mui/icons-material/Search';
import OpenArrow from '@mui/icons-material/ChevronLeft';
import CloseArrow from '@mui/icons-material/ChevronRight';
import CloseIcon from '@mui/icons-material/Close';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import RefreshIcon from '@mui/icons-material/Refresh';

import axios from "axios";
import csc from "country-state-city";
import Select from "react-select";

import UserSnackBar from "../../snackBars/UserSnackbar";
// import data from "../../modals/skills.json"
import Skills from '../../modals/skills'
import SearchResults from './SearchResults';
import { timezone } from '../../../data-files/timezone'
import { useHistory } from 'react-router-dom';

const AdvancedSearch = ({ open, setSearchFilter, setSelectedSearch, setRoleFilter, setSelectedRole, roleFilter, viewProfile, setViewProfile, setOpen }) => {

    const data = Skills()

    //push for navigation
    const history = useHistory();
    //session storage states
    const id = sessionStorage.getItem("id");
    const companyId = sessionStorage.getItem("companyId");
    const role = sessionStorage.getItem("role");

    //snackbar
    const [openSnackbar, setOpenSnackbar] = useState(false);

    //filter states
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [gender, setGender] = useState("");
    const [fromExperience, setFromExperience] = useState("");
    const [toExperience, setToExperience] = useState("");
    const [salary, setSalary] = useState("");
    const [payType, setPayType] = useState("");
    const [degree, setDegree] = useState("");
    const [stream, setStream] = useState("");
    const [skill, setSkill] = useState("");
    const [skillsList, setSkillsList] = useState([]);
    const [value, setValue] = useState("");
    const [clear, setClear] = useState(false);
    const [designation, setDesignation] = useState("");
    const [company, setCompany] = useState("");

    const [zone, setZone] = useState("Asia/Kolkata");
    const [interviewLink, setInterviewLink] = useState("")
    const [time, setTime] = useState("")
    const [date, setDate] = useState("")

    //Accordian states
    const [expandAll, setExpandAll] = useState(false);
    const [personalDetailsExpand, setPersonalDetailsExpand] = useState(false);
    const [skillsExpand, setSkillsExpand] = useState(false);
    const [salaryExpand, setSalaryExpand] = useState(false);
    const [locationExpand, setLocationExpand] = useState(false);
    const [experienceExpand, setExperienceExpand] = useState(false);
    const [educationExpand, setEducationExpand] = useState(false);
    const [currentDesignationExpand, setCurrentDesignationExpand] = useState(false);
    const [currentCompanyExpand, setCurrentCompanyExpand] = useState(false);

    const [interviewTitle, setInterviewTitle] = useState("");
    const [candidateName, setCandidateName] = useState("")
    const [screenerName, setScreenerName] = useState("")
    const [candidateDetails, setCandidateDetails] = useState({
        candidateName: '',
        candidateId: "",
        candidateEmail: ''
    })
    const [screenerDetails, setScreenerDetails] = useState({
        screenerName: '',
        screenerId: "",
        screenerEmail: ''
    })

    const [scheduleClicked, setScheduleClicked] = useState(false)
    const [rightSidebarOpen, setRightSidebarOpen] = useState(false)

    const [highlight, setHighlight] = useState(false);
    const [filterPrevState, setfilterPrevState] = useState({
        skills: [],
        designation: "",
        experience: { from: "", to: "" },
        expectedSalary: { salary: "", payType: "" },
        location: { country: "", state: "", city: "" },
        personaldetails: { firstName: "", lastName: "", email: "", gender: "" },
        company: "",
        highesteducation: { degree: "", stream: "" },
    })

    //Pagination states
    const [currentPage, setCurrentPage] = useState(0)

    //localStorageCLear
    const [isLocalClear, setIsLocalClear] = useState(false)

    //Loader state
    const [isLoading, setIsLoading] = useState(true)

    //Loader used while generating link
    const [linkLoader, setLinkLoader] = useState(false)
    const [dialogOpen, setDialogOpen] = useState(false)

    //errors
    const [errors, setErrors] = useState({
        experience: ""
    })

    const [selectedProfile, setSelectedProfile] = useState({})


    const [message, setMessage] = useState("")

    //array of users
    const [users, setUsers] = useState([]);
    const [filterArray, setFilterArray] = useState([]);

    const meetSvg = [{
        icon: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="48px" height="48px"><circle cx="24" cy="24" r="20" fill="#2196f3" /><path fill="#fff" d="M29,31H14c-1.657,0-3-1.343-3-3V17h15c1.657,0,3,1.343,3,3V31z" /><polygon fill="#fff" points="37,31 31,27 31,21 37,17" /></svg>,
        label: "Zoom"
    },
    {
        icon: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="48px" height="48px"><rect width="16" height="16" x="12" y="16" fill="#fff" transform="rotate(-90 20 24)" /><polygon fill="#1e88e5" points="3,17 3,31 8,32 13,31 13,17 8,16" /><path fill="#4caf50" d="M37,24v14c0,1.657-1.343,3-3,3H13l-1-5l1-5h14v-7l5-1L37,24z" /><path fill="#fbc02d" d="M37,10v14H27v-7H13l-1-5l1-5h21C35.657,7,37,8.343,37,10z" /><path fill="#1565c0" d="M13,31v10H6c-1.657,0-3-1.343-3-3v-7H13z" /><polygon fill="#e53935" points="13,7 13,17 3,17" /><polygon fill="#2e7d32" points="38,24 37,32.45 27,24 37,15.55" /><path fill="#4caf50" d="M46,10.11v27.78c0,0.84-0.98,1.31-1.63,0.78L37,32.45v-16.9l7.37-6.22C45.02,8.8,46,9.27,46,10.11z" /></svg>,
        label: "Google"
    }]


    //time picker options
    const timeSlots = [

        "12:00 AM",
        "12:30 AM",
        "1:00 AM",
        "1:30 AM",
        "2:00 AM",
        "2:30 AM",
        "3:00 AM",
        "3:30 AM",
        "4:00 AM",
        "4:30 AM",
        "5:00 AM",
        "5:30 AM",
        "6:00 AM",
        "6:30 AM",
        "7:00 AM",
        "7:30 AM",
        "8:00 AM",
        "8:30 AM",
        "9:00 AM",
        "9:30 AM",
        "10:00 AM",
        "10:30 AM",
        "11:00 AM",
        "11:30 AM",
        "12:00 PM",
        "12:30 PM",
        "1:00 PM",
        "1:30 PM",
        "2:00 PM",
        "2:30 PM",
        "3:00 PM",
        "3:30 PM",
        "4:00 PM",
        "4:30 PM",
        "5:00 PM",
        "5:30 PM",
        "6:00 PM",
        "6:30 PM",
        "7:00 PM",
        "7:30 PM",
        "8:00 PM",
        "8:30 PM",
        "9:00 PM",
        "9:30 PM",
        "10:00 PM",
        "10:30 PM",
        "11:00 PM",
        "11:30 PM",
    ];


    useEffect(() => {
        setTimeout(() => {
            if (isLocalClear) {

                clearLocalStorage()
            }
        }, 2000)
    }, [isLocalClear])

    //favorite handler
    const favoriteHandler = (role, navigateTo) => {
        localStorage.setItem("role", role)
        localStorage.setItem("navigateTo", navigateTo)
        history.push('/admin/favorites')
    }

    //interviewSchedulers
    const saveHandler = () => {
        localStorage.removeItem('title')
        if (!interviewTitle) {
            //error for title
            setMessage("Please fill the interview title")
            setOpenSnackbar(true)
        } else if (!screenerName && !candidateName) {
            setMessage("Please fill either candidate or screener name")
            setOpenSnackbar(true)
        } else {
            const newData = {
                candidateDetails, companyId, id, screenerDetails, interviewTitle,
                interviewLink, zone, date, time, interviewStatus: "To Schedule",
                scheduledBy: id, role: role
            }

            axios.post('/api/schedule/scheduleinterview', { newData }).then(res => {
                setIsLocalClear(true)
                cancelInterview()
                setMessage("Interview Saved for Later")
                setOpenSnackbar(true)
            }).catch(err => {
                setMessage("Oops... There is some error")
                setOpenSnackbar(true)
            })
        }
    }

    const scheduleHandler = () => {
        if (interviewTitle && interviewLink && candidateName && screenerName && time && zone && date) {
            const newData = {
                candidateDetails, companyId, id, screenerDetails, interviewTitle,
                interviewLink, zone, date, time, interviewStatus: "Scheduled",
                scheduledBy: id, role: role
            }
            axios.post('/api/schedule/scheduleinterview', { newData }).then(res => {
                setMessage("Interview Scheduled successfully!")
                setOpenSnackbar(true)
                cancelInterview()
                setIsLocalClear(true)
            }).catch(err => {
                setMessage("Oops... There is some error")
                setOpenSnackbar(true)
            })
        } else {
            //show a dialogue saying there is some error
            setMessage("Please fill all the fields")
            setOpenSnackbar(true)
        }
    }

    useEffect(() => {
        if (localStorage.getItem('candidate') || localStorage.getItem('screener')) {
            localStorage.getItem('title') === null ? setInterviewTitle('') : setInterviewTitle(localStorage.getItem('title'))
            setRightSidebarOpen(localStorage.getItem('rightSidebarOpen'))
            setScheduleClicked(localStorage.getItem('scheduled'))
            localStorage.getItem('screener') ? setScreenerName(localStorage.getItem('screener')) : setScreenerName("")
            localStorage.getItem('candidate') ? setCandidateName(localStorage.getItem('candidate')) : setCandidateName("")
            localStorage.getItem('zone') ? setZone(localStorage.getItem('zone')) : setZone("Asia/Kolkata")
            localStorage.getItem('time') ? setTime(localStorage.getItem('time')) : setTime("")
            localStorage.getItem('date') ? setDate(localStorage.getItem('date')) : setDate("")
            localStorage.getItem('candidateDetails') ? setCandidateDetails(JSON.parse(localStorage.getItem('candidateDetails'))) : setCandidateDetails({
                name: "",
                id: '',
                email: ''
            })
            localStorage.getItem('screenerDetails') ? setScreenerDetails(JSON.parse(localStorage.getItem('screenerDetails'))) : setScreenerDetails({
                name: "",
                id: '',
                email: ''
            })
            localStorage.getItem('link') ? setInterviewLink(localStorage.getItem('link')) : setInterviewLink('')
        }
    }, [])

    let filters = {
        firstName,
        lastName,
        email,
        gender,
        fromExperience,
        toExperience,
        salary,
        payType,
        degree,
        stream,
        skill,
        skillsList,
        designation,
        company,
    }

    const countries = csc.getAllCountries();
    const updatedCountries = countries.map((country) => ({
        label: country.name,
        value: country.id,
        ...country,
    }));

    const updatedStates = (countryId) =>
        csc.getStatesOfCountry(countryId).map((state) => ({
            label: state.name,
            value: state.id,
            ...state,
        }));

    const updatedCities = (stateId) =>
        csc.getCitiesOfState(stateId).map((city) => ({
            label: city.name,
            value: city.id,
            ...city,
        }));

    const [initialLocation, setInitialLocation] = useState({
        country: null,
        state: null,
        city: null,
        countryId: 0,
        stateId: 0,
    });


    useEffect(() => {
        localStorage.setItem("title", interviewTitle)
        localStorage.setItem('candidate', candidateName)
        localStorage.setItem('screener', screenerName)
        localStorage.setItem('link', interviewLink)
        localStorage.setItem('date', date)
        localStorage.setItem('time', time)
        localStorage.setItem('zone', zone)
        localStorage.setItem('role', roleFilter)
        localStorage.setItem('candidateDetails', JSON.stringify(candidateDetails))
        localStorage.setItem('screenerDetails', JSON.stringify(screenerDetails))
        localStorage.setItem('scheduled', scheduleClicked)
        localStorage.setItem('rightSidebarOpen', rightSidebarOpen)
    }, [screenerName, interviewLink, date, zone, time, roleFilter, candidateName, interviewTitle, rightSidebarOpen, scheduleClicked])

    //useEffect handlers
    useEffect(() => {
        if (open) {
            setRightSidebarOpen(false);
        }
    }, [open])

    const clearLocalStorage = () => {
        localStorage.removeItem("title")
        localStorage.removeItem('candidate')
        localStorage.removeItem('screener')
        localStorage.removeItem('link')
        localStorage.removeItem('date')
        localStorage.removeItem('time')
        localStorage.removeItem('zone')
        localStorage.removeItem('role')
        localStorage.removeItem('candidateDetails')
        localStorage.removeItem('screenerDetails')
        localStorage.removeItem("navigateTo")
        localStorage.removeItem('scheduled')
    }

    const cancelInterview = () => {
        setRightSidebarOpen(false)
        setScheduleClicked(false)
        setDialogOpen(false)
        setInterviewTitle("")
        setInterviewLink("")
        setCandidateName("")
        setScreenerName("")
        setZone("Asia/Kolkata")
        setTime("")
        setDate("")
        setCandidateDetails({
            name: '', id: '', email: ''
        })
        setScreenerDetails({
            name: '', id: '', email: ''
        })
        clearLocalStorage()
    }

    //roleHandler 
    const roleHandler = (roleName, selected) => {
        setRoleFilter(roleName)
        setSelectedRole(selected)
    }

    useEffect(() => {
        (async () => {
            if (roleFilter === "screener") {
                setIsLoading(true);
                let { data } = await axios.get(`/api/admin/screeners/internal-screeners`)
                if (data) {
                    let newUsers = data.map(item => {
                        let skillsList = item.skills.map(el => el.skillName);
                        return { ...item, skillArray: skillsList }
                    })
                    newUsers = newUsers.reverse()
                    setUsers([])
                    setFilterArray(newUsers)
                    setIsLoading(false);
                }
            }
            else {
                setIsLoading(true);
                let { data } = await axios.get(`/api/admin/candidates/internal-candidates`)
                if (data) {
                    let newUsers = data.map(item => {
                        let skillsList = item.skills.map(el => el.skillName);
                        return { ...item, skillArray: skillsList }
                    })
                    newUsers = newUsers.reverse()
                    setUsers([])
                    setFilterArray(newUsers)
                    setIsLoading(false);
                }
            }
        })();
        resetHandler()
        checkExpandHandler()
    }, [roleFilter])

    useEffect(() => {
        checkExpandHandler()
    }, [errors.experience, expandAll])



    const copyTextToClipboard = async (text) => {
        try {
            await navigator.clipboard.writeText(text)
            setMessage("Link copied to clipboard")
            setOpenSnackbar(true)
        } catch (err) {
            setMessage("Failed to copy link")
            setOpenSnackbar(true)
        }
    }

    const generateLinkHandler = () => {
        setLinkLoader(true)
        axios.get(`/api/schedule/generate`)
            .then(res => {
                setLinkLoader(false)
                setInterviewLink(res.data.join_url)
            })
            .catch(err => {
                setMessage("Link cannot be generated at the moment.")
                setOpenSnackbar(true)
                setLinkLoader(false)
            })
    }

    const handleTimeChange = (value) => {
        setTime(value)
    }

    const dateHandler = (e) => {
        setDate(e.target.value)
    }

    //closeHandler Function for handling close states
    const closeHandler = (role) => {
        if (candidateName && screenerName) {
            if (role === 'candidate') {
                setCandidateName('')
            } else {
                setScreenerName("")
            }
        }
        else {
            setDialogOpen(true)
        }

    }

    const closeAllHandler = () => {
        setDialogOpen(true)
    }
    const handlePageChange = ({ selected }) => {
        setCurrentPage(selected)
    }

    const handleSalary = (e) => {
        const limit = 7;
        setSalary(e.target.value.slice(0, limit));
        setHighlight(false)
    };

    //add skill to array 
    const addSkill = (e, skill) => {
        e.preventDefault();
        if (skill) {
            setSkillsList([...skillsList, skill])
            setSkill("");
            setValue("")
            setClear((prev) => !prev);
        }
    }

    //Accordion fields expand handler
    const expandHandler = () => {
        setExpandAll(prev => !prev)
    }

    const checkExpandHandler = () => {
        if (expandAll) {
            setPersonalDetailsExpand(true);
            setSalaryExpand(true);
            setExperienceExpand(true);
            setEducationExpand(true);
            setLocationExpand(true);
            setSkillsExpand(true);
            setCurrentCompanyExpand(true)
            setCurrentDesignationExpand(true)
        } else {
            if (errors.experience) {
                setPersonalDetailsExpand(false)
                setSalaryExpand(false);
                setExperienceExpand(true);
                setEducationExpand(false);
                setLocationExpand(false);
                setSkillsExpand(false);
                setCurrentCompanyExpand(false)
                setCurrentDesignationExpand(false)
            } else {
                setPersonalDetailsExpand(false)
                setSalaryExpand(false);
                setExperienceExpand(false);
                setEducationExpand(false);
                setLocationExpand(false);
                setSkillsExpand(false);
                setCurrentCompanyExpand(false)
                setCurrentDesignationExpand(false)
            }
        }
    }

    const resetHandler = () => {
        setUsers(filterArray)
        setFirstName("");
        setLastName("");
        setSkill("")
        setValue("")
        setClear((prev) => !prev);
        setEmail('');
        setGender("")
        setCompany("");
        setDegree("");
        setStream("");
        setFromExperience("");
        setToExperience("")
        setInitialLocation({
            country: null,
            state: null,
            city: null,
            countryId: 0,
            stateId: 0,
        });
        setSkillsList([]);
        setSalary('');
        setDesignation("")
        setPayType('')
        setErrors({
            experience: ""
        })

        setfilterPrevState({
            skills: [],
            designation: "",
            experience: { from: "", to: "" },
            expectedSalary: { salary: "", payType: "" },
            location: { country: "", state: "", city: "" },
            personaldetails: { firstName: "", lastName: "", email: "", gender: "" },
            company: "",
            highesteducation: { degree: "", stream: "" },
        })
        setPersonalDetailsExpand(false)
        setSalaryExpand(false);
        setExperienceExpand(false);
        setEducationExpand(false);
        setLocationExpand(false);
        setSkillsExpand(false);
        setCurrentCompanyExpand(false);
        setCurrentDesignationExpand(false);
        setExpandAll(false);
    }

    //filterHandler 
    const filterHandler = () => {
        handlePageChange({ selected: 0 })

        if (!firstName && !lastName && !email && !gender && initialLocation.country === null && !designation && !fromExperience && !toExperience && !salary && !payType && !company && !degree && !stream && skillsList.length === 0) {
            setOpenSnackbar(true);
            setMessage("Please add atleast one search parameter!")
        }

        else {
            setHighlight(true)
            setfilterPrevState({
                skills: skillsList,
                designation: designation,
                experience: { from: fromExperience, to: toExperience },
                expectedSalary: { salary: salary, payType: payType },
                location: { country: initialLocation.country, state: initialLocation.state, city: initialLocation.city },
                personaldetails: { firstName: firstName, lastName: lastName, email: email, gender: gender },
                company: company,
                highesteducation: { degree: degree, stream: stream },
            })

            let filtered = filterArray.map(user => {
                return { ...user, matchPercentage: 100 }
            })

            //firstName filter
            firstName && (filtered = filtered.filter(item => {
                if (firstName.toLowerCase().trim() === item.firstName.toLowerCase().trim()) {
                    return true
                } else if (item.firstName.toLowerCase().includes(firstName.toLowerCase().trim())) {
                    item.matchPercentage -= 10
                    return true
                }
                return false
            }).reverse());

            // lastName filter
            lastName && (filtered = filtered.filter(item => {
                if (lastName.toLowerCase().trim() === item.lastName.toLowerCase().trim()) {
                    return true
                } else if (item.lastName.toLowerCase().includes(lastName.toLowerCase().trim())) {
                    item.matchPercentage -= 10
                    return true;
                }
                return false
            }).reverse())

            //email filter
            email && (filtered = filtered.filter(item => {
                if (email.toLowerCase().trim() === item.email.toLowerCase().trim()) {
                    return true
                } else if (item.email.toLowerCase().includes(email.toLowerCase().trim())) {
                    item.matchPercentage -= 10
                    return true
                }
                return false
            }).reverse())

            //gender filter
            gender && (filtered = filtered.filter(item => {
                return (gender.toLowerCase() === item.personal_details.gender?.toLowerCase().trim())
            }))

            //designation filter
            designation && (
                filtered = filtered.filter(item => {
                    if (designation.toLowerCase().trim() === item.profileTitle.toLowerCase().trim()) {
                        return true
                    } else if (item.profileTitle.toLowerCase().includes(designation.toLowerCase().trim())) {
                        item.matchPercentage -= 10
                        return true
                    }
                    return false
                }).reverse()
            )

            //company filter
            company && (filtered = filtered.filter(item => {
                if (item.job_experience.find(exp => exp.checked && exp.company?.toLowerCase() == company.toLowerCase().trim())) {
                    return true
                }
                else if (item.job_experience.find(exp => exp.checked && exp.company?.toLowerCase().includes(company.toLowerCase().trim()))) {
                    item.matchPercentage -= 10
                    return true
                }
                return false
            }))

            //degree and stream filter
            filtered = filtered.filter(item => {
                if (degree && stream) {
                    if (item.educational_details.find(edu => edu.degree?.toLowerCase() == degree.toLowerCase().trim() && edu.stream?.toLowerCase() == stream.toLowerCase().trim())) {
                        return true
                    }
                    if (item.educational_details.find(edu => edu.degree?.toLowerCase() == degree.toLowerCase().trim()) && item.educational_details.find(edu => edu.stream?.toLowerCase().includes(stream.toLowerCase().trim()))) {
                        item.matchPercentage -= 10
                        return true
                    }
                    if (item.educational_details.find(edu => edu.degree?.toLowerCase().includes(degree.toLowerCase().trim())) && item.educational_details.find(edu => edu.stream?.toLowerCase() == stream.toLowerCase().trim())) {
                        item.matchPercentage -= 10
                        return true
                    }
                    if (item.educational_details.find(edu => edu.degree?.toLowerCase().includes(degree.toLowerCase().trim())) && item.educational_details.find(edu => edu.stream?.toLowerCase().includes(stream.toLowerCase().trim()))) {
                        item.matchPercentage -= 20
                        return true
                    }
                    return false
                }
                else if (degree) {
                    if (item.educational_details.find(edu => edu.degree?.toLowerCase() == degree.toLowerCase().trim())) {
                        return true
                    } else if (item.educational_details.find(edu => edu.degree?.toLowerCase().includes(degree.toLowerCase().trim()))) {
                        item.matchPercentage -= 10
                        return true
                    }
                    return false
                }
                else if (stream) {
                    if (item.educational_details.find(edu => edu.stream?.toLowerCase() == stream.toLowerCase().trim())) {
                        return true
                    } else if (item.educational_details.find(edu => edu.stream?.toLowerCase().includes(stream.toLowerCase().trim()))) {
                        item.matchPercentage -= 10
                        return true
                    }
                    return false
                }
                return true
            })

            //salary and paytype filter
            filtered = filtered.filter(item => {
                if (salary && payType) {
                    if (payType === "As per industry standards") {
                        return item.salaryDetail.salary.includes(payType.trim());
                    }
                    else return (item.salaryDetail.salary == salary && item.salaryDetail.payType.includes(payType.trim()))
                }
                else if (salary) {
                    return (item.salaryDetail.salary == salary);
                }
                else if (payType) {
                    if (payType === "As per industry standards") {
                        return item.salaryDetail.salary.includes(payType.trim());
                    }
                    else return (item.salaryDetail.payType.includes(payType.trim()));
                }
                else return item;
            })

            //from and to experience filter
            filtered = filtered.filter(item => {
                if (fromExperience && toExperience) {
                    if (Number(fromExperience) > Number(toExperience)) {
                        setErrors(prev => ({
                            ...prev,
                            experience: "From experience cannot be greater than To experience"
                        }))
                        return item;
                    }
                    else return (item.totalExperience.charAt(0) >= fromExperience && item.totalExperience.charAt(0) <= toExperience);
                }
                else if (fromExperience) {
                    return (item.totalExperience.charAt(0) >= fromExperience);
                }
                else if (toExperience) {
                    return (item.totalExperience.charAt(0) >= 0 && item.totalExperience.charAt(0) <= toExperience);
                }
                else return item;
            }).sort((a, b) => a.totalExperience - b.totalExperience)

            //country filter
            initialLocation.country?.label && (filtered = filtered.filter(item => {
                if (item.personal_details.location.country.toLowerCase().includes(initialLocation.country.label.toLowerCase().trim())) {
                    return true
                }
                return false
            }))

            // state filter
            initialLocation.state?.label && (filtered = filtered.filter(item => {
                if (item.personal_details.location.state.toLowerCase().includes(initialLocation.state.label.toLowerCase().trim())) {
                    return true
                }
                return false
            }))

            //city filter
            initialLocation.city?.label && (filtered = filtered.filter(item => {
                if (item.personal_details.location.city.toLowerCase().includes(initialLocation.city.label.toLowerCase().trim())) {
                    return true
                }
                return false
            }))

            // skills filter
            const skillsListLowerCase = skillsList?.map(skill => skill.toLowerCase().trim())
            const skillsLength = skillsListLowerCase.length
            filtered = filtered.filter((item, index) => {
                if (skillsListLowerCase.length > 0) {
                    const skillsFilter = item.skillArray?.filter((skill) => {
                        return skillsListLowerCase.indexOf(skill.toLowerCase().trim()) !== -1;
                    })

                    if (skillsFilter.length > 0) {
                        if (skillsFilter.length !== skillsLength) {
                            item.matchPercentage = item.matchPercentage - 2 * Math.ceil(skillsLength / skillsFilter.length)
                        }
                    }
                    else return false
                }
                return true
            }).sort((a, b) => b.matchPercentage - a.matchPercentage)
            setUsers(filtered)
        }
    }

    const openArrowHandler = () => {
        setRightSidebarOpen((prev) => !prev);
        setOpen(false);
    };

    const closeArrowHandler = () => {
        setRightSidebarOpen((prev) => !prev);
    };

    return (
        <div>
            {!rightSidebarOpen ? (scheduleClicked && <OpenArrow className="open-arrow" onClick={openArrowHandler} />)
                :
                <>
                    <div className="right-sidebar" >
                        <CloseArrow className="close-arrow" onClick={closeArrowHandler} />
                        <Grid
                            container
                            spacing={0}
                            direction="row"
                            className="add-recruiter-card-container"
                        >
                            <Grid
                                item
                                xs={5}
                                md={12}
                                lg={12}
                                sx={{ margin: "15px 0px 10px 10px", display: "flex", gap: "7px", justifyContent: 'center' }}
                            >
                                <div style={{
                                    display: 'flex',
                                    gap: '3px',
                                    alignItems: 'flex-start'
                                }}>
                                    <strong>
                                        <h5 style={{ fontSize: 18 }}>Quick Interview Scheduler</h5>
                                    </strong>
                                    <EventIcon fontSize='small' style={{ marginLeft: "5px" }} />
                                    <Tooltip title="Close">
                                        <CloseIcon fontSize='small' onClick={closeAllHandler} className="close-scheduler " />
                                    </Tooltip>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            spacing={0}
                            direction="row"
                            className="add-recruiter-card-container"
                        >
                            <Grid
                                item
                                xs={12}
                                md={12}
                                lg={12}
                                className="container-heading-title filter-form-body"
                                sx={{ overflowY: 'hidden', height: "70px" }}
                            >
                                <Typography className='scheduler-title'>Interview Title</Typography>
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    sx={{ padding: "0px 5px 0px 5px", marginBottom: "10px" }}
                                    className="right-grid"
                                >
                                    <form noValidate>
                                        <div className="input-container filter-container">

                                            <div class="input-field-modal" >
                                                <input
                                                    type="text"
                                                    placeholder="Ex: Software Developer-Round 1"
                                                    value={interviewTitle}
                                                    onChange={(e) => setInterviewTitle(e.target.value)}

                                                />
                                            </div>
                                        </div>
                                    </form>
                                </Grid>
                            </Grid>
                            {

                                screenerName && candidateName && (roleFilter === 'candidate' || roleFilter === 'screener') ?
                                    <Grid
                                        item
                                        xs={12}
                                        md={12}
                                        lg={12}
                                        className="container-heading-title "
                                    >
                                        <Typography className='scheduler-title'>Job Seeker Name</Typography>
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            sx={{ padding: "0px 5px 0px 5px" }}
                                            className="right-grid"
                                        >

                                            <div className="input-container filter-container">

                                                <div class="input-field-modal right-sidebar-input" style={{ marginBottom: 10 }}>
                                                    <input
                                                        type="text"
                                                        value={candidateName}
                                                        onChange={(e) => {

                                                            setCandidateName(e.target.value)
                                                        }}
                                                        disabled
                                                    />
                                                    {candidateName && <CloseIcon fontSize='small' className="right-sidebar-clear-input" onClick={() => closeHandler('candidate')} />}
                                                    <button onClick={() => favoriteHandler('candidate', 'cardView')} className="choose-fav-btn">
                                                        Choose from Favorites</button>
                                                </div>

                                                <Typography className='scheduler-title'>Screener Name</Typography>
                                                <div class="input-field-modal right-sidebar-input" style={{ marginBottom: 10 }}>
                                                    <input
                                                        type="text"
                                                        value={screenerName}
                                                        onChange={(e) => setScreenerName(e.target.value)}
                                                        disabled
                                                    />
                                                    {screenerName && <CloseIcon className="right-sidebar-clear-input" fontSize='small' onClick={() => closeHandler('screener')} />}
                                                    <button onClick={() => favoriteHandler('screener', 'cardView')} className="choose-fav-btn">Choose from Favorites</button>
                                                </div>
                                            </div>
                                        </Grid>
                                    </Grid> : (candidateName && roleFilter === 'screener') ? <Grid
                                        item
                                        xs={12}
                                        md={12}
                                        lg={12}
                                        className="container-heading-title "
                                    >
                                        <Typography className='scheduler-title'>Job Seeker Name</Typography>
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            sx={{ padding: "0px 5px 0px 5px" }}
                                            className="right-grid"
                                        >

                                            <div className="input-container filter-container">

                                                <div class="input-field-modal right-sidebar-input" style={{ marginBottom: 10 }}>
                                                    <input
                                                        type="text"
                                                        value={candidateName}
                                                        onChange={(e) => setCandidateName(e.target.value)}
                                                        disabled
                                                    />
                                                    {candidateName && <CloseIcon className="right-sidebar-clear-input" fontSize='small' onClick={() => closeHandler('candidate')} />}
                                                    <button onClick={() => favoriteHandler('candidate', 'cardView')} className="choose-fav-btn">
                                                        Choose from Favorites</button>
                                                </div>

                                                <Typography className='scheduler-title'>Screener Name</Typography>
                                                <div class="input-field-modal right-sidebar-input" style={{ marginBottom: 10 }}>
                                                    <input
                                                        type="text"
                                                        value={screenerName}
                                                        onChange={(e) => setScreenerName(e.target.value)}
                                                        disabled
                                                    />
                                                    {screenerName && <CloseIcon className="right-sidebar-clear-input" fontSize='small' onClick={() => closeHandler('screener')} />}
                                                    <button className="choose-fav-btn" onClick={() => favoriteHandler('screener', 'cardView')}>Choose from Favorites</button>
                                                </div>
                                            </div>
                                        </Grid>
                                    </Grid> : screenerName && roleFilter === 'screener' ? <Grid
                                        item
                                        xs={12}
                                        md={12}
                                        lg={12}
                                        className="container-heading-title filter-form-body"
                                        sx={{ overflowY: 'hidden' }}
                                    >
                                        <div className="scheduler-buttons">
                                            <div
                                                style={{ margin: "0px 0px 6px 1px" }}
                                                className="add-new-row-button"
                                                onClick={() => roleHandler('candidate', true)}
                                            >
                                                <div>
                                                    <AddIcon />
                                                </div>
                                                <div>
                                                    <label className="schedule-label">Add Job Seeker </label>
                                                </div>
                                            </div>
                                        </div>
                                        <Typography className='scheduler-title'>Screener Name</Typography>
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            sx={{ padding: "0px 5px 0px 5px" }}
                                            className="right-grid"
                                        >

                                            <div className="input-container filter-container">

                                                <div class="input-field-modal right-sidebar-input" style={{ marginBottom: 10 }}>
                                                    <input
                                                        type="text"
                                                        value={screenerName}
                                                        onChange={(e) => setScreenerName(e.target.value)}
                                                        disabled
                                                    />
                                                    {screenerName && <CloseIcon className="right-sidebar-clear-input" fontSize='small' onClick={() => closeHandler('screener')} />}
                                                    <button className="choose-fav-btn" onClick={() => favoriteHandler('screener', 'cardView')}>Choose from Favorites</button>
                                                </div>

                                            </div>

                                        </Grid>
                                    </Grid> : screenerName && roleFilter === 'candidate' ? <Grid
                                        item
                                        xs={12}
                                        md={12}
                                        lg={12}
                                        className="container-heading-title filter-form-body"
                                        sx={{ overflowY: 'hidden' }}
                                    >
                                        <Typography className='scheduler-title'>Job Seeker Name</Typography>
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            sx={{ padding: "0px 5px 0px 5px" }}
                                            className="right-grid"
                                        >

                                            <div className="input-container filter-container">

                                                <div class="input-field-modal right-sidebar-input" style={{ marginBottom: 10 }}>
                                                    <input
                                                        type="text"
                                                        value={candidateName}
                                                        onChange={(e) => setCandidateName(e.target.value)}
                                                        disabled
                                                    />
                                                    {candidateName && <CloseIcon className="right-sidebar-clear-input" fontSize='small' onClick={() => closeHandler('candidate')} />}
                                                    <button onClick={() => favoriteHandler('candidate', 'cardView')} className="choose-fav-btn">
                                                        Choose from Favorites</button>
                                                </div>

                                                <Typography className='scheduler-title'>Screener Name</Typography>
                                                <div class="input-field-modal right-sidebar-input" style={{ marginBottom: 10 }}>
                                                    <input
                                                        type="text"
                                                        value={screenerName}
                                                        onChange={(e) => setScreenerName(e.target.value)}
                                                        disabled
                                                    />
                                                    {screenerName && <CloseIcon className="right-sidebar-clear-input" fontSize='small' onClick={() => closeHandler('screener')} />}
                                                    <button
                                                        className="choose-fav-btn"
                                                        onClick={() => favoriteHandler('screener', 'cardView')}
                                                    >Choose from Favorites</button>
                                                </div>
                                                <div>

                                                </div>
                                            </div>

                                        </Grid>
                                    </Grid> : (screenerName && candidateName && (roleFilter === 'candidate' || roleFilter === 'screener')) ? <Grid
                                        item
                                        xs={12}
                                        md={12}
                                        lg={12}
                                        className="container-heading-title filter-form-body"
                                        sx={{ overflowY: 'hidden' }}
                                    >
                                        <Typography className='scheduler-title'>Job Seeker Name</Typography>
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            sx={{ padding: "0px 5px 0px 5px" }}
                                            className="right-grid"
                                        >

                                            <div className="input-container filter-container">

                                                <div class="input-field-modal right-sidebar-input" style={{ marginBottom: 10 }}>
                                                    <input
                                                        type="text"
                                                        value={candidateName}
                                                        onChange={(e) => setCandidateName(e.target.value)}
                                                        disabled
                                                    />
                                                    {candidateName && <CloseIcon className="right-sidebar-clear-input" fontSize='small' onClick={() => closeHandler('candidate')} />}
                                                    <button onClick={() => favoriteHandler('candidate', 'cardView')} className="choose-fav-btn">
                                                        Choose from Favorites</button>
                                                </div>

                                                <Typography className='scheduler-title'>Screener Name</Typography>
                                                <div class="input-field-modal right-sidebar-input" style={{ marginBottom: 10 }}>
                                                    <input
                                                        type="text"
                                                        value={screenerName}
                                                        onChange={(e) => setScreenerName(e.target.value)}
                                                        disabled
                                                    />
                                                    {screenerName && <CloseIcon className="right-sidebar-clear-input" fontSize='small' onClick={() => closeHandler('screener')} />}
                                                    <button className="choose-fav-btn" onClick={() => favoriteHandler('screener', 'cardView')}>Choose from Favorites</button>
                                                </div>
                                                <div>

                                                </div>
                                            </div>

                                        </Grid>
                                    </Grid> : candidateName && roleFilter === 'candidate' ? <Grid
                                        item
                                        xs={12}
                                        md={12}
                                        lg={12}
                                        className="container-heading-title filter-form-body"
                                        sx={{ overflowY: 'hidden' }}
                                    >
                                        <Typography className='scheduler-title'>Job Seeker Name</Typography>
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            sx={{ padding: "0px 5px 0px 5px" }}
                                            className="right-grid"
                                        >

                                            <div className="input-container filter-container">

                                                <div class="input-field-modal right-sidebar-input" style={{ marginBottom: 10 }}>
                                                    <input
                                                        type="text"
                                                        value={candidateName}
                                                        onChange={(e) => setCandidateName(e.target.value)}
                                                        disabled
                                                    />
                                                    {candidateName && <CloseIcon className="right-sidebar-clear-input" fontSize='small' onClick={() => closeHandler('candidate')} />}
                                                    <button onClick={() => favoriteHandler('candidate', 'cardView')} className="choose-fav-btn">
                                                        Choose from Favorites</button>
                                                </div>

                                                <div className="scheduler-buttons">
                                                    <div
                                                        className="add-new-row-button"
                                                        onClick={() => roleHandler('screener', false)}
                                                    >
                                                        <div>
                                                            <AddIcon />
                                                        </div>
                                                        <div>
                                                            <label className="schedule-label">Add Screener </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </Grid>
                                    </Grid> : null}
                        </Grid>
                        <Grid
                            container
                            spacing={0}
                            direction="row"
                            className="add-recruiter-card-container"
                        >
                            <Grid
                                item
                                xs={5}
                                md={12}
                                lg={12}
                                className="container-heading-title advanced-filter-form"
                                sx={{
                                    margin: "1px 10px 10px 0px", display: "flex", alignItems: 'center',
                                    justifyContent: 'center',
                                    display: 'flex',
                                    gap: '10px',
                                    marginTop: '10px'
                                }}
                            >
                                <div class="input-field-modal" style={{ width: '50%', marginTop: 0 }}>
                                    <Typography className='scheduler-title'>Interview Date</Typography>
                                    <input
                                        style={{ height: 35, marginLeft: 5 }}
                                        class="position"
                                        type="date"
                                        min={new Date().toISOString().split("T")[0]}
                                        value={date}
                                        onChange={dateHandler}
                                        placeholder="mm/dd/yyyy"
                                    />
                                </div>

                                <div
                                    className="input-date-modal"
                                    style={{ width: '50%' }}
                                    onClick={() => {
                                        if (!date) {
                                            setMessage("Please fill the interview date");
                                            setOpenSnackbar(true);
                                        }
                                    }}>
                                    <Typography className='scheduler-title'>Interview Time</Typography>
                                    <select
                                        value={time || 'default'}
                                        onChange={(e) => handleTimeChange(e.target.value)} style={{ outline: "none" }} disabled={date ? false : true}
                                    >
                                        <option
                                            selected
                                            disabled
                                            value="default"
                                        >Select Time</option>
                                        {timeSlots.map((time) => (
                                            <option key={time} value={time}>
                                                {time}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </Grid>
                            <Grid
                                item
                                xs={5}
                                md={12}
                                lg={12}
                                className="container-heading-title advanced-filter-form"
                                sx={{ margin: "1px 10px 10px 0px" }}
                            >
                                <div className="input-date-modal">
                                    <Typography className='scheduler-title'>Time Zone</Typography>

                                    <select style={{ outline: "none", marginLeft: '5px', width: '99%' }}
                                        value={zone} onChange={(e) => setZone(e.target.value)}
                                    >
                                        {timezone.map((time) => {
                                            return <option value={time.abbr}>{time.value}</option>;
                                        })}
                                    </select>
                                </div>

                            </Grid>

                            <Grid
                                item
                                xs={5}
                                md={12}
                                lg={12}
                                className="container-heading-title advanced-filter-form"
                                sx={{ margin: "1px 10px 10px 0px" }}
                            >
                                <div className="input-date-modal">

                                    <div>
                                        <div className="scheduler-buttons">
                                            <div
                                                style={{ margin: "0px 0px 6px 1px", display: "flex", alignItems: 'center' }}
                                                className="add-new-row-button"
                                                onClick={generateLinkHandler}
                                            >
                                                <div>
                                                    <AddIcon />
                                                </div>
                                                <div>
                                                    <label className="schedule-label" style={{ marginRight: '4px', marginTop: '5px' }}>Generate Zoom Link </label>
                                                    {linkLoader ? <CircularProgress style={{ width: "unset", height: "unset", color: '#F06161' }} /> : <img src={Zoom} alt="gmeet" style={{ width: "20px" }} />}
                                                </div>
                                            </div>
                                        </div>


                                        {interviewLink != "" && <div className="input-container filter-container" style={{ position: 'relative', background: "#eee" }}>
                                            <div class="input-field-modal" >
                                                <input
                                                    disabled
                                                    type="text"
                                                    value={interviewLink}
                                                    onChange={(e) => setInterviewLink(e.target.value)}
                                                    style={{ marginLeft: '5px', width: '81%' }}
                                                />
                                            </div>
                                            <ContentCopyIcon onClick={() => copyTextToClipboard(interviewLink)} className="meet-link-icons" fontSize='small' sx={{ position: 'absolute', fontSize: '14px', cursor: 'pointer', top: '16px', right: '36px', fontSize: '15px', opacity: '50%' }} />
                                            <RefreshIcon onClick={generateLinkHandler} className="meet-link-icons" fontSize='small' sx={{ position: 'absolute', fontSize: '14px', cursor: 'pointer', top: '14px', right: '9px', fontSize: '18px', opacity: '50%' }} />
                                        </div>}
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        width: "100%",
                                        justifyContent: "space-between",
                                        marginTop: "26px"
                                    }}>
                                        <button
                                            onClick={saveHandler}
                                            style={{
                                                fontSize: "12px", padding: "5px 10px",
                                                border: "1px solid #f06161",
                                                borderRadius: "5px",
                                                color: "#f06161",
                                                backgroundColor: "white",
                                                fontWeight: "normal"
                                            }}
                                            className="btn-style outlined-btn outlined-btn-animation"
                                        >
                                            {" "}
                                            Save for Later
                                        </button>

                                        <button
                                            onClick={scheduleHandler}
                                            style={{
                                                fontSize: "12px", padding: "5px 10px",
                                                border: "1px solid #f06161",
                                                borderRadius: "5px",
                                                color: " white",
                                                backgroundColor: "#f06161",
                                                fontWeight: "normal"
                                            }}
                                            className="btn-style filled-btn filled-btn-animation"
                                        >
                                            {" "}
                                            Schedule Now
                                        </button></div>

                                </div>

                            </Grid>
                        </Grid>
                    </div>
                </>}

            <Slide direction="left" in={true} timeout={500}>
                <Box sx={{ flexGrow: 1, background: "#faf9f6", display: "flex", position: "relative", marginRight: `${rightSidebarOpen ? "260px" : "0px"}` }}>
                    {/* Left Section */}
                    <Card id="main-edit" style={{
                        width: `${open ? "26%" : rightSidebarOpen ? "25%" : "30%"}`,
                        boxShadow: "none", borderRadius: "4px", margin: "0px 40px 40px 70px", position: "fixed"
                    }}>
                        <Grid
                            container
                            spacing={0}
                            direction="row"
                            className="add-recruiter-card-container"
                        >
                            <Grid
                                item
                                xs={5}
                                md={2}
                                lg={2}
                                className="container-heading-title"
                                sx={{ margin: "20px 0px 10px 10px", display: "flex", gap: "7px" }}
                            >
                                <FilterIcon fontSize='small' sx={{ color: "#2c3e50" }} />
                                <h5>Filters</h5>
                            </Grid>
                            <Grid
                                item
                                xs={5}
                                md={8}
                                lg={8}
                                className="container-heading-title advanced-filter-form"
                                sx={{ margin: "20px 10px 10px 0px", display: "flex", justifyContent: "flex-end" }}
                            >
                                <FormGroup>
                                    <FormControlLabel onChange={expandHandler} value={expandAll} control={<Switch />} label="Expand All" />
                                </FormGroup>
                            </Grid>
                        </Grid>
                        <Divider
                            sx={{
                                borderColor: "lightgray",
                                borderBottomWidth: "1.5px",
                            }}
                            id="divider"
                        />
                        <Grid
                            container
                            spacing={0}
                            direction="row"
                            className="add-recruiter-card-container"
                        >
                            <Grid
                                item
                                xs={12}
                                md={12}
                                lg={12}
                                className="container-heading-title filter-form-body"
                                sx={{ overflowY: 'hidden' }}
                            >
                                <Accordion sx={{ boxShadow: "none" }} onChange={() => setSkillsExpand(!skillsExpand)} expanded={skillsExpand}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography>Skills</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails sx={{ padding: "0px 10px 10px" }}>
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            sx={{ padding: "0px 5px 0px 5px" }}
                                            className="right-grid"
                                        >
                                            <form noValidate onSubmit={addSkill}>
                                                <div className="input-container filter-container" style={{ display: "flex", alignItems: "center" }}>
                                                    <Autocomplete
                                                        sx={{
                                                            "& fieldset": { border: "none" }
                                                        }}
                                                        value={skillsList}
                                                        onChange={(event, newValue) => {
                                                            setSkillsList(newValue);
                                                            setHighlight(false);
                                                        }}
                                                        multiple
                                                        id="tags-filled"
                                                        options={data.map((option) => option.skill)}
                                                        freeSolo
                                                        renderTags={(skillsList, getTagProps) =>
                                                            skillsList.map((option, index) => (
                                                                <Chip
                                                                    variant="outlined"
                                                                    label={option}
                                                                    {...getTagProps({ index })}
                                                                />
                                                            ))
                                                        }
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                variant="filled"
                                                            />
                                                        )}
                                                    />
                                                </div>
                                            </form>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                                {/* Designation */}
                                <Accordion sx={{ boxShadow: "none" }} onChange={() => setCurrentDesignationExpand(!currentDesignationExpand)} expanded={currentDesignationExpand}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"

                                    >
                                        <Typography>Current Designation</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails sx={{ padding: "0px 10px 10px" }}>
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            sx={{ padding: "0px 5px 0px 5px" }}
                                            className="right-grid"
                                        >
                                            <form noValidate>
                                                <div className="input-container filter-container">

                                                    <div class="input-field-modal" >
                                                        <input
                                                            type="text"
                                                            value={designation}
                                                            onChange={(e) => {
                                                                setDesignation(e.target.value)
                                                                setHighlight(false)
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </form>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>


                                {/* Experience */}
                                <Accordion sx={{ boxShadow: "none" }} onChange={() => setExperienceExpand(!experienceExpand)} expanded={experienceExpand}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"

                                    >
                                        <Typography>Total Experience (Years)</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails sx={{ padding: "0px 10px 10px" }}>
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            sx={{ padding: "0px 5px 0px 5px" }}
                                            className="right-grid"
                                        >
                                            <form noValidate>
                                                <div className="split-inputs">
                                                    <div class="input-field-modal">
                                                        <input
                                                            type="number"
                                                            placeholder="From"
                                                            value={fromExperience}
                                                            onChange={(e) => {
                                                                setFromExperience(e.target.value);
                                                                setErrors({
                                                                    experience: ""
                                                                })
                                                                setHighlight(false)
                                                            }}
                                                        />
                                                    </div>
                                                    <div class="input-field-modal">
                                                        <input
                                                            type="number"
                                                            placeholder="To"
                                                            class="position"
                                                            value={toExperience}
                                                            onChange={(e) => {
                                                                setToExperience(e.target.value)
                                                                setErrors({
                                                                    experience: ""
                                                                })
                                                                setHighlight(false)
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div>
                                                    <span className="text-danger">
                                                        {errors.experience}
                                                    </span>
                                                </div>
                                            </form>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>

                                {/* Salary */}
                                <Accordion sx={{ boxShadow: "none" }} onChange={() => setSalaryExpand(!salaryExpand)} expanded={salaryExpand}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"

                                    >
                                        <Typography>Expected Salary (USD)</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails sx={{ padding: "0px 10px 10px" }}>
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            sx={{ padding: "0px 5px 0px 5px" }}
                                            className="right-grid"
                                        >
                                            <form noValidate>
                                                <div className="input-container filter-container">
                                                    <div className="split-inputs">
                                                        <div class="input-field-modal">
                                                            <input
                                                                class="max-salary"
                                                                value={salary}
                                                                type="number"
                                                                placeholder="Expected Salary"
                                                                onChange={handleSalary}
                                                            />
                                                        </div>
                                                        <div class="input-field-modal">

                                                            <select
                                                                style={{ outline: "none" }}
                                                                value={payType || "default"}
                                                                onChange={(e) => {
                                                                    setPayType(e.target.value)
                                                                    setHighlight(false)
                                                                }}
                                                            >
                                                                <option
                                                                    value="default"
                                                                    selected
                                                                    disabled
                                                                    hidden
                                                                >
                                                                    Pay Type
                                                                </option>
                                                                <option value="Hour">Hourly</option>
                                                                <option value="Week">Weekly</option>
                                                                <option value="Month">Monthly</option>
                                                                <option value="Annum">Per Annum</option>
                                                                <option value="As per industry standards">As Per Industry Standards</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>

                                {/* Location */}
                                <Accordion sx={{ boxShadow: "none" }} onChange={() => setLocationExpand(!locationExpand)} expanded={locationExpand}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography>Location</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails sx={{ padding: "0px 10px 10px" }}>
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            sx={{ padding: "0px 5px 0px 5px" }}
                                            className="right-grid"
                                        >
                                            <form noValidate>
                                                <div className="input-container ">
                                                    <div class="input-field-modal">
                                                        <Select
                                                            class="position"
                                                            type="text"
                                                            placeholder="Country"
                                                            value={initialLocation.country}
                                                            options={updatedCountries}
                                                            onChange={(value) => {
                                                                setInitialLocation(
                                                                    {
                                                                        country: value,
                                                                        countryId: value.id,
                                                                        state: null,
                                                                        city: null,
                                                                    },
                                                                    false
                                                                );
                                                                setHighlight(false)
                                                            }}
                                                        />
                                                    </div>

                                                    <div class="input-field-modal">
                                                        <Select
                                                            class="position"
                                                            type="text"
                                                            placeholder="State"
                                                            options={updatedStates(
                                                                initialLocation.country
                                                                    ? initialLocation.countryId
                                                                    : null
                                                            )}
                                                            value={initialLocation.state}
                                                            onChange={(value) => {
                                                                setInitialLocation(
                                                                    {
                                                                        ...initialLocation,
                                                                        state: value,
                                                                        city: null,
                                                                        stateId: value.id,
                                                                    },
                                                                    false
                                                                );
                                                                setHighlight(false)
                                                            }}
                                                        />
                                                    </div>

                                                    <div class="input-field-modal">
                                                        <Select
                                                            class="position"
                                                            placeholder="City"
                                                            type="text"
                                                            options={updatedCities(
                                                                initialLocation.state ? initialLocation.stateId : null
                                                            )}
                                                            value={initialLocation.city}
                                                            onChange={(value) => {
                                                                setInitialLocation({
                                                                    ...initialLocation,
                                                                    city: value,
                                                                });
                                                                setHighlight(false)
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </form>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>

                                {/* Personal Details */}
                                <Accordion sx={{ boxShadow: "none" }} onChange={() => setPersonalDetailsExpand(!personalDetailsExpand)} expanded={personalDetailsExpand} >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography>Personal Details</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails sx={{ padding: "0px 10px 10px" }}>
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            sx={{ padding: "0px 5px 0px 5px" }}
                                            className="right-grid"
                                        >
                                            <form noValidate>
                                                <div className="input-container filter-container">
                                                    <div class="input-field-modal" >
                                                        <input
                                                            type="text"
                                                            placeholder="First Name"
                                                            value={firstName}
                                                            onChange={(e) => {
                                                                setFirstName(e.target.value)
                                                                setHighlight(false)
                                                            }}
                                                        />
                                                    </div>

                                                    <div class="input-field-modal" >
                                                        <input class="position"
                                                            type="text"
                                                            placeholder="Last Name"
                                                            value={lastName}
                                                            onChange={(e) => {
                                                                setLastName(e.target.value)
                                                                setHighlight(false)
                                                            }}
                                                        />
                                                    </div>

                                                    <div class="input-field-modal" >
                                                        <input
                                                            class="email"
                                                            type="email"
                                                            placeholder="Email Id*"
                                                            value={email}
                                                            onChange={(e) => {
                                                                setEmail(e.target.value)
                                                                setHighlight(false)
                                                            }}
                                                        />
                                                    </div>

                                                    <div class="input-field-modal" >
                                                        <select
                                                            style={{ outline: "none" }}
                                                            value={gender || "default"}
                                                            onChange={(e) => {
                                                                setGender(e.target.value)
                                                                setHighlight(false)
                                                            }}
                                                        >
                                                            <option value="default" selected disabled hidden>
                                                                Gender
                                                            </option>
                                                            <option value="Male">Male</option>
                                                            <option value="Female">Female</option>
                                                            <option value="Prefer not to say">
                                                                Prefer not to say
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </form>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>

                                {/* Company */}
                                <Accordion sx={{ boxShadow: "none" }} onChange={() => setCurrentCompanyExpand(!currentCompanyExpand)} expanded={currentCompanyExpand}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography>Current Company</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails sx={{ padding: "0px 10px 10px" }}>
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            sx={{ padding: "0px 5px 0px 5px" }}
                                            className="right-grid"
                                        >
                                            <form noValidate>
                                                <div className="input-container filter-container">
                                                    <div class="input-field-modal" >
                                                        <input
                                                            type="text"
                                                            placeholder="Ex: Microsoft"
                                                            value={company}
                                                            onChange={(e) => {
                                                                setCompany(e.target.value)
                                                                setHighlight(false)
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </form>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>

                                {/* Education */}
                                <Accordion sx={{ boxShadow: "none" }} onChange={() => setEducationExpand(!educationExpand)} expanded={educationExpand}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography>Highest Education</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails sx={{ padding: "0px 10px 10px" }}>
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            sx={{ padding: "0px 5px 0px 5px" }}
                                            className="right-grid"
                                        >
                                            <form noValidate>
                                                <div className="input-container filter-container">

                                                    <div className="split-inputs">
                                                        <div class="input-field-modal">
                                                            <input
                                                                class="max-salary"
                                                                value={degree}
                                                                type="text"
                                                                placeholder="Highest Degree"
                                                                onChange={(e) => {
                                                                    setDegree(e.target.value)
                                                                    setHighlight(false)
                                                                }}
                                                            />

                                                        </div>
                                                        <div class="input-field-modal">
                                                            <input
                                                                class="max-salary"
                                                                value={stream}
                                                                type="text"
                                                                placeholder="Stream"
                                                                onChange={(e) => {
                                                                    setStream(e.target.value)
                                                                    setHighlight(false)
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                        </Grid>

                        <Divider
                            sx={{
                                borderColor: "lightgray",
                                borderBottomWidth: "1.5px",
                            }}
                            id="divider"
                        />

                        <Grid
                            container
                            spacing={0}
                            direction="row"
                            className="add-recruiter-card-container"
                        >
                            <Grid
                                item
                                xs={5}
                                md={12}
                                lg={12}
                                className="container-heading-title advanced-filter-form"
                                sx={{ margin: "20px 10px 10px 0px", display: "flex", justifyContent: "flex-end" }}
                            >
                                <div
                                    className='filter-buttons' style={{
                                        display: "flex",
                                        width: "100%",
                                        justifyContent: "space-between",
                                        marginLeft: "10px"
                                    }}>
                                    <button style={{
                                        fontSize: "12px",
                                        minWidth: "70px",
                                        padding: "5px 10px",
                                        border: "1px solid #f06161",
                                        borderRadius: "5px",
                                        color: "#f06161",
                                        backgroundColor: "white",
                                        fontWeight: "normal"
                                    }}
                                        className="btn-style outlined-btn outlined-btn-animation"
                                        onClick={resetHandler}>
                                        {" "}
                                        Reset
                                    </button>

                                    <button style={{
                                        fontSize: "12px",
                                        padding: "5px 10px",
                                        border: "1px solid #f06161",
                                        borderRadius: "5px",
                                        color: " white",
                                        backgroundColor: "#f06161",
                                        fontWeight: "normal"
                                    }}
                                        className="btn-style filled-btn filled-btn-animation"
                                        onClick={filterHandler}>
                                        {" "}
                                        <SearchIcon fontSize='small' sx={{ color: "white", height: "0.8em", }} />
                                        Search
                                    </button></div>

                            </Grid>
                        </Grid>
                    </Card>

                    {/* Right Section */}
                    <div style={{
                        position: "absolute",
                        left: `${open ? "36%" : "41%"}`
                    }}>
                        <SearchResults {...initialLocation} {...filters} candidateDetails={candidateDetails} handlePageChange={handlePageChange} setScreenerDetails={setScreenerDetails} setCandidateDetails={setCandidateDetails} screenerDetails={screenerDetails} currentPage={currentPage} setCurrentPage={setCurrentPage} roleFilter={roleFilter} users={users} length={filterArray.length} isLoading={isLoading} setViewProfile={setViewProfile} setSelectedProfile={setSelectedProfile} rightSidebarOpen={rightSidebarOpen} setRightSidebarOpen={setRightSidebarOpen} setScheduleClicked={setScheduleClicked} setCandidateName={setCandidateName} setScreenerName={setScreenerName} setUsers={setUsers} setOpen={setOpen} highlight={highlight} filterPrevState={filterPrevState} />;
                    </div>
                </Box>
            </Slide>

            {/* Snackbar modal */}
            <UserSnackBar
                openSnackbar={openSnackbar}
                setOpenSnackbar={setOpenSnackbar}
                message={message}
            />


            < Dialog
                fullWidth
                maxWidth="xs"
                open={dialogOpen}
                onClose={setDialogOpen}
                className="experience-edit-dialog"
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent className="dialog-content">
                    <Grid container spacing={0} justifyContent="center">
                        <Grid
                            item
                            xs={11.5}
                            sm={11.5}
                            md={12}
                            lg={12}
                            className="experience-delete-grid"
                        >
                            <div className="delete-message-icon">
                                <DeleteMessageIcon fontSize="small" />
                            </div>
                            <div className="delete-dialog-content">
                                <h5>Are you sure?</h5>
                                <p>All your progress will be lost.This process cannot be reverted</p>
                            </div>
                        </Grid>
                    </Grid>
                </DialogContent>
                <div className="alert-buttons-container">
                    <button className="warning-btn-style filled-btn filled-btn-animation" onClick={() => setDialogOpen(false)}>
                        {" "}
                        Cancel
                    </button>
                    <button className="warning-btn-style outlined-btn outlined-btn-animation" onClick={cancelInterview}>
                        {" "}
                        Confirm
                    </button>
                </div>
            </Dialog >
        </div>
    )
}

export default AdvancedSearch