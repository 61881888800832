import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import LaunchIcon from "@mui/icons-material/Launch";
import { useSelector, useDispatch } from "react-redux";
import "./tableList.css";
import { DataGrid } from "@mui/x-data-grid";
import { screeners } from "../../actions/userActions"
import VisibilityIcon from '@mui/icons-material/Visibility';
import Button from '@material-ui/core/Button';
import { Grid, TextareaAutosize, OutlinedTextFieldProps, Divider, TextField } from "@material-ui/core";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Dialog from "@mui/material/Dialog";
import { Typography } from "@mui/material";

const ScreenerList = (history) => {
  //const dispatch = useDispatch();
  useEffect(() => {
    // if (error) {
    //   alert.error(error);
    //   dispatch(clearErrors());
    // }
    axios.get("/api/users/screener").then((response) => {
      setScreenerList(response.data);
    });
  }, []);

  const { search } = window.location;
  const input = new URLSearchParams(search).get('search');
  const [keyword, setKeyword] = React.useState(input || '');

  //const { users } = useSelector((state) => state.screenerlist)
  const [screenerList, setScreenerList] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const BarStyling = { width: "30rem", height: '5px', boxShadow: '5px 10px #d9d9d9', background: "white", border: "none", padding: "20px", margin: '0px 0px 20px 10px', border: '1px solid' };



  const filterScreeners = (unfilteredscreeners, givenInput) => {
    if (!givenInput) {
      return unfilteredscreeners;
    }
    return unfilteredscreeners.filter((screener) => {
      // console.log(candidate);
      const name = screener.name.toLowerCase();
      const email = screener.email.toLowerCase();
      const role = screener.role.toLowerCase();
      const date = String(screener.date).substr(0, 10);

      const currentTitle = screener.professional_details.currentTitle.toLowerCase();
      const status = screener.status.toLowerCase();
      const uniqueMatchId = String(screener.uniqueMatchId).toLowerCase();
      //console.log(app);
      //console.log(company);
      return name.includes(givenInput.toLowerCase()) || email.includes(givenInput.toLowerCase()) || currentTitle.includes(givenInput.toLowerCase())
        || status.includes(givenInput.toLowerCase()) || role.includes(givenInput.toLowerCase())
        || date.includes(givenInput.toLowerCase()) || uniqueMatchId.includes(givenInput.toLowerCase());
      //return name.includes(givenInput.toLowerCase());
    });


  };

  const [open, setOpen] = useState(false);
  const [info, setInfo] = useState({
    screenerId: '', screenerName: '', screenerEmail: '', mobile: '', dob: '', role: '', status: '', skills: '', experience: '', currentTitle: '', highestDegree: '', major: '', englishProficiency: '', addressLine1: '', addressLine2: '', city: '', state: '', country: '', postalCode: ''
  });

  // Open dialog box
  const handleClickOpen = (id) => {
    const arrScreener = screenerList.find(item => {
      return id === item._id
    })
    // console.log(arrScreener)
    setInfo({ screenerName: arrScreener.name, screenerEmail: arrScreener.email, mobile: arrScreener.phone, dob: arrScreener.dob, role: arrScreener.role, status: arrScreener.status, skills: arrScreener.skills, experience: arrScreener.professional_details.yearsOfExperience, currentTitle: arrScreener.professional_details.currentTitle, highestDegree: arrScreener.professional_details.highestDegree, major: arrScreener.major, englishProficiency: arrScreener.englishProficiency, addressLine1: arrScreener.address.address_line1, addressLine2: arrScreener.address.address_line2, city: arrScreener.address.city, state: arrScreener.address.state, country: arrScreener.address.country, postalCode: arrScreener.address.postalCode })
    setOpen(true);
  }

  //Close the dialog box
  const handleClose = () => {
    return setOpen(false);
  };


  const columns = [
    {
      field: "id",
      // headerName: "", 
      width: -1,
      flex: -1,
      hide: true
    },
    {
      field: "uniqueMatchId",
      headerName: "Screener Id",
      minWidth: 150,
      flex: 0.3,
    },
    {
      field: "name",
      headerName: "Name",
      minWidth: 150,
      flex: 0.5,

    },

    {
      field: "email",
      headerName: "Email",
      minWidth: 150,
      flex: 0.3,
    },

    {
      field: "role",
      headerName: "Role",

      minWidth: 150,
      flex: 0.3,
    },
    {
      field: "status",
      headerName: "Status",

      minWidth: 150,
      flex: 0.3,
    },
    // {
    //     field:"expertise",
    //     headerName: "Technology",
    //     minWidth: 150,
    //     flex: 0.3,
    // },
    {
      field: "currentTitle",
      headerName: "Current Position",
      minWidth: 150,
      flex: 0.3,
    },
    {
      field: "date",
      headerName: "Created Date",

      minWidth: 150,
      flex: 0.3,
    },

    {
      field: "actions",
      flex: 0.3,
      headerName: "Actions",
      minWidth: 150,
      // type: "number",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            &nbsp;&nbsp;
            {/* <Link to={`/screener/viewprofile/${params.getValue(params.id, "id")}`} title="View Profile" > */}
            {/* <LaunchIcon /> */}
            <VisibilityIcon style={{ color: "#75a478" }} onClick={() => { handleClickOpen(params.getValue(params.id, "id")) }} />
            {/* </Link> */}
            &nbsp;&nbsp;
          </>
        );
      },

    },
  ]

  const rows = [];

  // console.log(users.data);
  const filteredScreeners = filterScreeners(screenerList, keyword);
  console.log(screenerList);
  filteredScreeners && filteredScreeners.forEach(item => {
    //  console.log(item.name)
    //  console.log(item._id)
    //  console.log(item.email)
    //  console.log(item.role)
    rows.push({
      name: item.name,
      id: item._id,
      email: item.email,
      role: item.role,
      //  expertise:item.screener_info.expertise,
      currentTitle: item.professional_details.currentTitle,
      status: item.status,
      date: String(item.date).substr(0, 10),
      uniqueMatchId: item.uniqueMatchId,
    })

  })

  return (
    <>
      <br /> <br /> <br /> <br /> <br /> <br />
      <Dialog
        maxWidth="md"
        fullWidth
        open={open}
        onClose={handleClose}
        // aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogContent>
          {/* <DialogContentText id="alert-dialog-description"> */}
          <Typography variant="h6" style={{ textAlign: "center" }}>
            <b> Screener Information{" "}</b>
            <Divider style={{ width: "25%", margin: "auto" }} />
            {/* <br /> */}
          </Typography>

          <Grid container spacing={24} style={{ padding: "2em" }}>
            <Grid item xs={12} sm={8}>
              <Typography variant="subtitle1" style={{ color: "gray" }}>
                Personal Details
                <Divider />
                <br />
              </Typography>
            </Grid>
            <Grid item xs={12} sm={5}>
              <div>
                <label>Name</label>
                <TextField
                  disabled
                  id="standard-disabled"
                  value={info.screenerName}
                  variant="standard"
                  fullWidth
                />
              </div>
            </Grid>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Grid item xs={12} sm={5}>
              <div>
                <label>Email</label>
                <TextField
                  disabled
                  id="standard-disabled"
                  value={info.screenerEmail}
                  variant="standard"
                  fullWidth
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={5}>
              <div>
                <label>Mobile</label>
                <TextField
                  disabled
                  id="standard-disabled"
                  value={info.mobile}
                  variant="standard"
                  fullWidth
                />
              </div>
            </Grid>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Grid item xs={12} sm={5}>
              <div>
                <label>Date of birth</label>
                <TextField
                  disabled
                  id="standard-disabled"
                  value={info.dob}
                  variant="standard"
                  fullWidth
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={5}>
              <div>
                <label>Role</label>
                <TextField
                  disabled
                  id="standard-disabled"
                  value={info.role}
                  variant="standard"
                  fullWidth
                />
              </div>
            </Grid>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Grid item xs={12} sm={5}>
              <div>
                <label>Status</label>
                <div>
                  <TextField
                    disabled
                    maxRows={5}
                    value={info.status}
                    variant="standard"
                    style={{ width: "100%" }}
                  // style={{ width: "auto" }}
                  />
                </div>
              </div>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </Grid>
            <Grid item xs={12} sm={8}>
              <Typography variant="subtitle1" style={{ color: "gray" }}>
                Professional Details
                <Divider />
                <br />
              </Typography>
            </Grid>
            <br />
            <Grid item xs={12} sm={5}>
              <div>
                <label>Skills</label>
                <TextField
                  disabled
                  id="standard-disabled"
                  value={info.skills}
                  variant="standard"
                  fullWidth
                />
              </div>
            </Grid>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Grid item xs={12} sm={5}>
              <div>
                <label>Years of experience</label>
                <TextField
                  disabled
                  id="standard-disabled"
                  value={info.experience}
                  variant="standard"
                  fullWidth
                />
              </div>
            </Grid>

            <Grid item xs={12} sm={5}>
              <div>
                <label>Current title</label>
                <TextField
                  disabled
                  id="standard-disabled"
                  value={info.currentTitle}
                  variant="standard"
                  fullWidth
                />
              </div>
            </Grid>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

            <Grid item xs={12} sm={5}>
              <div>
                <label>Highest Degree</label>
                <TextField
                  disabled
                  id="standard-disabled"
                  value={info.highestDegree}
                  variant="standard"
                  fullWidth
                />
              </div>
            </Grid>

            <Grid item xs={12} sm={5}>
              <div>
                <label>Major</label>
                <TextField
                  disabled
                  id="standard-disabled"
                  value={info.major}
                  variant="standard"
                  fullWidth
                />
              </div>
            </Grid>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

            <Grid item xs={12} sm={5}>
              <div>
                <label>English Proficiency</label>
                <TextField
                  disabled
                  id="standard-disabled"
                  value={info.englishProficiency}
                  variant="standard"
                  fullWidth
                />
              </div>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </Grid>

            <Grid item xs={12} sm={8}>
              <Typography variant="subtitle1" style={{ color: "gray" }}>
                Contact Details
                <Divider />
                <br />
              </Typography>
            </Grid>
            <br />
            <Grid item xs={12} sm={5}>
              <div>
                <label>Address Line 1</label>
                <TextField
                  disabled
                  id="standard-disabled"
                  value={info.addressLine1}
                  variant="standard"
                  fullWidth
                />
              </div>
            </Grid>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Grid item xs={12} sm={5}>
              <div>
                <label>Address Line 2</label>
                <TextField
                  disabled
                  id="standard-disabled"
                  value={info.addressLine2}
                  variant="standard"
                  fullWidth
                />
              </div>
            </Grid>

            <Grid item xs={12} sm={5}>
              <div>
                <label>City</label>
                <TextField
                  disabled
                  id="standard-disabled"
                  value={info.city}
                  variant="standard"
                  fullWidth
                />
              </div>
            </Grid>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

            <Grid item xs={12} sm={5}>
              <div>
                <label>State</label>
                <TextField
                  disabled
                  id="standard-disabled"
                  value={info.state}
                  variant="standard"
                  fullWidth
                />
              </div>
            </Grid>

            <Grid item xs={12} sm={5}>
              <div>
                <label>Country</label>
                <TextField
                  disabled
                  id="standard-disabled"
                  value={info.country}
                  variant="standard"
                  fullWidth
                />
              </div>
            </Grid>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

            <Grid item xs={12} sm={5}>
              <div>
                <label>Postal Code</label>
                <TextField
                  disabled
                  id="standard-disabled"
                  value={info.postalCode}
                  variant="standard"
                  fullWidth
                />
              </div>
            </Grid>
          </Grid>
          {/* </DialogContentText> */}

        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            style={{ backgroundColor: "#D73B30", color: "white" , marginRight:"20px" }}
          >
            Back
          </Button>
        </DialogActions>
      </Dialog>
      <input
        style={BarStyling}
        type="text"
        key="random1"
        value={keyword}
        placeholder={'Search For Screeners'}
        onChange={(e) => setKeyword(e.target.value)}
        name="search"
      />
      <div className="myList">

        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={10}
          disableSelectionOnClick
          className="tableList"
          autoHeight
        />
      </div>
    </>
  )
}
export default ScreenerList;