import React, { useState } from 'react';
import axios from 'axios'

import {
    Dialog,
    DialogContent,
    Grid,
    Tooltip
} from "@material-ui/core";
import { Divider } from "@mui/material";

// ICONS
import CloseIcon from '@mui/icons-material/Close';

import { timeSlots } from '../../data-files/timeSlots';

const RescheduleDialog = ({ rescheduleOpen, setRescheduleOpen, setOpenSnackbar, setMessage, getInterviews, selectedInterview, role }) => {
    const [time, setTime] = useState("");
    const [date, setDate] = useState("");

    const [errors, setErrors] = useState({
        date: "",
        time: ""
    })

    const dateHandler = (value) => {
        setDate(value)
        setErrors({})
    }

    const handleTimeChange = (value) => {
        setTime(value)
        setErrors({})
    }

    // close reschedule modal
    const handleCloseModal = () => {
        setRescheduleOpen(false)
        setDate("")
        setTime("")
    }

    const handleReschedule = (e) => {
        e.preventDefault();

        if (date == "") return setErrors({ date: "Please enter date" })
        if (time == "") return setErrors({ time: "Please enter time" })
        setRescheduleOpen(false)

        const rescheduledTime = {
            date, time
        }

        axios.post(`/api/schedule/${role}/reschedule/${selectedInterview.interviewUniqueId}`, { selectedInterview, rescheduledTime }).then(res => {
            setDate("")
            setTime("")
            getInterviews()
            setMessage(res.data.msg)
            setOpenSnackbar(true)

        }).catch(err => {
            console.log(err)
        })
    }
    return (
        <div>
            <Dialog
                open={rescheduleOpen}
                onClose={setRescheduleOpen}
                className="experience-edit-dialog"
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent
                    className="experience-edit-dialog-content"
                >
                    <Grid
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        className="experience-edit-title-grid"
                    >
                        <div>
                            <div className="experience-edit-title">
                                <p>Reschedule Interview</p>
                            </div>
                            <div className="experience-edit-close">
                                <Tooltip title="Close">
                                    <CloseIcon onClick={handleCloseModal} />
                                </Tooltip>
                            </div>
                        </div>
                        <Divider className="experience-edit-top-divider" />
                    </Grid>
                    <Grid
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        className="experience-edit-right-grid"
                    >
                        <div className="experience-edit-form-container reschedule-form">
                            <form
                                className="experience-edit-form"
                                onSubmit={handleReschedule}
                                noValidate
                            >
                                <div class="input-container">
                                    <div class="input-field">
                                        <label>Enter Date </label>
                                        <div>
                                            <input
                                                style={{ height: 35 }}
                                                class="position"
                                                type="date"
                                                min={new Date().toISOString().split("T")[0]}
                                                value={date}
                                                name="interviewDate"
                                                onChange={(e) => dateHandler(e.target.value)}
                                                placeholder="mm/dd/yyyy"
                                            />
                                        </div>
                                        <span className="text-danger">{errors.date}</span>
                                    </div>
                                    <div class="input-field">
                                        <label>Enter Time</label>
                                        <div>
                                            <select
                                                value={time || "default"}
                                                name="interviewTime"
                                                onChange={(e) => handleTimeChange(e.target.value)}
                                                style={{ outline: "none", height: 35 }}
                                            >
                                                <option value="default" selected disabled>
                                                    Select Time
                                                </option>
                                                {timeSlots.map((time) => (
                                                    <option key={time} value={time}>
                                                        {time}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <span className="text-danger">{errors.time}</span>
                                    </div>

                                    <div className="button-container">
                                        <button className="btn-style filled-btn filled-btn-animation" >
                                            {" "}
                                            Save
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </Grid>
                </DialogContent>
            </Dialog >
        </div>
    )
}

export default RescheduleDialog