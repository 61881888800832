import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Gauge } from '@ant-design/plots';

const GaugeChart = ({ result, chart }) => {
    console.log(result);
    const [label, setLabel] = useState("");


    useEffect(() => {
        if (chart == "profileActivity") {
            if (result > 75) {
                setLabel("High")
            } else if (result <= 75 && result > 50) {
                setLabel("Medium")
            } else if (result <= 50 && result > 25) {
                setLabel("Low")
            } else {
                setLabel("Very Low")
            }
        }
    }, [result]);

    const config = {
        percent: `${result}` / 100,
        range: {
            color: 'l(0) 0:#F0616138 1:#F06161',
        },
        startAngle: 0.9 * Math.PI,
        endAngle: 2.1 * Math.PI,
        indicator: null,
        statistic: {
            title: {
                offsetY: -36,
                style: {
                    fontSize: '24px',
                    color: '#283848',
                },
                formatter: () => chart == "profileActivity" ? label : result + "%",
            },
            content: {
                style: {
                    fontSize: '12px',
                    lineHeight: '44px',
                    color: '#283848',
                },
                formatter: () => '',
            },
        },
    };
    return <Gauge className="profile-completion-chart" {...config} />;
};

export default GaugeChart;
