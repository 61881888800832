import React, { useState, useEffect, useRef } from "react";
import axios from "axios";

// CSS import
import "../CompanyAdmin.css";

//component imports
import CompanyMenu from "../../userProfiles/CompanyMenu";
import SendInvitation from "./SendInvitation";
import InvitationList from "./InvitationList";
import Footer from "../../../Footer/Footer";

const Invitation = () => {
  //Account Id and role
  const id = sessionStorage.getItem("id");

  //Navbar states
  const [open, setOpen] = useState();
  const [sidebarOpen, setsidebarOpen] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [interacted, setInteracted] = useState("");
  const [croppedImg, setCroppedImg] = useState("");
  const [profileImage, setProfileImage] = useState("");
  const [defaultAvatar, setDefaultAvatar] = useState("");

  const [pageName, setPageName] = useState("invitationList");

  //setting document title
  useEffect(() => {
    document.title = "Screener | Invitations";
  }, []);

  //fetching navbar info
  useEffect(() => {
    const { data } = axios.get(`/api/admin/${id}`).then((res) => {
      const {
        defaultAvatar,
        profileImage,
        croppedImage,
        interacted,
        companyName,
      } = res.data.user;
      setDefaultAvatar(defaultAvatar);
      setProfileImage(profileImage);
      setCroppedImg(croppedImage);
      setInteracted(interacted);
      setCompanyName(companyName);
    });
  }, []);

  return (
    <div>
      {/* Navbar Component */}
      <CompanyMenu
        open={open}
        setOpen={setOpen}
        sidebarOpen={sidebarOpen}
        setsidebarOpen={setsidebarOpen}
        companyName={companyName}
        croppedImg={croppedImg}
        profileImage={profileImage}
        interacted={interacted}
        defaultAvatar={defaultAvatar}
      />

      {/* displaying component based on condition */}
      {pageName === "invitationList" ?
        <InvitationList
          sidebarOpen={sidebarOpen}
          setsidebarOpen={setsidebarOpen}
          setPageName={setPageName}
          pageName={pageName}
        />
        : pageName === "sendInvitation" ?
          <SendInvitation
            sidebarOpen={sidebarOpen}
            setPageName={setPageName} />
          : ""}

      <Footer sidebarOpen={sidebarOpen} />
    </div>
  );
};

export default Invitation;
