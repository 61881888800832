import React, { useState } from "react";
import axios from "axios";
import Grid from "@mui/material/Grid";
import DialogContent from "@mui/material/DialogContent";
import { Dialog } from "@mui/material";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
//css
import "./ChangePassword.css";
import CloseIcon from "@mui/icons-material/Close";

import ViewIcon from "../../images/view.png";
import HideIcon from "../../images/hide.png";
import ErrorMessageSnackbar from "../snackBars/errorMessageSnackbar";
import PasswordUpdateSnackbar from "../snackBars/passwordUpdateSnackbar";
import PasswordChecklist from "react-password-checklist";

const ChangePasswordModal = ({
  openAlertDialog,
  setOpenAlertDialog,
  id,
  role,
}) => {

  console.log(openAlertDialog)
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);

  //toggler for pwd
  const [currentPasswordImage, setCurrentPasswordImage] = useState(ViewIcon);
  const [currentToggler, setCurrentToggler] = useState("password");
  const [firstCurrentToggle, setFirstCurrentToggle] = useState(true);
  const [passwordImage, setPasswordImage] = useState(ViewIcon);
  const [toggler, setToggler] = useState("password");
  const [firstToggle, setFirstToggle] = useState(true);

  const [confirmPasswordImage, setConfirmPasswordImage] = useState(ViewIcon);
  const [confirmToggler, setConfirmToggler] = useState("password");
  const [firstConfirmToggle, setFirstConfirmToggle] = useState(true);

  // state used to handle display of password checklist
  const [isPasswordFocussed, setIsPasswordFocussed] = useState(false);

  //passwordHandler

  const currentPasswordHandler = () => {
    if (firstCurrentToggle) {
      setCurrentToggler("text");
      setCurrentPasswordImage(HideIcon);
      setFirstCurrentToggle(false);
    } else {
      setCurrentToggler("password");
      setCurrentPasswordImage(ViewIcon);
      setFirstCurrentToggle(true);
    }
  };

  const passwordHandler = () => {
    if (firstToggle) {
      setToggler("text");
      setPasswordImage(HideIcon);
      setFirstToggle(false);
    } else {
      setToggler("password");
      setPasswordImage(ViewIcon);
      setFirstToggle(true);
    }
  };

  const confirmPasswordHandler = () => {
    if (firstConfirmToggle) {
      setConfirmToggler("text");
      setConfirmPasswordImage(HideIcon);
      setFirstConfirmToggle(false);
    } else {
      setConfirmToggler("password");
      setConfirmPasswordImage(ViewIcon);
      setFirstConfirmToggle(true);
    }
  };

  // initialization of errors
  const [errors, setErrors] = useState({
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  });

  const onCloseAlertDialog = (e) => {
    e.preventDefault();
    setOpenAlertDialog(false);
    setCurrentPassword("");
    setConfirmPasswordImage("");
    setNewPassword("");
    setConfirmPasswordImage(ViewIcon);
    setCurrentPasswordImage(ViewIcon);
    setPasswordImage(ViewIcon);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (!currentPassword) {
      return setErrors({ currentPassword: "Current Password is required." });
    }
    if (!newPassword) {
      return setErrors({ newPassword: "New Password is required." });
    } else if (
      !/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/.test(
        newPassword
      )
    ) {
      return setIsPasswordFocussed(true);
    }

    if (!confirmNewPassword) {
      return setErrors({ confirmNewPassword: "Confirm Password is required." });
    } else if (confirmNewPassword !== newPassword) {
      return setErrors({ confirmNewPassword: "Passwords do not match." });
    }
    if (currentPassword === newPassword) {
      return setErrors({
        newPassword: "Current Password and New Password cannot be same.",
      });
    }

    const newData = {
      currentPassword: currentPassword,
      newPassword: newPassword,
    };
    axios
      .post(`/api/${role}/change-password/${id}`, newData)
      .then((data) => {
        setOpenSnackbar(true);
        setOpenAlertDialog(false);
        setErrors({
          currentPassword: "",
          confirmNewPassword: "",
          newPassword: "",
        });
        setCurrentPassword("");
        setNewPassword("");
        setConfirmNewPassword("");
        setConfirmToggler("password");
        setToggler("password");
        setCurrentToggler("password");
        setConfirmPasswordImage(ViewIcon);
        setCurrentPasswordImage(ViewIcon);
        setPasswordImage(ViewIcon);
      })
      .catch((err) => {
        const statusCodes = err.response.status;
        if (statusCodes === 400)
          setErrors({ currentPassword: err.response.data.message });
        else if (statusCodes === 500) setOpenErrorSnackbar(true);
      });
  };

  return (
    <div>
      <Dialog
        open={openAlertDialog}
        onClose={openAlertDialog}
        className="experience-edit-dialog"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        id="change-password-dialog"
      >
        <DialogContent className="experience-edit-dialog-content">
          <Grid container spacing={0} justifyContent="center">
            <Grid
              xs={12}
              sm={12}
              md={12}
              lg={12}
              className="experience-edit-title-grid"
            >
              <div>
                <div className="experience-edit-title">
                  <p>Change Password</p>
                </div>
                <div className="experience-edit-close">
                  <Tooltip title="Close">
                    <CloseIcon onClick={onCloseAlertDialog} />
                  </Tooltip>
                </div>
              </div>
              <Divider className="experience-edit-top-divider" />
            </Grid>

            <Grid
              xs={12}
              sm={12}
              md={12}
              lg={12}
              className="experience-edit-right-grid"
            >
              <div className="experience-edit-form-container">
                <form
                  className="experience-edit-form"
                  onSubmit={onSubmit}
                  noValidate
                >
                  <div class="input-container">
                    <div class="input-field">
                      <label className="date-label">Current Password*</label>

                      <input
                        class="position"
                        type={currentToggler}
                        value={currentPassword}
                        onChange={(e) => {
                          setCurrentPassword(e.target.value);
                          setErrors({ currentPassword: "" });
                          setIsPasswordFocussed(false);
                        }}
                        placeholder="Enter Current Password"
                      />
                      <img
                        class="password-icon"
                        src={currentPasswordImage}
                        onClick={currentPasswordHandler}
                      />
                    </div>
                    <div>
                      <span className="text-danger">
                        {errors.currentPassword}
                      </span>
                    </div>
                    <div class="input-field">
                      <label className="date-label">New Password*</label>
                      <input
                        class="company"
                        type={toggler}
                        onChange={(e) => {
                          setNewPassword(e.target.value);
                          setErrors({ newPassword: "" });
                          setIsPasswordFocussed(true);
                        }}
                        placeholder="Enter New Password"
                      />
                      <img
                        class="password-icon"
                        src={passwordImage}
                        onClick={passwordHandler}
                      />
                    </div>
                    <div>
                      <span className="text-danger">{errors.newPassword}</span>
                    </div>

                    {newPassword && isPasswordFocussed ? (
                      <PasswordChecklist
                        className="password-checklist"
                        rules={[
                          "minLength",
                          "specialChar",
                          "number",
                          "capital",
                          "lowercase",
                        ]}
                        minLength={8}
                        value={newPassword}
                        iconSize={10}
                        onChange={(isValid) => { }}
                        messages={{
                          minLength: "Minimum 8 characters",
                          specialChar: "Special characters",
                          number: "Numbers",
                          capital: "Uppercase letters",
                          lowercase: "Lowercase letters",
                        }}
                        invalidColor="#f06161"
                        validColor="#2e8b57"
                      />
                    ) : (
                      ""
                    )}

                    <div class="input-field">
                      <label className="date-label">
                        Re-Enter New Password *
                      </label>
                      <input
                        class="company"
                        type={confirmToggler}
                        onChange={(e) => {
                          setConfirmNewPassword(e.target.value);
                          setErrors({ confirmNewPassword: "" });
                          setIsPasswordFocussed(false);
                        }}
                        placeholder="Re-Enter Password"
                      />
                      <img
                        class="password-icon"
                        src={confirmPasswordImage}
                        onClick={confirmPasswordHandler}
                      />
                    </div>
                    <div>
                      <span className="text-danger">
                        {errors.confirmNewPassword}
                      </span>
                    </div>
                  </div>
                  <div className="button-container-change-password edit-btn-container flex-btn">
                    <button
                      className="warning-btn-style filled-btn filled-btn-animation"
                      onClick={onCloseAlertDialog}
                    >
                      {" "}
                      Cancel
                    </button>
                    <button className=" warning-btn-style outlined-btn outlined-btn-animation">Confirm</button>
                  </div>
                </form>
              </div>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      <PasswordUpdateSnackbar
        openSnackbar={openSnackbar}
        setOpenSnackbar={setOpenSnackbar}
      />
      <ErrorMessageSnackbar
        openErrorSnackbar={openErrorSnackbar}
        setOpenErrorSnackbar={setOpenErrorSnackbar}
      />
    </div>
  );
};

export default ChangePasswordModal;
