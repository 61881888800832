import * as React from "react";
import { useEffect, useState, useRef } from "react";
import { Link as RouterLink } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
// import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "./LoginComponent.css";
import classnames from "classnames";
import Logo from "../../images/brand.svg";
import BackArrow from "../../images/left-arrow.png";
// import arrow from "../../images/backArrow.png";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import MailIcon from "../../images/mail.png";
import LockIcon from "../../images/lock.png";
import UserIcon from "../../images/user.png";
import ViewIcon from "../../images/view.png";
import HideIcon from "../../images/hide.png";
import PhoneInput from "react-phone-input-2";
import "intl-tel-input/build/css/intlTelInput.css";
import "react-phone-input-2/lib/style.css";
import axios from "axios";
import ConfirmationModal from "../modals/confirmationModal";
import RedirectModal from "../modals/redirectModal";
import PasswordChecklist from "react-password-checklist";
import { getPhonesRegex } from "./PhoneValidation";
import { useParams, useHistory } from "react-router-dom";
import { decode as base64_decode } from "base-64";
import { FcGoogle } from "react-icons/fc";
import { googleLogout, useGoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import UserSnackbar from "../snackBars/UserSnackbar";
import SocialRegisterModal from "../modals/SocialRegisterModal";
import { Divider } from "@mui/material";
const theme = createTheme();

const RegisterComponent = (
  {
    // firstName,
    // lastName,
    // email,
    // phone,
    // password,
    // confirmPassword,
    // role,
    // onChange,
    // handlePhoneChange,
    // onSubmit,
    // errors,
    // isErrors,

  }
) => {
  let { fName, lName, Email, roleType } = useParams();

  fName = fName && base64_decode(fName);
  lName = lName && base64_decode(lName);
  Email = Email && base64_decode(Email);
  roleType = roleType && base64_decode(roleType);
  const [firstName, setFirstName] = useState(fName || "");
  const [lastName, setLastName] = useState(lName || "");
  const [email, setEmail] = useState(Email || "");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [passwordIsValid, setPasswordIsValid] = useState(true)
  const [confirmPassword, setConfirmPassword] = useState("");
  const [role, setRole] = useState(roleType || "");

  const [user, setUser] = useState("")

  const [passwordImage, setPasswordImage] = useState(ViewIcon);
  const [toggler, setToggler] = useState("password");
  const [firstToggle, setFirstToggle] = useState(true);

  const [confirmPasswordImage, setConfirmPasswordImage] = useState(ViewIcon);
  const [confirmToggler, setConfirmToggler] = useState("password");
  const [firstConfirmToggle, setFirstConfirmToggle] = useState(true);
  const [tempRole, setTempRole] = useState("")
  const [modalShow, setModalShow] = useState(false);
  const [redirectModal, setRedirectModal] = useState(false);
  const [registerModal, setRegisterModal] = useState(false)



  const [message, setMessage] = useState("")
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [tempMail, setTempMail] = useState("");
  // const [newData, setNewData] = useState({    firstName:"",
  // lastName:"",
  // email:"",
  // phone:"",
  // password:"",
  // role:""});

  // state used to handle display of password checklist
  const [isPasswordFocussed, setIsPasswordFocussed] = useState(false);

  const [phoneIsValid, setPhoneIsValid] = useState(false);

  let newData = {};


  const history = useHistory()
  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    password: "",
    confirmPassword: "",
    role: "",
  });

  const [icon, setIcon] = useState("")

  // store the regex of phone number patterns of all countries
  const phones = getPhonesRegex();

  //title
  useEffect(() => {
    // history.push("/register");
    document.title = "Screener | Register";
  }, [Email]);


  useEffect(
    () => {

      if (user) {
        axios
          .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
              Accept: 'application/json'
            }
          })
          .then((res) => {
            console.log(res.data)
            const { given_name, family_name, email, verified_email, picture, } = res.data
            const newData = {
              firstName: given_name,
              lastName: family_name,
              verified: verified_email,
              email, profileImage: picture
            }
            axios.post(`/api/${tempRole}/register/social-media`, newData).then(res => {
              console.log(res)
              if (res.status == 200) {
                setRedirectModal(true)
                setTempMail(res.data.email)
                setTimeout(() => {

                  history.push('/login')
                  //setSomething(false)
                  setRedirectModal(false)
                }, 2100)
              }
            }).catch(err => {
              console.log(err)
              setOpenSnackbar(true)
              setMessage(err.response.data.message)
              setTempRole("")
            })

            // setProfile(res.data);
          })
          .catch((err) => {
            console.log(err)
          });
      }

    }, [user]);

  const socialMediaHandler = (platform) => {
    console.log(platform)
    if (!tempRole) {
      setOpenSnackbar(true)
      setMessage("Please select a role")
      return
    }
    switch (platform) {
      case 'google':
        setOpenSnackbar(false)
        setRegisterModal(false)
        register()
        break;
    }
  }

  //password Toggle with image

  const passwordHandler = () => {
    if (firstToggle) {
      setToggler("text");
      setPasswordImage(HideIcon);
      setFirstToggle(false);
    } else {
      setToggler("password");
      setPasswordImage(ViewIcon);
      setFirstToggle(true);
    }
  };

  const ConfirmPasswordHandler = () => {
    if (firstConfirmToggle) {
      setConfirmToggler("text");
      setConfirmPasswordImage(HideIcon);
      setFirstConfirmToggle(false);
    } else {
      setConfirmToggler("password");
      setConfirmPasswordImage(ViewIcon);
      setFirstConfirmToggle(true);
    }
  };

  const isLetter = (e) => {
    const re = /[a-zA-z\s]+/g;
    if (!re.test(e.key)) {
      e.preventDefault();
    }
  };
  // const resetFieldPassword = () => {
  //   errors.password = "";

  //   errors.email = "";
  //   errors.name = "";
  // };

  // const resetFieldconfirmPassword = () => {
  //   errors.confirmPassword = "";
  // };

  // const resetFieldEmail = () => {
  //   errors.email = "";
  // };

  // const resetFieldname = () => {
  //   errors.name = "";
  // };
  // const resetFieldPhone = () => {
  //   errors.phone = "";
  // };

  console.log(passwordIsValid)

  const onSubmit = (e) => {
    e.preventDefault();
    if (firstName === "") {
      return setErrors({
        firstName: "First Name is required",
      });
    }

    if (lastName === "") {
      return setErrors({
        lastName: "Last Name is required",
      });
    }
    if (email === "") {
      return setErrors({
        email: "Email is required",
      });
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      return setErrors({
        email: "Email is Invalid",
      });
    }

    if (phone === "") {
      return setErrors({ phone: "Phone Number is required" });
    } else if (!phoneIsValid) {
      return setErrors({ phone: "Invalid Phone Number" });
    }
    if (password === "") {
      return setErrors({ password: "Password is required" });
    } else if (
      !/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/.test(
        password
      )
    ) {
      // return setErrors({
      //   password:
      //     "Your password must be at least 8 characters including a lowercase letter, an uppercase letter, a number, and a special character.",
      // });
      return setIsPasswordFocussed(true);
    }

    if (confirmPassword === "") {
      return setErrors({ confirmPassword: "Confirm Password is required" });
    } else if (confirmPassword !== password) {
      return setErrors({ confirmPassword: "Passwords do not match" });
    }

    if (role === "") {
      return setErrors({ role: "Please select a role" });
    }
    newData = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      phone: phone,
      password: password,
      role: role,
    };

    console.log(role);
    const newRole = role === "candidate" ? "candidate" : "screener";

    axios
      .post(`/api/${newRole}/register`, {
        newData: newData,
      })
      .then((data) => {
        console.log(data);
        setTempMail(newData.email);
        setLastName("");
        setFirstName("");
        setPhone("");
        setEmail("");
        setConfirmPassword("");
        setPassword("");
        setRole("");
        setPasswordImage(ViewIcon);
        setConfirmPasswordImage(ViewIcon);
        setToggler("password");
        setConfirmToggler("password");
        setModalShow(true);
        setErrors({
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
          password: "",
          confirmPassword: "",
          role: "",
        });
      })
      .catch((err) => {
        if (err.response.status == 409) {
          return setErrors({
            email: err.response.data.email
          });
        }
      });

    // else if(role ==="screener"){
    //   axios.post('/api/screener/register', {
    //     newData:newData
    //    }).then(data=>console.log(newData)).catch(err=>console.log(err))

    // }
  };

  const register = useGoogleLogin({
    onSuccess: (codeResponse) => setUser(codeResponse),
    onError: (error) => console.log('Login Failed:', error)
  });

  const handleGoogleRegister = (e, platform) => {
    e.preventDefault();

    setErrors({})
    setFirstName("")
    setLastName("")
    setEmail("")
    setPhone("")
    setPassword("")
    setConfirmPassword("")
    setConfirmPasswordImage(ViewIcon)
    setRole("")
    setToggler("password")
    setFirstToggle(true)
    setConfirmToggler("password")
    setFirstConfirmToggle(true)
    setPasswordImage(ViewIcon)
    //Dialog popop state
    if ((platform === 'google')) {
      setRegisterModal(true)
      setIcon(platform)
    }
  }



  return (
    <div className="login-bg">
      <div className="card-container">
        <ThemeProvider theme={theme}>
          <Grid container component="main" className="main-grid">
            <CssBaseline />

            <Grid
              className="overlay-image-register"
              style={{ backgroundColor: "#2c3e50" }}
              item
              xs={false}
              sm={false}
              md={6}
            >
              <Grid
                item
                //   md={6}
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
                className="header-grid"
              >
                <div class="left-logo ">
                  {/* <h1 class="welcome-header">Welcome to</h1> */}

                  <div class="logo-img">
                    <img class="login-img" src={Logo} />

                    <div class="main-heading right-title">
                      <h1>Screener</h1>
                      <p> Let's get started!</p>
                    </div>
                  </div>
                </div>
              </Grid>
              <div class="back-arrow">
                <RouterLink to="/">
                  <img src={BackArrow} />
                </RouterLink>
              </div>
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              component={Paper}
              elevation={6}
              square
              className="register-form"
            >
              <Box
                sx={{
                  // my: 3,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  minHeight: "85vh",

                  position: "relative",
                }}
                className="register-main-box"
              >
                <div class="register-app-heading">
                  <div class="right-back-arrow">
                    <a href="/">
                      {/* <img src={arrow} /> */}
                      <ArrowBackIcon />
                    </a>
                  </div>
                  <div class="container-logo">
                    <img class="screener-logo" src={Logo} />

                    <div class="main-heading main-title">
                      <h1>Screener</h1>
                    </div>
                  </div>
                </div>
                <div className="register-form-container">
                  {/* <form
                    noValidate

                    className="form-conatiner"
                  > */}
                  <div class="register-form-header">
                    <h1>Create Account</h1>
                  </div>
                  {/* <div class="social-card-container">
                <a href="#" class="social">
                <i class="fab fa-facebook-f"></i>
                </a>
                <a href="#" class="social">
                <i class="fab fa-google-plus-g"></i>
                </a>
                <a href="#" class="social">
                <i class="fab fa-linkedin-in"></i>
                </a>
                </div>
              <span>or use your account</span> */}
                  <div class="register-input-conatiner">


                    <div class="social-card-container">
                      {/* <a href="#" class="social">
                          <i class="fab fa-facebook-f"></i>
                        </a> */}
                      {/* <a href="#" class="social">
                           <i class="fab fa-google-plus-g"></i> 
                        <FcGoogle />Login
                      </a> */}
                      {/* <a href="#" class="social">
                          <i class="fab fa-linkedin-in"></i>
                        </a> */}
                      {/* <span>or use your account</span> */}
                      {/* <GoogleLogin
                          clientId="458868131518-q07k762hfd4dfkl09novdikuecv5qu8l.apps.googleusercontent.com"
                          disabled={false}
                          className="google-login-icon"
                          buttonText="Login"
                          onSuccess={responseGoogle}
                          onFailure={responseGoogle}
                          cookiePolicy={"single_host_origin"}
                        /> */}
                      <button className="google-login google-btn" onClick={(e) => { handleGoogleRegister(e, "google") }}>
                        <FcGoogle classname='google-icon' />Sign Up  </button>
                    </div>
                    <div className="divider-container">
                      <Divider style={{ width: "100px" }} />
                      <div className="circle"><p>Or</p></div>
                      <Divider style={{ width: "100px" }} />
                    </div>
                    <form
                      className="form-conatiner"

                      noValidate>
                      <div class="input-field" >
                        <img class="icon-size" src={UserIcon} />
                        <input
                          autoComplete="firstName"
                          id="firstName"
                          class="name"
                          type="text"
                          name="firstName"
                          // onClick={resetFieldname}
                          onChange={(e) => {
                            setFirstName(e.target.value);
                            setErrors({
                              firstName: "",
                            });
                            setIsPasswordFocussed(false);
                          }}
                          onKeyPress={(e) => isLetter(e)}
                          value={firstName}
                          //   error={isErrors.name.toString()}
                          //   className={classnames("name", {
                          //   invalid: isErrors.name,
                          // })}
                          placeholder="First Name*"
                        />
                      </div>
                      <div>
                        <span className="text-danger">{errors.firstName}</span>
                      </div>

                      <div class="input-field">
                        <img class="icon-size" src={UserIcon} />

                        <input
                          autoComplete="lastName"
                          id="lastName"
                          class="name"
                          type="text"
                          name="lastName"
                          //  onClick={resetFieldname}
                          onChange={(e) => {
                            setLastName(e.target.value);
                            setErrors({
                              lastName: "",
                            });
                            setIsPasswordFocussed(false);
                          }}
                          onKeyPress={(e) => isLetter(e)}
                          value={lastName}
                          //  error={isErrors.name.toString()}
                          //  className={classnames("name", {
                          //    invalid: isErrors.name,
                          //  })}
                          placeholder="Last Name*"
                        />
                      </div>
                      <div>
                        <span className="text-danger">{errors.lastName}</span>
                      </div>

                      <div class="input-field ">
                        <img class="field-icon" src={MailIcon} />

                        <input
                          class="email marginBottom "
                          autoComplete="email"
                          onChange={(e) => {
                            setEmail(e.target.value);
                            setErrors({
                              email: "",
                            });
                            setIsPasswordFocussed(false);
                          }}
                          value={email}
                          disabled={Email ? true : false}
                          // onClick={resetFieldEmail}
                          type="email"
                          //error={isErrors.email.toString()}
                          id="email"
                          name="email"
                          placeholder="Email Id*"
                        // className={classnames("email", {
                        //   invalid: isErrors.email,
                        // })}
                        />
                      </div>
                      <div>
                        <span className="text-danger">{errors.email}</span>
                      </div>
                      {/* <div class="input-field"> */}

                      <PhoneInput
                        id="phone"
                        placeholder="Contact No*"
                        name="phone"
                        class="phone"
                        country={"us"}
                        value={phone}
                        autoFormat={true}
                        onChange={(phone, country) => {
                          setPhone(phone);
                          setIsPasswordFocussed(false);
                          setErrors({ phone: "" });
                          var regex = phones[country.countryCode.toUpperCase()];
                          if (regex != undefined) {
                            if (!regex.test(phone)) {
                              setPhoneIsValid(false);
                              return setErrors({
                                phone: "Invalid Phone Number",
                              });
                            } else {
                              setPhoneIsValid(true);
                              return setErrors({ phone: "" });
                            }
                          }
                        }}
                      // error={isErrors.phone.toString()}
                      // className={classnames("phone", {
                      //   invalid: isErrors.phone})}
                      />
                      <div style={{ paddingTop: "7px" }}>
                        <span className="text-danger">{errors.phone}</span>
                      </div>
                      {/* </div> */}
                      <div class="input-field">
                        <img class="field-icon" src={LockIcon} />
                        <input
                          autoComplete="new-password"
                          onChange={(e) => {
                            setPassword(e.target.value);
                            setErrors({
                              password: "",
                            });
                            setIsPasswordFocussed(true);
                          }}
                          //onClick={resetFieldPassword}
                          value={password}
                          class="password "
                          //error={isErrors.password.toString()}
                          type={toggler}
                          id="password"
                          name="password"
                          placeholder="Password*"
                        // className={classnames("password", {
                        //   invalid: isErrors.password,
                        // })}
                        />

                        <img
                          class="view-icon"
                          src={passwordImage}
                          onClick={passwordHandler}
                        />
                      </div>
                      <div>
                        <span className="text-danger">{errors.password}</span>
                      </div>
                      {password && isPasswordFocussed ? (
                        <PasswordChecklist
                          rules={[
                            "minLength",
                            "specialChar",
                            "number",
                            "capital",
                            "lowercase",
                          ]}
                          style={{ display: `${passwordIsValid ? "none" : ""}` }}
                          minLength={8}
                          value={password}
                          iconSize={10}
                          className="password-checklist"
                          onChange={(isValid) => setPasswordIsValid(isValid)}
                          messages={{
                            minLength: "Minimum 8 characters",
                            specialChar: "Special characters",
                            number: "Numbers",
                            capital: "Uppercase letters",
                            lowercase: "Lowercase letters",
                          }}
                          invalidColor="#f06161"
                          validColor="#2e8b57"
                        />
                      ) : (
                        ""
                      )}

                      <div class="input-field">
                        <img class="field-icon" src={LockIcon} />
                        <input
                          autoComplete="new-password"
                          onChange={(e) => {
                            setConfirmPassword(e.target.value);
                            setErrors({
                              confirmPassword: "",
                            });
                            setIsPasswordFocussed(false);
                            // setRulesPassword([])
                          }}
                          // onClick={resetFieldconfirmPassword}
                          value={confirmPassword}
                          // error={isErrors.confirmPassword.toString()}
                          class="password"
                          id="confirmPassword"
                          type={confirmToggler}
                          name="confirmPassword"
                          placeholder="Re-Enter Password*"
                        // className={classnames("password", {
                        //   invalid: isErrors.confirmPassword,
                        // })}
                        />
                        <img
                          class="view-icon"
                          src={confirmPasswordImage}
                          onClick={ConfirmPasswordHandler}
                        />
                      </div>
                      <div>
                        <span className="text-danger">
                          {errors.confirmPassword}
                        </span>
                      </div>
                      <div class="register-radio-container">
                        <label className="radio-label">Register as*</label>

                        <FormControl class="register-radio-group">
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            // name="row-radio-buttons-group"
                            name="role"
                            id="role"
                            value={role}
                            onChange={(e) => {
                              setRole(e.target.value);
                              setErrors({
                                role: "",
                              });
                              setIsPasswordFocussed(false);
                            }}
                          >
                            <FormControlLabel
                              disabled={roleType ? true : false}
                              value="candidate"
                              control={
                                <Radio
                                  size="small"
                                  sx={{
                                    "& .MuiSvgIcon-root": {
                                      fontSize: 18,
                                    },
                                  }}
                                />
                              }
                              label="Job Seeker"
                            />
                            <FormControlLabel
                              value="screener"
                              disabled={roleType ? true : false}
                              control={
                                <Radio
                                  size="small"
                                  sx={{
                                    "& .MuiSvgIcon-root": {
                                      fontSize: 18,
                                    },
                                  }}
                                />
                              }
                              label="Screener"
                            />
                          </RadioGroup>
                        </FormControl>
                        <div>
                          <span className="text-danger">{errors.role}</span>
                        </div>
                      </div>
                      <div className="register-button-container">
                        <div className="target-link">
                          <RouterLink to="/company-registration">
                            <div className="link-left">
                              <p class="display-link">
                                Are you a Recruiting Company?
                              </p>
                            </div>
                          </RouterLink>
                          <RouterLink to="/login">
                            <div className="link-right">
                              <p class="display-link">
                                Already Registered? Login Now
                              </p>
                            </div>
                          </RouterLink>
                          <div class="clear"></div>
                        </div>
                        <button onClick={onSubmit} class="btn-register">Register</button>
                      </div>
                    </form>
                  </div>



                </div>
              </Box>
            </Grid>
          </Grid>
        </ThemeProvider>
      </div>

      {/* Snackbar */}
      {/* Snackbar  */}
      <UserSnackbar
        openSnackbar={openSnackbar}
        setOpenSnackbar={setOpenSnackbar}
        message={message}
      />
      <ConfirmationModal
        tempMail={tempMail}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      <RedirectModal
        tempMail={tempMail}
        show={redirectModal}
        onHide={() => setModalShow(false)}
      />

      <SocialRegisterModal
        icon={icon}
        show={registerModal}
        role={tempRole}
        setRole={setTempRole}
        socialMediaHandler={socialMediaHandler}
        onHide={() => {
          setTempRole('')
          setRegisterModal(false)
        }}
      />
    </div >
  );
};
export default RegisterComponent;
