import React from 'react'
import About from './About'
import Testimonial from './Testimonial'
import Footer from './Footer'
import Hero from './Hero'
import Navbar from './Navbar'
import Services from './Services'
import './landing.css'



function LandingPage() {

  localStorage.clear()

  return (
    <div>

      <Navbar />
      <Hero />
      <About />
      <Services />
      <Testimonial />
      <Footer />




    </div>
  );
}

export default LandingPage;