import { CardHeader } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React from "react";
import InterviewTable from "../tables/interviewTable";
import { Divider,Typography } from '@material-ui/core';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {  faFrown } from "@fortawesome/free-solid-svg-icons";

// import { Link } from "react-router-dom";

const useStyles = makeStyles({
    card: {
        background: "#FFFFFF 0% 0% no-repeat padding-box",
        border: "3px solid #A5D6A7",
        borderRadius: "10px",
        opacity: "1",
        padding: "20px",
    },
});

export default function SalesforceInterviews(props) {
    const classes = useStyles();
    let { interviews, updateRescheduleStatus, updateConfirmStatus ,updateCompleteStatus} = props;
    const screenerId = props.auth.user.id;


    // if (interviews.length <= 0) {
    if (props.interviews[0] === 'No alerts for this user') {
        return <div className="unfinPage-component"><Card className={classes.card}>
        <CardHeader align="center" title="Interviews" />
        <Divider/><br/>
        <Typography
                variant="subtitle2"                 
                style= {{color:"gray"}}
                align="center" 
        >There seems to be no Interviews assigned to you right now.. &nbsp;<FontAwesomeIcon icon={faFrown}></FontAwesomeIcon> </Typography>
        </Card>
        </div>
        // props.getInterviews(screenerId);
    }else{

    return (
        <div className="unfinPage-component">
            <Card className={classes.card}>
                <CardHeader align="center" title="Interviews" />
                <InterviewTable interviews={interviews} statusConfirm={updateConfirmStatus} statusReschedule={updateRescheduleStatus} statusComplete={updateCompleteStatus}  />
            </Card>
        </div>
    );
}
}

SalesforceInterviews.propTypes = {
    auth: PropTypes.object.isRequired,
    interviews: PropTypes.array,
};

