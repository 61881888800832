import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import NearMeOutlinedIcon from '@material-ui/icons/NearMeOutlined';
import React from "react";

const statusView = ({status, ifAction}) => {

    function whatColor(stage){
        if (stage === status && ifAction === true) {
            return (<div className="redBar"></div>)
        }

        switch (stage) {
            case 'Received':
                return status==='Received' || status==='Reviewed' ||  status==='Interview' ||status==='Offer' ||status==='Rejected' ?
                    (<div className="blueBar">  </div>) :
                    (<div className="bar">  </div>);
            case 'Reviewed':
                return status==='Reviewed' ||  status==='Interview' || status==='Offer' || status==='Rejected'?
                (<div className="blueBar">  </div>):
                (<div className="bar">  </div>);
            case 'Interview':
                return status==='Interview'||status==='Offer'||status==='Rejected'?
                (<div className="blueBar">  </div>):
                (<div className="bar">  </div>);
            case 'Offer':
                return status==='Offer'?
                (<div className="blueBar">  </div>):
                (<div className="bar">  </div>);
            case 'Rejected':
                return status==='Rejected'?
                (<div className="orangeBar">  </div>):
                (<div className="bar">  </div>);
            default:
                return (<div className="bar"></div>);
        }
    }

    const icon1=<EmailOutlinedIcon className="icon" />;
    const icon2=<CheckBoxOutlinedIcon className="icon"/>;
    const icon3=<NearMeOutlinedIcon className="icon"/>;
    const icon4=<CalendarTodayIcon className="icon"/>;

        return(
            <div className="flex-fill d-flex flex-row mt-3 justify-content-between">
                <div className='flex-fill d-flex flex-column align-items-center  '>
                    {icon1}
                    {whatColor('Received')}
                    <span className="iconText">Received</span>
                </div>

                <div className='flex-fill d-flex flex-column align-items-center '>
                    {icon2}
                    {whatColor('Reviewed')}
                    <span className="iconText">Reviewed</span>
                </div>

                <div className='flex-fill d-flex flex-column align-items-center '>
                    {icon3}
                    {whatColor('Interview')}
                    <span className="iconText">Interview</span>
                </div>

                <div className='flex-fill d-flex flex-column align-items-center '>
                    {icon4}
                    {whatColor('Offer')}
                    <span className="iconText">Offer</span>
                </div>

                <div className='flex-fill d-flex flex-column align-items-center '>
                    {icon4}
                    {whatColor('Rejected')}
                    <span className="iconText">Rejected</span>
                </div>
            </div>
        )
}



export default statusView;