import React, { useEffect, useState } from 'react'
// MUI imports
import { Dialog, Tooltip, DialogContent, Grid, TextField, CircularProgress } from "@material-ui/core";
import { Divider, Switch, FormGroup, FormControlLabel, Rating } from "@mui/material";
// import Checkbox from "@material-ui/core/Checkbox";
import Checkbox, { checkboxClasses } from "@mui/material/Checkbox";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
//MUI icons
import RefreshIcon from '@mui/icons-material/Refresh';
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import LinkIcon from '@mui/icons-material/Link';

import Zoom from "../images/zoom.svg";
import { CopyToClipboard } from 'react-copy-to-clipboard';


const hoursArray = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12']
const minutesArray = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36', '37', '38', '39', '40', '41', '42', '43', '44', '45', '46', '47', '48', '49', '50', '51', '52', '53', '54', '55', '56', '57', '58', '59']
const meridianArray = ['AM', "PM"]


const InterviewDialog = ({
    profileId,
    scheduledBy,
    profileType,
    scheduleInterview,
    handleCloseScheduleInterview,
    mode,
    interviewsFilter,
    interviewStatus,
    interviewTitle,
    handleChangeDialogFields,
    errors,
    clientDetails,
    handleClientData,
    setErrors,
    usersDBHandler,
    usersDBScreener,
    internalDBHandler,
    screenerDetails,
    setCandidateRating,
    setCandidateRatingsTitle,
    setCandidateReview,
    notifyDialog,
    setNotifyDialog,
    internalDBScreener,
    favScreenerFilter,
    candidateOptions,
    candidateName,
    setCandidateName,
    setCandidateDetails,
    clientOptions,
    clientName,
    positionOptions,
    positionName,
    handlePositionData,
    screenerOptions,
    screenerName,
    setScreenerName,
    setScreenerDetails,
    favDBHandler,
    usersDBCandidate,
    internalDBCandidate,
    favCandidateFilter,
    backupDate,
    interviewTime,
    timeSlots,
    timeZone,
    timeList,
    generateJitsiLink,
    generateLinkHandler,
    customLink,
    meetLinkRef,
    meetingPlatform,
    customLinkHandler,
    linkLoader,
    interviewLink,
    showFeedback,
    view,
    handleRatingsViewChange,
    ratingsView,
    candidateRating,
    candidateRatingsTitle,
    screenerRating,
    setScreenerRating,
    screenerRatingsTitle,
    screenerReview,
    screenerFeedback,
    markCompleted,
    setMarkCompleted,
    setDialogOpen,
    setOpenScheduleInterview,
    handleScheduleInterview,
    copyTextToClipboard,
    candidateReview,
    interviewTitleRef,
    clientRef,
    positionRef,
    screenerRef,
    candidateRef,
    setInterviewTime,
    hours,
    setHours,
    meridian,
    setMeridian,
    minutes,
    setMinutes,
    checkAllFieldsFilled
}) => {
    useEffect(() => {

        if (mode == 'edit') {
            let timeArray = interviewTime.split(':')
            const hour = timeArray[0];
            setHours(hour)
            const min = timeArray[1].slice(0, 2);
            setMinutes(min)
            const mer = timeArray[1].slice(3);

            setMeridian(mer)
        }
    }, [mode])

    ///notiify mail handler
    const notifyHandler = e => {
        e.preventDefault()
        const allFieldsFilled = checkAllFieldsFilled()
        if (!allFieldsFilled) return
        else {
            setNotifyDialog(true)
            setOpenScheduleInterview(false)
        }
    }

    const handleChangeTime = (e, field) => {
        if (field == 'hours') {
            setHours(e.target.value)
            setInterviewTime(`${e.target.value}:${minutes} ${meridian}`)
        } else if (field == 'minutes') {
            setMinutes(e.target.value)
            setInterviewTime(`${hours}:${e.target.value} ${meridian}`)
        } else {
            setMeridian(e.target.value)
            setInterviewTime(`${hours}:${minutes} ${e.target.value}`)
        }
    }

    console.log(scheduledBy, profileId, "check id")

    return (
        <Dialog
            open={scheduleInterview}
            onClose={handleCloseScheduleInterview}
            className="experience-edit-dialog"
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogContent className="experience-edit-dialog-content">
                <Grid container spacing={0} justifyContent="center">
                    <Grid
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        className="experience-edit-title-grid"
                    >
                        <div>
                            <div className="experience-edit-title">
                                {mode === "create" ? (
                                    <p>Schedule Interview</p>
                                ) : mode === "edit" && scheduledBy.id !== profileId ? (
                                    <p>View Interview</p>
                                ) : (
                                    <p>Edit Interview</p>
                                )}
                            </div>
                            <div className="experience-edit-close">
                                <Tooltip title="Close">
                                    <CloseIcon onClick={handleCloseScheduleInterview} />
                                </Tooltip>
                            </div>
                        </div>
                        <Divider className="experience-edit-top-divider" />
                    </Grid>
                    <Grid
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        className="experience-edit-right-grid"
                        style={{ overflowY: "unset" }}
                    >
                        <div className="experience-edit-form-container">
                            <form
                                className="experience-edit-form"
                                noValidate
                            >
                                <div class="input-container">
                                    <div class="input-field mobile-input-field">
                                        <label className="date-label">Interview Title</label>
                                        <input
                                            // disabled={mode === "edit" && interviewStatus === "Completed" || interviewsFilter !== "Scheduled by me" && profileType.toLowerCase() === 'admin'}
                                            disabled={interviewStatus == "Completed" || mode == "edit" && profileType.toLowerCase() === "admin" && scheduledBy.id !== profileId}
                                            style={{ height: "35px" }}
                                            class="position"
                                            ref={interviewTitleRef}
                                            className={`${errors.interviewTitle && "input-field-error"}`}
                                            type="text"
                                            value={interviewTitle}
                                            name="interviewTitle"
                                            placeholder="Ex: Software Developer-Round 1"
                                            onChange={handleChangeDialogFields}
                                        />
                                        <span className="text-danger">
                                            {errors.interviewTitle}
                                        </span>
                                    </div>
                                </div>

                                {/* If the filter is set to all interviews, if it's not scheduled by this profile and also the client details is empty then dont display Client Details field  */}
                                {interviewsFilter !== "Scheduled by me" && scheduledBy.id !== profileId && clientDetails.id == '' && mode !== 'create' ? <></> : <div class="input-container">
                                    <div class="input-field mobile-input-field">
                                        <label className="date-label" >Client Name</label>
                                        <Autocomplete
                                            // disabled={mode === "edit" && interviewStatus === "Completed" || interviewsFilter !== "Scheduled by me"}
                                            disabled={interviewStatus == "Completed" || mode == "edit" && profileType.toLowerCase() === "admin" && scheduledBy.id !== profileId}
                                            disablePortal
                                            disableClearable
                                            id="combo-box-demo"
                                            options={clientOptions}
                                            getOptionLabel={(option) => option?.label}
                                            sx={{ width: 300 }}
                                            inputValue={clientName}
                                            onChange={(event, newValue) => {
                                                handleClientData(event, newValue)
                                            }}
                                            renderInput={(params) => {
                                                return (
                                                    <TextField
                                                        ref={clientRef}
                                                        className={`${errors.client && "input-field-error-client"}`}
                                                        name="clientName"
                                                        style={{ width: "125%" }}
                                                        onChange={handleChangeDialogFields}
                                                        {...params}
                                                        placeholder="Select Client"
                                                    />
                                                );
                                            }}
                                        />
                                    </div>
                                    <span className="text-danger">
                                        {errors.client}
                                    </span>
                                </div>}

                                {clientDetails.id !== '' && <div class="input-container">
                                    <div class="input-field mobile-input-field">
                                        <label className="date-label">Position</label>
                                        <Autocomplete
                                            // disabled={mode === "edit" && interviewStatus === "Completed" || interviewsFilter !== "Scheduled by me"}
                                            disabled={interviewStatus == "Completed" || mode == "edit" && profileType.toLowerCase() === "admin" && scheduledBy.id !== profileId}
                                            disablePortal
                                            disableClearable
                                            id="combo-box-demo"
                                            options={positionOptions}
                                            getOptionLabel={(option) => option?.label}
                                            sx={{ width: 300 }}
                                            // style={{ display: interviewsFilter === "Scheduled by me" ? 'none' : '' }}
                                            inputValue={positionName}
                                            onChange={(event, newValue) => {
                                                handlePositionData(event, newValue)
                                            }}
                                            renderInput={(params) => {
                                                return (
                                                    <TextField
                                                        name="positionName"
                                                        style={{ width: "125%" }}
                                                        ref={positionRef}
                                                        className={`${errors.position && "input-field-error-position"}`}
                                                        onChange={handleChangeDialogFields}
                                                        {...params}
                                                        placeholder="Select Position"
                                                    />
                                                );
                                            }}
                                        />
                                    </div>
                                    <span className="text-danger">
                                        {errors.position}
                                    </span>
                                </div>}

                                <div class="input-container">
                                    <div class="input-field mobile-input-field">
                                        <label className="date-label">Screener Name</label>
                                        <Autocomplete
                                            // disabled={mode === "edit" && interviewStatus === "Completed" || interviewsFilter !== "Scheduled by me" && profileType.toLowerCase() === 'admin'}
                                            disabled={interviewStatus == "Completed" || mode == "edit" && profileType.toLowerCase() === "admin" && scheduledBy.id !== profileId}
                                            disablePortal
                                            disableClearable
                                            id="combo-box-demo"
                                            options={screenerOptions}
                                            getOptionLabel={(option) => option?.label}
                                            sx={{ width: 300 }}
                                            inputValue={screenerName}
                                            onChange={(event, newValue) => {
                                                setScreenerName(newValue?.name);
                                                setScreenerDetails(newValue?.value);
                                                setErrors({ screenerName: "" });
                                            }}
                                            renderInput={(params) => {
                                                return (
                                                    <TextField
                                                        ref={screenerRef}
                                                        className={`${errors.screenerName && "input-field-error-screener"}`}
                                                        name="screenerName"
                                                        style={{ width: "125%" }}
                                                        onChange={handleChangeDialogFields}
                                                        {...params}
                                                        placeholder="Select screener"
                                                    />
                                                );
                                            }}
                                        />
                                        <span className="text-danger">{errors.screenerName}</span>
                                    </div>
                                    {/* {(mode == 'create' || (mode === "edit" && interviewStatus !== "Completed" && interviewsFilter == "Scheduled by me")) && */}
                                    {(mode == 'create' || (interviewStatus !== "Completed" && mode == "edit" && scheduledBy.id == profileId)) &&
                                        <div style={{
                                            display: 'flex', flexFlow: "wrap", justifyContent: "space-between",

                                        }}>
                                            <FormGroup sx={{ width: 'fit-content' }}>
                                                <FormControlLabel
                                                    style={{ marginBottom: 0, height: 25 }}
                                                    className='toggle-label'
                                                    onChange={() => usersDBHandler("screener")}
                                                    value={usersDBScreener}
                                                    control={<Switch defaultChecked checked={usersDBScreener} />}
                                                    label="Infomatics Database"
                                                />
                                            </FormGroup>
                                            <FormGroup sx={{ width: 'fit-content' }}>
                                                <FormControlLabel
                                                    style={{ marginBottom: 0, height: 25 }}
                                                    className='toggle-label'
                                                    onChange={() => internalDBHandler("screener")}
                                                    value={internalDBScreener}
                                                    control={<Switch checked={internalDBScreener} />}
                                                    label="Infomatics database only"
                                                />
                                            </FormGroup>
                                            <FormGroup sx={{ width: 'fit-content' }}>
                                                <FormControlLabel
                                                    className='toggle-label'
                                                    onChange={() => favDBHandler("screener")}
                                                    value={favScreenerFilter}
                                                    control={<Switch checked={favScreenerFilter} />}
                                                    label="Select from favorites"
                                                    style={{ marginBottom: 0, height: 25 }}
                                                />
                                            </FormGroup>
                                        </div>}
                                </div>

                                <div class="input-container">
                                    <div class="input-field mobile-input-field">
                                        <label className="date-label">Job Seeker Name</label>

                                        <Autocomplete
                                            // disabled={mode === "edit" && interviewStatus === "Completed" || interviewsFilter !== "Scheduled by me" && profileType.toLowerCase() === 'admin'}
                                            disabled={interviewStatus == "Completed" || mode == "edit" && profileType.toLowerCase() === "admin" && scheduledBy.id !== profileId}
                                            disableClearable
                                            disablePortal
                                            id="combo-box-demo"
                                            options={candidateOptions}
                                            getOptionLabel={(option) => option?.label}
                                            sx={{ width: 300 }}
                                            inputValue={candidateName}
                                            onChange={(event, newValue) => {
                                                setCandidateName(newValue?.name);
                                                setCandidateDetails(newValue?.value);
                                                setErrors({ candidateName: "" });
                                            }}
                                            renderInput={(params) => {
                                                return (
                                                    <TextField
                                                        ref={candidateRef}
                                                        className={`${errors.candidateName && "input-field-error-candidate"}`}
                                                        name="candidateName"
                                                        style={{ width: "125%" }}
                                                        onChange={handleChangeDialogFields}
                                                        {...params}
                                                        placeholder="Select Job Seeker"
                                                    />
                                                );
                                            }}
                                        />
                                        <span className="text-danger">
                                            {errors.candidateName}
                                        </span>
                                    </div>
                                    {/* {(mode == 'create' || (mode === "edit" && interviewStatus !== "Completed" && interviewsFilter == "Scheduled by me")) &&  */}
                                    {(mode == 'create' || (interviewStatus !== "Completed" && mode == "edit" && scheduledBy.id == profileId)) &&
                                        <div style={{ display: 'flex', flexFlow: "wrap", justifyContent: "space-between" }}>
                                            <FormGroup sx={{ width: 'fit-content' }}>
                                                <FormControlLabel
                                                    style={{ marginBottom: 0, height: 25 }}
                                                    className='toggle-label'
                                                    onChange={() => usersDBHandler("candidate")}
                                                    value={usersDBCandidate}
                                                    control={<Switch defaultChecked checked={usersDBCandidate} />}
                                                    label="Infomatics Database"
                                                />
                                            </FormGroup>
                                            <FormGroup sx={{ width: 'fit-content' }}>
                                                <FormControlLabel
                                                    style={{ marginBottom: 0, height: 25 }}
                                                    className='toggle-label'
                                                    onChange={() => internalDBHandler("candidate")}
                                                    value={internalDBCandidate}
                                                    control={<Switch checked={internalDBCandidate} />}
                                                    label="Infomatics database only"
                                                />
                                            </FormGroup>
                                            <FormGroup sx={{ width: 'fit-content' }}>
                                                <FormControlLabel
                                                    className='toggle-label'
                                                    onChange={() => favDBHandler("candidate")}
                                                    value={favCandidateFilter}
                                                    control={<Switch checked={favCandidateFilter} />}
                                                    label="Select from favorites"
                                                    style={{ marginBottom: 0, height: 25 }}
                                                />
                                            </FormGroup>
                                        </div>}
                                </div>



                                <div >
                                    <div class="input-container" style={{ width: "100%" }}>
                                        <div class="input-field mobile-input-field">
                                            <label className="date-label" style={{ width: "100%" }}>Time</label>

                                            <div className='select-time'>
                                                <select
                                                    // disabled={mode !== "create" && (mode === "edit" && interviewStatus === "Completed" || interviewsFilter !== "Scheduled by me")}
                                                    disabled={interviewStatus == "Completed" || mode == "edit" && profileType.toLowerCase() === "admin" && scheduledBy.id !== profileId}
                                                    value={hours}
                                                    onChange={(e) => handleChangeTime(e, 'hours')}
                                                    style={{ outline: "none", height: 35 }}
                                                >
                                                    {hoursArray.map((time) => (
                                                        <option key={time} value={time}>
                                                            {time}
                                                        </option>
                                                    ))}
                                                </select>

                                                <select
                                                    // disabled={mode !== "create" && (mode === "edit" && interviewStatus === "Completed" || interviewsFilter !== "Scheduled by me")}
                                                    disabled={interviewStatus == "Completed" || mode == "edit" && profileType.toLowerCase() === "admin" && scheduledBy.id !== profileId}
                                                    value={minutes}
                                                    onChange={(e) => handleChangeTime(e, 'minutes')}
                                                    style={{ outline: "none", height: 35 }}
                                                >
                                                    {minutesArray.map((time) => (
                                                        <option key={time} value={time}>
                                                            {time}
                                                        </option>
                                                    ))}
                                                </select>
                                                <select
                                                    // disabled={mode !== "create" && (mode === "edit" && interviewStatus === "Completed" || interviewsFilter !== "Scheduled by me")}
                                                    disabled={interviewStatus == "Completed" || mode == "edit" && profileType.toLowerCase() === "admin" && scheduledBy.id !== profileId}
                                                    value={meridian}
                                                    onChange={(e) => handleChangeTime(e, 'meridian')}
                                                    style={{ outline: "none", height: 35 }}
                                                >
                                                    {meridianArray.map((time) => (
                                                        <option key={time} value={time}>
                                                            {time}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    <div style={{ display: 'flex' }} >
                                        <div class="input-container" style={{ width: '50%' }}>
                                            <div class="input-field mobile-input-field">
                                                <label className="date-label">Date</label>
                                                <input
                                                    // disabled={mode === "edit" && interviewStatus === "Completed" || interviewsFilter !== "Scheduled by me" && profileType.toLowerCase() === 'admin'}
                                                    disabled={interviewStatus == "Completed" || mode == "edit" && profileType.toLowerCase() === "admin" && scheduledBy.id !== profileId}
                                                    className={`${errors.interviewDate && "input-field-error"}`}
                                                    style={{ height: 35 }}
                                                    class="position"
                                                    type="date"
                                                    min={new Date().toISOString().split("T")[0]}
                                                    value={backupDate}
                                                    name="interviewDate"
                                                    onChange={handleChangeDialogFields}
                                                    placeholder="mm/dd/yyyy"
                                                />
                                                <span className="text-danger">
                                                    {errors.interviewDate}
                                                </span>
                                            </div>
                                        </div>
                                        <div class="input-container" style={{ width: "50%" }}>
                                            <div class="input-field mobile-input-field">
                                                <label className="date-label">Time Zone</label>
                                                <select
                                                    style={{
                                                        outline: "none",
                                                        marginLeft: "5px",
                                                        width: "99%",
                                                        height: 35,
                                                    }}
                                                    // disabled={mode === "edit" && interviewStatus === "Completed" || interviewsFilter !== "Scheduled by me" && profileType.toLowerCase() === 'admin'}
                                                    disabled={interviewStatus == "Completed" || mode == "edit" && profileType.toLowerCase() === "admin" && scheduledBy.id !== profileId}
                                                    value={timeZone}
                                                    name="timeZone"
                                                    onChange={handleChangeDialogFields}
                                                >
                                                    {timeList.map((time) => {
                                                        return (
                                                            <option value={time.abbr}>{time.value}</option>
                                                        );
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="input-container">
                                    <div
                                        style={{
                                            marginTop: 15,
                                            display: "flex",
                                            alignItems: "center",
                                            // margin: "15px 0 5px 0px"
                                        }}
                                    // className="add-new-row-button"
                                    >
                                        {(mode == 'create' || (mode === "edit" && interviewStatus !== "Completed" && interviewsFilter == "Scheduled by me")) ? <div class="input-field mobile-input-field ">
                                            <label className="date-label">Meeting Link</label>
                                            <div className='generate-link-container' >
                                                <div className={`generate-link-btn ${meetingPlatform == "Jitsi" ? "active" : ""}`} onClick={generateJitsiLink}>
                                                    Jitsi Link <LinkIcon className='meet-link-icon' />
                                                </div>

                                                <div className={`generate-link-btn ${meetingPlatform == "Zoom" ? "active" : ""}`} onClick={generateLinkHandler}>
                                                    Zoom Link <LinkIcon className='meet-link-icon' />
                                                </div>
                                                <div className={`generate-link-btn ${meetingPlatform == "Custom" ? "active" : ""}`} onClick={customLinkHandler}>
                                                    Custom Link <LinkIcon className='meet-link-icon' />
                                                </div>
                                            </div>
                                        </div> : (scheduledBy?.id == profileId && interviewStatus !== "Completed") ? <div class="input-field mobile-input-field ">
                                            <label className="date-label">Meeting Link</label>
                                            <div className='generate-link-container' >
                                                <div className={`generate-link-btn ${meetingPlatform == "Jitsi" ? "active" : ""}`} onClick={generateJitsiLink}>
                                                    Jitsi Link <LinkIcon className='meet-link-icon' />
                                                </div>

                                                <div className={`generate-link-btn ${meetingPlatform == "Zoom" ? "active" : ""}`} onClick={generateLinkHandler}>
                                                    Zoom Link <LinkIcon className='meet-link-icon' />
                                                </div>
                                                <div className={`generate-link-btn ${meetingPlatform == "Custom" ? "active" : ""}`} onClick={customLinkHandler}>
                                                    Custom Link <LinkIcon className='meet-link-icon' />
                                                </div>
                                            </div>
                                        </div> : ""}
                                    </div>

                                    {(interviewStatus == "Completed" && mode == "edit") && <label className="date-label">Meeting Link</label>}

                                    {(interviewLink != "" || customLink) && <div className="input-container filter-container" style={{ position: 'relative', background: "#eee" }}>
                                        <div class="input-field mobile-input-field" style={{ paddingTop: 0, paddingRight: "75px" }}>
                                            <input
                                                ref={meetLinkRef}
                                                style={{ height: "35px" }}
                                                class="position"
                                                type="text"
                                                className={`${errors.interviewLink && "input-field-error"}`}
                                                disabled={!customLink}
                                                value={interviewLink}
                                                name="interviewLink"
                                                onChange={handleChangeDialogFields}
                                            />
                                        </div>
                                        <div >
                                            <CopyToClipboard text={interviewLink} onCopy={copyTextToClipboard}>
                                                <ContentCopyIcon className="meet-link-icons" fontSize='small' sx={{ position: 'absolute', fontSize: '14px', cursor: 'pointer', top: '16px', right: '9px', fontSize: '15px', opacity: '50%' }} />
                                            </CopyToClipboard>
                                            {/* {!customLink && (mode == 'create' || (mode === "edit" && interviewStatus !== "Completed" && interviewsFilter == "Scheduled by me" && profileType.toLowerCase() !== 'admin')) && <RefreshIcon onClick={generateLinkHandler} className="meet-link-icons" fontSize='small' sx={{ position: 'absolute', fontSize: '14px', cursor: 'pointer', top: '14px', right: '9px', fontSize: '18px', opacity: '50%' }} />} */}
                                        </div>
                                    </div>}
                                    <span className="text-danger">{errors.interviewLink}</span>


                                    {(mode === "edit" && interviewStatus !== 'To Schedule') && (<div class="input-container">
                                        <div class="input-field mobile-input-field">
                                            <label className="date-label">Interview Status</label>
                                            <select
                                                style={{
                                                    outline: "none",
                                                    width: "100%",
                                                    height: 35,
                                                    padding: '0 0 0 10px'
                                                }}
                                                className={`${errors.interviewStatus && "input-field-error"}`}
                                                disabled={interviewStatus === "Completed" || scheduledBy.id != profileId}
                                                value={interviewStatus || "default"}
                                                name="interviewStatus"
                                                onChange={handleChangeDialogFields}
                                            >
                                                <option value="default" selected disabled>
                                                    Select Interview Status
                                                </option>
                                                <option value="To Schedule">To Schedule</option>
                                                <option value="Scheduled">Scheduled</option>
                                                <option value="Confirmed">Confirmed</option>
                                                <option value="Completed">Completed</option>
                                                <option value="Declined">Declined</option>
                                            </select>
                                            <span className="text-danger">
                                                {errors.interviewStatus}
                                            </span>
                                        </div>
                                    </div>
                                    )}

                                    {(showFeedback || screenerDetails.screenerType === "internal") && interviewStatus === "Completed" && mode !== "create" && <>
                                        <Divider style={{ margin: "15px 0" }} />
                                        <div
                                            style={{
                                                display: "flex",
                                                gap: "20px",
                                                justifyContent: "center",
                                            }}>
                                            <ToggleButtonGroup
                                                value={view}
                                                exclusive
                                                onChange={handleRatingsViewChange}
                                                aria-label="Platform"
                                            >
                                                <ToggleButton
                                                    value="candidate rating"
                                                    selected={ratingsView === "candidate rating"}
                                                    className={
                                                        ratingsView === "candidate rating"
                                                            ? "Mui-selected btn-ripple"
                                                            : "toggle-role-btn"
                                                    }
                                                    sx={{
                                                        background: "#5050502e",
                                                        color: "#505050",
                                                        padding: "3px 7px",
                                                        fontSize: "11px",
                                                        fontFamily: "Raleway",
                                                        fontWeight: "600",
                                                        textTransform: "none",
                                                        border: "none",
                                                    }}
                                                >
                                                    {scheduledBy.id === profileId && screenerDetails.screenerType === "internal" ? "Add Candidate Rating" : "View Candidate Rating"}
                                                </ToggleButton>
                                                <ToggleButton
                                                    selected={ratingsView === "screener rating"}
                                                    className={
                                                        ratingsView === "screener rating"
                                                            ? "Mui-selected "
                                                            : "toggle-role-btn"
                                                    }
                                                    value="screener rating"
                                                    sx={{
                                                        background: "#5050502e",
                                                        color: "#505050",
                                                        padding: "3px 7px",
                                                        fontSize: "11px",
                                                        fontWeight: "600",
                                                        border: "none",
                                                        fontFamily: "Raleway",
                                                        textTransform: "none",
                                                    }}
                                                >
                                                    {scheduledBy.id == profileId ? "Add Screener Rating" : "View Screener Rating"}
                                                </ToggleButton>
                                            </ToggleButtonGroup>
                                        </div>

                                        {ratingsView === "candidate rating" && <div class="input-container">
                                            <div class="input-field mobile-input-field screener-feedback">
                                                <label className="date-label">Candidate Rating</label>
                                                <Rating
                                                    value={candidateRating}
                                                    disabled={screenerDetails.screenerType !== "internal" || scheduledBy.id !== profileId}
                                                    onChange={(e) => setCandidateRating(e.target.value)}
                                                />
                                            </div>

                                            <div class="input-field mobile-input-field">
                                                <label className="date-label">Title</label>
                                                <input
                                                    style={{ height: "35px" }}
                                                    class="position"
                                                    type="text"
                                                    name="ratingsTitle"
                                                    value={candidateRatingsTitle}
                                                    disabled={screenerDetails.screenerType !== "internal" || scheduledBy.id !== profileId}
                                                    onChange={(e) => setCandidateRatingsTitle(e.target.value)}
                                                />
                                            </div>
                                            <div class="input-field mobile-input-field">
                                                <label className="date-label">Review</label>
                                                <textarea
                                                    disabled={screenerDetails.screenerType !== "internal" || scheduledBy.id !== profileId}
                                                    onChange={(e) => setCandidateReview(e.target.value)}
                                                    style={{ height: "35px", resize: 'none', fontSize: '12px', width: '100%', overflowY: "hidden" }}
                                                    class="position"
                                                    type="text"
                                                    name="ratingsReview"
                                                    value={candidateReview}
                                                />
                                            </div>
                                        </div>}

                                        {ratingsView === "screener rating" && <div class="input-container">
                                            <div class="input-field mobile-input-field screener-feedback">
                                                <label className="date-label">Screener Rating</label>
                                                <Rating
                                                    value={screenerRating}
                                                    disabled={scheduledBy.id !== profileId}
                                                    onChange={(event, newValue) => {
                                                        setScreenerRating(newValue);
                                                    }}
                                                />
                                                <span className="text-danger">
                                                    {errors.screenerRating}
                                                </span>
                                            </div>

                                            <div class="input-field mobile-input-field">
                                                <label className="date-label">Title</label>
                                                <input
                                                    disabled={mode === "edit" && scheduledBy.id !== profileId}
                                                    style={{ height: "35px" }}
                                                    class="position"
                                                    type="text"
                                                    name="ratingsTitle"
                                                    value={screenerRatingsTitle}
                                                    placeholder="Title"
                                                    onChange={handleChangeDialogFields}
                                                />
                                                <span className="text-danger">
                                                    {errors.screenerRatingsTitle}
                                                </span>
                                            </div>
                                            <div class="input-field mobile-input-field">
                                                <label className="date-label">Review</label>
                                                <textarea
                                                    disabled={mode === "edit" && scheduledBy.id !== profileId}
                                                    style={{ height: "35px", resize: 'none', fontSize: '12px', width: '100%', overflowY: "hidden" }}
                                                    class="position"
                                                    type="text"
                                                    name="ratingsReview"
                                                    value={screenerReview}
                                                    placeholder="Review"
                                                    onChange={handleChangeDialogFields}
                                                />
                                                <span className="text-danger">
                                                    {errors.screenerReview}
                                                </span>
                                            </div>

                                            {screenerFeedback !== true && <div className="input-field mobile-input-field">
                                                <FormGroup className="input-field-checkbox">
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                size="small"
                                                                sx={{
                                                                    [`&, &.${checkboxClasses.checked}`]: {
                                                                        color: '#f06161',
                                                                    },
                                                                }}
                                                                disabled={scheduledBy.id !== profileId}
                                                                checked={markCompleted}
                                                                value={markCompleted}
                                                                onClick={(e) => {
                                                                    setMarkCompleted(e.target.checked)
                                                                }}
                                                            />
                                                        }
                                                        label="Mark Interview as Completed"
                                                    />
                                                </FormGroup>
                                            </div>}
                                        </div>}
                                    </>}


                                    <div
                                        className={`button-container ${mode == "edit" ? "navigation-btn-container next-btn-container" : "next-btn-container"}`}
                                        style={{ justifyContent: mode == "edit" ? "space-between" : "flex-end" }}
                                    >
                                        {
                                            (mode === "edit" && scheduledBy.id == profileId) && <button
                                                className="outlined-btn btn-style outlined-btn-animation"
                                                onClick={(e) => {
                                                    e.preventDefault()
                                                    setDialogOpen(true)
                                                    setOpenScheduleInterview(false)
                                                }}
                                            >
                                                {" "}
                                                Delete
                                            </button>
                                        }

                                        {(mode == 'create' || (mode === "edit" && scheduledBy.id == profileId)) && <button
                                            className="filled-btn btn-style filled-btn-animation"
                                            type="submit"
                                            onClick={mode == 'create' || interviewStatus == 'To Schedule' || interviewStatus == 'Completed' ? (e) => handleScheduleInterview(e, true) : notifyHandler}
                                        >
                                            {" "}
                                            Save
                                        </button>}
                                    </div>
                                </div>
                            </form>
                        </div>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog >
    )
}

export default InterviewDialog