import React, { useEffect, useState } from "react";
import axios from "axios";

// external library import
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { Grid } from "@mui/material";
import Divider from "@mui/material/Divider";
import CloseIcon from "@mui/icons-material/Close";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Tooltip from "@mui/material/Tooltip";
import DeleteMessageIcon from "@mui/icons-material/ErrorOutlineOutlined";

import "./ExperienceEdit.css";
import SaveProfileSnackBar from "../snackBars/saveProfileSnackbar";
import ErrorMessageSnackbar from "../snackBars/errorMessageSnackbar";
import DeleteMessageSnackbar from "../snackBars/deleteMessageSnackbar";


//Education detail edit modal
const EducationEditModal = ({
  role,
  open,
  setOpenEducation,
  id,
  activeDialog,
  openEdit,
  setOpenEditEducation,
  editForm,
  education,
  edid,
  profileCompletion,
  educationArray,
  setEditForm,
  openDeleteEd,
  setOpenDeleteEd,
}) => {

  const minOffset = 0;
  const maxOffset = 50;
  const thisYear = new Date().getFullYear();
  let newData = {};
  const currentYear = thisYear;
  const [userRole, setUserRole] = useState(role);
  const [school, setSchool] = useState("");
  const [degree, setDegree] = useState("");
  const [stream, setStream] = useState("");
  const [startMonth, setStartMonth] = useState("-1");
  const [startYear, setStartYear] = useState(thisYear);
  const [endMonth, setEndMonth] = useState("-1");
  const [endYear, setEndYear] = useState(thisYear);
  const [checked, setChecked] = useState(false);
  const [currentWorking, setCurrentWorking] = useState(false);

  const [currentSchool, setCurrentSchool] = useState();
  const [currentDegree, setCurrentDegree] = useState();
  const [currentStream, setCurrentStream] = useState();
  const [currentStartMonth, setCurrentStartMonth] = useState();
  const [currentStartYear, setCurrentStartYear] = useState();
  const [currentEndMonth, setCurrentEndMonth] = useState();
  const [currentEndYear, setCurrentEndYear] = useState();
  const [currentChecked, setCurrentChecked] = useState();
  const [working, setWorking] = useState();

  const [openCloseDialog, setOpenCloseDialog] = useState(false);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
  const [openDeleteSnackbar, setOpenDeleteSnackbar] = useState(false);
  const [disableButton, setDisableButton] = useState(false);


  const [startMonths, setStartMonths] = useState([]);
  const [endMonths, setEndMonths] = useState([]);
  const currentMonth = new Date().getMonth();

  //disbaling months after the current month
  useEffect(() => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ];
    let inc = "0";
    const startMonthOptions = monthNames.map((month, index) => {
      if (index > 9) {
        inc = "";
      }
      const option = { value: inc + index, label: month };
      if (startYear == currentYear) {
        if (index > currentMonth) {
          option.disabled = true;
        } else {
          option.disabled = false;
        }
      }
      return option;
    });
    setStartMonths(startMonthOptions);

    inc = "0";
    const endMonthOptions = monthNames.map((month, index) => {
      if (index > 9) {
        inc = "";
      }
      const option = { value: inc + index, label: month };
      if (endYear == currentYear) {
        if (index > currentMonth) {
          option.disabled = true;
        } else {
          option.disabled = false;
        }
      }
      return option;
    });
    setEndMonths(endMonthOptions);
  }, [currentMonth, startYear, endYear]);

  //handle close confirmation dialog
  const onCloseDialog = () => {
    setSchool("");
    setDegree("");
    setStream("");
    setStartMonth("-1");
    setStartYear(thisYear);
    setEndMonth("-1");
    setEndYear(thisYear);
    setChecked(false);
    setCurrentWorking(false);

    setErrors({
      school: "",
      degree: "",
      stream: "",
      startMonth: "",
      startYear: "",
      endMonth: "",
      endYear: "",
    });
    setCurrentSchool(education.school);
    setCurrentDegree(education.degree);
    setCurrentStartMonth(education.startMonth);
    setCurrentStartYear(education.startYear);
    setCurrentEndMonth(education.endMonth);
    setCurrentEndYear(education.endYear);
    setCurrentStream(education.stream);
    setCurrentChecked(education.checked);
    setWorking(education.currentWorking);

    setOpenCloseDialog(false);
    setOpenEducation(false);
    setOpenEditEducation(false);
    setEditForm(false);
  };

  const onCloseCancel = () => {
    setOpenCloseDialog(false);
    activeDialog === "Add"
      ? setOpenEducation(true)
      : setOpenEditEducation(true);
  };
  useEffect(() => {
    setCurrentSchool(education.school);
    setCurrentDegree(education.degree);
    setCurrentStartMonth(education.startMonth);
    setCurrentStartYear(education.startYear);
    setCurrentEndMonth(education.endMonth);
    setCurrentEndYear(education.endYear);
    setCurrentStream(education.stream);
    setCurrentChecked(education.checked);
    setWorking(education.currentWorking);
  }, [editForm]);

  // initialization of errors
  const [errors, setErrors] = useState({
    school: "",
    degree: "",
    stream: "",
    startMonth: "",
    startYear: "",
    endMonth: "",
    endYear: "",
  });

  // educationHandler
  const schoolHandler = (e) => {
    setSchool(e.target.value);
    setErrors({ school: "" });
  };

  const degreeHandler = (e) => {
    setDegree(e.target.value);
    setErrors({ degree: "" });
  };
  const streamHandler = (e) => {
    setStream(e.target.value);
    setErrors({ stream: "" });
  };

  const startMonthHandler = (e) => {
    setStartMonth(e.target.value);
    setErrors({ startMonth: "" });
  };

  const startYearHandler = (e) => {
    setStartYear(e.target.value);
    setErrors({ startYear: "" });
  };
  const endMonthHandler = (e) => {
    setEndMonth(e.target.value);
    setErrors({ endMonth: "" });
  };
  const endYearHandler = (e) => {
    setEndYear(e.target.value);
    setErrors({ endYear: "" });
  };
  const currentSchoolHandler = (e) => {
    setCurrentSchool(e.target.value);
    setErrors({ school: "" });
  };
  const currentDegreeHandler = (e) => {
    setCurrentDegree(e.target.value);
    setErrors({ degree: "" });
  };
  const currentStreamHandler = (e) => {
    setCurrentStream(e.target.value);
    setErrors({ stream: "" });
  };

  const currentStartYearHandler = (e) => {
    setCurrentStartYear(e.target.value);
    setErrors({ startYear: "" });
  };

  const currentStartMonthHandler = (e) => {
    setCurrentStartMonth(e.target.value);
    setErrors({ startMonth: "" });
  };
  const currentEndMonthHandler = (e) => {
    setCurrentEndMonth(e.target.value);
    setErrors({ endMonth: "" });
  };
  const currentEndYearHandler = (e) => {
    setCurrentEndYear(e.target.value);
    setErrors({ endYear: "" });
  };
  const options = [];

  for (let i = minOffset; i <= maxOffset; i++) {
    const year = currentYear - i;
    options.push(<option value={year}>{year}</option>);
  }

  const handleCloseEducation = () => {
    setOpenEducation(false);
    setOpenEditEducation(false);
    setOpenCloseDialog(true);
  };

  const checkHandler = () => {
    setChecked((prevalue) => !prevalue);
    setCurrentWorking(!currentWorking);

    if (!checked) setErrors({ endMonth: "" });
  };

  const currentCheckHandler = () => {
    setCurrentChecked((prevalue) => !prevalue);
    setWorking(!working);
    setCurrentEndMonth("00");
    if (!currentChecked) {
      setErrors({ endMonth: "" });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // checking if input is empty
    if (school === "") {
      return setErrors({
        school: "School is required",
      });
    }
    if (degree === "") {
      return setErrors({
        degree: "Degree is required",
      });
    }

    if (stream === "") {
      return setErrors({
        stream: "Stream is required",
      });
    }

    if (startMonth == "-1") {
      return setErrors({
        startMonth: "Start date is required",
      });
    }

    if (endMonth == "-1" && checked === false) {
      return setErrors({
        endMonth: "End date is required",
      });
    }
    if (
      (startYear > endYear && checked === false) ||
      (endYear == startYear && endMonth < startMonth && checked === false)
    ) {
      return setErrors({
        endMonth: "End date cannot be less than start date",
      });
    }
    if (educationArray.length === 0) {
      profileCompletion = profileCompletion + 20;
    }
    setDisableButton(true);

    if (checked === true) {
      newData = {
        school,
        degree,
        stream,
        startMonth,
        checked,
        currentWorking,
        startYear,
        endMonth: "Present",
        endYear: currentYear,
        profileCompletion,
      };
    } else {
      newData = {
        school,
        degree,
        stream,
        startMonth,
        startYear,
        endMonth,
        endYear,
        checked,
        currentWorking,
        profileCompletion,
      };
    }

    //post to bakcend
    axios
      .post(`/api/${userRole}/profile-education/${id}`, { newData })
      .then((res) => {
        setSchool("");
        setDegree("");
        setStream("");
        setStartMonth("-1");
        setStartYear(thisYear);
        setEndMonth("-1");
        setOpenSnackbar(true);
        setEndYear(thisYear);
        setChecked(false);
        setCurrentWorking(false);
        setOpenEducation(false);
        setDisableButton(false);

      })
      .catch((err) => {
        console.log(err);
        setOpenErrorSnackbar(true);
        setDisableButton(false);

      });
  };

  //Update

  const handleUpdate = (e) => {
    e.preventDefault();

    if (currentSchool === "") {
      return setErrors({
        school: "School is required",
      });
    }
    if (currentDegree === "") {
      return setErrors({
        degree: "Degree is required",
      });
    }
    if (currentStream === "") {
      return setErrors({
        stream: "Stream is required",
      });
    }
    if (currentStartMonth == "0") {
      return setErrors({
        startMonth: "Start date is required",
      });
    }

    if (currentEndMonth == "0" && currentChecked === false) {
      return setErrors({
        endMonth: "End date is required",
      });
    }

    if (
      (currentStartYear > currentEndYear && currentChecked === false) ||
      (currentEndYear == currentStartYear &&
        currentEndMonth < currentStartMonth &&
        currentChecked === false)
    ) {
      return setErrors({
        endMonth: "End date cannot be less than start date",
      });
    }
    if (currentChecked === true) {
      newData = {
        edid,
        currentSchool,
        currentDegree,
        currentStream,
        currentStartMonth,
        currentStartYear,
        currentEndMonth: "Present",
        currentChecked,
        working,
        currentEndYear: currentYear,
      };
    } else {
      newData = {
        edid,
        currentSchool,
        currentDegree,
        currentStream,
        currentStartMonth,
        currentStartYear,
        currentEndMonth,
        currentChecked,
        working,
        currentEndYear,
      };
    }

    axios
      .post(`/api/${userRole}/profile-education/edit/${id}`, { newData })
      .then((res) => {
        setEditForm(false);
        setOpenSnackbar(true);
        setOpenEditEducation(false);
      })
      .catch((err) => {
        console.log(err);
        setOpenErrorSnackbar(true);
      });
  };

  const handleDelete = (e) => {
    e.preventDefault();
    setOpenEditEducation(false);
    setOpenDeleteEd(true);
  };

  //Delete
  const onHide = () => {
    setOpenDeleteEd(false);
    setOpenEditEducation(true);
  };

  const onDeleteConfirm = (e) => {
    e.preventDefault();
    if (educationArray.length === 1) {
      profileCompletion = profileCompletion - 20;
    }
    axios
      .post(`/api/${userRole}/profile-education/delete/${id}`, { edid, profileCompletion })
      .then((res) => {
        setEditForm(false);
        setOpenDeleteEd(false);
      })
      .catch((err) => {
        console.log(err);
        setOpenErrorSnackbar(true);
      });
  };

  return (
    <div>
      {/* Add Experience Dialogue */}
      <Dialog
        PaperProps={{ sx: { position: "fixed", m: 0 } }}
        open={open}
        onClose={handleCloseEducation}
        className="experience-edit-dialog"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent className="experience-edit-dialog-content">
          <Grid container spacing={0} justifyContent="center">
            <Grid
              xs={12}
              sm={12}
              md={12}
              lg={12}
              className="experience-edit-title-grid"
            >
              <div>
                <div className="experience-edit-title">
                  <p>Education</p>
                </div>
                <div className="experience-edit-close">
                  <Tooltip title="Close">
                    <CloseIcon onClick={handleCloseEducation} />
                  </Tooltip>
                </div>
              </div>
              <Divider className="experience-edit-top-divider" />
            </Grid>

            <Grid
              xs={12}
              sm={12}
              md={12}
              lg={12}
              className="experience-edit-right-grid"
            >
              <div className="experience-edit-form-container">
                <form
                  className="experience-edit-form"
                  noValidate
                  onSubmit={handleSubmit}
                >
                  <div class="input-container">
                    <div class="input-field">
                      <label className="date-label">School*</label>
                      <input
                        class="position"
                        type="text"
                        value={school}
                        onChange={schoolHandler}
                        placeholder="Ex: Boston University"
                      />
                      <span className="text-danger">{errors.school}</span>
                    </div>
                    <div class="input-field">
                      <label className="date-label">Degree*</label>
                      <input
                        class="company"
                        type="text"
                        value={degree}
                        onChange={degreeHandler}
                        placeholder="Ex: Master's"
                      />
                      <span className="text-danger">{errors.degree}</span>
                    </div>
                    <div class="input-field">
                      <label className="date-label">Field of Study*</label>
                      <input
                        class="company"
                        type="text"
                        value={stream}
                        onChange={streamHandler}
                        placeholder="Ex: Computer Science"
                      />
                      <span className="text-danger">{errors.stream}</span>
                    </div>

                    <div className="date-dropdown-picker">
                      <div class="input-field">
                        <label className="date-label">Start Date*</label>
                        <select value={startMonth} onChange={startMonthHandler}>
                          <option value="-1" selected disabled hidden>
                            Month*
                          </option>
                          {startMonths.map((month) => (
                            <option
                              key={month.value}
                              value={month.value}
                              disabled={month.disabled}
                            >
                              {month.label}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div class="input-field">
                        <select value={startYear} onChange={startYearHandler}>
                          {options}
                        </select>
                      </div>
                    </div>
                    <span className="text-danger">{errors.startMonth}</span>
                    <div className="input-field">
                      <FormGroup className="input-field-checkbox">
                        <FormControlLabel
                          control={
                            <Checkbox
                              sx={{
                                color: "gray",
                                "&.Mui-checked": {
                                  color: "#F06161",
                                },
                              }}
                              checked={checked}
                              value={checked}
                              onClick={checkHandler}
                            />
                          }
                          label="Currently Studying here"
                        />
                      </FormGroup>
                    </div>
                    <div className="date-dropdown-picker">
                      <div class="input-field">
                        <label className="date-label">End Date*</label>
                        <select
                          disabled={currentWorking}
                          value={endMonth}
                          onChange={endMonthHandler}
                        >
                          <option value="-1" selected disabled hidden>
                            Month*
                          </option>
                          {endMonths.map((month) => (
                            <option
                              key={month.value}
                              value={month.value}
                              disabled={month.disabled}
                            >
                              {month.label}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div class="input-field">
                        <select
                          disabled={currentWorking}
                          value={endYear}
                          onChange={endYearHandler}
                        >
                          {options}
                        </select>
                      </div>
                    </div>
                    <span className="text-danger">{errors.endMonth}</span>
                  </div>
                  <div className="single-button-container">
                    <button
                      className="btn-style filled-btn filled-btn-animation"
                      type="submit"
                      disabled={disableButton}
                    >
                      {" "}
                      Add
                    </button>
                  </div>
                </form>
              </div>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      {/* Edit Experience dialog*/}

      <Dialog
        open={openEdit}
        onClose={handleCloseEducation}
        className="experience-edit-dialog"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent className="experience-edit-dialog-content">
          <Grid container spacing={0} justifyContent="center">
            <Grid
              xs={12}
              sm={12}
              md={12}
              lg={12}
              className="experience-edit-title-grid"
            >
              <div>
                <div className="experience-edit-title">
                  <p>Education</p>
                </div>
                <div className="experience-edit-close">
                  <Tooltip title="Close">
                    <CloseIcon onClick={handleCloseEducation} />
                  </Tooltip>
                </div>
              </div>
              <Divider className="experience-edit-top-divider" />
            </Grid>

            <Grid
              xs={12}
              sm={12}
              md={12}
              lg={12}
              className="experience-edit-right-grid"
            >
              <div className="experience-edit-form-container">
                <form
                  className="experience-edit-form"
                  noValidate
                  onSubmit={handleUpdate}
                >
                  <div class="input-container">
                    <div class="input-field">
                      <label className="date-label">School*</label>
                      <input
                        class="position"
                        type="text"
                        value={currentSchool}
                        onChange={currentSchoolHandler}
                        placeholder="Ex: University/School Name"
                      />
                      <span className="text-danger">{errors.school}</span>
                    </div>
                    <div class="input-field">
                      <label className="date-label">Degree*</label>
                      <input
                        class="company"
                        type="text"
                        value={currentDegree}
                        onChange={currentDegreeHandler}
                        placeholder="Ex: Master's"
                      />
                      <span className="text-danger">{errors.degree}</span>
                    </div>
                    <div class="input-field">
                      <label className="date-label">Stream*</label>
                      <input
                        class="company"
                        type="text"
                        value={currentStream}
                        onChange={currentStreamHandler}
                        placeholder="Ex: Computer Science"
                      />
                      <span className="text-danger">{errors.stream}</span>
                    </div>

                    <div className="date-dropdown-picker">
                      <div class="input-field">
                        <label className="date-label">Start Date*</label>
                        <select
                          value={currentStartMonth}
                          onChange={currentStartMonthHandler}
                        >
                          <option value="-1" selected disabled hidden>
                            Month*
                          </option>
                          {startMonths.map((month) => (
                            <option
                              key={month.value}
                              value={month.value}
                              disabled={month.disabled}
                            >
                              {month.label}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div class="input-field">
                        <select
                          value={currentStartYear}
                          onChange={currentStartYearHandler}
                        >
                          {options}
                        </select>
                      </div>
                    </div>
                    <span className="text-danger">{errors.startMonth}</span>
                    <div className="input-field">
                      <FormGroup className="input-field-checkbox">
                        <FormControlLabel
                          control={
                            <Checkbox
                              sx={{
                                color: "gray",
                                "&.Mui-checked": {
                                  color: "#F06161",
                                },
                              }}
                              checked={currentChecked}
                              value={currentChecked}
                              onClick={currentCheckHandler}
                            />
                          }
                          label="Currently Studying here"
                        />
                      </FormGroup>
                    </div>
                    <div className="date-dropdown-picker">
                      <div class="input-field">
                        <label className="date-label">End Date*</label>
                        <select
                          disabled={working}
                          value={currentEndMonth}
                          onChange={currentEndMonthHandler}
                        >
                          <option value="-1" selected disabled hidden>
                            Month*
                          </option>
                          {endMonths.map((month) => (
                            <option
                              key={month.value}
                              value={month.value}
                              disabled={month.disabled}
                            >
                              {month.label}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div class="input-field">
                        <select
                          disabled={working}
                          value={currentEndYear}
                          onChange={currentEndYearHandler}
                        >
                          {options}
                        </select>
                      </div>
                    </div>
                    <span className="text-danger">{errors.endMonth}</span>
                  </div>
                  <div className="button-container edit-btn-container">
                    <button
                      className="btn-style outlined-btn outlined-btn-animation"
                      type="button"
                      onClick={handleDelete}
                    >
                      {" "}
                      Remove
                    </button>
                    <button
                      className="btn-style filled-btn filled-btn-animation"
                      type="submit"
                    >
                      {" "}
                      Save
                    </button>
                  </div>
                </form>
              </div>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      {/* Delete experience Popup dialog */}

      <Dialog
        open={openDeleteEd}
        onClose={openDeleteEd}
        className="experience-edit-dialog"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent className="dialog-content">
          <Grid container spacing={0} justifyContent="center">
            <Grid
              xs={11.5}
              sm={11.5}
              md={11.5}
              lg={11.5}
              className="experience-delete-grid"
            >
              <div className="delete-message-icon">
                <DeleteMessageIcon fontSize="small" />
              </div>
              <div className="delete-dialog-content">
                <h5>Are you sure ?</h5>
                <p>
                  Are you sure you want to delete the current education details?
                </p>
                <p>This process cannot be reverted</p>
              </div>
            </Grid>
          </Grid>
        </DialogContent>
        <div className="button-container delete-btn-container">
          <button
            className="warning-btn-style filled-btn filled-btn-animation"
            onClick={onHide}
          >
            {" "}
            Cancel
          </button>
          <button
            className="warning-btn-style outlined-btn outlined-btn-animation"
            onClick={onDeleteConfirm}
          >
            {" "}
            Confirm
          </button>
        </div>
      </Dialog>

      {/* Close dialog confimation dialog */}
      <Dialog
        fullWidth
        maxWidth="xs"
        open={openCloseDialog}
        onClose={handleCloseEducation}
        className="experience-edit-dialog"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent className="dialog-content">
          <Grid container spacing={0} justifyContent="center">
            <Grid
              xs={11.5}
              sm={11.5}
              md={11.5}
              lg={11.5}
              className="experience-delete-grid"
            >
              <div className="delete-message-icon">
                <DeleteMessageIcon fontSize="small" />
              </div>
              <div className="delete-dialog-content">
                <h5>Are you sure ?</h5>
                <p>Are you sure you want to go back?.</p>
                <p>All your current changes will be lost!</p>
              </div>
            </Grid>
          </Grid>
        </DialogContent>
        <div className="button-container delete-btn-container">
          <button
            className="warning-btn-style filled-btn filled-btn-animation"
            onClick={onCloseCancel}
          >
            {" "}
            Cancel
          </button>
          <button
            className="warning-btn-style outlined-btn outlined-btn-animation"
            onClick={onCloseDialog}
          >
            {" "}
            Confirm
          </button>
        </div>
      </Dialog>

      <SaveProfileSnackBar
        openSnackbar={openSnackbar}
        setOpenSnackbar={setOpenSnackbar}
      />
      <ErrorMessageSnackbar
        openErrorSnackbar={openErrorSnackbar}
        setOpenErrorSnackbar={setOpenErrorSnackbar}
      />
      <DeleteMessageSnackbar
        openDeleteSnackbar={openDeleteSnackbar}
        setOpenDeleteSnackbar={setOpenDeleteSnackbar}
      />
    </div>
  );
};

export default EducationEditModal;
