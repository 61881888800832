import withStyles from "@material-ui/core/styles/withStyles";
import Rating from "material-ui-rating";
import React, { Component } from "react";

const styles = (theme) => ({
    ratingWrapper: {
        padding: "16px",
        height: "70px",
    },
    iconButton: {
        padding: "17px",
        width: "100px",
    },
});

class CandidateRating extends Component {
    render() {
        const { classes, name, input, onRatingChange, rating } = this.props;

        return (
            <div className={classes.ratingWrapper}>
                <Rating
                    name={name}
                    precision={0.5}
                    value={rating}
                    max={5}
                    size="large"
                    onChange={onRatingChange(input)}
                    classes={{ iconButton: classes.iconButton }}
                />
            </div>
        );
    }
}

export default withStyles(styles)(CandidateRating);
