import React from "react";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";

const deleteMessageSnackbar = ({
  openDeleteSnackbar,
  setOpenDeleteSnackbar,
}) => {
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenDeleteSnackbar(false);
  };

  return (
    <div>
      <Snackbar
        open={openDeleteSnackbar}
        autoHideDuration={4000}
        onClose={handleClose}
        message="Deleted Successfully"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        action={
          <React.Fragment>
            <Button
              style={{ textTransform: "capitalize", color: "#f06161" }}
              onClick={handleClose}
            >
              Close
            </Button>
          </React.Fragment>
        }
      />
    </div>
  );
};

export default deleteMessageSnackbar;
