import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import withStyles from "@material-ui/core/styles/withStyles";
import axios from "axios";
import { withSnackbar } from "notistack";
import PropTypes from "prop-types";
import React, { Component } from "react";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { connect } from "react-redux";
import { receiveAllAvailabilities, sendConfirmedAvailabilities } from "../../actions/availability";
import AvailabilityCalendar from "../calendar/availabilityCalendar";
import TimeSlotModal from "../calendar/timeSlotModal";

const styles = (theme) => ({
    main: {
        width: "auto",
        display: "block", // Fix IE 11 issue.
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(400 + theme.spacing(6))]: {
            width: "100%",
            marginLeft: "auto",
            marginRight: "auto",
        },
    },
    card_calendar: {
        border: "3px solid #A5D6A7",
        opacity: "1",
        background: "#FFFFFF 0% 0% no-repeat padding-box",
        minHeight: "810px",
        borderRadius: "10px",
        padding: "20px",
    },
    card: {
        margin: "auto",
        height: "auto",
        width: "auto",
    },
    calendar: {
        margin: "auto",
        height: "100%",
        width: "150%",
    },
    title: {
        color: "#212121",
        textAlign: "center",
        letterSpacing: "0px",
        font: "regular 32px",
        fontWeight: "500",
    },
    button: {
        padding: theme.spacing(2),
        margin: "auto",
        width: "100%",
        align: "center",
    },
    active: {
        backgroundColor: "#4ed657",
        padding: "10px",
        borderRadius: "5px",
    },
    inactive: {
        padding: "10px",
        borderRadius: "5px",
    },
    container: {
        display: "flex",
        flexDirection: "row",
        borderRadius: "10px",
        border: "2px solid #A5D6A7",
        width: "219px",
        marginBottom: "6px",
    },
});

class availableScreener extends Component {
    constructor(props) {
        super(props);

        this.state = {
            modalIsOpen: false,
            viewState: "all",
        };

        this.openModal = this.openModal.bind(this);
        this.afterOpenModal = this.afterOpenModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.sendToSalesforce = this.sendToSalesforce.bind(this);
        // this.handleClick = this.handleClick.bind(this);
    }

    componentDidMount() {
        let availabilityMessage = "please create Availabilities, so Recruiters can match you to Interviews";

        this.props.enqueueSnackbar(availabilityMessage, {
            variant: "info",
            autoHideDuration: 3000,
        });
    }

    onSubmit = (e) => {
        e.preventDefault();
        // notify user that we are saving
        let preNotifMessage = "Please wait while we send your availabilities to salesforce";
        this.props.enqueueSnackbar(preNotifMessage, {
            variant: "default",
            autoHideDuration: 1000,
        });

        this.props.sendConfirmedAvailabilities(this.props.auth.user.id, this.props.availabilities, this.props.enqueueSnackbar);
    };

    // handleClick = e => {
    //   debugger;
    //   e.preventDefault();
    //   let innerText = e.currentTarget.innerText;
    //   this.setState({ viewState: innerText.toLowerCase() });
    // }

    // convert this into an action that has a loading screen and confirmation modal.
    sendToSalesforce = () => {
        axios
            .get("/salesforce/availability/" + this.props.auth.user.id)
            .then(() => (window.location = "/home"))
            .catch((err) => console.log(err));
    };

    // Modal capability for deletion of eventa
    openModal() {
        this.setState({ modalIsOpen: true });
    }
    closeModal() {
        this.setState({ modalIsOpen: false });
    }
    afterOpenModal() {
        // references are now sync'd and can be accessed.
        this.subtitle.style.color = "#f00";
    }

    render() {
        const { classes } = this.props;

        const customStyles = {
            content: {
                top: "50%",
                left: "50%",
                right: "auto",
                bottom: "auto",
                marginRight: "-50%",
                transform: "translate(-50%, -50%)",
            },
        };
        const calendarStyle = { height: "600px" };

        return (
            <div className="dashboard-component">
                {/* <Toggle handleClick={() => this.handleClick} currentOption={viewState} classes={cla0sses} /> */}

                <Card className={classes.card_calendar}>
                    <div className="d-flex flex-column justify-content-center">
                        <CardHeader align="center" className="flex-fill" classes={{ title: classes.title }} title="Availability" />
                        <form className="flex-fill" noValidate onSubmit={this.onSubmit}>
                            <AvailabilityCalendar
                                selectable="ignoreEvents"
                                popup={false}
                                hourStep={30}
                                defaultView="week"
                                calendarStyle={calendarStyle}
                                user={this.props.auth.user}
                                views={["month", "week", "day"]}
                            />
                            &nbsp;
                            <Button type="submit" fullWidth variant="contained" color="secondary" className={classes.button}>
                                Confirm availability
                            </Button>
                            &nbsp;
                        </form>
                    </div>
                </Card>

                <TimeSlotModal
                    isOpen={this.state.modalIsOpen}
                    onAfterOpen={this.afterOpenModal}
                    onRequestClose={this.closeModal}
                    style={customStyles}
                    contentLabel="Example TimeSlotModal"
                />
            </div>
        );
    }
}

availableScreener.propTypes = {
    sendConfirmedAvailabilities: PropTypes.func.isRequired,
    receiveAllAvailabilities: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    availabilities: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    availabilities: state.availabilities,
});

export default withSnackbar(
    withStyles(styles)(connect(mapStateToProps, { receiveAllAvailabilities, sendConfirmedAvailabilities })(availableScreener))
);
