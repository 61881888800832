import { makeStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { Rating } from "@material-ui/lab";
import React from "react";

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        "&:nth-of-type(odd)": {
            backgroundColor: theme.palette.primary.main,
        },
    },
}))(TableRow);

const useRowStyles = makeStyles({
    root: {
        "& > *": {
            borderBottom: "unset",
        },
    },
    confirm: {
        backgroundColor: "#9DCD95",
        marginLeft: "30px",
    },
    review: {
        marginLeft: "30px",
        color: "#75A478",
    },
});

export default function ReviewRows(props) {
    const { row, columns } = props;
    const classes = useRowStyles();

    // console.log(row);
    return (
        <>
            <StyledTableRow className={classes.root} hover role="checkbox" tabIndex={-1} key={row["salesforceReviewId"]}>
                {columns.slice(1).map((column) => {
                    const value = row[column.id];
                    // console.log(value);
                    if (column.id === "rating") {
                        return (
                            <StyledTableCell key={column.id} align="left" component="th" scope="row">
                                <Rating name="Performance Rating" readOnly value={value} max={5} precision={0.1} />
                            </StyledTableCell>
                        );
                    } else  {
                        return (
                            <StyledTableCell key={column.id} align="left">
                                {value}
                            </StyledTableCell>
                        );
                    }
                })}
            </StyledTableRow>
        </>
    );
}
