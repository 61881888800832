import React, { useEffect, useState } from "react";

//external imports
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
//css imports
import Logo from "../../images/emailimg.jpg";
import "../auth/LoginComponent.css";
import CircularProgress from '@mui/material/CircularProgress';
//Modal to show email verification message
const RedirectModal = (props) => {

    const [progress, setProgress] = React.useState(0);

    useEffect(() => {
        const timer = setInterval(() => {
            setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 50));
        }, 1000);

        return () => {
            clearInterval(timer);
        };
    }, []);
    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <img
                width="300"
                height="200"
                src={Logo}
                alt="email-img"
                style={{ margin: "0 auto" }}
            />
            {/* Modal body */}
            <Modal.Body style={{ textAlign: 'center' }}>
                <h4 style={{ textAlign: "center" }}>Successfully Registered!</h4>
                <p style={{ fontSize: "14px" }}>
                    Thank you for choosing Screener
                    . <br />
                    Please wait while we redirect you to Login
                </p>
                <div style={{ textAlign: "center" }}>
                    <CircularProgress variant="determinate" value={progress} style={{ height: 30, width: 30, color: "#f06161" }} />
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default RedirectModal;
