import React from 'react'

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";

import DeleteMessageIcon from "@mui/icons-material/ErrorOutlineOutlined";

const CancelInterview = ({ cancelInterview, setDialogOpen, dialogOpen }) => {
    return (
        <div>
            <Dialog
                fullWidth
                maxWidth="xs"
                open={dialogOpen}
                onClose={setDialogOpen}
                className="experience-edit-dialog"
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent className="dialog-content">
                    <Grid container spacing={0} justifyContent="center">
                        <Grid
                            item
                            xs={11.5}
                            sm={11.5}
                            md={12}
                            lg={12}
                            className="experience-delete-grid"
                        >
                            <div className="delete-message-icon">
                                <DeleteMessageIcon fontSize="small" />
                            </div>
                            <div className="delete-dialog-content">
                                <h5>Are you sure?</h5>
                                <p>
                                    All your progress will be lost.This process cannot be
                                    reverted
                                </p>
                            </div>
                        </Grid>
                    </Grid>
                </DialogContent>
                <div className="alert-buttons-container">
                    <button
                        className="warning-btn-style filled-btn filled-btn-animation"
                        onClick={() => setDialogOpen(false)}
                    >
                        {" "}
                        Cancel
                    </button>
                    <button
                        className="warning-btn-style outlined-btn outlined-btn-animation"
                        onClick={cancelInterview}
                    >
                        {" "}
                        Confirm
                    </button>
                </div>
            </Dialog>
        </div>
    )
}

export default CancelInterview