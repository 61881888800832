import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { moveCard, changeCard } from "@asseinfo/react-kanban";
import "@asseinfo/react-kanban/dist/styles.css";
import moment from "moment-timezone";
import RefreshIcon from "@mui/icons-material/Refresh";
import DeleteMessageIcon from "@mui/icons-material/ErrorOutlineOutlined";
import "../../company admin/CompanyAdmin.css";
import { v4 as uuidv4 } from 'uuid';

// MUI imports
import {
  Box,
  Card,
  Dialog,
  Tooltip,
  DialogContent,
  Grid,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

//MUI icons
import CloseIcon from "@mui/icons-material/Close";

import CompanyMenu from "../../userProfiles/CompanyMenu";
import { timezone as timeList } from "../../../data-files/timezone";
import { timeSlots } from "../../../data-files/timeSlots";
import UserSnackBar from "../../snackBars/UserSnackbar";
import TableLoader from "../../loaders/TableLoader";

import { CopyToClipboard } from "react-copy-to-clipboard";
import PageNotFound from "../../ErrorHandling/PageNotFound";
import Footer from "../../../Footer/Footer";
import InterviewDialog from "./InterviewDialog";

import ControlledBoard from "./ControlledBoard";
import InterviewsTable from "./InterviewsTable";
import NotifyConfirmationDialog from "./Modals/NotifyConfirmationDialog";
import DeleteDialog from "./Modals/DeleteDialog";

const JITSI_URL = 'https://meet.jit.si';

const RecruiterInterview = ({ history }) => {
  //Session storage values
  const compId = sessionStorage.getItem("compId"); //company id
  const companyId = sessionStorage.getItem("companyId"); //company ref id
  const role = sessionStorage.getItem("role"); //profile role
  const id = sessionStorage.getItem("id"); //role id
  const profileType = sessionStorage.getItem("profileType"); //user role

  // Refs
  const meetLinkRef = useRef(null);

  const [interviewsFilter, setInterviewsFilter] = useState("Scheduled by me");
  //filter interview based on recruiter
  const [recruiterName, setRecruiterName] = useState("");
  const [recruiterDetails, setRecruiterDetails] = useState({});

  //navbar states
  const [open, setOpen] = useState();
  const [sidebarOpen, setsidebarOpen] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [interacted, setInteracted] = useState("");
  const [croppedImg, setCroppedImg] = useState("");
  const [profileImage, setProfileImage] = useState("");
  const [defaultAvatar, setDefaultAvatar] = useState("");
  const [email, setEmail] = useState("");

  // moduleList access
  const [moduleAccess, setModuleAccess] = useState({});

  //client states
  const [clientName, setClientName] = useState("");
  const [clientDetails, setClientDetails] = useState({
    id: "",
    name: "",
    email: "",
  });

  //position states
  const [positionName, setPositionName] = useState("");
  const [positionDetails, setPositionDetails] = useState({ id: "", name: "" });

  const [notifyDialog, setNotifyDialog] = useState(false);
  //Interview ReScheduled and Declined
  const [rescheduledBy, setRescheduledBy] = useState({
    id: "",
    name: "",
    email: "",
  });

  const [declinedBy, setDeclinedBy] = useState({
    id: "",
    name: "",
    email: "",
  });

  const [hours, setHours] = useState("12");
  const [meridian, setMeridian] = useState("PM");
  const [minutes, setMinutes] = useState("15");
  //table column heading
  const columns = [
    {
      title: "Interview Id",
      width: "13%",
    },
    {
      title: "Interview Title",
      width: "18%",
    },
    {
      title: "Screener Name",
      width: "18%",
    },
    {
      title: "Job Seeker Name",
      width: "18%",
    },
    {
      title: "Schedule Time",
      width: "18%",
    },
    {
      title: "Status",
      width: "20%",
    },
    {
      title: "Meeting Link",
      width: "10%",
    },
  ];

  // Kanban board column name
  const [board, setBoard] = useState({
    columns: [
      {
        id: 1,
        title: "To Schedule",
        cards: [],
      },
      {
        id: 2,
        title: "Scheduled",
        cards: [],
      },
      {
        id: 3,
        title: "Confirmed",
        cards: [],
      },
      {
        id: 4,
        title: "Completed",
        cards: [],
      },
      {
        id: 5,
        title: "Declined",
        cards: [],
      },
    ],
  });

  //snackbar and loader states
  const [message, setMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [linkLoader, setLinkLoader] = useState(false);
  const [loading, setLoading] = useState(true);

  // table filter states
  const [idFilter, setIdFilter] = useState("");
  const [titleFilter, setTitleFilter] = useState("");
  const [screenerFilter, setScreenerFilter] = useState("");
  const [candidateFilter, setCandidateFilter] = useState("");
  const [interviewTimeFilter, setInterviewTimeFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("");

  // interviews array based on status
  let toSchedule = [];
  let scheduled = [];
  let confirmed = [];
  let completed = [];
  let declined = [];

  //schedule interview dialog states
  const [dialogOpen, setDialogOpen] = useState(false);
  const [scheduleInterview, setOpenScheduleInterview] = useState(false);
  const [mode, setMode] = useState("");
  const [interviewTitle, setInterviewTitle] = useState("");
  const [screenerName, setScreenerName] = useState("");
  const [candidateName, setCandidateName] = useState("");
  const [interviewDate, setInterviewDate] = useState("");
  const [interviewTime, setInterviewTime] = useState("12:15 PM");
  const [timeZone, setTimeZone] = useState("Asia/Kolkata");
  const [interviewLink, setInterviewLink] = useState("");
  const [meetingPlatform, setMeetingPlatform] = useState("")
  const [customLink, setCustomLink] = useState(false);
  const [interviewStatus, setInterviewStatus] = useState("");
  const [interviewId, setInterviewId] = useState("");
  const [oldInterviewStatus, setOldInterviewStatus] = useState("");
  const [scheduledBy, setScheduledBy] = useState({});
  const [interviewUniqueId, setInterviewUniqueId] = useState("");
  const [backupDate, setBackupDate] = useState("");
  const [screenerDetails, setScreenerDetails] = useState({});
  const [candidateDetails, setCandidateDetails] = useState({});

  //screener feedback states
  const [screenerRating, setScreenerRating] = useState(0);
  const [screenerRatingsTitle, setScreenerRatingsTitle] = useState("");
  const [screenerReview, setScreenerReview] = useState("");

  //candidate feedback states
  const [candidateRating, setCandidateRating] = useState(0);
  const [candidateRatingsTitle, setCandidateRatingsTitle] = useState("");
  const [candidateReview, setCandidateReview] = useState("");

  const [markCompleted, setMarkCompleted] = useState(false);

  //Switch states
  //only favorites
  const [favScreenerFilter, setFavScreenerFilter] = useState(false);
  const [favCandidateFilter, setFavCandidateFilter] = useState(false);
  //Complete database
  const [usersDBCandidate, setUsersDBCandidate] = useState(true);
  const [usersDBScreener, setUsersDBScreener] = useState(true);
  //only internal DB
  const [internalDBCandidate, setInternalDBCandidate] = useState(false);
  const [internalDBScreener, setInternalDBScreener] = useState(false);
  // role toggle
  const [screenerList, setScreenerList] = useState([]);
  const [candidateList, setCandidateList] = useState([]);
  const [recruiterList, setRecruiterList] = useState([]);
  const [clientsList, setClientsList] = useState([]);
  const [positionsList, setPositionsList] = useState([]);
  // view toggle
  const [view, setView] = useState("kanban");
  const [kanbanSelected, setKanbanSelected] = useState(true);

  const [ratingsView, setRatingsView] = useState("candidate rating");

  //refs
  const interviewTitleRef = useRef(null);
  const clientRef = useRef(null);
  const positionRef = useRef(null);
  const screenerRef = useRef(null);
  const candidateRef = useRef(null);

  //error states
  const [errors, setErrors] = useState({
    interviewTitle: "",
    screenerName: "",
    candidateName: "",
    interviewDate: "",
    interviewTime: "",
    timeZone: "",
    interviewLink: "",
    interviewStatus: "",
    client: "",
    position: "",
    screenerRating: "",
    screenerRatingsTitle: "",
    screenerReview: "",
  });

  //state for fetching all interviews
  const [allInterviews, setAllInterviews] = useState([]);
  const [filterArray, setFilterArray] = useState([]);

  // setting interviews other than
  const [toScheduleArray, setToScheduleArray] = useState([]);
  const [scheduleArray, setScheduleArray] = useState([]);
  const [confirmedArray, setConfirmedArray] = useState([]);
  const [completedArray, setCompletedArray] = useState([]);
  const [declinedArray, setDeclinedArray] = useState([]);

  //pagination states
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);

  //state to store the Interviews access of the user or recruiter
  const [hasInterviewsAccess, setHasInterviewsAccess] = useState(true);

  //will be set to true after screener has submitted feedback
  const [showFeedback, setShowFeedback] = useState(true);

  //will be set to true when admin submits feedback to screener
  const [screenerFeedback, setScreenerFeedBack] = useState(false);

  useEffect(() => {
    getAllInterviews();
  }, []);
  //fetching navbar data
  useEffect(() => {
    document.title = "Screener | Interviews";

    axios
      .get(`/api/admin/userRole/side-list/${companyId}`)
      .then((res) => {
        let modules = res.data[0].profileType.filter(
          (item) => item.roleName.toLowerCase() === profileType.toLowerCase()
        );
        modules = modules[0].modules.filter(
          (item) => item.title === "Interviews"
        );
        if (!modules[0].checked) setHasInterviewsAccess(false);
      })
      .catch((err) => {
        console.log(err);
      });

    if (profileType.toLowerCase() == "recruiter") {
      const { data } = axios.get(`/api/recruiter/${id}`).then((res) => {
        const {
          defaultAvatar,
          profileImage,
          croppedImage,
          interacted,
          firstName,
          recruiterEmail,
        } = res.data.user;
        setDefaultAvatar(defaultAvatar);
        setProfileImage(profileImage);
        setCroppedImg(croppedImage);
        setInteracted(interacted);
        setCompanyName(firstName);
        setEmail(recruiterEmail);
      });
    } else {
      const { data } = axios.get(`/api/myUsers/${id}`).then((res) => {
        const {
          defaultAvatar,
          profileImage,
          croppedImage,
          interacted,
          firstName,
          userEmail,
        } = res.data.user;
        setDefaultAvatar(defaultAvatar);
        setProfileImage(profileImage);
        setCroppedImg(croppedImage);
        setInteracted(interacted);
        setCompanyName(firstName);
        setEmail(userEmail);
      });
    }

    getAllCompanyUsers();
  }, []);

  useEffect(() => {
    getAllClients();
  }, [scheduleInterview]);

  // Function to scroll to the target element
  const scrollToElement = (ref) => {
    if (ref.current) {
      ref.current.scrollIntoView({
        behavior: "smooth", // You can change this to 'auto' for instant scrolling
        block: "center", // You can change this to 'end' or 'center' to adjust where the element appears on the screen
      });
    }
  };

  //get all the clients of the company
  function getAllClients() {
    axios
      .get(`/api/admin/get-all-clients/${compId}`)
      .then((res) => {
        const { data } = res;
        setClientsList(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function getAllPositions(clientDetail) {
    axios
      .get(`/api/admin/get-all-positions/${compId}`)
      .then((res) => {
        let { position } = res.data;
        const { id } = clientDetail;
        position = position.filter((pos) => pos.client.id == id);
        setPositionsList(position);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function getCandidateFeedback(interviewUniqueId) {
    axios
      .get(`/api/schedule/get-candidate-feedback/${interviewUniqueId}`)
      .then((res) => {
        const {
          feedback: { ratings, remarks, comment },
        } = res.data;
        setCandidateRating(ratings);
        setCandidateRatingsTitle(remarks);
        setCandidateReview(comment);
      })
      .catch((err) => console.log(err));
  }

  function getScreenerFeedback(interviewUniqueId) {
    axios
      .get(`/api/schedule/get-screener-feedback/${interviewUniqueId}`)
      .then((res) => {
        const {
          feedback: { ratings, remarks, comment },
        } = res.data;
        setScreenerRating(ratings);
        setScreenerRatingsTitle(remarks);
        setScreenerReview(comment);
      })
      .catch((err) => console.log(err));
  }

  //get all interviews
  const getAllInterviews = async () => {
    try {
      const res = await axios.get(`/api/schedule/get-interviews/${compId}`);
      let toSchedule = [],
        scheduled = [],
        confirmed = [],
        completed = [],
        declined = [];

      if (profileType.toLowerCase() != "admin") {
        if (res.data.length > 0) {
          // fetching own interviews
          toSchedule = res.data[0].toSchedule
            ?.filter((item) => item.scheduledBy.id == id)
            .reverse();
          scheduled = res.data[0].scheduled
            ?.filter((item) => item.scheduledBy?.id == id)
            .reverse();
          confirmed = res.data[0].confirmed
            ?.filter((item) => item.scheduledBy.id == id)
            .reverse();
          completed = res.data[0].completed
            ?.filter((item) => item.scheduledBy.id == id)
            .reverse();
          declined = res.data[0].declined
            ?.filter((item) => item.scheduledBy.id == id)
            .reverse();

          // setting interviews in kanban and table
          handleSetInterviewsTable(
            toSchedule,
            scheduled,
            confirmed,
            completed,
            declined
          );
          handleSetInterviewsKanban(
            toSchedule,
            scheduled,
            confirmed,
            completed,
            declined
          );

          // fetching other interviews
          setToScheduleArray(
            res.data[0].toSchedule?.filter((item) => item.scheduledBy.id != id)
          );
          setScheduleArray(
            res.data[0].scheduled?.filter((item) => item.scheduledBy?.id != id)
          );
          setConfirmedArray(
            res.data[0].confirmed?.filter((item) => item.scheduledBy.id != id)
          );
          setCompletedArray(
            res.data[0].completed?.filter((item) => item.scheduledBy.id != id)
          );
          setDeclinedArray(
            res.data[0].declined?.filter((item) => item.scheduledBy.id != id)
          );
          setLoading(false);
        } else {
          setLoading(false);
          setAllInterviews(res.data);
          setFilterArray(res.data);
        }
      } else {
        if (res.data.length > 0) {
          if (interviewsFilter === "All Interviews") {
            // fetching all interviews
            toSchedule = res.data[0]?.toSchedule.reverse();
            scheduled = res.data[0]?.scheduled.reverse();
            confirmed = res.data[0]?.confirmed.reverse();
            completed = res.data[0]?.completed.reverse();
            declined = res.data[0]?.declined.reverse();
          } else if (interviewsFilter === "Scheduled by me") {
            // fetching own interviews
            toSchedule = res.data[0].toSchedule
              ?.filter((item) => item.scheduledBy.id == id)
              .reverse();
            scheduled = res.data[0].scheduled
              ?.filter((item) => item.scheduledBy?.id == id)
              .reverse();
            confirmed = res.data[0].confirmed
              ?.filter((item) => item.scheduledBy.id == id)
              .reverse();
            completed = res.data[0].completed
              ?.filter((item) => item.scheduledBy.id == id)
              .reverse();
            declined = res.data[0].declined
              ?.filter((item) => item.scheduledBy.id == id)
              .reverse();
          }

          // setting interviews to kanban and table
          handleSetInterviewsTable(
            toSchedule,
            scheduled,
            confirmed,
            completed,
            declined
          );
          handleSetInterviewsKanban(
            toSchedule,
            scheduled,
            confirmed,
            completed,
            declined
          );

          // getting all other interviews
          setToScheduleArray(
            res.data[0].toSchedule?.filter((item) => item.scheduledBy.id != id)
          );
          setScheduleArray(
            res.data[0].scheduled?.filter((item) => item.scheduledBy?.id != id)
          );
          setConfirmedArray(
            res.data[0].confirmed?.filter((item) => item.scheduledBy.id != id)
          );
          setCompletedArray(
            res.data[0].completed?.filter((item) => item.scheduledBy.id != id)
          );
          setDeclinedArray(
            res.data[0].declined?.filter((item) => item.scheduledBy.id != id)
          );
          setLoading(false);
        } else {
          // setting empty array if there are no interviews
          setLoading(false);
          setAllInterviews(res.data);
          setFilterArray(res.data);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  //set interviews for table view
  function handleSetInterviewsTable(
    toSchedule,
    scheduled,
    confirmed,
    completed,
    declined
  ) {
    let interviews = [
      ...toSchedule,
      ...scheduled,
      ...confirmed,
      ...completed,
      ...declined,
    ];
    const sortedInterview = interviews.sort(
      (a, b) => new Date(a.interviewDate) - new Date(b.interviewDate)
    ); //sort based on date
    const array = sortedInterview.map((interview) => {
      let interviewTime = formatDateAndTime(
        interview.interviewDate + " " + interview.interviewTime,
        interview?.timezone
      );
      return { ...interview, scheduledTime: interviewTime };
    });
    setAllInterviews(array);
    setFilterArray(array);
    setLoading(false);
  }

  //push the interviews to kanban cards
  function handleSetInterviewsKanban(
    toSchedule,
    scheduled,
    confirmed,
    completed,
    declined
  ) {
    let tempBoard = {
      columns: [
        {
          id: 1,
          title: "To Schedule",
          cards: [],
        },
        {
          id: 2,
          title: "Scheduled",
          cards: [],
        },
        {
          id: 3,
          title: "Confirmed",
          cards: [],
        },
        {
          id: 4,
          title: "Completed",
          cards: [],
        },
        {
          id: 5,
          title: "Declined",
          cards: [],
        },
      ],
    };

    toSchedule.map((item) => {
      tempBoard.columns[0].cards.push({
        id: item._id,
        interviewTitle: item.interviewTitle,
        screenerName: item.screener?.name,
        screenerDetails: item.screener,
        candidateDetails: item.candidate,
        candidateName: item.candidate?.name,
        interviewDate: formatDateAndTime(
          item.interviewDate + " " + item.interviewTime,
          item?.timezone
        ),
        backupDate: item.interviewDate,
        interviewTime: item.interviewTime,
        timeZone: item.timezone,
        interviewLink: item.interviewLink,
        meetingPlatform: item.meetingPlatform,
        interviewId: item.interviewUniqueId,
        interviewStatus: item.interviewStatus,
        scheduledBy: item.scheduledBy,
        interviewUniqueId: item.interviewUniqueId,
        clientDetails: item.clientDetails,
        positionDetails: item.positionDetails,
        rescheduledBy: item.rescheduledBy,
        declinedBy: item.declinedBy,
        screenerConfirm: item.screenerConfirm,
        candidateConfirm: item.candidateConfirm,
        candidateStatus: item.candidateStatus,
        screenerStatus: item.screenerStatus,
        feedback: item?.feedback,
        screenerFeedback: item?.screenerFeedback,
      });
    });

    scheduled.map((item) => {
      tempBoard.columns[1].cards.push({
        id: item._id,
        interviewTitle: item.interviewTitle,
        screenerName: item.screener?.name,
        candidateName: item.candidate?.name,
        screenerDetails: item.screener,
        candidateDetails: item.candidate,
        interviewDate: formatDateAndTime(
          item.interviewDate + " " + item.interviewTime,
          item?.timezone
        ),
        backupDate: item.interviewDate,
        interviewTime: item.interviewTime,
        timeZone: item.timezone,
        interviewLink: item.interviewLink,
        meetingPlatform: item.meetingPlatform,
        interviewId: item.interviewUniqueId,
        interviewStatus: item.interviewStatus,
        scheduledBy: item.scheduledBy,
        interviewUniqueId: item.interviewUniqueId,
        clientDetails: item.clientDetails,
        positionDetails: item.positionDetails,
        rescheduledBy: item.rescheduledBy,
        declinedBy: item.declinedBy,
        screenerConfirm: item.screenerConfirm,
        candidateConfirm: item.candidateConfirm,
        candidateStatus: item.candidateStatus,
        screenerStatus: item.screenerStatus,
        feedback: item?.feedback,
        screenerFeedback: item?.screenerFeedback,
      });
    });

    confirmed.map((item) => {
      tempBoard.columns[2].cards.push({
        id: item._id,
        interviewTitle: item.interviewTitle,
        screenerName: item.screener?.name,
        candidateName: item.candidate?.name,
        screenerDetails: item.screener,
        candidateDetails: item.candidate,
        interviewDate: formatDateAndTime(
          item.interviewDate + " " + item.interviewTime,
          item?.timezone
        ),
        backupDate: item.interviewDate,
        interviewTime: item.interviewTime,
        timeZone: item.timezone,
        interviewLink: item.interviewLink,
        meetingPlatform: item.meetingPlatform,
        interviewId: item.interviewUniqueId,
        interviewStatus: item.interviewStatus,
        scheduledBy: item.scheduledBy,
        interviewUniqueId: item.interviewUniqueId,
        clientDetails: item.clientDetails,
        positionDetails: item.positionDetails,
        rescheduledBy: item.rescheduledBy,
        declinedBy: item.declinedBy,
        screenerConfirm: item.screenerConfirm,
        candidateConfirm: item.candidateConfirm,
        candidateStatus: item.candidateStatus,
        screenerStatus: item.screenerStatus,
        feedback: item?.feedback,
        screenerFeedback: item?.screenerFeedback,
      });
    });

    completed.map((item) => {
      tempBoard.columns[3].cards.push({
        id: item._id,
        interviewTitle: item.interviewTitle,
        screenerName: item.screener?.name,
        candidateName: item.candidate?.name,
        screenerDetails: item.screener,
        candidateDetails: item.candidate,
        interviewDate: formatDateAndTime(
          item.interviewDate + " " + item.interviewTime,
          item?.timezone
        ),
        backupDate: item.interviewDate,
        interviewTime: item.interviewTime,
        timeZone: item.timezone,
        interviewLink: item.interviewLink,
        meetingPlatform: item.meetingPlatform,
        interviewId: item.interviewUniqueId,
        interviewStatus: item.interviewStatus,
        scheduledBy: item.scheduledBy,
        interviewUniqueId: item.interviewUniqueId,
        clientDetails: item.clientDetails,
        positionDetails: item.positionDetails,
        rescheduledBy: item.rescheduledBy,
        declinedBy: item.declinedBy,
        screenerConfirm: item.screenerConfirm,
        candidateConfirm: item.candidateConfirm,
        candidateStatus: item.candidateStatus,
        screenerStatus: item.screenerStatus,
        feedback: item?.feedback,
        screenerFeedback: item?.screenerFeedback,
      });
    });

    declined.map((item) => {
      tempBoard.columns[4].cards.push({
        id: item._id,
        interviewTitle: item.interviewTitle,
        screenerName: item.screener?.name,
        candidateName: item.candidate?.name,
        screenerDetails: item.screener,
        candidateDetails: item.candidate,
        interviewDate: formatDateAndTime(
          item.interviewDate + " " + item.interviewTime,
          item?.timezone
        ),
        backupDate: item.interviewDate,
        interviewTime: item.interviewTime,
        timeZone: item.timezone,
        interviewLink: item.interviewLink,
        meetingPlatform: item.meetingPlatform,
        interviewId: item.interviewUniqueId,
        interviewStatus: item.interviewStatus,
        scheduledBy: item.scheduledBy,
        interviewUniqueId: item.interviewUniqueId,
        clientDetails: item.clientDetails,
        positionDetails: item.positionDetails,
        rescheduledBy: item.rescheduledBy,
        declinedBy: item.declinedBy,
        screenerConfirm: item.screenerConfirm,
        candidateConfirm: item.candidateConfirm,
        candidateStatus: item.candidateStatus,
        screenerStatus: item.screenerStatus,
        feedback: item?.feedback,
        screenerFeedback: item?.screenerFeedback,
      });
    });
    setBoard(tempBoard);
  }
  console.log(loading);
  // fetching all data when view is changed
  useEffect(() => {
    if (!recruiterName) {
      getAllInterviews();
    }
  }, [view, interviewsFilter]);

  //get screeners and candidates for scheduling interview
  async function getUsers() {
    let data1, data2; //storing screener and candidate array
    //Fetch screener from both internal db and normal db
    if (usersDBScreener) {
      let { data } = await axios.get(`/api/admin/screeners/internal-screeners`); //fetching from internal db
      //filter out converted profile to avoid redundancy
      if (data.length) {
        data = data.filter((item) => item.requestStatus !== "Accepted");
        data = data.map((item) => ({ ...item, screenerType: "internal" }));
      }

      let { data: normalUsers } = await axios.get(
        `/api/screener/screenerList/screeners`
      ); //fetching from normal db
      //renaming email and phone field
      normalUsers =
        normalUsers &&
        normalUsers.map((item) => {
          const { screenerEmail, screenerPhone, ...rest } = item;
          return {
            email: screenerEmail,
            primaryPhone: screenerPhone,
            screenerType: "external",
            ...rest,
          };
        });
      //merging both the arrays
      if (data || normalUsers) {
        let allUsers = [...data, ...normalUsers];
        // allUsers = allUsers.reverse()
        setScreenerList(allUsers);
      } else {
        setScreenerList([]);
      }
    }
    // fetching screener from internal db only
    else if (internalDBScreener) {
      data1 = await axios.get(`/api/admin/screeners/internal-screeners`);
      let { data } = data1;
      if (data.length) {
        data = data.filter((item) => item.requestStatus !== "Accepted");
        data = data.map((item) => ({ ...item, screenerType: "internal" }));
      }
      setScreenerList(data);
    }
    // fetching screener from favorites
    else if (favScreenerFilter) {
      const userId = id;
      const {
        data: { screener },
      } = await axios.get(`/api/admin/favorites/${userId}/screener/${compId}`);
      if (screener) {
        setScreenerList(screener);
      } else {
        setScreenerList([]);
      }
    }
    // fetching screeners from normal db
    else {
      let { data: normalUsers } = await axios.get(
        `/api/screener/screenerList/screeners`
      );
      //renaming email and phone field
      normalUsers =
        normalUsers &&
        normalUsers.map((item) => {
          const { screenerEmail, screenerPhone, ...rest } = item;
          return { email: screenerEmail, primaryPhone: screenerPhone, ...rest };
        });
      setScreenerList(normalUsers);
    }

    // fetching candidates from normal and inetrnal db
    if (usersDBCandidate) {
      let { data } = await axios.get(
        `/api/admin/candidates/internal-candidates`
      ); //fetching from internal db
      if (data.length) {
        data = data.filter((item) => item.requestStatus !== "Accepted");
      }
      let { data: normalUsers } = await axios.get(
        `/api/candidate/candidateList/candidates`
      ); //fetching from normal db
      // renaming fields
      normalUsers =
        normalUsers &&
        normalUsers.map((item) => {
          const { candidateEmail, candidatePhone, ...rest } = item;
          return {
            email: candidateEmail,
            primaryPhone: candidatePhone,
            ...rest,
          };
        });
      // merging both the arrays
      if (data || normalUsers) {
        let allUsers = [...data, ...normalUsers];
        // allUsers = allUsers.reverse()
        setCandidateList(allUsers);
      } else {
        setCandidateList([]);
      }
    }
    // fetching candidates from internal db only
    else if (internalDBCandidate) {
      data2 = await axios.get(`/api/admin/candidates/internal-candidates`);
      let { data } = data2;
      if (data.length) {
        data = data.filter((item) => item.requestStatus !== "Accepted");
      }
      setCandidateList(data);
    }
    // fetching candidates from favorites
    else if (favCandidateFilter) {
      const userId = id;
      const {
        data: { candidate },
      } = await axios.get(`/api/admin/favorites/${userId}/candidate/${compId}`);
      if (candidate) {
        setCandidateList(candidate);
      } else {
        setCandidateList([]);
      }
    }
    // fetching candidates from normal db only
    else {
      let { data: normalUsers } = await axios.get(
        `/api/candidate/candidateList/candidates`
      );
      // renaming fields
      normalUsers =
        normalUsers &&
        normalUsers.map((item) => {
          const { candidateEmail, candidatePhone, ...rest } = item;
          return {
            email: candidateEmail,
            primaryPhone: candidatePhone,
            ...rest,
          };
        });
      setCandidateList(normalUsers);
    }
  }

  // fetching list of users from different database based on the toggle
  useEffect(() => {
    getUsers();
  }, [
    usersDBCandidate,
    internalDBCandidate,
    favCandidateFilter,
    usersDBScreener,
    internalDBScreener,
    favScreenerFilter,
  ]);

  // get all the recruiters of the company
  async function getAllCompanyUsers() {
    let userArray = [];
    //fetching all recruiters of the company
    let recruiters = await axios.get(
      `/api/schedule/get-company-recruiters/${compId}`
    );
    // mapping and returning required structure
    recruiters = recruiters.data?.map((recruiter) => {
      // renaming object keys
      const { firstName, lastName, recruiterEmail, ...rest } = recruiter;
      return {
        email: recruiterEmail,
        fullName: firstName + " " + lastName,
        ...rest,
      };
    });

    // fetching main admin
    let admin = await axios.get(`/api/admin/${compId}`);
    // renaming object keys
    const { companyName, companyEmail, ...rest } = admin.data.user;
    admin = { fullName: companyName, email: companyEmail, ...rest };

    // fetching all other users of the company
    let allUsers = await axios.get(`/api/myUsers/get-company-users/${compId}`);
    // mapping and returning required structure
    allUsers = allUsers.data?.map((user) => {
      // renaming object keys
      const { firstName, lastName, userEmail, ...rest } = user;
      return {
        email: userEmail,
        fullName: firstName + " " + lastName,
        ...rest,
      };
    });

    userArray = [admin, ...recruiters, ...allUsers];
    userArray = userArray.filter((item) => item._id != id);
    setRecruiterList(userArray);
  }

  //MUI autocomplete options
  const recruiterOptions = recruiterList?.map((user) => {
    return {
      name: user.fullName,
      label: `${user.fullName} - ${user.email}`,
      value: {
        id: user._id,
        email: user.email,
        name: user.fullName,
      },
    };
  });

  //getting the output after filtered
  const getFiltered = () => {
    let filtered = filterArray.filter((item, index) => {
      if (idFilter) {
        return item.interviewUniqueId
          .toLowerCase()
          .includes(idFilter.toLowerCase().trim());
      } else return item;
    });

    filtered = filtered.filter((item, index) => {
      if (titleFilter) {
        return item?.interviewTitle
          .toLowerCase()
          .startsWith(titleFilter.toLowerCase().trim());
      } else return item;
    });

    filtered = filtered.filter((item, index) => {
      if (screenerFilter) {
        return item?.screener?.name
          .toLowerCase()
          .includes(screenerFilter.toLowerCase().trim());
      } else return item;
    });

    filtered = filtered.filter((item, index) => {
      if (candidateFilter) {
        return item?.candidate?.name
          .toLowerCase()
          .includes(candidateFilter.toLowerCase().trim());
      } else return item;
    });

    filtered = filtered.filter((item, index) => {
      if (interviewTimeFilter) {
        return item?.scheduledTime
          .toLowerCase()
          .includes(interviewTimeFilter.toLowerCase().trim());
      } else return item;
    });

    filtered = filtered.filter((item, index) => {
      if (statusFilter) {
        return item.interviewStatus
          .toLowerCase()
          .includes(statusFilter.toLowerCase().trim());
      } else return item;
    });

    setAllInterviews(filtered);
  };

  //filter interviews based on values and set page number to starting
  useEffect(() => {
    setPage(0);
    getFiltered();
  }, [
    idFilter,
    titleFilter,
    screenerFilter,
    candidateFilter,
    interviewTimeFilter,
    statusFilter,
  ]);

  // copying link
  const copyTextToClipboard = async (text) => {
    try {
      setMessage("Link copied to clipboard");
      setOpenSnackbar(true);
    } catch (err) {
      setMessage("Failed to copy link");
      setOpenSnackbar(true);
    }
  };

  // mergining all the interviews while dragging
  const updatedCompleteArray = (filteredArray, arrayName) => {
    if (arrayName === "toSchedule") {
      filteredArray = [...filteredArray, ...toScheduleArray];
      return filteredArray;
    } else if (arrayName === "scheduled") {
      filteredArray = [...filteredArray, ...scheduleArray];
      return filteredArray;
    } else if (arrayName === "confirmed") {
      filteredArray = [...filteredArray, ...confirmedArray];
      return filteredArray;
    } else if (arrayName === "completed") {
      filteredArray = [...filteredArray, ...completedArray];
      return filteredArray;
    } else {
      filteredArray = [...filteredArray, ...declinedArray];
      return filteredArray;
    }
  };

  // updating interview status on dragging in kanban
  const updateStatusOnDrag = (card, source, destination, updatedBoard) => {
    toSchedule = updatedBoard.columns[0].cards;
    scheduled = updatedBoard.columns[1].cards;
    confirmed = updatedBoard.columns[2].cards;
    completed = updatedBoard.columns[3].cards;
    declined = updatedBoard.columns[4].cards;

    toSchedule = toSchedule.map((item) => {
      const {
        id,
        backupDate,
        screenerName,
        candidateName,
        screenerDetails,
        candidateDetails,
        interviewDate,
        timeZone,
        ...rest
      } = item;
      return {
        _id: id,
        interviewDate: backupDate,
        screener: screenerDetails,
        candidate: candidateDetails,
        timezone: timeZone,
        ...rest,
      };
    });
    toSchedule = toSchedule.reverse();
    toSchedule = updatedCompleteArray(toSchedule, "toSchedule");

    scheduled = scheduled.map((item) => {
      const {
        id,
        backupDate,
        screenerName,
        candidateName,
        screenerDetails,
        candidateDetails,
        interviewDate,
        timeZone,
        ...rest
      } = item;
      return {
        _id: id,
        interviewDate: backupDate,
        screener: screenerDetails,
        candidate: candidateDetails,
        timezone: timeZone,
        ...rest,
      };
    });
    scheduled = scheduled.reverse();
    scheduled = updatedCompleteArray(scheduled, "scheduled");

    confirmed = confirmed.map((item) => {
      const {
        id,
        backupDate,
        screenerName,
        candidateName,
        screenerDetails,
        candidateDetails,
        interviewDate,
        timeZone,
        ...rest
      } = item;
      return {
        _id: id,
        interviewDate: backupDate,
        screener: screenerDetails,
        candidate: candidateDetails,
        timezone: timeZone,
        ...rest,
      };
    });
    confirmed = confirmed.reverse();
    confirmed = updatedCompleteArray(confirmed, "confirmed");

    completed = completed.map((item) => {
      const {
        id,
        backupDate,
        screenerName,
        candidateName,
        screenerDetails,
        candidateDetails,
        interviewDate,
        timeZone,
        ...rest
      } = item;
      return {
        _id: id,
        interviewDate: backupDate,
        screener: screenerDetails,
        candidate: candidateDetails,
        timezone: timeZone,
        ...rest,
      };
    });
    completed = completed.reverse();
    completed = updatedCompleteArray(completed, "completed");

    declined = declined.map((item) => {
      const {
        id,
        backupDate,
        screenerName,
        candidateName,
        screenerDetails,
        candidateDetails,
        interviewDate,
        timeZone,
        ...rest
      } = item;
      return {
        _id: id,
        interviewDate: backupDate,
        screener: screenerDetails,
        candidate: candidateDetails,
        timezone: timeZone,
        ...rest,
      };
    });
    declined = declined.reverse();
    declined = updatedCompleteArray(declined, "declined");
    axios
      .post(`/api/schedule/change-interview-status/${compId}`, {
        card,
        source,
        destination,
        role: profileType.toLowerCase(),
        toSchedule,
        scheduled,
        confirmed,
        completed,
        declined,
      })
      .then((res) => {
        setMessage(res.data.message);
        setOpenSnackbar(true);
        getAllInterviews();
      })
      .catch((err) => {
        setMessage(err.data.message);
        setOpenSnackbar(true);
      });
  };

  // generating meeting link
  const generateLinkHandler = () => {
    setLinkLoader(true);
    setMeetingPlatform('Zoom')
    setInterviewLink("Loading...")
    axios
      .get(`/api/schedule/generate`)
      .then((res) => {
        setLinkLoader(false);
        setInterviewLink(res.data.join_url);
        setErrors({});
        setCustomLink(false);
      })
      .catch((err) => {
        setMessage("Zoom server is busy at the moment.");
        setOpenSnackbar(true);
        setLinkLoader(false);
        setCustomLink(false);
      });
  };

  const generateJitsiLink = () => {
    try {
      setMeetingPlatform("Jitsi")
      const meetId = uuidv4();
      const meetingURL = `${JITSI_URL}/${meetId}`
      setInterviewLink(meetingURL)
    } catch (err) {
      setMessage("Jitsi server is busy at the moment.");
      setOpenSnackbar(true);
    }
  }

  const customLinkHandler = () => {
    setMeetingPlatform("Custom")
    setCustomLink(true);
    setInterviewLink("");
    if (meetLinkRef.current) {
      // Set focus using setTimeout to ensure it's focused on the next render cycle
      setTimeout(() => {
        meetLinkRef.current.focus();
      }, 0);
    }
  };

  // formatting date in dd-mmm-yyyy format
  function formatDateAndTime(inputDate, timezone) {
    const [date, time] = inputDate.split(" ");
    if (date && time) {
      let gmtDate = moment.tz(inputDate, "YYYY-MM-DD hh:mm a", timezone).utc();
      gmtDate = moment.tz(gmtDate, "MMMM Do YYYY[,] h:mm a", timezone);
      gmtDate = gmtDate.format("DD-MMM-YYYY[,] h:mm a z");
      return gmtDate;
    } else if (date) {
      let gmtDate = moment
        .tz(inputDate.trim(), "YYYY-MM-DD hh:mm a", timezone)
        .utc();
      gmtDate = moment.tz(gmtDate, "MMMM Do YYYY", timezone);
      gmtDate = gmtDate.format("DD-MMM-YYYY");
      return gmtDate;
    } else if (time) {
      let gmtDate = moment
        .tz(inputDate.trim(), "YYYY-MM-DD hh:mm a", timezone)
        .utc();
      gmtDate = moment.tz(gmtDate, "MMMM Do YYYY", timezone);
      gmtDate = gmtDate.format("DD-MMM-YYYY");
      return gmtDate;
    } else {
      return " ";
    }
  }

  //closeHandler for Dialog6
  const closeHandler = () => {
    setNotifyDialog(false);
    setOpenScheduleInterview(true);
  };

  // handle to open quick inetrview dialog
  const handleOpenQuickInterview = () => {
    sessionStorage.setItem("navigateTo", "advancedSearch");
    localStorage.removeItem("role");
    history.push("/recruiter/advanced-search");
  };

  // opening schedule interview dialog
  const handleOpenScheduleInterview = () => {
    setMode("create");
    getUsers();
    setOpenScheduleInterview(true);
  };



  // checking fields of schedule inetrview dialog
  const checkAllFieldsFilled = () => {
    if (!interviewTitle) {
      setErrors({ interviewTitle: "Please enter Interview Title" });
      scrollToElement(interviewTitleRef);
      return false;
    }
    if (clientName && !clientDetails.id) {
      setErrors({ client: "Please choose one among the list of Clients" });
      scrollToElement(clientRef);
      return false;
    }
    if (positionName && !positionDetails.id) {
      setErrors({ position: "Please choose one among the list of Positions" });
      scrollToElement(positionRef);
      return false;
    }

    if (Object.keys(screenerDetails).length == 0) {
      setErrors({
        screenerName: "Please choose one among the list of Screeners",
      });
      scrollToElement(screenerRef);
      return false;
    }
    if (Object.keys(candidateDetails).length == 0) {
      setErrors({
        candidateName: "Please choose one among the list of Candidates",
      });
      scrollToElement(candidateRef);
      return false;
    }
    if (!screenerName) {
      setErrors({ screenerName: "Please enter Screener Name" });
      scrollToElement(screenerRef);
      return false;
    }
    if (!candidateName) {
      setErrors({ candidateName: "Please enter Candidate Name" });
      scrollToElement(candidateRef);
      return false;
    }
    if (!backupDate) {
      setErrors({ interviewDate: "Please enter Interview Date" });
      return false;
    }
    if (!interviewTime) {
      setErrors({ interviewTime: "Please enter Interview Time" });
      return false;
    }
    if (!timeZone) {
      setErrors({ interviewTime: "Please enter Time-zone" });
      return false;
    }
    if (!interviewLink) {
      setErrors({ interviewLink: "Please generate Interview Link" });
      return false;
    }
    if (mode === "edit" && !interviewStatus) {
      setErrors({ interviewStatus: "Please select interview status" });
      return false;
    }
    if (showFeedback && markCompleted) {
      if (!screenerRating) {
        setErrors({ screenerRating: "Please enter Screener Rating" });
        return false;
      }
      if (!screenerRatingsTitle) {
        setErrors({
          screenerRatingsTitle: "Please enter Screener Rating Title",
        });
        return false;
      }
      if (!screenerReview) {
        setErrors({ screenerReview: "Please enter Screener Review" });
        return false;
      }
    }
    return true;
  };

  // handle to open edit interview dialog
  const handleOpenEditInterview = (
    id,
    interviewTitle,
    screenerName,
    candidateName,
    screenerDetails,
    candidateDetails,
    interviewDate,
    backupDate,
    interviewTime,
    timeZone,
    interviewLink,
    meetingPlatform,
    interviewStatus,
    scheduledBy,
    interviewUniqueId,
    clientDetails,
    positionDetails,
    declinedBy,
    rescheduledBy,
    screenerConfirm,
    candidateConfirm,
    screenerStatus,
    candidateStatus,
    feedback,
    screenerFeedback
  ) => {
    setMode("edit");
    getUsers();
    setInterviewTitle(interviewTitle);
    setScreenerName(screenerName);
    setCandidateName(candidateName);
    setBackupDate(backupDate);
    setScreenerDetails(screenerDetails);
    setCandidateDetails(candidateDetails);
    setInterviewDate(interviewDate);
    setInterviewTime(interviewTime);
    setTimeZone(timeZone);
    setInterviewLink(interviewLink);
    setMeetingPlatform(meetingPlatform)
    setInterviewStatus(interviewStatus);
    setOpenScheduleInterview(true);
    setInterviewId(id);
    setOldInterviewStatus(interviewStatus);
    setScheduledBy(scheduledBy);
    setInterviewUniqueId(interviewUniqueId);
    setClientDetails(clientDetails);
    setClientName(clientDetails?.name);
    setPositionDetails(positionDetails);
    setPositionName(positionDetails?.name || "");
    setDeclinedBy(declinedBy);
    setRescheduledBy(rescheduledBy);
    setShowFeedback(feedback);
    setScreenerFeedBack(screenerFeedback);
    //function call
    getCandidateFeedback(interviewUniqueId);
    getScreenerFeedback(interviewUniqueId);
  };

  // handle to close the interview scheduling dialog and setting data to empty
  const handleCloseScheduleInterview = () => {
    setOpenScheduleInterview(false);
    setInterviewTitle("");
    setScreenerDetails({});
    setScreenerName("");
    setCandidateDetails({});
    setCandidateName("");
    setInterviewDate("");
    setBackupDate("");
    setTimeZone("Asia/Kolkata");
    setInterviewLink("");
    setMeetingPlatform("");
    setInterviewStatus("");
    setOldInterviewStatus("");
    setInterviewTime("");
    setScheduledBy({});
    setInterviewUniqueId("");
    setClientDetails({ id: "", name: "", email: "" });
    setPositionDetails({ id: "", name: "" });
    setClientName("");
    setPositionName("");
    setErrors({});
    setDeclinedBy({ id: "", name: "", email: "" });
    setRescheduledBy({ id: "", name: "", email: "" });
    setScreenerRating(0);
    setScreenerRatingsTitle("");
    setScreenerReview("");
    setMarkCompleted(false);

    setCandidateRating(0);
    setCandidateRatingsTitle("");
    setCandidateReview("");
    setShowFeedback(false);
    setScreenerFeedBack(false);
    setCustomLink(false);
    setMode("create");
    setHours("12");
    setMeridian("PM");
    setMinutes("15");
    setInterviewTime("12:15 PM");
  };



  // handle for interviews
  function handleScheduleInterview(e, notificationStatus) {
    e.preventDefault();

    //scheduling new interview
    if (mode === "create") {
      const allFieldsFilled = checkAllFieldsFilled();
      if (!allFieldsFilled) return;

      const newData = {
        candidateDetails,
        companyId,
        id: compId,
        screenerDetails,
        interviewTitle,
        interviewLink,
        meetingPlatform,
        zone: timeZone,
        date: backupDate,
        time: interviewTime,
        interviewStatus: "Scheduled",
        scheduledBy: id,
        role: profileType.toLowerCase(),
        clientDetails,
        positionDetails,
        declinedBy,
        rescheduledBy,
        shouldNotify: true,
      };
      axios
        .post("/api/schedule/scheduleinterview", { newData })
        .then((res) => {
          setMessage("Interview Scheduled successfully!");
          setOpenSnackbar(true);
          handleCloseScheduleInterview();
          getAllInterviews();
          setHours("12");
          setMeridian("PM");
          setMinutes("15");
        })
        .catch((err) => {
          setMessage("Oops... There is some error");
          setOpenSnackbar(true);
        });
    }
    //editing existing interview
    else {
      let rescheduledBy = {
        id: "",
        name: "",
        email: "",
      };
      let declinedBy = {
        id: "",
        name: "",
        email: "",
      };

      let screenerConfirm = "pending";
      let candidateConfirm = "pending";
      let screenerStatus = "Rescheduled";
      let candidateStatus = "Rescheduled";

      if (interviewStatus === "To Schedule") {
        rescheduledBy = scheduledBy;
        declinedBy = { id: "", name: "", email: "" };
      } else if (interviewStatus === "Scheduled") {
        declinedBy = { id: "", name: "", email: "" };
        rescheduledBy = { id: "", name: "", email: "" };
        screenerStatus = "Scheduled";
        candidateStatus = "Scheduled";
        // const allFieldsFilled = checkAllFieldsFilled();
        // if (!allFieldsFilled) return;
      } else if (interviewStatus === "Confirmed") {
        screenerConfirm = "confirmed";
        candidateConfirm = "confirmed";
        screenerStatus = "Confirmed";
        candidateStatus = "Confirmed";
      } else if (interviewStatus === "Completed") {
        candidateConfirm = "completed";
        screenerConfirm = "completed";
        screenerStatus = "Completed";
        candidateStatus = "Completed";
      } else if (interviewStatus === "Declined") {
        declinedBy = scheduledBy;
        rescheduledBy = { id: "", name: "", email: "" };
        candidateConfirm = "declined";
        screenerConfirm = "declined";
        screenerStatus = "Declined";
        candidateStatus = "Declined";
      }

      // if (interviewStatus !== "To Schedule") {
      //   const allFieldsFilled = checkAllFieldsFilled();
      //   if (!allFieldsFilled) return;
      // }

      const newData = {
        candidateDetails,
        companyId,
        id: compId,
        screenerDetails,
        interviewTitle,
        backupDate: backupDate,
        interviewLink,
        meetingPlatform,
        zone: timeZone,
        date: interviewDate,
        time: interviewTime,
        interviewStatus: interviewStatus,
        scheduledBy,
        interviewUniqueId,
        role: profileType.toLowerCase(),
        interviewId,
        oldInterviewStatus,
        clientDetails,
        positionDetails,
        declinedBy,
        rescheduledBy,
        screenerConfirm,
        candidateConfirm,
        screenerStatus,
        candidateStatus,
        feedback: showFeedback,
        screenerFeedback,
        shouldNotify: notificationStatus,
      };

      axios
        .post(`/api/schedule/editinterview`, newData)
        .then((res) => {
          setMessage("Interview saved sucessfully");
          setOpenSnackbar(true);
          handleCloseScheduleInterview();
          getAllInterviews();
          setNotifyDialog(false);
          setHours("12");
          setMeridian("PM");
          setMinutes("15");
        })
        .catch((err) => {
          console.log(err);
        });

      if (
        screenerRating &&
        screenerRatingsTitle &&
        screenerReview &&
        showFeedback
      ) {
        const feedbackData = {
          feedback: {
            ratings: screenerRating,
            remarks: screenerRatingsTitle,
            comment: screenerReview,
          },
          user: {
            id: id,
            email: email,
            name: companyName,
            role: profileType,
          },
          positionDetails,
          screener: screenerDetails,
          clientDetails,
          interviewUniqueId,
          interviewId,
          companyId: compId,
        };

        axios
          .post("/api/schedule/add-screener-rating", {
            feedbackData,
            markCompleted,
          })
          .then((res) => console.log(res))
          .catch((err) => {
            setMessage(err.data.msg);
            setOpenSnackbar(true);
          });
      }

      //candidate rating given by recruiter/admin if the screener is internal
      if (
        screenerDetails.screenerType === "internal" &&
        candidateRating &&
        candidateRatingsTitle &&
        candidateReview
      ) {
        const feedback = {
          ratings: candidateRating,
          remarks: candidateRatingsTitle,
          comment: candidateReview,
        };
        const selectedInterview = {
          candidate: candidateDetails,
          screener: screenerDetails,
          scheduledBy: scheduledBy,
          clientDetails: clientDetails,
          positionDetails: positionDetails,
          companyId: compId,
          _id: interviewId,
          interviewUniqueId: interviewUniqueId,
          interviewTitle: interviewTitle,
          interviewDate: interviewDate,
          interviewTime: interviewTime,
        };

        const newData = { feedback, selectedInterview };
        axios
          .post(`/api/schedule/candidate-feedback`, newData)
          .then((res) => {
            console.log(res);
          })
          .catch((err) => {
            console.log(err);
            setOpenSnackbar(true);
            setMessage(
              "Something went wrong while submitting candidate review"
            );
          });
      }

      // setMode('create')
    }
  }

  //handle interviews filter
  function interviewsFilterHandler(e) {
    setInterviewsFilter(e.target.value); //setting filter value
    setLoading(true);
    handleSetInterviewsKanban([], [], [], [], []); //setting kanban to empty array while loading
    setRecruiterName("");
    setRecruiterDetails({});
  }

  //set the recruiter name and details
  const handleSetRecruiterFilter = (event, newValue) => {
    const selectedObj = recruiterOptions.find(
      (option) => option.label === newValue
    );

    selectedObj == undefined
      ? setRecruiterDetails({})
      : setRecruiterDetails(selectedObj);
    if (selectedObj != undefined) {
      handleRecruiterFilter(selectedObj.value);
    }
  };

  // filter interviews based on recruiter
  function handleRecruiterFilter(recruiterDetail) {
    let filtered = filterArray.filter((item, index) => {
      return item.scheduledBy?.id == recruiterDetail?.id;
    });

    let toSchedule = filtered?.filter((item) => {
      return item.interviewStatus == "To Schedule";
    });
    let scheduled = filtered?.filter((item) => {
      return item.interviewStatus == "Scheduled";
    });
    let confirmed = filtered?.filter((item) => {
      return item.interviewStatus == "Confirmed";
    });
    let completed = filtered?.filter((item) => {
      return item.interviewStatus == "Completed";
    });
    let declined = filtered?.filter((item) => {
      return item.interviewStatus == "Declined";
    });

    handleSetInterviewsTable(
      toSchedule,
      scheduled,
      confirmed,
      completed,
      declined
    );
    handleSetInterviewsKanban(
      toSchedule,
      scheduled,
      confirmed,
      completed,
      declined
    );
  }

  // handle to fill the data automatically in text field
  const handleAutoCompleteTextField = (roleType, value) => {
    if (roleType == "screener") {
      setScreenerName(value);
      setScreenerDetails({});
    } else if (roleType == "candidate") {
      setCandidateName(value);
      setCandidateDetails({});
    } else if (roleType === "recruiter") {
      setRecruiterName(value);
      // setRecruiterDetails({})
      // setLoading(true)
      // handleSetInterviewsKanban([], [], [], [], [])
      if (value.length == 0) {
        getAllInterviews();
      }
    } else if (roleType === "client") {
      if (value === "") setPositionDetails({ id: "", name: "" }); //delete position details when client name is empty
      setClientName(value);
      setClientDetails({ id: "", name: "", email: "" });
    } else if (roleType === "position") {
      setPositionName(value);
      setPositionDetails({ id: "", name: "" });
    }
  };

  // handle for input field changes
  const handleChangeDialogFields = (e) => {
    switch (e.target.name) {
      case "recruiterName":
        handleAutoCompleteTextField("recruiter", e.target.value);
        break;
      case "interviewTitle":
        setInterviewTitle(e.target.value);
        setErrors({});
        break;
      case "screenerName":
        handleAutoCompleteTextField("screener", e.target.value);
        setErrors({});
        break;
      case "candidateName":
        handleAutoCompleteTextField("candidate", e.target.value);
        setErrors({});
        break;
      case "clientName":
        handleAutoCompleteTextField("client", e.target.value);
        setErrors({});
        break;
      case "positionName":
        handleAutoCompleteTextField("position", e.target.value);
        setErrors({});
        break;
      // case "interviewTime":
      //     setInterviewTime(e.target.value);
      //     setErrors({});
      //     break;
      case "timeZone":
        setTimeZone(e.target.value);
        setErrors({});
        break;
      case "interviewDate":
        setBackupDate(e.target.value);
        setErrors({});
        break;
      case "interviewLink":
        setInterviewLink(e.target.value);
        setErrors({});
        break;
      case "interviewStatus":
        if (e.target.value == "Completed") setShowFeedback(true);
        setInterviewStatus(e.target.value);
        setErrors({});
        break;
      case "ratingsTitle":
        setScreenerRatingsTitle(e.target.value);
        setErrors({});
        break;
      case "ratingsReview":
        setScreenerReview(e.target.value);
        setErrors({});
        break;
      default:
    }
  };

  // view Tab handler
  const handleViewChange = (event, selectedView) => {
    if (selectedView === null) {
      setView(view);
    } else {
      setView(selectedView);
    }
    if (selectedView == "kanban") {
      setKanbanSelected(true);
    } else if (selectedView === "table") {
      setKanbanSelected(false);
    } else {
      setKanbanSelected(kanbanSelected);
    }
  };

  //ratings view handler
  const handleRatingsViewChange = (event, selectedView) => {
    switch (selectedView) {
      case "candidate rating":
        setRatingsView("candidate rating");
        break;
      case "screener rating":
        setRatingsView("screener rating");
        break;
      default:
    }
  };

  //Toggle handler
  const usersDBHandler = (role) => {
    if (role === "candidate") {
      setUsersDBCandidate((prev) => !prev);
      setInternalDBCandidate(false);
      setFavCandidateFilter(false);
    } else {
      setUsersDBScreener((prev) => !prev);
      setInternalDBScreener(false);
      setFavScreenerFilter(false);
    }
  };

  const internalDBHandler = (role) => {
    if (role === "candidate") {
      setInternalDBCandidate((prev) => !prev);
      setUsersDBCandidate(false);
      setFavCandidateFilter(false);
    } else {
      setInternalDBScreener((prev) => !prev);
      setUsersDBScreener(false);
      setFavScreenerFilter(false);
    }
  };

  const favDBHandler = (role) => {
    if (role === "candidate") {
      setFavCandidateFilter((prev) => !prev);
      setUsersDBCandidate(false);
      setInternalDBCandidate(false);
    } else {
      setFavScreenerFilter((prev) => !prev);
      setUsersDBScreener(false);
      setInternalDBScreener(false);
    }
  };

  //pagination handler
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const positionOptions = positionsList
    ?.filter((pos) => pos.status === "Active")
    .map((pos) => {
      return {
        name: pos.position,
        label: `${pos.position} - POS-${pos._id.slice(-5)}`,
        value: {
          id: pos._id,
          name: pos.position,
        },
      };
    });

  const screenerOptions = screenerList
    ?.filter((item) => item.accountStatus && item.primaryPhone)
    .map((screener) => {
      const fullName = screener.firstName + " " + screener.lastName;
      return {
        name: fullName,
        label: `${fullName} - SCR-${screener._id.slice(-5)} - ${screener.email
          }`,
        value: {
          id: screener._id,
          email: screener.email,
          name: fullName,
          screenerType: screener.screenerType,
        },
      };
    });

  const candidateOptions = candidateList
    ?.filter((item) => item.accountStatus && item.primaryPhone)
    .map((candidate) => {
      const fullName = candidate.firstName + " " + candidate.lastName;
      return {
        name: fullName,
        label: `${fullName} - CAN-${candidate._id.slice(-5)} - ${candidate.email
          }`,
        value: {
          id: candidate._id,
          email: candidate.email,
          name: fullName,
        },
      };
    });

  const clientOptions = clientsList
    ?.filter((item) => item.status)
    .map((client) => {
      return {
        name: client.companyName,
        label: `${client.companyName} - CLN-${client._id.slice(-5)} - ${client.email
          }`,
        value: {
          id: client._id,
          email: client.email,
          name: client.companyName,
        },
      };
    });

  //set the client name and details
  const handleClientData = (event, newValue) => {
    setClientName(newValue?.name);
    setClientDetails(newValue?.value);
    setPositionName("");
    setPositionDetails({ id: "", name: "" });
    getAllPositions(newValue?.value); //get all the positions of the respective client
  };

  //set the position name and details
  const handlePositionData = (event, newValue) => {
    setPositionName(newValue.name);
    setPositionDetails(newValue.value);
  };

  const dialogProps = {
    profileId: id,
    profileType,
    scheduleInterview,
    handleCloseScheduleInterview,
    mode,
    interviewsFilter,
    interviewStatus,
    interviewTitle,
    handleChangeDialogFields,
    errors,
    scheduledBy,
    clientDetails,
    setInterviewTime,
    handleClientData,
    setErrors,
    usersDBHandler,
    screenerDetails,
    usersDBScreener,
    internalDBHandler,
    internalDBScreener,
    favScreenerFilter,
    candidateOptions,
    candidateName,
    setCandidateName,
    setCandidateDetails,
    clientOptions,
    clientName,
    positionOptions,
    positionName,
    handlePositionData,
    screenerOptions,
    screenerName,
    setScreenerName,
    setScreenerDetails,
    favDBHandler,
    usersDBCandidate,
    internalDBCandidate,
    favCandidateFilter,
    backupDate,
    interviewTime,
    timeSlots,
    timeZone,
    timeList,
    generateJitsiLink,
    generateLinkHandler,
    customLink,
    meetLinkRef,
    customLinkHandler,
    linkLoader,
    interviewLink,
    meetingPlatform,
    showFeedback,
    view,
    handleRatingsViewChange,
    ratingsView,
    candidateRating,
    setCandidateRating,
    candidateRatingsTitle,
    setCandidateRatingsTitle,
    setCandidateReview,
    screenerRating,
    setScreenerRating,
    screenerRatingsTitle,
    screenerReview,
    screenerFeedback,
    markCompleted,
    setMarkCompleted,
    setDialogOpen,
    setOpenScheduleInterview,
    handleScheduleInterview,
    copyTextToClipboard,
    candidateReview,
    interviewTitleRef,
    clientRef,
    positionRef,
    screenerRef,
    candidateRef,
    scrollToElement,
    hours,
    setHours,
    meridian,
    setMeridian,
    minutes,
    setMinutes,
    notifyDialog,
    setNotifyDialog,
    checkAllFieldsFilled
  };

  const boardProps = {
    interviewsFilter,
    setOpenSnackbar,
    board,
    setBoard,
    updateStatusOnDrag,
    setMessage,
    moveCard,
    handleOpenEditInterview,
    copyTextToClipboard,
  };

  const tableProps = {
    profileType,
    columns,
    idFilter,
    setIdFilter,
    titleFilter,
    setTitleFilter,
    screenerFilter,
    setScreenerFilter,
    candidateFilter,
    setCandidateFilter,
    interviewTimeFilter,
    setInterviewTimeFilter,
    statusFilter,
    setStatusFilter,
    loading,
    TableLoader,
    allInterviews,
    page,
    rowsPerPage,
    handleOpenEditInterview,
    CopyToClipboard,
    handleChangeRowsPerPage,
    copyTextToClipboard,
    handleChangePage,
    sidebarOpen,
  };

  return (
    <>
      {hasInterviewsAccess ? (
        <div>
          {/* Navbar Component */}
          <CompanyMenu
            open={open}
            setOpen={setOpen}
            sidebarOpen={sidebarOpen}
            setsidebarOpen={setsidebarOpen}
            companyName={companyName}
            croppedImg={croppedImg}
            profileImage={profileImage}
            interacted={interacted}
            defaultAvatar={defaultAvatar}
          />

          {/* Main Page Component */}
          <div
            style={{
              marginLeft: `${sidebarOpen === "sidebar-open" ? "200px" : "0px"}`,
            }}
          >
            <Box sx={{ flexGrow: 1, background: "#faf9f6" }}>
              {/* Title Card */}
              <Card
                id="main-edit"
                className={`add-recruiter-form form-card ${profileType.toLowerCase() === "admin"
                  ? "interviews-top-card"
                  : ""
                  }`}
              >
                <Grid
                  container
                  spacing={0}
                  // direction="row"
                  className="add-recruiter-card-container interview-page-top-card"
                >
                  <Grid
                    item
                    xs={12}
                    sm={sidebarOpen === "sidebar-open" ? 3 : 4}
                    md={sidebarOpen === "sidebar-open" ? 3 : 4}
                    lg={6}
                    className="container-heading-title interview-page-title"
                  >
                    <h3>Interviews</h3>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={sidebarOpen === "sidebar-open" ? 8.5 : 7}
                    md={sidebarOpen === "sidebar-open" ? 8.5 : 7}
                    lg={6}
                    className="container-heading-buttons add-recruiter-button"
                    style={{ width: "100%" }}
                  >
                    <div className="main-button-container">
                      <div className="top-card-button-container">
                        <ToggleButtonGroup
                          value={view}
                          exclusive
                          onChange={handleViewChange}
                          aria-label="Platform"
                          className="toggle-btn-group"
                        >
                          <ToggleButton
                            value="kanban"
                            selected={kanbanSelected}
                            className={
                              view === "kanban"
                                ? "Mui-selected btn-ripple"
                                : "toggle-role-btn"
                            }
                            sx={{
                              background: "#5050502e",
                              color: "#505050",
                              padding: "3px 7px",
                              // fontSize: "11px",
                              fontFamily: "Raleway",
                              fontWeight: "600",
                              textTransform: "none",
                              border: "none",
                            }}
                          >
                            Kanban Board
                          </ToggleButton>
                          <ToggleButton
                            selected={!kanbanSelected}
                            className={
                              view === "table"
                                ? "Mui-selected "
                                : "toggle-role-btn"
                            }
                            value="table"
                            sx={{
                              background: "#5050502e",
                              color: "#505050",
                              padding: "3px 7px",
                              // fontSize: "11px",
                              fontWeight: "600",
                              border: "none",
                              fontFamily: "Raleway",
                              textTransform: "none",
                            }}
                          >
                            Table View
                          </ToggleButton>
                        </ToggleButtonGroup>
                      </div>
                      <div className="add-user-button-container">
                        <button
                          className="add-recruiter small-font-button"
                          onClick={handleOpenScheduleInterview}
                        >
                          Schedule Interview
                        </button>
                        <button
                          className="add-recruiter small-font-button"
                          onClick={handleOpenQuickInterview}
                        >
                          Quick Interview Schedule
                        </button>
                      </div>
                    </div>
                  </Grid>

                  <Grid
                    container
                    spacing={1}
                    direction="row"
                  // className="profile-progress-bar"
                  // style={{ paddingTop: "0px" }}
                  >
                    <Grid
                      id="profile-completion-percentage"
                      item
                      xs={12}
                      md={6}
                      sx={{ textAlign: "center" }}
                      className="container-profile"
                    >
                      {profileType.toLowerCase() == "admin" ? (
                        <form className="experience-edit-form" noValidate>
                          <div className="date-dropdown-picker interview-filters">
                            <div class="input-field-modal-interviews">
                              <select
                                style={{ outline: "none", width: "120%" }}
                                value={interviewsFilter}
                                onChange={interviewsFilterHandler}
                              >
                                <option value="All Interviews" selected>
                                  All Interviews
                                </option>
                                <option value="Scheduled by me">
                                  Scheduled by me
                                </option>
                              </select>
                            </div>

                            <div class="input-field" style={{ width: "65%" }}>
                              <input
                                style={{
                                  display:
                                    interviewsFilter === "Scheduled by me"
                                      ? "none"
                                      : "",
                                }}
                                type="text"
                                placeholder="Filter Recruiter Interviews"
                                name="recruiterName"
                                value={recruiterName}
                                onChange={(e) => {
                                  handleChangeDialogFields(e);
                                }}
                                onInput={(e) => {
                                  handleSetRecruiterFilter(e, e.target.value);
                                }}
                                list="datalistOptions"
                              />
                              <datalist id="datalistOptions">
                                {recruiterOptions.map((item) => (
                                  <option key={item.name} value={item.label} />
                                ))}
                              </datalist>
                            </div>
                          </div>
                        </form>
                      ) : (
                        ""
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Card>

              <div className="loading-container">
                {loading
                  ? <p className="message-div">Loading...</p>
                  : <>
                    <p className="message-div">
                      You are currently viewing {" "}
                      {profileType.toLowerCase() === "admin"
                        ? <span style={{ color: "#f06161" }}>
                          {Object.keys(recruiterDetails).length
                            ? `${recruiterDetails?.name}'s`
                            : interviewsFilter === "Scheduled by me"
                              ? "Your"
                              : "All"}
                        </span>
                        : <span style={{ color: "#f06161" }}>
                          Your
                        </span>
                      }
                      {" "} interviews
                    </p>
                    <button onClick={getAllInterviews} className="interview-refresh-btn">
                      <RefreshIcon />
                    </button>
                  </>}
              </div>
              {kanbanSelected ? (
                //  Kanban Board Component
                loading ? (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginTop: "10%",
                      color: "#f06161",
                    }}
                  >
                    <CircularProgress />
                  </Box>
                ) : (
                  <Card
                    // id="skills-edit"
                    className="interviews-kanban-card"
                    style={{ boxShadow: "none", background: "transparent" }}
                  >
                    <ControlledBoard {...boardProps} />
                  </Card>
                )
              ) : (
                <InterviewsTable {...tableProps} />
              )}
            </Box>
          </div>

          {/* Schedule Interview Dialog */}
          <InterviewDialog {...dialogProps} />

          {/* Snackbar modal */}
          <UserSnackBar
            openSnackbar={openSnackbar}
            setOpenSnackbar={setOpenSnackbar}
            message={message}
          />

          {/* Delete Confirmation Dialog */}
          <DeleteDialog
            setOpenScheduleInterview={setOpenScheduleInterview}
            setDialogOpen={setDialogOpen}
            dialogOpen={dialogOpen}
            handleCloseScheduleInterview={handleCloseScheduleInterview}
            setOpenSnackbar={setOpenSnackbar}
            setMessage={setMessage}
            getAllInterviews={getAllInterviews}
            compId={compId}
            oldInterviewStatus={oldInterviewStatus}
            interviewId={interviewId}
          />

          {/* Send Email Confirmation Dialog */}
          <NotifyConfirmationDialog
            handleScheduleInterview={handleScheduleInterview}
            setNotifyDialog={setNotifyDialog}
            notifyDialog={notifyDialog}
            setOpenScheduleInterview={setOpenScheduleInterview}
          />

          <Footer sidebarOpen={sidebarOpen} />
        </div>
      ) : (
        <PageNotFound />
      )}
    </>
  );
};

export default RecruiterInterview;
