import React from "react";

//external imports
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
//css imports
import Logo from "../../images/emailimg.jpg";
import "../auth/LoginComponent.css";
//Modal to show email verification message
const confirmationModal = (props) => {
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <img
        width="300"
        height="200"
        src={Logo}
        alt="email-img"
        style={{ margin: "0 auto" }}
      />
      {/* Modal body */}
      <Modal.Body>
        <h4 style={{ textAlign: "center" }}>Verify your Email!</h4>
        <p style={{ fontSize: "14px" }}>
          A verification link has been sent to <strong>{props.tempMail}</strong>
          . <br />
          Please click on the link to verify your email and activate your
          account.
        </p>
        <div style={{ textAlign: "center" }}>
          <Button
            onClick={props.onHide}
            style={{ borderRadius: "20px", width: "25%", textAlign: "center" }}
            className="btn-login"
          >
            Got it!
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default confirmationModal;
