import withStyles from "@material-ui/core/styles/withStyles";
import React, { Component } from "react";
import Modal from "react-modal";
// import Grid from "@material-ui/core/Grid";
// import Card from "@material-ui/core/Card";
// import CardHeader from "@material-ui/core/CardHeader";

const styles = (theme) => ({
    main: {
        width: "auto",
        display: "block", // Fix IE 11 issue.
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(400 + theme.spacing(6))]: {
            width: "100%",
            marginLeft: "auto",
            marginRight: "auto",
        },
    },

    card_calendar: {
        margin: "auto",
        height: "auto",
        width: "150%",
    },
    card: {
        margin: "auto",
        height: "auto",
        width: "auto",
    },
    calendar: {
        margin: "auto",
        height: "100%",
        width: "150%",
    },
    cardHeader: {
        background: "#69a1ff",
    },
    title: {
        color: "white",
    },
    button: {
        padding: theme.spacing(2),
        margin: "auto",
        width: "100%",
        align: "center",
    },
});

class TimeSlotModal extends Component {
    // constructor(props) {
    //     super(props);
    // }

    render() {
        return (
            <Modal>
                <h2 ref={(subtitle) => (this.subtitle = subtitle)}>Hello</h2>
                <button onClick={this.closeModal}>close</button>
                <div>I am a modal</div>
                <form>
                    <input />
                    <button>tab navigation</button>
                    <button>stays</button>
                    <button>inside</button>
                    <button>the modal</button>
                </form>
            </Modal>
        );
    }
}

export default withStyles(styles)(TimeSlotModal);
