import axios from "axios";

export const RECEIVE_INTERVIEWS = "RECEIVE_INTERVIEWS";
export const RECEIVE_INTERVIEW = "RECEIVE_INTERVIEW";
export const RECEIVE_INTERVIEW_ERRORS = "RECEIVE_INTERVIEW_ERRORS";
export const RECEIVE_UPDATED_INTERVIEW = "RECEIVE_UPDATED_INTERVIEW";

const receiveInterviews = (interviews) => {
    return {
        type: RECEIVE_INTERVIEWS,
        interviews,
    };
};

const receiveOneInterview = (interview) => {
    return {
        type: RECEIVE_INTERVIEW,
        interview,
    };
};

const receiveUpdatedInterview = (interview) => {
    return {
        type: RECEIVE_UPDATED_INTERVIEW,
        interview
    }
}

const receiveInterviewErrors = (errors) => {
    return {
        type: RECEIVE_INTERVIEW_ERRORS,
        errors,
    };
};

export const receiveAllInterviews = (userId) => (dispatch) => {
    axios
        .get("/salesforce/interview/all/" + userId)
        .then((interviews) => {
            dispatch(receiveInterviews(interviews));
        })
        .catch((err) => dispatch(receiveInterviewErrors(err)));
};

export const receiveInterview = (interviewId) => (dispatch) => {
    axios
        .get("/salesforce/interview/" + interviewId)
        .then((interview) => {
            dispatch(receiveOneInterview(interview));
        })
        .catch((error) => dispatch(receiveInterviewErrors(error)));
};

export const sendConfirmationInterview = (idx, enqueueSnackbar) => (dispatch) => {
    // console.log(`the interview id is ${idx}`);
    axios
            .post("/salesforce/interview/statusUpdate/" + idx, { status: "Scheduled-Confirmed" })
            .then((response) => {
                // console.log(response);
                let message = 'confirmed status change';
                enqueueSnackbar(message, {
                    variant: "info",
                    autoHideDuration: 3000,
                });
                dispatch(receiveUpdatedInterview(response));
            })
            .catch((err) => {
                let message = 'Something happened. please try again!';
                enqueueSnackbar(message, {
                    variant: "error",
                    autoHideDuration: 3000,
                });
            });
}

export const sendRescheduleInterview = (idx, enqueueSnackbar) => (dispatch) => {
    // console.log(`the interview id is ${idx}`);
        axios
            .post("/salesforce/interview/statusUpdate/" + idx, { status: "Reschedule" })
            .then((response) => {
                let message = 'Reschedule sent';
                enqueueSnackbar(message, {
                    variant: "info",
                    autoHideDuration: 3000,
                });
                dispatch(receiveUpdatedInterview(response));
            })
            .catch((err) => {
                let message = 'Something happened. please try again!';
                enqueueSnackbar(message, {
                    variant: "error",
                    autoHideDuration: 3000,
                });
            });
}

export const sendCompleteInterview = (idx, enqueueSnackbar) => (dispatch) => {
    // console.log(`the interview id is ${idx}`);
    axios
            .post("/salesforce/interview/statusUpdate/" + idx, { status: "Completed" })
            .then((response) => {
                // console.log(response);
                let message = 'complete status change';
                enqueueSnackbar(message, {
                    variant: "info",
                    autoHideDuration: 3000,
                });
                dispatch(receiveUpdatedInterview(response));
            })
            .catch((err) => {
                let message = 'Something happened. please try again!';
                enqueueSnackbar(message, {
                    variant: "error",
                    autoHideDuration: 3000,
                });
            });
}