import { connect } from "react-redux";
import { receiveAllReviews } from "../../actions/reviewActions";
import SalesforceReviews from "./salesforceReviews";

const mapStateToProps = (state) => ({
    auth: state.auth,
    reviews: state.reviews,
});

const mapDispatchToProps = (dispatch) => ({
    getReviews: (screenerId) => dispatch(receiveAllReviews(screenerId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SalesforceReviews);
