import { Typography } from '@material-ui/core';
import React, { useState } from 'react';
import SearchBar from '../searchbar/searchbar';
import './applicationCss.css';
// import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
// import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';
// import NearMeOutlinedIcon from '@material-ui/icons/NearMeOutlined';
// import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import ApplicationStatus from './applicationStatus';
import { Paper, Grid, Divider } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFrown } from '@fortawesome/free-solid-svg-icons';
import { CardHeader } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/core/styles';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

const Joblist = ({ applications }) => {
  const filterJobs = (unfilteredApplications, givenInput) => {
    if (!givenInput) {
      return unfilteredApplications;
    }
    return unfilteredApplications.filter((application) => {
      //  console.log(application);
      const app = application.Position_Title__c.toLowerCase();
      const company = application.related_company_name__c.toLowerCase();

      console.log(app);
      console.log(company);
      return app.includes(givenInput.toLowerCase()) || company.includes(givenInput.toLowerCase());
    });
  };
  const useStyles = makeStyles({
    card: {
      background: '#FFFFFF 0% 0% no-repeat padding-box',
      border: '3px solid #A5D6A7',
      borderRadius: '10px',
      opacity: '1',
      padding: '20px',
    },
  });

  const { search } = window.location;
  const input = new URLSearchParams(search).get('search');
  const [keyword, setKeyword] = useState(input || '');
  const filteredJobs = filterJobs(applications, keyword);

  const paperStyle = { padding: 10, height: '80%', width: '60%', margin: '0px 0px 20px 280px', display: 'flex', flexDirection: 'row' };
  const paperStyle1 = { padding: 10, display: 'flex', flexDirection: 'row' };
  const divStyle = { padding: '20px' };
  const fontStyle = { color: 'rgb(97, 186, 103)' };
  const classes = useStyles();
  if (applications.length !== 0) {
    return (
      <div>
        <div className="flex-fill d-flex flex-row mt-3 justify-content-center">
          <h3>Job Applications</h3>
          <SearchBar keyword={keyword} setKeyword={setKeyword} />
          <FontAwesomeIcon style={{ marginTop: '10px', marginLeft: '10px' }} size="2x" icon={faSearch} />
        </div>

        {filteredJobs.map((job, index) => (
          <div key={index}>
            <Paper elevation={10} style={paperStyle}>
              <div style={{ width: '40%' }}>
                <Typography className="typographyrole">{job.Position_Title__c}</Typography>

                <Typography className="typographyproduct">{job.related_company_name__c}</Typography>
                {job.action__c !== false ? (
                  <Typography className="typographyproduct" style={{ color: 'red' }}>
                    {job.comment__c}
                  </Typography>
                ) : (
                  <Typography className="typographyproduct"></Typography>
                )}
              </div>

              <div style={{ width: '60%' }}>
                <ApplicationStatus status={job.Application_Status__c} ifAction={job.action_needed__c} />
              </div>
            </Paper>
          </div>
        ))}
      </div>
    );
  } else {
    return (
      <div className="unfinPage-component">
        <Card className={classes.card}>
          <CardHeader align="center" title="Applications" />
          <Divider />
          <br />
          <Typography variant="subtitle2" style={{ color: 'gray' }} align="center">
            There are No Active Applications found. &nbsp;<FontAwesomeIcon icon={faFrown}></FontAwesomeIcon>{' '}
          </Typography>
        </Card>
      </div>
      //     <Grid item xs={12} sm={12}>
      //     <Paper elevation={10} style={paperStyle1}  >

      //     <div className="flex-fill d-flex flex-row mt-3 justify-content-center">
      //     <Typography style={fontStyle}><strong>There are No Active Applications found.</strong></Typography>
      //     </div>
      // </Paper>
      // </Grid>
    );
  }
};
export default Joblist;
