import React from "react";
import SimpleCards from "../../cards/SimpleCards";
// import PendingInterviewAlerts from "../../interview/alerts/pendingInterviewAlerts";
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Grid, Typography, Divider } from '@material-ui/core';
import PositionRequests from "../../positions/positionRequest";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        height:'400px',
        overflow: 'auto',
        border: '2px solid #75a478 '
    },
}));

export default function DashRowTwo(props) {
    const classes = useStyles();
    const { cardStyle } = props;
    return (
        <div className="d-flex flex-row justify-content-between">
            {/* <div className="flex-fill maxw-500">
                <SimpleCards cardStyle={cardStyle} title="Pending Interview Assignments">
                    <PendingInterviewAlerts />
                </SimpleCards>
            </div>
            <div className="flex-fill maxw-500 ml-3">
                <SimpleCards cardStyle={cardStyle} title="Pending Reviews">
                    <PositionRequests />
                </SimpleCards>
            </div> */}
            <div className={classes.root}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={8
                    }>
                        <Paper className={classes.paper} elevation={5}>
                            <Typography className="typographyStyle">Pending Reviews Assignments</Typography>
                            <PositionRequests/>
                        </Paper>
                    </Grid>
                </Grid>
            </div>


        </div>
    );
}
