export const RECEIVE_HOME_COMPONENT = 'RECEIVE_HOME_COMPONENT';

const receiveHomeComponent = (homeComponent) => {
    return {
        type: RECEIVE_HOME_COMPONENT,
        homeComponent
    };
};

export const receiveHomeComponentAction = (homeComponent) => dispatch => {
    dispatch(receiveHomeComponent(homeComponent))
}