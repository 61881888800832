import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Slide from "@mui/material/Slide";
import { Divider, IconButton } from "@mui/material";
import TablePagination from "@mui/material/TablePagination";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DeleteMessageIcon from "@mui/icons-material/ErrorOutlineOutlined";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Tooltip from "@mui/material/Tooltip";
import Axios from "axios";

import UserSnackbar from "../../../snackBars/UserSnackbar";
import TableLoader from "../../../loaders/TableLoader";
import DeleteMessageSnackbar from "../../../snackBars/deleteMessageSnackbar";

const UserRole = ({ setPageName, pageName, sidebarOpen, setMainSettings, mainSettings, setSelectedRole }) => {

    //table heading
    const columns = [
        {
            title: "Role",
            width: "25%",
        },
        {
            title: "Description",
            width: "30%",
        },
        {
            title: "Privileges",
            width: "30%",
        },
        {
            title: "Action",
            width: "15%",
        },
    ];

    //company id
    const id = sessionStorage.getItem("id"); //recruiter id
    const compId = sessionStorage.getItem("compId")

    //Loader
    const [tableLoading, setTableLoading] = useState(true);

    //pagination states
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [page, setPage] = React.useState(0);

    //Form states
    const [userArray, setUserArray] = useState([]);

    //Delete dialog states
    const [openDelete, setOpenDelete] = useState(false);
    const [selectedItem, setSelectedItem] = useState({}); //selected item to delete

    //snackbar states
    const [openDeleteSnackbar, setOpenDeleteSnackbar] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [message, setMessage] = useState("");
    //dialog handler
    const deleteDialogHandler = (item) => {
        setSelectedItem(item);
        setOpenDelete(true);
    };

    //delete user role
    const deleteHandler = () => {
        Axios.post(`/api/admin/delete/userRole/${compId}`, { selectedItem })
            .then((res) => {
                console.log(res)
                setOpenDelete(false);
                setOpenDeleteSnackbar(true);
            })
            .catch((err) => {
                if (err.response.status == 400) {
                    console.log(err.response.data.message);
                    setOpenDelete(false);
                    setTimeout(() => {
                        setMessage(err.response.data.message);
                        setOpenSnackbar(true)
                    }, 1000)

                }
            });
    };

    //fetching all user roles from backend
    useEffect(() => {
        Axios.get(`/api/admin/userRole/${compId}`)
            .then((res) => {
                if (res.data.length > 0) {
                    const reverseData = res.data[0].profileType.reverse();
                    setUserArray(reverseData);
                } else {
                    setUserArray(res.data);
                }
                setTableLoading(false);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [openDelete, pageName]);

    //back button handler
    const itemHandler = () => {
        setMainSettings({
            ...mainSettings,
            userroles: false,
            settings: true,
        });
        setPageName("settings")
    };

    //pagination handler
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    //add user role button handler
    const formHandler = () => {
        setPageName("addUserRole")
    };

    //edit user role button handler
    const roleEditHandler = (index, item) => {
        setSelectedRole(item);
        setPageName("editUserRole")
    };


    return (
        <div style={{ marginLeft: `${sidebarOpen === "sidebar-open" ? "200px" : "0px"}` }}>
            {/* Recruiter List Component */}
            <Box sx={{ flexGrow: 1, background: "#faf9f6" }}>
                <Card id="main-edit" className="add-recruiter-form form-card">
                    <Grid
                        container
                        spacing={0}
                        // direction="row"
                        className="add-recruiter-card-container"
                    >
                        <Grid
                            item
                            xs={12}
                            sm={sidebarOpen === "sidebar-open" ? 12 : 4}
                            md={sidebarOpen === "sidebar-open" ? 12 : 4}
                            lg={6}
                            className="container-heading-title admin-table"
                        >
                            <h3>
                                <span className="previousHighlighter" onClick={itemHandler}>
                                    Settings
                                </span>
                                {` > User Role `}
                            </h3>
                        </Grid>

                        <Grid
                            container
                            spacing={1}
                            direction="row"
                        // className="profile-progress-bar"
                        >
                            <Grid
                                id="profile-completion-percentage"
                                item
                                xs={12}
                                md={12}
                                sx={{ textAlign: "center" }}
                                className="container-profile"
                            >
                                <div className="table-buttons">
                                    <button className="add-recruiter " onClick={itemHandler}>
                                        Back
                                    </button>

                                    <button
                                        className="add-recruiter outlined-btn "
                                        onClick={formHandler}
                                    >
                                        Add User Role
                                    </button>

                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Card>


                <Card
                    id="skills-edit"
                    className="add-recruiter-form-container "
                    style={{ boxShadow: "none", borderRadius: "2px" }}
                >
                    <div className="recruiter-list">
                        <Grid
                            item
                            xs={12}
                            md={12}
                            lg={12}
                            className="recruiter-list-heading"
                        >
                            {columns.map((item, index) => {
                                return (
                                    <h6
                                        style={{
                                            width: `${item.width}`,
                                            background: index % 2 ? "rgba(240, 97, 97, 37%)" : "rgba(240, 97, 97, 17%)",
                                            color: "#283848",
                                        }}
                                    >
                                        {item.title}
                                    </h6>
                                );
                            })}
                        </Grid>

                        {tableLoading ? (
                            <TableLoader page="userRoleList" />
                        ) : (
                            <div className="recruiter-details-list">
                                {userArray.length > 0 ? (
                                    userArray
                                        .slice(
                                            page * rowsPerPage,
                                            page * rowsPerPage + rowsPerPage
                                        )
                                        .map((item, index) => {
                                            let firstElement = 1;

                                            return (
                                                <>
                                                    <div
                                                        className={` recruiter-details ${index % 2
                                                            ? ""
                                                            : "alternate-recruiterlist-bg"
                                                            }`}
                                                    >
                                                        <div className="user-role" style={{ width: "26%" }}>
                                                            <p style={{ width: "240px", marginLeft: "15px" }}>{item.roleName.toLowerCase() === "admin" ? "Administrator" : item.roleName.charAt(0).toUpperCase() + item.roleName.slice(1)}</p>
                                                        </div>

                                                        <div className="user-role-description " style={{ width: "30%" }}>
                                                            {item.description.length > 24 ? (
                                                                <Tooltip title={`${item.description}`}>
                                                                    <p style={{ width: "305px" }}>{item.description}</p>
                                                                </Tooltip>
                                                            ) : (
                                                                <p style={{ width: "305px" }}>{item.description}</p>
                                                            )}
                                                        </div>
                                                        <div className="user-role-privileges" style={{ width: "35%" }}>
                                                            <p style={{ width: "320px" }}>{item.modules.map((element, index) => {
                                                                const { checked, title } = element;
                                                                if (checked) {
                                                                    if (firstElement === 1) {
                                                                        firstElement += 1;
                                                                        return title;
                                                                    } else return ", " + title;
                                                                }
                                                            })}</p>
                                                        </div>

                                                        <div className="user-role-action-buttons">
                                                            <div className="action-button-icon">
                                                                <Tooltip title="Edit">
                                                                    <IconButton className="user-role-action-button">
                                                                        <EditIcon
                                                                            style={{ color: '#2c3e50' }}
                                                                            fontSize="small"
                                                                            onClick={() =>
                                                                                roleEditHandler(index, item)
                                                                            }
                                                                        />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </div>

                                                            <div className="action-button-icon">
                                                                <Tooltip title="Delete">

                                                                    <IconButton className="user-role-action-button" disabled={
                                                                        (item.roleName.toLowerCase() === "admin" || item.roleName.toLowerCase() === "recruiter") ?
                                                                            true :
                                                                            false
                                                                    }>
                                                                        <DeleteIcon
                                                                            style={{
                                                                                color: (item.roleName.toLowerCase() === "admin" || item.roleName.toLowerCase() === "recruiter") ?
                                                                                    "" :
                                                                                    "#2c3e50"
                                                                            }}
                                                                            disabled={true}
                                                                            fontSize="small"
                                                                            onClick={() =>
                                                                                deleteDialogHandler(item)
                                                                            }
                                                                        />
                                                                    </IconButton>

                                                                </Tooltip>
                                                            </div>
                                                        </div>
                                                    </div >

                                                </>
                                            );
                                        })
                                ) : (
                                    <p>No User Roles</p>
                                )}
                            </div>
                        )}
                    </div>

                    <TablePagination
                        id="recruiter-list-pagination"
                        component="div"
                        rowsPerPageOptions={[10, 25, 50, 100]}
                        count={userArray.length}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        variant="outlined"
                    />
                </Card>
            </Box>

            <Dialog
                open={openDelete}
                onClose={openDelete}
                className="experience-edit-dialog"
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent className="dialog-content">
                    <Grid container spacing={0} justifyContent="center">
                        <Grid
                            xs={11.5}
                            sm={11.5}
                            md={11.5}
                            lg={11.5}
                            className="experience-delete-grid"
                        >
                            <div className="delete-message-icon">
                                <DeleteMessageIcon fontSize="small" />
                            </div>
                            <div className="delete-dialog-content">
                                <h5>Are you sure ?</h5>
                                <p>Are you sure you want to delete the current user role?</p>
                                <p>This process cannot be reverted</p>
                            </div>
                        </Grid>
                    </Grid>
                </DialogContent>
                <div className="button-container delete-btn-container">
                    <button className="warning-btn-style filled-btn filled-btn-animation" onClick={() => setOpenDelete(false)}>
                        {" "}
                        Cancel
                    </button>
                    <button className="warning-btn-style outlined-btn outlined-btn-animation" onClick={deleteHandler}>
                        {" "}
                        Confirm
                    </button>
                </div>
            </Dialog>
            <DeleteMessageSnackbar
                openDeleteSnackbar={openDeleteSnackbar}
                setOpenDeleteSnackbar={setOpenDeleteSnackbar}
            />
            < UserSnackbar
                openSnackbar={openSnackbar}
                setOpenSnackbar={setOpenSnackbar}
                message={message}
            />

        </div >
    );
};

export default UserRole;
