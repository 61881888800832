import Grid from "@material-ui/core/Grid";
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from "@material-ui/core/TextField";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { logoutUser } from "../../../actions/authActions";

const styles = (theme) => ({
    main: {
        width: "auto",
        display: "block", // Fix IE 11 issue.
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        [theme.breakpoints.up(400 + theme.spacing(6))]: {
            width: "50%",
            marginLeft: "auto",
            marginRight: "auto",
        },
    },

    textField: {
        marginTop: theme.spacing(5),
        marginLeft: theme.spacing(),
        marginRight: theme.spacing(),
        width: "500px",
    },
});
class candidateInformation extends Component {
    render() {
        const { values, handleChange, classes } = this.props;
        return (
            <div>
                <main className={classes.main}>
                    <Grid container spacing={10}>
                        <br />

                        <TextField
                            id="candidateName"
                            name="candidateName"
                            value={values.candidateName}
                            onChange={handleChange("candidateName")}
                            label="Candidate Name"
                            className={classes.textField}
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            autoComplete="comment"
                        />

                        <TextField
                            id="jobTitle"
                            name="jobTitle"
                            value={values.jobTitle}
                            onChange={handleChange("jobTitle")}
                            label="Job title applied for"
                            className={classes.textField}
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            autoComplete="comment"
                        />
                    </Grid>
                </main>
            </div>
        );
    }
}

candidateInformation.propTypes = {
    logoutUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
    auth: state.auth,
});
export default withStyles(styles)(connect(mapStateToProps, { logoutUser })(candidateInformation));
