import React from "react";

//external imports
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
//css imports
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";

import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import "../auth/LoginComponent.css";
//Modal to show email verification message
const SocialRegisterModal = (props) => {
    return (
        <Modal

            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <img
                width="30"
                height="30"
                src={props.icon == 'google' && "https://cdn-icons-png.flaticon.com/512/2991/2991148.png"}
                alt="email-img"
                style={{ margin: "20px auto -6px auto" }}
            />
            {/* Modal body */}
            <Modal.Body>
                <h6 style={{ textAlign: "center" }}>Sign Up as*</h6>
                {/* <p style={{ fontSize: "14px" }}>
                    A verification link has been sent to <strong>{props.tempMail}</strong>
                    . <br />
                    Please click on the link to verify your email and activate your
                    account.
                </p> */}
                {/* radio btns */}

                <div class="login-radio-container" >


                    <FormControl class="login-radio-group" style={{ justifyContent: 'center' }}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            value={props.role}
                            onChange={(e) => {
                                props.setRole(e.target.value);
                            }}
                        >
                            <FormControlLabel
                                value="candidate"
                                control={
                                    <Radio
                                        size="small"
                                        sx={{
                                            "& .MuiSvgIcon-root": {
                                                fontSize: 18,
                                            },
                                        }}
                                        required={true}
                                    />
                                }
                                label="Job Seeker"
                            />
                            <FormControlLabel
                                value="screener"
                                control={
                                    <Radio
                                        size="small"
                                        sx={{
                                            "& .MuiSvgIcon-root": {
                                                fontSize: 18,
                                            },
                                        }}
                                        required={true}
                                    />
                                }
                                label="Screener"
                            />

                        </RadioGroup>
                    </FormControl>
                </div>
                <div style={{ textAlign: "center", marginTop: "15px" }}>
                    <Button
                        onClick={() => props.socialMediaHandler(props.icon)}
                        style={{ fontSize: 'smaller', borderRadius: "20px", width: "18%", textAlign: "center" }}
                        className="btn-login"
                    >
                        Submit
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default SocialRegisterModal;
