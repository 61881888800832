import DateFnsUtils from "@date-io/date-fns";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from "@material-ui/core/TextField";
import axios from "axios";
import {
  DatePicker,
  MuiPickersUtilsProvider,
  TimePicker,
} from "material-ui-pickers";
import PropTypes, { string } from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { withSnackbar, useSnackbar } from "notistack";
import Rating from "material-ui-rating";
import { Link } from "react-router-dom";

import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Dialog from "@mui/material/Dialog";

const styles = (theme) => ({
  main: {
    width: "auto",
    display: "block", // Fix IE 11 issue.
    marginTop: theme.spacing(5),
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(6))]: {
      width: "50%",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing(2),
    display: "flex",
    width: "100%",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(
      3
    )}px`,
  },
  button: {
    padding: theme.spacing(2),
    margin: 10,
    alignItems: "center",
  },

  textField: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    width: "100px",
  },

  textFieldBox: {
    marginTop: theme.spacing() * 1,
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    width: "500px",
  },
  select: {
    marginTop: theme.spacing() * 1,
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    width: "250px",
  },
  formControl: {
    margin: theme.spacing(),
    minWidth: 120,
    maxWidth: 300,
  },
  ratingWrapper: {
    padding: "16px",
    height: "70px",
  },
  iconButton: {
    padding: "17px",
    width: "100px",
  },
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

class UpdateReview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      _id: this.props.match.params.id,
      comments: string,
      //recruiter: string,
      // recruiterList: [],
      screener: string,
      screenerList: [],
      numberRating: 0,
      loading: true,
      open: false,
      openDialog: false,
    };
    console.log(this.props.match.params.id);
  }

  //Open back dialog box
  handleClickOpen = () => {
    return this.setState({ open: true });
  };

  //Open update dialog box
  handleClickOpenDialog = () =>{
    return this.setState({openDialog:true});
  }

  //Close the dialog box
  handleClose = () => {
    this.setState({ open: false });
    this.setState({ openDialog: false });
  };

  componentDidMount() {
    // axios.get("api/users/recruiter").then((response) => {
    //     this.setState({
    //         recruiterList: response.data,
    //     });
    // });

    axios.get("/api/users/screener").then((response) => {
      this.setState({
        screenerList: response.data,
      });
    });

    axios.get("/api/review/rating/" + this.state._id).then(
      (res) => {
        var newState = {
          comments: res.data.comments,
          numberRating: res.data.numberRating,
          screener: res.data.screener,
          //recruiter: res.data.recruiter,
        };

        this.setState(newState);
        this.setState({ loading: false });
      },
      (err) => {
        alert("An error occured! Try refreshing the page.", err);
      }
    );
  }
  // handleRecruiterChange = (event) => {
  //     this.setState({ recruiter: event.target.value });
  // };

  handleScreenerChange = (event) => {
    this.setState({ screener: event.target.value });
  };

  handleInputChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleRatingChange = (event) => {
    this.setState({ numberRating: event.target.value });
  };

  onSubmit = (e) => {
    e.preventDefault();
    const { _id, comments, numberRating, screener } = this.state;

    var review = {
      _id,
      comments,
      numberRating,
      screener,
    };
    if (
      this.state.screener &&
      this.state.comments &&
      this.state.numberRating
    ) {
    axios
      .put("/api/review/reviewupdate/" + this.state._id, review)

      .then((res) => {
        //alert('Updated successfully');
        let regMessage = <span>Updated Review Successfully</span>;
        this.props.enqueueSnackbar(regMessage, {
          variant: "success",
          autoHideDuration: 3000,
        });
        this.props.history.push("/review-list");
      })
      .catch((err) => {
        console.log(err.response);
        // alert('An error occured try submitting the form again');
        let regMessage = (
          <span>An error occured try submitting the form again</span>
        );
        this.props.enqueueSnackbar(regMessage, {
          variant: "error",
          autoHideDuration: 3000,
        });
      });
    } else {
      let fillMessage = <span>Fill in required fields</span>;
      this.props.enqueueSnackbar(fillMessage, {
        variant: "error",
        autoHideDuration: 3000,
        // preventDuplicate: false,
      });
    }
  };

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <div className={classes.main}>
          <Paper className={classes.paper}>
            <br />
            <Typography variant="h6">Update Review</Typography>
            <Dialog
              open={this.state.open}
              onClose={this.handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                Warning: All your current changes will be lost when you leave.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={this.handleClose}
                  style={{ backgroundColor: "#D73B30", color: "white" }}
                >
                  Cancel
                </Button>
                <Link
                  to="/review-list"
                  style={{ color: "white", textDecoration: "none" }}
                >
                  <Button
                    autoFocus
                    style={{
                      backgroundColor: "#75A478",
                      color: "white",
                      margin: "6px",
                    }}
                  >
                    Confirm
                  </Button>
                </Link>
              </DialogActions>
            </Dialog>

            {/* Save Diaglog */}
            <Dialog
              open={this.state.openDialog}
              onClose={this.handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Warning: Do you want to update the current changes and exit?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={this.handleClose}
                  style={{ backgroundColor: "#D73B30", color: "white" }}
                >
                  Cancel
                </Button>
                <Button
                  autoFocus
                  type="submit"
                  // className={classes.submit}
                  onClick={this.onSubmit}
                  style={{
                    backgroundColor: "#75A478",
                    color: "white",
                    margin: "6px",
                  }}
                  // onClick={this.onSubmit}
                  // href={this.handleProfileEdit()}
                >
                  Update
                </Button>
              </DialogActions>
            </Dialog>

            <form className={classes.form} onSubmit={this.onSubmit}>
              <Grid container spacing={24} style={{ padding: "2em" }}>
                {/* <Grid item xs={12} sm={12}>
                                <FormControl className={classes.select} >
                                    <InputLabel htmlFor="select-multiple-checkbox">Select Your Name</InputLabel>
                                    <Select
                                        // multiple
                                        value={this.state.recruiter}
                                        onChange={this.handleRecruiterChange}
                                        // input={<Input id="select-multiple-checkbox" />}
                                        // renderValue={(selected) => "Selected"}
                                        autoComplete="on"
                                        MenuProps={MenuProps}
                                    >
                                        {this.state.recruiterList.map((recruiterListItem) => (
                                            <MenuItem key={recruiterListItem._id} value={recruiterListItem._id}>
                                                
                                                <ListItemText primary={recruiterListItem.name} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid> */}
                <Grid item xs={12} sm={6}>
                  <FormControl className={classes.select}>
                    <InputLabel htmlFor="select-multiple-checkbox">
                      Screener *
                    </InputLabel>
                    <Select
                      // multiple
                      value={this.state.screener}
                      onChange={this.handleScreenerChange}
                      // input={<Input id="select-multiple-checkbox" />}
                      // renderValue={(selected) => "Selected"}
                      autoComplete="on"
                      MenuProps={MenuProps}
                    >
                      {this.state.screenerList.map((screenerListItem) => (
                        <MenuItem
                          key={screenerListItem._id}
                          value={screenerListItem._id}
                        >
                          {/* <Checkbox checked={this.state.screenerList.indexOf(screenerList) >-1} /> */}
                          <ListItemText
                            primary={screenerListItem.name}
                            secondary={screenerListItem.uniqueMatchId}
                          />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={12}>
                  <TextField
                    name="comments"
                    onChange={this.handleInputChange}
                    fullWidth
                    multiline
                    className={classes.textFieldBox}
                    value={this.state.comments}
                    margin="normal"
                    variant="outlined"
                    label="Comment *"
                  />
                </Grid>

                <Grid item xs={12} sm={12}>
                  <InputLabel htmlFor="select-multiple-checkbox">
                    Select Rating *
                  </InputLabel>
                  <Select
                    id="numberRating"
                    name="numberRating"
                    displayEmpty
                    value={this.state.numberRating}
                    fullWidth
                    onChange={this.handleRatingChange}
                    label="Rating"
                    autoComplete="numberRating"
                  >
                    <MenuItem value="">
                      <em>select the value</em>
                    </MenuItem>
                    <MenuItem value={1}>1 - Unsatisfactory</MenuItem>
                    <MenuItem value={2}>2 - Below Average</MenuItem>
                    <MenuItem value={3}>3 - Average</MenuItem>
                    <MenuItem value={4}>4 -Above Average</MenuItem>
                    <MenuItem value={5}>5 - Exceptional</MenuItem>
                  </Select>
                </Grid>

                <Grid item xs={12} sm={12}>
                  <br />
                  <br />
                  <br />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      flexWrap: "wrap",
                      float: "left",
                    }}
                  >
                    <Button
                      onClick={this.handleClickOpen}
                      variant="contained"
                      color="secondary"
                      className={classes.submit}
                      style={{
                        marginRight: "12px",
                        backgroundColor: "#D73B30",
                        color: "white",
                      }}
                    >
                      Back
                    </Button>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      flexWrap: "wrap",
                    }}
                  >
                    <Button
                      style={{ color: "white" }}
                      // type="submit"
                      variant="contained"
                      color="secondary"
                      // className={classes.submit}
                      onClick={this.handleClickOpenDialog}
                    >
                      Update Review
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </form>
            <br />
          </Paper>
        </div>
      </React.Fragment>
    );
  }
}

UpdateReview.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default withSnackbar(
  withStyles(styles)(connect(mapStateToProps, { logoutUser })(UpdateReview))
);
