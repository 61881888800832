import React, { useEffect, useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

const CalendarChart = ({ currentDate, completedIntDates, upcomingIntDates }) => {

    const [value, onChange] = useState(new Date());
    const [completedInterviews, setCompletedInterviews] = useState([])
    const [upcomingInterviews, setUpcomingInterviews] = useState([])

    useEffect(() => {
        let date = ""
        let year = "";
        let month = "";
        let day = "";
        const completedDates = []
        completedIntDates.map(item => {
            console.log(item)
            date = item.split("-");
            year = date[0];
            month = date[1];
            day = date[2];
            const dateObj = {
                year: year,
                month: month,
                day: day
            }
            completedDates.push(dateObj)
        })
        setCompletedInterviews(completedDates)

        const upcomingDates = []
        upcomingIntDates.map(item => {
            console.log(item)
            date = item.split("-");
            year = date[0];
            month = date[1];
            day = date[2];
            const dateObj = {
                year: year,
                month: month,
                day: day
            }
            upcomingDates.push(dateObj)
        })
        setUpcomingInterviews(upcomingDates)

    }, [completedIntDates, upcomingIntDates])

    console.log("Completed ", completedInterviews, "Upcoming", upcomingInterviews)
    return (
        <Calendar
            onChange={onChange}
            value={value}
            tileClassName={({ date, view }) => {
                let foundCompleted = false;
                let foundUpcoming = false;
                const fullYear = date.getFullYear();
                const month = (date.getMonth() + 1).toString().padStart(2, '0');
                const day = date.getDate().toString().padStart(2, '0');
                upcomingInterviews.map((item) =>
                    item.year == fullYear && item.month == month && item.day == day && (foundUpcoming = true)
                )
                completedInterviews.map((item) =>
                    item.year == fullYear && item.month == month && item.day == day && (foundCompleted = true)
                )
                return foundUpcoming === true ? 'green' : foundCompleted === true ? 'blue' : null
                // return foundCompleted === true && 'green'


            }}
        />
    )
}

export default CalendarChart