import React, { useEffect } from "react";

import { Link } from "react-router-dom";
import "./tableList.css";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import { candidates } from "../../actions/userActions";
import LaunchIcon from "@mui/icons-material/Launch";
import SearchBar from "../searchbar/searchbar";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  DatePicker,
  MuiPickersUtilsProvider,
  TimePicker,
} from "material-ui-pickers";
import DateFnsUtils from "@date-io/date-fns";

import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Dialog from "@mui/material/Dialog";
import { Typography } from "@mui/material";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core";
import {
  Container,
  Grid,
  Paper,
  Box,
  TextareaAutosize,
  Divider,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  main: {
    width: "auto",
    display: "block", // Fix IE 11 issue.
    marginTop: theme.spacing(-2),
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(6))]: {
      width: "50%",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing(-5),
    display: "flex",
    width: "100%",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(
      -15
    )}px`,
  },
  button: {
    padding: theme.spacing(2),
    margin: 10,
    alignItems: "center",
  },

  textField: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    width: "100px",
  },

  textFieldBox: {
    marginTop: theme.spacing() * 1,
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    width: "500px",
  },
  select: {
    marginTop: theme.spacing() * 1,
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    width: "250px",
  },
  formControl: {
    margin: theme.spacing(),
    minWidth: 120,
    maxWidth: 300,
  },
}));

const CandidateList = (history) => {
  const [candidateList, setCandidateList] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [info, setInfo] = React.useState({
    cname: "",
    currentitle: "",
    email: "",
    dob: "",
    preferredPosition: "",
    skills: "",
    phone: "",
    role: "",
    status: "",
    yearsOfExperience: "",
    highestDegree: "",
    major: "",
    englishProficiency: "",
    address_line1: "",
    address_line2: "",
    city: "",
    state: "",
    country: "",
    postalCode: "",
  });
  const { search } = window.location;
  const input = new URLSearchParams(search).get("search");
  const [keyword, setKeyword] = React.useState(input || "");

  //Open the dialog box
  const handleClickOpen = (id) => {
    // console.log(id);
    const candidateInfo = candidateList.filter((item) => {
      return item._id === id;
    });

    console.log(candidateInfo);
    console.log(candidateInfo[0].candidate_info.preferredPosition);

    setInfo({
      cname: candidateInfo[0].name,
      currentitle: candidateInfo[0].professional_details.currentTitle,
      email: candidateInfo[0].email,
      dob: candidateInfo[0].dob,
      preferredPosition: candidateInfo[0].candidate_info.preferredPosition,
      skills: candidateInfo[0].skills,
      phone: candidateInfo[0].phone,
      role: candidateInfo[0].role,
      status: candidateInfo[0].status,
      yearsOfExperience:
        candidateInfo[0].professional_details.yearsOfExperience,
      highestDegree: candidateInfo[0].professional_details.highestDegree,
      major: candidateInfo[0].professional_details.major,
      englishProficiency:
        candidateInfo[0].professional_details.englishProficiency,
      address_line1: candidateInfo[0].address.address_line1,
      address_line2: candidateInfo[0].address.address_line2,
      city: candidateInfo[0].address.city,
      state: candidateInfo[0].address.state,
      country: candidateInfo[0].address.country,
      postalCode: candidateInfo[0].address.postalCode,
    });

    return setOpen(true);
  };

  //Close the dialog box
  const handleClose = () => {
    return setOpen(false);
  };

  //const { users } = useSelector((state) => state.candidatelist)
  // console.log(users)

  const filterCandidates = (unfilteredcandidates, givenInput) => {
    if (!givenInput) {
      return unfilteredcandidates;
    }
    return unfilteredcandidates.filter((candidate) => {
      // console.log(candidate);
      const name = candidate.name.toLowerCase();
      const email = candidate.email.toLowerCase();
      const role = candidate.role.toLowerCase();
      const date = String(candidate.date).substr(0, 10);
      const currentTitle =
        candidate.professional_details.currentTitle.toLowerCase();
      const status = candidate.status.toLowerCase();
      const uniqueMatchId = String(candidate.uniqueMatchId).toLowerCase();
      //console.log(app);
      //console.log(company);
      return (
        name.includes(givenInput.toLowerCase()) ||
        email.includes(givenInput.toLowerCase()) ||
        currentTitle.includes(givenInput.toLowerCase()) ||
        status.includes(givenInput.toLowerCase()) ||
        role.includes(givenInput.toLowerCase()) ||
        date.includes(givenInput.toLowerCase()) ||
        uniqueMatchId.includes(givenInput.toLowerCase())
      );
    });
  };

  const columns = [
    {
      field: "id",
      // headerName: "",
      width: -1,
      flex: -1,
      hide: true,
    },
    {
      field: "uniqueMatchId",
      headerName: "Candidate Id",
      minWidth: 150,
      flex: 0.3,
    },

    {
      field: "name",
      headerName: "Name",
      minWidth: 150,
      flex: 0.3,
    },
    {
      field: "email",
      headerName: "Email",
      minWidth: 150,
      flex: 0.3,
    },

    {
      field: "role",
      headerName: "Role",

      minWidth: 150,
      flex: 0.3,
    },
    {
      field: "status",
      headerName: "Status",

      minWidth: 150,
      flex: 0.3,
    },
    {
      field: "date",
      headerName: "Created Date",

      minWidth: 150,
      flex: 0.3,
    },
    {
      field: "currentTitle",
      headerName: "Current Title",

      minWidth: 150,
      flex: 0.3,
    },
    {
      field: "actions",
      flex: 0.3,
      headerName: "Actions",
      minWidth: 150,
      // type: "number",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            &nbsp;&nbsp;
            {/* <LaunchIcon /> */}
            <VisibilityIcon
              style={{ color: "#75a478", cursor: "pointer" }}
              onClick={() => handleClickOpen(params.id)}
            />
            &nbsp;&nbsp;
          </>
        );
      },
    },
  ];

  const rows = [];

  const filteredCandidates = filterCandidates(candidateList, keyword);

  if (candidateList.length !== 0) {
    {
      filteredCandidates &&
        filteredCandidates.map((item) =>
          rows.push({
            name: item.name,
            id: item._id,
            email: item.email,
            role: item.role,
            status: item.status,
            currentTitle: item.professional_details.currentTitle,
            date: String(item.date).substr(0, 10),
            uniqueMatchId: item.uniqueMatchId,
          })
        );
    }
  }

  //  console.log(item.name)
  //  console.log(item._id)
  //  console.log(item.email)
  //  console.log(item.role)

  useEffect(() => {
    axios.get("/api/users/candidate").then((response) => {
      setCandidateList(response.data);
    });
  }, []);

  const classes = useStyles();

  return (
    <>
      <br /> <br /> <br /> <br /> <br /> <br />
      {/* {console.log(users.data.length)} */}
      <SearchBar keyword={keyword} setKeyword={setKeyword} />
      <Dialog
        open={open}
        fullWidth
        maxWidth="md"
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
        <Typography variant="h6" style={{ textAlign: "center" }}>
                              <b> Candidate Information{" "}</b> 
                                <Divider style={{ width: "25%", margin: "auto" }} />
                                {/* <br /> */}
                            </Typography>
          {/* <Link to="/screener-review-list">Back</Link> */}
          <form className={classes.form}>
            <Grid container spacing={24} style={{ padding: "2em" }}>
              <Grid item xs={12} sm={8}>
                <Typography variant="subtitle1" style={{ color: "gray" }}>
                  Personal Details
                  <Divider />
                  <br />
                </Typography>
              </Grid>
              <Grid item xs={12} sm={5}>
                <div>
                  <label>Name</label>
                  <TextField
                    disabled
                    id="standard-disabled"
                    value={info.cname}
                    variant="standard"
                    fullWidth
                  />
                </div>
              </Grid>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Grid item xs={12} sm={5}>
                <div>
                  <label>Email</label>
                  <TextField
                    disabled
                    id="standard-disabled"
                    value={info.email}
                    variant="standard"
                    fullWidth
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={5}>
                <div>
                  <label>Mobile</label>
                  <TextField
                    disabled
                    id="standard-disabled"
                    value={info.phone}
                    variant="standard"
                    fullWidth
                  />
                </div>
              </Grid>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Grid item xs={12} sm={5}>
                <div>
                  <label>Date Of Birth</label>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      required
                      id="dob"
                      name="dob"
                      value={info.dob}
                      format="MM/dd/yyyy"
                      disabled
                      fullWidth
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </Grid>
              <Grid item xs={12} sm={5}>
                <div>
                  <label>Role</label>
                  <TextField
                    disabled
                    id="standard-disabled"
                    value={info.role}
                    variant="standard"
                    fullWidth
                  />
                </div>
              </Grid>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Grid item xs={12} sm={5}>
                <div>
                  <label>Status</label>
                  <TextField
                    disabled
                    id="standard-disabled"
                    value={info.status}
                    variant="standard"
                    fullWidth
                  />
                </div>
              </Grid>
              <br />
              <Grid item xs={12} sm={8}>
                <br />
                <br />
                <Typography variant="subtitle1" style={{ color: "gray" }}>
                  Professional Details
                  <Divider />
                  <br />
                </Typography>
              </Grid>
              <Grid item xs={12} sm={5}>
                <div>
                  <label>Skills</label>
                  <TextField
                    disabled
                    id="standard-disabled"
                    value={info.skills}
                    variant="standard"
                    fullWidth
                  />
                </div>
              </Grid>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Grid item xs={12} sm={5}>
                <div>
                  <label>Years Of Experience</label>
                  <TextField
                    disabled
                    id="standard-disabled"
                    value={info.yearsOfExperience}
                    variant="standard"
                    fullWidth
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={5}>
                <div>
                  <label>Current Title</label>
                  <TextField
                    disabled
                    id="standard-disabled"
                    value={info.currentitle}
                    variant="standard"
                    fullWidth
                  />
                </div>
              </Grid>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Grid item xs={12} sm={5}>
                <div>
                  <label>Preferred position</label>
                  <TextField
                    disabled
                    id="standard-disabled"
                    value={info.preferredPosition}
                    variant="standard"
                    fullWidth
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={5}>
                <div>
                  <label>Highest Degree</label>
                  <TextField
                    disabled
                    id="standard-disabled"
                    value={info.highestDegree}
                    variant="standard"
                    fullWidth
                  />
                </div>
              </Grid>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Grid item xs={12} sm={5}>
                <div>
                  <label>Major</label>
                  <TextField
                    disabled
                    id="standard-disabled"
                    value={info.major}
                    variant="standard"
                    fullWidth
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={5}>
                <div>
                  <label>English Proficiency</label>
                  <TextField
                    disabled
                    id="standard-disabled"
                    value={info.englishProficiency}
                    variant="standard"
                    fullWidth
                  />
                </div>
              </Grid>
              <br />
              <Grid item xs={12} sm={8}>
                <br />
                <br />
                <Typography variant="subtitle1" style={{ color: "gray" }}>
                  Contact Details
                  <Divider />
                  <br />
                </Typography>
              </Grid>
              <Grid item xs={12} sm={5}>
                <div>
                  <label>Address Line1</label>
                  <TextField
                    disabled
                    id="standard-disabled"
                    value={info.address_line1}
                    variant="standard"
                    fullWidth
                  />
                </div>
              </Grid>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Grid item xs={12} sm={5}>
                <div>
                  <label>Address Line2</label>
                  <TextField
                    disabled
                    id="standard-disabled"
                    value={info.address_line2}
                    variant="standard"
                    fullWidth
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={5}>
                <div>
                  <label>City</label>
                  <TextField
                    disabled
                    id="standard-disabled"
                    value={info.city}
                    variant="standard"
                    fullWidth
                  />
                </div>
              </Grid>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Grid item xs={12} sm={5}>
                <div>
                  <label>State</label>
                  <TextField
                    disabled
                    id="standard-disabled"
                    value={info.state}
                    variant="standard"
                    fullWidth
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={5}>
                <div>
                  <label>Country</label>
                  <TextField
                    disabled
                    id="standard-disabled"
                    value={info.country}
                    variant="standard"
                    fullWidth
                  />
                </div>
              </Grid>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Grid item xs={12} sm={5}>
                <div>
                  <label>Postal Code</label>
                  <TextField
                    disabled
                    id="standard-disabled"
                    value={info.postalCode}
                    variant="standard"
                    fullWidth
                  />
                </div>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            style={{ backgroundColor: "#D73B30", color: "white", marginRight:"20px" }}
          >
            Back
          </Button>
        </DialogActions>
      </Dialog>
      <div className="myList">
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={10}
          disableSelectionOnClick
          className="tableList"
          autoHeight
        />
      </div>
    </>
  );
};
export default CandidateList;
