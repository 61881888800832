import React, { useEffect, useState } from "react";
import axios from "axios"
// MUI Imports

import BasicDetails from "./BasicDetails";
import SkillsDetails from "./SkillsDetails";
import EducationDetails from "./EducationDetails";
import ExperienceDetails from "./ExperienceDetails";

const EditUsers = ({ role, setRefreshCount, addUserClicked, setAddUserClicked, selectedUserId, mode }) => {

    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [croppedAreaPixels, setCroppedAreaPixels] = useState();
    const [dataLoaded, setDataLoaded] = useState(false)
    const [userData, setUserData] = useState({
        role: role,
        crop: crop,
        croppedAreaPixels: croppedAreaPixels,
        educationErrors: [{
            school: "",
            degree: "",
            stream: "",
            startMonth: "",
            startYear: "",
            endMonth: "",
            endYear: "",
        }],
        experienceErrors: [{
            position: "",
            company: "",
            startMonth: "",
            startYear: "",
            endMonth: "",
            endYear: "",
        }]
    })

    const [count, setCount] = useState(1);
    const [nextPage, setNextPage] = useState(true);
    const [previousPage, setPreviousPage] = useState(false);

    useEffect(() => {
        (async () => {
            if (role == "screener") {
                let { data } = await axios.get(`/api/admin/screeners/internal-screeners`)
                let screener = data.find(item => item._id == selectedUserId)
                let { croppedAreaPixels, salaryDetail, profileTitle, personal_details, profileImage, defaultAvatar, croppedImage, croppedImagePublicId, interacted, zoom, resume, _id, unique_id, firstName, lastName, email, primaryPhone, secondaryPhone, skills, totalExperience, job_experience, educational_details } = screener;
                console.log(screener, "screener")
                setUserData({
                    ...userData,
                    ...screener,
                    salary: screener.salaryDetail.salary,
                    payType: screener.salaryDetail.payType,
                    checked: screener.salaryDetail.checked,
                    dob: screener.personal_details.dob,
                    gender: screener.personal_details.gender,
                    address1: screener.personal_details.location.address1,
                    address2: screener.personal_details.location.address2,
                    city: screener.personal_details.location.city,
                    state: screener.personal_details.location.state,
                    country: screener.personal_details.location.country,
                    countryId: screener.personal_details.location.countryId,
                    stateId: screener.personal_details.location.stateId,
                    postalCode: screener.personal_details.location.postalCode,
                    nationality: screener.personal_details.nationality,
                    isAvatar: screener.defaultAvatar,
                    profileImg1: screener.profileImage,
                    phone1: screener.primaryPhone,
                    phone2: screener.secondaryPhone,
                    educationDetails: screener.educational_details,
                    experienceDetails: screener.job_experience,
                    // resume

                })
                setDataLoaded(true)
            } else {
                let { data } = await axios.get(`/api/admin/candidates/internal-candidates`)
                let candidate = data.find(item => item._id == selectedUserId)
                console.log(candidate, "candidate")
                setUserData({
                    ...userData,
                    ...candidate,
                    salary: candidate.salaryDetail.salary,
                    payType: candidate.salaryDetail.payType,
                    checked: candidate.salaryDetail.checked,
                    dob: candidate.personal_details.dob,
                    gender: candidate.personal_details.gender,
                    address1: candidate.personal_details.location.address1,
                    address2: candidate.personal_details.location.address2,
                    city: candidate.personal_details.location.city,
                    state: candidate.personal_details.location.state,
                    country: candidate.personal_details.location.country,
                    countryId: candidate.personal_details.location.countryId,
                    stateId: candidate.personal_details.location.stateId,
                    postalCode: candidate.personal_details.location.postalCode,
                    nationality: candidate.personal_details.nationality,

                    isAvatar: candidate.defaultAvatar,

                    profileImg1: candidate.profileImage,

                    phone1: candidate.primaryPhone,
                    phone2: candidate.secondaryPhone,

                    educationDetails: candidate.educational_details,
                    experienceDetails: candidate.job_experience,
                })
                setDataLoaded(true)
            }
        })()
    }, [])


    return (
        <div>

            {dataLoaded && nextPage && count === 1 ? (
                <BasicDetails userData={userData} setUserData={setUserData} nextPage={nextPage} previousPage={previousPage} setPreviousPage={setPreviousPage} count={count} setCount={setCount} mode={mode} />
            )
                :
                nextPage && count === 2 ? (
                    <SkillsDetails userData={userData} setUserData={setUserData} nextPage={nextPage} previousPage={previousPage} setPreviousPage={setPreviousPage} count={count} setCount={setCount} />
                ) : nextPage && count === 3 ? (
                    <EducationDetails userData={userData} setUserData={setUserData} nextPage={nextPage} previousPage={previousPage} setPreviousPage={setPreviousPage} count={count} setCount={setCount} />
                ) : nextPage && count === 4 ? (
                    <ExperienceDetails setAddUserClicked={setAddUserClicked} userData={userData} setUserData={setUserData} nextPage={nextPage} previousPage={previousPage} setPreviousPage={setPreviousPage} count={count} setCount={setCount} mode={mode} selectedUserId={selectedUserId} setRefreshCount={setRefreshCount} />) : ""}
            {/* </Slide> */}
        </div>
    );
};

export default EditUsers;
