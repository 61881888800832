import { connect } from "react-redux";
import { receiveCandidateApplications } from "../../actions/applicationActions";
import ApplicationsList from "./applicationsList";

const mapStateToProps = (state) => ({
    auth: state.auth,
    applications: state.applications,
});

const mapDispatchToProps = (dispatch) => ({
    getApplications: (candidateId) => dispatch(receiveCandidateApplications(candidateId))
});

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationsList);