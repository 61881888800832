import DateFnsUtils from '@date-io/date-fns';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import Typography from '@material-ui/core/Typography';
import MuiPhoneNumber from 'material-ui-phone-number';
import PhoneIcon from '@material-ui/icons/Phone';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import React from 'react';
import {FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAddressCard } from "@fortawesome/free-solid-svg-icons";

const styles = makeStyles((theme) => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: '48px',
    [theme.breakpoints.up(750)]: {
      marginLeft: '35px',
    },
    padding: '20px',
  },
  textField: {
    width: '300px',
    fontSize: '1.4rem',
  },
  phoneTextField: {
    [theme.breakpoints.up(750)]: {
      marginTop: '15px',
    },
  },
}));

export default function formUserDetails(props) {
  const classes = styles();
  const { values, handleChange } = props;
  console.log(props);
  const error = '';
  let dob = new Date(values.dob);

  const isLetter = (e) => {
    const re = /[a-zA-z\s]+/g;
    if (!re.test(e.key)) {
      e.preventDefault();
    }
  };

  return (
    <main className={classes.main} id="user-data-container">
      <div className="align-self-left flex-fill mb-2 flex-wrap"  id="name-email">
      <Typography variant="h6" align="left">
      <AccountCircleIcon /> &nbsp; Personal Information
      </Typography>
       <br/><div className="align-self-left flex-fill mb-2 flex-wrap" >
         
        <TextField
          required
          className="text-field"
          id="name"
          name="name"
          value={values.name}
          onChange={handleChange('name')}
          label="Name"
          placeholder="Enter Name"
          autoComplete="name"
          onKeyPress={(e) => isLetter(e)}
          type="text"
        />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <TextField
          required
          id="email"
          name="email"
          value={values.email}
          label="Email"
          className="text-field"
          disabled
          autoComplete="email"
        />
         &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <TextField
          id="secondaryemail"
          name="secondaryemail"
          value={values.secondaryemail}
          onChange={handleChange('secondaryemail')}
          label="Secondary Email"
          className="text-field"
          autoComplete="email"
        />
         &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
         <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DatePicker
            id="dob"
            label="Birthday"
            name="dob"
            format="MM/dd/yyyy"
            value={dob}
            onChange={handleChange('dob')}
            className="dob-field"
          />
        </MuiPickersUtilsProvider>
      </div>
      </div>
      {/* <div className="d-flex flex-row flex-wrap justify-content-around mb-4" id="dob-phone">
       

        <MuiPhoneNumber
          required
          id="phone"
          name="phone"
          label="Primary Number"
          value={values.phone}
          defaultCountry={'us'}
          className="text-field"
          onChange={handleChange('phone')}
        />

        <MuiPhoneNumber
          id="secondaryPhone"
          name="secondaryPhone"
          label="Secondary Number"
          value={values.secondaryPhone}
          defaultCountry={'us'}
          className="text-field"
          onChange={handleChange('secondaryPhone')}
        />
        </div> */}

      <br/>  <br/>
    <div className="align-self-left flex-fill mb-2 flex-wrap"  id="name-email">
      <Typography variant="h6" align="left">
      <PhoneIcon /> &nbsp; Contact Information
      </Typography>

      <br/><div className="align-self-left flex-fill mb-2 flex-wrap" >
        
      <MuiPhoneNumber
          required
          id="phone"
          name="phone"
          label="Primary Number"
          value={values.phone}
          defaultCountry={'us'}
          className="text-field"
          onChange={handleChange('phone')}
        />
         &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <MuiPhoneNumber
          id="secondaryPhone"
          name="secondaryPhone"
          label="Secondary Number"
          value={values.secondaryPhone}
          defaultCountry={'us'}
          className="text-field"
          onChange={handleChange('secondaryPhone')}
        />
        </div>
        </div>
     
      {/* <div className="marl-50">
        <div className="d-flex flex-row mb-3">
          <div className="marl-50" id="address-field">
            <TextField
              id="address_line1"
              name="address_line1"
              value={values.address_line1}
              onChange={handleChange('address.address_line1')}
              label="Address line 1"
              className="address-field"
              autoComplete="billing address-line1"
            />
          </div>
          <div className="marl-150" id="address-extra-field">
            <TextField
              id="address_line2"
              name="address_line2"
              value={values.address_line2}
              onChange={handleChange('address.address_line2')}
              label="Address line 2"
              className="address-field"
              autoComplete="billing address-line2"
            />
          </div>
        </div>
        <div className="d-flex flex-row mb-3">
          <div className="marl-50" id="city-field">
            <TextField
              id="city"
              name="city"
              value={values.city}
              onKeyPress={(e) => isLetter(e)}
              onChange={handleChange('address.city')}
              label="City"
              className="address-field"
              type="text"
            />
          </div>
          <div className="marl-150" id="state-field">
            <TextField
              id="state"
              name="state"
              value={values.state}
              onChange={handleChange('address.state')}
              label="State/Province/Region"
              className="address-field"
              onKeyPress={(e) => isLetter(e)}
              type="text"
            />
          </div>
        </div>
        <div className="d-flex flex-row mb-3">
          <div className="marl-50" id="country-field">
            <TextField
              id="country"
              name="country"
              value={values.country}
              onChange={handleChange('address.country')}
              label="Country"
              className="address-field"
              type="text"
              onKeyPress={(e) => isLetter(e)}
              autoComplete="on"
            />
          </div>
          <div className="marl-150" id="p ostal-field">
            <TextField
              type="number"
              id="postalCode"
              name="postalCode"
              value={values.postalCode}
              onChange={handleChange('address.postalCode')}
              label="Postal code"
              className="address-field"
              autoComplete="billing postal-code"
              onInput={(e) => {
                e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 6);
              }}
            />
          </div>
        </div>
      </div> */}
       {/* <div className="d-flex flex-row flex-wrap justify-content-around mb-4" id="name-email">
       <TextField
              id="address_line1"
              name="address_line1"
              value={values.address_line1}
              onChange={handleChange('address.address_line1')}
              label="Address line 1"
              className="address-field"
              autoComplete="billing address-line1"
        />
        <TextField
              id="address_line2"
              name="address_line2"
              value={values.address_line2}
              onChange={handleChange('address.address_line2')}
              label="Address line 2"
              className="address-field"
              autoComplete="billing address-line2"
        />
         <TextField
              id="city"
              name="city"
              value={values.city}
              onKeyPress={(e) => isLetter(e)}
              onChange={handleChange('address.city')}
              label="City"
              className="address-field"
              type="text"
        />

       </div>
       <div className="d-flex flex-row flex-wrap justify-content-around mb-4" id="name-email">
       <TextField
              id="state"
              name="state"
              value={values.state}
              onChange={handleChange('address.state')}
              label="State/Province/Region"
              className="address-field"
              onKeyPress={(e) => isLetter(e)}
              type="text"
        />
        <TextField
              id="country"
              name="country"
              value={values.country}
              onChange={handleChange('address.country')}
              label="Country"
              className="address-field"
              type="text"
              onKeyPress={(e) => isLetter(e)}
              autoComplete="on"
        />
         <TextField
              type="number"
              id="postalCode"
              name="postalCode"
              value={values.postalCode}
              onChange={handleChange('address.postalCode')}
              label="Postal code"
              className="address-field"
              autoComplete="billing postal-code"
              onInput={(e) => {
                e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 6);
              }}
          />
       </div> */}
<br/><br/>
<div className="align-self-left flex-fill mb-2 flex-wrap"  id="name-email">
      <Typography variant="h6" align="left">
      <FontAwesomeIcon icon={faAddressCard} /> &nbsp; Permanant Address
      </Typography>
      <br/><div className="align-self-left flex-fill mb-2 flex-wrap" >
      <TextField
              id="address_line1"
              name="address_line1"
              value={values.address_line1}
              onChange={handleChange('address.address_line1')}
              label="Address line 1"
              className="address-field"
              autoComplete="billing address-line1"
      />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <TextField
              id="address_line2"
              name="address_line2"
              value={values.address_line2}
              onChange={handleChange('address.address_line2')}
              label="Address line 2"
              className="address-field"
              autoComplete="billing address-line2"
        />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <TextField
              id="city"
              name="city"
              value={values.city}
              onKeyPress={(e) => isLetter(e)}
              onChange={handleChange('address.city')}
              label="City"
              className="address-field"
              type="text"
        />
      </div>
      <br/><div className="align-self-left flex-fill mb-2 flex-wrap" >
      <TextField
              id="state"
              name="state"
              value={values.state}
              onChange={handleChange('address.state')}
              label="State/Province/Region"
              className="address-field"
              onKeyPress={(e) => isLetter(e)}
              type="text"
        />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <TextField
              id="country"
              name="country"
              value={values.country}
              onChange={handleChange('address.country')}
              label="Country"
              className="address-field"
              type="text"
              onKeyPress={(e) => isLetter(e)}
              autoComplete="on"
        />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
         <TextField
              type="number"
              id="postalCode"
              name="postalCode"
              value={values.postalCode}
              onChange={handleChange('address.postalCode')}
              label="Postal code"
              className="address-field"
              autoComplete="billing postal-code"
              onInput={(e) => {
                e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 6);
              }}
          />
      </div>

</div>



    </main>
  );
}
