import PropTypes from "prop-types";
import React from "react";
// import RatingsSidebar from "../sidebarComponents/ratingsSide";
import ProfileCompletion from "../sidebarComponents/profileCompletion";
// import CertificationSidebar from "./sidebarComponents/certification";

function DashboardSidebar(props) {
    const { sidebar } = props;
    // console.log(sidebar);
    return (
        <div className="d-flex flex-column justify-content-between">
            {/* <Grid item xs><ProfileCompletion completionPercentage={sidebar.profileCompletionPercentage} /></Grid> */}
            {/* <Grid item xs><TrainingSidebar /></Grid> */}
            {/* <div className="flex-fill maxw-250"> */}
                {/* <RatingsSidebar averageRating={sidebar.averageRating} /> */}
               
                
            {/* </div> */}
            {/* <div className="flex-fill mt-5 maxw-250"><EarningsSidebar /></div> */}
            {/* <div item xs><CertificationSidebar /></div> */}

            <ProfileCompletion averageRating={sidebar.averageRating} completionPercentage={sidebar.profileCompletionPercentage}  />
        </div>
    );
}

DashboardSidebar.propTypes = {
    user: PropTypes.object.isRequired,
    sidebar: PropTypes.object,
};

export default (DashboardSidebar);
