import React, { useEffect, useState } from "react";

//external libraries
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { Grid } from "@mui/material";
import Divider from "@mui/material/Divider";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import PhoneInput from "react-phone-input-2";
import Tooltip from "@mui/material/Tooltip";
import DeleteMessageIcon from "@mui/icons-material/ErrorOutlineOutlined";

import SaveProfileSnackBar from "../snackBars/saveProfileSnackbar";
import ErrorMessageSnackbar from "../snackBars/errorMessageSnackbar";

//css import
import "./ExperienceEdit.css";

const CompanyPocEdit = ({
  name,
  email,
  phone,
  jobTitle,
  setOpenPocCardEdit,
  open,
  role,
  id,
}) => {

  const [name1, setName1] = useState(name);
  const [email1, setEmail1] = useState(email);
  const [phone1, setPhone1] = useState(phone);
  const [jobTitle1, setJobTitle1] = useState(jobTitle);

  const [openCloseDialog, setOpenCloseDialog] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);

  // error handling
  const [errors, setErrors] = useState({
    name: "",
    email: "",
    phone: "",
    jobTitle: "",
  });

  const nameHandler = (e) => {
    setName1(e.target.value);
    setErrors({ name: "" });
  };

  const emailHandler = (e) => {
    setEmail1(e.target.value);
    setErrors({ email: "" });
  };

  const jobTitleHandler = (e) => {
    setJobTitle1(e.target.value);
    setErrors({ jobTitle: "" });
  };

  //handle close confirmation dialog
  const onClose = () => {
    setName1(name);
    setEmail1(email);
    setPhone1(phone);
    setJobTitle1(jobTitle);
    setOpenPocCardEdit(false);
    setOpenCloseDialog(true);
  };

  const onCloseCancel = () => {
    setOpenCloseDialog(false);
    setOpenPocCardEdit(true);
  };

  const handleCloseDetails = () => {
    setName1(name);
    setEmail1(email);
    setPhone1(phone);
    setJobTitle1(jobTitle);
    setErrors({
      name: "",
      email: "",
      phone: "",
      jobTitle: "",
    });
    setOpenPocCardEdit(false);
    setOpenCloseDialog(false);
  };

  //submit function
  const handleSubmit = (e) => {
    e.preventDefault();
    // if form is empty , then error is shown
    if (name1 === "") {
      return setErrors({
        name: "Name is required!",
      });
    }
    if (email1 === "") {
      return setErrors({
        email: "Email is required",
      });
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email1)) {
      return setErrors({
        email: "Email is Invalid",
      });
    }
    if (phone1 === "") {
      return setErrors({
        phone: "Phone is required",
      });
    }

    if (jobTitle1 === "") {
      return setErrors({
        jobTitle: "Job Title is required",
      });
    }

    const newData = {
      name: name1,
      email: email1,
      phone: phone1,
      jobTitle: jobTitle1,
    };

    axios
      .post(`/api/${role}/poc-edit/${id}`, { newData })
      .then((res) => {
        setOpenSnackbar(true);
        setOpenPocCardEdit(false);
      })
      .catch((err) => {
        console.log(err);
        setOpenErrorSnackbar(true);
      });
  };

  return (
    <div>
      {/* Add Personal details Dialogue */}
      <Dialog
        open={open}
        onClose={() => {
          setOpenPocCardEdit(false);
        }}
        className="experience-edit-dialog"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent className="experience-edit-dialog-content">
          <Grid container spacing={0} justifyContent="center">
            <Grid
              xs={12}
              sm={12}
              md={12}
              lg={12}
              className="experience-edit-title-grid"
            >
              <div>
                <div className="experience-edit-title">
                  <p>POC Details</p>
                </div>
                <div className="experience-edit-close">
                  <Tooltip title="Close">
                    <CloseIcon onClick={onClose} />
                  </Tooltip>
                </div>
              </div>
              <Divider className="experience-edit-top-divider" />
            </Grid>
            <Grid
              xs={12}
              sm={12}
              md={12}
              lg={12}
              className="experience-edit-right-grid"
            >
              <div className="experience-edit-form-container">
                <form
                  className="experience-edit-form"
                  noValidate
                  onSubmit={handleSubmit}
                >
                  <div class="input-container">
                    <div class="input-field">
                      <label className="date-label">Name*</label>
                      <input
                        class="position"
                        type="text"
                        value={name1}
                        onChange={nameHandler}
                        placeholder="Name"
                      />
                      <span className="text-danger">{errors.name}</span>
                    </div>

                    <div class="input-field">
                      <label className="date-label">Email*</label>
                      <input
                        class="position"
                        type="email"
                        value={email1}
                        onChange={emailHandler}
                        placeholder="Email"
                      />
                      <span className="text-danger">{errors.email}</span>
                    </div>

                    <div class="input-field">
                      <label className="date-label">Phone*</label>
                      <PhoneInput
                        country={"us"}
                        className="poc-phone"
                        type="number"
                        placeholder="Phone"
                        value={phone1}
                        onChange={(phone) => {
                          setPhone1(phone);
                          setErrors({
                            phone: "",
                          });
                        }}
                      />
                      <span className="text-danger">{errors.phone}</span>
                    </div>

                    <div class="input-field">
                      <label className="date-label">Job Title*</label>
                      <input
                        class="position"
                        type="text"
                        value={jobTitle1}
                        onChange={jobTitleHandler}
                        placeholder="Job Title"
                      />
                      <span className="text-danger">{errors.jobTitle}</span>
                    </div>
                  </div>
                  <div className="button-container">
                    <button className="filled-btn filled-btn-animation btn-style" type="submit">
                      {" "}
                      Save
                    </button>
                  </div>
                </form>
              </div>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      {/* Close dialog confimation dialog */}
      <Dialog
        fullWidth
        maxWidth="xs"
        open={openCloseDialog}
        onClose={handleCloseDetails}
        className="experience-edit-dialog"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent className="dialog-content">
          <Grid container spacing={0} justifyContent="center">
            <Grid
              xs={11.5}
              sm={11.5}
              md={11.5}
              lg={11.5}
              className="experience-delete-grid"
            >
              <div className="delete-message-icon">
                <DeleteMessageIcon fontSize="small" />
              </div>
              <div className="delete-dialog-content">
                <h5>Are you sure ?</h5>
                <p>Are you sure you want to go back?.</p>
                <p>All your current changes will be lost!</p>
              </div>
            </Grid>
          </Grid>
        </DialogContent>
        <div className="button-container delete-btn-container">
          <button className="warning-btn-style filled-btn filled-btn-animation" onClick={onCloseCancel}>
            {" "}
            Cancel
          </button>
          <button className="warning-btn-style outlined-btn outlined-btn-animation" onClick={handleCloseDetails}>
            {" "}
            Confirm
          </button>
        </div>
      </Dialog>

      <SaveProfileSnackBar
        openSnackbar={openSnackbar}
        setOpenSnackbar={setOpenSnackbar}
      />
      <ErrorMessageSnackbar
        openErrorSnackbar={openErrorSnackbar}
        setOpenErrorSnackbar={setOpenErrorSnackbar}
      />
    </div>
  );
};

export default CompanyPocEdit;
