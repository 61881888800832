import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Pie } from '@ant-design/plots';

const DonutChart = ({ result, startAngle, endAngle, title }) => {
    const data = result;

    console.log(data)
    const config = {
        startAngle: Math.PI * startAngle,
        endAngle: Math.PI * endAngle,
        legend: 'bottom-left',
        color: ['#ef798a', '#3091A2'],
        appendPadding: 10,
        data,
        angleField: 'value',
        colorField: 'type',
        radius: 1,
        innerRadius: 0.6,
        label: {
            type: 'inner',
            offset: '-50%',
            content: '{value}',
            style: {
                textAlign: 'center',
                fontSize: 14,
            },
        },
        interactions: [
            {
                type: 'element-selected',
            },
            {
                type: 'element-active',
            },
        ],
        statistic: {
            title: title,
            content: {
                style: {
                    whiteSpace: 'pre-wrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    textAlign: "center",
                    marginBottom: "20px",
                    fontSize: "24px"
                },
                // content: 'AntV\nG2Plot',
            },
        },
    };
    return <Pie {...config} className="donut-chart" />;
};

export default DonutChart