import axios from "axios";

export const RECEIVE_AVAILABILITIES = "RECEIVE_AVAILABILITIES";
export const RECEIVE_CONFIRMED_AVAILABILITIES = "RECEIVE_CONFIRMED_AVAILABILITIES";
export const MODIFY_AVAILABILITIES = "MODIFY_AVAILABILITIES";
export const RECEIVE_AVAILABILITIES_ERROR = "RECEIVE_AVAILABILITIES_ERROR";

const receiveAvailabilities = (availabilities) => {
    return {
        type: RECEIVE_AVAILABILITIES,
        availabilities,
    };
};

const receiveConfirmedAvailabilities = (confirmedAvailabilities) => {
    return {
        type: RECEIVE_CONFIRMED_AVAILABILITIES,
        confirmedAvailabilities,
    };
};

const modifyAvailabilities = (modifiedAvailabilities) => {
    return {
        type: MODIFY_AVAILABILITIES,
        modifiedAvailabilities,
    };
};

const receiveAvailabilityError = (errors) => {
    return {
        type: RECEIVE_AVAILABILITIES_ERROR,
        errors,
    };
}

// initial call for props
export const receiveAllAvailabilities = (userId) => (dispatch) => {
    axios
        .get("/api/availability/events/" + userId)
        .then((availabilities) => dispatch(receiveAvailabilities(availabilities)))
        .catch((err) => receiveAvailabilityError(err));
};

// onSubmit call return availabilities
export const sendConfirmedAvailabilities = (userId, events, enqueueSnackbar) => (dispatch) => {
    axios
        .post("/api/availability/confirm", { user: userId, events: events })
        .then((availabilities) => {
            // console.log(availabilities);
            let regMessage = `you have succesfully sent ${availabilities.data.events.length} availabilities, You can add more or modify them.`;

            enqueueSnackbar(regMessage, {
                variant: "success",
                autoHideDuration: 3000,
            });

            dispatch(receiveConfirmedAvailabilities(availabilities));
        })
        .catch((err) => receiveAvailabilityError(err));
};

// action that updates availabilities on calendar/schedule set page
export const updateAvailabilities = (events) => (dispatch) => {
    dispatch(modifyAvailabilities(events));
};
