import axios from "axios";

export const RECEIVE_POSITIONS = 'RECEIVE_POSITIONS';

const receivePositions = (positions) => {
    return {
        type: RECEIVE_POSITIONS,
        positions,
    };
};

export const receiveAllPositions = () => dispatch => {
    axios
        .get('/salesforce/position/all')
        .then(positions => dispatch(receivePositions(positions)))
        .catch(err => console.log(err));
}