import React, { Component } from 'react';
import { Drawer, Grid, IconButton, MenuItem } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
// import Typography from "@material-ui/core/Typography";
// import IconButton from "@material-ui/core/IconButton";
// import MenuIcon from "@material-ui/icons/Menu";
// import List from "@material-ui/core/List";
// import Drawer from "@material-ui/core/Drawer";
// import ListItem from "@material-ui/core/ListItem";
// import ListItemIcon from "@material-ui/core/ListItemIcon";
// import ListItemText from "@material-ui/core/ListItemText";
// import Divider from "@material-ui/core/Divider";
// import Home from "@material-ui/icons/Home";
// import AccountBox from "@material-ui/icons/AccountBox";
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import PropTypes from 'prop-types';
import Logo from '../../images/Logos/logolanding.png';
import HomeNavigation from '../home/Navigation';
//import MoreVertIcon from '@material-ui/icons/MoreVert';
import MenuIcon from '@material-ui/icons/Menu';
//import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import CloseIcon from '@material-ui/icons/Close';
import './Navbar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { faUser } from '@fortawesome/free-solid-svg-icons';
class TabbedNavbar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      left: false,
      anchorEl: null,
      toggleMenu: false,
      open: false,
    };

    // this.state ={
    //     toggleMenu :'',
    // };

    this.updateHome = this.updateHome.bind(this);
  }

  handleToggle = (e) => {
    e.preventDefault();
    this.setState({
      toggleMenu: !this.state.toggleMenu,
      open: !this.state.open,
    });
  };

  //     handleToggle = (e) =>{
  //         e.preventDefault();
  //         if(this.state.toggleMenu === false){
  //             this.setState({
  //                 toggleMenu:!this.state.toggleMenu,
  //             })
  //         }else{
  //         this.setState({
  //             toggleMenu:!this.state.toggleMenu,
  //         })
  //     }
  // }

  handleMenu = (event) => {
    this.setState({
      anchorEl: event.currentTarget,
    });
  };

  handleHomeClick = () => {
    let route = '/home';
    return route;
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
    });
  };

  // this is prop method that gets passes to the navigation bar
  // to control the tab active state
  updateHome(displayTab) {
    this.props.receiveHomeComponentAction(displayTab);
  }

  onLogoutClick = (e) => {
    this.props.logoutUser();
  };

  render() {
    const { classes, location } = this.props;
    const { user } = this.props.auth;
    const openMenu = Boolean(this.state.anchorEl);

    // this allows for TabbedNavbar persistance in the pathways that we want, and
    // excluding the routes defined below.
    const nonNavRoutes = ['/profilewizard', '/register', '/', '/register-wizard'];
    let navClassName = nonNavRoutes.includes(location) ? 'invisible' : `${classes.root} visible`;

    const { toggleMenu } = this.state;
    const { open } = this.state;

    return (
      <Grid style={{ width: '100%' }}>
        <div className={navClassName}>
          <AppBar position="sticky" color="inherit" className="Appbar">
            <Toolbar className="d-flex flex-row align-items-center p-2 marl-50">
              {/* <Toolbar variant="dense" className="toolbar" > */}
              <img className={classes.logo} src={Logo} alt="Screener Logo" class="logo" />
              <div className="div1">
                <div className="w-50 p-2 align-self-end">
                  {/* {toggleMenu ? "" : <HomeNavigation updateHome={this.updateHome} />} */}
                  <HomeNavigation className="navs" updateHome={this.updateHome} />
                </div>
              </div>

              {/* <div className={classes.nameButton}>  */}

              <div className="divuser">
                <Button color="inherit" onClick={this.handleMenu} className="user">
                  <FontAwesomeIcon icon={faUser} /> &nbsp; {user.name ? user.name : ' '}
                </Button>
              </div>

              <Menu
                id="menu-appbar"
                anchorEl={this.state.anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                style={{ marginTop: '40px' }}
                open={openMenu}
                onClose={this.handleClose}
              >
                <MenuItem component="a" href={this.handleHomeClick()}>
                  <FontAwesomeIcon icon={faHome} /> &nbsp; Home
                </MenuItem>
                <MenuItem onClick={this.onLogoutClick}>
                  <FontAwesomeIcon icon={faSignOutAlt} />
                  &nbsp; Logout
                </MenuItem>
              </Menu>

              <IconButton class="btnclass" onClick={(e) => this.handleToggle(e)}>
                {/* <MoreVertIcon fontSize="large"></MoreVertIcon> */}
                <MenuIcon fontSize="large"></MenuIcon>
              </IconButton>
              <div className="div2">
                <Drawer variant="persistent" anchor="top" open={open} className="drawer" style={{ height: 'calc(100% - 100%)', top: 50 }}>
                  <div id="overlay">
                    <div className="div3">
                      <IconButton onClick={(e) => this.handleToggle(e)} className="iconbtn">
                        <CloseIcon fontSize="large" />
                      </IconButton>
                      {toggleMenu ? <HomeNavigation updateHome={this.updateHome} /> : ''}
                    </div>
                  </div>
                </Drawer>
              </div>
              {/* </div>  */}
            </Toolbar>
          </AppBar>
        </div>
      </Grid>
    );
  }
}
TabbedNavbar.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  receiveHomeComponentAction: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

export default TabbedNavbar;
