import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
// import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import React from 'react';
import Typography from '@material-ui/core/Typography';
// const styles = makeStyles((theme) => ({
//     main: {
//         display: "block", // Fix IE 11 issue.
//     },
// }));

const positions = ['Salesforce Developer', 'Software Developer', 'Network Engineer', 'Junior Developer', 'Cyber Ops', 'DevOps', 'BlackOps'];

export default function formProfessionalDetails(props) {
  // // add a new field
  // addCertificationField(e) {
  //     e.preventDefault();
  //     const { addSkills } = this.state;
  //     addSkills[addSkills.length] = [""];
  //     this.setState({ addSkills });
  // }

  const { values, candidate, handleChange, handleCandidateChange } = props;
  // console.log(values.interviewRate);
  // console.log(values);

  return (
    <div className="d-flex flex-column justify-content-around p-5">
      <div className="flex-fill d-flex flex-column">
        <div className={candidate ? 'flex-fill d-flex flex-column mr-2' : 'invisible'}>
        <Typography variant="h6" align="left">
 Preferred Work Position
      </Typography>
          <Select
            id="preferredPosition"
            name="preferredPosition"
            autoComplete="on"
            value={candidate ? candidate.preferredPosition : ''}
            onChange={handleCandidateChange('preferredPosition')}
            label="Preferred Position"
            displayEmpty
            className="flex-fill mt-2"
          >
            <MenuItem value="">
              <em>Select position</em>
            </MenuItem>
            {positions.map((position) => {
              return (
                <MenuItem key={position} value={position.toString()}>
                  {position}
                </MenuItem>
              );
            })}
          </Select>
        </div>
        <br/>
        <Typography variant="h6" align="left">
  &nbsp;Professional Summary


      </Typography>
        <TextField
          id="bio"
          name="bio"
          value={values.bio}
          onChange={handleChange('bio')}
          
          multiline
          margin="normal"
          variant="outlined"
          autoComplete="on"
          className="flex-fill mt-2 p-3"
        />
        {/* <label className="flex-fill font-weight-bold align-self-left" htmlFor="bio">
                        Professional Summary <strong className="text-danger">*</strong>
                    </label>
                    <textarea
                        type="text"
                        id="bio"
                        name="bio"
                        value={values.bio}
                        onChange={handleChange("bio")}
                        className="flex-fill mt-2 p-3"
                    /> */}

        <span className=".text-secondary font-weight-italic flex-fill mt-1">
          Your entry is visible to recruiters when you accept their jobs.
        </span>
      </div>
      <div className="d-flex flex-row justify-content-between mt-4">
        <div className="flex-fill d-flex flex-column">
          {/* <label htmlFor="title" className="flex-fill font-weight-bold align-self-left">
                            Current Position <strong className="text-danger">*</strong>
                        </label>
                        <input
                            type="text"
                            id="title"
                            name="title"
                            value={values.currentTitle}
                            onChange={handleChange("title")}
                            className="flex-fill p-3 common-input mt-1"
                        /> */}
                          <Typography variant="h6" align="left">
  &nbsp;Current Position / Title


      </Typography>
          <TextField
            id="currentTitle"
            name="currentTitle"
            value={values.currentTitle}
            className="flex-fill p-3 common-input"
            variant="outlined"
            onChange={handleChange('currentTitle')}
          
            fullWidth
            autoComplete="on"
          />
        </div>

        <div className="flex-fill d-flex flex-column mr-2">
        <Typography variant="h6" align="left">
  &nbsp;Total years of experience

      </Typography>
      <br/>
          <Select
            id="yearsOfExperience"
            name="yearsOfExperience"
            value={values.yearsOfExperience}
            onChange={handleChange('yearsOfExperience')}
            label="Job Experience"
            displayEmpty
            autoComplete="on"
            className="flex-fill mt-2"
          >
            <MenuItem value="">
              <em>Select Experience</em>
            </MenuItem>
            <MenuItem value="0-2">0-2</MenuItem>
            <MenuItem value="3-5">3-5</MenuItem>
            <MenuItem value="6-10">6-10</MenuItem>
            <MenuItem value="greater than 10">10 and above</MenuItem>
          </Select>
        </div>
        {/* <div className="flex-fill d-flex flex-column">
                        <TextField
                            required
                            id="interviewRate"
                            name="interviewRate"
                            value={values.interviewRate}
                            className="flex-fill common-input"
                            variant="outlined"
                            onChange={handleChange("interviewRate")}
                            label="interview Rate"
                            fullWidth
                            autoComplete="interview Rate "
                        />
                    </div> */}
      </div>
      <div className="d-flex flex-row  mt-2 mt-3">
        {/*  <div className="flex-fill d-flex flex-row justify-content-start" id="certifications">
                         <div className="flex-fill d-flex flex-column mt-2">
                            <label htmlFor="certifications" className="flex-fill font-weight-bold align-self-left">
                                Certifications <strong className="text-danger"></strong>
                            </label>
                            <input
                                type="text"
                                id="certications"
                                name="certications"
                                value=""
                                // value={values.certications}
                                onChange={handleChange("certications")}
                                className="flex-fill p-3 common-input mt-1"
                            />
                        </div>
                        <div>
                            <Button onClick={this.addCertificationField} className={classes.deleteSkillButton}>
                                <span>+</span>
                            </Button>
                        </div>
                    </div> */}
                    <br/>
                    <br/>
        <div className="flex-fill d-flex flex-column mt-3">
        <Typography variant="h6" align="left">
  &nbsp;English Proficiency

      </Typography>
          <Select
            id="englishProficiency"
            name="englishProficiency"
            label="english Proficiency"
            value={values.englishProficiency}
            onChange={handleChange('englishProficiency')}
            className="mt-2 mr-5"
            displayEmpty
            autoComplete="on"
          >
            <MenuItem value="">
              <em>Select level</em>
            </MenuItem>
            <MenuItem value="Fair">Fair</MenuItem>
            <MenuItem value="Good">Good</MenuItem>
            <MenuItem value="Excellent">Excellent</MenuItem>
            <MenuItem value="Proficient">Proficient</MenuItem>
          </Select>
        </div>

        <div className="flex-fill d-flex flex-column mt-3">
        <Typography variant="h6" align="left">
  &nbsp;Highest Degree

      </Typography>
          <Select
            id="highestDegree"
            name="highestDegree"
            label="Highest Degree"
            value={values.highestDegree}
            onChange={handleChange('highestDegree')}
            className="mt-2"
            autoComplete="on"
            displayEmpty
          >
            <MenuItem value="">
              <em>Select Degree</em>
            </MenuItem>
            <MenuItem value="High School">High School</MenuItem>
            <MenuItem value="High School">High School/GED</MenuItem>
            <MenuItem value="High School">Associate degree</MenuItem>
            <MenuItem value="High School">Some college coursework completed</MenuItem>
            <MenuItem value="Bachelor's Degree">Bachelor's Degree</MenuItem>
            <MenuItem value="Masters Degree">Masters Degree</MenuItem>
            <MenuItem value="High School">Doctorate</MenuItem>
            <MenuItem value="Doctoral Degree">Doctoral Degree</MenuItem>
          </Select>
        </div>
                   
        {/* <div className="flex-fill d-flex flex-column mt-3">
        <Typography variant="h6" align="left">
  &nbsp;Major

      </Typography>
      &nbsp;  
          <TextField 
            id="major"
            name="major"
            value={values.major}
            className="mt-2 mr-5"
            onChange={handleChange('major')}
            fullWidth
            autoComplete="on"
          />
        </div> */}
      </div>
      <div className="flex-fill d-flex flex-column mt-3">
        <Typography variant="h6" align="left">
  &nbsp;Job Duties

      </Typography>
      &nbsp;  
          <TextField 
            id="Job Duties"
            name="Job Duties"
            value={values.jobDuties}
            className="mt-2 mr-5"
            onChange={handleChange('jobDuties')}
            fullWidth
            autoComplete="on"
          />
        </div> 
      <div className="flex-fill d-flex flex-column mt-3">
        <Typography variant="h6" align="left">
  &nbsp;Major

      </Typography>
      &nbsp;  
          <TextField 
            id="major"
            name="major"
            value={values.major}
            className="mt-2 mr-5"
            onChange={handleChange('major')}
            fullWidth
            autoComplete="on"
          />
        </div> 
    </div>
  );
}
