import { Typography } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import dateFormat from 'dateformat';
import timeConvertor from "../../../utils/timeConvertor";

const styles = (theme) => ({
    body: {
        // backgroundColor: "#C8E6C9",
        backgroundColor: "#FFFFFF",
        fontSize: "1rem",
    },
});

const divStyle = {padding:'20px'}

// import link later to add a link to a full interview page.
// import { Link } from "react-router-dom";
class PendingInterviewAlerts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            interviewList: [],
        }

        this.filterPendingEvents = this.filterPendingEvents.bind(this);
    }

    componentDidMount() {
        axios.get("api/event/interviews").then((response) => {
            this.setState({
                interviewList: response.data,
            });
        });

    }

    filterPendingEvents(interviews) {
        return interviews.filter((interviewInfo) => interviewInfo.interviewstatus === "Schedule Awaiting Confirmation");    
    }

    render() {
        const { interviews,user, classes } = this.props;
        let pendingInterviews = this.filterPendingEvents(this.state.interviewList);
        if (pendingInterviews.length === 0) {
            return (
                // <div className={`p-1 text-center ${classes.body} border-bottom-0 border-dark`}>
                //     <h1>There are no pending interviews.</h1>
                // </div>
                <div style={divStyle}>
                    <Typography>There are no pending interviews</Typography>
                </div>
            );
        } else {
            return (
                <>
                    {/* {pendingInterviews.map((alert, idx, interviewsArray) => (
                        <div style={divStyle}
                            key={`pendingInterviewAlert-${idx}-${alert.salesforceInterviewRecordId}`}
                            className={`p-1 text-center ${classes.body} ${
                                idx === interviewsArray.length - 1 ? "border-bottom-0" : "border-bottom"
                            } border-dark`}
                        >
                          
                            <b>{`Pending Interview Review ${new Date(alert.Start_Time__c ).toString()}`}</b>
                            <br />
                            <b className="text-capitalize">interviewing: </b> {alert.Candidate_Full_Name__c}{" "}
                            <br />
                            <b> for the position: </b> {alert.Position_Name__c} <br />
                            &nbsp;
                        </div>
                    ))} */}
                     {
                        pendingInterviews.map((interview) => (
                            (() => {
                                // if (this.state.interviewList.length != 0) {
                                if (interview.screener === user._id) {
                                    console.log(user._id);
                                    console.log(interview.screener);
                                    if (interview.interviewstatus === "Schedule Awaiting Confirmation") {
                                        console.log(pendingInterviews.length);
                                        console.log(interview.interviewstatus);

                                        return (
                                            <div>
                                                <b>Scheduled Interview On </b>
                                                {interview.startdate}<br />
                                                <b>Start Time : </b>
                                                {interview.starttime}<br />
                                                <b>End Time : </b>
                                                {interview.endtime}<br />
                                            </div>
                                        )

                                    }
                                }
                                // }else{
                                //     console.log(this.state.interviewList.length);
                                // }
                            })()
                        ))
                    }

                    
                    
                </>
            );
        }
    }
}

PendingInterviewAlerts.propTypes = {
    user: PropTypes.object.isRequired,
    classes: PropTypes.object,
    interviews: PropTypes.array,
};

const mapStateToProps = (state) => ({
    user: state.user,
    interviews: state.interviews,
});

export default withStyles(styles)(connect(mapStateToProps)(PendingInterviewAlerts));
