import React, { useEffect, useState, useRef, useCallback } from "react";

import axios from "axios";

// MUI Imports
import Box from "@mui/material/Box";
import CircularProgress from '@mui/material/CircularProgress';
import Card from "@mui/material/Card";
import Slide from "@mui/material/Slide";
import CloseIcon from "@mui/icons-material/Close";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DeleteMessageIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { Grid } from "@mui/material";
import Slider from "@mui/material/Slider";
import Tooltip from "@mui/material/Tooltip";
import Checkbox from "@mui/material/Checkbox";
import PhoneInput from "react-phone-input-2";
import DownloadIcon from "@mui/icons-material/Download";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import SchoolIcon from '@mui/icons-material/School';

import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';
import PublicIcon from '@mui/icons-material/Public';

//cropper imports
import Select from "react-select";
import Cropper from "react-easy-crop";


//other imports
import getCroppedImg from "../../../modals/CropImage";
import { getPhonesRegex } from "../../../auth/PhoneValidation";
import UserSnackbar from "../../../snackBars/UserSnackbar";
import csc from "country-state-city";
// import base64 from 'base64topdf';


const BasicDetails = ({ userData, setUserData, nextPage, previousPage, setPreviousPage, count, setCount, mode }) => {

    //useRef
    const imageRef = useRef();
    const resumeRef = useRef();
    //useRef for mandatory input fields
    const firstNameRef = useRef(null);
    const emailRef = useRef(null)
    const phoneRef = useRef(null)
    const totalExperienceRef = useRef(null)

    const role = userData.role;
    const id = sessionStorage.getItem("id")

    //default avatar
    let profileAvatar =
        "https://res.cloudinary.com/df2qsnyox/image/upload/v1681133496/Default%20Profile%20Avatar/profile-avatar_ogxozq_ranghl.png";

    //states for cropper
    const [profileImage, setProfileImage] = useState(userData.profileImg1);
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [isAvatar, setIsAvatar] = useState(userData.isAvatar);
    const [isNewImg, setIsNewImg] = useState(false);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState();
    const [croppedArea, setCroppedArea] = useState();
    const [interaction, setInteraction] = useState(false);
    const [croppedImage, setCroppedImage] = useState(userData.croppedImage);
    const [profileImg1, setProfileImg1] = useState(userData.profileImg1);
    const [newImageUploaded, setNewImageUploaded] = useState(false);
    const [resume, setResume] = useState();
    const [resumeObject,setResumeObject]=useState(null);
    const [resumeType, setResumeType] = useState("");
    const [newResumeUploaded, setNewResumeUploaded] = useState(false);
    const [openResumePreview, setOpenResumePreview] = useState(false);
    const [sliderValue, setSliderValue] = useState(zoom);
    const [imagePublicId, setImagePublicId] = useState(userData.imagePublicId)
    const [resumePublicId, setResumePublicId] = useState(userData.resumePublicId)
    const [croppedImagePublicId, setCroppedImagePublicId] = useState(userData.croppedImagePublicId)
    const [resumeDialog, setResumeDialog] = useState(false);
    //states for snackBar
    const [message, setMessage] = useState("")
    const [openSnackbar, setOpenSnackbar] = useState(false)

    //form states
    const [firstName, setFirstName] = useState(userData.firstName);
    const [lastName, setLastName] = useState(userData.lastName);
    const [profileTitle, setProfileTitle] = useState(userData.profileTitle);
    const [email, setEmail] = useState(userData.email);
    // store the regex of phone number patterns of all countries
    const phones = getPhonesRegex();
    const [phoneIsValid, setPhoneIsValid] = useState(true);
    const [phone1, setPhone1] = useState(userData.phone1);
    const [phone2, setPhone2] = useState(userData.phone2);
    const [salary, setSalary] = useState(userData.salary);
    const [payType, setPayType] = useState(userData.payType);
    const [checked, setChecked] = useState(userData.checked);
    const [dob, setDob] = useState(userData.dob);
    const [gender, setGender] = useState(userData.gender);
    const [address1, setAddress1] = useState(userData.address1);
    const [address2, setAddress2] = useState(userData.address2);
    const [parseLoader, setParseLoader] = useState(false)
    const [skills, setSkills] = useState(userData.skills)
    //resume 
    const [parsedResume, setParsedResume] = useState(null);

    const [flag, setFlag] = useState(false)
    const [resumeName, setResumeName] = useState("");

    const [linkedinURL, setLinkedinURL] = useState(userData.socialMedia.linkedin)
    const [githubURL, setGithubURL] = useState(userData.socialMedia.github)
    const [portfolioURL, setPortfolioURL] = useState(userData.socialMedia.portfolio)

    let nextRender = true;

    //city state countries for getting external states, cities and countries
    const countries = csc.getAllCountries();
    const updatedCountries = countries.map((country) => ({
        label: country.name,
        value: country.id,
        ...country,
    }));

    const updatedStates = (countryId) =>
        csc.getStatesOfCountry(countryId).map((state) => ({
            label: state.name,
            value: state.id,
            ...state,
        }));

    const updatedCities = (stateId) =>
        csc.getCitiesOfState(stateId).map((city) => ({
            label: city.name,
            value: city.id,
            ...city,
        }));


    const [initialLocation, setInitialLocation] = useState({
        country: userData.country
            ? { label: userData.country }
            : null,
        state: userData.state
            ? { label: userData.state }
            : null,
        city: userData.city
            ? { label: userData.city }
            : null,
        countryId: userData.countryId,
        stateId: userData.stateId,
    });

    const [totalExperience, setTotalExperience] = useState(userData.totalExperience);
    const [nationality, setNationality] = useState(userData.nationality);
    const [postalCode, setPostalCode] = useState(userData.postalCode);

    const [errors, setErrors] = useState({
        imageSize: "",
        imageType: "",
        resume: "",
        resumeSize: "",
        resumeType: "",
        firstName: "",
        email: "",
        phone: "",
        salaryDetail: "",
        dob: "",
        gender: "",
        nationality: "",
        totalExperience: "",
    });

    //Dialog box states
    const [openAlertDialog, setOpenAlertDialog] = useState(false);
    const [openResumeAlertDialog, setOpenResumeAlertDialog] = useState(false);
    useEffect(() => {
        setResume(userData.resume)
        const resumeFormat = userData.resume?.includes(".pdf")
        setResumeType(resumeFormat ? "application/pdf" : "")
        setCrop(userData.crop)
        setZoom(userData.zoom)
        setCroppedAreaPixels(userData.croppedAreaPixels)
    }, [count])

    // Function to scroll to the target element
    const scrollToElement = (ref) => {
        if (ref.current) {
            ref.current.scrollIntoView({
                behavior: 'smooth', // You can change this to 'auto' for instant scrolling
                block: 'center', // You can change this to 'end' or 'center' to adjust where the element appears on the screen
            });
        }
    };

    //Profile Photo handlers
    const onCropChange = (crop) => {
        if (!isAvatar) {
            setCrop(crop);
            setUserData({ ...userData, crop: crop })
        }
    }

    const onZoomChange = (zoom) => {
        if (isAvatar) {
            return null
        } else { setZoom(zoom); setUserData({ ...userData, zoom: zoom }) }

    }

    // called everytime the image is cropped
    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
        setCroppedArea(croppedArea);
        setUserData({ ...userData, croppedAreaPixels: croppedAreaPixels })
    }, []);

    // used to get cropped image
    const showCroppedImage = useCallback(async () => {
        let profileImgUrl = profileImg1;
        try {
            const croppedImage = await getCroppedImg(
                profileImgUrl,
                croppedAreaPixels,
                0 //rotation
            );
            setCroppedImage(croppedImage);
        } catch (e) {
            // console.error(e);
        }

    }, [croppedAreaPixels, profileImg1]);

    // handle remove image alert dialog
    const handleRemoveImageDialog = () => {
        setOpenAlertDialog(true);
    };
    const handleRemoveResumeDialog = () => {
        setResumeDialog(false)
    }
    // handle profile image upload
    const handleProfileImageUpload = async (e) => {

        const fileCheck = e.target.files[0];
        const maxSize = 1024 * 1024; //1MB
        if (fileCheck) {
            if (fileCheck?.size > maxSize) {
                setErrors({ imageSize: "Please upload image less than 1MB" });
                imageRef.current.value = null;
            } else if (!fileCheck?.type.startsWith("image")) {
                setErrors({ imageType: "Please upload an image file" });
                e.target.files = null;
            } else {
                let imageDataUrl = await readFile(fileCheck);
                setProfileImg1(imageDataUrl);
                setProfileImage(fileCheck);

                setNewImageUploaded(true);
                setErrors({ imageSize: "", imageType: "" });
                setIsAvatar(false);
                setIsNewImg(true);
                setInteraction(false);
                setZoom(1);
            }
        }
    };



    // handle profile image remove
    const handleRemoveProfileImage = (e) => {
        setProfileImage(profileAvatar);
        setProfileImg1(profileAvatar);
        setZoom(1);
        setErrors({ imageSize: "", imageType: "" });
        setIsAvatar(true);
        setNewImageUploaded(false);
        imageRef.current.value = null;
        setOpenAlertDialog(false);
        setCrop({ x: 0, y: 0 })
    };

    const handleResumeParse =async () => {

        function extractPositionAndCompany(resumeEntry) {
            let position = '';
            // const regex = /(?:\bat\s)?(.*?)(?:\s\d{2}|\s\d{4})?(?: - Present| –)/;
            // const regex = /(?:\bat\s)?(.*?)(?=\s-\sPresent|\s–|$)/;
            // const regex = /(?:\bat\s)?(.*?)(?=\s\d{2}\/\d{4}|\s\d{4}|\s–)/;
            const regex = /(?:\bat\s)?(.*?)(?=\s(?:Jan(?:uary)?|Feb(?:ruary)?|Mar(?:ch)?|Apr(?:il)?|May|Jun(?:e)?|Jul(?:y)?|Aug(?:ust)?|Sep(?:tember)?|Oct(?:ober)?|Nov(?:ember)?|Dec(?:ember)?)|\s\d{2}\/\d{4}|\s\d{4}|\s–)/;                    
            const match = regex.exec(resumeEntry);
    
            if (match) {
                position = match[1];
            }
        
            return position.trim();
        }

        function monthConversion(monthName) {
            const months = {
                Jan: '00', January: '00', Feb: '01', February: '01', Mar: '02', March: '02',
                Apr: '03', April: '03', May: '04', Jun: '05', June: '05',
                Jul: '06', July: '06', Aug: '07', August: '07', Sep: '08', September: '08',
                Oct: '09', October: '09', Nov: '10', November: '10', Dec: '11', December: '11'
            };
            return months[monthName];
        }

        function dateMatch(dateMatch)
        {
            if (dateMatch) {
                if(!dateMatch[0].includes('-') && !dateMatch[0].includes('/')){
                    const [date] = dateMatch[0].split(' ');
                    console.log(date);
                    const month = monthConversion(date);
                    const year =dateMatch[0].split(' ')[1];
                    console.log("month and year",month,year);
                    console.log("Date: ", `${month}/${year}`);

                    return {month,year}
                    
                }
                else{
                    console.log("Date: ", dateMatch[0]);
                    if(dateMatch[0].includes('/')){
                        const date=dateMatch[0].split('/');
                        console.log("Date",date);
                        const month=`0${(date[0]-1).toString()}`;
                        // `0${(startMonth-1).toString()}`
                        const year=date[1];
                        console.log("month and year",month,year);

                        return {month,year}


                    }
                    else if(dateMatch[0].includes('-')){
                        const date=dateMatch[0].split('-');
                        console.log("Date",date);
                        const month=`0${(date[0]-1).toString()}`;
                        const year=date[1];
                        console.log("month and year",month,year);

                        return {month,year}


                    }
                }
                
                
            
            }
        }
        
        console.log("Called")
        setFlag(true);
        setParseLoader(true)
        setParsedResume(resume)
        setResumeName(resume?.name)
        // setResume(null)


        const formData = new FormData();
        formData.append("parsedResume", resumeObject)
        console.log(userData)

        let resumeURL = resume
        let fileExtension = resumeObject?.name.split('.')
        fileExtension = fileExtension[1]
        console.log(fileExtension)

        // if(fileExtension == "docx") {
        //     console.log("called")
        //     try {
        //         let res = await axios.post('/api/admin/convert-resume-pdf', formData)
        //         resumeURL = `data:application/docx;base64,${res.data}`
        //         console.log("res data",res.data)

        //     }
        //     catch(err) {
        //         console.log(err)
        //     }
        // }



        const formData1 = new FormData()
        formData1.append("parsedResume", resumeURL)

        axios.post(`/api/admin/resume/parse`, formData1).then(res => {


            setParseLoader(false)
            setOpenResumeAlertDialog(false)
        
            console.log(res)
            const { email,skills,languages,phone,profiles,experience} = res.data;
            console.log(profiles);
            console.log("exprience",experience);
            setEmail(email)
            if(phone){
                setPhone1(phone)
            }

            if (profiles) {
                let splittedProfiles = profiles.split('\n')
                console.log(splittedProfiles)
                let profilesArr = []
                splittedProfiles.forEach(profile => {
                    const [label, link] = profile.trim().split(':')
                    console.log(label, link)
                    profilesArr.push({ label, link })
                })
                console.log(profilesArr)
                profilesArr.forEach(profile => {
                    switch (profile.label.toLowerCase()) {
                        case "github": setGithubURL(profile.link)
                            break;
                        case "linkedin": setLinkedinURL(profile.link)
                            break;
                        case "portfolio": setPortfolioURL(profile.link)
                            break;
                        default:
                    }
                })
            }


            console.log("email",email);
            console.log("skills",skills);
            console.log("languages",languages);
            console.log("skills",!skills);
            const splitString = skills ? skills : languages;
            console.log(splitString)

            if(splitString){
                const [skillsPart] = splitString.split('Certificates');
                // Extract the skills by removing the leading 'skills: ' and trimming whitespace and quotes
                const updatedskills = skillsPart.trim().replace(/'/g, '').split(/\n|,|;|:/)               
                .map(skill => ({skillName:skill.trim(),yearOfExprience:""}));
    
                console.log(updatedskills);    
                setSkills(updatedskills)
                // setSkillsList(updatedskills)
            }
            if(experience){
                let position,company,startMonth,startYear,endMonth,endYear;
                const list = [...userData.experienceDetails];
    
                let expArr = experience.split('\n')
                console.log(expArr);
                let expStr = ""
                for (let item of expArr) {
                    expStr = expStr.concat(item + " ")
                    if (expStr.includes("20")) break
                }
    
                console.log(expStr);
    
                position = extractPositionAndCompany(expStr);
                list[0].position=position;
    
                
                //code for extracting startdate and  enddate
                // if (expStr.toLowerCase().includes('present') || expStr.toLowerCase().includes('current')) {
                //     let startDateRegex = /(?:(?:Jan(?:uary)?|Feb(?:ruary)?|Mar(?:ch)?|Apr(?:il)?|May|Jun(?:e)?|Jul(?:y)?|Aug(?:ust)?|Sep(?:tember)?|Oct(?:ober)?|Nov(?:ember)?|Dec(?:ember)?)\s\d{4})|(?:\d{2}(?:\/|-)\d{4})/
                
                //     // if (startDateRegex.exec(expStr)) {
                //     //     console.log("Start Date: ", startDateRegex.exec(expStr)[0]);
                
                //     //     console.log("End Date: ", "present");
                //     // } 
                //     const startDateMatch = startDateRegex.exec(expStr);
                //     const {month,year}=dateMatch(startDateMatch);
                //     startMonth=month;
                //     startYear=year;
                   
                //     list[0].startMonth=startMonth;           
                //     list[0].startYear=startYear;
                //     console.log("End Date: ", "present");
                //     list[0].currentWorking=true;
                //     list[0].checked=true;
                //     setUserData({...userData, experienceDetails: list})
                
                
                
                
                // }
                // else {
                //     let startDateRegex = /(?:(?:Jan(?:uary)?|Feb(?:ruary)?|Mar(?:ch)?|Apr(?:il)?|May|Jun(?:e)?|Jul(?:y)?|Aug(?:ust)?|Sep(?:tember)?|Oct(?:ober)?|Nov(?:ember)?|Dec(?:ember)?)\s\d{4})|(?:\d{2}(?:\/|-)\d{4})/
                //     let endDateRegex = /(?:(?:Jan(?:uary)?|Feb(?:ruary)?|Mar(?:ch)?|Apr(?:il)?|May|Jun(?:e)?|Jul(?:y)?|Aug(?:ust)?|Sep(?:tember)?|Oct(?:ober)?|Nov(?:ember)?|Dec(?:ember)?)\s\d{4})|(?:\d{2}(?:\/|-)\d{4})/g
                
                
                //     const startDateMatch = startDateRegex.exec(expStr);
                //     const {month,year}=dateMatch(startDateMatch);
                //     startMonth=month;
                //     startYear=year;
                
                
                //     const endDateMatch = endDateRegex.exec(expStr);
                //     const {month:endMonthName,year:endYearname}=dateMatch(endDateMatch);
                //     endMonth=endMonthName;
                //     endYear=endYearname;
                //     console.log("endMonth",endMonth);
                //     console.log("endYear",endYear);
                
                //     list[0].startMonth=startMonth;           
                //     list[0].startYear=startYear;
                //     list[0].endMonth=endMonth;
                //     list[0].endYear=endYear;
                //     if(list[0].endMonth && list[0].endYear)
                //     {
                //         list[0].currentWorking=false;
                //         list[0].checked=false;
                //     }
                //     setUserData({...userData, experienceDetails: list})
                
                // }
                

    
    
                //if experience contains 'present' keyword
                if (expStr.toLowerCase().includes('present') || expStr.toLowerCase().includes('current')) {
                    let startDateRegex = /(?:(?:Jan(?:uary)?|Feb(?:ruary)?|Mar(?:ch)?|Apr(?:il)?|May|Jun(?:e)?|Jul(?:y)?|Aug(?:ust)?|Sep(?:tember)?|Oct(?:ober)?|Nov(?:ember)?|Dec(?:ember)?)\s\d{4})|(?:\d{2}(?:\/|-)\d{4})/

                    // if (startDateRegex.exec(expStr)) {
                    //     console.log("Start Date: ", startDateRegex.exec(expStr)[0]);

                    //     console.log("End Date: ", "present");
                    // } 
                    const startDateMatch = startDateRegex.exec(expStr);
                    if (startDateMatch) {
                        if (!startDateMatch[0].includes('-') && !startDateMatch[0].includes('/')) {
                            const [startDate] = startDateMatch[0].split(' ');
                            console.log(startDate);
                            startMonth = monthConversion(startDate);
                            startYear = startDateMatch[0].split(' ')[1];
                            console.log("month and year", startMonth, startYear);
                            console.log("Start Date: ", `${startMonth}/${startYear}`);

                        }
                        else {
                            console.log("Start Date: ", startDateMatch[0]);
                            if (startDateMatch[0].includes('/')) {
                                const startDate = startDateMatch[0].split('/');
                                console.log("startDate", startDate);
                                // startMonth = `0${(startDate[0] - 1).toString()}`;
                                startMonth = `${((startDate[0]-1)>=10?"":"0")}${(startDate[0] - 1).toString()}`;

                                console.log(startMonth);
                                // `0${(startMonth-1).toString()}`
                                startYear = startDate[1];
                                console.log("month and year", startMonth, startYear);
                            }
                            else if (startDateMatch[0].includes('-')) {
                                const startDate = startDateMatch[0].split('-');
                                console.log("startDate", startDate);
                                // startMonth = `0${(startDate[0] - 1).toString()}`;
                                startMonth = `${((startDate[0]-1)>=10?"":"0")}${(startDate[0] - 1).toString()}`;
                                console.log(startMonth);
                                startYear = startDate[1];
                                console.log("month and year", startMonth, startYear);
                            }
                        }
                    }
                    list[0].startMonth = startMonth;
                    list[0].startYear = startYear;
                    console.log("End Date: ", "present");
                    list[0].currentWorking = true;
                    list[0].checked = true;
                }
                //if experience contains both start date and end date
                else {
                    let startDateRegex = /(?:(?:Jan(?:uary)?|Feb(?:ruary)?|Mar(?:ch)?|Apr(?:il)?|May|Jun(?:e)?|Jul(?:y)?|Aug(?:ust)?|Sep(?:tember)?|Oct(?:ober)?|Nov(?:ember)?|Dec(?:ember)?)\s\d{4})|(?:\d{2}(?:\/|-)\d{4})/
                    let endDateRegex = /(?:(?:Jan(?:uary)?|Feb(?:ruary)?|Mar(?:ch)?|Apr(?:il)?|May|Jun(?:e)?|Jul(?:y)?|Aug(?:ust)?|Sep(?:tember)?|Oct(?:ober)?|Nov(?:ember)?|Dec(?:ember)?)\s\d{4})|(?:\d{2}(?:\/|-)\d{4})/g


                    if (startDateRegex.exec(expStr)) {
                        const startDateMatch = startDateRegex.exec(expStr)[0];

                        if (!startDateMatch.includes('-') && !startDateMatch.includes('/')) {
                            const [startDate] = startDateMatch.split(' ');
                            console.log(startDate);
                            startMonth = monthConversion(startDate);
                            startYear = startDateMatch.split(' ')[1];
                            console.log("month and year", startMonth, startYear);
                            console.log("Start Date: ", `${startMonth}/${startYear}`);
                        }
                        else {
                            console.log("Start Date: ", startDateMatch);
                            if (startDateMatch.includes('/')) {
                                const startDate = startDateMatch.split('/');
                                console.log("startDate", startDate);
                                // startMonth = `0${(startDate[0] - 1).toString()}`;
                                startMonth = `${((startDate[0]-1)>=10?"":"0")}${(startDate[0] - 1).toString()}`;
                                console.log(startMonth);
                                startYear = startDate[1];
                                console.log("month and year", startMonth, startYear);
                            }
                            else if (startDateMatch.includes('-')) {
                                const startDate = startDateMatch.split('-');
                                console.log("startDate", startDate);
                                // startMonth = `0${(startDate[0] - 1).toString()}`;
                                startMonth = `${((startDate[0]-1)>=10?"":"0")}${(startDate[0] - 1).toString()}`;
                                console.log(startMonth);
                                startYear = startDate[1];
                                console.log("month and year", startMonth, startYear);
                            }
                        }
                    }

                    if (endDateRegex.exec(expStr)) {
                        console.log(expStr)
                        const endDateMatch = endDateRegex.exec(expStr)?.[0];
                        if (!endDateMatch?.includes('-') && !endDateMatch?.includes('/') && endDateMatch) {
                            // console.log(endDateMatch[0]);
                            const [endDate] = endDateMatch?.split(' ');
                            console.log(endDate);
                            endMonth = monthConversion(endDate);
                            endYear = endDateMatch?.split(' ')[1];
                            console.log("month and year", endMonth, endYear);
                        }
                        else {
                            console.log("end Date: ", endDateMatch);
                            if (endDateMatch?.includes('/')) {
                                const endDate = endDateMatch?.split('/');
                                console.log("endDate", endDate);
                                // endMonth = `0${(endDate[0] - 1).toString()}`;
                                endMonth = `${((endDate[0]-1)>=10?"":"0")}${(endDate[0] - 1).toString()}`;

                                console.log(endMonth);
                                endYear = endDate[1];
                                console.log("month and year", endMonth, endYear);
                            }
                            else if (endDateMatch?.includes('-')) {
                                const endDate = endDateMatch?.split('-');
                                console.log("endDate", endDate);
                                // endMonth = `0${(endDate[0] - 1)?.toString()}`;
                                endMonth = `${((endDate[0]-1)>=10?"":"0")}${(endDate[0] - 1).toString()}`;
                                console.log(endMonth);
                                endYear = endDate[1];
                                console.log("month and year", endMonth, endYear);
                            }
                        }
                    }
                    list[0].startMonth = startMonth;
                    list[0].startYear = startYear;
                    list[0].endMonth = endMonth;
                    list[0].endYear = endYear;
                    if (list[0].endMonth && list[0].endYear) {
                        list[0].currentWorking = false;
                        list[0].checked = false;
                    }
                    // setUserData({ ...userData, experienceDetails: list })
                }
    

            }
           




        }).catch(err => {
            console.log(err)
            setOpenResumeAlertDialog(false)
            setParseLoader(true)
            setMessage("Resume couldn't be parsed")
            setOpenSnackbar(true)
        })
    }

    console.log(userData)
    // disable the right and left arrow keys for the slider
    function preventHorizontalKeyboardNavigation(event) {
        if (event.key === "ArrowLeft" || event.key === "ArrowRight") {
            event.preventDefault();
        }
    }

    // used to get url of newly uploaded image
    function readFile(file) {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.addEventListener("load", () => resolve(reader.result), false);
            reader.readAsDataURL(file);
        });
    }

    // handle resume upload
    const handleResumeUpload = async (e) => {

        const resumeCheck = e.target.files[0];
        const fileTypes = ['doc', "docx", "pdf"];
        let fileExtension = resumeCheck?.name.split('.')
        fileExtension = fileExtension[1]
        console.log(fileExtension)
        console.log('resumecheck', resumeCheck)
        setErrors({
            resume: "",
        });
        const maxSize = 1024 * 1024 * 1; //1MB
        //resumeCheck.size > maxSize ,
        if (resumeCheck?.size > maxSize) {
            setErrors({ resumeSize: "Please upload a file less than 1MB" });
            resumeRef.current.value = null;
        }
        else if (!fileTypes.includes(fileExtension)) {
            console.log(resumeCheck?.type, fileTypes.includes(resumeCheck?.type))
            setErrors({ resumeType: "Please upload a pdf/doc file" });
        }
        else {
            // if (fileExtension==='doc'||fileExtension==='docx') {
            //     console.log("doc file or docx file");
            //     console.log(resumeCheck);

            // }

            const resumeURL = await readFile(resumeCheck)
            console.log(resumeURL);
            

            // const formData = new FormData();
            // formData.append("parsedResume", resumeURL)
            
            // if(fileExtension =='docx') {
            //     axios.post('/api/admin/convert-resume-pdf', formData)
            //     .then(res=>{
            //         console.log(res)
            //     })
            //     .catch(err=> {

            //     })
            // }
            
            
            // let decodedBase64 = base64.base64Decode(resumeURL, 'conversion');
            // console.log(decodedBase64);

            setResumeName(resumeCheck.name)
            setNewResumeUploaded(true);
            setResume(resumeURL);
            setResumeObject(resumeCheck)
            setResumeType(resumeCheck?.type)
            // setOpenResumeAlertDialog(true)
            setErrors({ resumeType: "", resumeSize: "" });
            setUserData({ ...userData, resume: resumeURL, resumeFormat: fileExtension })
                           
        }
    };

    // handle open of resume preview
    const handleOpenResumePreview = () => {
        setOpenResumePreview(true);
    };

    // handle close of resume preview
    const handleCloseResumePreview = () => {
        setOpenResumePreview(false);
    };

    // handle resume remove
    const handleRemoveResume = () => {
        setParseLoader(false)

        if (flag) {
            setResume(null);
            setResumeType("");
            if (resumeRef.current) {
                resumeRef.current.value = null;
            }
            setNewResumeUploaded(false);
            setFirstName("")
            setLastName("")
            setEmail("")
            setUserData({ ...userData, resume: null, resumeFormat: "", firstName: "", lastName: "", email: "" })
        } else {
            setResume(null);
            setResumeType("")
            if (resumeRef.current) {
                resumeRef.current.value = null;
            }
            setNewResumeUploaded(false);
            setUserData({ ...userData, resume: null, resumeFormat: "" })
        }
        setResumeDialog(false)
    };

    //dialog handlers
    const onCloseAlertDialog = () => {
        setOpenAlertDialog(false)
    }


    //maincard form handler
    const checkHandler = () => {
        setChecked((prevalue) => !prevalue);
        if (!checked) {
            setErrors({ salaryDetail: "" });
            setSalary("As per industry standards");
            setPayType("")
        }
    };

    const firstNameHandler = (e) => {
        setFirstName(e.target.value);
        setErrors({
            firstName: "",
        });
    };

    const lastNameHandler = (e) => {
        setLastName(e.target.value);
        // setErrors({
        //     lastName: "",
        // });
    };

    const profileTitleHandler = (e) => {
        setProfileTitle(e.target.value);
        // setErrors({
        //     profileTitle: "",
        // });
    };

    const emailHandler = (e) => {
        setEmail(e.target.value);
        setErrors({
            email: "",
        });
    };

    //handle salary for screener
    const handleSalary = (e) => {
        const limit = 7;
        setSalary(e.target.value.slice(0, limit));
        setErrors({
            salaryDetail: "",
        });
    };

    const payTypeHandler = (e) => {
        setPayType(e.target.value);
        setErrors({
            salaryDetail: "",
        });
    };

    const dobHandler = (e) => {
        setDob(e.target.value);
        // setErrors({ dob: "" });
    };

    const genderHandler = (e) => {
        setGender(e.target.value);
        // setErrors({ gender: "" });
    };

    const address1Handler = (e) => {
        setAddress1(e.target.value);
    };
    const address2Handler = (e) => {
        setAddress2(e.target.value)
    };

    const nationalityHandler = (e) => {
        setNationality(e.target.value);
        // setErrors({ nationality: "" });
    };

    const tottalExperienceHandler = (e) => {
        setTotalExperience(e.target.value);
        setErrors({ totalExperience: "" });
    };

    // handlePin Change to limit to max of 10m digits
    const handleNumChange = (e) => {
        // setErrors({
        //     postalCode: "",
        // });
        const limit = 10;
        setPostalCode(e.target.value.slice(0, limit));
    };

    const goToNextPage = () => {
        if (firstName === "") {
            scrollToElement(firstNameRef)
            return setErrors({
                firstName: "First Name is required"
            })
        }

        if (email === "") {
            scrollToElement(emailRef)
            return setErrors({
                email: "Email is required",
            });
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
            scrollToElement(emailRef)
            return setErrors({
                email: "Email is Invalid",
            });
        }

        if (phone1 === "") {
            // scrollToElement(phoneRef)
            return setErrors({ phone: "Phone Number is required" });

        } else if (!phoneIsValid) {
            // scrollToElement(phoneRef)
            return setErrors({ phone: "Invalid Phone Number" });
        }


        // if (totalExperience === "") {
        //     scrollToElement(totalExperienceRef)
        //     return setErrors({
        //         totalExperience: "Total Experience is required",
        //     });
        // }

        if (!resume) return setErrors({ resume: "Resume is required!" });

        axios.get(`/api/admin/internal-database/${role}/get-all-emails/${email}`)
            .then((res) => {
                if (mode == "add") {
                    if (res.data.length > 0) {

                        nextRender = false
                        setMessage("Email already exists")
                        setOpenSnackbar(true)
                        setErrors({ email: "Email already exists" })
                        scrollToElement(emailRef)
                    } else {
                        if (nextPage) {
                            setCount(count + 1)
                        } else {
                            setCount(count - 1)
                        }
                        setPreviousPage(false)
                    }
                } else {
                    if (nextPage) {
                        setCount(count + 1)
                    } else {
                        setCount(count - 1)
                    }
                    setPreviousPage(false)
                }
            })
            .catch((err) => {
                if (err.response.status === 404) {
                    if (nextPage) {
                        setCount(count + 1)
                    } else {
                        setCount(count - 1)
                    }
                    setPreviousPage(false)
                }
            })

        setUserData({
            ...userData,
            profileImg1,
            croppedImage,
            croppedAreaPixels,
            croppedArea,
            checked,
            zoom,
            crop,
            imagePublicId,
            resumePublicId,
            croppedImagePublicId,
            interacted: interaction,
            firstName,
            lastName,
            profileTitle,
            email,
            phone1,
            phone2,
            salary,
            payType,
            isAvatar,
            dob,
            gender,
            address1,
            address2,
            city: initialLocation.city?.label,
            state: initialLocation.state?.label,
            country: initialLocation.country?.label,
            stateId: userData.stateId,
            countryId: userData.countryId,
            totalExperience,
            nationality,
            postalCode,
            linkedinURL,
            githubURL,
            portfolioURL,
            skills
        })
    }

    return (
        <div>
            <Slide direction="left" in={true} timeout={500}>

                <Box sx={{ flexGrow: 1, background: "#faf9f6" }}>
                    <Card
                        id="main-edit"
                        className=" form-card table-card"
                        style={{
                            padding: "15px 20px 18px 20px ",
                            boxShadow: "none",
                        }}
                    >
                        <Grid
                            container
                            spacing={0}
                            direction="row"
                            className="add-recruiter-card-container"
                        >
                            <Grid
                                item
                                xs={8}
                                md={5}
                                lg={6}
                            >
                                <div style={{ display: "flex", gap: "10px" }}>
                                    <SchoolIcon sx={{ color: "#2c3e50" }} />
                                    <h5 style={{ color: "#283848" }}>Basic Details</h5>
                                </div>
                            </Grid>

                            <Grid container spacing={0} justifyContent="center">
                                {/* Left Grid item */}
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={4}
                                    lg={4}
                                    sx={{ padding: "0 20px 20px 20px" }}
                                    className="left-grid"
                                >
                                    <div className="left-container" style={{ width: "530px" }}>
                                        <div className="image-container basic-details">
                                            <div className="crop-container">
                                                <Cropper
                                                    className="cropper"
                                                    image={profileImg1}
                                                    crop={crop}
                                                    zoom={zoom}
                                                    initialCroppedAreaPixels={
                                                        isNewImg || isAvatar ? null : croppedAreaPixels
                                                    }
                                                    aspect={16 / 16}
                                                    onCropChange={(crop) => onCropChange(crop)}
                                                    onCropComplete={onCropComplete}
                                                    onCropSizeChange={showCroppedImage}
                                                    onInteractionStart={() => {
                                                        setInteraction(true);
                                                        showCroppedImage();
                                                    }}
                                                    onInteractionEnd={showCroppedImage}
                                                    onZoomChange={(zoom) => onZoomChange(zoom)}
                                                    restrictPosition={true}
                                                    onMediaLoaded={showCroppedImage}
                                                    objectFit="auto-cover"
                                                />
                                            </div>
                                            <div>
                                                <label className="image-upload">
                                                    <input
                                                        type="file"
                                                        onChange={handleProfileImageUpload}
                                                        accept=".jpg, .png, .jpeg"
                                                        ref={imageRef}
                                                    />
                                                    <span>Upload</span>
                                                </label>
                                                <button
                                                    className="image-remove"
                                                    onClick={handleRemoveImageDialog}
                                                >
                                                    Remove
                                                </button>
                                            </div>
                                        </div>
                                        <div>
                                            <span className="text-danger">
                                                {errors.imageSize} {errors.imageType} {errors.image}
                                            </span>
                                        </div>
                                        <div className="name-designation">
                                            <h3>{firstName} {lastName}</h3>
                                            <p>{profileTitle}</p>
                                        </div>

                                        {resume ? (
                                            <div className="resume-name-preview">
                                                <div style={{ textAlign: "center" }}>
                                                    {firstName ? `${firstName} ${lastName}-resume` : `Resume`}
                                                </div>
                                                <div
                                                    style={{ textAlign: "center", marginLeft: "5px" }}
                                                >
                                                    <Tooltip title="Remove">
                                                        <CloseIcon
                                                            className="close-icon2"
                                                            fontSize="small"
                                                            onClick={() => setResumeDialog(true)}
                                                        />
                                                    </Tooltip>
                                                </div>

                                                <div
                                                    className="eye-icon"
                                                    style={{
                                                        textAlign: "center",
                                                        padding: "0px 5px",
                                                    }}
                                                >
                                                    {resumeType == "application/pdf" ? <Tooltip title="View">
                                                        <RemoveRedEyeOutlinedIcon
                                                            fontSize="small"
                                                            onClick={handleOpenResumePreview}
                                                            style={{ cursor: "pointer" }}
                                                        />
                                                    </Tooltip> : <Tooltip title="Download">
                                                        <a href={resume} download >
                                                            <DownloadIcon
                                                                className="download-resume"
                                                                fontSize="small"
                                                                style={{ cursor: "pointer" }}
                                                            />
                                                        </a>
                                                    </Tooltip>
                                                    }
                                                </div>
                                                <div
                                                    className="download-icon"
                                                    style={{
                                                        textAlign: "center",
                                                        padding: "0px 5px",
                                                    }}
                                                >
                                                    <a href={resume} download >
                                                        <DownloadIcon
                                                            fontSize="small"
                                                            style={{ cursor: "pointer" }}
                                                        />
                                                    </a>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="upload-resume">
                                                <p>Upload Resume*</p>
                                                <label className="resume-file-upload">
                                                    <input
                                                        type="file"
                                                        onChange={(e) => {
                                                            handleResumeUpload(e)
                                                            setOpenResumeAlertDialog(true)
                                                        }}
                                                        accept=".pdf, .doc, .docx"
                                                        ref={resumeRef}
                                                    />

                                                    <span>Attach</span>
                                                </label>
                                            </div>
                                        )}
                                        <div>
                                            <p class="text-danger">
                                                {errors.resumeSize} {errors.resumeType}{" "}
                                                {errors.resume}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="slider-container">
                                        <Box sx={{ height: 100 }}>
                                            <Slider
                                                sx={{
                                                    '& input[type="range"]': {
                                                        WebkitAppearance: "slider-vertical",
                                                    },
                                                }}
                                                step={0.1}
                                                type="range"
                                                min={1}
                                                max={3}
                                                // marks
                                                size="small"
                                                value={zoom}
                                                orientation="vertical"
                                                defaultValue={sliderValue}
                                                aria-label="Zoom In"
                                                valueLabelDisplay="auto"
                                                onKeyDown={preventHorizontalKeyboardNavigation}
                                                onChange={
                                                    isAvatar
                                                        ? null
                                                        : (e, zoom) => {
                                                            setZoom(zoom);
                                                            setUserData({ ...userData, zoom: zoom })
                                                            setInteraction(true);
                                                            showCroppedImage();
                                                        }
                                                }
                                            />
                                        </Box>
                                        <div>
                                            <p>Zoom in</p>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={8}
                                    lg={8}
                                    // sx={{ padding: "0 20px 20px 20px" }}
                                    className="right-grid form-view-mobile"
                                >
                                    <form noValidate>
                                        <div className="input-container">
                                            <div className="split-inputs">
                                                <div class="input-field-modal">
                                                    <label className="date-label">First Name*</label>
                                                    <input
                                                        ref={firstNameRef}
                                                        className={`${errors.firstName && "input-field-error"}`}
                                                        type="text"
                                                        placeholder="First Name"
                                                        value={firstName}
                                                        onChange={firstNameHandler}
                                                    />
                                                    <div className='error-div' style={{ height: "20px" }}>
                                                        <span className="text-danger">{errors.firstName}</span>
                                                    </div>
                                                </div>


                                                <div class="input-field-modal">
                                                    <label className="date-label">Last Name</label>
                                                    <input
                                                        class="position"
                                                        type="text"
                                                        placeholder="Last Name"
                                                        value={lastName}
                                                        onChange={lastNameHandler}
                                                    />
                                                    <div className='error-div' style={{ height: "20px" }}>
                                                        {/* <span className="text-danger">{errors.lastName}</span> */}
                                                    </div>
                                                </div>

                                            </div>

                                            <div className="split-inputs">

                                                <div class="input-field-modal">
                                                    <label className="date-label">Email*</label>
                                                    <input
                                                        className={`${errors.email && "input-field-error"}`}
                                                        ref={emailRef}
                                                        class="email"
                                                        type="email"
                                                        placeholder="Email Id*"
                                                        disabled={mode == 'edit'}
                                                        value={email}
                                                        onChange={emailHandler}
                                                    />
                                                    <div className='error-div' style={{ height: "20px" }}>
                                                        <span className="text-danger">{errors.email}</span>
                                                    </div>
                                                </div>
                                                <div class="input-field-modal">
                                                    <label className="date-label">Profile Title</label>
                                                    <input
                                                        class="profileTitle"
                                                        type="text"
                                                        placeholder="Ex: Software Developer"
                                                        value={profileTitle}
                                                        onChange={profileTitleHandler}
                                                    />
                                                    <div className='error-div' style={{ height: "20px" }}>
                                                        {/* <span className="text-danger">{errors.profileTitle}</span> */}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="split-inputs">
                                                <div class="input-field-modal">
                                                    <label className="date-label">Primary Phone*</label>
                                                    <PhoneInput
                                                        id="phone"
                                                        placeholder="Contact No*"
                                                        name="phone"
                                                        class="phone"
                                                        className={`${errors.phone && "input-field-error"}`}
                                                        // ref={phoneRef}
                                                        country={"us"}
                                                        value={phone1}
                                                        onChange={(phone, country) => {
                                                            setPhone1(phone);
                                                            var regex =
                                                                phones[country.countryCode.toUpperCase()];
                                                            if (regex != undefined) {
                                                                if (!regex.test(phone)) {
                                                                    setPhoneIsValid(false);
                                                                    return setErrors({
                                                                        phone: "Invalid Phone Number",
                                                                    });
                                                                } else {
                                                                    setPhoneIsValid(true);
                                                                    return setErrors({ phone: "" });
                                                                }
                                                            }
                                                        }}

                                                    />

                                                </div>
                                                <div class="input-field-modal">
                                                    <label className="date-label">Secondary Phone</label>
                                                    <PhoneInput
                                                        id="phone"
                                                        placeholder="Contact No"
                                                        name="phone"
                                                        class="phone"
                                                        country={"us"}
                                                        value={phone2}
                                                        onChange={(phone, country) => {
                                                            setPhone2(phone);
                                                            var regex =
                                                                phones[country.countryCode.toUpperCase()];
                                                            if (regex != undefined) {
                                                                if (!regex.test(phone)) {
                                                                    setPhoneIsValid(false);
                                                                    return setErrors({
                                                                        phone: "Invalid Phone Number",
                                                                    });
                                                                } else {
                                                                    setPhoneIsValid(true);
                                                                    return setErrors({ phone: "" });
                                                                }
                                                            }
                                                        }}

                                                    />

                                                </div>

                                            </div>
                                            <div className='error-div' style={{ height: "20px" }}>
                                                <span className="text-danger">{errors.phone}</span>
                                            </div>

                                            <div className="split-inputs">
                                                <div class="input-field-modal">
                                                    <label className="date-label">
                                                        Expected Pay
                                                    </label>

                                                    <input
                                                        disabled={checked}
                                                        class="max-salary"
                                                        value={salary}
                                                        type="number"
                                                        placeholder="Expected Salary"
                                                        onChange={handleSalary}
                                                    />
                                                    <div className='error-div' >
                                                        <span className="text-danger">{errors.salaryDetail}</span>
                                                    </div>
                                                </div>
                                                <div class="input-field-modal">
                                                    <label className="date-label">Pay Type</label>
                                                    <select
                                                        style={{ outline: "none" }}
                                                        disabled={checked}
                                                        value={payType || "default"}
                                                        onChange={payTypeHandler}
                                                    >
                                                        <option
                                                            value="default"
                                                            selected
                                                            disabled
                                                            hidden
                                                        >
                                                            Pay Type
                                                        </option>
                                                        <option value="Hour">Hourly</option>
                                                        <option value="Week">Weekly</option>
                                                        <option value="Month">Monthly</option>
                                                        <option value="Annum">Per Annum</option>
                                                    </select>
                                                </div>

                                            </div>
                                            <div className="input-field">
                                                <FormGroup className="input-field-checkbox">
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                size="small"
                                                                checked={checked}
                                                                value={checked}
                                                                onClick={checkHandler}
                                                                sx={{
                                                                    '&.Mui-checked': {
                                                                        color: "#f06161",
                                                                    },
                                                                }}
                                                            />
                                                        }
                                                        label="As per industry standards"
                                                    />
                                                </FormGroup>
                                            </div>



                                            <div className="split-inputs">
                                                <div class="input-field-modal">
                                                    <label className="date-label">Date of Birth</label>
                                                    <input
                                                        class="position"
                                                        type="date"

                                                        value={dob}
                                                        max={new Date().toISOString().split("T")[0]}
                                                        onChange={dobHandler}
                                                        placeholder="mm/dd/yyyy"
                                                    />
                                                    <div className='error-div' style={{ height: "20px" }}>
                                                        <span className="text-danger">{errors.dob}</span>
                                                    </div>
                                                </div>
                                                <div class="input-field-modal">
                                                    <label className="date-label">Gender</label>
                                                    <select
                                                        style={{ outline: "none" }}
                                                        value={gender || "default"}
                                                        onChange={genderHandler}
                                                    >
                                                        <option value="default" selected disabled hidden>
                                                            Gender
                                                        </option>
                                                        <option value="Male">Male</option>
                                                        <option value="Female">Female</option>
                                                        <option value="Prefer not to say">
                                                            Prefer not to say
                                                        </option>
                                                    </select>
                                                    <div className='error-div' style={{ height: "20px" }}>
                                                        <span className="text-danger">{errors.gender}</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="split-inputs">
                                                <div class="input-field-modal">
                                                    <label className="date-label">Address Line1</label>
                                                    <input
                                                        class="position"
                                                        type="text"
                                                        value={address1}
                                                        onChange={address1Handler}
                                                        placeholder="Ex: Door No./Apartment No."
                                                    />
                                                    <div className='error-div' style={{ height: "20px" }}></div>
                                                </div>
                                                <div class="input-field-modal">
                                                    <label className="date-label">Address Line2</label>
                                                    <input
                                                        class="position"
                                                        type="text"
                                                        value={address2}
                                                        onChange={address2Handler}
                                                        placeholder="Ex: Street name"
                                                    />
                                                    <div className='error-div' style={{ height: "20px" }}></div>
                                                </div>
                                            </div>

                                            <div className="split-inputs">
                                                <div class="input-field-modal">
                                                    <label className="date-label">Country</label>
                                                    <Select
                                                        class="position"
                                                        type="text"
                                                        placeholder=" Country"
                                                        value={initialLocation.country}
                                                        options={updatedCountries}
                                                        onChange={(value) => {
                                                            setInitialLocation(
                                                                {
                                                                    country: value,
                                                                    countryId: value.id,
                                                                    state: null,
                                                                    city: null,
                                                                },
                                                                false
                                                            );

                                                            setUserData({
                                                                ...userData, country: value, countryId: value.id,
                                                                state: null,
                                                                city: null,
                                                            })
                                                            // setErrors({
                                                            //     country: "",
                                                            // });
                                                        }}

                                                    />
                                                    <div className='error-div' style={{ height: "20px" }}>
                                                        {/* <span className="text-danger">{errors.country}</span> */}
                                                    </div>
                                                </div>
                                                <div class="input-field-modal">
                                                    <label className="date-label">State</label>
                                                    <Select
                                                        class="position"
                                                        type="text"
                                                        placeholder="State"
                                                        options={updatedStates(
                                                            initialLocation.country
                                                                ? initialLocation.countryId
                                                                : null
                                                        )}
                                                        value={initialLocation.state}
                                                        onChange={(value) => {
                                                            setInitialLocation(
                                                                {
                                                                    ...initialLocation,
                                                                    state: value,
                                                                    city: null,
                                                                    stateId: value.id,
                                                                },
                                                                false
                                                            );
                                                            setUserData({
                                                                ...userData, state: value,
                                                                city: null,
                                                                stateId: value.id,
                                                            })
                                                            // setErrors({
                                                            //     state: "",
                                                            // });
                                                        }}
                                                    />
                                                    <div className='error-div' style={{ height: "20px" }}>
                                                        {/* <span className="text-danger">{errors.state}</span> */}
                                                    </div>
                                                </div>

                                                <div class="input-field-modal">
                                                    <label className="date-label">City</label>
                                                    <Select
                                                        class="position"
                                                        placeholder="City"
                                                        type="text"
                                                        options={updatedCities(
                                                            initialLocation.state ? initialLocation.stateId : null
                                                        )}
                                                        value={initialLocation.city}
                                                        onChange={(value) => {
                                                            setInitialLocation({
                                                                ...initialLocation,
                                                                city: value,
                                                            });
                                                            setUserData({ ...userData, city: value })
                                                            // setErrors({
                                                            //     city: "",
                                                            // });
                                                        }}
                                                    />
                                                    <div className='error-div' style={{ height: "20px" }}>
                                                        {/* <span className="text-danger">{errors.city}</span> */}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="split-inputs">
                                                <div class="input-field-modal">
                                                    <label className="date-label">Total Experience</label>
                                                    <input
                                                        className={`${errors.totalExperience && "input-field-error"}`}
                                                        ref={totalExperienceRef}
                                                        class="position"
                                                        type="number"
                                                        value={totalExperience}
                                                        onChange={tottalExperienceHandler}
                                                        placeholder="Ex: 5 Years"
                                                    />

                                                </div>
                                                <div class="input-field-modal">
                                                    <label className="date-label">Nationality</label>
                                                    <input
                                                        class="position"
                                                        type="text"
                                                        value={nationality}
                                                        onChange={nationalityHandler}
                                                        placeholder="Ex: American"
                                                    />

                                                </div>
                                                <div class="input-field-modal">
                                                    <label className="date-label">Postal Code</label>
                                                    <input
                                                        class="position"
                                                        type="number"
                                                        value={postalCode}
                                                        onChange={handleNumChange}
                                                        placeholder="Ex: 1234567"
                                                    />

                                                </div>
                                            </div>
                                            <div className='error-div' style={{ height: "20px" }}>
                                                {/* <span className="text-danger">{errors.totalExperience}</span> */}
                                            </div>

                                            <label className="date-label">Social Media</label>

                                            <div className="split-inputs">
                                                <div class="input-field-modal">
                                                    <div className="social-media-container">
                                                        <LinkedInIcon fontSize="small" style={{ color: "#f06161" }} />
                                                        <input
                                                            class="position"
                                                            type="text"
                                                            value={linkedinURL}
                                                            onChange={(e) => setLinkedinURL(e.target.value)}
                                                            placeholder="Linkedin Profile URL"
                                                        />
                                                    </div>
                                                </div>
                                                <div class="input-field-modal">
                                                    <div className="social-media-container">
                                                        <GitHubIcon fontSize="small" style={{ color: "#f06161" }} />
                                                        <input
                                                            value={githubURL}
                                                            onChange={(e) => setGithubURL(e.target.value)}
                                                            className="position"
                                                            type="text"
                                                            placeholder="Github Profile URL"
                                                        />
                                                    </div>
                                                </div>
                                                <div class="input-field-modal">
                                                    <div className="social-media-container">
                                                        <PublicIcon fontSize="small" style={{ color: "#f06161" }} />
                                                        <input
                                                            // className={`${errors.totalExperience && "input-field-error"}`}
                                                            value={portfolioURL}
                                                            onChange={(e) => setPortfolioURL(e.target.value)}
                                                            className="position"
                                                            type="text"
                                                            placeholder="Portfolio URL"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                    <div className="button-container navigation-btn-container next-btn-container">
                                        <button className="btn-style filled-btn filled-btn-animation" onClick={goToNextPage}>
                                            {" "}
                                            Next
                                        </button>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Card>
                </Box>
            </Slide>

            {/* Alert Dialog  */}
            <Dialog
                fullWidth
                maxWidth="xs"
                open={openAlertDialog}
                onClose={onCloseAlertDialog}
                className="experience-edit-dialog"
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent className="dialog-content">
                    <Grid container spacing={0} justifyContent="center">
                        <Grid
                            item
                            xs={11.5}
                            sm={11.5}
                            md={12}
                            lg={12}
                            className="experience-delete-grid"
                        >
                            <div className="delete-message-icon">
                                <DeleteMessageIcon fontSize="small" />
                            </div>
                            <div className="delete-dialog-content">
                                <h5>Are you sure?</h5>
                                <p>Do you want remove your profile picture?</p>
                            </div>
                        </Grid>
                    </Grid>
                </DialogContent>
                <div className="alert-buttons-container">
                    <button className="warning-btn-style filled-btn filled-btn-animation" onClick={onCloseAlertDialog}>
                        {" "}
                        Cancel
                    </button>
                    <button className="warning-btn-style outlined-btn outlined-btn-animation" onClick={handleRemoveProfileImage}>
                        {" "}
                        Confirm
                    </button>
                </div>
            </Dialog>
            <Dialog
                fullWidth
                maxWidth="xs"
                open={resumeDialog}
                onClose={handleRemoveResumeDialog}
                className="experience-edit-dialog"
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent className="dialog-content">
                    <Grid container spacing={0} justifyContent="center">
                        <Grid
                            item
                            xs={11.5}
                            sm={11.5}
                            md={12}
                            lg={12}
                            className="experience-delete-grid"
                        >
                            <div className="delete-message-icon">
                                <DeleteMessageIcon fontSize="small" />
                            </div>
                            <div className="delete-dialog-content">
                                <h5>Are you sure?</h5>
                                <p>Do you want remove your resume?</p>
                            </div>
                        </Grid>
                    </Grid>
                </DialogContent>
                <div className="alert-buttons-container">
                    <button className="warning-btn-style filled-btn filled-btn-animation" onClick={handleRemoveResumeDialog}>
                        {" "}
                        Cancel
                    </button>
                    <button className="warning-btn-style outlined-btn outlined-btn-animation" onClick={handleRemoveResume}>
                        {" "}
                        Confirm
                    </button>
                </div>
            </Dialog>

            {/* Alert Dialog  */}
            <Dialog
                fullWidth
                maxWidth="xs"
                open={openResumeAlertDialog}
                onClose={openResumeAlertDialog}
                className="experience-edit-dialog"
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent className="dialog-content">
                    {!parseLoader && <Tooltip title="Close">
                        <CloseIcon
                            className="close-icon"
                            onClick={() => { setOpenResumeAlertDialog(false); setResume(null); setResumeType(""); setParsedResume(null) }}
                            sx={{ cursor: "pointer", padding: 0, margin: "-5px 12px 10px 0 !important" }}
                        />
                    </Tooltip>}
                    <Grid container spacing={0} justifyContent="center" >
                        {parseLoader ? <Grid
                            style={{ padding: "30px" }}
                            item
                            xs={11.5}
                            sm={11.5}
                            md={12}
                            lg={12}
                            className="experience-delete-grid"
                        >
                            <div style={{ width: "50px", height: "50px", display: 'flex', justifyContent: 'center', margin: "auto" }} className="delete-message-icon">
                                <CircularProgress />
                            </div>
                            <div className="delete-dialog-content">
                                <h5><strong>Parsing your resume..</strong></h5>
                                <p>Kindly wait. This will take few seconds.</p>
                            </div>
                        </Grid> : <Grid
                            item
                            xs={11.5}
                            sm={11.5}
                            md={12}
                            lg={12}
                            className="experience-delete-grid"
                        >
                            <div className="delete-message-icon">
                                <DeleteMessageIcon fontSize="small" />
                            </div>
                            <div className="delete-dialog-content">
                                <h5><strong>Do you want to parse your resume?</strong></h5>
                                <p>Parsing your resume will auto-fill some of the relevant information.</p>
                            </div>
                        </Grid>}
                    </Grid>
                </DialogContent>
                {!parseLoader && <div className="alert-buttons-container">
                    <button className="warning-btn-style filled-btn filled-btn-animation" onClick={handleResumeParse}>
                        {" "}
                        Yes
                    </button>
                    <button className="warning-btn-style outlined-btn outlined-btn-animation" onClick={() => setOpenResumeAlertDialog(false)}>
                        {" "}
                        No
                    </button>
                </div>}
            </Dialog>

            {/* Resume Preview Dialog */}
            <Dialog open={openResumePreview} onClose={handleCloseResumePreview}>
                <DialogContent>
                    <Tooltip title="Close">
                        <CloseIcon
                            className="close-icon1"
                            onClick={() => {
                                setOpenResumePreview(false);
                            }}
                            sx={{
                                cursor: "pointer",
                                padding: 0,
                                margin: "1px",
                            }}
                        />
                    </Tooltip>
                    <Grid
                        item
                        xs={11.5}
                        sm={11.5}
                        md={11.5}
                        lg={11.5}
                        sx={{
                            padding: "30px 10px 10px 10px",
                            width: "600px",
                            height: "650px",
                        }}
                    >
                        <object
                            className="pdf-preview-component"
                            style={{ width: "550px", height: "550px", margin: "15px" }}
                            data={resume}
                            type="application/pdf"
                        >

                            <iframe
                                src={resume}
                            ></iframe>
                        </object>
                    </Grid>
                </DialogContent>
            </Dialog>

            <UserSnackbar
                openSnackbar={openSnackbar}
                setOpenSnackbar={setOpenSnackbar}
                message={message}
            />
        </div>
    );
};

export default BasicDetails;
