import { MODIFY_AVAILABILITIES, RECEIVE_AVAILABILITIES, RECEIVE_CONFIRMED_AVAILABILITIES } from "../actions/availability";
// import { merge } from 'lodash';

export default (state = [], action) => {
    Object.freeze(state);
    switch (action.type) {
        case RECEIVE_AVAILABILITIES:
            if (!action.availabilities.data.events) return state;
            let calendarAvail = action.availabilities.data.events.map((availability) => {
                return {
                    start: new Date(availability.start),
                    end: new Date(availability.end),
                    title: "Available",
                    salesforeExternalMatchId: availability.salesforeExternalMatchId,
                };
            });
            return Object.assign([], state, calendarAvail);
        case RECEIVE_CONFIRMED_AVAILABILITIES:
            if (!action.confirmedAvailabilities.data.events) return state;
            let confirmedAvailabilities = action.confirmedAvailabilities.data.events.map((availability) => {
                return {
                    start: new Date(availability.start),
                    end: new Date(availability.end),
                    title: "Available",
                    salesforeExternalMatchId: availability.salesforeExternalMatchId,
                };
            });
            return Object.assign([], state, confirmedAvailabilities);
        case MODIFY_AVAILABILITIES:
            if (!action.modifiedAvailabilities) return state;
            return Object.assign([], state, action.modifiedAvailabilities);
        default:
            return state;
    }
};
