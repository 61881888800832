import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Pie } from '@ant-design/plots';

const QuarterPie = ({ invitations }) => {
    const data = invitations
    const config = {
        appendPadding: 10,
        data,
        angleField: 'value',
        colorField: 'type',
        radius: 1,
        legend: 'bottom-left',
        color: ['#ef798a', '#3091A2', "#505253"],
        startAngle: Math.PI,
        endAngle: Math.PI * 1.5,
        label: {
            type: 'inner',
            // offset: '-8%',
            // content: '{name}',
            // style: {
            //     fontSize: 15,
            // },
        },
        interactions: [
            {
                type: 'element-active',
            },
        ],
        pieStyle: {
            lineWidth: 0,
        },
    };
    return <Pie {...config} />;
};

export default QuarterPie