import * as React from "react";
import { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import FormControlLabel from "@mui/material/FormControlLabel";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import SocialLoginModal from "../modals/SocialLoginModal";
import "intl-tel-input/build/css/intlTelInput.css";
import "./LoginComponent.css";
import Logo from "../../images/brand.svg";
import BackArrow from "../../images/left-arrow.png";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import MailIcon from "../../images/mail.png";
import LockIcon from "../../images/lock.png";
import ViewIcon from "../../images/view.png";
import HideIcon from "../../images/hide.png";
import { FcGoogle } from "react-icons/fc";
import { googleLogout, useGoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import FrozenMessageSnackbar from "../snackBars/FrozenMessageSnackbar";
import UserSnackbar from "../snackBars/UserSnackbar";
import { Divider } from "@mui/material";
const theme = createTheme();

const LoginComponent = ({ history }) => {
  const [passwordImage, setPasswordImage] = useState(ViewIcon);
  const [toggler, setToggler] = useState("password");
  const [firstToggle, setFirstToggle] = useState(true);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("");
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
  const [message, setMessage] = useState('');
  const [tempRole, setTempRole] = useState('')
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [loginModal, setLoginModal] = useState(false);
  //icon state
  const [icon, setIcon] = useState('')
  let newData = {};
  let newRole = "";
  const [errors, setErrors] = useState({
    email: "",
    password: "",
    role: "",
  });

  const [user, setUser] = useState("")

  useEffect(() => {
    document.title = "Screener | Login";
  }, []);

  useEffect(
    () => {

      if (user) {
        axios
          .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
              Accept: 'application/json'
            }
          })
          .then((res) => {
            console.log(res.data)
            // setProfile(res.data);

            const { verified_email, email } = res.data
            const newData = {
              email, verified_email
            }

            if (tempRole === "candidate" || tempRole === "screener") {
              axios.post(`/api/${tempRole}/login/social-media`, newData).then(res => {
                console.log(res)

                const { token } = res.data;
                const decoded = jwt_decode(token);
                const { id, role } = res.data.payload;

                sessionStorage.setItem("jwtToken", token);
                sessionStorage.setItem("id", id);
                sessionStorage.setItem("role", role);

                const user = sessionStorage.getItem("jwtToken");
                history.push({
                  pathname: `/${role}/profile`,
                  state: res.data,
                });
                setEmail("");
                setTempRole("")
                setPassword("");
                setRole("");
                setPasswordImage(ViewIcon);

                setToggler("password");

                setErrors({
                  email: "",
                  password: "",
                  role: "",
                });


                // setTimeout(() => {

                //   history.push('/login')
                //   //setSomething(false)
                //   setRedirectModal(false)
                // }, 2100)

              }).catch(err => {
                console.log(err)
                if (err.response.status === 400) {
                  setOpenSnackbar(true)
                  setMessage(err.response.data.message)
                }
                else if (err.response.status === 404) {
                  setOpenSnackbar(true)
                  setMessage(err.response.data.message)
                }
              })
            } else {
              setOpenSnackbar(true)
              setMessage("You cannot Login through Google!")
            }
          })
          .catch((err) => console.log(err));
      }

    }, [user]);

  //passwordHandler
  const passwordHandler = () => {
    if (firstToggle) {
      setToggler("text");
      setPasswordImage(HideIcon);
      setFirstToggle(false);
    } else {
      setToggler("password");
      setPasswordImage(ViewIcon);
      setFirstToggle(true);
    }
  };


  const socialMediaHandler = (platform) => {
    if (!tempRole) {
      setOpenSnackbar(true)
      setMessage("Pleace choose a role")
      return
    }
    switch (platform) {
      case 'google':
        setOpenSnackbar(false)
        setLoginModal(false)
        login()
        break;
    }
  }
  //login submit handler
  const onSubmit = (e) => {
    e.preventDefault();
    if (email === "") {
      return setErrors({ email: "Email is required" });
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      return setErrors({
        email: "Email is Invalid",
      });
    }

    if (password === "") return setErrors({ password: "Password is required" });
    if (!role) return setErrors({ role: "Please select a role" });

    newData = {
      email: email,
      password: password,
      role: role,
    };
    if (role === "candidate" || role === "screener") {
      axios
        .post(`/api/${role}/login`, { newData })
        .then((res) => {
          const { token } = res.data;
          const decoded = jwt_decode(token);
          const { id, role } = res.data.payload;

          sessionStorage.setItem("jwtToken", token);
          sessionStorage.setItem("id", id);
          sessionStorage.setItem("role", role);

          const user = sessionStorage.getItem("jwtToken");
          history.push({
            pathname: `/${role}/profile`,
            state: res.data,
          });

          setEmail("");

          setPassword("");
          setRole("");
          setPasswordImage(ViewIcon);

          setToggler("password");

          setErrors({
            email: "",
            password: "",
            role: "",
          });
        })
        .catch((err) => {
          if (err.response.status == 400) {
            return setErrors({
              password: "Incorrect Password",
            });
          } else if (err.response.status == 404) {
            return setErrors({
              email: err.response.data.message,
            });
          } else if (err.response.status == 401) {
            //Associated with Google
            setOpenSnackbar(true)
            setMessage(err.response.data.message)
          }
        });
    } else if (role === "recruiter") {
      axios
        .post(`/api/recruiter/login`, { newData })
        .then((res) => {
          console.log(newData);
          console.log(res.data);
          const { token } = res.data;
          console.log(token);
          const decoded = jwt_decode(token);
          console.log(decoded);
          const { id, role, profileType, user, companyId, compId } = res.data.payload;
          console.log(profileType)

          sessionStorage.setItem("jwtToken", token);
          sessionStorage.setItem("id", id);
          sessionStorage.setItem("role", role);
          sessionStorage.setItem("profileType", profileType);
          sessionStorage.setItem("user", user);
          sessionStorage.setItem("companyId", companyId);
          sessionStorage.setItem("compId", compId)

          history.push({
            pathname: `/recruiter/profile`,
            state: res.data,
          });

          setEmail("");
          setPassword("");
          setRole("");
          setPasswordImage(ViewIcon);

          setToggler("password");

          setErrors({
            email: "",
            password: "",
            role: "",
          });
        })
        .catch((err) => {
          if (err.response.status == 404) {
            axios
              .post(`/api/admin/login`, { newData })
              .then((res) => {
                console.log(newData);
                console.log(res.data);
                const { token } = res.data;
                console.log(token);

                const decoded = jwt_decode(token);
                console.log(decoded);
                const { id, role, companyId } = res.data.payload;

                sessionStorage.setItem("jwtToken", token);
                sessionStorage.setItem("id", id);
                sessionStorage.setItem("role", role);
                sessionStorage.setItem("companyId", companyId);
                // localStorage.setItem("id", id);
                // localStorage.setItem("jwtToken", token);
                const user = sessionStorage.getItem("jwtToken");
                //history.push(`/admin/profile`);

                history.push({
                  pathname: `/admin/profile`,
                  state: res.data,
                });
                setEmail("");

                setPassword("");
                setRole("");
                setPasswordImage(ViewIcon);

                setToggler("password");

                setErrors({
                  email: "",
                  password: "",
                  role: "",
                });
              })
              .catch((err) => {
                if (err.response.status == 400) {
                  return setErrors({
                    password: "Incorrect Password",
                  });
                } else if (err.response.status == 404) {
                  return setErrors({
                    email: err.response.data.message,
                  });
                } else if (err.response.status == 403) {
                  setOpenSnackbar(true);
                  setMessage(err.response.data.message)
                }
              });
          } else if (err.response.status == 400) {
            return setErrors({
              password: "Incorrect Password",
            });
          } else if (err.response.status == 401) {
            setSnackbarMessage(err.response.data.message);
            setEmail("");
            setPassword("");
            setRole("");
            setOpenErrorSnackbar(true);
          }
        });
    }
  };

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => setUser(codeResponse),
    onError: (error) => console.log('Login Failed:', error)
  });

  const handleGoogleLogin = (e, platform) => {
    e.preventDefault();
    //Dialog popop state
    if ((platform === 'google')) {
      setLoginModal(true)
      setIcon(platform)
    }
    //   if (!role) return setErrors({ role: "Please select a role" });
    // login();
  }

  // 3, if(r==='candidate') candidate profile api/${role}/login/
  //backend,

  return (
    <div className="login-bg">
      <div className="card-container">
        <ThemeProvider theme={theme}>
          <Grid container component="main" className="main-grid">
            <CssBaseline />

            <Grid className="overlay-image" item xs={false} sm={false} md={6}>
              <Grid
                item
                //   md={6}
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
                className="header-grid"
              >
                <div class="left-logo ">
                  <h1 class="welcome-header">Welcome to</h1>

                  <div class="logo-img">
                    <img class="login-img" src={Logo} />

                    <div class="main-heading right-title">
                      <h1>Screener</h1>
                    </div>
                  </div>
                </div>
              </Grid>
              <div class="back-arrow">
                <RouterLink to="/">
                  <img src={BackArrow} />
                </RouterLink>
              </div>
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              component={Paper}
              elevation={6}
              square
              className="login-form"
            >
              <Box
                sx={{
                  my: 3,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  minHeight: "80vh",
                  position: "relative",
                }}
                className="main-box"
              >
                <div class="app-heading">
                  <div class="right-back-arrow">
                    <RouterLink to="/">
                      <ArrowBackIcon />
                    </RouterLink>
                  </div>
                  <div class="container-logo">
                    <img class="screener-logo" src={Logo} />

                    <div class="main-heading main-title">
                      <h1>Screener</h1>
                    </div>
                  </div>
                </div>
                <div className="login-form-container">

                  <div class="login-form-header">
                    <h1>Login</h1>
                  </div>





                  <div class="login-input-conatiner">
                    <div class="social-card-container">
                      {/* <a href="#" class="social">
                          <i class="fab fa-facebook-f"></i>
                        </a> */}
                      {/* <a href="#" class="social">
                           <i class="fab fa-google-plus-g"></i> 
                        <FcGoogle />Login
                      </a> */}
                      {/* <a href="#" class="social">
                          <i class="fab fa-linkedin-in"></i>
                        </a> */}
                      {/* <span>or use your account</span> */}
                      {/* <GoogleLogin
                          clientId="458868131518-q07k762hfd4dfkl09novdikuecv5qu8l.apps.googleusercontent.com"
                          disabled={false}
                          className="google-login-icon"
                          buttonText="Login"
                          onSuccess={responseGoogle}
                          onFailure={responseGoogle}
                          cookiePolicy={"single_host_origin"}
                        /> */}
                      <button className="google-login google-btn" onClick={(e) => handleGoogleLogin(e, 'google')}><FcGoogle classname='google-icon' /> Sign In  </button>
                    </div>
                    <div className="divider-container">
                      <Divider style={{ width: "100px" }} />
                      <div className="circle"><p>Or</p></div>
                      <Divider style={{ width: "100px" }} />
                    </div>
                    <form
                      className="form-conatiner"

                      noValidate
                    >
                      <div class="input-field" >
                        <img class="field-icon" src={MailIcon} />

                        <input
                          className="email"
                          autoComplete="email"
                          onChange={(e) => {
                            setEmail(e.target.value);
                            setErrors({
                              email: "",
                            });
                          }}
                          value={email}
                          id="email"
                          type="email"
                          placeholder="Email Id*"
                        />
                      </div>
                      <div>
                        <span className="text-danger">{errors.email}</span>
                      </div>

                      <div class="input-field">
                        <img class="field-icon" src={LockIcon} />
                        <input
                          className="password"
                          type={toggler}
                          placeholder="Password*"
                          autoComplete="current-password"
                          onChange={(e) => {
                            setPassword(e.target.value);
                            setErrors({
                              password: "",
                            });
                          }}
                          value={password}
                          id="password"
                        />
                        <img
                          class="view-icon"
                          src={passwordImage}
                          onClick={passwordHandler}
                        />
                      </div>
                      <div>
                        {" "}
                        <span className="text-danger">{errors.password}</span>
                      </div>
                      <a class="forgot-password" href="/forgot-password">
                        Forgot Password?
                      </a>
                      <div class="login-radio-container">
                        <label className="radio-label">Login as*</label>

                        <FormControl class="login-radio-group">
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            value={role}
                            onChange={(e) => {
                              setRole(e.target.value);
                              setErrors({
                                role: "",
                              });
                            }}
                          >
                            <FormControlLabel
                              value="candidate"
                              control={
                                <Radio
                                  size="small"
                                  sx={{
                                    "& .MuiSvgIcon-root": {
                                      fontSize: 18,
                                    },
                                  }}
                                  required={true}
                                />
                              }
                              label="Job Seeker"
                            />
                            <FormControlLabel
                              value="screener"
                              control={
                                <Radio
                                  size="small"
                                  sx={{
                                    "& .MuiSvgIcon-root": {
                                      fontSize: 18,
                                    },
                                  }}
                                  required={true}
                                />
                              }
                              label="Screener"
                            />
                            <FormControlLabel
                              value="recruiter"
                              control={
                                <Radio
                                  size="small"
                                  sx={{
                                    "& .MuiSvgIcon-root": {
                                      fontSize: 18,
                                    },
                                  }}
                                  required={true}
                                />
                              }
                              label="Recruiter / Company"
                            />
                          </RadioGroup>
                        </FormControl>
                      </div>
                      <div>
                        <span className="text-danger">{errors.role}</span>
                      </div>
                      <div className="login-button-container">
                        <RouterLink to="/register">
                          <p class="display-link">New User? Register Now</p>
                        </RouterLink>
                        <button class="btn-login" type="submit" onClick={onSubmit}>
                          Login
                        </button>
                      </div>


                    </form>
                  </div>
                </div>
              </Box>
            </Grid>
          </Grid>
        </ThemeProvider>
      </div >
      <FrozenMessageSnackbar
        openErrorSnackbar={openErrorSnackbar}
        setOpenErrorSnackbar={setOpenErrorSnackbar}
        message={snackbarMessage}
      />
      <UserSnackbar openSnackbar={openSnackbar} setOpenSnackbar={setOpenSnackbar} message={message} />

      <SocialLoginModal
        icon={icon}
        show={loginModal}
        role={tempRole}
        setRole={setTempRole}
        socialMediaHandler={socialMediaHandler}
        onHide={() => {
          setTempRole('')
          setLoginModal(false)
        }}
      />
    </div >
  );
};

export default LoginComponent;
