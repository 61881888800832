import React, { useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import axios from "axios";
import Button from "@material-ui/core/Button";
import { withSnackbar, useSnackbar } from "notistack";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import Divider from "@material-ui/core/Divider";
import dateFormat from "dateformat";
import "../recruiters/tableList.css";

const CandidateInterviewList = (props) => {
  const [interviewList, setInterviewList] = React.useState([]);
  const [candidateList, setCandidateList] = React.useState([]);

  // const { user } = props;
  // const candidateid = user._id;
  // console.log(candidateid);
  // console.log(user);

  const {
    auth: { user },
  } = props;

  console.log(user);
  const userId = user.id;
  console.log(userId);

  const { classes } = props;
  const paperStyle = { padding: 10, display: "flex", flexDirection: "row" };

  useEffect(() => {
    axios.get("api/event/interviews").then((response) => {
      setInterviewList(response.data);
    });

    //      axios.get("api/users/screener").then((response) => {
    //            setScreenerList(response.data);
    //     });
    axios.get("api/users/candidate").then((response) => {
      setCandidateList(response.data);
    });
  }, []);

  const columns = [
    {
      field: "id",
      width: -1,
      flex: -1,
      hide: true,
    },
    {
      field: "interviewId",
      headerName: "Interview ID",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "title",
      headerName: "Title",
      minWidth: 150,
      flex: 0.5,
    },
    {
      field: "description",
      headerName: "Description",
      minWidth: 150,
      flex: 0.5,
    },

    {
      field: "candidate",
      headerName: "Candidate Name",
      minWidth: 150,
      flex: 0.5,
    },
    {
      field: "email",
      headerName: "Candidate Email",
      minWidth: 150,
      flex: 0.5,
    },
    {
      field: "startdate",
      headerName: "Interview Date",
      minWidth: 150,
      flex: 0.5,
    },
    {
      field: "starttime",
      headerName: "Interview StartTime",
      minWidth: 150,
      flex: 0.5,
    },
    {
      field: "endtime",
      headerName: "Interview EndTime",
      minWidth: 150,
      flex: 0.5,
    },
    // {
    //   field: "timezone",
    //   headerName: "TimeZone",
    //   minWidth: 150,
    //   flex: 0.5,
    // },
    {
      field: "interviewstatus",
      headerName: "Interview Status",
      minWidth: 150,
      flex: 0.5,
    },
    {
      field: "interviewstage",
      headerName: "Interview Stage",
      minWidth: 150,
      flex: 0.5,
    },
  ];

  const rows = [];
  let startFormat = "";
  let endFormat = "";
  interviewList &&
    interviewList.forEach((item) => {
      // if (candidateid === item.candidate) {
      candidateList &&
        candidateList.forEach((candidate) => {
          if (item.candidate === userId && candidate._id === userId) {
            //deviceRegion
            const deviceRegion = Intl.DateTimeFormat().resolvedOptions()
              .timeZone;
            if (deviceRegion === "America/New_York") {
              startFormat = dateFormat(item.starttime, "hh:MM TT ") + " EST";
              endFormat = dateFormat(item.endtime, "hh:MM TT ") + " EST";
            } else {
              startFormat = dateFormat(item.starttime, "hh:MM TT ") + " IST";
              endFormat = dateFormat(item.endtime, "hh:MM TT ") + " IST";
            }
            console.log(item._id);
            console.log(candidate._id);
            console.log(candidate.name);
            console.log(item.title);
            console.log(item.screener);

            console.log(item.startdate);
            rows.push({
              id: item._id,
              interviewId: item.interviewId,
              title: item.title,
              // timezone:(item.timezone).toUpperCase(),
              description: item.description,
              candidate: candidate.name,
              email: candidate.email,
              startdate: dateFormat(item.startdate, "mmmm dS, yyyy"),
              starttime: startFormat,

              endtime: endFormat,
              interviewstatus: item.interviewstatus,
              interviewstage: item.interviewstage,
            });
            // } else {
            //   console.log("No interviews")
            // }
          }
        });
    });

  return (
    <>
      <br /> <br /> <br /> <br /> <br /> <br />
      <br />
      <Typography variant="h6" style={{ alignItems: "center" }}>
        Scheduled Interviews
      </Typography>
      <Divider />
      <br />
      <br />
      <div className="myList">
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={10}
          disableSelectionOnClick
          className="tableList"
          autoHeight
        />
      </div>
    </>
  );
};

CandidateInterviewList.propTypes = {
  auth: PropTypes.object.isRequired,
};

const msp = (state) => ({
  auth: state.auth,
});

export default connect(msp)(CandidateInterviewList);
