import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import withStyles from "@material-ui/core/styles/withStyles";
import React, { Component } from "react";

const styles = (theme) => ({
    cardHeader: {
        background: "#FFFFFF",
    },
    title: {
        color: "#212121",
        textAlign: "left",
        letterSpacing: "0px",
        font: "regular 32px",
        fontWeight: "500",
    },
    standard: {
        background: "#FFFFFF 0% 0% no-repeat padding-box",
        border: "3px solid #A5D6A7",
        borderRadius: "10px",
        width: "600px",
        height: "100%",
        opacity: "1",
    },
});

class DashCalendarCard extends Component {
    render() {
        const { title, classes } = this.props;
        return (
            <>
                <Card className={classes.standard}>
                    <CardHeader align="center" className={classes.cardHeader} classes={{ title: classes.title }} title={title} />{" "}
                    {this.props.children}
                </Card>
            </>
        );
    }
}

export default withStyles(styles)(DashCalendarCard);
