import React from "react";
import Logo from "../../images/emailimg.jpg";
import Modal from "react-bootstrap/Modal";
import "../auth/LoginComponent.css";

//popup to show on password reset.
const ResetPasswordModal = (props) => {
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <img
        width="300"
        height="200"
        src={Logo}
        alt="email-img"
        style={{ margin: "0 auto" }}
      />

      <Modal.Body>
        <h4 style={{ textAlign: "center" }}>{props.message}</h4>
      </Modal.Body>
    </Modal>
  );
};

export default ResetPasswordModal;
