import React, { useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import axios from "axios";
import Typography from "@material-ui/core/Typography";

import Button from "@material-ui/core/Button";
import { withSnackbar, useSnackbar } from "notistack";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import dateFormat from "dateformat";
import { useConfirm } from "material-ui-confirm";
import LaunchIcon from "@mui/icons-material/Visibility";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { makeStyles, Divider } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import "./tableList.css";
import DateFnsUtils from "@date-io/date-fns";

import {
  DatePicker,
  MuiPickersUtilsProvider,
  TimePicker,
} from "material-ui-pickers";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";

const InterviewList = (props) => {
  const [interviewList, setInterviewList] = React.useState([]);
  const [screenerList, setScreenerList] = React.useState([]);
  const [candidateList, setCandidateList] = React.useState([]);
  const [info, setInfo] = React.useState({
    candidateName: "",
    screenerName: "",
    title: "",
    interviewdate: "",
    description: "",
    status: "",
    stage: "",
  });
  const [deleteId, setDeleteId] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirm();

  const { search } = window.location;
  const input = new URLSearchParams(search).get("search");
  const [keyword, setKeyword] = React.useState(input || "");
  const BarStyling = {
    width: "32rem",
    height: "5px",
    boxShadow: "5px 10px #d9d9d9",
    background: "white",
    border: "none",
    padding: "20px",
    margin: "0px 0px 20px 10px",
    border: "1px solid",
  };

  const useStyles = makeStyles((theme) => ({
    main: {
      width: "auto",
      display: "block", // Fix IE 11 issue.
      marginTop: theme.spacing(-2),
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
      [theme.breakpoints.up(400 + theme.spacing(6))]: {
        width: "50%",
        marginLeft: "auto",
        marginRight: "auto",
      },
    },
    paper: {
      marginTop: theme.spacing(-5),
      display: "flex",
      width: "100%",
      flexDirection: "column",
      alignItems: "center",
      padding: `${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(
        -15
      )}px`,
    },
    button: {
      padding: theme.spacing(2),
      margin: 10,
      alignItems: "center",
    },

    textField: {
      marginLeft: theme.spacing(),
      marginRight: theme.spacing(),
      width: "100px",
    },

    textFieldBox: {
      marginTop: theme.spacing() * 1,
      marginLeft: theme.spacing(),
      marginRight: theme.spacing(),
      width: "500px",
    },
    select: {
      marginTop: theme.spacing() * 1,
      marginLeft: theme.spacing(),
      marginRight: theme.spacing(),
      width: "250px",
    },
    formControl: {
      margin: theme.spacing(),
      minWidth: 120,
      maxWidth: 300,
    },
  }));

  const { user } = props;
  const recruiterid = user._id;
  console.log(recruiterid);
  console.log(user);
  const classes = useStyles();

  //handleClickOpen
  const handleClickOpen = (id) => {
    const arrInterview = interviewList.filter((item) => {
      return item._id === id;
    });

    const arrCandidate = candidateList.filter((item) => {
      return item._id === arrInterview[0].candidate;
    });

    const arrScreener = screenerList.filter((item) => {
      return item._id === arrInterview[0].screener;
    });

    setInfo({
      candidateName: arrCandidate[0].name,
      screenerName: arrScreener[0].name,
      title: arrInterview[0].title,
      interviewdate: arrInterview[0].startdate,
      description: arrInterview[0].description,
      status: arrInterview[0].interviewstatus,
      stage: arrInterview[0].interviewstage,
    });
    setOpen(true);
  };
  //handleClose
  const handleClose = () => {
    setOpen(false);
    setOpenDialog(false);
  };

  const deleteInterviewHandler = (id) => {
    const arrDeleteInterview = interviewList.filter((item) => {
      return item._id === id;
    });
    console.log(arrDeleteInterview[0]._id);

    setDeleteId(arrDeleteInterview[0]._id);
    setOpenDialog(true);
  };

  const onDelete = (id) => {
    console.log(id);
    console.log(`/api/event/interviewdelete/${id}`);
    axios
      .delete(`/api/event/interviewdelete/${id}`)
      .then((res) => {
        console.log(res);

        let regMessage = <span>Interview Deleted Successfully</span>;
        enqueueSnackbar(regMessage, {
          variant: "info",
          autoHideDuration: 4000,
          preventDuplicate: true,
        });
        window.location.reload(false);
      })
      .catch((err) => {
        console.log(err.response);
        alert("An error ccured try submitting the form again");

        let regMessage = (
          <span>An error occured try submitting the form again</span>
        );
        enqueueSnackbar(regMessage, {
          variant: "error",
        });
      });
    console.log("Deleted");
  };

  // const deleteInterviewHandler = (id) => {
  //   // dispatch(deleteOrder(id));
  //   confirm({ description: `You want to Delete the interview ` })
  //     .then(() => {
  //       axios
  //         .delete(`/api/event/interviewdelete/${id}`)
  //         .then((res) => {
  //           console.log(res);

  //           //alert('Interview Deleted successfully');
  //           let regMessage = <span>Interview Deleted successfully</span>;
  //           enqueueSnackbar(regMessage, {
  //             variant: "info",
  //             autoHideDuration: 4000,
  //             preventDuplicate: true,
  //           });
  //           window.location.reload(false);
  //         })
  //         .catch((err) => {
  //           console.log(err.response);
  //           //alert('An error occured try submitting the form again');
  //           let regMessage = (
  //             <span> An error occured try submitting the again</span>
  //           );
  //           enqueueSnackbar(regMessage, {
  //             variant: "error",
  //             // autoHideDuration: 3000,
  //           });
  //         });
  //     })
  //     .catch(() => console.log("Deleted"));
  // };

  const filterInterviews = (unfilteredinterviews, givenInput) => {
    if (!givenInput) {
      return unfilteredinterviews;
    }
    return unfilteredinterviews.filter((interview) => {
      // console.log(candidate);
      const title = String(interview.title).toLowerCase();
      const description = String(interview.description).toLowerCase();
      const interviewstatus = interview.interviewstatus.toLowerCase();
      const interviewstage = interview.interviewstage.toLowerCase();
      const startdate = String(interview.startdate);
      const interviewId = String(interview.interviewId).toLowerCase();

      return (
        title.includes(givenInput.toLowerCase()) ||
        description.includes(givenInput.toLowerCase()) ||
        interviewstatus.includes(givenInput.toLowerCase()) ||
        interviewstage.includes(givenInput.toLowerCase()) ||
        startdate.includes(givenInput.toLowerCase()) ||
        interviewId.includes(givenInput.toLowerCase())
      );
    });
  };


  const columns = [
    {
      field: "id",
      width: -1,
      flex: -1,
      hide: true,
    },
    {
      field: "interviewId",
      headerName: "Interview ID",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "title",
      headerName: "Title",
      minWidth: 150,
      flex: 0.5,
    },

    {
      field: "screener",
      headerName: "Screener Name",
      minWidth: 150,
      flex: 0.5,
    },
    {
      field: "candidate",
      headerName: "Candidate Name",
      minWidth: 150,
      flex: 0.5,
    },
    {
      field: "startdate",
      headerName: "Interview Date",
      minWidth: 200,
      flex: 0.5,
    },
    {
      field: "starttime",
      headerName: "Interview StartTime",
      minWidth: 150,
      flex: 0.5,
    },
    {
      field: "endtime",
      headerName: "Interview EndTime",
      minWidth: 150,
      flex: 0.5,
    },
    // {
    //   field: "timezone",
    //   headerName: "TimeZone",
    //   minWidth: 150,
    //   flex: 0.5,
    // },
    {
      field: "description",
      headerName: "Description",
      minWidth: 150,
      flex: 0.5,
    },
    {
      field: "interviewstatus",
      headerName: "Interview Status",
      minWidth: 200,
      flex: 0.5,
    },
    {
      field: "interviewstage",
      headerName: "Interview Stage",
      minWidth: 250,
      flex: 0.5,
    },
    {
      field: "actions",
      flex: 0.3,
      headerName: "Actions",
      minWidth: 150,
      // type: "number",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            {/* <Link
              to={`/interview/interviewdetails/${params.getValue(
                params.id,
                "id"
              )}`}
              title="Interview Details"
              style={{ color: "#75a478" }}
            >
              <LaunchIcon />
            </Link> */}
            <LaunchIcon
              style={{ color: "#75a478", cursor: "pointer" }}
              onClick={() => handleClickOpen(params.getValue(params.id, "id"))}
            />
            &nbsp;&nbsp;&nbsp;
            <Link to={`/${params.getValue(params.id, "id")}`}>
              {/* Edit */}
              <EditIcon />
            </Link>
            &nbsp;&nbsp;
            <Button
              onClick={() =>
                deleteInterviewHandler(params.getValue(params.id, "id"))
              }
            >
              <DeleteIcon style={{ color: "#75a478" }} />
            </Button>
            {/* <Button onClick={() => onDelete(params.id, params.getValue(params.id, "status"))}>{ params.getValue(params.id, "status") == "Active"? "Deactivate" : "Activate" }</Button> */}
          </>
        );
      },
    },
  ];

  const rows = [];
  let startFormat = "";
  let endFormat = "";
  const filteredInterviews = filterInterviews(interviewList, keyword);

  {
    filteredInterviews &&
      filteredInterviews.map(
        (item) =>
          screenerList &&
          screenerList.map((user) => {
            if (item.screener === user._id) {
              if (candidateList.length !== 0) {
                candidateList &&
                  candidateList.map((candidate) => {
                    if (item.candidate === candidate._id) {
                      const deviceRegion = Intl.DateTimeFormat().resolvedOptions()
                        .timeZone;
                      if (deviceRegion === "America/New_York") {
                        startFormat =
                          dateFormat(item.starttime, "hh:MM TT ") + " EST";
                        endFormat =
                          dateFormat(item.endtime, "hh:MM TT ") + " EST";
                      } else {
                        startFormat =
                          dateFormat(item.starttime, "hh:MM TT ") + " IST";
                        endFormat =
                          dateFormat(item.endtime, "hh:MM TT ") + " IST";
                      }
                      // console.log(dateFormat(item.starttime, "hh:MM TT"));
                      rows.push({
                        id: item._id,
                        interviewId: item.interviewId,
                        screener: user.name,
                        candidate: candidate.name,

                        startdate: dateFormat(item.startdate, "mmmm dS, yyyy"),
                        // starttime: dateFormat(item.starttime, "hh:MM TT Z"),
                        starttime: startFormat,

                        endtime: endFormat,
                        // " " +
                        // item.timezone.toUpperCase(),
                        title: item.title,
                        //timezone:(item.timezone).toUpperCase(),
                        description: item.description || "N/A",
                        interviewstatus: item.interviewstatus,
                        interviewstage: item.interviewstage,
                      });
                    }
                  });
              }
            }
          })
      );
  }

  // filteredInterviews && filteredInterviews.forEach(item => {
  //   console.log(item._id)
  //   //   console.log(item.candidate && item.candidate.name);

  //   screenerList && screenerList.forEach(user => {
  //     if (item.screener === user._id) {

  //       candidateList && candidateList.forEach(candidate => {
  //         if (item.candidate === candidate._id) {
  //           console.log(user.name);
  //           console.log(candidate.name);
  //           console.log(item.candidate);
  //           console.log(candidate._id);
  //           console.log(item.screener);
  //           console.log(user._id);
  //           console.log(item.startdate);
  //           rows.push({
  //             id: item._id,
  //             interviewId: item.interviewId,
  //             screener: user.name,
  //             candidate: candidate.name,
  //             startdate: dateFormat(item.startdate, "mmmm dS, yyyy"),
  //             starttime: dateFormat(item.starttime, "hh:MM TT") + ' ' + (item.timezone).toUpperCase(),
  //             endtime: dateFormat(item.endtime, 'hh:MM TT') + ' ' + (item.timezone).toUpperCase(),
  //             title: item.title,
  //             //timezone:(item.timezone).toUpperCase(),
  //             description: item.description,
  //             interviewstatus: item.interviewstatus,
  //             interviewstage: item.interviewstage,
  //           })
  //         }
  //       })

  //     }
  //   })

  // })

  useEffect(() => {
    axios.get("/api/event/interviews").then((response) => {
      setInterviewList(response.data);
    });

    axios.get("/api/users/screener").then((response) => {
      setScreenerList(response.data);
    });
    axios.get("/api/users/candidate").then((response) => {
      setCandidateList(response.data);
    });
  }, []);

  return (
    <>
      <br /> <br /> <br /> <br /> <br /> <br />
      <div className="Container">
        <div>
          <Link to="/addInterview">Schedule Interview</Link>
        </div>
      </div>
      <br />
      <input
        style={BarStyling}
        type="text"
        key="random1"
        value={keyword}
        placeholder={
          "Search For Interviews based on Title, Status and Stage and description......"
        }
        onChange={(e) => setKeyword(e.target.value)}
        name="search"
      />
      <div className="myList">
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={10}
          disableSelectionOnClick
          className="tableList"
          autoHeight
        />
      </div>
      <Dialog
        open={openDialog}
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Do you want to delete this interview?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            style={{ backgroundColor: "#D73B30", color: "white" }}
          >
            Cancel
          </Button>
          <Link
            style={{ textDecoration: "none", padding: "10px" }}
            to="/interview-list"
          >
            <Button
              onClick={() => onDelete(deleteId)}
              style={{
                backgroundColor: "#75A478",
                color: "white",
              }}
            >
              Delete
            </Button>
          </Link>
        </DialogActions>
      </Dialog>
      {/* Dialog */}
      <Dialog
        maxWidth="lg"
        fullWidth
        open={open}
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <Typography variant="h6" style={{ textAlign: "center" }}>
            Interview Information{" "}
            <Divider style={{ width: "25%", margin: "auto" }} />
            <br />
          </Typography>
          <form className={classes.form}>
            <Grid container spacing={24} style={{ padding: "2em" }}>
              <Grid item xs={12} sm={5}>
                <div>
                  <label>Candidate Name</label>
                  <TextField
                    disabled
                    id="standard-disabled"
                    value={info.candidateName}
                    variant="standard"
                    fullWidth
                  />
                </div>
              </Grid>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Grid item xs={12} sm={5}>
                <div>
                  <label>Screener Name</label>
                  <TextField
                    disabled
                    id="standard-disabled"
                    value={info.screenerName}
                    variant="standard"
                    fullWidth
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={5}>
                <div>
                  <label>Interview Title</label>
                  <TextField
                    disabled
                    id="standard-disabled"
                    value={info.title}
                    variant="standard"
                    fullWidth
                  />
                </div>
              </Grid>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Grid item xs={12} sm={5}>
                <div>
                  <label>Interview Date</label>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      required
                      id="dob"
                      name="dob"
                      value={info.interviewdate}
                      format="dd/MM/yyyy"
                      disabled
                      fullWidth
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </Grid>
              <Grid item xs={12} sm={5}>
                <div>
                  <label>Description</label>
                  <TextField
                    disabled
                    id="standard-disabled"
                    value={info.description}
                    variant="standard"
                    fullWidth
                  />
                </div>
              </Grid>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Grid item xs={12} sm={5}>
                <div>
                  <label>Interview Status</label>
                  <TextField
                    disabled
                    id="standard-disabled"
                    value={info.status}
                    variant="standard"
                    fullWidth
                  />
                </div>
              </Grid>
              <br />
              <Grid item xs={12} sm={5}>
                <div>
                  <label>Interview Stage</label>
                  <TextField
                    disabled
                    id="standard-disabled"
                    value={info.stage}
                    variant="standard"
                    fullWidth
                  />
                </div>
              </Grid>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {/* <Grid item xs={12} sm={5}>
                                <div>
                                <label>Years Of Experience</label>
                                <TextField
                                    disabled
                                    id="standard-disabled"
                                   // value={yearsOfExperience}
                                    variant="standard"
                                    fullWidth
                                />
                                </div>
                            

                            </Grid>
                            <Grid item xs={12} sm={5}>
                                <div>
                                <label>Current Title</label>
                                <TextField
                                    disabled
                                    id="standard-disabled"
                                   // value={currentitle}
                                    variant="standard"
                                    fullWidth
                                />
                                </div>
                            

                            </Grid>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
              {/* <Grid item xs={12} sm={5}>
                                <div>
                                <label>Preferred position</label>
                                <TextField
                                    disabled
                                    id="standard-disabled"
                                   // value={preferredPosition}
                                    variant="standard"
                                    fullWidth
                                />
                                </div>      
                            </Grid>

                            <Grid item xs={12} sm={5}>
                                <div>
                                <label>Highest Degree</label>
                                <TextField
                                    disabled
                                    id="standard-disabled"
                                   // value={highestDegree}
                                    variant="standard"
                                    fullWidth
                                />
                                </div>
                            

                            </Grid>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <Grid item xs={12} sm={5}>
                                <div>
                                <label>Major</label>
                                <TextField
                                    disabled
                                    id="standard-disabled"
                                  //  value={major}
                                    variant="standard"
                                    fullWidth
                                />
                                </div>      
                            </Grid>
                            <Grid item xs={12} sm={5}>
                                <div>
                                <label>English Proficiency</label>
                                <TextField
                                    disabled
                                    id="standard-disabled"
                                 //   value={englishProficiency}
                                    variant="standard"
                                    fullWidth
                                />
                                </div>
                                </Grid>
                            
                                <br />
                                <Grid item xs={12} sm={8}>
                                    <br/><br/>
                    <Typography variant ="subtitle1"  style= {{color:"gray"}} >Contact Details<Divider /><br/></Typography></Grid>
                            
                            <Grid item xs={12} sm={5}>
                                <div>
                                <label>Address Line1</label>
                                <TextField
                                    disabled
                                    id="standard-disabled"
                                  //  value={address_line1}
                                    variant="standard"
                                    fullWidth
                                />
                                </div>      
                            </Grid>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <Grid item xs={12} sm={5}>
                                <div>
                                <label>Address Line2</label>
                                <TextField
                                    disabled
                                    id="standard-disabled"
                                   // value={address_line2}
                                    variant="standard"
                                    fullWidth
                                />
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={5}>
                                <div>
                                <label>City</label>
                                <TextField
                                    disabled
                                    id="standard-disabled"
                                  //  value={city}
                                    variant="standard"
                                    fullWidth
                                />
                                </div>      
                            </Grid>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <Grid item xs={12} sm={5}>
                                <div>
                                <label>State</label>
                                <TextField
                                    disabled
                                    id="standard-disabled"
                                  //  value={state}
                                    variant="standard"
                                    fullWidth
                                />
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={5}>
                                <div>
                                <label>Country</label>
                                <TextField
                                    disabled
                                    id="standard-disabled"
                                 //   value={country}
                                    variant="standard"
                                    fullWidth
                                />
                                </div>      
                            </Grid>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <Grid item xs={12} sm={5}>
                                <div>
                                <label>Postal Code</label>
                                <TextField
                                    disabled
                                    id="standard-disabled"
                                  //  value={postalCode}
                                    variant="standard"
                                    fullWidth
                                />
                                </div>
                            </Grid> */}
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            style={{ backgroundColor: "#D73B30", color: "white" }}
          >
            Back
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

InterviewList.propTypes = {
  user: PropTypes.object.isRequired,
};

const msp = (state) => ({
  user: state.user,
});

export default connect(msp)(InterviewList);
