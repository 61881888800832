import React, { useEffect, useState } from "react";
import ClientDetails from "./ClientDetails";
import PocDetails from "./PocDetails";


const AddClient = ({ setAddClient, mode }) => {


    //default Avatar from cloud
    let profileAvatar =
        "https://res.cloudinary.com/df2qsnyox/image/upload/v1681133496/Default%20Profile%20Avatar/profile-avatar_ogxozq_ranghl.png";
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [croppedAreaPixels, setCroppedAreaPixels] = useState();
    const [clientData, setClientData] = useState({
        crop: crop,
        zoom: 1,
        isAvatar: true,
        croppedAreaPixels: croppedAreaPixels,
        profileImage: profileAvatar,
        croppedImage: profileAvatar,
        imagePublicId: "",
        resumePublicId: "",
        croppedImagePublicId: "",
        companyName: "",
        about: "",
        email: "",
        phone: "",
        address1: "",
        address2: "",
        city: null,
        state: null,
        stateId: "0",
        country: null,
        countryId: "0",
        postalCode: "",
        website: "",
        status: true,
        pocDetails: {
            pocName: "",
            pocEmail: "",
            pocTitle: "",
            pocPhone: ""
        }
    })


    const [count, setCount] = useState(1);
    const [nextPage, setNextPage] = useState(true);
    const [previousPage, setPreviousPage] = useState(false);


    console.log(clientData, "client data")


    return (
        <div>
            {nextPage && count === 1 ? (
                <ClientDetails clientData={clientData} setClientData={setClientData} nextPage={nextPage} previousPage={previousPage} setPreviousPage={setPreviousPage} count={count} setCount={setCount} />
            ) : nextPage && count === 2 ? (
                <PocDetails clientData={clientData} setClientData={setClientData} setAddClient={setAddClient} nextPage={nextPage} previousPage={previousPage} setPreviousPage={setPreviousPage} count={count} setCount={setCount} mode={mode} />
            ) : ""}
        </div>
    )
}


export default AddClient

