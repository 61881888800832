import React, { useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";

// CSS import
import "../../company admin/CompanyAdmin.css";

const Settings = ({ sidebarOpen, mainSettings, setMainSettings, setPageName }) => {

    //Main settings list
    const settingsList = [
        {
            icon: <i class="fa-solid fa-gear"></i>,
            header: "General",
            description: "View and update most used features",
        },
        {
            icon: <i class="fa-solid fa-users"></i>,
            header: "User Roles",
            description: "Manage what staff can see or do in your company",
        },
        {
            icon: <i class="fa-solid fa-bell"></i>,
            header: "Notifications",
            description: "Manage notifications sent to you and your users",
        },
        {
            icon: <i class="fa-solid fa-credit-card"></i>,
            header: "Payments",
            description: "Enable and manage your company's payments",
        },
        {
            icon: <i class="fa-solid fa-file-invoice-dollar"></i>,
            header: "Billing",
            description: "Manage your billing information and view your invoices",
        },
    ];


    //document title change 
    useEffect(() => {
        document.title = "Screener | Settings";
    }, []);

    //back button handler
    const itemHandler = ({ header }) => {
        header = header.replace(/ /g, "").toLowerCase();
        docHeader(header);

        setMainSettings({
            ...mainSettings,
            [header]: true,
            settings: false,
        });

        if (header === "general") { setPageName("general") }
        else if (header === "userroles") { setPageName("userRole") }
        else { setPageName("") }
    };

    //document header functions
    const docHeader = (header) => {
        switch (header) {
            case "userroles":
                document.title = "Screener | User Roles";
                break;
            case "general":
                document.title = "Screener | General";
                break;
            case "notifications":
                document.title = "Screener | Notifications";
                break;
            case "payments":
                document.title = "Screener | Payments";
                break;
            case "billing":
                document.title = "Screener | Billing";
                break;
        }
    };

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: "center",
        color: theme.palette.text.secondary,
    }));



    return (
        <div style={{ marginLeft: `${sidebarOpen === "sidebar-open" ? "200px" : "0px"}` }}>
            <Box sx={{ flexGrow: 1, background: "#faf9f6" }}>
                {/* Setting Heading Card  */}
                <Card id="main-edit" className="add-recruiter-form form-card">
                    <Grid
                        container
                        spacing={0}
                        // direction="row"
                        className="add-recruiter-card-container"
                    >
                        <Grid item xs={8} lg={8} md={7} className="container-heading-title">
                            <h1>Settings</h1>
                        </Grid>
                    </Grid>
                </Card>

                {/* Settings Card */}
                <Card id="skills-edit"
                    className="add-recruiter-form-container"
                    style={{ boxShadow: "none", borderRadius: "2px" }}>
                    <div className="settings-card-container">
                        <Grid container spacing={2}>
                            {settingsList.map((item) => {
                                return (
                                    <Grid
                                        item
                                        xs={12}
                                        sm={4}
                                        md={3}
                                        lg={3}
                                        className="settings-item-grid"
                                    >
                                        <Item
                                            className="settings-item"
                                            onClick={() => itemHandler(item)}
                                        >
                                            <div className="settings-icon">{item.icon}</div>
                                            <div className="settings-heading">
                                                <h6>{item.header}</h6>
                                                <p>{item.description}</p>
                                            </div>
                                        </Item>
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </div>
                </Card>
            </Box>
        </div >
    );
};

export default Settings;
