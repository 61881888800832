import { CardHeader } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import Assessment from "../feedback/candidateAssessment/Assessment";

const useStyles = makeStyles({
    body: {
        backgroundColor: "#C8E6C9",
        fontSize: "1rem",
    },
    card: {
        background: "#FFFFFF 0% 0% no-repeat padding-box",
        border: "3px solid #A5D6A7",
        borderRadius: "10px",
        opacity: "1",
        padding: "50px",
    },
    bigTextField: {
        width: "500px",
        height: "100px",
    },
    confirm: {
        backgroundColor: "#9DCD95",
        marginTop: "30px",
        marginLeft: "20px",
    },
});

export default function InterviewDetails(props) {
    const classes = useStyles();
    const { interviewObject } = props.location.state;

    return (
        <div className="unfinPage-component">
            <Card className={classes.card}>
                <CardHeader align="center" title="Candidate Review Page" />
                <div className="d-flex flex-column mt-5" id="Interview Introduction">
                    <div className="flex-fill mr-5 ml-4">
                        <strong>{`Interview date: ${interviewObject.dateTime}`}</strong>
                        <strong>{` / Candidate: ${interviewObject.candidateName}`}</strong>
                        <strong>{` / Position: ${interviewObject.position}`}</strong>
                        <strong>{` / Status: ${interviewObject.status}`}</strong>{" "}
                    </div>
                    <div>
                        <Assessment interview={interviewObject} />
                    </div>
                </div>
            </Card>
        </div>
    );
}
