import React, { useState, useEffect } from "react";
import axios from "axios";
import CompanyMenu from "../../userProfiles/CompanyMenu";
import Settings from "./Settings";

// CSS import
import "../CompanyAdmin.css";
import UserRole from "./UserRole/UserRole";
import General from "./General/General";
import AddUserRole from "./UserRole/AddUserRole";
import EditUserRole from "./UserRole/EditUserRole";
import Footer from "../../../Footer/Footer";

const MainSettings = () => {
  //Navbar states
  const [open, setOpen] = useState();
  const [sidebarOpen, setsidebarOpen] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [interacted, setInteracted] = useState("");
  const [croppedImg, setCroppedImg] = useState("");
  const [profileImage, setProfileImage] = useState("");
  const [defaultAvatar, setDefaultAvatar] = useState("");

  //settings components
  const [mainSettings, setMainSettings] = useState({
    settings: true,
    general: false,
    userroles: false,
    notifications: false,
    payments: false,
    billings: false,
  });

  //company id and role
  const id = sessionStorage.getItem("id");
  const companyId = sessionStorage.getItem("companyId");
  const role = sessionStorage.getItem("role");

  //to display different pages
  const [pageName, setPageName] = useState("settings")
  const [selectedRole, setSelectedRole] = useState({});

  //fetching data for navbar
  useEffect(() => {
    const { data } = axios.get(`/api/admin/${id}`).then((res) => {
      const {
        defaultAvatar,
        profileImage,
        croppedImage,
        interacted,
        companyName,
      } = res.data.user;
      setDefaultAvatar(defaultAvatar);
      setProfileImage(profileImage);
      setCroppedImg(croppedImage);
      setInteracted(interacted);
      setCompanyName(companyName);
    });
  }, []);

  return (
    <div>
      {/* Navbar Component */}
      <CompanyMenu
        open={open}
        setOpen={setOpen}
        sidebarOpen={sidebarOpen}
        setsidebarOpen={setsidebarOpen}
        companyName={companyName}
        croppedImg={croppedImg}
        profileImage={profileImage}
        interacted={interacted}
        defaultAvatar={defaultAvatar}
      />

      {/* Recruiter List Component */}
      {mainSettings.settings && (
        pageName === "settings" ?
          <Settings
            sidebarOpen={sidebarOpen}
            mainSettings={mainSettings}
            setMainSettings={setMainSettings}
            setPageName={setPageName}
          /> : ""
      )}

      {mainSettings.general && (
        pageName === "general" ?
          <General
            setPageName={setPageName}
            sidebarOpen={sidebarOpen}
            mainSettings={mainSettings}
            setMainSettings={setMainSettings}
          /> : ""

      )}

      {mainSettings.userroles && (
        pageName === "userRole" ?
          <UserRole
            setPageName={setPageName}
            pageName={pageName}
            sidebarOpen={sidebarOpen}
            mainSettings={mainSettings}
            setMainSettings={setMainSettings}
            setSelectedRole={setSelectedRole}
          /> : pageName === "addUserRole" ?
            <AddUserRole
              sidebarOpen={sidebarOpen}
              setPageName={setPageName} /> : pageName === "editUserRole" ?
              <EditUserRole
                sidebarOpen={sidebarOpen}
                setPageName={setPageName}
                selectedRole={selectedRole} /> : ""
      )}

      <Footer sidebarOpen={sidebarOpen} />
    </div>
  );
};

export default MainSettings;
