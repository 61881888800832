import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";

// MUI imports
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import { styled } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import Grid from "@mui/material/Grid";

// ICON imports
import LocationIcon from "@mui/icons-material/LocationOnOutlined";
import EditIcon from "@mui/icons-material/CreateOutlined";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import Phone from "@mui/icons-material/LocalPhoneOutlined";
import CalendarIcon from "@mui/icons-material/CalendarMonth";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import PublicIcon from "@mui/icons-material/Public";

import CompanyMenu from "./CompanyMenu";
import CompanyMainCardEdit from "../modals/CompanyMainCardEdit";
import Spinner from "../loaders/Spinner";
import CompanyPocEdit from "../modals/CompanyPocEdit";
import Footer from "../../Footer/Footer";

import "./Profile.css";

var drawerWidth = 200;
//Main
const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const CompanyProfile = (props) => {
  //UserId and Role
  const id = sessionStorage.getItem("id");
  const role = sessionStorage.getItem("role");

  const [isLoading, setIsLoading] = useState(true);
  let profileAvatar =
    "https://res.cloudinary.com/df2qsnyox/image/upload/v1681133496/Default%20Profile%20Avatar/profile-avatar_ogxozq_ranghl.png";

  const [imageWidth, setImageWidth] = useState("");
  //for navbar and Sidebar
  const [open, setOpen] = useState(false);
  const [sidebarOpen, setsidebarOpen] = useState("");

  //dialog
  const [MainCardEditDetails, setMainCardEditDetails] = useState({});

  useEffect(() => {
    open
      ? setImageWidth("sidebar-open-image")
      : setImageWidth("sidebar-close-image");
  }, [open]);

  const [companyName, setCompanyName] = useState("");
  const [profileTitle, setProfileTitle] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [about, setAbout] = useState("");
  const [startYear, setStartYear] = useState("");
  const [address, setAddress] = useState(null);
  const [location, setLocation] = useState({});
  const [croppedAreaPixels, setCroppedAreaPixels] = useState({});
  const [croppedArea, setCroppedArea] = useState({});
  const [defaultAvatar, setDefaultAvatar] = useState();
  const [profileImage, setProfileImage] = useState(profileAvatar);
  const [croppedImg, setCroppedImg] = useState("");
  const [interacted, setInteracted] = useState();
  const [companyId, setCompanyId] = useState("");
  const [imagePublicId, setImagePublicId] = useState("");
  const [croppedImagePublicId, setCroppedImagePublicId] = useState("");
  const [zoom, setZoom] = useState(1);
  const [PocDetails, setPocDetails] = useState({});
  const [openMainCardEdit, setopenMainCardEdit] = useState(false);
  const [openPocCardEdit, setOpenPocCardEdit] = useState(false);
  const [linkedinURL, setLinkedinURL] = useState("");
  const [websiteURL, setWebsiteURL] = useState("");

  useEffect(() => {
    const { data } = axios.get(`/api/admin/${id}`).then((res) => {
      const {
        companyName,
        designation,
        companyEmail,
        companyPhone,
        companyId,
        about,
        startYear,
        location,
        croppedAreaPixels,
        croppedArea,
        defaultAvatar,
        profileCompletionPercentage,
        profileImage,
        croppedImage,
        interacted,
        croppedImagePublicId,
        zoom,
        imagePublicId,
        PocDetails,
        socialMedia: { linkedin, website },
      } = res.data.user;
      setCompanyName(companyName);
      setProfileTitle(designation);
      setEmail(companyEmail);
      setPhone(companyPhone);
      setCompanyId(companyId);
      setAbout(about);
      setStartYear(startYear);
      setLocation(location);
      setAddress(address);
      setCroppedAreaPixels(croppedAreaPixels);
      setCroppedArea(croppedArea);
      setDefaultAvatar(defaultAvatar);
      setProfileImage(profileImage);
      setCroppedImg(croppedImage);
      setImagePublicId(imagePublicId);
      setInteracted(interacted);
      setCroppedImagePublicId(croppedImagePublicId);
      setZoom(zoom);
      setPocDetails(PocDetails);
      setLinkedinURL(linkedin);
      setWebsiteURL(website);

      setMainCardEditDetails({
        id: id,
        role: role,
        companyName: companyName,
        profileTitle: designation,
        email: companyEmail,
        phone: companyPhone,
        about: about,
        startYear: startYear,
        location: location,
        address: address,
        croppedAreaPixels: croppedAreaPixels,
        croppedArea: croppedArea,
        defaultAvatar: defaultAvatar,
        profileImage: profileImage,
        croppedImg: croppedImg,
        imagePublicId: imagePublicId,
        interacted: interacted,
        croppedImagePublicId: croppedImagePublicId,
        zoom: zoom,
        profileCompletionPercentage: profileCompletionPercentage,
        linkedinURL: linkedin,
        websiteURL: website,
      });
      setIsLoading(false);
    });
  }, [openMainCardEdit, openPocCardEdit]);

  //title
  useEffect(() => {
    if (companyName) document.title = `Screener | ${companyName} `;
  }, [companyName]);

  // main card modal handlers
  const mainCardEditOpenHandler = () => {
    setopenMainCardEdit(true);
  };

  const mainCardEditCloseHandler = () => {
    setopenMainCardEdit(false);
  };

  // POC edit card modal handler
  const pocEditOpenHandler = () => {
    setOpenPocCardEdit(true);
  };

  const pocEditCloseHandler = () => {
    setOpenPocCardEdit(false);
  };

  return isLoading ? (
    <Spinner />
  ) : (
    <>
      <CompanyMenu
        open={open}
        setOpen={setOpen}
        sidebarOpen={sidebarOpen}
        setsidebarOpen={setsidebarOpen}
        companyName={companyName}
        croppedImg={croppedImg}
        profileImage={profileImage}
        interacted={interacted}
        defaultAvatar={defaultAvatar}
      />
      <Main id={`${sidebarOpen}`}>
        <div
          className="root-div"
          id="restrict-scroll"
          style={{ overflowY: "hidden" }}
        >
          <Box sx={{ flexGrow: 1 }}>
            <Card
              id="main-edit"
              className="main-card profile-card"
              style={{ padding: "30px 20px 0px 40px" }}
            >
              {/* 1st Grid container */}
              <Grid
                container
                spacing={0}
                direction="row"
                className="profile-card-container"
              >
                <div className="edit-icon-mobile-container">
                  <Tooltip title="Edit">
                    <EditIcon
                      className="edit-icon-mobile"
                      onClick={mainCardEditOpenHandler}
                    />
                  </Tooltip>
                </div>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={2.5}
                  lg={2}
                  xl={1.5}
                  className="container-profile"
                >
                  <img
                    src={
                      defaultAvatar
                        ? profileAvatar
                        : interacted
                          ? croppedImg
                          : profileImage
                    }
                    className={`${imageWidth}`}
                    style={{ objectFit: "cover" }}
                  />

                  <div
                    class="social-profile-card-container"
                    style={{ zIndex: 1, position: "relative" }}
                  >
                    {linkedinURL && (
                      <a href={linkedinURL} className="social" target="_blank">
                        <LinkedInIcon />
                      </a>
                    )}

                    {websiteURL && (
                      <a href={websiteURL} class="social" target="_blank">
                        <PublicIcon />
                      </a>
                    )}
                  </div>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={7.2}
                  xl={7.5}
                  className="container-heading-title"
                >
                  <div>
                    <h2>{companyName}</h2>
                    <h5>{profileTitle}</h5>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={2.5}
                  lg={2.5}
                  className="container-heading-buttons"
                >
                  <div>
                    <Tooltip title="Edit">
                      <EditIcon
                        id="main-edit"
                        className="edit-icon"
                        onClick={mainCardEditOpenHandler}
                      />
                    </Tooltip>
                  </div>
                </Grid>
              </Grid>

              {/* 2nd Grid container */}
              <Grid
                container
                spacing={0}
                direction="row"
                className="info-container company-profile"
                justifyContent="flex-end"
              >
                <Grid
                  item
                  xs={0}
                  sm={0}
                  md={0}
                  lg={1.8}
                  xl={1.2}
                  className="container-profile"
                ></Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={4.5}
                  lg={3.2}
                  xl={3.1}
                  className="about-container"
                >
                  <h4>About</h4>
                  <p>{about ? about : "Tell us about yourself"}</p>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={5.5}
                  md={3}
                  lg={3}
                  xl={3}
                  className="info-container1 company-container1"
                >
                  <div className="info-container-height">
                    <div className="info-container1-heading">
                      <Phone className="icon" />
                      Phone
                    </div>
                    <p>+{phone}</p>
                  </div>
                  <div className="info-container-height">
                    <div className="info-container1-heading">
                      <LocationIcon className="icon" />
                      Business Address
                    </div>
                    {location.address ? (
                      <p>
                        {location.address}, {location.city}, {location.state}
                        <br></br>
                        {location.country} - {location.postalCode}
                      </p>
                    ) : (
                      <></>
                    )}
                  </div>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={5.5}
                  md={3}
                  lg={3}
                  xl={3}
                  className="info-container2 company-container1"
                >
                  <div className="info-container-height">
                    <div className="info-container1-heading">
                      <CalendarIcon className="icon" />
                      In Business Since
                    </div>
                    <p>{startYear}</p>
                  </div>
                  <div className="info-container-height">
                    <div className="info-container1-heading">
                      <MailOutlineIcon className="icon" />
                      Email
                    </div>
                    <p>{email}</p>
                  </div>
                </Grid>
              </Grid>

            </Card>

            <div className="cards-list">
              <Grid className="card-tiles" >
                <Card
                  id="personal-details-edit"
                  className="side-card profile-card"
                >
                  {/* 1st Grid container */}
                  <Grid
                    container
                    spacing={1}
                    direction="row"
                    className="personal-card sub-card"
                  >
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      className="personal-card-title"
                    >
                      <div className="personal-card-heading">
                        <p>Point Of Contact</p>
                      </div>

                      <div className="personal-card-editIcon">
                        <Tooltip title="Edit">
                          <EditIcon
                            className="edit-icon"
                            onClick={pocEditOpenHandler}
                          />
                        </Tooltip>
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      className="personal-details"
                    >
                      <div className="details-block">
                        {PocDetails.name ? (
                          <>
                            <div className="personal-info">
                              <p className="personal-info-heading">
                                Name: &nbsp;
                              </p>
                              <p className="personal-info-content">
                                {PocDetails.name}
                              </p>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}

                        {PocDetails.email ? (
                          <>
                            <div className="personal-info">
                              <p className="personal-info-heading">
                                Email: &nbsp;
                              </p>
                              <p className="personal-info-content">
                                {PocDetails.email}
                              </p>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}

                        {PocDetails.phone ? (
                          <>
                            <div className="personal-info">
                              <p className="personal-info-heading">
                                Phone: &nbsp;
                              </p>
                              <p className="personal-info-content">
                                +{PocDetails.phone}
                              </p>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}

                        {PocDetails.jobTitle ? (
                          <>
                            <div className="personal-info">
                              <p className="personal-info-heading">
                                Job Title: &nbsp;
                              </p>
                              <p className="personal-info-content">
                                {PocDetails.jobTitle}
                              </p>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            </div>
          </Box>
        </div>
      </Main>

      {/* Main Card Edit Modal */}
      <CompanyMainCardEdit
        {...MainCardEditDetails}
        setMainCardEditDetails={setMainCardEditDetails}
        setopenMainCardEdit={setopenMainCardEdit}
        open={openMainCardEdit}
        onClose={mainCardEditCloseHandler}
      />

      {/* POC Details Edit Modal */}
      <CompanyPocEdit
        {...PocDetails}
        role={role}
        id={id}
        setOpenPocCardEdit={setOpenPocCardEdit}
        open={openPocCardEdit}
        onClose={pocEditCloseHandler}
      />
      <Footer sidebarOpen={sidebarOpen} />
    </>
  );
};

export default CompanyProfile;
