import React, { useState, useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import axios from "axios";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import moment from 'moment-timezone';
import CalendarIcon from '@mui/icons-material/CalendarMonth';
import Carousel from 'react-material-ui-carousel';
import NextArrow from '@mui/icons-material/KeyboardArrowRight';
import PreviousArrow from '@mui/icons-material/KeyboardArrowLeft';

import "./Dashboard.css"
import Spinner from '../loaders/Spinner';
import DonutChart from './Charts/DonutChart';
import ProfileNavbar from "../userProfiles/ProfileNavbar";
import GaugeChart from './Charts/GaugeChart';
import UserSnackBar from '../snackBars/UserSnackbar';

import Calendar from './Charts/CalendarChart';
import BarChart from './Charts/BarChart';
import Footer from '../../Footer/Footer';

const useStyles = makeStyles({
    carouselContainer: {
        height: '80%', // Adjust the percentage value as needed
        marginTop: "15px"
    },
});

const CandidateDashboard = (props) => {

    // profile id
    const id = sessionStorage.getItem("id");
    const history = useHistory()
    // Loading
    const [isLoading, setIsLoading] = useState(true);
    const [progress, setProgress] = useState(0);

    // Navbar states
    let profileAvatar = "https://res.cloudinary.com/df2qsnyox/image/upload/v1681133496/Default%20Profile%20Avatar/profile-avatar_ogxozq_ranghl.png";
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [profileImage, setProfileImage] = useState(profileAvatar);
    const [croppedImg, setCroppedImg] = useState("");
    const [interacted, setInteracted] = useState();
    const [defaultAvatar, setDefaultAvatar] = useState();
    const [registeredThrough, setRegisteredThrough] = useState("")

    // states
    const [role, setRole] = useState("");
    const [profileCompletion, setProfileCompletion] = useState(0);

    const [allInterviews, setAllInterviews] = useState([]);
    const [scheduledToday, setScheduledToday] = useState(0);
    const [interviewsCount, setInterviewsCount] = useState([]);

    const [pendingReviews, setPendingReviews] = useState([]);
    const [pendingReviewsCount, setPendingReviewsCount] = useState(0)

    const [nextInterviewDetails, setNextInterviewDetails] = useState({})
    const [nextInterviewCount, setNextInterviewCount] = useState(0)

    const [actionNeededInterviews, setActionNeededInterviews] = useState({})
    const [actionNeededCount, setActionNeededCount] = useState(0)

    const [selectedState, setSelectedState] = useState("upcoming");
    const [message, setMessage] = useState("")
    const [openSnackbar, setOpenSnackbar] = useState(false)

    const classes = useStyles();

    const [firstCarousel, setFirstCarousel] = useState(0);
    const [thirdCarousel, setThirdCarousel] = useState(0);
    const [activityCount, setActivityCount] = useState(0);


    // calendar states
    const [currentDate, setCurrentDate] = useState("");
    const [completedIntDates, setCompletedIntDates] = useState([]);
    const [upcomingIntDates, setUpcomingIntDates] = useState([]);

    const [allInterviewCount, setAllInterviewCount] = useState([])
    //update document title
    useEffect(() => {
        if (firstName) document.title = `Candidate |  ${firstName}'s Dashboard`;
    }, [firstName]);

    useEffect(() => {
        axios.get(`/api/candidate/${id}`).then((res) => {
            console.log(res);
            const {
                role,
                firstName,
                lastName,
                profileTitle,
                defaultAvatar,
                profileCompletionPercentage,
                profileImage,
                croppedImage,
                interacted,
                registeredThrough
            } = res.data.user;

            setCroppedImg(croppedImage);
            setRole(role);
            setProfileImage(profileImage);
            setFirstName(firstName);
            setLastName(lastName);
            setDefaultAvatar(defaultAvatar);
            setInteracted(interacted);
            setIsLoading(false);
            setProfileCompletion(profileCompletionPercentage)
            setRegisteredThrough(registeredThrough)

            const timer = setInterval(() => {
                setProgress((prevProgress) => (prevProgress >= profileCompletionPercentage ? prevProgress : prevProgress + 5));
            }, 1000);
            return () => {
                clearInterval(timer);
            };
        }).catch(error => console.log(error));

        getAllInterviews()
    }, []);

    // fetching details for every 5 secs
    useEffect(() => {
        const interval = setInterval(() => {

            getAllInterviews();
        }, 5000);
        return () => {
            clearInterval(interval)
        }
    }, [])

    useEffect(() => {
        console.log("useEffect called")
        axios.get(`/api/candidate/get-activity/${id}`).then(res => {
            // console.log(res, "result");
            if (res.data) {

                const activityObject = res.data;
                let loginCount = 0;
                loginCount += findDateDifference(activityObject.loginActivity, "loggedDate");
                console.log(loginCount, 'login')
                loginCount += findDateDifference(activityObject.editActivity, "edittedDate");
                console.log(loginCount, 'Edit')
                loginCount += findDateDifference(activityObject.resumeEditActivity, "resumeEdittedDate");
                console.log(loginCount, 'ProfileEdit')
                loginCount = (loginCount * 3.33) / 3

                setActivityCount(loginCount.toFixed(2))
                console.log(loginCount, 'ProfileEdit')
            }
        }).catch(err => {
            console.log(err)
        })
    }, [])

    let dateObj = {}

    const findDateDifference = (activityArray, key) => {
        let count = 0;
        var currentDate = new Date();

        // Subtract 30 days
        const finalArray = activityArray.map(activity => {

            var activityDate = new Date(activity[key]);
            activityDate.setDate(activityDate.getDate() - 30);

            // Calculate the difference in milliseconds
            var differenceInMilliseconds = currentDate - activityDate;

            // Convert milliseconds to days 
            let differenceInDays = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24)) - 30;
            if (differenceInDays <= 30 && differenceInDays >= 0) {

                // if (key == "loggedDate") {
                console.log("logged")
                const date = activity[key]
                if (dateObj[date] == undefined) {
                    dateObj[date] = 1
                } else {
                    dateObj[date] = dateObj[date] + 1
                }
                // }
                // else {
                //     console.log("Edit")
                //     count++;
                // }
            }
        })

        if (activityArray.length) {
            for (let date in dateObj) {
                console.log(count)
                if (dateObj[date] == 1 || dateObj[date] == 2) {
                    count += 1
                } else {
                    count += Math.trunc(dateObj[date] / 3)
                }
            }
        }
        console.log(dateObj, count)

        return count
    }

    // handle rescheduled interview
    const interviewHandler = (status, interview) => {
        const { companyId, interviewStatus, interviewUniqueId } = interview;
        console.log(status, interview)
        axios.post(`/api/schedule/update-status/candidate/${interviewUniqueId}`, { interview, status, companyId, interviewStatus: interviewStatus.toLowerCase() }).then(res => {
            console.log(res)//Result
            setMessage(res.data.msg)
            setOpenSnackbar(true)

        }).catch(error => {
            console.log(error)
        })
    }

    // function to format the display of date and time
    function formatDateAndTime(inputDate, timezone) {
        const [date, time] = inputDate.split(" ")
        if (date && time) {
            let gmtDate = moment.tz(inputDate, "YYYY-MM-DD hh:mm a", timezone).utc();
            gmtDate = moment.tz(gmtDate, "MMMM Do YYYY[,] h:mm a", timezone);
            gmtDate = gmtDate.format("DD-MMM-YYYY[,] h:mm a, z");
            return gmtDate
        }
        else if (date) {
            let gmtDate = moment.tz(inputDate.trim(), "YYYY-MM-DD hh:mm a", timezone).utc()
            gmtDate = moment.tz(gmtDate, "MMMM Do YYYY", timezone);
            gmtDate = gmtDate.format("DD-MMM-YYYY");
            return gmtDate
        }
        else if (time) {
            let gmtDate = moment.tz(inputDate.trim(), "YYYY-MM-DD hh:mm a", timezone).utc()
            gmtDate = moment.tz(gmtDate, "MMMM Do YYYY", timezone);
            gmtDate = gmtDate.format("DD-MMM-YYYY");
            return gmtDate
        }
        else {
            return " "
        }
    }

    // filtering rescheduled interview which needs action
    const getActionNeededInterviews = (interviews) => {
        const actionNeeded = interviews.filter(item => item.candidateStatus == "Action Needed")
        setActionNeededInterviews(actionNeeded)
        setActionNeededCount(actionNeeded.length)
    }

    // converting interview and local device time to gmt
    const convertTimeToGMT = (date, time, timezone) => {
        const inputTime = `${date} ${time} ${timezone}`;
        const momentObj = moment.tz(inputTime, "YYYY-MM-DD hh:mm A", `${timezone}`);
        const convertedTime = momentObj.clone().tz("GMT").format("YYYY-MM-DD HH:mm");
        return convertedTime;
    }

    const getNextInt = (sortedInterviews) => {
        const year = new Date().getFullYear()
        const month = new Date().getMonth() + 1 < 10 ? '0' + (new Date().getMonth() + 1) : new Date().getMonth() + 1
        const day = new Date().getDate() < 10 ? '0' + new Date().getDate() : new Date().getDate()
        const currentDate = `${year}-${month}-${day}`

        sortedInterviews = sortedInterviews.filter(item => item.interviewDate >= currentDate)
        console.log(sortedInterviews)

        sortedInterviews = sortedInterviews.sort((a, b) => {
            console.log(a, b, 'Int details')
            //button for sorting
            return new Date(a.interviewDate + " " + a.interviewTime) - new Date(b.interviewDate + " " + b.interviewTime)
        })

        const nextInterview = sortedInterviews.find(interview => {
            // interview timings
            const givenTime = interview.interviewTime;
            const givenTimezone = interview.timezone;
            const givenDate = interview.interviewDate;
            const givenGmtTime = convertTimeToGMT(givenDate, givenTime, givenTimezone)

            // local device timings
            const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            const date = new Date();
            const currentTime = date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });
            const currentDate = date.toISOString().split('T')[0];
            const currentGmtTime = convertTimeToGMT(currentDate, currentTime, currentTimezone)

            const date1 = new Date(givenGmtTime);
            const date2 = new Date(currentGmtTime);

            // comparing local and interview timings
            if (date1.getTime() === date2.getTime()) {
                console.log("Dates are equal.");
            } else if (date1.getTime() < date2.getTime()) {
                console.log(`${givenGmtTime} is earlier than ${currentGmtTime}.`);
            } else {
                console.log(`${givenGmtTime} is later than ${currentGmtTime}.`);
                return interview;
            }
        })
        if (nextInterview) {
            setNextInterviewCount(1)
            setNextInterviewDetails(nextInterview)
        } else {
            setNextInterviewCount(0)
        }
    }

    const getAllInterviews = () => {
        axios.get(`/api/schedule/getAllInterviews/${id}`)
            .then(res => {
                let candidateInterviews = res.data;
                let toScheduledArray = [], declinedArray = [], confirmedArray = [], scheduledArray = [], completedArray = [];

                // filter interviews of this candidate
                candidateInterviews.forEach(interview => {
                    let { toSchedule, declined, confirmed, scheduled, completed, companyId } = interview;

                    toSchedule.length > 0 && toSchedule.forEach((item) => {
                        if (item.candidate.id == id && item.rescheduledBy?.id !== '') {
                            toScheduledArray.push({ ...item, companyId })
                        }
                    })

                    declined.length > 0 && declined.forEach((item) => {
                        if (item.candidate.id == id) {
                            declinedArray.push({ ...item, companyId })
                        }
                    })

                    confirmed.length > 0 && confirmed.forEach((item) => {
                        if (item.candidate.id == id) {
                            confirmedArray.push({ ...item, companyId })
                        }
                    })

                    completed.length > 0 && completed.forEach((item) => {
                        if (item.candidate.id == id) {
                            completedArray.push({ ...item, companyId })
                        }
                    })

                    scheduled.length > 0 && scheduled.forEach((item) => {
                        if (item.candidate.id == id) {
                            scheduledArray.push({ ...item, companyId })
                        }
                    })
                })

                // interviews scheduled today
                let todayScheduledNumber = 0
                const year = new Date().getFullYear()
                const month = new Date().getMonth() + 1 < 10 ? '0' + (new Date().getMonth() + 1) : new Date().getMonth() + 1
                const day = new Date().getDate() < 10 ? '0' + new Date().getDate() : new Date().getDate()
                const currentDate = `${year}-${month}-${day}`



                // set calendar states
                setCurrentDate(currentDate);

                let completedDates = completedArray.map(item => item.interviewDate)
                setCompletedIntDates(completedDates)

                const upcomingInterviews = [...scheduledArray, ...confirmedArray]
                let upcomingDates = upcomingInterviews.map(item => item.interviewDate)
                setUpcomingIntDates(upcomingDates)

                scheduledArray.map(interview => {
                    if (interview.interviewDate == currentDate) {
                        todayScheduledNumber++;
                    }
                })
                confirmedArray.map(interview => {
                    if (interview.interviewDate == currentDate) {
                        todayScheduledNumber++;
                    }
                })


                setAllInterviewCount([{
                    type: "Scheduled",
                    value: scheduledArray.length
                }, {
                    type: "Confirmed",
                    value: confirmedArray.length
                }, {

                    type: "Completed",
                    value: completedArray.length

                }, {

                    type: "Declined",
                    value: declinedArray.length

                }])

                let interviews = [...toScheduledArray, ...scheduledArray, ...confirmedArray, ...declinedArray, ...completedArray];
                setAllInterviews(interviews);
                // total o.of interviews scheduled till now
                // getTotalScheduledCount(interviews)
                setInterviewsCount([{
                    type: "Scheduled for today",
                    value: todayScheduledNumber
                }, {
                    type: "Total",
                    value: interviews.length
                }])

                let filtered = [...scheduledArray, ...confirmedArray]
                // next interview
                getNextInt(filtered)

                // interview with pending reviews
                // getPendingReviewInterviews(completedArray)

                // interview that is rescheduled
                const actionNeededArray = [...toScheduledArray, ...scheduledArray, ...confirmedArray]
                getActionNeededInterviews(actionNeededArray)

            })
            .catch(err => console.log(err))
    }

    const handleStateChange = (value) => {
        setSelectedState(value)
    }

    return isLoading ? (
        <Spinner />
    ) : (<div className="root-div">
        <ProfileNavbar
            props={props}
            firstName={firstName}
            croppedImg={croppedImg}
            profileImage={profileImage}
            interacted={interacted}
            defaultAvatar={defaultAvatar}
            registered={registeredThrough}
        />

        <Box sx={{ flexGrow: 1 }} className='dashboard-box'>
            <div className='dashboard-body'>
                <Grid
                    container
                    className="grid-container"
                    spacing={3}
                    direction="row"
                    gap={"30px"}
                    justifyContent={"space-between"}
                >
                    <Grid
                        item
                        xs={12}
                        sm={5.7}
                        md={5.7}
                        lg={3.3}
                        xl={3.3}
                    >
                        <Card className="count-cards">

                            <div className='card-heading'>
                                <h6> Profile Activity</h6>
                            </div>
                            <div className="count-info">

                                <GaugeChart result={activityCount} chart={"profileActivity"} />
                            </div>
                        </Card>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={5.7}
                        md={5.7}
                        lg={3.3}
                        xl={3.3}
                    >
                        <Card className="count-cards">
                            <div className='card-heading'>
                                <h6>Profile Completion</h6>
                            </div>
                            <div className="count-info">
                                <GaugeChart result={profileCompletion} chart={"profileCompletion"} />
                            </div>
                        </Card>
                    </Grid>

                    <Grid
                        item
                        xs={12}
                        sm={5.7}
                        md={5.7}
                        lg={3.3}
                        xl={3.3}
                    >
                        <Card className="count-cards">

                            <div className='card-heading'>
                                <h6> Interviews</h6>
                            </div>
                            <div className="count-info">
                                <DonutChart result={interviewsCount} startAngle={2} endAngle={4} title={false} />
                            </div>
                        </Card>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={5.5}
                        xl={5.5}
                    >
                        <Card className="count-cards multiple-interviews">

                            <div className='card-heading'>
                                <h6>  {selectedState == "upcoming" ?
                                    "Upcoming Interviews"
                                    : selectedState == "actionNeeded" ?
                                        "Action Needed"
                                        : ""
                                }</h6>
                            </div>

                            <div className="count-info">
                                <div className='top-section-divider'>
                                    <div className='candidate-count-top-section'>
                                        <div className={`information-box ${selectedState == "upcoming" ? "upcoming" : ""}`} onClick={() => handleStateChange("upcoming")}>
                                            <p className='count'>{nextInterviewCount}</p>
                                            <p className='description'>Upcoming Interview</p>
                                        </div>
                                        <div className={`information-box ${selectedState == "actionNeeded" ? "actionNeeded" : ""}`} onClick={() => handleStateChange("actionNeeded")}>
                                            <p className='count'>{actionNeededCount}</p>
                                            <p className='description'>Action Needed</p>
                                        </div>
                                    </div>
                                    <Divider className="horizontal-divider" />
                                </div>

                                <div className='count-bottom-section'>
                                    {selectedState == "upcoming" ?
                                        nextInterviewCount > 0 ?
                                            <Carousel
                                                // height={100}
                                                className={`${classes.carouselContainer} interviews-carousel`}
                                                navButtonsAlwaysVisible
                                                cycleNavigation={false}
                                                indicators={false}
                                                autoPlay={false}
                                                index={firstCarousel}
                                                onChange={(now, prev) => {
                                                    setFirstCarousel(now);
                                                    setThirdCarousel(0);
                                                }}
                                                NextIcon={<NextArrow />}
                                                PrevIcon={<PreviousArrow />}
                                                duration={500}
                                                strictIndexing={true}
                                                animation='slide'
                                                navButtonsProps={{          // Change the colors and radius of the actual buttons. THIS STYLES BOTH BUTTONS
                                                    style: {
                                                        // backgroundColor: 'cornflowerblue',
                                                        opacity: 0.6,
                                                        borderRadius: 100,
                                                        height: "30px",
                                                        width: "30px",
                                                        marginLeft: "10px",
                                                        marginRight: "10px"
                                                    }
                                                }}
                                            >

                                                <div className='content-section'>
                                                    <div className="left-column">
                                                        <div className='top-line'>

                                                            <p className="title">Title :</p>
                                                            <p className='info'>{nextInterviewDetails.interviewTitle}</p>
                                                        </div>
                                                        <div className='bottom-line'>
                                                            <p className="title">Company :</p>
                                                            <p className='info'> {nextInterviewDetails.clientDetails?.name}</p>
                                                        </div>
                                                    </div>
                                                    <div className='right-column'>
                                                        <div className='calendar-icon'>
                                                            <CalendarIcon />
                                                        </div>
                                                    </div>
                                                </div>
                                            </Carousel> : <div className='no-interviews' >
                                                <p>No Upcoming Interviews</p>
                                            </div>
                                        : selectedState == "actionNeeded" ?
                                            actionNeededCount > 0 ?
                                                <Carousel
                                                    // height={100}
                                                    className={`${classes.carouselContainer} interviews-carousel`}
                                                    navButtonsAlwaysVisible
                                                    cycleNavigation={false}
                                                    indicators={false}
                                                    autoPlay={false}
                                                    index={thirdCarousel}
                                                    onChange={(now, prev) => {
                                                        setFirstCarousel(0);
                                                        setThirdCarousel(now);
                                                    }}
                                                    NextIcon={<NextArrow />}
                                                    PrevIcon={<PreviousArrow />}
                                                    duration={500}
                                                    strictIndexing={true}
                                                    animation='slide'
                                                    navButtonsProps={{          // Change the colors and radius of the actual buttons. THIS STYLES BOTH BUTTONS
                                                        style: {
                                                            // backgroundColor: 'cornflowerblue',
                                                            opacity: 0.6,
                                                            borderRadius: 100,
                                                            height: "30px",
                                                            width: "30px",
                                                            marginLeft: "10px",
                                                            marginRight: "10px"
                                                        }
                                                    }}
                                                >

                                                    {
                                                        actionNeededInterviews.map((item, i) => <div key={i} className='action-needed-interviews'>
                                                            <div className='content-section'>
                                                                <div className="left-column">
                                                                    <div className='top-line'>

                                                                        <p className="title">Title :</p>
                                                                        <p className='info'>{item.interviewTitle}</p>
                                                                    </div>
                                                                    <div className='bottom-line'>
                                                                        <p className="title">Job Seeker :</p>
                                                                        <p className='info'> {item.candidate?.name}</p>
                                                                    </div>
                                                                    <div className='right-column'>
                                                                        <div className='top-line'>

                                                                            <p className="title" style={{ marginBottom: "5px" }}>Rescheduled Time :</p>
                                                                            <p className='info'>{formatDateAndTime((item.rescheduledTime.date + " " + item.rescheduledTime.time), item?.timezone)}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                            <div className="action-container">
                                                                <button className="dashboard-btn positive-btn" onClick={() => interviewHandler("confirmedReschedule", item)}>Accept</button>
                                                                <button className="dashboard-btn " onClick={() => interviewHandler("declined", item)}>Decline</button>
                                                            </div>

                                                        </div>

                                                        )

                                                    }

                                                </Carousel> : <div className='no-interviews' >
                                                    <p>No Action Needed</p>
                                                </div>
                                            : ""}
                                </div>

                            </div>
                        </Card>
                    </Grid>
                </Grid>

                <Grid
                    container
                    className="grid-container reverse-container"
                    spacing={3}
                    direction="row"
                    gap={"30px"}
                    justifyContent={"space-between"}
                >
                    <Grid
                        item
                        xs={12}
                        sm={5.7}
                        md={5.7}
                        lg={4}
                        xl={4}
                    >
                        <Card className="count-cards calendar-card">
                            <div className='calendar-card-heading'>
                                <div className='highlighter-desc'>
                                    <span style={{ width: '11px', height: '11px', backgroundColor: '#81b4c5', display: 'inline-block', margin: '0 6px', borderRadius: '50%' }}></span>Completed
                                </div>
                                <div className='highlighter-desc'>
                                    <span style={{ width: '11px', height: '11px', backgroundColor: '#659f65', display: 'inline-block', margin: '0 6px', borderRadius: '50%' }}></span>Upcoming
                                </div>
                            </div>
                            <Calendar

                                currentDate={currentDate}
                                completedIntDates={completedIntDates} upcomingIntDates={upcomingIntDates}
                            />

                        </Card>
                    </Grid>

                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={7.5}
                        xl={7.5}
                    >
                        <Card className="count-cards stats-card">
                            <div className='card-heading'>
                                <h6> Stats</h6>
                            </div>

                            <div className="count-info stat-info">
                                <BarChart stats={allInterviewCount} />
                            </div>

                        </Card>
                    </Grid>
                </Grid>
            </div>
        </Box >
        <Footer sidebarOpen={"sidebar-close"} />
        < UserSnackBar
            openSnackbar={openSnackbar}
            setOpenSnackbar={setOpenSnackbar}
            message={message}
        />
    </div >)
}

export default CandidateDashboard