import { RECEIVE_HOME_COMPONENT } from "../actions/homepage";

export default function (state = '', action) {
    switch (action.type) {
        case RECEIVE_HOME_COMPONENT:
            if (action.homeComponent === undefined) return state;
            return action.homeComponent;
        default:
            return state;
    }
}

// making the state default to dashboard, allows the dashboard to set
// as the default component to render. This happens on refresh too, so I am not sure on how to fix this.