import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";
import TabbedNavbar from "./TabbedNavbar";
import { receiveHomeComponentAction } from "../../actions/homepage";
import Navbar from "./Navbar";
//import Navigation from '../home/Navigation';
const styles = theme => ({
    root: {
        flexGrow: 1,
        position:"fixed",
        width:"inherit"
    },
    grow: {
        flexGrow: 1
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20
    },
    list: {
        color: "rgba(255, 255, 255, 0.7)",
        width: 250
    },
    logo: {
        width: "180px",
        height: "50px",
        opacity: "1",
        justifyContent: "start"
    },
    nameButton: {
        marginLeft: "200px",
    }
    
});


const mapStateToProps = state => ({
    auth: state.auth,
    location: window.location.pathname
});

export default withStyles(styles)(
    connect(
        mapStateToProps,
        { logoutUser,
        receiveHomeComponentAction
        }
    )(Navbar)


    // connect(
    //     mapStateToProps,{logoutUser}
    // )(Navigation),
    // connect(
    //     mapStateToProps,{receiveHomeComponentAction}
    // )(TabbedNavbar)
    
     
);