import React, { useEffect, useState } from 'react'
import { AdminModuleList } from '../../company admin/Subscribers/AdminModulesList'
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@mui/material/Card";
import Axios from 'axios'
import UserSnackbar from '../../snackBars/UserSnackbar';
const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
});

function ModuleAccess({ companyDetails, setTile, setSettingsView }) {
    console.log(companyDetails)
    const classes = useStyles();
    const [rows, setRows] = useState(AdminModuleList);
    const [internalHandler, setInternalHandler] = useState(false)
    const [privType, setPrivType] = useState('dbPrivilege')


    //snackbar states
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('')


    const getInternalAccess = async () => {

        try {
            const result = await Axios.get(`/api/admin/module-access/${privType}/${companyDetails._id}`)
            const { data } = result
            setInternalHandler(data.internalDB[0].isInternalDB)
            console.log(data.internalDB[0].isInternalDB)
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        getInternalAccess()
    }, [])

    const dbHandler = (evnt, type) => {
        //edit = >true, view: FALSE  
        console.log(evnt)
        setPrivType(type)
        if (evnt.target.checked) {
            setInternalHandler(true)
        }
        else {
            setInternalHandler(false)
        }

    }


    const onSubmit = (e) => {
        e.preventDefault();
        let newData = {};
        if (internalHandler) {
            newData = {
                internalHandler: internalHandler,
                module: [{
                    index: 4,
                    checked: true,
                    editChecked: false,
                    title: "Internal DB",
                    view: false,
                    edit: true,
                }]
            }
        }
        else {
            newData = {
                internalHandler: internalHandler,
                module: []
            }
        }
        console.log(newData)
        Axios.post(`/api/admin/module-access/${privType}/${companyDetails._id}`, newData).then(res => {
            setOpen(true)
            setMessage("Updated successfully!")
            setTimeout(() => {
                setSettingsView("main")
                setTile(false)
            }, 2000)
        }).catch(err => {

            setOpen(true)
            setMessage("Oops..! Something went wrong")
            console.log(err)
        })
    }

    return (
        <div className="add-recruiter-form-container bs-none profile-card">
            <Card
                style={{ marginBottom: "20px" }}
                id="skills-edit"
                className=" profile-card"
            >
                <div style={{ margin: "20px" }}>
                    <div class="input-field">
                        <Paper>
                            <TableContainer>
                                <Table
                                    className={classes.table}
                                    aria-label="simple table"
                                >
                                    <TableHead>
                                        <TableRow>
                                            <TableCell
                                                style={{
                                                    paddingLeft: "5%",
                                                    fontFamily: "Raleway",
                                                    color: "#4c4c5d",
                                                }}
                                            >
                                                Modules
                                            </TableCell>
                                            {/* <TableCell
                                                style={{
                                                    fontFamily: "Raleway",
                                                    color: "#4c4c5d",
                                                }}
                                                align="center"
                                                colSpan={2}
                                            >
                                                Privileges
                                            </TableCell> */}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rows.map((row) => (
                                            <TableRow key={row.index}>
                                                <TableCell
                                                    align="left"
                                                    scope="row"
                                                    component="th"
                                                    size="small"
                                                    padding="normal"
                                                    style={{
                                                        width: "80%",
                                                    }}
                                                >
                                                    <input
                                                        style={{
                                                            width: "5%",
                                                            marginTop: "9px",
                                                            accentColor: "#f06161",
                                                        }}
                                                        type="checkbox"
                                                        checked={internalHandler}
                                                        onChange={(e) =>
                                                            dbHandler(e, 'dbPrivilege')
                                                        }
                                                    />
                                                    <label style={{ fontSize: "13px" }}>
                                                        {row.title}{" "}
                                                    </label>
                                                </TableCell>

                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                        <div className="button-container">
                            <button className="btn-style filled-btn filled-btn-animation" onClick={onSubmit}>Save</button>
                        </div>
                    </div></div>
            </Card>

            <UserSnackbar openSnackbar={open} setOpenSnackbar={setOpen} message={message} />
        </div>
    )
}

export default ModuleAccess