import Grid from "@material-ui/core/Grid";
import withStyles from "@material-ui/core/styles/withStyles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { logoutUser } from "../../../actions/authActions";
const styles = (theme) => ({
    main: {
        width: "auto",
        display: "block", // Fix IE 11 issue.
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        alignItems: "center",
        [theme.breakpoints.up(400 + theme.spacing(6))]: {
            width: "50%",
            marginLeft: "auto",
            marginRight: "auto",
        },
    },

    table: {
        width: "400px",
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        alignItems: "center",
    },
});

class Confirm extends Component {
    submit = (e) => {
        e.preventDefault();
        // PROCESS FORM //
        this.props.onSubmit();
    };

    componentDidMount() {
        this.props.add();
    }
    render() {
        // const { classes } = this.props;
        const {
            classes,
            values: {
                // candidateName,
                // jobTitle,
                communicationSkills,
                commentCommunication,
                technicalProficiency,
                commentTechnical,
                projectExperience,
                commentProject,
                toolsKnowledge,
                commentTools,
                teamLeadership,
                commentTeam,
                totalRating,
            },
        } = this.props;

        return (
            <div>
                <main className={classes.main}>
                    <Grid container spacing={10}>
                        <Grid item xs={12}>
                            <Typography align="center" component="h6" variant="h6">
                                Review
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography align="center" color="textSecondary" component="span">
                                Cumulative score: {totalRating} /25
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography align="center" color="textSecondary" component="span">
                                Average Rating: {totalRating / 5} /25
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Table className={classes.table}>
                                <TableBody>
                                    {/* <TableRow>
                                        <TableCell>
                                            <Typography variant="subtitle2">Candidate Name</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography component="span" color="textPrimary">
                                                <b>{candidateName}</b> <br />
                                            </Typography>
                                        </TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell>
                                            <Typography variant="subtitle2">Job Title being Assessed for</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography component="span" color="textPrimary">
                                                <b>{jobTitle}</b> <br />
                                            </Typography>
                                        </TableCell>
                                    </TableRow> */}

                                    <TableRow>
                                        <TableCell>
                                            <Typography variant="subtitle2">Communication Skills</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography component="span" color="textPrimary">
                                                <b>Score: {communicationSkills}/5</b> <br />
                                                {commentCommunication}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell>
                                            <Typography variant="subtitle2">Techincal Proficiency</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography component="span" className={classes.inline} color="textPrimary">
                                                <b>Score: {technicalProficiency}/5</b> <br />
                                                {commentTechnical}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell>
                                            <Typography variant="subtitle2">Project Experience</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography component="span" className={classes.inline} color="textPrimary">
                                                <b>Score: {projectExperience} /5</b> <br />
                                                {commentProject}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell>
                                            <Typography variant="subtitle2">Tools Knowledge</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography component="span" className={classes.inline} color="textPrimary">
                                                <b>Score:{toolsKnowledge} /5</b> <br />
                                                {commentTools}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Typography variant="subtitle2">Team Leadership</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography component="span" className={classes.inline} color="textPrimary">
                                                <b>Score:{teamLeadership} /5</b> <br />
                                                {commentTeam}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Grid>
                    </Grid>
                </main>
            </div>
        );
    }
}

Confirm.propTypes = {
    logoutUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
    auth: state.auth,
});
export default withStyles(styles)(connect(mapStateToProps, { logoutUser })(Confirm));
