import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import axios from 'axios';

// MUI imports
import { Box, Card, Tooltip, Grid } from "@material-ui/core";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { Divider } from "@mui/material";

import Highlighter from "react-highlight-words";
import moment from 'moment-timezone';

// Icons 
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { CopyToClipboard } from 'react-copy-to-clipboard';

// CSS import
import './Interviews.css'

import ProfileNavbar from '../userProfiles/ProfileNavbar';
import UserSnackBar from '../snackBars/UserSnackbar';
import Spinner from '../loaders/Spinner'
import RescheduleDialog from './RescheduleDialog';
import ScreenerFeedbackDialog from './ScreenerFeedbackDialog';

const ScreenerInterview = () => {

    let profileAvatar = "https://res.cloudinary.com/df2qsnyox/image/upload/v1681133496/Default%20Profile%20Avatar/profile-avatar_ogxozq_ranghl.png";

    const role = sessionStorage.getItem("role");
    const id = sessionStorage.getItem("id");

    const { interviewId } = useParams();

    //states for nav
    const [profileImage, setProfileImage] = useState(profileAvatar);
    const [interacted, setInteracted] = useState();
    const [croppedImg, setCroppedImg] = useState("");
    const [defaultAvatar, setDefaultAvatar] = useState();
    const [firstName, setFirstName] = useState("");
    const [registeredThrough, setRegisteredThrough] = useState("")

    //reschedule popup state
    const [rescheduleOpen, setRescheduleOpen] = useState(false)

    const [loading, setLoading] = useState(true)

    //feedbackpopup state
    const [feedbackOpen, setFeedbackOpen] = useState(false)

    // candidate Interview
    const [interviews, setInterviews] = useState([])

    // filter states
    const [text, setText] = useState("");
    const [status, setStatus] = useState("default");

    const [message, setMessage] = useState("")
    const [openSnackbar, setOpenSnackbar] = useState(false)
    const [selectedInterview, setSelectedInterview] = useState({})

    //accordion
    const [expanded, setExpanded] = useState(false)

    const handleAccordion = (intId) => (event, isExpanded) => {
        setExpanded(isExpanded ? intId : false)
    }

    const dialogHandler = (interview) => {
        setSelectedInterview(interview)
        setFeedbackOpen(true)
    }

    const searchHandler = (e) => {
        setText(e.target.value)
    }

    const statusHandler = (e) => {
        setStatus(e.target.value)
    }

    //handle copy interview link to clipboard
    const copyTextToClipboard = async (text) => {
        try {
            setMessage("Link copied to clipboard")
            setOpenSnackbar(true)
        } catch (err) {
            setMessage("Failed to copy link")
            setOpenSnackbar(true)
        }
    }

    //useEffect to fetch details in nav
    useEffect(() => {
        if (firstName) document.title = `Screener | ${firstName}'s Interviews`;
    }, [firstName]);

    const getInterviews = () => {
        axios.get(`/api/schedule/getAllInterviews/${id}`)
            .then(res => {
                const allInterviews = res.data;
                let screenerInterviews = []
                allInterviews.forEach(interview => {
                    let { toSchedule, declined, confirmed, scheduled, completed, companyId, feedback } = interview;

                    toSchedule.length > 0 && toSchedule.forEach((item) => {
                        if (item.screener.id == id && item.rescheduledBy?.id !== '') {
                            screenerInterviews.push({ ...item, companyId })
                        }
                    })

                    declined.length > 0 && declined.forEach((item) => {
                        if (item.screener.id == id) {
                            screenerInterviews.push({ ...item, companyId })
                        }
                    })

                    confirmed.length > 0 && confirmed.forEach((item) => {
                        if (item.screener.id == id) {
                            screenerInterviews.push({ ...item, companyId })
                        }
                    })

                    completed.length > 0 && completed.forEach((item) => {
                        if (item.screener.id == id) {
                            screenerInterviews.push({ ...item, companyId })
                        }
                    })

                    scheduled.length > 0 && scheduled.forEach((item) => {
                        if (item.screener.id == id) {
                            screenerInterviews.push({ ...item, companyId })
                        }
                    })
                })

                let filtered = [...screenerInterviews]
                filtered = filtered.sort((a, b) => {
                    //button for sorting
                    return new Date(a.interviewDate + " " + a.interviewTime) - new Date(b.interviewDate + " " + b.interviewTime)
                })
                filtered.length && status !== 'default' && (filtered = filtered.filter(interview => interview.screenerStatus?.toLowerCase() == status.toLowerCase()))
                filtered.length && text && (filtered = filtered.filter(interview => interview.interviewTitle?.toLowerCase().includes(text.toLowerCase()) || interview.clientDetails.name?.toLowerCase().includes(text.toLowerCase())))
                setLoading(false)
                setInterviews(filtered)
            })
    }

    useEffect(() => {
        getInterviews()
    }, [text, status]);

    useEffect(() => {
        setExpanded(interviewId)

        const { data } = axios.get(`/api/screener/${id}`).then((res) => {
            const {
                firstName,
                croppedImage,
                defaultAvatar,
                profileImage,
                interacted,
                registeredThrough
            } = res.data.user;
            setCroppedImg(croppedImage);
            setProfileImage(profileImage);
            setFirstName(firstName);
            setDefaultAvatar(defaultAvatar);
            setInteracted(interacted);
            setRegisteredThrough(registeredThrough)
        });

        const fetchInterval = setInterval(() => {
            getInterviews()
        }, 5000);
        return () => clearInterval(fetchInterval)
    }, [])



    const interviewHandler = (status, interview) => {
        const { companyId, interviewStatus, interviewUniqueId } = interview;
        setSelectedInterview(interview)

        if (status == 'rescheduled') {
            setRescheduleOpen(true)
        }

        else {
            axios.post(`/api/schedule/update-status/screener/${interviewUniqueId}`, { interview, status, companyId, interviewStatus: interviewStatus.toLowerCase() }).then(res => {
                setTimeout(() => {
                    getInterviews();
                }, 2000)
                setMessage(res.data.msg)
                setOpenSnackbar(true)

            }).catch(error => {
                console.log(error)
            })
        }
    }

    // convert date format
    function formatDateAndTime(inputDate, timezone) {
        const [date, time] = inputDate.split(" ")
        if (date && time) {
            let gmtDate = moment.tz(inputDate, "YYYY-MM-DD hh:mm a", timezone).utc();
            gmtDate = moment.tz(gmtDate, "MMMM Do YYYY[,] h:mm a", timezone);
            gmtDate = gmtDate.format("DD-MMM-YYYY[,] h:mm a, z");
            return gmtDate
        }
        else if (date) {
            let gmtDate = moment.tz(inputDate.trim(), "YYYY-MM-DD hh:mm a", timezone).utc()
            gmtDate = moment.tz(gmtDate, "MMMM Do YYYY", timezone);
            gmtDate = gmtDate.format("DD-MMM-YYYY");
            return gmtDate
        }
        else if (time) {
            let gmtDate = moment.tz(inputDate.trim(), "YYYY-MM-DD hh:mm a", timezone).utc()
            gmtDate = moment.tz(gmtDate, "MMMM Do YYYY", timezone);
            gmtDate = gmtDate.format("DD-MMM-YYYY");
            return gmtDate
        }
        else {
            return " "
        }
    }

    return (
        <div className="root-div">
            <ProfileNavbar
                croppedImg={croppedImg}
                profileImage={profileImage}
                defaultAvatar={defaultAvatar}
                interacted={interacted}
                firstName={firstName}
                registered={registeredThrough}
            />
            {/* Interviews title card */}
            <Box sx={{ flexGrow: 1, background: "#faf9f6" }}>
                <Card id="main-edit" className="add-recruiter-form form-card interviews-top-card">
                    <Grid
                        container
                        spacing={0}
                        direction="row"
                        className="add-recruiter-card-container"
                    >
                        <Grid
                            item
                            xs={12}
                            md={8}
                            lg={6}
                            className="container-heading-title"
                            style={{
                                display: "flex",
                                flexDirection: "column"
                            }}
                        >
                            <h3>Interviews</h3>
                            <Grid
                                container
                                spacing={1}
                                direction="row"
                            >
                                <Grid
                                    id="profile-completion-percentage"
                                    item
                                    xs={12}
                                    sm={8}
                                    md={8}
                                    lg={12}
                                    style={{ textAlign: "center" }}
                                    className="container-profile"
                                >
                                    <form className="experience-edit-form" noValidate>
                                        <div className="date-dropdown-picker interview-filter-form">


                                            <div class="input-field" >
                                                <input type="text" className="about"
                                                    placeholder='Search Interviews'
                                                    name="Text"
                                                    onChange={searchHandler} value={text} />
                                            </div>
                                            <div className="date-dropdown-picker">
                                                {/* Filter based on user status */}
                                                <div class="input-field">
                                                    <select
                                                        name='Status'
                                                        value={status}
                                                        onChange={statusHandler}
                                                    >
                                                        <option value="default" selected>
                                                            All Interviews
                                                        </option>
                                                        <option value="Scheduled">Scheduled</option>
                                                        <option value="Partially Confirmed">Partially Confirmed</option>
                                                        <option value="Confirmed">Confirmed</option>
                                                        <option value="Rescheduled">Re-Scheduled</option>
                                                        <option value="Action Needed">Action Needed</option>
                                                        <option value="Action Awaited">Action Awaited</option>

                                                        <option value="Declined">Declined</option>
                                                        <option value="Completed">Completed</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Card>

                {/* Accordion  */}
                <Card id="skills-edit"
                    className="add-recruiter-form-container top-margin"
                    style={{ boxShadow: "none", borderRadius: "2px", margin: "0px 70px 40px 70px " }} >

                    {loading ? <><Spinner /></> : interviews.length > 0 ? interviews.map(interview => <div><Accordion
                        expanded={expanded === interview._id} onChange={handleAccordion(interview._id)}
                        sx={{ boxShadow: "none" }} >

                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon className="interview-expand-icon" />}
                            aria-controls="panel1a-content"
                            className="interviews-accordion"
                        >

                            <p className='interview-title'>
                                <Highlighter
                                    highlightClassName="highlighter"
                                    textToHighlight={interview.interviewTitle}
                                    searchWords={[`${text}`]}
                                />
                            </p>
                            <p className='client-name'>
                                <Highlighter
                                    highlightClassName="highlighter"
                                    textToHighlight={interview.clientDetails?.name}
                                    searchWords={[`${text}`]}
                                />
                            </p>

                            <p className='current-interview-status'>
                                <Highlighter
                                    highlightClassName="highlighter"
                                    textToHighlight={interview.screenerStatus}
                                    searchWords={[`${status}`]}
                                />
                            </p>
                            <p className='interview-date'>{formatDateAndTime((interview.interviewDate + " " + interview.interviewTime), interview?.timezone)}</p>

                        </AccordionSummary>
                        <AccordionDetails sx={{ padding: "0px 10px 10px" }}
                            className="details-accordion">
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                className="interviews-details-accordion"
                            >
                                {/* Interview Details */}
                                <div className="interview-status">
                                    <div className="single-interview-status">
                                        {interview.screenerConfirm === "pending" && interview.candidateConfirm === 'pending' &&
                                            <>
                                                <div className='icons-container'>

                                                    <div className={`interview-icon green`}>
                                                        <i className="fa-solid fa-check done-icon"></i>
                                                    </div>
                                                    <div>
                                                        <Divider className="interview-divider" />
                                                    </div>
                                                    <div className="interview-icon gray">
                                                        <i className="fa-solid fa-check-double done-icon"></i>
                                                    </div>
                                                    <div>
                                                        <Divider className="interview-divider" />
                                                    </div>
                                                    <div className="interview-icon gray">
                                                        <i class="fa-sharp fa-solid fa-exclamation interview-reschedule"></i>
                                                    </div>
                                                    <div>
                                                        <Divider className="interview-divider" />
                                                    </div>
                                                    <div className="interview-icon gray">
                                                        <i class="fa-sharp fa-solid fa-xmark interview-decline"></i>
                                                    </div>
                                                    <div>
                                                        <Divider className="interview-divider " />
                                                    </div>
                                                    <div className="interview-icon gray">
                                                        <i className="fa-solid fa-check interview-complete"></i>
                                                    </div>
                                                </div>
                                            </>}

                                        {((interview.candidateConfirm === "pending" && interview.screenerConfirm == 'confirmed') || (interview.candidateConfirm === "confirmed" && interview.screenerConfirm == 'pending')) &&
                                            <>
                                                <div className='icons-container'>

                                                    <div className={`interview-icon green`}>
                                                        <i className="fa-solid fa-check done-icon"></i>
                                                    </div>
                                                    <div>
                                                        <Divider className="interview-divider" />
                                                    </div>
                                                    <div className="interview-icon green">
                                                        <i className="fa-solid fa-check done-icon"></i>
                                                    </div>
                                                    <div>
                                                        <Divider className="interview-divider" />
                                                    </div>
                                                    <div className="interview-icon gray">
                                                        <i class="fa-sharp fa-solid fa-exclamation interview-reschedule"></i>
                                                    </div>
                                                    <div>
                                                        <Divider className="interview-divider" />
                                                    </div>
                                                    <div className="interview-icon gray">
                                                        <i class="fa-sharp fa-solid fa-xmark interview-decline"></i>
                                                    </div>
                                                    <div>
                                                        <Divider className="interview-divider " />
                                                    </div>
                                                    <div className="interview-icon gray">
                                                        <i className="fa-solid fa-check interview-complete"></i>
                                                    </div>
                                                </div>
                                            </>}
                                        {interview.screenerConfirm === "confirmed" && interview.candidateConfirm === 'confirmed' &&
                                            <>
                                                <div className='icons-container'>
                                                    <div className={`interview-icon green`}>
                                                        <i className="fa-solid fa-check done-icon"></i>
                                                    </div>
                                                    <div>
                                                        <Divider className="interview-divider" />
                                                    </div>
                                                    <div className="interview-icon green">
                                                        <i className="fa-solid fa-check-double done-icon"></i>
                                                    </div>
                                                    <div>
                                                        <Divider className="interview-divider" />
                                                    </div>
                                                    <div className="interview-icon gray">
                                                        <i class="fa-sharp fa-solid fa-exclamation interview-reschedule"></i>
                                                    </div>
                                                    <div>
                                                        <Divider className="interview-divider" />
                                                    </div>
                                                    <div className="interview-icon gray">
                                                        <i class="fa-sharp fa-solid fa-xmark interview-decline"></i>
                                                    </div>
                                                    <div>
                                                        <Divider className="interview-divider " />
                                                    </div>
                                                    <div className="interview-icon gray">
                                                        <i className="fa-solid fa-check interview-complete"></i>
                                                    </div>
                                                </div>
                                            </>}
                                        {interview.interviewStatus === 'Declined' &&
                                            <>
                                                <div className='icons-container'>
                                                    <div className={`interview-icon gray`}>
                                                        <i className="fa-solid fa-check done-icon"></i>
                                                    </div>
                                                    <div>
                                                        <Divider className="interview-divider" />
                                                    </div>
                                                    <div className="interview-icon gray">
                                                        <i className="fa-solid fa-check-double done-icon"></i>
                                                    </div>
                                                    <div>
                                                        <Divider className="interview-divider" />
                                                    </div>
                                                    <div className="interview-icon gray">
                                                        <i class="fa-sharp fa-solid fa-exclamation interview-reschedule"></i>
                                                    </div>
                                                    <div>
                                                        <Divider className="interview-divider" />
                                                    </div>
                                                    <div className="interview-icon red">
                                                        <i class="fa-sharp fa-solid fa-xmark interview-decline"></i>
                                                    </div>
                                                    <div>
                                                        <Divider className="interview-divider " />
                                                    </div>
                                                    <div className="interview-icon gray">
                                                        <i className="fa-solid fa-check interview-complete"></i>
                                                    </div>
                                                </div>
                                            </>}

                                        {interview.interviewStatus === 'Completed' &&
                                            <>
                                                <div className='icons-container'>
                                                    <div className={`interview-icon green`}>
                                                        <i className="fa-solid fa-check done-icon"></i>
                                                    </div>
                                                    <div>
                                                        <Divider className="interview-divider" />
                                                    </div>
                                                    <div className="interview-icon green">
                                                        <i className="fa-solid fa-check-double done-icon"></i>
                                                    </div>
                                                    <div>
                                                        <Divider className="interview-divider" />
                                                    </div>
                                                    <div className="interview-icon gray">
                                                        <i class="fa-sharp fa-solid fa-exclamation interview-reschedule"></i>
                                                    </div>
                                                    <div>
                                                        <Divider className="interview-divider" />
                                                    </div>
                                                    <div className="interview-icon gray">
                                                        <i class="fa-sharp fa-solid fa-xmark interview-decline"></i>
                                                    </div>
                                                    <div>
                                                        <Divider className="interview-divider " />
                                                    </div>
                                                    <div className="interview-icon green">
                                                        <i className="fa-solid fa-check interview-complete"></i>
                                                    </div>
                                                </div>
                                            </>}
                                        {interview.screenerConfirm === 'rescheduled' &&
                                            <>
                                                <div className='icons-container'>
                                                    <div className={`interview-icon gray`}>
                                                        <i className="fa-solid fa-check done-icon"></i>
                                                    </div>
                                                    <div>
                                                        <Divider className="interview-divider" />
                                                    </div>
                                                    <div className="interview-icon gray">
                                                        <i className="fa-solid fa-check-double done-icon"></i>
                                                    </div>
                                                    <div>
                                                        <Divider className="interview-divider" />
                                                    </div>
                                                    <div className="interview-icon yellow">
                                                        <i class="fa-sharp fa-solid fa-exclamation interview-reschedule"></i>
                                                    </div>
                                                    <div>
                                                        <Divider className="interview-divider" />
                                                    </div>
                                                    <div className="interview-icon gray">
                                                        <i class="fa-sharp fa-solid fa-xmark interview-decline"></i>
                                                    </div>
                                                    <div>
                                                        <Divider className="interview-divider " />
                                                    </div>
                                                    <div className="interview-icon gray">
                                                        <i className="fa-solid fa-check interview-complete"></i>
                                                    </div>
                                                </div>
                                            </>}
                                        {interview.candidateConfirm === "rescheduled" &&
                                            <>

                                                <div className="reschedule-request-body">
                                                    <p className='reschedule-request-msg'>The Job Seeker has asked for a change in Interview</p>
                                                    <div className="reschedule-request-details">
                                                        <p className="reschedule-request-title"> Rescheduled Interview Details:</p>
                                                        <div className='reschedule-request-info'>
                                                            <p className='reschedule-date'>{`Date: ${interview.rescheduledTime.date}`}</p>
                                                            <p className='reschedule-time'>{`Time: ${interview.rescheduledTime.time}`}</p>
                                                        </div>
                                                    </div>
                                                    <div className="reschedule-action-buttons">
                                                        <button className='filled-sq-btn filled-btn-animation btn-style' onClick={() => interviewHandler('confirmedReschedule', interview)}>Accept</button>
                                                        <button className="outlined-sq-btn outlined-btn-animation btn-style" onClick={() => interviewHandler('declined', interview)}>Decline</button>
                                                    </div>
                                                </div>

                                            </>}

                                        {interview.candidateConfirm !== "rescheduled" && <div className="status-title">
                                            <p>Scheduled</p>
                                            <p>Confirmed</p>
                                            <p>Rescheduled</p>
                                            <p>Declined</p>
                                            <p>Completed</p>
                                        </div>}
                                    </div>
                                </div>
                                <div className="interview-details">
                                    <div className="meeting-link">
                                        <p>Copy Meeting URL </p>
                                        {interview.interviewLink && <Tooltip title="Copy meet link">
                                            <CopyToClipboard className="copy-link-icon" text={interview.interviewLink} onCopy={copyTextToClipboard}>
                                                <ContentCopyIcon
                                                    className="suit-icon"
                                                    style={{ cursor: "pointer" }}
                                                />
                                            </CopyToClipboard>
                                        </Tooltip>}
                                    </div>

                                    {interview.screenerConfirm === 'rescheduled' && <div>
                                        <p className='request-sent-msg'>
                                            {`Reschedule requested for ${interview.rescheduledTime.date} ${interview.rescheduledTime.time} `}
                                        </p>
                                    </div>}

                                    {interview.screenerConfirm == "pending" && interview.candidateConfirm !== "rescheduled" &&
                                        <div className="status-button-group">
                                            <button onClick={() => interviewHandler('confirmed', interview)} className=" interview-stage-btn green">Confirm</button>
                                            <button onClick={() => interviewHandler('declined', interview)} className=" interview-stage-btn red">Decline</button>
                                            <button className=" interview-stage-btn orange" onClick={() => interviewHandler('rescheduled', interview)}>Reschedule</button>
                                        </div>}

                                    {interview.screenerConfirm == "confirmed" && interview.candidateConfirm !== "rescheduled" &&
                                        <div className="status-button-group">
                                            <button className=" interview-stage-btn red" onClick={() => interviewHandler('declined', interview)}>Decline</button>
                                            <button className=" interview-stage-btn orange" onClick={() => interviewHandler('rescheduled', interview)}>Reschedule</button>
                                        </div>}

                                    {interview.screenerConfirm == "rescheduled" &&
                                        <div className="status-button-group">
                                            <button className=" interview-stage-btn red" onClick={() => interviewHandler('declined', interview)}>Decline</button>
                                        </div>}

                                    {(interview.interviewStatus == "Completed" && interview.feedback === true) && <div>
                                        <button className="btn-green" >Feedback Submitted</button>
                                    </div>}

                                    {(interview.interviewStatus == "Completed" && interview.feedback === false) && <div className="status-button-group">
                                        <button className=" interview-stage-btn olive-green" onClick={() => dialogHandler(interview)}>Submit Feedback</button>
                                    </div>}
                                </div>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                        <Divider
                            sx={{
                                borderColor: "lightgray",
                                borderBottomWidth: "1.5px",
                            }}
                            id="divider"
                        />
                    </div>
                    ) : <div
                        id="main-edit"
                    > <Card
                        id="skills-edit"

                        style={{
                            minWidth: "300px", height: "70%", borderRadius: "10px",
                            boxShadow: "none",
                            fontFamily: "Raleway", backgroundColor: "#faf9f6"
                        }}
                    >
                            <Grid
                                container
                                spacing={0}
                                direction="row"
                                style={{ justifyContent: "center" }}
                            >
                                <Grid
                                    item
                                    xs={5}
                                    md={10}
                                    lg={10}
                                    className="container-heading-title"
                                    style={{ margin: "15px", display: "flex", justifyContent: "center" }}
                                >
                                    <img src="https://res.cloudinary.com/df2qsnyox/image/upload/v1683794977/mailImg/nothingtoshow_urqrqr.svg" alt="Result Not found" style={{ width: "400px" }} />
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                spacing={0}
                                direction="row"
                                style={{ justifyContent: "center" }}
                            >
                                <Grid
                                    item
                                    xs={5}
                                    md={10}
                                    lg={10}
                                    className="container-heading-title "
                                    style={{ margin: "15px", display: "flex", flexDirection: "column", alignItems: "center" }}
                                >
                                    <h5>Nothing to show!</h5>
                                    {<p className="nothing-to-show" style={{ width: "max-content" }}>Oops!...Looks like there are no interviews.</p>}
                                </Grid>
                            </Grid>
                        </Card>
                    </div>
                    }
                </Card>
            </Box>

            {/* Snackbar modal */}
            < UserSnackBar
                openSnackbar={openSnackbar}
                setOpenSnackbar={setOpenSnackbar}
                message={message}
            />

            {/* Reschedule Interview Dialog */}
            <RescheduleDialog
                rescheduleOpen={rescheduleOpen}
                setRescheduleOpen={setRescheduleOpen}
                setOpenSnackbar={setOpenSnackbar}
                setMessage={setMessage}
                getInterviews={getInterviews}
                selectedInterview={selectedInterview}
                role={role}
            />

            {/* feedback dialog */}
            <ScreenerFeedbackDialog
                feedbackOpen={feedbackOpen}
                setFeedbackOpen={setFeedbackOpen}
                getInterviews={getInterviews}
                setMessage={setMessage}
                setOpenSnackbar={setOpenSnackbar}
                selectedInterview={selectedInterview}
            />

        </div >
    )
}

export default ScreenerInterview