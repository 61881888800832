export const moduleList = [
  {
    index: 0,
    checked: false,
    editChecked: false,
    title: "Dashboard",
    view: false,
    edit: false,
  },
  // {
  //   index: 1,
  //   checked: false,
  //   editChecked: false,
  //   title: "Notifications",
  //   view: false,
  //   edit: false,
  // },
  {
    index: 1,
    checked: false,
    editChecked: false,
    title: "Internal DB",
    view: false,
    edit: false,
  },
  {
    index: 2,
    checked: false,
    editChecked: false,
    title: "Favorites",
    view: false,
    edit: false,
  },
  {
    index: 3,
    checked: false,
    editChecked: false,
    title: "Interviews",
    view: false,
    edit: false,
  },
  {
    index: 4,
    checked: false,
    editChecked: false,
    title: "Search",
    view: false,
    edit: false,
  },
  {
    index: 5,
    checked: false,
    editChecked: false,
    title: "My Recruiters",
    view: false,
    edit: false,
  },
  {
    index: 6,
    checked: false,
    editChecked: false,
    title: "My Users",
    view: false,
    edit: false,
  },
  {
    index: 7,
    checked: false,
    editChecked: false,
    title: "Clients",
    view: false,
    edit: false,
  },
  {
    index: 8,
    checked: false,
    editChecked: false,
    title: "Positions",
    view: false,
    edit: false,
  },
  {
    index: 9,
    checked: false,
    editChecked: false,
    title: "Invitations",
    view: false,
    edit: false,
  },
  {
    index: 10,
    checked: false,
    editChecked: false,
    title: "Stats",
    view: false,
    edit: false,
  },
  {
    index: 11,
    checked: false,
    editChecked: false,
    title: "Reports",
    view: false,
    edit: false,
  },
  {
    index: 12,
    checked: false,
    editChecked: false,
    title: "Payments",
    view: false,
    edit: false,
  },
  {
    index: 13,
    checked: false,
    editChecked: false,
    title: "Settings",
    view: false,
    edit: false,
  },
  {
    index: 14,
    checked: true,
    editChecked: false,
    title: "Profile",
    view: true,
    edit: false,
  },
];
