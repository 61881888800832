import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import NavBar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { NavLink, useHistory } from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import axios from "axios";
import Badge from '@mui/material/Badge';
import NotificationsIcon from '@mui/icons-material/Notifications';
import logo from "../../images/brand.svg";
import ChangePasswordModal from "../modals/ChangePasswordModal";
import NotificationPopper from "./NotificationPopper";

const CompanyMenu = ({
  open,
  setOpen,
  sidebarOpen,
  setsidebarOpen,
  companyName,
  croppedImg,
  profileImage,
  interacted,
  defaultAvatar,
}) => {
  var drawerWidth = 200;


  let history = useHistory();
  const theme = useTheme();
  const id = sessionStorage.getItem("id");
  const compRefId = sessionStorage.getItem("companyId");
  const role = sessionStorage.getItem("role");
  const profileType = sessionStorage.getItem("profileType");
  let moduleArray = [];


  const IconStyle = {
    fontSize: "15px",
  };

  const [imageWidth, setImageWidth] = useState("");

  // Navbar collapse
  const [expanded, setExpanded] = useState(false);
  const [activeClass, setActiveClass] = useState("");


  const [openAlertDialog, setOpenAlertDialog] = useState(false);

  //Notifications
  const [notifications, setNotifications] = useState([])
  const [unreadMessages, setUnreadMessages] = useState(0)
  const [anchorEl, setAnchorEl] = useState(null)

  const notificationHandler = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  }

  let openNotifications = Boolean(anchorEl);

  function updateUnreadCount() {
    const unread = notifications.filter(item => !item.read)
    setUnreadMessages(unread.length)
  }

  function getNotifications() {
    axios.get(`/api/admin/get-notifications/${id}`)
      .then(res => {
        const unread = res.data.filter(item => !item.read)
        setUnreadMessages(unread.length)
        setNotifications(res.data.reverse())
      })
      .catch(err => {
        console.log(err)
      })
  }

  useEffect(() => {
    updateUnreadCount()
  }, [notifications])

  useEffect(() => {
    // const fetchInterval = setInterval(() => {
    getNotifications()
    // }, 5000)

    // return () => clearInterval(fetchInterval)
  }, [])



  // Side Menu list array
  let sideMenuList = [{
    title: "Main",
    subTitle: [
      {
        icon: <i class="fa-brands fa-windows" style={IconStyle}></i>,
        name: "Dashboard",
        url: `/recruiter/dashboard`,
      },
      // {
      //   icon: <i class="fa-solid fa-bell" style={IconStyle}></i>,
      //   name: "Notifications",
      //   url: `/recruiter/notification`,
      // },
      {
        icon: <i class="fa-solid fa-database" style={{ fontSize: "15px", marginLeft: "2px" }} ></i>,
        name: "Internal DB",
        url: `/recruiter/internal-users`,
      },
      {
        icon: <i class="fa-solid fa-star" style={IconStyle}></i>,
        name: "Favorites",
        url: `/recruiter/favorites`,
      },
      {
        icon: <i class="fa-solid fa-calendar-check" style={IconStyle}></i>,
        name: "Interviews",
        url: `/recruiter/interviews`,
      },
      {
        icon: <i class="fa-solid fa-magnifying-glass" style={IconStyle}></i>,
        name: "Search",
        url: `/recruiter/advanced-search`,
      },
    ],
  },
  {
    title: "Lists",
    subTitle: [
      {
        icon: <i class="fa-solid fa-user-group" style={IconStyle}></i>,
        name: "My Recruiters",
        url: `/recruiter/recruiters`,
      },
      {
        icon: <i class="fa-solid fa-users" style={IconStyle}></i>,
        name: "My Users",
        url: `/recruiter/users`,
      },
      {
        icon: <i class="fa-solid fa-building-user" style={IconStyle}></i>,
        name: "Clients",
        url: `/recruiter/clients`,
      },
      {
        icon: <i class="fa-solid fa-suitcase" style={IconStyle}></i>,
        name: "Positions",
        url: `/recruiter/positions`,
      },
    ],
  },
  {
    title: "Useful",
    subTitle: [
      {
        icon: <i class="fa-solid fa-envelope" style={IconStyle}></i>,
        name: "Invitations",
        url: `/recruiter/invite`,
      },
      {
        icon: <i class="fa-solid fa-chart-simple" style={IconStyle}></i>,
        name: "Stats",
        url: `/recruiter/stats`,
      },
      {
        icon: <i class="fa-solid fa-file-contract" style={IconStyle}></i>,
        name: "Reports",
        url: `/recruiter/report`,
      },
    ],
  },
  {
    title: "Service",
    subTitle: [
      {
        icon: <i class="fa-regular fa-credit-card" style={IconStyle}></i>,
        name: "Payments",
        url: `/recruiter/payment`,
      },
      {
        icon: <i class="fa-solid fa-user-check" style={IconStyle}></i>,
        name: "Subscribers",
        url: `/recruiter/subscribers`,
      },
      {
        icon: <i class='fa-solid fa-gear' style={IconStyle}></i>,
        name: "Settings",
        url: `/recruiter/settings`,
      },
    ],
  },
  {
    title: "User",
    subTitle: [
      {
        icon: <i class="fa-solid fa-circle-user" style={IconStyle}></i>,
        name: "Profile",
        url: `/recruiter/profile`,
      },
      {
        icon: <i class="fa-solid fa-lock" style={IconStyle}></i>,
        name: "Change Password",
        url: `/recruiter/change-password`,
      },
      {
        icon: (
          <i class="fa-solid fa-right-from-bracket " style={IconStyle}></i>
        ),
        name: "Logout",
        url: "/logout",
      },
    ],
  },];
  const [adminMenuList, setAdminMenuList] = useState([]);
  const [usersMenuList, setUsersMenuList] = useState([])


  let profileAvatar =
    "https://res.cloudinary.com/df2qsnyox/image/upload/v1681133496/Default%20Profile%20Avatar/profile-avatar_ogxozq_ranghl.png";


  useEffect(() => {
    var sidebarStatus = open
      ? setsidebarOpen("sidebar-open")
      : setsidebarOpen("sidebar-close");


    var imageWidth = open
      ? setImageWidth("sidebar-open-image")
      : setImageWidth("sidebar-close-image");
  });


  useEffect(() => {
    window.innerWidth <= 578 ? setOpen(false) : setOpen(true);
    let compId = "", profileType = ""
    if (role === "admin") {
      compId = sessionStorage.getItem("id")
      profileType = sessionStorage.getItem("role")
    } else if (role === "recruiter") {
      profileType = sessionStorage.getItem("profileType")
      compId = sessionStorage.getItem("compId")
    }
    if (profileType.toLowerCase() === "admin") {
      axios.get(`/api/admin/subscriber-access/${compId}`)
        .then(res => {
          let newData = {}
          if (res.data.msg) {
            newData = {
              module: [{
                index: 12,
                checked: true,
                editChecked: false,
                title: "Subscribers",
                view: false,
                edit: true
              }]
            }
          } else {
            newData = {
              module: []
            }
          }
          axios.post(`/api/admin/subscriber-module-access/${compId}`, newData)
            .then(result => console.log(result.data))
        })


      axios.get(`/api/admin/${compId}`).then((res) => {
        const {
          isInternalDB
        } = res.data.user;
        console.log(isInternalDB)
        let newData = {}
        if (isInternalDB) {
          newData = {
            module: [{
              index: 4,
              checked: true,
              editChecked: false,
              title: "Internal DB",
              view: false,
              edit: true
            }]
          }
        } else {
          newData = {
            module: []
          }
        }


        axios.post(`/api/admin/internal-db-access/${compId}`, newData)
          .then(res => {
            console.log(res)
          })
          .catch(err => {
            console.log(err)
          })


      });
    }
  }, []);






  console.log(open)
  useEffect(() => {
    if (role === "admin") {
      axios.get(`/api/admin/fetch/menu/${id}`).then(res => {
        console.log(res)
        setAdminMenuList(res.data)
      }).catch(error => {
        console.log(error)
      })
    } else {
      axios.get(`/api/admin/userRole/side-list/${compRefId}`)
        .then(res => {
          moduleArray = res.data[0]?.profileType?.filter((item) => {
            if (item.roleName?.toLowerCase() === profileType?.toLowerCase()) {
              return item.modules;
            }
          });
          console.log(moduleArray)
          moduleArray = moduleArray[0]?.modules
            .map((item) => item.checked && item.title)
            .filter((item) => item);
          for (let i = 0; i < sideMenuList.length; i++) {
            for (let j = 0; j < sideMenuList[i].subTitle.length; j++) {
              let flag = false;
              for (let k = 0; k < moduleArray?.length; k++) {
                if (moduleArray[k] === sideMenuList[i].subTitle[j].name) {
                  flag = true;
                  break;
                }
              }
              if (!flag) {
                sideMenuList[i].subTitle.splice(j, 1);
                j -= 1;
              }
            }
          }
          console.log(sideMenuList)
          let finalSideList = [...sideMenuList.filter(item => item.title !== "User"), {
            title: "User",
            subTitle: [
              {
                icon: <i class="fa-solid fa-circle-user" style={IconStyle}></i>,
                name: "Profile",
                url: `/${role}/profile`,
              },
              {
                icon: <i class="fa-solid fa-lock" style={IconStyle}></i>,
                name: "Change Password",
                url: `/${role}/change-password`,
              },
              {
                icon: (
                  <i class="fa-solid fa-right-from-bracket " style={IconStyle}></i>
                ),
                name: "Logout",
                url: "/logout",
              },
            ],
          }
          ]


          setUsersMenuList(finalSideList)
          // window.innerWidth <= 578 ? setOpen(false) : setOpen(true)
        })
    }
  }, [])


  console.log(usersMenuList)


  const handleDrawerOpen = () => {
    setOpen(!open);
  };


  const handleDrawerClose = () => {
    setOpen(false);
  };


  const logoutHandler = () => {
    sessionStorage.removeItem("jwtToken");
    sessionStorage.removeItem("id");
    sessionStorage.removeItem("role");
    sessionStorage.removeItem("companyId");
    localStorage.clear();
    history.push("/");
  };


  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  }));


  // Admin route checking
  const checkRoute = (url) => {
    switch (url) {
      case "/admin/dashboard":
        setActiveClass("dashboard");
        break;
      case "/admin/notification":
        setActiveClass(url.slice(7));
        break;


      case "/admin/recruiters":
        return history.push(url);


      case "/admin/internal-db/screeners":
        return history.push(url);


      case "/admin/internal-db/candidates":
        return history.push(url);


      case "/admin/stats":
        return history.push(url);


      case "/admin/report":
        return history.push(url);


      case "/admin/payment":
        return history.push(url);


      case "/admin/settings":
        return history.push(url);


      case "/admin/profile":
        return history.push(url);


      case "/admin/change-password":
        setOpenAlertDialog(true);
        break;


      case "/logout":
        return logoutHandler();
    }
  };


  // check routes for recruiter profile
  const checkRouteRecruiter = (url) => {
    switch (url) {
      case "/recruiter/dashboard":
        setActiveClass("dashboard");
        break;
      case "/recruiter/notification":
        setActiveClass(url.slice(7));
        break;


      case "/recruiter/recruiters":
        return history.push(url);


      case "/recruiter/stats":
        return history.push(url);


      case "/recruiter/report":
        return history.push(url);


      case "/recruiter/payment":
        return history.push(url);


      case "/recruiter/settings":
        return history.push(url);


      case "/recruiter/profile":
        return history.push(url);


      case "/recruiter/change-password":
        setOpenAlertDialog(true);
        break;


      case "/logout":
        return logoutHandler();


    }
  };


  return (
    <div>
      <NavBar
        expand="lg"
        className={`nav-header  profile-navbar  nav-bg`}
        expanded={expanded}
      >
        <Container className="profile-nav-container">
          <NavBar.Brand href="" className="profile-navbar-brand-img">
            {!open ? (
              <i
                class="fa-solid fa-bars navbar-hamburger"
                onClick={handleDrawerOpen}
                style={{ fontSize: "700", width: "1.5rem" }}
              ></i>
            ) : (
              <i
                class="fa-solid fa-xmark navbar-hamburger"
                onClick={handleDrawerOpen}
                style={{ fontSize: "700", width: "1.5rem" }}
              ></i>
            )}
            <img
              src={logo}
              alt=""
              class="brand-img profile-brand-img"
            />
          </NavBar.Brand>
          <h2 class="main-heading profile-main-heading">Screener</h2>
        </Container>
        <Nav className="profile-dropdown-navbar company">
          <Badge badgeContent={unreadMessages} color="primary" className="notifications-icon" onClick={notificationHandler}>
            <NotificationsIcon color="action" />
          </Badge>
          <p className="firstname-handler">{companyName}</p>
          <NavDropdown
            className="profile-dropdown"
            id="nav-dropdown-dark-example"
            title={
              <React.Fragment>
                <div className={`profile-acc-btn`}>
                  <img
                    style={{
                      objectFit: "cover",
                      width: "30px",
                      height: "30px",
                    }}
                    src={
                      defaultAvatar
                        ? profileAvatar
                        : interacted
                          ? croppedImg
                          : profileImage
                    }
                  />
                </div>
              </React.Fragment>
            }
            menuVariant="dark"
          >
            <NavDropdown.Item onClick={logoutHandler}>Logout</NavDropdown.Item>
          </NavDropdown>
        </Nav>
      </NavBar>
      <Drawer
        id="drawer-header"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <List id="sidebar-list">
          {role === "admin" ? adminMenuList.map((item, index) => {
            return (
              <>
                <div className="sidebar-sub-headings">
                  <h6>{item.title}</h6>
                </div>
                {item.subTitle.map((subItem, subIndex) => {
                  return (
                    <div
                      className={`sidebar-link`}
                      onClick={() => checkRoute(subItem.url)}
                    >
                      {subItem.url != "/admin/change-password" ? (
                        <NavLink to={subItem.url}>
                          <div dangerouslySetInnerHTML={{ __html: subItem.icon }} className="sidebar-link-icon">
                            {/* {subItem.icon} */}
                          </div>
                          <div className="sidebar-link-text">
                            <h6>{subItem.name}</h6>
                          </div>
                        </NavLink>
                      ) : (
                        <div className="change-password">
                          <div dangerouslySetInnerHTML={{ __html: subItem.icon }} className="sidebar-link-icon">
                          </div>
                          <div className="sidebar-link-text">
                            <h6>{subItem.name}</h6>
                          </div>
                        </div>
                      )}
                    </div>
                  );
                })}
              </>
            );
          }) : usersMenuList.map((item, index) => {
            return item.subTitle.length === 0 ? null : (
              <>
                <div className="sidebar-sub-headings">
                  <h6>{item.title}</h6>
                </div>
                {item.subTitle.map((subItem, subIndex) => {
                  return (
                    <div
                      className={`sidebar-link`}
                      onClick={() => checkRouteRecruiter(subItem.url)}
                    >
                      {subItem.url != "/recruiter/change-password" ? (
                        <NavLink to={subItem.url}>
                          <div className="sidebar-link-icon">
                            {subItem.icon}
                          </div>
                          <div className="sidebar-link-text">
                            <h6>{subItem.name}</h6>
                          </div>
                        </NavLink>
                      ) : (
                        <div className="change-password">
                          <div className="sidebar-link-icon">
                            {subItem.icon}
                          </div>
                          <div className="sidebar-link-text">
                            <h6>{subItem.name}</h6>
                          </div>
                        </div>
                      )}
                    </div>
                  );
                })}
              </>
            );
          })}
        </List>
      </Drawer>


      <ChangePasswordModal
        id={id}
        role={role}
        openAlertDialog={openAlertDialog}
        setOpenAlertDialog={setOpenAlertDialog}
      />

      <NotificationPopper
        open={openNotifications}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        notifications={notifications}
        setNotifications={setNotifications}
        className="popper-component" />
    </div >
  );
};


export default CompanyMenu;

