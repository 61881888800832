import React, { Component } from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import Select from "react-select";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";

const suggestions = [
  { label: "Java" },
  { label: "Spring Boot" },
  { label: "Python" },
  { label: "C" },
  { label: "C++" },
  { label: "HTML" },
  { label: "CSS" },
  { label: "JavaScript" },
  { label: "SQL" },
  { label: "MongoDB" },
  { label: "Ruby" },
  { label: "XML" },
  { label: "Amazon Web Sevices" },
  { label: "Microsoft Azure" },
  { label: "Hadoop" },
  { label: "Pega" },
  { label: "React JS" },
  { label: "React Native" },
  { label: "Angular" },
  { label: "Android" },
  { label: "IOS" },
  { label: "Node Js" }
].map(suggestion => ({
  value: suggestion.label,
  label: suggestion.label
}));

const styles = theme => ({
  select: {
    width: "80%"
  }
});

class screenSkills extends Component {
  render() {
    const { values, handleChange } = this.props;
    return (
      <div>
        <Select
          id="skills"
          name="skills"
          value={values.skills}
          TextFieldProps={{
            label: "Skills",
            InputLabelProps: {
              htmlFor: "react-select-multiple",
              shrink: true
            }
          }}
          placeholder="Select skills "
          options={suggestions}
          onChange={handleChange}
          isMulti
        />
      </div>
    );
  }
}

screenSkills.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  auth: state.auth
});
export default withStyles(styles)(
  connect(
    mapStateToProps,
    { logoutUser }
  )(screenSkills)
);
