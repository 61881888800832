import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Column } from '@ant-design/plots';


const BarChart = ({ internalDB }) => {
    console.log(internalDB)
    const data = internalDB
    const config = {
        data,
        xField: 'type',
        yField: 'value',
        color: ['#ef798a', '#3091A2', "#505253"],
        legend: false,
        yAxis: {
            title: { text: 'counter' },
            min: 0,             // Set the minimum value of the Y-axis to 0
            // max: 20,           // Set the maximum value of the Y-axis (adjust according to your data)
            tickInterval: 5,    // Set the tick interval to 5
        },
        label: {

            position: 'middle',

            style: {
                fill: '#FFFFFF',
                opacity: 0.6,
            },
        },
        xAxis: {
            label: {
                autoHide: true,
                autoRotate: false,
            },
            title: { text: 'Internal Users' }
        },
        yAxis: { title: { text: 'No. of Users' } }, // Y-axis label
        // tickCount: 8,

        seriesField: "type"
    };
    return <Column {...config} />;
};

export default BarChart