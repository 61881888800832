import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import React from 'react';
import EnhancedTableHead from './enhancedTableHead';
import InterviewRows from './interviewRows';

const columns = [
  {
    id: 'interviewId',
    label: 'Interview Id',
    minWidth: 0,
    align: 'left',
  },
  {
    id: 'interviewExternalName',
    label: 'Interview Name',
    minWidth: 100,
    align: 'left',
  },
  {
    id: 'candidateName',
    label: 'Candidate Name',
    minWidth: 170,
    numeric: false,
    disablePadding: false,
    align: 'left',
  },
  {
    id: 'dateTime',
    label: 'Interview Date/Time',
    minWidth: 340,
    align: 'center',
    numeric: false,
    disablePadding: true,
  },
  {
    id: 'status',
    label: 'Status',
    minWidth: 170,
    align: 'left',
    numeric: false,
    disablePadding: false,
  },
  {
    id: 'rating',
    label: 'Recruiter Rating',
    minWidth: 170,
    align: 'center',
    numeric: true,
    disablePadding: false,
  },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const useStyles = makeStyles({
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
});

export default function InterviewTables(props) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('asc');

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // need to customize these methods, for intake of interview objects.
  function createData(
    interviewId,
    interviewExternalName,
    externalId,
    candidateName,
    candidateEmail,
    company,
    position,
    dateTime,
    endTime,
    status,
    rating,
    applicationId
  ) {
    let stringNormalizedDate = dateTime.split('G')[0];
    let stringNormalizedEndDate = endTime.split('G')[0];
    return {
      interviewId,
      interviewExternalName,
      externalId,
      candidateName,
      candidateEmail,
      company,
      position,
      dateTime: stringNormalizedDate.slice(3),
      endTime: stringNormalizedEndDate.slice(3),
      status,
      rating,
      applicationId,
    };
  }

  const rows = [];
  if (props.interviews[0] === 'No alerts for this user') {
    rows.push(
      // createData(
      //   '',
      //   'INT-15232',
      //   '',
      //   'Example Example',
      //   'Example@fakememail.com',
      //   'Daily Show',
      //   'Correspondent',
      //   new Date().toString(),
      //   new Date().toString(),
      //   'Completed',
      //   0,
      //   ''
      // )

      'Sorry there do not seem to be any open positions at the moment, Please check back later.'

    );
  } else {
    props.interviews.forEach(
      ({
        Id,
        Name,
        Status__c,
        Application__c,
        Position_Name__c,
        Candidate_Email__c,
        Candidate_Full_Name__c,
        Screener_ID_External__c,
        Start_Time__c,
        End_Time__c,
        Screener_Rating__c,
        Client__c,
      }) => {
        let correctStatus = Status__c === 'Completed' || Status__c === 'Completed-Reviewed';
        let performanceRating = Screener_Rating__c && correctStatus ? Screener_Rating__c : 0;
        rows.push(
          createData(
            Id,
            Name,
            Screener_ID_External__c,
            Candidate_Full_Name__c,
            Candidate_Email__c,
            Client__c,
            Position_Name__c,
            new Date(Start_Time__c).toString(),
            new Date(End_Time__c).toString(),
            Status__c,
            performanceRating,
            Application__c
          )
        );
      }
    );
  }

  return (
    <>




      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="customized table">
          <EnhancedTableHead
            classes={classes}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            headCells={columns.slice(1)}
          />
          <TableBody>
            {stableSort(rows, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, idx) => {
                return (
                  <InterviewRows
                    key={`row-${idx}`}
                    row={row}
                    columns={columns}
                    statusConfirm={props.statusConfirm}
                    statusReschedule={props.statusReschedule}
                    statusComplete={props.statusComplete}
                  />
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </>
  );
}
