import { Grid, Paper, Typography } from "@material-ui/core";
import React from "react";
import StandardCalendar from "../../calendar/standardCalendar";
import DashCalendarCard from "../../cards/dashCalendarCard";
import SimpleCards from "../../cards/SimpleCards";
import InterviewAlerts from "../../interview/alerts/interviewAlerts";
import './candidateRows.css';
import { makeStyles } from '@material-ui/core/styles';
import Divider from "@material-ui/core/Divider";




const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
         height:'400px',
        // maxHeight: 580,
         overflow: 'auto'
    },
    paper1:{
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        height:'195px',
        overflow:'auto',
        padding:'15px',
        marginTop:'2px',
        marginBottom:'5px',
        // border: '2px solid #75a478 '

    }
}));

const divStyle = {padding:'20px'}

export default function CandidateRowOne(props) {
    const classes = useStyles();
    return (
        <div>
            <div className="d-flex flex-row justify-content-between">
                {/* <div className="flex-fill" id="column-one"> */}
                {/* <DashCalendarCard cardStyle={props.cardStyle} title="Schedule">
                    <StandardCalendar
                        hourStep={60}
                        defaultView="agenda"
                        selectable={false}
                        popup={true}
                        views={["month", "agenda"]}
                        // views={['month', 'day', 'agenda']}
                    />
                </DashCalendarCard>
                 */}
                {/* <Grid container spacing={3}>
                        <Grid item xs={6} sm={6} cardStyle={props.cardStyle}>
                            <Paper elevation={5} style={paperStyle}>
                            <Typography className="typographySchedule">Schedule</Typography>
                     <StandardCalendar
                        hourStep={60}
                        defaultView="agenda"
                        selectable={false}
                        popup={true}
                        views={["month", "agenda"]}
                        // views={['month', 'day', 'agenda']}
                    />

                            </Paper>
                        </Grid>
                        <Grid item xs={12} sm={6}  cardStyle={props.cardStyle} >
                            <Paper elevation={5} style={paperStyle1}>
                            <Typography className="typographyPending">Pending Applications</Typography>
                                  <InterviewAlerts />
                            </Paper>
                        </Grid>
                        


                </Grid>
                 */}



                {/* <Grid cardStyle={props.cardStyle} title="Schedule">
                    
                     <Paper elevation={5} style={paperStyle}>
                     <Typography className="typographySchedule">Schedule</Typography>
                     <StandardCalendar
                        hourStep={60}
                        defaultView="agenda"
                        selectable={false}
                        popup={true}
                        views={["month", "agenda"]}
                        // views={['month', 'day', 'agenda']}
                    />

                     </Paper>
                 </Grid> */}
                {/* </div> */}

                {/* <SimpleCards cardStyle={props.cardStyle} title="Pending Applications">
                    <InterviewAlerts />
                </SimpleCards> */}
                {/* <div className="flex-fill maxw-500 ml-3" id="column-two">
                 <Grid cardStyle={props.cardStyle} >
                    <Paper elevation={5} style={paperStyle1} >
                 <Typography className="typographyPending">Pending Applications</Typography>
                 <InterviewAlerts />
                 </Paper>
                 </Grid>

            </div> */}

                <div className={classes.root}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}sm={7}>
                            <Paper className={classes.paper} elevation={5}> 
                                <Typography className="typographyStyle">Schedule</Typography>
                                <StandardCalendar
                                    hourStep={60}
                                    defaultView="agenda"
                                    selectable={false}
                                    popup={true}
                                    views={["month", "agenda"]}
                                // views={['month', 'day', 'agenda']}                
                                />

                            </Paper>
                        </Grid>
                        <Grid item xs={12} sm={5}>
                            <Paper className={classes.paper1} elevation={5}>
                                <Typography className="typographyStyle">Pending Applications</Typography>
                                <InterviewAlerts />
                            </Paper>

                            <Paper className={classes.paper1} elevation={5}>
                                <Typography className="typographyStyle"> Candidate Social Feed</Typography>
                                <div style={divStyle} >
                                <h3 style={{ fontSize: "15px", marginLeft: "15px", textAlign: "center" }}>Keep updated with new information on the latest oppertunities and advancements and see what others have to say about their career and companies</h3>

                                <Divider variant="middle" />

                                <h5 style={{ fontSize: "20px", marginLeft: "15px" ,padding:'10px' }}>Many things</h5>
                                </div>
                            </Paper>
                        </Grid>
                        {/* <Grid item xs>
                            <Paper className={classes.paper}>
                                <Typography className="typographyStyle"> Candidate Social Feed</Typography>
                                <div style={divStyle} >
                                <h3 style={{ fontSize: "15px", marginLeft: "15px", textAlign: "center" }}>Keep updated with new information on the latest oppertunities and advancements and see what others have to say about their career and companies</h3>

                                <Divider variant="middle" />

                                <h5 style={{ fontSize: "20px", marginLeft: "15px" ,padding:'10px' }}>Many things</h5>
                                </div>
                            </Paper>
                        </Grid> */}
                    </Grid>
                    
                </div>

            </div>
        </div>
    );
}
