import React from 'react'

import Board, { moveCard, changeCard } from "@asseinfo/react-kanban";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

//svg icons import
import BriefCase from "../images/briefcase.svg"
import Zoom from "../images/zoom.svg";
import SuitIcon from "../images/suit-and-tie.svg";
import acceptedGreen from "../images/green-tick.svg";
import completedBlue from "../images/blue-tick.svg";
import rescheduleYellow from "../images/yellow-tick.svg";
import grayTick from "../images/gray-tick.svg";
import declinedRed from "../images/red-cross.svg";
import orangeTick from "../images/orange-tick.svg";
import Calendar from "../images/calendar.svg";

import { Card, Tooltip, } from "@material-ui/core";

const ControlledBoard = ({
    interviewsFilter,
    setOpenSnackbar,
    board,
    setBoard,
    updateStatusOnDrag,
    setMessage,
    moveCard,
    handleOpenEditInterview,
    copyTextToClipboard
}) => {

    function handleCardMove(_card, source, destination) {
        const { screenerName, candidateName, interviewDate, interviewTime, timeZone, interviewLink,
            interviewTitle, } = _card

        if (interviewsFilter === "All Interviews") {
            setOpenSnackbar(true);
            setMessage("You do not have the permission to edit here.")
        }
        else if (source.fromColumnId == 4) {
            setOpenSnackbar(true);
            setMessage("You cannot edit Completed Interview")
        }
        //check whether card is being dragged to 'Scheduled' column
        else if (destination.toColumnId == 2) {
            if (interviewTitle && screenerName && candidateName && interviewDate && interviewTime && timeZone && interviewLink) {
                const updatedBoard = moveCard(board, source, destination);
                updatedBoard.columns[1].cards.map(item => {
                    if (item.id == _card.id) {
                        item.interviewStatus = "Scheduled"
                        item.rescheduledBy = { id: '', name: '', email: '' }
                        item.declinedBy = { id: '', name: '', email: '' }
                        item.screenerConfirm = "pending"
                        item.candidateConfirm = "pending"
                        item.candidateStatus = "Scheduled"
                        item.screenerStatus = "Scheduled"
                    }

                    return item
                })
                setBoard(updatedBoard);
                updateStatusOnDrag(_card, source, destination, updatedBoard)
            }
            else {
                setMessage("Please fill all the interview details")
                setOpenSnackbar(true)
            }
        }
        //check whether card is being dragged to 'Confirmed' column
        else if (destination.toColumnId == 3) {
            if (interviewTitle && screenerName && candidateName && interviewDate && interviewTime && timeZone && interviewLink) {
                const updatedBoard = moveCard(board, source, destination);
                updatedBoard.columns[2].cards.map(item => {
                    if (item.id == _card.id) {
                        item.interviewStatus = "Confirmed"
                        item.rescheduledBy = { id: '', name: '', email: '' }
                        item.declinedBy = { id: '', name: '', email: '' }
                        item.screenerConfirm = 'confirmed'
                        item.candidateConfirm = 'confirmed'
                        item.candidateStatus = "Confirmed"
                        item.screenerStatus = "Confirmed"
                    }

                    return item
                })
                setBoard(updatedBoard);
                updateStatusOnDrag(_card, source, destination, updatedBoard)
            }
            else {
                setMessage("Please fill all the interview details")
                setOpenSnackbar(true)
            }
        }
        //check whether card is being dragged to 'Completed' column
        else if (destination.toColumnId == 4) {
            if (interviewTitle && screenerName && candidateName && interviewDate && interviewTime && timeZone && interviewLink) {
                const updatedBoard = moveCard(board, source, destination);
                updatedBoard.columns[3].cards.map(item => {
                    if (item.id == _card.id) {
                        item.interviewStatus = "Completed"
                        item.rescheduledBy = {
                            id: '', name: '', email: ''
                        }
                        item.declinedBy = { id: '', name: '', email: '' }
                        item.screenerConfirm = "completed"
                        item.candidateConfirm = "completed"
                        item.candidateStatus = "Completed"
                        item.screenerStatus = "Completed"
                    }
                    return item
                })

                setBoard(updatedBoard);
                updateStatusOnDrag(_card, source, destination, updatedBoard)
            }
            else {
                setMessage("Please fill all the interview details")
                setOpenSnackbar(true)
            }
        }
        //check whether card is being dragged to 'Declined' column
        else if (destination.toColumnId == 5) {
            if (interviewTitle && screenerName && candidateName && interviewDate && interviewTime && timeZone && interviewLink) {
                const updatedBoard = moveCard(board, source, destination);
                updatedBoard.columns[4].cards.map(item => {
                    if (item.id == _card.id) {
                        item.interviewStatus = "Declined"
                        item.rescheduledBy = { id: '', name: '', email: '' }
                        item.declinedBy = item.scheduledBy
                        item.screenerConfirm = "declined"
                        item.candidateConfirm = "declined"
                        item.candidateStatus = "Declined"
                        item.screenerStatus = "Declined"
                    }

                    return item
                })
                setBoard(updatedBoard);
                updateStatusOnDrag(_card, source, destination, updatedBoard)
            }
            else {
                setMessage("Please fill all the interview details")
                setOpenSnackbar(true)
            }
        }
        // if card is being moved to 'To Schedule' column
        else {
            const updatedBoard = moveCard(board, source, destination);
            updatedBoard.columns[0].cards.map(item => {

                if (item.id == _card.id) {
                    item.interviewStatus = "To Schedule"
                    item.declinedBy = {
                        id: '', name: '', email: ''
                    }
                }
                if (source.fromColumnId > 1 && destination.toColumnId == 1) {
                    item.rescheduledBy = item.scheduledBy
                    item.screenerConfirm = 'pending'
                    item.candidateConfirm = 'pending'
                    item.candidateStatus = "Rescheduled"
                    item.screenerStatus = "Rescheduled"
                }
                return item
            })
            setBoard(updatedBoard);
            updateStatusOnDrag(_card, source, destination, updatedBoard)
        }
    }


    return (
        <Board
            onCardDragEnd={handleCardMove}
            disableColumnDrag
            renderCard={(
                {
                    id,
                    interviewTitle,
                    screenerName,
                    candidateName,
                    screenerDetails,
                    candidateDetails,
                    interviewDate,
                    backupDate,
                    interviewTime,
                    timeZone,
                    interviewLink,
                    meetingPlatform,
                    interviewId,
                    interviewStatus,
                    scheduledBy,
                    interviewUniqueId,
                    clientDetails,
                    positionDetails,
                    declinedBy,
                    rescheduledBy,
                    screenerConfirm,
                    candidateConfirm,
                    screenerStatus,
                    candidateStatus,
                    feedback,
                    screenerFeedback
                },
                { dragging }
            ) => {
                return (
                    <Card
                        dragging={dragging}
                        style={{ display: `${screenerFeedback ? "none" : ""}` }}
                        className={`kanban-card-custom ${screenerFeedback ? "screener-feedback-card" : ""}`}>
                        <div onClick={() => {
                            handleOpenEditInterview(
                                id,
                                interviewTitle,
                                screenerName,
                                candidateName,
                                screenerDetails,
                                candidateDetails,
                                interviewDate,
                                backupDate,
                                interviewTime,
                                timeZone,
                                interviewLink,
                                meetingPlatform,
                                interviewStatus,
                                scheduledBy,
                                interviewUniqueId,
                                clientDetails,
                                positionDetails,
                                rescheduledBy,
                                declinedBy,
                                screenerConfirm,
                                candidateConfirm,
                                screenerStatus,
                                candidateStatus,
                                feedback,
                                screenerFeedback
                            )
                        }
                        }
                            style={{ cursor: 'pointer' }
                            }>
                            <div className="kanban-card-row-1">
                                <div className="img-name">
                                    <img src={BriefCase} className="suit-icon" />
                                    <p style={{ width: 135, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{interviewTitle}</p>
                                </div>
                            </div>
                            <div className="kanban-card-row-1">
                                <div className="img-name">
                                    <img src={SuitIcon} className="suit-icon" />
                                    <p style={{ width: 135, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{screenerName}</p>
                                </div>
                                <div className="status-buttons">
                                    <img src={screenerConfirm === "confirmed" ? acceptedGreen : screenerConfirm === "completed" ? completedBlue : screenerConfirm === "declined" ? declinedRed : (screenerConfirm == "pending" && interviewStatus !== "To Schedule") ? orangeTick : screenerConfirm == "rescheduled" ? rescheduleYellow : grayTick} className="status-icon" />
                                </div>
                            </div>
                            <div className="kanban-card-row-2">
                                <div className="img-name">
                                    <img src={SuitIcon} className="suit-icon" />
                                    <p style={{ width: 135, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{candidateName}</p>
                                </div>
                                <div className="status-buttons">
                                    <img src={candidateConfirm === "confirmed" ? acceptedGreen : candidateConfirm === "completed" ? completedBlue : candidateConfirm === "declined" ? declinedRed : (candidateConfirm == "pending" && interviewStatus !== "To Schedule") ? orangeTick : candidateConfirm == "rescheduled" ? rescheduleYellow : grayTick} className="status-icon" />
                                </div>
                            </div>
                            <div className="kanban-card-row-3">
                                <div style={{ display: "flex" }}>
                                    <img src={Calendar} className="suit-icon" />
                                    <p style={{ marginRight: 4, marginBottom: 0 }}>{interviewDate}</p>
                                </div>
                            </div>
                        </div>
                        <div className="kanban-card-row-4">
                            <div>
                                {interviewLink && <>
                                    <img src={Zoom} className="suit-icon" />
                                    <Tooltip title="Copy meet link">
                                        <CopyToClipboard text={interviewLink} onCopy={copyTextToClipboard}>
                                            <ContentCopyIcon
                                                className="suit-icon"
                                                style={{ width: 15, height: 15, cursor: "pointer" }}
                                            />
                                        </CopyToClipboard>
                                    </Tooltip>
                                </>}
                            </div>
                            <p>{interviewId}</p>
                        </div>
                    </Card >)
            }
            }>
            {board}
        </Board >
    );
};

export default ControlledBoard