import withStyles from "@material-ui/core/styles/withStyles";
import {Grid} from '@material-ui/core'
import { withSnackbar } from "notistack";
import PropTypes from "prop-types";
import React, { Component } from "react";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { connect } from "react-redux";
import { calendarCardStyle, cardStyles } from "../calendar/calendarStyle";
import DashRowOne from "./dashboardRows/dashboardRowOne";
import DashRowTwo from "./dashboardRows/dashboardRowTwo";
import DashboardSidebar from "./dashboardRows/dashboardSidebar";
import ProfileCompletion from "./sidebarComponents/profileCompletion";

const styles = (theme) => ({
    ratingWrapper: {
        padding: "16px",
    },
    iconButton: {
        padding: "17px",
    },
});

// const action = (key) => (
//     <>
//         <Button
//             onClick={() => {
//                 closeSnackbar(key);
//             }}
//         >
//             'Dismiss'
//         </Button>
//         <Button onClick={() => {}}>Calendar</Button>
//     </>
// );

class dashboardScreener extends Component {
    onClickDismiss = (key) => () => {
        this.props.closeSnackbar(key);
    };

    componentDidUpdate(prevProps) {
        const { sidebar, availabilities } = this.props;
        if (sidebar.profileCompletionPercentage < 100) {
            this.props.enqueueSnackbar("Please update your profile, it has not been completed", {
                variant: "warning",
                autoHideDuration: 3000,
            });
        }

        if (availabilities.length <= 0) {
            this.props.enqueueSnackbar("Navigate to the Calendar to send your availability schedule to recruiters", {
                variant: "info",
                autoHideDuration: 3000,
            });
        }
    }

    render() {
        const { sidebar } = this.props;

        return (
           
            <div className="dashboard-component d-flex flex-row justify-content-around" id="dashboard-full-container" style={{padding:"130px"}}>
                <div className="flex-fill ml-4" id="dash-info-column">
                    <div className="d-flex flex-column justify-content-around" id="dash-info-container">
                        <div className="flex-fill" id="profileCompletion">
                        
                            {/* <ProfileCompletion completionPercentage={sidebar.profileCompletionPercentage} user={this.props.auth.user} sidebar={sidebar}  /> */}
                            <DashboardSidebar user={this.props.auth.user} sidebar={sidebar} />
                        </div>
                        <div className="flex-fill mt-4" id="dash-row-1">
                            <DashRowOne cardStyle={calendarCardStyle} />
                        </div>
                        <div className="flex-fill mt-4" id="dash-row-2">
                            <DashRowTwo cardStyle={cardStyles} />
                        </div>
                    </div>
                </div>
{/* 
                // <div className="flex-fill ml-5" id="dash-sidebar">
                //     <DashboardSidebar user={this.props.auth.user} sidebar={sidebar} />
                // </div> */}

            </div>
        );
    }
}

dashboardScreener.propTypes = {
    auth: PropTypes.object.isRequired,

};

const mapStateToProps = (state) => ({
    auth: state.auth,
    sidebar: state.sidebar,
    availabilities: state.availabilities,
});

export default withSnackbar(withStyles(styles)(connect(mapStateToProps)(dashboardScreener)));
