import React, { Fragment, useState, useEffect, Component } from "react";
import Button from "@material-ui/core/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  updateRecruiter,
  getRecruiterDetails,
  UPDATE_PROFILE_RESET,
} from "../../actions/userActions";
import axios from "axios";
import { withSnackbar, useSnackbar } from "notistack";
import { Link } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles, Divider } from "@material-ui/core";

//material ui

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";

const UpdateRecruiter = ({ history, match, classes }) => {
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.getRecruiterDetail);
  const { isUpdated } = useSelector((state) => state.recruiterupdate);

  console.log(user);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);

  const userId = match.params.id;

  const isLetter = (e) => {
    const re = /[a-zA-z\s]+/g;
    if (!re.test(e.key)) {
      e.preventDefault();
    }
  };

  // console.log(userId);
  // console.log(user.data.name);

  useEffect(() => {
    if (user && user._id !== userId) {
      dispatch(getRecruiterDetails(userId));
    } else {
      setName(user.name);
      setEmail(user.email);
      setRole(user.role);
    }
    // console.log(user._id)

    if (isUpdated) {
      history.push("/admin/recruiter-list");

      dispatch({
        type: UPDATE_PROFILE_RESET,
      });
    }
  }, [dispatch, history, isUpdated, user, userId]);

  const updateAPIData = () => {
    axios
      .put(`/api/users/user/recruiterupdate/${userId}`, {
        name,
        email,
        role,
      })
      .then((res) => {
        //alert('Updated successfully');
        let regMessage = <span>Updated successfully</span>;
        enqueueSnackbar(regMessage, {
          variant: "success",
          // autoHideDuration: 3000,
        });
        history.push("/admin/recruiter-list");
      })
      .catch((err) => {
        console.log(err.response);
        alert("An error occured try submitting the form again");
      });
  };

  //handleClickOpen
  const handleClickOpen = () => {
    setOpen(true);
  };
  //handleClose
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <div className="d-flex flex-column align-items-center p-3 m-2">
        <Grid style={{ height: "100vh" }}>
          <Paper style={{ padding: "32px" }}>
            <br />
            <Typography variant="h6" style={{ textAlign: "center" }}>
              Update Recruiter
              <Divider style={{ width: "20%", margin: "auto" }} />
              <br />
            </Typography>

            {/* <Link to="/admin/recruiter-list">Back</Link> */}
            <Dialog
              open={open}
              onClose={handleClose}
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                Warning: All your current changes will be lost when you leave.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleClose}
                  style={{ backgroundColor: "#D73B30", color: "white" }}
                >
                  Cancel
                </Button>

                <Link
                  style={{
                    color: "white",
                    textDecoration: "none",
                    padding: "10px",
                  }}
                  to="/admin/recruiter-list"
                >
                  <Button
                    style={{ backgroundColor: "#75A478", color: "white" }}
                  >
                    Confirm
                  </Button>
                </Link>
              </DialogActions>
            </Dialog>
            <form className="registerform">
              <div className="form-group">
                <label className="font-weight-bold" htmlFor="name">
                  Full Name
                </label>
                <input
                  type="text"
                  placeholder="Name"
                  required
                  name="name"
                  value={name}
                  onKeyPress={(e) => isLetter(e)}
                  onChange={(e) => setName(e.target.value)}
                  className="form-control"
                />
                <span className="text-danger"></span>
              </div>

              <div className="form-group">
                <label className="font-weight-bold" htmlFor="email">
                  Email
                </label>
                <input
                  type="email"
                  placeholder="Email"
                  required
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="form-control"
                />
                <span className="text-danger"></span>
              </div>

              {/* <div className="form-group">
                <MuiPhoneNumber
                    id="phone"
                    type="phone"
                    name="phone"
                    value={phone}
                    defaultCountry={"us"}
                    onChange={onPhoneChange}
                    error={isErrors.phone}
                />
                <span className="text-danger">{errors.phone}</span>
            </div> */}

              <div className="form-group">
                <label className="font-weight-bold" htmlFor="email">
                  Role
                </label>
                <input
                  type="role"
                  placeholder="Role"
                  required
                  name="role"
                  value={role}
                  onChange={(e) => setRole(e.target.value)}
                  className="form-control"
                  disabled
                />
              </div>
              <br />
              {/* <Button type="submit" fullWidth variant="contained" color="secondary">
                    Update Recruiter
                </Button> */}
              {/* <Button onClick={updateAPIData}>Update</Button> */}
              <Grid item xs={12} sm={12}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    flexWrap: "wrap",
                    float: "left",
                  }}
                >
                  {" "}
                  <Button
                    onClick={handleClickOpen}
                    variant="contained"
                    color="secondary"
                    style={{
                      marginRight: "12px",
                      backgroundColor: "#D73B30",
                      color: "white",
                    }}
                  >
                    Back
                  </Button>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    flexWrap: "wrap",
                  }}
                >
                  <Button
                    style={{ color: "white" }}
                    type="button"
                    variant="contained"
                    color="secondary"
                    align="center"
                    onClick={updateAPIData}
                  >
                    Update Recruiter
                  </Button>
                </div>
              </Grid>
            </form>
          </Paper>
        </Grid>
      </div>
    </>
  );

  // return(
  //     <div>
  //         <br/><br/><br/><br/><br/><br/><br/>
  //         <h1>
  //             Update Recruiter
  //         </h1>

  //     </div>
  // )
};

export default UpdateRecruiter;

// class UpdateRecruiter extends Component {

//     constructor(props) {
//         super(props);
//         this.state = {
//             _id: this.props.match.params.id,
//             name: "",
//             email: "",
//             role: "",
//             loading: true,
//         }
//         console.log(this.props.match.params.id)
//         this.handleNameChange = this.handleNameChange.bind(this);
//         this.handleEmailChange= this.handleEmailChange.bind(this);
//         this.handleRoleChange=this.handleRoleChange.bind(this);
//         this.submitForm = this.submitForm.bind(this);
//     }

//     componentDidMount() {
//         axios.get("/api/users/user/"+this.state._id)
//             .then(
//                 (res) => {
//                     var newState = {
//                         name: res.data.name,
//                         email: res.data.email,
//                         role: res.data.role,
//                     };
//                     this.setState(newState);
//                     this.setState({ loading: false });
//                 },
//                 (err) => {
//                     alert('An error occured! Try refreshing the page.', err);
//                 }
//             );
//     }

//     handleNameChange(event) {
//         this.setState({ name: event.target.value });
//     }
//     handleEmailChange(event) {
//         this.setState({ email: event.target.value });
//     }
//     handleRoleChange(event) {
//         this.setState({ role: event.target.value });
//     }

//     submitForm(e) {
//         e.preventDefault();
//         const { _id, name, email, role } = this.state;
//         console.log(name)
//         var user = {
//             _id, name, email, role
//         };
//         axios.put('/api/users/user/recruiterupdate', user)

//             .then((res) => {

//                 alert('Updated successfully');
//                 // let regMessage = <span>you have succesfully updated {res.data.name}, You will now be redirected</span>;
//                 // this.props.enqueueSnackbar(regMessage, {
//                 //     variant: "default",
//                 //     autoHideDuration: 1000,
//                 // });
//                 //this.props.history.push("/home")

//             })
//             .catch(err => {
//                 console.log(err.response);
//                 alert('An error occured try submitting the form again');
//             });
//     }

//     render() {

//         console.log(this.state.email)
//         return (
//             <>
//                 <br /><br /><br /><br /><br /><br />
//                 <div className="d-flex flex-column align-items-center p-3 m-2">
//                     <h3>Update Recruiter</h3>
//                     <form className="registerform" encType="multipart/form-data"
//                         onSubmit={this.submitForm}>
//                         <div className="form-group">
//                             <label className="font-weight-bold" htmlFor="name">
//                                 Full Name
//                             </label>
//                             <input
//                                 type="text"
//                                 placeholder="Name"
//                                 required
//                                 name="name"
//                                 value={this.state.name}
//                                 onChange={this.handleNameChange}
//                                 className='form-control'
//                             />
//                             <span className="text-danger"></span>
//                         </div>
//                         <div className="form-group">
//                             <label className="font-weight-bold" htmlFor="email">
//                                 Email
//                             </label>
//                             <input
//                                 type="email"
//                                 placeholder="Email"
//                                 required
//                                 name="email"
//                                 value={this.state.email}
//                                 onChange={this.handleEmailChange}
//                                 className='form-control'
//                             />
//                             <span className="text-danger"></span>
//                         </div>

//                         <div className="form-group">

//                             <label className="font-weight-bold" htmlFor="email">
//                                 Role
//                             </label>
//                             <input
//                                 type="role"
//                                 placeholder="Role"
//                                 required
//                                 name="role"
//                                 value={this.state.role}
//                                 onChange={this.handleRoleChange}
//                                 className='form-control'
//                             />

//                         </div>

//                         <Button type="submit" fullWidth variant="contained" color="secondary">
//                         Update Recruiter
//                     </Button>

//                     </form>
//                 </div>
//             </>
//         )
//     }
// }

// export default UpdateRecruiter;
