import React, { Component } from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
const styles = theme => ({
  card: {
    width: "100%",
    height: "100%",
    textAlign: "center"
  },
  media: {
    width: "100%",
    height: "800px",
    marginLeft: "auto",
    marginRight: "auto"
  }
});

class iframeHire extends Component {
  componentDidMount() {
    try {
      document
        .getElementById("iframeM")
        .setAttribute("allowfullscreen", "true");
      document
        .getElementById("iframeM")
        .setAttribute("src", "https://hire.google.com");
    } catch (error) { }
  }

  render() {
    const { classes } = this.props;

    return (
      <div>
        <Card>
          <CardMedia
            id="iframeM"
            component="iframe"
            alt="Google Hire"
            className={classes.media}
            title="Google Hire"
          />
        </Card>
      </div>
    );
  }
}

iframeHire.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  auth: state.auth
});
export default withStyles(styles)(
  connect(
    mapStateToProps,
    { logoutUser }
  )(iframeHire)
);
