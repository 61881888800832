import axios from "axios";

export const RECEIVE_REVIEWS = "RECEIVE_REVIEWS";
export const RECEIVE_REVIEW_ERRORS = "RECEIVE_REVIEW_ERRORS";

const receiveReviews = (reviews) => {
    return {
        type: RECEIVE_REVIEWS,
        reviews,
    };
};

const receiveReviewErrors = (errors) => {
    return {
        type: RECEIVE_REVIEW_ERRORS,
        errors,
    };
};

// Use this method when you make a review reducer
// export const receiveAllInterviews = (interviewId) => (dispatch) => {
//     axios
//         .get("/salesforce/reviews/" + interviewId)
//         .then((reviews) => dispatch(receiveReviews(reviews)))
//         .catch((err) => console.log(err));
// };

export const receiveAllReviews = (screenerId) => (dispatch) => {
    axios
        .get("/salesforce/screenerRating/screener/" + screenerId)
        .then((reviews) => dispatch(receiveReviews(reviews)))
        .catch((err) => dispatch(receiveReviewErrors(err)));
};
