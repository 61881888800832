import React, { useState, useEffect } from 'react'

// MUI Imports
import { Dialog, DialogContent } from '@material-ui/core';
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Divider from "@mui/material/Divider";
import CloseIcon from "@mui/icons-material/Close";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import axios from 'axios';
import UserSnackbar from '../snackBars/UserSnackbar';

const InvitesExpiryEditModal = ({ invitationExpModal, setInvitationExpModal }) => {

    const id = sessionStorage.getItem("role") == "admin" ? sessionStorage.getItem('id') : sessionStorage.getItem("compId");
    const companyRefId = sessionStorage.getItem("companyId");

    //states to store values
    const [pendingInviteDays, setPendingInviteDays] = useState('')
    const [acceptedInviteDays, setAcceptedInviteDays] = useState('')
    const [errors, setErrors] = useState({
        pending: "",
        accepted: ""
    })
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [message, setMessage] = useState("")

    const handleCloseModal = () => {
        setPendingInviteDays(null);
        setAcceptedInviteDays(null);
        setInvitationExpModal(false);
    }
    const localStorageCompId = sessionStorage.getItem("role") == "admin" ? sessionStorage.getItem('id') : sessionStorage.getItem("compId");

    useEffect(() => {
        axios.get(`/api/admin/general/inviteExpiry/${localStorageCompId}`).then((res) => {
            if (res.data.length > 0) {
                setAcceptedInviteDays(res.data[0].acceptedCount);
                setPendingInviteDays(res.data[0].pendingCount)
            }
        })
    }, [invitationExpModal])

    const handleSubmit = (e) => {
        e.preventDefault();

        if (pendingInviteDays === '') {
            return setErrors({
                pending: "Please enter a value"
            })
        }
        if (acceptedInviteDays === '') {
            return setErrors({
                accepted: "Please enter a value"
            })
        }
        if (pendingInviteDays < 0) {
            return setErrors({
                pending: "Value cannot be less than 0"
            })
        }
        if (pendingInviteDays > 365) {
            return setErrors({
                pending: "Value cannot be greater than 365"
            })
        }
        if (acceptedInviteDays < 0) {
            return setErrors({
                accepted: "Value cannot be less than 0"
            })
        }
        if (acceptedInviteDays > 365) {
            return setErrors({
                accepted: "Value cannot be greater than 365"
            })
        }

        const data = { pendingInviteDays, acceptedInviteDays };
        axios.post(`/api/admin/general/deleteInvite/${id}/${companyRefId}`, data)
            .then(res => {
                handleCloseModal()
                setMessage("Days updated successfully")
                setOpenSnackbar(true)

            })
            .catch((err) => {
                console.error(err)
                setMessage("Oops.. something went wrong!")
                setOpenSnackbar(true)
            })
    }

    return (
        <div>
            <Dialog
                open={invitationExpModal}
                onClose={handleCloseModal}
                className="experience-edit-dialog invites-expiry-edit-dialog"
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent
                    className="experience-edit-dialog-content"
                >
                    <Grid container spacing={0} justifyContent="center">
                        <Grid
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            className="experience-edit-title-grid"
                        >
                            <div>
                                <div className="experience-edit-title">
                                    <p>Invitations Expiry</p>
                                </div>
                                <div className="experience-edit-close">
                                    <Tooltip title="Close">
                                        <CloseIcon onClick={handleCloseModal} />
                                    </Tooltip>
                                </div>
                            </div>
                            <Divider className="experience-edit-top-divider" />
                        </Grid>

                        <Grid
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            className="experience-edit-right-grid"
                        >
                            <div className="experience-edit-form-container">
                                <form
                                    className="experience-edit-form"
                                    onSubmit={handleSubmit}
                                    noValidate
                                >
                                    <div class="input-container">
                                        <div class="input-field invites-expiry">
                                            <label>Pending Expiry
                                                <Tooltip title="Pending invites will be deleted after below no. of days">
                                                    <InfoOutlinedIcon
                                                        fontSize="small"
                                                        style={{ color: "#4c4c5d", fontSize: "16px", marginLeft: "5px", marginBottom: "1px" }}
                                                    />
                                                </Tooltip>
                                            </label>
                                            <div>
                                                <input
                                                    class="position"
                                                    type="number"
                                                    value={pendingInviteDays}
                                                    onChange={(e) => {
                                                        setPendingInviteDays(e.target.value);
                                                        setErrors({ pending: "", accepted: "" })
                                                    }}
                                                />
                                                <span style={{ marginLeft: "8px" }}>days</span>
                                            </div>
                                            <span className="text-danger">{errors.pending}</span>
                                        </div>
                                        <div class="input-field invites-expiry">
                                            <label>Accepted Expiry
                                                <Tooltip title="Accepted invites will be deleted after below no. of days">
                                                    <InfoOutlinedIcon
                                                        fontSize="small"
                                                        style={{ color: "#4c4c5d", fontSize: "16px", marginLeft: "5px", marginBottom: "1px" }}
                                                    />
                                                </Tooltip>
                                            </label>
                                            <div >
                                                <input
                                                    class="position"
                                                    type="number"
                                                    value={acceptedInviteDays}
                                                    onChange={(e) => {
                                                        setAcceptedInviteDays(e.target.value);
                                                        setErrors({ pending: "", accepted: "" })
                                                    }}
                                                />
                                                <span style={{ marginLeft: "8px" }}>days</span>
                                            </div>
                                            <span className="text-danger">{errors.accepted}</span>
                                        </div>
                                        <p className="invites-note">Invites will remain indefinitely if the days are set to zero.</p>
                                        <div className="button-container">
                                            <button className="btn-style filled-btn filled-btn-animation" onClick={handleSubmit}>
                                                {" "}
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>

            {/* Snackbar */}
            <UserSnackbar openSnackbar={openSnackbar} setOpenSnackbar={setOpenSnackbar} message={message} />
        </div>
    )
}

export default InvitesExpiryEditModal