import DateFnsUtils from "@date-io/date-fns";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from "@material-ui/core/TextField";
import axios from "axios";
import { DatePicker, MuiPickersUtilsProvider, TimePicker } from "material-ui-pickers";
import PropTypes, { string } from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";

const styles = (theme) => ({
    main: {
        width: "auto",
        display: "block", // Fix IE 11 issue.
        marginTop: theme.spacing(5),
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        [theme.breakpoints.up(400 + theme.spacing(6))]: {
            width: "50%",
            marginLeft: "auto",
            marginRight: "auto",
        },
    },

    textField: {
        marginLeft: theme.spacing(),
        marginRight: theme.spacing(),
        width: "100px",
    },

    textFieldBox: {
        marginTop: theme.spacing() * 1,
        marginLeft: theme.spacing(),
        marginRight: theme.spacing(),
        width: "500px",
    },
    select: {
        marginTop: theme.spacing() * 1,
        marginLeft: theme.spacing(),
        marginRight: theme.spacing(),
        width: "250px",
    },
    formControl: {
        margin: theme.spacing(),
        minWidth: 120,
        maxWidth: 300,
    },
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

class createEvents extends Component {
    constructor(props) {
        super(props);

        this.state = {
            userID: this.props.auth.user.id,
            start: new Date(),
            end: new Date(),
            title: string,
            timeZone: string,
            description: string,
            screeners: [],
            screenerList: [],
        };
    }

    componentDidMount() {
        axios.get("api/users/screener").then((response) => {
            this.setState({
                screenerList: response.data,
            });
        });
    }

    handleChange = (event) => {
        this.setState({ screeners: event.target.value });
    };

    handleInputChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    };

    handleStartDateChange = (date) => {
        this.setState({ start: date });
    };

    handleEndDateChange = (date) => {
        this.setState({ end: date });
    };

    onSubmit = (e) => {
        e.preventDefault();
        const recruiterData = {
            userID: this.state.userID,
            start: this.state.start,
            title: this.state.title,
            timeZone: this.state.timeZone,
            description: this.state.description,
            screeners: this.state.screeners,
            type: "recruiter",
        };

        axios.post("/api/schedule/new", recruiterData).then((res) => (window.location = "/Schedule"));

        this.state.screeners.forEach((element) => {
            const screenerData = {
                userID: element,
                start: this.state.start,
                title: this.state.title,
                timeZone: this.state.timeZone,
                description: this.state.description,
                recruiterID: this.state.userID,
                type: "screener",
            };
            axios.post("/api/schedule/new", screenerData).then((res) => (window.location = "/Schedule"));
        });
    };

    render() {
        const { classes } = this.props;
        const { start, end } = this.state;
        return (
            <React.Fragment>
                <form className={classes.form} onSubmit={this.onSubmit}>
                    <Grid container spacing={24} style={{ padding: "2em" }}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                className="event-input"
                                name="title"
                                required
                                onChange={this.handleInputChange}
                                fullWidth
                                label="Title"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl className={classes.select}>
                                <InputLabel htmlFor="select-multiple-checkbox">Screener</InputLabel>
                                <Select
                                    multiple
                                    value={this.state.screeners}
                                    onChange={this.handleChange}
                                    input={<Input id="select-multiple-checkbox" />}
                                    renderValue={(selected) => "Selected"}
                                    autoComplete="on"
                                    MenuProps={MenuProps}
                                >
                                    {this.state.screenerList.map((screenerListItem) => (
                                        <MenuItem key={screenerListItem._id} value={screenerListItem._id}>
                                            {/* <Checkbox checked={this.state.screenerList.indexOf(screenerList) >-1} /> */}
                                            <ListItemText primary={screenerListItem.name} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DatePicker
                                    required
                                    id="startDate"
                                    label="Start Date"
                                    name="startDate"
                                    value={start}
                                    onChange={this.handleStartDateChange}
                                    fullWidth
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <TimePicker
                                    id="startTime"
                                    label="Start Time"
                                    name="startTime"
                                    value={start}
                                    onChange={this.handleStartDateChange}
                                    fullWidth
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <TimePicker
                                    id="EndTime"
                                    label="end Time"
                                    name="FinishTime"
                                    value={end}
                                    fullWidth
                                    onChange={this.handleEndDateChange}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                className="event-input"
                                name="timeZone"
                                required
                                onChange={this.handleInputChange}
                                fullWidth
                                label="TimeZone"
                            />
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <TextField
                                name="description"
                                onChange={this.handleInputChange}
                                fullWidth
                                multiline
                                className={classes.textFieldBox}
                                margin="normal"
                                variant="outlined"
                                label="Description"
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Button type="submit" fullWidth variant="contained" color="secondary" align="center" className={classes.submit}>
                                Add Event
                            </Button>
                        </Grid>
                    </Grid>
                </form>
                <br />
            </React.Fragment>
        );
    }
}

createEvents.propTypes = {
    logoutUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
    auth: state.auth,
});
export default withStyles(styles)(connect(mapStateToProps, { logoutUser })(createEvents));
