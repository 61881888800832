import jwt_decode from "jwt-decode";
import React from "react";
import ReactDOM from "react-dom";
import { logoutUser, setCurrentUser } from "./actions/authActions";
import Root from "./components/Root";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import store from "./store";
import setAuthToken from "./utils/setAuthToken";
import "intro.js/introjs.css";

document.addEventListener("DOMContentLoaded", () => {
  // Check for token to keep user logged in
  if (sessionStorage.jwtToken) {
    // Set auth token header auth

    // token is set in localStorage, this is probably not the most secure
    // need to find a diffrent process for this.
    const token = sessionStorage.jwtToken;
    setAuthToken(token);
    // Decode token and get user info and exp
    const decoded = jwt_decode(token);
    // Set user and isAuthenticated
    store.dispatch(setCurrentUser(decoded));
    // Check for expired token
    const currentTime = Date.now() / 1000; // to get in milliseconds
    if (decoded.exp < currentTime) {
      // Logout user
      store.dispatch(logoutUser());
      // Redirect to login
      window.location.href = "/";
    }
  }

  // browser console test methods
  window.getState = store.getState;
  window.dispatch = store.dispatch;

  ReactDOM.render(<Root store={store} />, document.getElementById("root"));
});
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
