import { Step, StepLabel, Stepper } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import axios from "axios";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { logoutUser } from "../../../actions/authActions";
// import img from "../../../images/b2.jpg";
import CandidateInformation from "./candidateInformation";
import CommunicationSkills from "./communicationSkills";
import Confirm from "./confirm";
import ProjectExperience from "./projectExperience";
import TeamLeadership from "./teamLeadership";
import TechnicalProficiency from "./technicalProficiency";
import ToolsKnowledge from "./toolsKnowledge";
import { withRouter } from "react-router-dom";
import { withSnackbar } from "notistack";
import { string } from "prop-types";


const styles = (theme) => ({
    main: {
        width: "100%",
        display: "block", // Fix IE 11 issue.
        // [theme.breakpoints.up(400 + theme.spacing(6))]: {
        //     width: "50%",
        //     marginLeft: "auto",
        //     marginRight: "auto",
        // },
    },
    paper: {
        marginTop: theme.spacing(2),
        display: "flex",
        width: "100%",
        flexDirection: "column",
        alignItems: "center",
        padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
    },
    button: {
        padding: theme.spacing(2),
        margin: 10,
        align: "center",
    },
});

class Assessment extends Component {

    constructor(props) {
        console.log(props)
        super(props);
        // const idArray = this.props.interview.interviewId;
        this.state = {
            // user: "",
            // candidateName: "",
            // candidateEmail: "",
            // salesforceInterviewId: "",
            // jobTitle: "",
            _id: this.props.match.params.id,
            communicationSkills: 0,
            commentCommunication: string,
            technicalProficiency: 0,
            commentTechnical: string,
            projectExperience: 0,
            commentProject: string,
            toolsKnowledge: 0,
            commentTools: string,
            teamLeadership: 0,
            commentTeam: string,
            totalRating: 0,
            interviewstatus: string,
            activeStep: 0,
            loading: true,
        };
    }

    componentDidMount() {
        //     const {interview, auth} = this.props;
        //     console.log(interview)
        //     axios
        //         .get("/api/candidateAssessment/" + interview.interviewId)
        //         .then((response) => {
        //             console.log(response);
        //             if (response.data !== "There are no candidate assesments made for this interview") {
        //                 this.setState({
        //                     user: auth.user.id,
        //                     candidateName: interview.candidateName,
        //                     candidateEmail: interview.candidateEmail,
        //                     salesforceInterviewId: interview.interviewId,
        //                     jobTitle: interview.position,
        //                     communicationSkills: response.data.assessment.communication_skills,
        //                     commentCommunication: response.data.assessment.comment_communication,
        //                     technicalProficiency: response.data.assessment.technical_proficiency,
        //                     commentTechnical: response.data.assessment.comment_technical,
        //                     projectExperience: response.data.assessment.project_experience,
        //                     commentProject: response.data.assessment.comment_project,
        //                     toolsKnowledge: response.data.assessment.tools_knowledge,
        //                     commentTools: response.data.assessment.comment_tools,
        //                     teamLeadership: response.data.assessment.team_leadership,
        //                     commentTeam: response.data.assessment.comment_team,
        //                     totalRating: response.data.total_rating,
        //                 });
        //             } else {
        //     console.log("There has been no review with this interview");
        //     console.log(interview);

        // }
        //         })
        //         .catch((err) => {
        //             this.setState({
        //                 user: auth.user.id,
        //                 candidateName: interview.candidateName,
        //                 candidateEmail: interview.candidateEmail,
        //                 salesforceInterviewId: interview.interviewId,
        //                 jobTitle: interview.position,
        //             })
        //             console.log(err);
        //         });


        axios.get("/api/event/interview/" + this.state._id)
            .then(
                (res) => {
                    var newState = {
                        communicationSkills: res.data.communication_skills,
                        commentCommunication: res.data.comment_communication,
                        technicalProficiency: res.data.technical_proficiency,
                        commentTechnical: res.data.comment_technical,
                        projectExperience: res.data.project_experience,
                        commentProject: res.data.comment_project,
                        toolsKnowledge: res.data.tools_knowledge,
                        commentTools: res.data.comment_tools,
                        teamLeadership: res.data.team_leadership,
                        commentTeam: res.data.comment_team,
                        totalRating: res.data.total_rating,
                        interviewstatus: "Completed With Feedback",
                    };

                    this.setState(newState);
                    this.setState({ loading: false });
                },
                (err) => {
                    alert('An error occured! Try refreshing the page.', err);
                }
            );


    }

    // Proceed to next step
    nextStep = () => {
        const { activeStep } = this.state;
        console.log(this.state.activeStep)

        if (this.state.activeStep === 0) {
            console.log(this.state.commentCommunication)
            if (this.state.commentCommunication === undefined || this.state.commentCommunication==="") {
                this.props.enqueueSnackbar('Please fill in the required fields', {
                    variant: 'error',
                    autoHideDuration: 3000,
                });

            } else {
                this.setState({
                    activeStep: activeStep + 1,
                });
            }
        } else if (this.state.activeStep === 1) {
            console.log(this.state.commentTechnical)
            if (this.state.commentTechnical === undefined || this.state.commentTechnical === "") {
                console.log("technical")
                this.props.enqueueSnackbar('Please fill in the required fields', {
                    variant: 'error',
                    autoHideDuration: 3000,
                });
            } else {
                this.setState({
                    activeStep: activeStep + 1,
                });
            }
        } else if (this.state.activeStep === 2) {
            console.log(this.state.commentProject)
            if (this.state.commentProject === undefined || this.state.commentProject === "") {
                console.log("Project")
                this.props.enqueueSnackbar('Please fill in the required fields', {
                    variant: 'error',
                    autoHideDuration: 3000,
                });
            } else {
                this.setState({
                    activeStep: activeStep + 1,
                });
            }
        } else if (this.state.activeStep === 3) {
            console.log(this.state.commentTools)
            if (this.state.commentTools === undefined || this.state.commentTools === "") {
                console.log("tools")
                this.props.enqueueSnackbar('Please fill in the required fields', {
                    variant: 'error',
                    autoHideDuration: 3000,
                });
            } else {
                this.setState({
                    activeStep: activeStep + 1,
                });
            }
        } else if (this.state.activeStep === 4) {
            console.log(this.state.commentTeam)
            if (this.state.commentTeam === undefined || this.state.commentTeam === "") {
                console.log("team")
                this.props.enqueueSnackbar('Please fill in the required fields', {
                    variant: 'error',
                    autoHideDuration: 3000,
                });
            } else {
                this.setState({
                    activeStep: activeStep + 1,
                });
            }
        }




    };

    // Go back to prev stepF
    prevStep = () => {
        const { activeStep } = this.state;
        this.setState({
            activeStep: activeStep - 1,
        });
    };
    onSubmit = () => {

        //e.preventDefault();
        // const { _id,communication_skills,comment_communication,technical_proficiency,comment_technical,project_experience,comment_project,tools_knowledge,comment_tools,team_leadership,comment_team,total_rating } = this.state;

        // var interview = {
        //     _id,communication_skills,comment_communication,technical_proficiency,comment_technical,project_experience,comment_project,tools_knowledge,comment_tools,team_leadership,comment_team,total_rating
        // };
        //    this.setState({
        //         interviewstatus: "Completed Reviewed"
        //    })
        //      console.log(this.state.interviewstatus)
        axios.put("/api/event/assessment/" + this.state._id, this.state)

            .then((res) => {

                //alert('Updated successfully');
                let regMessage = <span>Reviewed Successfully</span>;
                this.props.enqueueSnackbar(regMessage, {
                    variant: "success",
                    autoHideDuration: 3000,
                });
                this.props.history.push("/screener-interview-list")

            })
            .catch(err => {
                console.log(err.response);
                // alert('An error occured try submitting the form again');
                let regMessage = <span>An error occured try submitting the form again</span>;
                this.props.enqueueSnackbar(regMessage, {
                    variant: "error",
                    autoHideDuration: 3000,
                });
            });

        //console.log(this.props);


    }


    // Handle fields change
    handleChange = (input) => (event) => {
        this.setState({ [input]: event.target.value });
    };
    handleRatingChange = (input) => (event) => {
        // console.log(`${input} rated as ${event}`);
        this.setState({ [input]: event });
    };
    add = () => {
        const { communicationSkills, technicalProficiency, projectExperience, toolsKnowledge, teamLeadership } = this.state;
        this.setState({
            totalRating:
                parseInt(communicationSkills) +
                parseInt(technicalProficiency) +
                parseInt(projectExperience) +
                parseInt(toolsKnowledge) +
                parseInt(teamLeadership),
        });
    };

    getSteps = () => {
        return [
            // "Candidate Information",
            "Communication Skills",
            "Technical proficiency",
            "Project Experience",
            "Tools Knowledge",
            "Team leadership",
            "Review",
        ];
    };

    getStepContent = (stepIndex) => {
        const {
            // candidateName,
            // jobTitle,
            communicationSkills,
            commentCommunication,
            technicalProficiency,
            commentTechnical,
            projectExperience,
            commentProject,
            toolsKnowledge,
            commentTools,
            teamLeadership,
            commentTeam,
            totalRating,
        } = this.state;

        const values = {
            // candidateName,
            // jobTitle,
            communicationSkills,
            commentCommunication,
            technicalProficiency,
            commentTechnical,
            projectExperience,
            commentProject,
            toolsKnowledge,
            commentTools,
            teamLeadership,
            commentTeam,
            totalRating,
        };

        switch (stepIndex) {
            // case 0:
            //     return <CandidateInformation nextStep={this.nextStep} handleChange={this.handleChange} values={values} />;
            case 0:
                return (
                    <CommunicationSkills
                        nextStep={this.nextStep}
                        handleChange={this.handleChange}
                        onRatingChange={this.handleRatingChange}
                        values={values}
                    />
                );
            case 1:
                return (
                    <TechnicalProficiency
                        nextStep={this.nextStep}
                        handleChange={this.handleChange}
                        onRatingChange={this.handleRatingChange}
                        values={values}
                    />
                );

            case 2:
                return (
                    <ProjectExperience
                        nextStep={this.nextStep}
                        handleChange={this.handleChange}
                        onRatingChange={this.handleRatingChange}
                        values={values}
                    />
                );
            case 3:
                return (
                    <ToolsKnowledge
                        nextStep={this.nextStep}
                        handleChange={this.handleChange}
                        onRatingChange={this.handleRatingChange}
                        values={values}
                    />
                );
            case 4:
                return (
                    <TeamLeadership
                        nextStep={this.nextStep}
                        handleChange={this.handleChange}
                        onRatingChange={this.handleRatingChange}
                        values={values}
                    />
                );
            case 5:
                return <Confirm add={this.add} nextStep={this.nextStep} values={values} />;

            default:
                return "Unknown stepIndex";
        }
    };

    render() {
        const { classes } = this.props;
        const steps = this.getSteps();
        const { activeStep } = this.state;

        // const checkFieldsContact = () => {
        //     console.log(this.state.activeStep)
        //     console.log(this.state.commentCommunication);
        //     //console.log(this.state.contact_info.phone)
        //     console.log("hello");
        //     if (this.state.commentCommunication === undefined) {
        //         console.log("hello");
        //         this.props.enqueueSnackbar('Please fill in the required fields', {
        //             variant: 'error',
        //             autoHideDuration: 3000,
        //         });
        //     } else {
        //         if (activeStep === steps.length - 1) {
        //             this.onSubmit();
        //         } else {
        //             this.nextStep();
        //         }
        //     }

        // };
        // const checkFields = () => {
        //     console.log(this.state.activeStep)
        //     console.log(this.state.commentCommunication);
        //     console.log(this.state.commentProject)
        //     //console.log(this.state.contact_info.phone)
        //     console.log("hello");
        //     if (this.state.commentTechnical === undefined) {
        //         console.log("hello");
        //         this.props.enqueueSnackbar('Please fill in the required fields', {
        //             variant: 'error',
        //             autoHideDuration: 3000,
        //         });
        //     } else {
        //         if (activeStep === steps.length - 1) {
        //             this.onSubmit();
        //         } else {
        //             this.nextStep();
        //         }
        //     }

        // };

        return (
            <div className={classes.main}>
                <br /><br /><br /> <br /><br /><br />
                <Paper className={classes.paper}>
                    <br />
                    <Typography variant="h6">Candidate Assessment</Typography>
                    <Stepper activeStep={activeStep} alternativeLabel className={classes.stepper}>
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    <div>
                        {this.state.activeStep === steps.length ? (
                            <div>
                                <Typography className={classes.instructions}>Profile Completed</Typography>
                            </div>
                        ) : (
                            <React.Fragment>
                                {this.getStepContent(activeStep)}
                                <br /> <br />
                                <div>
                                    <Grid container spacing={10}>
                                        <Grid item xs={3}>
                                            <Button disabled={activeStep === 0} onClick={this.prevStep} className={classes.backButton}>
                                                Back
                                            </Button>
                                        </Grid>
                                        <Grid item xs={6} />
                                        <Grid item xs={3}>
                                            {/* <Button
                                                variant="contained"
                                                color="secondary"
                                                onClick={activeStep === steps.length - 1 ? this.onSubmit : this.nextStep}
                                            >
                                                {activeStep === steps.length - 1 ? "Confirm" : "Next"}
                                            </Button> */}
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                // onClick={activeStep === steps.length - 1 ? this.onSubmit : this.nextStep}
                                                // onClick={activeStep==0? checkFieldsContact:checkFields}
                                                onClick={activeStep === steps.length - 1 ? this.onSubmit : this.nextStep}
                                            >
                                                {activeStep === steps.length - 1 ? "Confirm" : "Next"}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </div>
                            </React.Fragment>
                        )}
                    </div>
                </Paper>
            </div>
        );
    }
}
Assessment.propTypes = {
    logoutUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    // interviews: PropTypes.array,
};
const mapStateToProps = (state) => ({
    auth: state.auth,
    //  interviews: state.interviews,
});

export default withSnackbar(withStyles(styles)(connect(mapStateToProps, { logoutUser })(withRouter(Assessment))));
