import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";

class Question extends Component {
  render() {
    return (
      <div style={{ height: "75vh" }} className="container">
        <p>Page is under construction!</p>
      </div>
    );
  }
}

Question.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  auth: state.auth
});
export default connect(
  mapStateToProps,
  { logoutUser }
)(Question);
