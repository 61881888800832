import React from "react";
import StandardCalendar from "../../calendar/standardCalendar";
import DashCalendarCard from "../../cards/dashCalendarCard";
import SimpleCards from "../../cards/SimpleCards";
import InterviewAlerts from "../../interview/alerts/interviewAlerts";
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Grid, Typography } from '@material-ui/core';
import PendingInterviewAlerts from "../../interview/alerts/pendingInterviewAlerts";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        height:'400px',
        overflow: 'auto',
        // width:'815px'
         border: '2px solid #75a478 '

    },
    paper1:{
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        height:'195px',
        overflow:'auto',
        padding:'15px',
        marginTop:'2px',
        marginBottom:'5px',
        border: '2px solid #75a478 '

    }
}));

export default function DashRowOne(props) {
    const classes = useStyles();
    return (
        <div className="d-flex flex-row justify-content-between">
            {/* <div className="flex-fill" id="column-one">
                <DashCalendarCard cardStyle={props.cardStyle} title="Schedule">
                    <StandardCalendar
                        hourStep={60}
                        defaultView="agenda"
                        selectable={false}
                        popup={true}
                        views={["month", "agenda"]}
                        // views={['month', 'day', 'agenda']}
                    />
                 
                </DashCalendarCard>
            </div>
            <div className="flex-fill maxw-500 ml-3" id="column-two">
                <SimpleCards cardStyle={props.cardStyle} title="Confirmed Interviews Assignments">
                    <InterviewAlerts />
                </SimpleCards>
            </div> */}
            <div className={classes.root}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={8}>
                        <Paper className={classes.paper} elevation={5}>
                            <Typography className="typographyStyle">Schedule</Typography>
                            <StandardCalendar
                                hourStep={60}
                                defaultView="agenda"
                                selectable={false}
                                popup={true}
                                views={["month", "agenda"]}
                            // views={['month', 'day', 'agenda']}
                            />
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Paper className={classes.paper1} elevation={5}>
                            <Typography className="typographyStyle">Confirmed Interviews Assignments</Typography>
                            <InterviewAlerts />
                        </Paper>
                        <Paper elevation={5} className={classes.paper1}>
                            <Typography className="typographyStyle">Pending Interview Assignments</Typography>
                            <PendingInterviewAlerts />
                        </Paper>
                    </Grid>
                    {/* <Grid item xs>
                    <Paper elevation={5} className={classes.paper1}>
                            <Typography className="typographyStyle">Pending Interview Assignments</Typography>
                            <PendingInterviewAlerts />
                        </Paper>
                    </Grid> */}
                </Grid>


            </div>
        </div>
    );
}
