import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import classnames from "classnames";
import React from "react";
import Logo from "../../images/Logos/logolanding.png";

// import MuiPhoneNumber from "material-ui-phone-number";
import Checkbox from "@material-ui/core/Checkbox";
import { useState } from "react";
import "./AddRecruiter.css";
import { Link } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { makeStyles, Divider } from "@material-ui/core";

//material ui

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";

const AddRecruiterForm = ({
  name,
  email,
  password,
  password2,
  role,
  onChange,
  onSubmit,
  errors,
  isErrors,
  classes,
}) => {
  const [passwordShown, setPasswordShown] = useState(false);
  const [open, setOpen] = useState(false);
  const togglePassword = () => {
    // When the handler is invoked
    // inverse the boolean state of passwordShown
    setPasswordShown(!passwordShown);
  };
  const isLetter = (e) => {
    const re = /[a-zA-z\s]+/g;
    if (!re.test(e.key)) {
      e.preventDefault();
    }
  };
  const resetFieldPassword = () => {
    errors.password = "";

    errors.email = "";
    errors.name = "";
  };

  const resetFieldPassword2 = () => {
    errors.password2 = "";
  };

  const resetFieldEmail = () => {
    errors.email = "";
  };

  const resetFieldname = () => {
    errors.name = "";
  };

  //handleClickOpen
  const handleClickOpen = () => {
    setOpen(true);
  };
  //handleClose
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <br /> <br /> <br /> <br /> <br /> <br /> <br />
      <div className="d-flex flex-column align-items-center p-3 m-2">
        {isErrors.misc ? (
          <span className="text-danger mt-3">
            {errors.message.slice(0, 23)}
          </span>
        ) : (
          ""
        )}

        {/* <Link to="/admin/recruiter-list">Back</Link> */}
        <Grid style={{ height: "100vh" }}>
          <Paper style={{ padding: "32px" }}>
            <br />
            <Typography variant="h6" style={{ textAlign: "center" }}>
              Add Recruiter
              <Divider style={{ width: "20%", margin: "auto" }} />
              <br />
            </Typography>

            <Dialog
              open={open}
              onClose={handleClose}
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                  Warning: All your inputs in the current form will be lost when
                  you leave.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleClose}
                  style={{ backgroundColor: "#D73B30", color: "white" }}
                >
                  Cancel
                </Button>

                <Link
                  style={{
                    color: "white",
                    textDecoration: "none",
                    padding: "10px",
                  }}
                  to="/admin/recruiter-list"
                >
                  <Button
                    style={{ backgroundColor: "#75A478", color: "white" }}
                  >
                    Confirm
                  </Button>
                </Link>
              </DialogActions>
            </Dialog>

            <form className="registerform" noValidate onSubmit={onSubmit}>
              <div className="form-group">
                <label className="font-weight-bold" htmlFor="name">
                  Full Name
                </label>
                <input
                  autoComplete="name"
                  id="name"
                  type="text"
                  name="name"
                  onClick={resetFieldname}
                  onChange={onChange}
                  onKeyPress={(e) => isLetter(e)}
                  value={name}
                  error={isErrors.name.toString()}
                  className={classnames("form-control", {
                    invalid: isErrors.name,
                  })}
                />
                <span className="text-danger">{errors.name}</span>
              </div>

              <div className="form-group">
                <label className="font-weight-bold" htmlFor="email">
                  Email
                </label>
                <input
                  autoComplete="email"
                  onChange={onChange}
                  onClick={resetFieldEmail}
                  value={email}
                  error={isErrors.email.toString()}
                  id="email"
                  type="email"
                  name="email"
                  className={classnames("form-control", {
                    invalid: isErrors.email,
                  })}
                />
                <span className="text-danger">{errors.email}</span>
              </div>

              {/* <div className="form-group">
        

          <label className="font-weight-bold" htmlFor="email">
           Role
          </label>
          <input
            autoComplete="role"
            onChange={onChange}
           // onClick={resetFieldEmail}
            value={role}
          
            id="role"
            type="role"
            name="role"
            className='form-control'
          />

        </div> */}

              <div className="form-group">
                <label className="font-weight-bold" htmlFor="password">
                  Password
                </label>

                <input
                  autoComplete="new-password"
                  onChange={onChange}
                  onClick={resetFieldPassword}
                  value={password}
                  error={isErrors.password.toString()}
                  id="password"
                  name="password"
                  type={passwordShown ? "text" : "password"}
                  className={classnames("form-control", {
                    invalid: isErrors.password,
                  })}
                />
                <span className="text-danger">{errors.password}</span>
                <div>
                  <Checkbox
                    color="primary"
                    inputProps={{ "aria-label": "secondary checkbox" }}
                    onClick={togglePassword}
                  >
                    {" "}
                  </Checkbox>
                  <label>Show Password</label>
                </div>
              </div>

              <div className="form-group">
                <label className="font-weight-bold" htmlFor="password2">
                  Confirm Password
                </label>
                <input
                  autoComplete="new-password"
                  onChange={onChange}
                  onClick={resetFieldPassword2}
                  value={password2}
                  error={isErrors.password2.toString()}
                  id="password2"
                  type="password"
                  name="password2"
                  className={classnames("form-control", {
                    invalid: isErrors.password2,
                  })}
                />
                <span className="text-danger">{errors.password2}</span>
              </div>

              {/* <Button
                type="submit"
               
                variant="contained"
                color="secondary"
                className={classes.submit}
              >
                Add Recruiter
              </Button> */}
              <Grid item xs={12} sm={12}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    flexWrap: "wrap",
                    float: "left",
                  }}
                >
                  {" "}
                  <Button
                    onClick={handleClickOpen}
                    variant="contained"
                    color="secondary"
                    className={classes.submit}
                    style={{
                      marginRight: "12px",
                      backgroundColor: "#D73B30",
                      color: "white",
                    }}
                  >
                    Back
                  </Button>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    flexWrap: "wrap",
                  }}
                >
                  <Button
                    style={{ color: "white" }}
                    type="submit"
                    variant="contained"
                    color="secondary"
                    align="center"
                    className={classes.submit}
                  >
                    Add Recruiter
                  </Button>
                </div>
              </Grid>
            </form>
          </Paper>
        </Grid>
      </div>
      {/* <div className="LoginSignUpContainer">
    <div className="LoginSignUpBox">
     <h3>Add Recruiter</h3>
    <form className="registerform" noValidate onSubmit={onSubmit}>
    <div className="loginName">
    <input
            autoComplete="name"
            id="name"
            type="text"
            name="name"
            onClick={resetFieldname}
            placeholder="Full Name"
            onChange={onChange}
            onKeyPress={(e) => isLetter(e)}
            value={name}
            error={isErrors.name.toString()}
            className={classnames('form-control', {
              invalid: isErrors.name,
            })}
          />
          <span className="text-danger">{errors.name}</span>
    </div>
        
    <div className="loginEmail">
    <input
            autoComplete="email"
            onChange={onChange}
            onClick={resetFieldEmail}
            value={email}
            error={isErrors.email.toString()}
            placeholder="Email"
            id="email"
            type="email"
            name="email"
            className={classnames('form-control', {
              invalid: isErrors.email,
            })}
          />
          <span className="text-danger">{errors.email}</span>

    </div>
    <div className="loginRole">
    <input
            autoComplete="role"
            onChange={onChange}
           // onClick={resetFieldEmail}
            value={role}
            placeholder="Role"
            id="role"
            type="role"
            name="role"
            className='form-control'
          />
    </div>
    <div className="loginPassword">
    <input
            autoComplete="new-password"
            onChange={onChange}
            onClick={resetFieldPassword}
            value={password}
            error={isErrors.password.toString()}
            id="password"
            placeholder="Password"
            name="password"
            type={passwordShown ? 'text' : 'password'}
            className={classnames('form-control', {
              invalid: isErrors.password,
            })}
          />
          <span className="text-danger">{errors.password}</span>
    </div>
    <div className="checkbox">
                    <Checkbox
                    color="default"
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                    onClick={togglePassword}> </Checkbox>
                    <label>Show Password</label>
     </div>


     <div className="loginPassword">
     <input
            autoComplete="new-password"
            onChange={onChange}
            onClick={resetFieldPassword2}
            value={password2}
            error={isErrors.password2.toString()}
            placeholder="Confirm Password"
            id="password2"
            type="password"
            name="password2"
            className={classnames('form-control', {
              invalid: isErrors.password2,
            })}
          />
          <span className="text-danger">{errors.password2}</span>
     </div>
     <input type="submit" value="Add Recruiter" className="AddBtn" />

    </form>

    </div>
</div> */}
    </>
  );
};

export default AddRecruiterForm;
