import React from "react";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";

const frozenMessageSnackbar = ({
  openErrorSnackbar,
  setOpenErrorSnackbar,
  message,
}) => {
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenErrorSnackbar(false);
  };

  return (
    <div>
      <Snackbar
        open={openErrorSnackbar}
        autoHideDuration={2000}
        onClose={handleClose}
        message={message}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        action={
          <React.Fragment>
            <Button
              style={{ textTransform: "capitalize", color: "#f06161" }}
              onClick={handleClose}
            >
              Close
            </Button>
          </React.Fragment>
        }
      />
    </div>
  );
};

export default frozenMessageSnackbar;
