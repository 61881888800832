import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { Component } from "react";
// import Logo from "../../images/Logos/logolanding.png";
// import Login from "../auth/Login";
// import "../auth/Login.css";
// import LoginPage from "../auth/LoginPage";
import LoginComponent from "../auth/LoginComponent";
import { GoogleOAuthProvider } from "@react-oauth/google";

const styles = (theme) => ({
  title: {
    fontSize: 25,
  },
  // logo: {
  //     // marginTop: "60px",
  //     // marginLeft: "424px",
  //     height: "180px",
  //     width: "600px",
  // },
  pos: {
    fontSize: 25,
  },
  button: {
    padding: theme.spacing(2),
    margin: 10,
    align: "center",
  },
});

class Landing extends Component {
  render() {
    const { classes } = this.props;

    return (
      <>
        <div className="landing-component">

          {/* <Login history={this.props.history} /> */}
          <GoogleOAuthProvider clientId="458868131518-qshhdo2sc2cfkf704q76fhkctb4pgg53.apps.googleusercontent.com">
            <LoginComponent history={this.props.history} />
          </GoogleOAuthProvider>
        </div>
      </>
    );
  }
}

Landing.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Landing);
