import React from "react";
import { Link, animateScroll as scroll } from "react-scroll";
const About = () => {
  return (
    <section class="section" id="about">
      <div class="container">
        <div class="row align-items-center mr-auto">
          <div class="col-md-4">
            <h6 class="xs-font mb-0">Know more.</h6>
            <h3 class="section-title">About</h3>
            <p>
              Screener is an application that focuses on the overall recruitment
              management cycle such as sourcing candidates, finding professional
              interviewers, scheduling interviews, and tracking the same.
            </p>
            <Link smooth={true} to="contact">
              <button class="btn btn-primary btn-rounded">
                <a class="contact" style={{ color: "white" }}>
                  Contact Us
                </a>
              </button>
            </Link>
          </div>
          <div class="col-sm-6 col-md-4 ml-auto">
            <div class="widget">
              <div class="icon-wrapper">
                <i class="fa-solid fa-building"></i>
              </div>
              <div class="infos-wrapper">
                <h4 class="text-primary">250+</h4>
                <p>Recruitment Companies</p>
              </div>
            </div>
            <div class="widget">
              <div class="icon-wrapper">
                <i class="fa-solid fa-users"></i>
              </div>
              <div class="infos-wrapper">
                <h4 class="text-primary">2000+</h4>
                <p>Expert Screeners</p>
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-md-4">
            <div class="widget">
              <div class="icon-wrapper">
                <i class="fa-regular fa-star"></i>
              </div>
              <div class="infos-wrapper">
                <h4 class="text-primary">30000+</h4>
                <p>Job Seekers</p>
              </div>
            </div>

            <div class="widget">
              <div class="icon-wrapper">
                <i class="fa-regular fa-face-smile"></i>
              </div>
              <div class="infos-wrapper">
                <h4 class="text-primary">98%</h4>
                <p>Successful Placements</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
