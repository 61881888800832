import React, { useState } from "react";

const Services = () => {
  const [img1, setImg1] = useState(
    "https://res.cloudinary.com/df2qsnyox/image/upload/v1669876985/Landing%20Page/img-6-min_lan84t.jpg"
  );
  const [img2, setImg2] = useState(
    "https://res.cloudinary.com/df2qsnyox/image/upload/v1669876986/Landing%20Page/img-7-min_lpjb1n.jpg"
  );
  const [img3, setImg3] = useState(
    "https://res.cloudinary.com/df2qsnyox/image/upload/v1669876985/Landing%20Page/img-5-min_vtuzms.jpg"
  );

  return (
    <section class="section" id="services">
      <div class="container">
        <div class="container-heading">
          <h6 class="xs-font mb-0">What we are good at.</h6>
          <h3 class="section-title mb-4">Services</h3>
        </div>
        <div class="row text-center ">
          <div class="col-lg-4">
            <a href="javascript:void(0)" class="card border-0 text-dark">
              <img class="card-img-top " src={img1} alt="image-1" />

              <span class="card-body">
                <h4 class="title mt-4">Sourcing Candidates</h4>
                <p class="xs-font">
                  Screener has a track record of connecting Job seekers to their
                  desired jobs.
                </p>
              </span>
            </a>
          </div>
          <div class="col-lg-4">
            <a href="javascript:void(0)" class="card border-0 text-dark">
              <img class="card-img-top " src={img2} alt="image-2" />
              <span class="card-body">
                <h4 class="title mt-4">Interview Tracking</h4>
                <p class="xs-font">
                  The job seekers, screeners and recruitment team can easily
                  keep a track of Interviews
                </p>
              </span>
            </a>
          </div>
          <div class="col-lg-4">
            <a href="javascript:void(0)" class="card border-0 text-dark">
              <img class="card-img-top" src={img3} alt="image-3" />
              <span class="card-body">
                <h4 class="title mt-4">Professional Screeners</h4>
                <p class="xs-font">
                  Around 2000+ expert and professional screeners to screen the
                  job seekers.
                </p>
              </span>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
