// import Badge from 'react-bootstrap/Badge';
import Grid from "@material-ui/core/Grid";
import withStyles from "@material-ui/core/styles/withStyles";
import React, { Component } from "react";

const styles = theme => ({
    isActive: {
        borderBottom: "3px solid #9DCD95",
        fontWeight: "bold",
        color: "#212121",
        opacity: "1px",
        letterSpacing: "0px",
        width: "100%"
    },
    isInactive: {
        color: "#212121",
        letterSpacing: "0px",
        fontWeight: "700"
    }
});

class NavigationTab extends Component {

    // This function can be used to add notifications to the tabs on the navbar
    // its find the props for each, and displays the length.
    // FEATURE can be improved by having a read input and use that to display
    // a new badge notification.
    // findProps() {
    //     switch (this.props.tabName) {
    //         case 'Dashboard':
    //         case 'My Profile':
    //         case 'My Calendar':
    //             return null;
    //         case 'Notifications':
    //             return 0;
    //         case 'Interviews':
    //             return this.props.interviews.length;
    //         case 'Jobs':
    //             return this.props.positions.length;
    //         default:
    //             break;
    //     }
    // }

    render() {
        const { classes, handleClick, tabName, isActive } = this.props;
        return (
            <Grid container direction="row" justify="space-around" align="flex-end">
                <div className={isActive ? classes.isActive : classes.isInactive}
                    onClick={handleClick()} value={tabName} >
                    <span>{tabName}{"  "}</span>
                  
                    {/* <Badge variant="secondary">{this.findProps(tabName)}</Badge> */}
                </div>
            </Grid>
        )
    }
}

// NavigationTab.propTypes = {
//     interviews: PropTypes.array,
//     positions: PropTypes.array,
// };

// const mapStateToProps = state => ({
//     interviews: state.interviews,
//     positions: state.positions
// });

export default withStyles(styles)(NavigationTab);