import React, { useState } from 'react';
import axios from 'axios';
// MUI imports
import { Tooltip, Grid, Dialog, DialogContent } from "@material-ui/core";
import { Divider } from "@mui/material";

// ICONS
import CloseIcon from '@mui/icons-material/Close';
import Rating from '@mui/material/Rating';

const ScreenerFeedbackDialog = ({ feedbackOpen, setFeedbackOpen, getInterviews, setMessage, setOpenSnackbar, selectedInterview }) => {

    const [errors, setErrors] = useState({
        date: "",
        time: "",
        ratings: '', remarks: '', comments: ""
    })

    const [feedback, setFeedback] = useState({
        ratings: 0, remarks: '', comment: ''
    })

    const handleCloseReschedule = () => {
        setFeedbackOpen(false)
        setFeedback({ ratings: 0, comment: '', remarks: '' })
    }

    const feedBackHandler = (e) => {
        e.preventDefault();
        if (!feedback.ratings) {
            return setErrors({ ratings: "Ratings is required!" })
        } else if (!feedback.remarks) {
            return setErrors({ remarks: "Title is required!" })
        } else if (!feedback.comment) {
            return setErrors({ comments: "Review is required!" })
        }

        const newData = {
            feedback, selectedInterview
        }

        axios.post(`/api/schedule/candidate-feedback`, newData).then((res) => {
            getInterviews()
            setFeedbackOpen(false)
            handleCloseReschedule()
            setOpenSnackbar(true)
            setMessage(res.data.msg)

        }).catch(err => {
            console.log(err)
        })
    }

    return (
        <div>
            <Dialog
                open={feedbackOpen}
                onClose={setFeedbackOpen}
                maxWidth='sm'
                fullWidth
                className="experience-edit-dialog"
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent
                    className="experience-edit-dialog-content"
                >
                    <Grid
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        className="experience-edit-title-grid"
                    >
                        <div>
                            <div className="experience-edit-title">
                                <p>Interview Feedback</p>
                            </div>
                            <div className="experience-edit-close">
                                <Tooltip title="Close">
                                    <CloseIcon onClick={handleCloseReschedule} />
                                </Tooltip>
                            </div>
                        </div>
                        <Divider className="experience-edit-top-divider" />
                    </Grid>
                    <Grid
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        className="experience-edit-right-grid"
                        style={{ overflowX: "hidden" }}
                    >
                        <div className="experience-edit-form-container">
                            <form
                                className="experience-edit-form"
                                onSubmit={feedBackHandler}
                                noValidate
                            >
                                <div class="input-container">
                                    <div class="input-field">
                                        <label>Ratings*</label>
                                        <div>
                                            <Rating
                                                name="simple-controlled"
                                                value={feedback.ratings}
                                                onChange={(event, newValue) => {
                                                    setFeedback({ ...feedback, ratings: newValue });
                                                    setErrors({})
                                                }}
                                            />
                                        </div>
                                        <span className="text-danger">{errors.ratings}</span>
                                    </div>
                                    <div class="input-field">
                                        <label>Title*</label>
                                        <div>
                                            <input
                                                style={{ height: 35 }}
                                                class="position"
                                                type="text"
                                                value={feedback.remarks}
                                                onChange={(e) => {
                                                    setErrors({})
                                                    setFeedback({ ...feedback, remarks: e.target.value })
                                                }}
                                            />
                                        </div>
                                        <span className="text-danger">{errors.remarks}</span>
                                    </div>
                                    <div class="input-field">
                                        <label>Review*</label>
                                        <div>
                                            <textarea
                                                value={feedback.comment}
                                                onChange={(e) => {
                                                    setErrors({})
                                                    setFeedback({ ...feedback, comment: e.target.value })
                                                }}
                                                style={{ outline: "none" }}
                                                rows="5"
                                            >
                                            </textarea>
                                        </div>
                                        <span className="text-danger">{errors.comments}</span>
                                    </div>
                                    <div className="button-container">
                                        <button className="btn-style filled-btn filled-btn-animation" >
                                            {" "}
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </Grid>
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default ScreenerFeedbackDialog