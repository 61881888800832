import "./PageNotFound.css"
import React from 'react';

const UnderDevelopment = () => {
    return (
        <div className="under-development-container">
            <img src="https://res.cloudinary.com/df2qsnyox/image/upload/v1679053607/404%20page/zero_2_rhsqlh.jpg" alt="Under Developement" />
        </div>
    )
}

export default UnderDevelopment