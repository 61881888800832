import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import { makeStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { Rating } from "@material-ui/lab";
import React from "react";
import CollapseIcon from "../common/collapseIcon";
import ConfirmationButtons from "./confirmationButtons";
import ReviewButton from "./reviewButton";
import CompleteInterviewButtons from './CompleteInterviewButtons';

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        "&:nth-of-type(odd)": {
            backgroundColor: theme.palette.primary.main,
        },
    },
}))(TableRow);

const useRowStyles = makeStyles({
    root: {
        "& > *": {
            borderBottom: "unset",
        },
    },
    confirm: {
        backgroundColor: "#9DCD95",
        marginLeft: "30px",
    },
    review: {
        marginLeft: "30px",
        color: "#75A478",
    },
});

export default function InterviewRows(props) {
    const { row, columns } = props;
    const [open, setOpen] = React.useState(false);
    const classes = useRowStyles();

    const handleStatus = (status) => {
        return status === "Scheduled-Awaiting Confirmation"
            ? "Pending confirm"
            : status === "Scheduled-Confirmed"
            ? "confirmed"
            : status === "Reschedule"
            ? "Pending reschedule"
            : "Completed";
    };

    const whichInterviewAction = (status, salesforceInterviewIdx, interview) => {
        // console.log(salesforceInterviewIdx);
        const {statusConfirm, statusReschedule,statusComplete} = props;
        console.log(status)
        console.log(interview)
        switch (status) {
            case "Scheduled-Awaiting Confirmation":
                return <ConfirmationButtons classes={classes} salesforceIdx={salesforceInterviewIdx} statusConfirm={statusConfirm} statusReschedule={statusReschedule} />;
            case "Scheduled-Confirmed":
                return <CompleteInterviewButtons classes={classes} salesforceIdx={salesforceInterviewIdx} statusComplete={statusComplete}  />;
            case "Completed":
                return <ReviewButton classes={classes} interview={interview} linkText={"Review"} />;
            case "Reschedule":
                return <span>Please wait for the recruiter to reschedule your interview</span>;
            case "Completed-Reviewed":
                return (
                    <>
                        <ReviewButton classes={classes} interview={interview} linkText={"Update Review"} />
                        <span>There are no more urgent actions for this Interview!</span>
                    </>
                );
            default:
                break;
        }
    };

    // console.log(row["interviewId"]);
    return (
        <>
            <StyledTableRow className={classes.root} hover role="checkbox" tabIndex={-1} key={row["dateTime"]}>
                {columns.slice(1).map((column, idx) => {
                    const value = row[column.id];
                    if (column.id === "rating") {
                        return (
                            <StyledTableCell key={`${column.id}-${idx}`} align="center">
                                <Rating name="Performance Rating" readOnly value={value} max={5} />
                            </StyledTableCell>
                        );
                    } else if (column.id === "status") {
                        return (
                            <StyledTableCell key={`${column.id}-${idx}`} align="center" padding="none">
                                {handleStatus(value)}
                            </StyledTableCell>
                        );
                    } else if (column.id === "interviewExternalName") {
                        return (
                            <StyledTableCell key={`${column.id}-${idx}`} align="left" component="th" scope="row" padding="none">
                                <CollapseIcon isOpen={open} openUp={() => setOpen(!open)} />
                                {value}
                            </StyledTableCell>
                        );
                    } else if (column.id === "candidateName") {
                        return ( <StyledTableCell key={`${column.id}-${idx}`} align="left" padding="none">
                                 {value}
                             </StyledTableCell>)
                    } else if (column.id === "candidateEmail") {
                       return ( <StyledTableCell key={`${column.id}-${idx}`} align="left" padding="none">
                                {value}
                            </StyledTableCell>)
                    } else {
                        return (
                            <StyledTableCell key={`${column.id}-${idx}`} align="center">
                                {value}
                            </StyledTableCell>
                        );
                    }
                })}
            </StyledTableRow>
            <TableRow key={row["interviewId"]}>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <div className="d-flex flex-column">
                            <span><strong>Candidate Email:</strong> {row["candidateEmail"]}</span>
                            <span><strong>Position:</strong> {row["position"]}</span>
                            <span><strong>Client:</strong> {row["company"]}</span>
                        </div>
                        <Box margin={1}>{whichInterviewAction(row["status"], row["interviewId"], row)}</Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
}
