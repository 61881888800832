import React, { useState, useRef, useCallback, useEffect } from "react";
import axios from "axios";

//external library Imports
import DialogContent from "@mui/material/DialogContent";
import { Grid } from "@mui/material";
import { Dialog } from "@mui/material";
import Divider from "@mui/material/Divider";
import CloseIcon from "@mui/icons-material/Close";

import formatDistanceToNow from 'date-fns/formatDistanceToNow';

import Tooltip from "@mui/material/Tooltip";
import OptionIcon from '@mui/icons-material/MoreHoriz';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import DeleteIcon from '@mui/icons-material/Delete';

// CSS import
// import "./MainCardEditModal.css";
import "../../CompanyAdmin.css"

import UserSnackbar from "../../../snackBars/UserSnackbar";

const ViewNotes = ({ companyId, profileId, user, setUser, openViewNoteModal, setOpenViewNoteModal, unreadNotesCount, setUnreadNotesCount, getInternalUsers }) => {

    const recentNoteRef = useRef(null);

    const [message, setMessage] = useState("")
    const [openSnackbar, setOpenSnackbar] = useState(false)

    //selected note 
    const [selectedItem, setSelectedItem] = useState({ companyId, profileId });
    const [notesArray, setNotesArray] = useState([]);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleCloseNoteModal = () => {
        setOpenViewNoteModal(false)
        console.log("Call from backend")
        getInternalUsers("intervalFetch")
    }

    const handleMenuClick = (event, item) => {
        setAnchorEl(event.currentTarget);
        setSelectedItem(prev => { return { ...prev, userId: user?._id, ...item } })
    };

    // Scroll to the bottom when messages are updated
    useEffect(() => {
        setUnreadNotesCount(user?.notes.filter(item => !item.read).length)
        return recentNoteRef.current?.scrollTo(0, recentNoteRef.current.scrollHeight)
    }, [user]);

    const handleDeleteNote = (item) => {
        console.log(item)
        axios.post(`/api/admin/delete-note`, { noteObj: item })
            .then(res => {
                axios.post(`/api/admin/note-given-by/${companyId}/${companyId}`, { _id: selectedItem.userId })
                    .then(res => {
                        let userObj = { ...user, notes: res.data }
                        setUser(userObj)
                    })
                    .catch(err => {
                        console.log(err)
                    })
            })
            .catch(error => console.log(error))
    }

    const handleClose = (condition, item) => {
        if (condition == "delete") {
            handleDeleteNote(item);
        }
        setAnchorEl(null);
    };


    // // Function to generate a random color
    // function getRandomNameColor() {
    //     const letters = '0123456789ABCDEF';
    //     let color = '#';
    //     for (let i = 0; i < 6; i++) {
    //         color += letters[Math.floor(Math.random() * 16)];
    //     }
    //     if (color == "#FFFFFF") return "#F06161"
    //     return color;
    // }

    const colors = [
        '#4A90E2',
        '#FF4500',
        '#8A2BE2',
        '#32CD32',
        // '#FFD700',
        '#A0522D',
        '#00CED1',
        '#DC143C',
        '#008080',
        '#FF6347',
        '#7B68EE',
        '#20B2AA',
        '#FF8C00',
        '#9932CC',
        '#2E8B57',
        '#FF1493',
        '#00BFFF',
        '#8B0000',
        '#556B2F',
        '#800080'
    ];

    return (
        <div>
            <Dialog
                open={openViewNoteModal}
                onClose={handleCloseNoteModal}
                className="experience-edit-dialog timezone-expiry-edit-dialog"
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent
                    className="experience-edit-dialog-content"
                >
                    <Grid container spacing={0} justifyContent="center">
                        <Grid
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                        // className="experience-edit-title-grid"
                        >
                            <div className="view-note-title-section">
                                <div className="view-note-title">
                                    <p>{user?.firstName} {user?.lastName}</p>
                                </div>
                                <div className="view-note-close">
                                    <Tooltip title="Close">
                                        <CloseIcon onClick={handleCloseNoteModal} />
                                    </Tooltip>
                                </div>
                            </div>
                            <Divider className="experience-edit-top-divider" />
                        </Grid>

                        <Grid
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            className="note-view-container"

                        >
                            <div className="note-display-container" ref={recentNoteRef}>
                                {user?.notes.map((item, index) => {
                                    return item.read && (
                                        <div className={`${item.profileId == profileId ? "align-note-right" : ""}`}>
                                            <div className="single-note-container" key={index} >
                                                <div className="details-section">
                                                    <h6 className="given-by-name" style={{ color: `${colors[index % colors.length]} ` }}> {item.name} {item.profileId == profileId ? "(You)" : ""}</h6>
                                                    {/* <p> {formatDistanceToNow(new Date(item.time), { addSuffix: true })}</p> */}
                                                    <OptionIcon onClick={(e) => handleMenuClick(e, item)} />

                                                    <Menu
                                                        className="actions-menu-list"
                                                        id="long-menu"
                                                        MenuListProps={{
                                                            "aria-labelledby": "long-button",
                                                        }}
                                                        aria-labelledby="demo-positioned-button"
                                                        anchorEl={anchorEl}
                                                        open={open}
                                                        onClose={() => handleClose("close", selectedItem)}
                                                        PaperProps={{
                                                            style: {
                                                                width: "15ch",
                                                                fontSize: "12px",
                                                                fontFamily: "raleway",
                                                                boxShadow:
                                                                    "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
                                                            },
                                                        }}
                                                    >
                                                        <MenuItem onClick={() => handleClose('delete', selectedItem)}>
                                                            <div>
                                                                <p>
                                                                    <DeleteIcon />
                                                                </p>
                                                                <p>
                                                                    Delete
                                                                </p>
                                                            </div>
                                                        </MenuItem>

                                                    </Menu>
                                                </div>
                                                <div className="note-info-section">
                                                    <div className="note-section" dangerouslySetInnerHTML={{ __html: item.note }}>
                                                    </div>

                                                    <p> {formatDistanceToNow(new Date(item.time), { addSuffix: true })}</p>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}

                                {unreadNotesCount > 0 && <div className="split-notes-container"  >
                                    <p>{unreadNotesCount} unread notes</p>
                                </div>}

                                {user?.notes.map((item, index) => {
                                    return !item.read && (
                                        <div className={`${item.profileId == profileId ? "align-note-right" : ""}`}>
                                            <div className="single-note-container" key={index}>
                                                <div className="details-section">
                                                    <h6 className="given-by-name" style={{ color: `${colors[index % colors.length]} ` }}> {item.name} {item.profileId == profileId ? "(You)" : ""}</h6>
                                                    {/* <p> {formatDistanceToNow(new Date(item.time), { addSuffix: true })}</p> */}
                                                    <OptionIcon onClick={(e) => handleMenuClick(e, item)} />

                                                    <Menu
                                                        className="actions-menu-list"
                                                        id="long-menu"
                                                        MenuListProps={{
                                                            "aria-labelledby": "long-button",
                                                        }}
                                                        aria-labelledby="demo-positioned-button"
                                                        anchorEl={anchorEl}
                                                        open={open}
                                                        onClose={() => handleClose("close", selectedItem)}
                                                        PaperProps={{
                                                            style: {
                                                                width: "15ch",
                                                                fontSize: "12px",
                                                                fontFamily: "raleway",
                                                                boxShadow:
                                                                    "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
                                                            },
                                                        }}
                                                    >
                                                        <MenuItem onClick={() => handleClose('delete', selectedItem)}>
                                                            <div>
                                                                <p>
                                                                    <DeleteIcon />
                                                                </p>
                                                                <p>
                                                                    Delete
                                                                </p>
                                                            </div>
                                                        </MenuItem>

                                                    </Menu>
                                                </div>
                                                <div className="note-info-section">
                                                    <div className="note-section" dangerouslySetInnerHTML={{ __html: item.note }}>
                                                    </div>

                                                    <p> {formatDistanceToNow(new Date(item.time), { addSuffix: true })}</p>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog >

            {/* Snackbar */}
            < UserSnackbar openSnackbar={openSnackbar} setOpenSnackbar={setOpenSnackbar} message={message} />
        </div >
    );
};

export default ViewNotes;
