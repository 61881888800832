import React, { useEffect, useState, Component } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import axios from "axios";
import Button from '@material-ui/core/Button';
import { withSnackbar, useSnackbar } from "notistack";
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import { Container, Grid, Paper, Box, TextareaAutosize, Divider } from "@material-ui/core";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from '@mui/icons-material/Visibility';
import TextField from "@material-ui/core/TextField";
import "./tableList.css";

import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Dialog from "@mui/material/Dialog";
import { Typography } from "@mui/material";

const ReviewList = (props) => {
    const [reviewList, setreviewList] = useState([]);
    const [screenerList, setScreenerList] = useState([]);
    const [recruiterList, setRecruiterList] = useState([]);
    const [open, setOpen] = useState(false);  // review details dialog open state
    const [dialogOpen, setDialogOpen] = useState(false); // review delete confirm dialog open state
    const [deleteReviewId, setDeleteReviewId] = useState(''); // review id of the review to be deleted

    useEffect(() => {
        axios.get("/api/review/myreviews").then((response) => {
            setreviewList(response.data);
        });
        axios.get("/api/users/screener").then((response) => {
            setScreenerList(response.data);
        });
        axios.get("/api/users/recruiter").then((response) => {
            setRecruiterList(response.data);
        });
    }, []);

    const { auth: { user } } = props;
    const userId = user.id;
    const [info, setInfo] = useState({
        reviewId: '', recruiterName: '', recruiterEmail: '', screenerName: '', screenerId: '', rating: '', comment: ''
    });
    const { enqueueSnackbar } = useSnackbar();
    // console.log(user)
    // console.log(props)
    // console.log(reviewList);

    // review details dialog open function
    const handleClickOpen = (id) => {
        const arrReview = reviewList.filter(item => {
            return id === item._id
        })
        const { recruiter, screener } = arrReview[0];

        const arrRecruiter = recruiterList.filter(item => {
            return recruiter === item._id
        })

        const arrScreener = screenerList.filter(item => {
            return screener === item._id
        })
        // console.log(arrScreener, arrRecruiter, arrReview);
        setInfo({
            reviewId: arrReview[0].reviewId,
            recruiterName: arrRecruiter[0].name,
            recruiterEmail: arrRecruiter[0].email,
            screenerName: arrScreener[0].name,
            screenerId: arrScreener[0].uniqueMatchId,
            rating: arrReview[0].numberRating,
            comment: arrReview[0].comments
        })
        setOpen(true);
    };

    // review details dialog close function
    const handleClose = () => {
        return setOpen(false);
    };

    // review delete confirm dialog open function
    const handleDeleteDialogOpen = (id) => {
        setDialogOpen(true);
        const arrReview = reviewList.filter((item) => {
            return item._id === id
        })
        setDeleteReviewId(arrReview[0]._id);
        // console.log(arrReview)
        // console.log(reviewList)
    }

    // review delete confirm dialog close function
    const handleDeleteDialogClose = () => {
        setDialogOpen(false);
    }

    // const candidateid= user._id;
    // console.log(candidateid);
    //console.log(user.name)
    // console.log(user);
    // const deleteReviewHandler = (id) => {

    //     dispatch(deleteOrder(id));
    //     var result = window.confirm("Are you sure to delete?");
    //     confirm({ description: `You want to Delete the review ` })
    //         .then(() => {
    //             axios.delete(`/api/review/reviewdelete/${id}`)
    //                 .then((res) => {
    //                     console.log(res);

    //                     //alert('Interview Deleted successfully');
    //                     let regMessage = <span>Review Deleted successfully</span>;
    //                     enqueueSnackbar(regMessage, {
    //                         variant: "info",
    //                         autoHideDuration: 150000,
    //                     });
    //                     window.location.reload(false);
    //                     //window.location.reload(false);
    //                 })
    //                 .catch(err => {
    //                     console.log(err.response);
    //                     //alert('An error occured try submitting the form again');
    //                     let regMessage = <span> An error occured try submitting the again</span>;
    //                     enqueueSnackbar(regMessage, {
    //                         variant: "error",
    //                         // autoHideDuration: 3000,
    //                     });
    //                 });
    //         }).catch(() => console.log("Deleted"));
    // };

    // review delete confirm function
    const confirmDeleteReview = (id) => {
        axios.delete(`/api/review/reviewdelete/${id}`)
            .then((res) => {
                console.log(res);
                let regMessage = <span>Review Deleted Successfully</span>;
                enqueueSnackbar(regMessage, {
                    variant: "info",
                    autoHideDuration: 150000,
                });
                window.location.reload(false);
            })
            .catch(err => {
                console.log(err.response);
                let regMessage = <span> An error occured try submitting the again</span>;
                enqueueSnackbar(regMessage, {
                    variant: "error",
                    // autoHideDuration: 3000,
                });
            });
    }

    //Row Headings
    const columns = [
        {
            field: "id",
            width: -1,
            flex: -1,
            hide: true
        },
        {
            field: "reviewId",
            headerName: "Review ID",
            minWidth: 150,
            flex: 1,

        },
        {
            field: "recruiter",
            headerName: "Recruiter Name",
            minWidth: 150,
            flex: 0.5,
        },

        {
            field: "email",
            headerName: "Recruiter Email",
            minWidth: 250,
            flex: 0.5,

        },
        {
            field: "screener",
            headerName: "Screener Name",
            minWidth: 250,
            flex: 0.5,

        },
        {
            field: "numberRating",
            headerName: "Rating",
            minWidth: 100,
            flex: 0.5,

        },
        {
            field: "comments",
            headerName: "Comment",
            minWidth: 150,
            flex: 0.5,
        },
        // Actions
        {
            field: "actions",
            flex: 0.3,
            headerName: "Actions",
            minWidth: 150,
            // type: "number",
            sortable: false,
            renderCell: (params) => {
                // console.log(params);
                return (
                    <>
                        {/* <Link to={`/${params.id}`}> */}
                        {/* <Link to={`/review/reviewdetails/${params.getValue(params.id, "id")}`} title="Review Details" style={{ color: "#75a478" }}> */}
                        <VisibilityIcon style={{ color: "#75a478", cursor: "pointer" }} onClick={() => handleClickOpen(params.getValue(params.id, "id"))} />&nbsp;&nbsp;&nbsp;
                        {/* </Link>&nbsp;&nbsp;&nbsp; */}
                        <Link to={`/updatereview/${params.getValue(params.id, "id")}`}>
                            <EditIcon />
                        </Link>&nbsp;&nbsp;

                        {/* <Button onClick={() => onDelete(params.id, params.getValue(params.id, "status"))}>{ params.getValue(params.id, "status") == "Active"? "Deactivate" : "Activate" }</Button> */}
                        <Button onClick={() => handleDeleteDialogOpen(params.getValue(params.id, "id"))}>
                            <DeleteIcon style={{ color: "#75a478" }} />
                        </Button>
                    </>
                );
            },
        },
    ]

    const rows = [];
    reviewList && reviewList.forEach(item => {
        recruiterList && recruiterList.forEach(recruiter => {
            if (item.recruiter === userId && recruiter._id === userId) {
                screenerList && screenerList.forEach(User => {
                    if (item.screener === User._id) {
                        rows.push({
                            id: item._id,
                            reviewId: item.reviewId,
                            recruiter: user.name,
                            email: recruiter.email,
                            screener: User.name + ' - ' + User.uniqueMatchId,
                            numberRating: item.numberRating,
                            comments: item.comments,
                        })
                    }
                })
            }
        })
    })
    // console.log(reviewList);
    return (
        <>
            <br /> <br /> <br /> <br /> <br /> <br /> <br />
            {/* Dialog Box to confirm delete review*/}
            <Dialog
                open={dialogOpen}
                onClose={handleDeleteDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Do you want to delete this review?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleDeleteDialogClose}
                        style={{ backgroundColor: "#D73B30", color: "white" }}>
                        Cancel
                    </Button>
                    <Link
                        to="/review-list"
                        style={{ color: "white", textDecoration: "none" }}>
                        <Button
                            onClick={() => { confirmDeleteReview(deleteReviewId) }}
                            autoFocus
                            style={{
                                backgroundColor: "#75A478",
                                color: "white",
                                margin: "6px",
                            }}>
                            Delete
                        </Button>
                    </Link>
                </DialogActions>
            </Dialog>

            {/* Dialog Box to view review details*/}
            <div className="Container">
                <Dialog
                    maxWidth="lg"
                    fullWidth
                    open={open}
                    onClose={handleClose}
                    // aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <DialogContent>
                        {/* <DialogContentText id="alert-dialog-description"> */}
                        <Typography variant="h6" style={{ textAlign: "center" }}>
                            <b> Review Information{" "}</b>
                            <Divider style={{ width: "25%", margin: "auto" }} />
                            {/* <br /> */}
                        </Typography>

                        <Grid container spacing={24} style={{ padding: "2em" }}>
                            <Grid item xs={12} sm={5}>
                                <div>
                                    <label>Review Id</label>
                                    <TextField
                                        disabled
                                        id="standard-disabled"
                                        value={info.reviewId}
                                        variant="standard"
                                        fullWidth
                                    />
                                </div>
                            </Grid>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <Grid item xs={12} sm={5}>
                                <div>
                                    <label>Recruiter Name</label>
                                    <TextField
                                        disabled
                                        id="standard-disabled"
                                        value={info.recruiterName}
                                        variant="standard"
                                        fullWidth
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={5}>
                                <div>
                                    <label>Recruiter Email</label>
                                    <TextField
                                        disabled
                                        id="standard-disabled"
                                        value={info.recruiterEmail}
                                        variant="standard"
                                        fullWidth
                                    />
                                </div>
                            </Grid>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <Grid item xs={12} sm={5}>
                                <div>
                                    <label>Screener Name</label>
                                    <TextField
                                        disabled
                                        id="standard-disabled"
                                        value={info.screenerName}
                                        variant="standard"
                                        fullWidth
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={5}>
                                <div>
                                    <label>Screener Id</label>
                                    <TextField
                                        disabled
                                        id="standard-disabled"
                                        value={info.screenerId}
                                        variant="standard"
                                        fullWidth
                                    />
                                </div>
                            </Grid>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <Grid item xs={12} sm={5}>
                                <div>
                                    <label>Comment</label>
                                    <div>
                                        <TextareaAutosize
                                            disabled
                                            maxRows={5}
                                            value={info.comment}
                                            variant="standard"
                                            style={{ width: "100%" }}
                                        // style={{ width: "auto" }}
                                        />
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={5}>
                                <div>
                                    <label>Rating</label>
                                    <TextField
                                        disabled
                                        id="standard-disabled"
                                        value={info.rating}
                                        variant="standard"
                                        fullWidth
                                    />
                                </div>
                            </Grid>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <br />
                        </Grid>
                        {/* </DialogContentText> */}
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={handleClose}
                            style={{ backgroundColor: "#D73B30", color: "white" }}
                        >
                            Back
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* Add review  */}
                <div>
                    <Link to="/addReview">Add Review</Link>
                </div>
            </div>
            <br />

            {/* Display the Review List */}
            <div className="myList">
                <DataGrid
                    rows={rows}
                    columns={columns}
                    pageSize={10}
                    disableSelectionOnClick
                    className="tableList"
                    autoHeight
                />
            </div>
            <br />
        </>
    );
}


ReviewList.propTypes = {
    auth: PropTypes.object.isRequired,
};

const msp = (state) => ({
    auth: state.auth,
});


export default (connect(msp)(ReviewList));