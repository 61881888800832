const timeConvertor = (hour, minutes) => {
    let newTime;
    if (hour > 12) {
        let standardTime = 0 + (hour - 12);
        newTime = " " + standardTime + ":" + minutes + " PM";
        return newTime;
    } else {
        return "" + hour + " AM";
    }
};

export default timeConvertor;
