import axios from 'axios'
import React, { useEffect, useState } from 'react';
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import ExperienceIcon from '@mui/icons-material/Work';
import SalaryIcon from '@mui/icons-material/AccountBalanceWallet';
import LocationIcon from '@mui/icons-material/LocationOn';
import ShareIcon from '@mui/icons-material/Share';
import EmailIcon from '@mui/icons-material/Email';
import FavoriteIcon from '@mui/icons-material/Star';
import ScheduleIcon from '@mui/icons-material/Schedule';
import ViewIcon from '@mui/icons-material/Visibility';
import ResumeIcon from '@mui/icons-material/Description';
import ReactPaginate from 'react-paginate'
import { CircularProgress } from '@material-ui/core';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { CircularProgressbar, CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import Highlighter from "react-highlight-words";

import UserSnackbar from '../../snackBars/UserSnackbar';
import NothingToShow from '../images/nothingtoshow.svg';

const SearchResults = ({
    firstName,
    lastName,
    email,
    gender,
    fromExperience,
    toExperience,
    salary,
    payType,
    stream,
    skillsList,
    designation,
    company,
    country,
    city,
    state,
    isLoading,
    users,
    length,
    roleFilter,
    currentPage,
    handlePageChange,
    setRightSidebarOpen,
    rightSidebarOpen,
    setScheduleClicked,
    setCandidateName,
    setScreenerName,
    setScreenerDetails,
    setCandidateDetails,
    setUsers,
    setOpen,
    highlight,
    filterPrevState }) => {

    //company id
    const id = sessionStorage.getItem("id")
    const token = sessionStorage.getItem("jwtToken")

    //filtered users array length
    const resLength = users.length
    let profileAvatar = "https://res.cloudinary.com/df2qsnyox/image/upload/v1681133496/Default%20Profile%20Avatar/profile-avatar_ogxozq_ranghl.png";

    //pagination
    // const [currentPage, setCurrentPage] = useState(0);
    const usersPerPage = 10;
    const pageCount = Math.ceil(users.length / usersPerPage);
    const offset = currentPage * usersPerPage;
    //snackbar
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [message, setMessage] = useState("")

    //loader
    const [favLoader, setFavLoader] = useState({});

    const generateYears = (from, to) => {
        var a = [];
        for (var i = from; i <= to; i++) {
            a.push(i);
        }
        return a;
    }


    //favoriteHandler
    const favoriteHandler = (item) => {
        setFavLoader({ ...favLoader, [item._id]: true });
        const newData = {
            favorite: !item.isFavorite
        }
        axios.post(`/api/admin/internal-users/favorite/${roleFilter}/${id}/${item._id}`, newData).then((res) => {
            axios.get(`/api/admin/${roleFilter}s/internal-${roleFilter}s/${id}`).then(res => {
                // setFavorite(prev => !prev)
                let updatedData = [];
                users.filter(item => {
                    res.data.some(ele => {
                        if (item._id === ele._id) {
                            updatedData = [...updatedData, ele]
                        }
                    })
                })
                setUsers(updatedData)
                setFavLoader({ ...favLoader, [item._id]: false });

            })
            setMessage(res.data.message);
            setOpenSnackbar(true)
        }).catch(err => {
            console.log(err)
        })
    }

    function debugBase64(base64URL) {
        var win = window.open();
        win.document.write('<iframe src="' + base64URL + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>');
    }

    const copyTextToClipboard = async (text) => {
        try {
            await navigator.clipboard.writeText(text)
            setMessage("Email copied to clipboard")
            setOpenSnackbar(true)
        } catch (err) {
            setMessage("Failed to copy Email")
            setOpenSnackbar(true)
        }
    }


    //function to scroll to top on changing page number
    const scrollFunction = () => {
        const scrollStep = -window.scrollY / (150); // 500ms to reach top
        const scrollInterval = setInterval(() => {
            if (window.scrollY !== 0) {
                window.scrollBy(0, scrollStep);
            } else {
                clearInterval(scrollInterval);
            }
        }, 0); // 15ms per frame
    }

    const handleScheduleInterview = (firstName, lastName, email, id) => {
        const fullName = firstName + " " + lastName
        setRightSidebarOpen(true)
        setOpen(false)
        setScheduleClicked(true)
        if (roleFilter === 'candidate') {
            setCandidateName(fullName)
            setCandidateDetails({
                name: fullName,
                email,
                id
            })
        }
        else {
            setScreenerName(fullName)
            setScreenerDetails({
                name: fullName,
                email,
                id
            })
        }
    }

    //useEffect to set current Page 
    useEffect(() => {
        scrollFunction()
    }, [currentPage])


    //profile view handler
    const viewProfileHandler = (item) => {
        localStorage.setItem("id", id)
        localStorage.setItem("jwtToken", token);
    }

    return (
        <>
            {isLoading ?
                <div style={{
                    width: " 675px",
                    height: "60vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}
                    className="loader-icon">
                    <CircularProgress size="3.5rem" />
                </div>
                : users.length !== 0 ?
                    <div>
                        <div style={{
                            width: "80%", minWidth: `${rightSidebarOpen ? "550px" : " 675px"}`, marginLeft: `${rightSidebarOpen ? "0px" : "40px"}`
                        }}>
                            <p style={{ fontSize: '12px', textAlign: "end" }}>About <span style={{ color: "#f06161", fontWeight: 'bold' }}>{resLength}</span> out of {length} results</p>
                        </div>
                        <div style={{ display: 'flex', flexDirection: "column", alignItems: 'center', scrollBehavior: 'smooth', width: `${rightSidebarOpen ? "50%" : "85%"}`, minWidth: `${rightSidebarOpen ? "550px" : " 675px"}`, marginLeft: `${rightSidebarOpen ? "0px" : "40px"}` }}>
                            {users.slice(offset, offset + usersPerPage).map((item, index) => {
                                const sortedExperience = [...item.job_experience].sort(
                                    (a, b) => b.startYear - a.startYear

                                );
                                const sortedEducation = [...item.educational_details].sort(
                                    (a, b) => b.startYear - a.startYear
                                )

                                return <Card
                                    id="skills-edit"

                                    style={{
                                        width: "80%", minWidth: `${rightSidebarOpen ? "550px" : "675px"}`, margin: `${rightSidebarOpen ? "0px 0px 40px 0px" : "0px 70px 40px 40px"}`, right: "95px", borderRadius: "10px",
                                        boxShadow: "0 0 0 rgb(0 0 0 / 25%), 0px 2px 6px rgb(0 0 0 / 22%)",
                                        fontFamily: "Raleway",
                                    }}
                                >

                                    <Grid
                                        container
                                        spacing={0}
                                        direction="row"
                                        className="add-recruiter-card-container"
                                        sx={{ position: "relative" }}

                                    >
                                        {item.matchPercentage && <div style={{ position: 'absolute', top: '10px', left: '10px' }}>
                                            <div style={{ width: 35, height: 35, color: "#f06161" }}>
                                                <CircularProgressbarWithChildren value={item.matchPercentage} strokeWidth={4}
                                                >
                                                    <div style={{ fontSize: 9.5, marginTop: -1, textAlign: 'center' }}>
                                                        <strong>{item.matchPercentage}%</strong>
                                                    </div>
                                                </CircularProgressbarWithChildren>
                                            </div>
                                        </div>}

                                        {/* <div style={{ position: 'absolute', top: '5px', left: '5px' }}>{item.matchPercentage && <p style={{ fontSize: '12px', color: 'white', backgroundColor: '#f06161', borderRadius: '15px', padding: '2px 7px' }}>{item.matchPercentage}% match</p>}</div> */}
                                        {/* <div style={{ position: 'absolute', top: '5px', left: '5px' }}>{item.matchPercentage && <div style={{ width: 43, height: 43 }}>
                                            <CircularProgressbar value={item.matchPercentage} text={`${item.matchPercentage}% 
                                        `} /><br/><span>match</span></div>}</div> */}

                                        <Grid
                                            item
                                            xs={5}
                                            md={10}
                                            lg={10}
                                            className="container-heading-title"
                                            sx={{ margin: "15px" }}
                                        >

                                            <Grid
                                                container
                                                spacing={0}
                                                direction="row"

                                            >
                                                <Grid
                                                    item
                                                    xs={5}
                                                    md={4}
                                                    lg={4}
                                                    sx={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        flexDirection: "column",
                                                        marginTop: "10px"
                                                    }}
                                                >
                                                    {/* <div>{item.matchPercentage && <p style={{ fontSize: '12px', color: 'white', backgroundColor: '#f06161', borderRadius: '15px', padding: '2px 7px' }}>{item.matchPercentage}% match</p>}</div> */}
                                                    <img
                                                        style={{ objectFit: "cover", height: "50px", width: "50px", borderRadius: "100%" }}
                                                        src={
                                                            item.defaultAvatar
                                                                ? profileAvatar
                                                                : item.interacted
                                                                    ? item.croppedImage
                                                                    : item.profileImage
                                                        }
                                                    />
                                                    <div className='personal-info-container'>
                                                        <h6 style={{ textAlign: "center", marginBottom: "10px" }}>
                                                            {highlight ? (<> <Highlighter highlightClassName="highlighter" textToHighlight={item.firstName} searchWords={[`${firstName}`]} /> &nbsp;
                                                                <Highlighter highlightClassName="highlighter" textToHighlight={item.lastName} searchWords={[`${lastName}`]} /></>) :
                                                                (
                                                                    <>   <Highlighter highlightClassName="highlighter" textToHighlight={item.firstName} searchWords={[`${filterPrevState.personaldetails.firstName}`]} /> &nbsp;
                                                                        <Highlighter highlightClassName="highlighter" textToHighlight={item.lastName} searchWords={[`${filterPrevState.personaldetails.lastName}`]} />
                                                                    </>
                                                                )}

                                                        </h6>
                                                        <p>
                                                            {highlight ? (<Highlighter highlightClassName="highlighter" textToHighlight={item.profileTitle} searchWords={[`${designation}`]} />) : (
                                                                <Highlighter highlightClassName="highlighter" textToHighlight={item.profileTitle} searchWords={[`${filterPrevState.designation}`]} />)}
                                                        </p>
                                                        <p>{item.primaryPhone}</p>
                                                        {item.email.length > 20 ? (
                                                            <Tooltip title={<>{item.email} <ContentCopyIcon fontSize='small' sx={{ fontSize: '14px', cursor: 'pointer' }} onClick={() => copyTextToClipboard(item.email)} /></>}>
                                                                <p>{highlight ? (<Highlighter highlightClassName="highlighter" textToHighlight={item.email} searchWords={[`${email}`]} />) :
                                                                    (<Highlighter highlightClassName="highlighter" textToHighlight={item.email} searchWords={[`${filterPrevState.personaldetails.email}`]} />)}</p>
                                                            </Tooltip>
                                                        ) : (
                                                            <p>{highlight ? (<Highlighter highlightClassName="highlighter" textToHighlight={item.email} searchWords={[`${email}`]} />) :
                                                                (<Highlighter highlightClassName="highlighter" textToHighlight={item.email} searchWords={[`${filterPrevState.personaldetails.email}`]} />)}</p>
                                                        )}
                                                    </div>
                                                </Grid>


                                                <Grid
                                                    item
                                                    xs={5}
                                                    md={8}
                                                    lg={8}
                                                    sx={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        marginTop: "10px"
                                                    }}
                                                >
                                                    <div className='top-section'>
                                                        <div>
                                                            {item.totalExperience ?
                                                                <>
                                                                    <ExperienceIcon
                                                                        fontSize='small'
                                                                        style={{ height: "0.7em", width: "0.8em", color: "#2c3e50" }}
                                                                    />
                                                                    <p>{highlight ? (<Highlighter highlightClassName="highlighter" textToHighlight={item.totalExperience + ' year(s)'} searchWords={generateYears(fromExperience, toExperience)} />)
                                                                        : (
                                                                            <Highlighter highlightClassName="highlighter" textToHighlight={item.totalExperience + ' year(s)'} searchWords={generateYears(filterPrevState.experience.from, filterPrevState.experience.to)} />
                                                                        )}</p>
                                                                </>
                                                                : ""
                                                            }
                                                        </div>

                                                        <div>
                                                            {item.salaryDetail.salary ?
                                                                item.salaryDetail.salary === 'As per industry standards' ?
                                                                    <>
                                                                        <SalaryIcon
                                                                            fontSize='small'
                                                                            style={{ height: "0.7em", width: "0.8em", color: "#2c3e50" }}
                                                                        />
                                                                        <p>{highlight ? (<Highlighter highlightClassName="highlighter" textToHighlight={item.salaryDetail.salary} searchWords={[`${salary}`]} />) : (
                                                                            <Highlighter highlightClassName="highlighter" textToHighlight={item.salaryDetail.salary} searchWords={[`${filterPrevState.expectedSalary.salary}`]} />
                                                                        )}</p>

                                                                    </>
                                                                    : <>
                                                                        <SalaryIcon
                                                                            fontSize='small'
                                                                            style={{ height: "0.7em", width: "0.8em", color: "#2c3e50" }}
                                                                        />
                                                                        <p>
                                                                            {highlight ? (<>
                                                                                <Highlighter highlightClassName="highlighter" textToHighlight={item.salaryDetail.salary + '/'} searchWords={[`${salary}`]} />
                                                                                <Highlighter highlightClassName="highlighter" textToHighlight={item.salaryDetail.payType} searchWords={[`${payType}`]} /></>)
                                                                                : (
                                                                                    <> <Highlighter highlightClassName="highlighter" textToHighlight={item.salaryDetail.salary + '/'} searchWords={[`${filterPrevState.expectedSalary.salary}`]} />
                                                                                        <Highlighter highlightClassName="highlighter" textToHighlight={item.salaryDetail.payType} searchWords={[`${filterPrevState.expectedSalary.payType}`]} /></>
                                                                                )}

                                                                        </p>
                                                                    </>

                                                                : ""
                                                            }
                                                        </div>

                                                        <div>
                                                            <LocationIcon
                                                                fontSize='small'
                                                                style={{ height: "0.8em", width: "0.8em", color: "#2c3e50" }}
                                                            />
                                                            <p>
                                                                {highlight ? (<>
                                                                    <Highlighter highlightClassName="highlighter" textToHighlight={item.personal_details.location?.country + ", "} searchWords={[`${country?.label}`]} />
                                                                    <Highlighter highlightClassName="highlighter" textToHighlight={item.personal_details.location?.state + ", "} searchWords={[`${state?.label}`]} />
                                                                    <Highlighter highlightClassName="highlighter" textToHighlight={item.personal_details.location?.city} searchWords={[`${city?.label}`]} />
                                                                </>) : (
                                                                    <>
                                                                        <Highlighter highlightClassName="highlighter" textToHighlight={item.personal_details.location?.country + ", "} searchWords={[`${filterPrevState.location.country?.label}`]} />
                                                                        <Highlighter highlightClassName="highlighter" textToHighlight={item.personal_details.location?.state + ", "} searchWords={[`${filterPrevState.location.state?.label}`]} />
                                                                        <Highlighter highlightClassName="highlighter" textToHighlight={item.personal_details.location?.city} searchWords={[`${filterPrevState.location.city?.label}`]} />
                                                                    </>
                                                                )}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className='profile-info card-content'>
                                                        <table>

                                                            {sortedExperience[0]?.company ?

                                                                <tr>
                                                                    <td style={{ width: "10px" }}>
                                                                        <p style={{ color: "#283848" }}><strong>Current</strong></p>
                                                                    </td>
                                                                    <td >
                                                                        <p>{sortedExperience[0].position + " at "}
                                                                            {highlight ? (<Highlighter highlightClassName="highlighter" textToHighlight={sortedExperience[0].company} searchWords={[`${company}`]} />) : (
                                                                                <Highlighter highlightClassName="highlighter" textToHighlight={sortedExperience[0].company} searchWords={[`${filterPrevState.company}`]} />
                                                                            )}
                                                                        </p>
                                                                    </td>
                                                                </tr>

                                                                : <tr>
                                                                    <td style={{ width: "10px" }}>
                                                                        <p style={{ color: "#283848" }}><strong>Current</strong></p>
                                                                    </td>
                                                                    <td>
                                                                        <p>
                                                                        </p>
                                                                    </td>
                                                                </tr>
                                                            }

                                                            {sortedExperience[1]?.company ?

                                                                <tr>
                                                                    <td style={{ width: "10px" }}>
                                                                        <p style={{ color: "#283848" }}><strong>Previous</strong></p>
                                                                    </td>
                                                                    <td>
                                                                        <p>{sortedExperience[1].position + " at "}
                                                                            <Highlighter highlightClassName="highlighter" textToHighlight={sortedExperience[1].company} searchWords={[`${company}`]} />
                                                                        </p>
                                                                    </td>
                                                                </tr>

                                                                : <tr>
                                                                    <td style={{ width: "10px" }}>
                                                                        <p style={{ color: "#283848" }}><strong>Previous</strong></p>
                                                                    </td>
                                                                    <td>
                                                                        <p>
                                                                        </p>
                                                                    </td>
                                                                </tr>
                                                            }

                                                            {sortedEducation[0].degree ?

                                                                <tr>
                                                                    <td style={{ width: "10px" }}>
                                                                        <p style={{ color: "#283848" }}><strong>Education</strong></p>
                                                                    </td>
                                                                    <td>
                                                                        <p>{sortedEducation[0].degree + " in "}
                                                                            {highlight ? (<Highlighter highlightClassName="highlighter" textToHighlight={sortedEducation[0].stream} searchWords={[`${stream}`]} />) :
                                                                                (<Highlighter highlightClassName="highlighter" textToHighlight={sortedEducation[0].stream} searchWords={[`${filterPrevState.highesteducation.stream}`]} />)}
                                                                        </p>

                                                                    </td>
                                                                </tr>

                                                                : <tr>
                                                                    <td style={{ width: "10px" }}>
                                                                        <p style={{ color: "#283848" }}><strong>Education</strong></p>
                                                                    </td>
                                                                    <td>
                                                                        <p>
                                                                        </p>

                                                                    </td>
                                                                </tr>
                                                            }

                                                            {item.skills.length > 0 ?
                                                                <tr>
                                                                    <td style={{ width: "10px" }}>
                                                                        <p style={{ color: "#283848" }}><strong>Skills</strong></p>
                                                                    </td>
                                                                    <td><p>{item.skills?.map((skill, ind) => {
                                                                        const { skillName } = skill;
                                                                        if (highlight) {
                                                                            if (ind !== item.skills.length - 1) {
                                                                                return <Highlighter highlightClassName="highlighter" textToHighlight={skillName + " | "} searchWords={skillsList} />
                                                                            } else return <Highlighter highlightClassName="highlighter" textToHighlight={skillName} searchWords={skillsList} />

                                                                        } else {
                                                                            if (ind !== item.skills.length - 1) {
                                                                                return <Highlighter highlightClassName="highlighter" textToHighlight={skillName + " | "} searchWords={filterPrevState.skills} />
                                                                            } else return <Highlighter highlightClassName="highlighter" textToHighlight={skillName} searchWords={filterPrevState.skills} />
                                                                        }

                                                                    })}</p></td>
                                                                </tr>
                                                                :
                                                                <tr>
                                                                    <td style={{ width: "10px" }}>
                                                                        <p style={{ color: "#283848" }}><strong>Skills</strong></p>
                                                                    </td>
                                                                    <td><p></p></td>
                                                                </tr>
                                                            }

                                                            {item.personal_details.dob ?
                                                                <tr>
                                                                    <td style={{ width: "10px" }}>
                                                                        <p style={{ color: "#283848" }}><strong>DOB</strong></p>
                                                                    </td>
                                                                    <td><p>

                                                                        {item.personal_details.dob}</p></td>
                                                                </tr>
                                                                : <tr>
                                                                    <td style={{ width: "10px" }}>
                                                                        <p style={{ color: "#283848" }}><strong>DOB</strong></p>
                                                                    </td>
                                                                    <td>
                                                                        <p>
                                                                        </p>
                                                                    </td>
                                                                </tr>
                                                            }

                                                            {item.personal_details.gender ?
                                                                <tr>
                                                                    <td style={{ width: "10px" }}>
                                                                        <p style={{ color: "#283848" }}><strong>Gender</strong></p>
                                                                    </td>
                                                                    <td><p>{highlight ? (<Highlighter highlightClassName="highlighter" textToHighlight={item.personal_details.gender} searchWords={[`${gender}`]} />) :
                                                                        (<Highlighter highlightClassName="highlighter" textToHighlight={item.personal_details.gender} searchWords={[`${filterPrevState.personaldetails.gender}`]} />)}
                                                                    </p></td>
                                                                </tr>
                                                                :
                                                                <tr>
                                                                    <td style={{ width: "10px" }}>
                                                                        <p style={{ color: "#283848" }}><strong>Gender</strong></p>
                                                                    </td>
                                                                    <td><p></p></td>
                                                                </tr>
                                                            }
                                                        </table>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid
                                            item
                                            xs={5}
                                            md={1}
                                            lg={1}
                                            className="side-icon-list"
                                        >
                                            <Tooltip title="Share" placement='right-start'>
                                                <ShareIcon fontSize='small' />
                                            </Tooltip>

                                            <Tooltip title="Send Mail" placement='right-start'>
                                                <a href={`mailto: ${item.email}`}>
                                                    <EmailIcon fontSize='small' />
                                                </a>
                                            </Tooltip>

                                            <Tooltip title={item.isFavorite ? "Remove Favorites" : "Add to Favorites"} placement='right-start'>
                                                {favLoader[item._id] ? <CircularProgress style={{ width: "unset", height: "unset" }} /> : <FavoriteIcon style={{ color: item.isFavorite ? "#f06161" : null }} fontSize="small" onClick={() => favoriteHandler(item)} />}
                                            </Tooltip>

                                            <Tooltip title="View Profile" placement='right-start'>

                                                <a href={`/admin/internal-db/profile/${roleFilter}/${item._id}/${id}`} target="_blank" >
                                                    <ViewIcon fontSize='small' onClick={() => viewProfileHandler(item)} />
                                                </a>
                                            </Tooltip>

                                            <Tooltip title={item.resume ? "Preview Resume" : 'No resume'} placement='right-start'>
                                                <ResumeIcon style={{ padding: 0 }} fontSize='small' onClick={() => { item.resume && debugBase64(item.resume) }} className={!item.resume && "disabled-icon-resume"} />
                                            </Tooltip>
                                            <Tooltip title='Schedule Interview' placement='right-start'>
                                                <ScheduleIcon style={{ padding: 0 }} fontSize='small' onClick={() => handleScheduleInterview(item.firstName, item.lastName, item.email, item._id)} />
                                            </Tooltip>
                                        </Grid>

                                    </Grid>


                                </Card>

                            })}

                            {/* React Pagination  */}

                            <ReactPaginate

                                nextLabel={<ArrowForwardIosIcon style={{ fontSize: 13, width: 13 }} />}
                                onPageChange={handlePageChange}
                                pageRangeDisplayed={2}
                                marginPagesDisplayed={2}
                                pageCount={pageCount}
                                previousLabel={<ArrowBackIosIcon style={{ fontSize: 13, width: 13 }} />}
                                pageClassName="page-item"
                                forcePage={currentPage}
                                pageLinkClassName="page-link"
                                previousClassName="page-item"
                                previousLinkClassName="page-link"
                                nextClassName="page-item"
                                nextLinkClassName="page-link"
                                breakLabel="..."
                                breakClassName="page-item"
                                breakLinkClassName="page-link"
                                containerClassName="pagination"
                                activeClassName="active"
                                renderOnZeroPageCount={null}
                            />
                        </div>
                    </div>
                    : <div style={{ width: "675px", height: "550px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Card
                            id="skills-edit"

                            style={{
                                minWidth: "300px", height: "70%", borderRadius: "10px",
                                boxShadow: "none",
                                fontFamily: "Raleway", backgroundColor: "#faf9f6"
                            }}
                        >
                            <Grid
                                container
                                spacing={0}
                                direction="row"
                                className="add-recruiter-card-container"
                                style={{ justifyContent: "center" }}
                            >
                                <Grid
                                    item
                                    xs={5}
                                    md={10}
                                    lg={10}
                                    className="container-heading-title"
                                    sx={{ margin: "15px", display: "flex", justifyContent: "center" }}
                                >
                                    <img src={NothingToShow} alt="Result Not found" style={{ width: "400px" }} />
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                spacing={0}
                                direction="row"
                                className="add-recruiter-card-container"
                                style={{ justifyContent: "center" }}
                            >
                                <Grid
                                    item
                                    xs={5}
                                    md={10}
                                    lg={10}
                                    className="container-heading-title"
                                    sx={{ margin: "15px", display: "flex", flexDirection: "column", alignItems: "center" }}
                                >
                                    <h5>Nothing to show!</h5>
                                    {<p style={{ width: "max-content" }}>Oops!...It looks like you haven't applied suitable filters.</p>}
                                </Grid>
                            </Grid>
                        </Card>
                    </div>
            }

            {/* Snackbar modal */}
            <UserSnackbar
                openSnackbar={openSnackbar}
                setOpenSnackbar={setOpenSnackbar}
                message={message}
            />

        </>

    )
}



export default SearchResults