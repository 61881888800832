import withStyles from "@material-ui/core/styles/withStyles";
import { withSnackbar } from "notistack";
import PropTypes from "prop-types";
import React, { Component } from "react";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { connect } from "react-redux";
import { calendarCardStyle, cardStyles } from "../calendar/calendarStyle";
import CandidateRowOne from "./candidateHomeRows/candidateRowOne";
import CandidateRowTwo from "./candidateHomeRows/candidateRowTwo";
import CandidateSidebar from "./candidateHomeRows/candidateSidebar";

const styles = (theme) => ({
    ratingWrapper: {
        padding: "16px",
    },
    iconButton: {
        padding: "17px",
    },
});

class candidateHome extends Component {
    // componentDidMount() {
    //     const { sidebar, availabilities } = this.props;
    //     // console.log(sidebar);
    //     // if (sidebar.profileCompletionPercentage < 100) {
    //     //     this.props.enqueueSnackbar("Please update your profile, it has not been completed", {
    //     //         variant: "warning",
    //     //         persist: true,
    //     //     });
    //     // }

    //     // if (availabilities.length <= 0) {
    //     //     // console.log(availabilities);
    //     //     this.props.enqueueSnackbar("Navigate to the Calendar to send your availability schedule to recruiters", {
    //     //         variant: "info",
    //     //         autoHideDuration: 3000,
    //     //     });
    //     // }
    // }

    componentDidUpdate(prevProps) {
        const { sidebar, availabilities } = this.props;
        if (sidebar !== prevProps.sidebar) {
            // console.log(sidebar);
            if (sidebar.profileCompletionPercentage < 100) {
                this.props.enqueueSnackbar("Please update your profile, it has not been completed", {
                    variant: "warning",
                    autoHideDuration: 3000,
                });
            }

            if (availabilities.length <= 0) {
                // console.log(availabilities);
                this.props.enqueueSnackbar("Navigate to the Calendar to send your availability schedule to recruiters", {
                    variant: "info",
                    autoHideDuration: 3000,
                });
            }
        }
    }

    render() {

        return (
            <div className="dashboard-component d-flex flex-row justify-content-around" id="dashboard-full-container" style={{padding:"130px"}}>
                <div className="flex-fill ml-4" id="dash-info-column">
                    <div className="d-flex flex-column justify-content-around" id="dash-info-container-candidate">
                        <div className="flex-fill ml-5" id="dash-sidebar">
                            <CandidateSidebar user={this.props.auth.user} />
                        </div>

                        <div className="flex-fill mt-4" id="dash-row-1">
                            <CandidateRowOne cardStyle={calendarCardStyle} />
                        </div>
                        {/* <div className="flex-fill mt-4" id="dash-row-2">
                            <CandidateRowTwo cardStyle={cardStyles} />
                        </div> */}

                    </div>
                </div>


            </div>
        );
    }
}

candidateHome.propTypes = {
    auth: PropTypes.object.isRequired,
    availabilities: PropTypes.array.isRequired,
    sidebar: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    sidebar: state.sidebar,
    availabilities: state.availabilities,
});

export default withSnackbar(withStyles(styles)(connect(mapStateToProps)(candidateHome)));
