// import { faEdit } from '@fortawesome/free-solid-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import Avatar from '@material-ui/core/Avatar';
// import Button from '@material-ui/core/Button';
// import Card from '@material-ui/core/Card';
// import Grid from '@material-ui/core/Grid';
// import withStyles from '@material-ui/core/styles/withStyles';
// import Typography from '@material-ui/core/Typography';
// import { withSnackbar } from 'notistack';
// import PropTypes from 'prop-types';
// import React, { Component } from 'react';
// import { connect } from 'react-redux';
// import { updateProfileInformation } from '../../actions/userActions';
// // import { Link } from "react-router-dom";
// // import EditIcon from "@material-ui/icons/Edit";
// import ContactExpansion from './contactExpansion';
// import ProfessionalDetailsColTwo from './ProfessionalDetailsColTwo';
// import ProfessionalDetailsExpansion from './professionalDetailsExpansion';
// import ProfessionalExpansion from './professionalExpansion';
// import ContactExpansionEdit from './profileEdit/contactExpansionEdit';
// import ProfesionalDetailEdit from './profileEdit/professionalDetailEdit';
// import ProfessionalExpansionEdit from './profileEdit/professionalExpansionEdit';
// //import {updateProfileInformation} from"../../actions/userActions";
// //import { withSnackbar } from "notistack";
// import PhoneIcon from '@material-ui/icons/Phone';
// import WorkIcon from '@material-ui/icons/Work';
// import PaymentDetailEdit from './profileEdit/PaymentDetailEdit';

// import AccountCircleIcon from '@material-ui/icons/AccountCircle';

// const styles = (theme) => ({
//   bigAvatar: {
//      width: '120px',
//      height: '120px',
//     // borderRadius: '25px',
//     opacity: '100% ',
//     position:"inherit",
//     background:'#75a478'
//     // border: '3px solid #A5D6A7',

//   },
//   imageOverlay: {
//     marginBottom: '-135px',
//   },
//   card: {
//     background: '#FFFFFF 0% 0% no-repeat padding-box',
//     border: '3px solid #A5D6A7',
//     borderRadius: '10px',
//     padding: '100px',
//     paddingTop: '40px'
//   },
//   detail: {
//     fontSize: '1.0rem',
//   },
//   detailLabel: {
//     fontSize: '1.0rem',
//   },
//   button: {
//     padding: theme.spacing(2),
//     margin: 'auto',
//     align: 'center',
//   },
// });

// class ProfilePage extends Component {
//   constructor(props) {
//     super(props);

//     this.state = {
//       profileEdit: false,
//       contact_info: {
//         name: '',
//         email: '',
//         uniqueMatchId: '',
//         secondaryemail: '',
//         dob: new Date(),
//         phone: '',
//         secondaryPhone: '',
//         address: {
//           address_line1: '',
//           address_line2: '',
//           city: '',
//           state: '',
//           postalCode: '',
//           country: '',
//         },
//       },
//       professional_details: {
//         currentTitle: '',
//         highestDegree: '',
//         college: '',
//         bio: '',
//         yearsOfExperience: '',
//         englishProficiency: '',
//         interviewRate: '',
//         major: '',
//       },
//       payment_info: {
//         payPalId: '',
//         payPalFirstName: '',
//         payPalLastName: '',
//       },
//       skills: {
//         skillSet: [['', '']],
//         skills: [],
//       },
//       role: '',
//       expertise: [],
//       agreement: false,
//       profileCompletionPercentage: 0,
//       candidate_info: {
//         preferredPosition: '',
//       },
//     };

//     this.handleProfileEdit = this.handleProfileEdit.bind(this);
//   }

//   componentDidMount() {
//     const { user } = this.props;
//     console.log(user._id);
//     console.log(user)
//     let onlyScreenerInfo = {
//       expertise: [],
//       interviewRate: '',
//       payPalId: '',
//       payPalFirstName: '',
//       payPalLastName: '',
//     };
//     //  console.log(user.screener_info)
//     if (!(user.screener_info === null)) {
//       // console.log("its coming here")
//       onlyScreenerInfo = user.screener_info;
//     }
//     console.log(user.screener_info);

//     //  console.log(user);
//     if (user.name !== undefined) {
//       this.setState({
//         contact_info: {
//           name: user.name,
//           email: user.email,
//           uniqueMatchId: user.uniqueMatchId,
//           secondaryemail: user.secondaryemail,
//           dob: user.dob,
//           phone: user.phone,
//           secondaryPhone: user.secondaryPhone,
//           address: {
//             address_line1: user.address.address_line1,
//             address_line2: user.address.address_line2,
//             city: user.address.city,
//             state: user.address.state,
//             postalCode: user.address.postalCode,
//             country: user.address.country,
//           },
//         },
//         professional_details: {
//           currentTitle: user.professional_details.currentTitle,
//           bio: user.professional_details.bio,
//           yearsOfExperience: user.professional_details.yearsOfExperience,
//           highestDegree: user.professional_details.highestDegree,
//           interviewRate: onlyScreenerInfo.interviewRate,
//           major: user.professional_details.major,
//         },
//         skills: {
//           skills: user.skills,
//           skillSet: user.skillSet,
//         },
//         payment_info: {
//           payPalId: onlyScreenerInfo.payPalId,
//           payPalFirstName: onlyScreenerInfo.payPalFirstName,
//           payPalLastName: onlyScreenerInfo.payPalLastName,
//         },
//         role: user.role,
//         expertise: onlyScreenerInfo.expertise,
//         agreement: user.agreement,
//         candidate_info: {
//           preferredPosition: user.candidate_info === null ? '' : user.preferredPosition,
//         },
//         profileCompletionPercentage: user.profileCompletionPercentage,
//       });
//     }
//   }

//   handleProfileEdit() {
//     const { profileEdit } = this.state;
//     this.setState({ profileEdit: !profileEdit });
//   }

//   handleChange = (input) => (event) => {
//     this.setState({ [input]: event.target.value });
//   };

//   handleDateChange = (input) => (date) => {
//     const objectNameArray = input.split('.');
//     const objectKey = objectNameArray[0];
//     const objectValue = objectNameArray[1];
//     const stateObject = this.state[objectKey];
//     stateObject[objectValue] = date;
//     this.setState({ [input]: stateObject });
//   };

//   handleProfessionalChange = (input) => (event) => {
//     event.preventDefault();
//     const objectNameArray = input.split('.');
//     const objectKey = objectNameArray[0];
//     const objectValue = objectNameArray[1];
//     const stateObject = this.state[objectKey];
//     stateObject[objectValue] = event.target.value;
//     this.setState({ [input]: stateObject });
//   };

//   handleAddressChange = (input) => (event) => {
//     event.preventDefault();
//     const objectNameArray = input.split('.');
//     const objectValue = objectNameArray[1];
//     const stateObject = this.state.contact_info.address;
//     stateObject[objectValue] = event.target.value;
//     this.setState({ [this.state.contact_info.address]: stateObject });
//   };

//   handlePaymentChange = (input) => (event) => {
//     event.preventDefault();
//     const objectNameArray = input.split('.');
//     const objectKey = objectNameArray[0];
//     const objectValue = objectNameArray[1];
//     const stateObject = this.state[objectKey];
//     stateObject[objectValue] = event.target.value;
//     this.setState({ [this.state.payment_info]: stateObject });
//   };

//   // handlePaymentChange = (input) => (event) => {
//   //   event.preventDefault();
//   //   // const { payPalId,payPalFirstName,payPalLastName } = this.state;
//   //   const {payment_info} = this.state;

//   //   payment_info[input] = event.target.value;
//   //   this.setState({
//   //      payment_info
//   //   });
//   // };

//   handleContactInfoChange = (input) => (event) => {
//     event.preventDefault();
//     const objectNameArray = input.split('.');
//     const objectValue = objectNameArray[1];
//     const stateObject = this.state.contact_info;
//     stateObject[objectValue] = event.target.value;
//     this.setState({ [this.state.contact_info]: stateObject });
//   };

//   checkRequiredFields(fieldName) {
//     if (fieldName === '') {
//       return 1;
//     } else {
//       return 0;
//     }
//   }
//   checkPhone(fieldName) {
//     if (fieldName === '' || fieldName.length < 13) {
//       return 1;
//     } else {
//       return 0;
//     }
//   }

//   onSubmit = (e) => {
//     let isState = this.checkRequiredFields(this.state['contact_info']['address'].state);
//     let isAddress = this.checkRequiredFields(this.state['contact_info']['address'].address_line1);
//     let isCountry = this.checkRequiredFields(this.state['contact_info']['address'].country);
//     let isCity = this.checkRequiredFields(this.state['contact_info']['address'].city);
//     let email = this.checkRequiredFields(this.state['contact_info'].email);
//     let phone = this.checkPhone(this.state['contact_info'].phone);
//     let name = this.checkRequiredFields(this.state['contact_info'].name);
//     let title = this.checkRequiredFields(this.state['professional_details'].currentTitle);

//     if (isState || isAddress || isCity || email || phone || name || title || isCountry) {
//       this.props.enqueueSnackbar('Please fill in the required fields', {
//         variant: 'warning',
//         autoHideDuration: 1000,
//       });
//     } else {
//       e.preventDefault();
//       let preNotifMessage = 'Please wait while we Update Your Profile!';
//       this.props.enqueueSnackbar(preNotifMessage, {
//         variant: 'default',
//         autoHideDuration: 1000,
//       });

//       this.props.updateUser(this.state, this.props.enqueueSnackbar);
//       this.setState({ profileEdit: false });
//     }
//   };

//   render() {
//     const { user, classes } = this.props;
//     // console.log(this.props)
//     const { profileEdit, contact_info, professional_details,payment_info  } = this.state;
//     const dob = new Date(contact_info.dob);
//     const address = contact_info.address;
//     var i = contact_info.name.indexOf(' ');
//     var firstName = contact_info.name.slice(0, i).trim();
//     var lastName = contact_info.name.slice(i + 1, contact_info.name.length).trim();

//     let lastnameupper = lastName.toUpperCase();
//     let firstnameupper = firstName.toUpperCase();

//     // console.log(this.state);
//     // let firstName = "";
//     // let initials = "";
//     // There are errors here when react first opens the page, this is because of the split functions on null variable instances. Need to create a ternary to bypass. Otherwise lookup further details on standard practice to stop this. possibly TypeScript.
//     // if (contact_info.name) {
//     //     firstName = contact_info.name.split(" ")[0];
//     //     initials = contact_info.name.slice(0, 1) + contact_info.name.split(" ")[1].slice(0, 1);
//     // }
//     return (
//       <div className="profile-component" style={{padding: "140px"}}>
//         <Grid container className={classes.imageOverlay} id="outside-img-container" direction="column" justify="center" alignItems="center">
//           <Avatar
//             alt="profile"
//             // src={profilePic}
//             className={`${classes.bigAvatar}`}
//             variant="circle"
//             // style= {{backgroundcolor:"black"}}

//           >
//           {/* <b><Typography
//            variant="h6"
//            style= {{color:"white"}}
//           >  */}
//           <h1> <b> {firstnameupper[0]+lastnameupper[0]}</b></h1>
//             {/* </Typography></b> */}

//           </Avatar>
//           <span className="headers">{contact_info.name}</span>
//         </Grid>

//         <Card className={classes.card}>
//           <div className="mart-100 d-flex flex-column p-2" id="card-parent-flex">

//               {/* <Typography variant="h6" align="left">
//                                 PROFESSIONAL DETAILS:
//                             </Typography> */}
//               {profileEdit === false ? (
//                 <div className="flex-fill mt-4 mb-4 d-flex flex-row">
//                 <ProfessionalExpansion user={this.state} dob={dob} classes={classes} />
//                 </div>
//               ) : (
//                 <div className="flex-fill mb-5">
//                 <ProfessionalExpansionEdit
//                   contact_info={contact_info}
//                   dob={dob}
//                   professional_details={professional_details}
//                   classes={classes}
//                   handleChange={this.handleChange}
//                   handleProfessionalChange={this.handleProfessionalChange}
//                   handleDateChange={this.handleDateChange}
//                 />
//                 </div>
//               )}

//             {/* <div className="flex-fill mt-4 mb-4" id="contact-detail-box"> */}

//               {profileEdit === false ? (
//                 <ContactExpansion user={this.state} address={address} />
//               ) : (
//                 <ContactExpansionEdit
//                   contact_info={contact_info}
//                   address={address}
//                   handleAddressChange={this.handleAddressChange}
//                   handleContactInfoChange={this.handleContactInfoChange}
//                   handleDateChange={this.handleDateChange}
//                 />
//               )}
//               {/* </div> */}

//               {/* <div className="flex-fill mt-4 mb-4 d-flex flex-row" id="professional-detail-box"> */}
//               {/* <div className="flex-fill"> */}

//                 {profileEdit === false ? (
//                   <div className="flex-fill mt-4 mb-4 d-flex flex-row">
//                   <ProfessionalDetailsExpansion screenr={this.state} />
//                   </div>
//                 ) : (
//                   <div className="flex-fill">
//                   <ProfesionalDetailEdit professional_details={professional_details} handleDetailChange={this.handleProfessionalChange} />
//                   </div>
//                 )}

//                 {/* {user.role === 'screener' ? (
//                   <ProfessionalDetailsColTwo payment_info={this.state.payment_info} expertise={this.state.expertise} />
//                 ) : null} */}
//                  {
//                    (() =>{
//                      if(user.role === 'screener'){
//                         if(profileEdit === false){
//                          return <ProfessionalDetailsColTwo payment_info={this.state.payment_info} expertise={this.state.expertise} />
//                          //return <ProfessionalDetailsColTwo user={this.state} expertise={this.state.expertise} />
//                         //  return <ProfessionalDetailsColTwo payPalId={this.state.payPalId} payPalFirstName ={this.state.payPalFirstName} payPalLastName={this.state.payPalLastName} expertise={this.state.expertise} />
//                         }else{

//                          return   <div className="flex-fill mb-5"> <PaymentDetailEdit  payment_info={payment_info} handlepaymentChange = {this.handlePaymentChange} />
//                           </div>
//                         }
//                      }
//                    })()
//                  }
//               {/* </div> */}

//           </div>

//           {profileEdit === false ? (
//              <div className="flex-fill mt-4 mb-4 d-flex flex-row">
//             <Button variant="contained" color="secondary" size="small" className={classes.button} onClick={this.handleProfileEdit}>
//               {/* <Button variant="contained" color="secondary" size="small" className={classes.button} href="/register-wizard"> */}
//               Edit Profile <FontAwesomeIcon icon={faEdit} style={{ marginLeft: '20px' }} />
//             </Button></div>
//           ) : (
//             <div>
//               <div className="float-right">
//                 <Button variant="contained" color="secondary" size="small" className={classes.button} onClick={this.onSubmit}>
//                   Save
//                 </Button>
//               </div>
//               <Button variant="contained" color="secondary" size="small" className={classes.button} onClick={this.handleProfileEdit}>
//                 Back
//               </Button>
//             </div>
//           )}
//         </Card>
//       </div>
//     );
//   }
// }

// ProfilePage.propTypes = {
//   user: PropTypes.object.isRequired,
// };

// const msp = (state) => ({
//   user: state.user,
// });

// const mdp = (dispatch) => ({
//   updateUser: (user, snackbar) => dispatch(updateProfileInformation(user, snackbar)),
// });

// export default withSnackbar(withStyles(styles)(connect(msp, mdp)(ProfilePage)));

import DateFnsUtils from "@date-io/date-fns";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from "@material-ui/core/TextField";
import Card from "@material-ui/core/Card";
import Divider from "@material-ui/core/Divider";
import axios from "axios";
import Loader from "../../Loader";
import {
  DatePicker,
  MuiPickersUtilsProvider,
  TimePicker,
} from "material-ui-pickers";
import PropTypes, { string } from "prop-types";
//import React, { Component } from "react";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { withSnackbar, useSnackbar } from "notistack";
import Rating from "material-ui-rating";
import { v4 as uuid } from "uuid";
import { addUserInformation } from "../../actions/userActions";
import { updateProfileInformation } from "../../actions/userActions";
import Avatar from "@material-ui/core/Avatar";
import React, { Fragment, useState, useEffect, Component } from "react";
// import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from "react-redux";
import {
  updateRecruiter,
  getRecruiterDetails,
  UPDATE_PROFILE_RESET,
} from "../../actions/userActions";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import PhoneIcon from "@material-ui/icons/Phone";
import dateFormat from "dateformat";
import WorkIcon from "@material-ui/icons/Work";
import PaymentIcon from "@material-ui/icons/Payment";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
//import axios from "axios";
//import { withSnackbar,useSnackbar } from "notistack";

const styles = (theme) => ({
  bigAvatar: {
    width: "120px",
    height: "120px",
    // borderRadius: '25px',
    opacity: "100% ",
    position: "inherit",
    background: "#75a478",
    // border: '3px solid #A5D6A7',
  },
  imageOverlay: {
    marginBottom: "-135px",
  },
  card: {
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "3px solid #A5D6A7",
    borderRadius: "10px",
    padding: "100px",
    paddingTop: "40px",
  },
  detail: {
    fontSize: "1.0rem",
  },
  detailLabel: {
    fontSize: "1.0rem",
  },
  button: {
    padding: theme.spacing(2),
    margin: "auto",
    align: "center",
    "&:hover": {
      color: "black",
    },
  },
});

class ProfilePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user_id: this.props.auth.user.id,
      recruiter: string,
      userList: [],
      profile: {},
      fullname: "",
      isLoading: true,
    };
  }

  componentDidMount() {
    const {
      auth: { user },
    } = this.props;
    const userId = user.id;
    console.log(this.state.user_id);
    axios.get("/api/users/users").then((response) => {
      this.setState({
        userList: response.data,
        isLoading: false,
      });
    });
    console.log(this.state.userList);
  }

  render() {
    var name = "",
      title = "",
      bio = "",
      title = "",
      dob = "",
      uniqueMatchId = "",
      companyID = "";
    var email = "",
      sec_email = "",
      phone = "",
      alternatephone = "",
      city = "",
      street_address1 = "",
      street_address2 = "",
      zipcode = "",
      state = "",
      country = "";
    var yearsOfExperience = "",
      highestDegree = "",
      major = "",
      skills = [],
      jobDuties = "";
    var payPalId = "",
      payPalFirstName = "",
      payPalLastName = "",
      expertise = [];
    const {
      auth: { user },
    } = this.props;
    const userId = user.id;
    console.log(userId);
    console.log(this.state.userList);
    this.state.userList.forEach((profile) => {
      if (profile._id === userId) {
        console.log(this.state.fullname);
        console.log(profile._id);
        console.log(profile.name);
        name = profile.name;
        email = profile.email;
        uniqueMatchId =
          user.role === "screener" || user.role === "candidate"
            ? profile.uniqueMatchId
            : null;

        companyID =
          user.role === "admin" || user.role === "recruiter"
            ? profile.companyID
            : null;
        title = profile.professional_details.currentTitle;
        bio = profile.professional_details.bio;
        dob = dateFormat(profile.dob, "mmmm dS, yyyy");
        sec_email = profile.secondaryemail;
        phone = profile.phone;
        alternatephone = profile.secondaryPhone;
        street_address1 = profile.address.address_line1;
        street_address2 = profile.address.address_line2;
        city = profile.address.city;
        state = profile.address.state;
        country = profile.address.country;
        zipcode = profile.address.postalCode;
        yearsOfExperience = profile.professional_details.yearsOfExperience;
        highestDegree = profile.professional_details.highestDegree;
        major = profile.professional_details.major;
        jobDuties = profile.professional_details.jobDuties;
        skills = profile.skillSet;

        console.log(profile.skills);
        console.log(skills);

        payPalId =
          user.role === "screener" ? profile.screener_info.payPalId : "";
        console.log(payPalId);
        payPalFirstName =
          user.role === "screener" ? profile.screener_info.payPalFirstName : "";
        payPalLastName =
          user.role === "screener" ? profile.screener_info.payPalLastName : "";
        expertise =
          user.role === "screener" ? profile.screener_info.expertise : "";
        console.log(expertise);
      }
    });
    console.log(skills);
    const { classes } = this.props;

    var i = name.indexOf(" ");
    var firstName = name.slice(0, i).trim();
    var lastName = name.slice(i + 1, name.length).trim();

    let lastnameupper = lastName.toUpperCase();
    let firstnameupper = firstName.toUpperCase();

    const paperStyle = { padding: 10, display: "flex", flexDirection: "row" };
    const divstyleleft = { width: "60%", float: "left" };
    const divstyleright = { width: "40%", float: "right" };
    if (this.state.isLoading) return <Loader />;
    else
      return (
        <>
          {/* <br/>   <br/>   <br/>   <br/>   <br/>   <br/>   <br/>
      <h2>Profile Detail</h2>
      <h1>Hello</h1>
     {userId}<br/> {name}<br/>{email} <br/>{title} */}
          <div className="profile-component" style={{ padding: "140px" }}>
            <Grid
              container
              className={classes.imageOverlay}
              id="outside-img-container"
              direction="column"
              justify="center"
              alignItems="center"
            >
              <Avatar
                alt="profile"
                // src={profilePic}
                className={`${classes.bigAvatar}`}
                variant="circle"
              // style= {{backgroundcolor:"black"}}
              >
                <h1>
                  {" "}
                  <b> {firstnameupper[0] + lastnameupper[0]}</b>
                </h1>
              </Avatar>
              <span className="headers">{name}</span>
            </Grid>
            <Card className={classes.card}>
              <div
                className="mart-100 d-flex flex-column p-2"
                id="card-parent-flex"
              >
                <div className="flex-fill mt-4 mb-4 d-flex flex-row">
                  <Grid item xs={12} sm={12}>
                    <Paper elevation={10} style={paperStyle}>
                      <div style={divstyleleft}>
                        <Typography
                          variant="h6"
                          className="align-self-left flex-fill mb-2"
                          style={{ color: "gray" }}
                        >
                          <b>
                            BASIC INFORMATION <AccountCircleIcon />
                          </b>
                        </Typography>
                        <Divider />

                        <br />
                        <Typography
                          variant="subtitle2"
                          className="flex-fill maxw-219"
                          style={{ color: "gray" }}
                        >
                          Full Name:
                        </Typography>
                        <Typography
                          variant="body2"
                          className="flex-fill"
                          align="left"
                        >
                          {name}
                        </Typography>
                        <br />
                        <Typography
                          // variant="h6"
                          // className="align-self-left flex-fill mb-2"
                          style={{ color: "gray" }}
                        >
                          <b>Professional Summary:</b>
                        </Typography>

                        <Typography
                          variant="body2"
                          color="textPrimary"
                          className="text-break flex-fill maxw-400 minh-150"
                        >
                          {bio}
                        </Typography>
                      </div>

                      <div style={divstyleright}>
                        <br />
                        <br /> <br />
                        <Typography
                          variant="subtitle2"
                          className="flex-fill maxw-219"
                          style={{ color: "gray" }}
                        >
                          Date of Birth:
                        </Typography>
                        <Typography variant="body2" className="flex-fill">
                          {dob}
                        </Typography>
                        <br />
                        <Typography
                          variant="subtitle2"
                          className="minw-200"
                          style={{ color: "gray" }}
                        >
                          Title:
                        </Typography>
                        <Typography className="flex-fill" variant="body2">
                          {title}
                        </Typography>
                        <br />
                        <Typography
                          variant="subtitle2"
                          className="flex-fill maxw-219"
                          style={{ color: "gray" }}
                        >
                          User Type:
                        </Typography>
                        <Typography className="flex-fill" variant="body2">
                          {user.role}
                        </Typography>
                        <br />
                        {user.role === "screener" ||
                          user.role === "candidate" ? (
                          <>
                            <Typography
                              variant="subtitle2"
                              className="flex-fill maxw-219"
                              style={{ color: "gray" }}
                            >
                              Unique Id:
                            </Typography>
                            <Typography className="flex-fill" variant="body2">
                              {/* {user.contact_info.uniqueMatchId} */}
                              {uniqueMatchId}
                            </Typography>
                          </>
                        ) : (
                          <></>
                        )}

                        {user.role === "admin"  ||
                          user.role === "recruiter"? (
                          <>
                            <Typography
                              variant="subtitle2"
                              className="flex-fill maxw-219"
                              style={{ color: "gray" }}
                            >
                              Company Id:
                            </Typography>
                            <Typography className="flex-fill" variant="body2">
                              {/* {user.contact_info.uniqueMatchId} */}
                              {companyID}
                            </Typography>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </Paper>
                  </Grid>
                </div>

                <Grid item xs={12} sm={12}>
                  <Paper elevation={10} style={paperStyle}>
                    {/* <div className="flex-fill" id="contact-details">
                <div className="d-flex flex-row" id="phone-row"> */}
                    <div style={divstyleleft}>
                      <Typography
                        variant="h6"
                        className="align-self-left flex-fill mb-2"
                        style={{ color: "gray" }}
                      >
                        <b>
                          CONTACT DETAILS <PhoneIcon />
                        </b>
                      </Typography>
                      <Divider />
                      <br />
                      <Typography
                        variant="subtitle2"
                        className="flex-fill maxw-219"
                        style={{ color: "gray" }}
                      >
                        Phone Number:
                      </Typography>
                      <Typography
                        variant="body2"
                        className="flex-fill"
                        align="left"
                      >
                        {phone}
                      </Typography>
                      <br />
                      {/* </div> */}
                      {/* <div className="d-flex flex-row mt-3" id="alt-phone-row"> */}
                      <Typography
                        variant="subtitle2"
                        className="flex-fill maxw-219"
                        style={{ color: "gray" }}
                      >
                        Alternate Number:
                      </Typography>

                      <Typography
                        variant="body2"
                        className="flex-fill"
                        align="left"
                      >
                        {alternatephone}
                      </Typography>
                      <br />
                      {/* </div> */}

                      {/* <div className="d-flex flex-row mt-3" id="email-row"> */}
                      <Typography
                        variant="subtitle2"
                        className="flex-fill maxw-219"
                        style={{ color: "gray" }}
                      >
                        Primary Email:
                      </Typography>
                      <Typography
                        className="flex-fill"
                        variant="body2"
                        align="left"
                      >
                        {email}
                      </Typography>
                      <br />
                      {/* </div> */}
                      {/* <div className="d-flex flex-row mt-3" id="alt-email-row"> */}
                      <Typography
                        variant="subtitle2"
                        className="flex-fill maxw-219"
                        style={{ color: "gray" }}
                      >
                        Secondary Email:
                      </Typography>
                      <Typography
                        className="flex-fill"
                        variant="body2"
                        align="left"
                      >
                        {sec_email}
                      </Typography>
                    </div>
                    {/* </div>
            </div> */}

                    {/* <div className="flex-fill ml-3" id="adress-box">

                <div className="d-flex flex-row" id="street-row"> */}
                    <div style={divstyleright}>
                      <br />
                      <br />
                      <br />
                      <Typography
                        variant="subtitle2"
                        className="flex-fill maxw-219"
                        style={{ color: "gray" }}
                      >
                        Street Address:
                      </Typography>
                      <Typography
                        variant="body2"
                        className="flex-fill"
                        align="left"
                      >
                        {street_address1} <br />
                        {/* {street_address2.length > 0
                            ? street_address2
                            : null}{" "} */}
                        {street_address2}
                      </Typography>
                      <br />
                      {/* </div> */}

                      {/* <div className="d-flex flex-row mt-3" id="city-row"> */}
                      <Typography
                        variant="subtitle2"
                        className="flex-fill maxw-219"
                        style={{ color: "gray" }}
                      >
                        City:
                      </Typography>
                      <Typography
                        variant="body2"
                        className="flex-fill"
                        align="left"
                      >
                        {city}
                      </Typography>
                      <br />
                      {/* </div> */}

                      {/* <div className="d-flex flex-row mt-3" id="state-row"> */}
                      <Typography
                        variant="subtitle2"
                        className="flex-fill maxw-219"
                        style={{ color: "gray" }}
                      >
                        State:
                      </Typography>
                      <Typography
                        variant="body2"
                        className="flex-fill"
                        align="left"
                      >
                        {state}
                      </Typography>
                      <br />
                      {/* </div> */}

                      {/* <div className="d-flex flex-row mt-3" id="country-row"> */}
                      <Typography
                        variant="subtitle2"
                        className="flex-fill maxw-219"
                        style={{ color: "gray" }}
                      >
                        Country:
                      </Typography>
                      <Typography
                        variant="body2"
                        className="flex-fill"
                        align="left"
                      >
                        {country}
                      </Typography>
                      <br />
                      {/* </div> */}

                      {/* <div className="d-flex flex-row mt-3" id="zip-row"> */}
                      <Typography
                        variant="subtitle2"
                        className="flex-fill maxw-219"
                        style={{ color: "gray" }}
                      >
                        Zip Code:
                      </Typography>
                      <Typography
                        variant="body2"
                        className="flex-fill"
                        align="left"
                      >
                        {zipcode}
                      </Typography>
                      {/* </div> */}
                    </div>
                  </Paper>
                </Grid>

                <div className="flex-fill mt-4 mb-4 d-flex flex-row">
                  <Grid item xs={12} sm={12}>
                    <Paper elevation={10} style={paperStyle}>
                      <div style={divstyleleft}>
                        <Typography
                          variant="h6"
                          className="align-self-left flex-fill mb-2"
                          style={{ color: "gray" }}
                        >
                          <b>
                            PROFESSIONAL DETAILS <WorkIcon />
                          </b>
                        </Typography>
                        <Divider />
                        <br />

                        <Typography
                          variant="subtitle2"
                          className="flex-fill maxw-219"
                          style={{ color: "gray" }}
                        >
                          Current Title:
                        </Typography>
                        <Typography
                          variant="body2"
                          className="flex-fill"
                          align="left"
                        >
                          {title}
                        </Typography>
                        <br />

                        <Typography
                          variant="subtitle2"
                          className="flex-fill maxw-219"
                          style={{ color: "gray" }}
                        >
                          Years of Experience:
                        </Typography>
                        <Typography
                          variant="body2"
                          className="flex-fill"
                          align="left"
                        >
                          {yearsOfExperience === "" ? 0 : yearsOfExperience}{" "}
                          years
                        </Typography>
                        <br />
                        <Typography
                          variant="subtitle2"
                          className="flex-fill maxw-219"
                          style={{ color: "gray" }}
                        >
                          Job Duties:
                        </Typography>
                        <Typography
                          variant="body2"
                          className="flex-fill"
                          align="left"
                        >
                          {jobDuties}
                        </Typography>
                        <br />
                      </div>

                      <div style={divstyleright}>
                        <br />
                        <br />
                        <br />

                        <Typography
                          variant="subtitle2"
                          className="flex-fill maxw-219"
                          style={{ color: "gray" }}
                        >
                          Highest Education:
                        </Typography>
                        <Typography
                          variant="body2"
                          className="flex-fill"
                          align="left"
                        >
                          {highestDegree}
                        </Typography>
                        <br />

                        <Typography
                          variant="subtitle2"
                          className="flex-fill maxw-219"
                          style={{ color: "gray" }}
                        >
                          Major:
                        </Typography>
                        <Typography
                          variant="body2"
                          className="flex-fill"
                          align="left"
                        >
                          {major}
                        </Typography>
                        <br />

                        <Typography
                          variant="subtitle2"
                          className="flex-fill mb-2"
                          style={{ color: "gray" }}
                        >
                          Skills:
                        </Typography>

                        {skills.map((skill, idx) => (
                          <div key={idx} className="d-flex flex-row mb-2 ml-2">
                            <span className="flex-fill font-italic maxw-219 text-left">
                              {skill[0]}:
                            </span>
                            <span className="flex-fill text-left">
                              {` ` + skill[1]} years
                            </span>
                          </div>
                        ))}
                      </div>
                    </Paper>
                  </Grid>
                </div>

                {user.role === "screener" ? (
                  <>
                    <Grid item xs={12} sm={12}>
                      <Paper elevation={10} style={paperStyle}>
                        <div style={divstyleleft}>
                          {/* <div className="d-flex flex-row mt-3" id="paypal-id-row"> */}
                          <Typography
                            variant="h6"
                            className="align-self-left flex-fill mb-2"
                            style={{ color: "gray" }}
                          >
                            <b>
                              SCREENER INFORMATION <PaymentIcon />
                            </b>
                          </Typography>
                          <Divider />
                          <br />
                          <Typography
                            variant="subtitle2"
                            className="flex-fill maxw-219"
                            style={{ color: "gray" }}
                          >
                            Paypal Id:
                          </Typography>
                          <Typography
                            variant="body2"
                            className="flex-fill"
                            align="left"
                          >
                            {payPalId}
                          </Typography>
                          <br />
                          {/* </div> */}
                          {/* <div className="d-flex flex-row mt-3" id="paypal-name-row"> */}
                          <Typography
                            variant="subtitle2"
                            className="flex-fill maxw-219"
                            style={{ color: "gray" }}
                          >
                            Paypal Name:
                          </Typography>
                          <Typography
                            variant="body2"
                            className="flex-fill"
                            align="left"
                          >
                            {/* {payment_info.payPalFirstName + payment_info.payPalLastName} */}
                            {payPalFirstName + " " + payPalLastName}
                          </Typography>
                          <br />
                          {/* </div> */}
                        </div>
                        {/* <div className="d-flex flex-column mt-3" id="expertise-box"> */}
                        <div style={divstyleright}>
                          <br />
                          <br />
                          <br />
                          <Typography
                            variant="subtitle2"
                            className="flex-fill mb-2"
                            style={{ color: "gray" }}
                          >
                            Areas of Expertise:
                          </Typography>
                          <ul className="ml-5">
                            {expertise.map((expertOf, idx) => (
                              <li key={idx}>{expertOf}</li>
                            ))}
                          </ul>
                        </div>
                        {/* </div> */}
                      </Paper>
                    </Grid>
                  </>
                ) : (
                  <></>
                )}
              </div>

              <div className="flex-fill mt-4 mb-4 d-flex flex-row">
                <Button
                  variant="contained"
                  color="secondary"
                  size="small"
                  className={classes.button}
                  href={`/profile_edit/${userId}`}
                  style={{
                    color: "white",
                  }}
                >
                  {/* <Button variant="contained" color="secondary" size="small" className={classes.button} > */}
                  Edit Profile{" "}
                  <FontAwesomeIcon
                    icon={faEdit}
                    style={{ marginLeft: "20px" }}
                  />
                </Button>
                {/* <Link to={`/profileedit/${userId}`}>Edit Profile <FontAwesomeIcon icon={faEdit} style={{ marginLeft: '20px' }} /> </Link> */}
              </div>
            </Card>
          </div>
        </>
      );
  }
}

ProfilePage.propTypes = {
  //user: PropTypes.object.isRequired,
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  addUserInformation: PropTypes.func.isRequired,
};

const msp = (state) => ({
  auth: state.auth,
});

const mdp = (dispatch) => ({
  updateUser: (user, snackbar) =>
    dispatch(updateProfileInformation(user, snackbar)),
  addUserInformation: (auth, snackbar) => dispatch(addUserInformation(auth)),
});

export default withSnackbar(withStyles(styles)(connect(msp, mdp)(ProfilePage)));
