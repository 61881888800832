import React, { useEffect, useState } from "react";
//External imports
import { useParams, Link } from "react-router-dom";
import axios from "axios";
import success from "../../images/verified.png";
import PageNotFound from "../ErrorHandling/PageNotFound";
//css import
import "./EmailVerify.css";
import LinkExpired from "../ErrorHandling/LinkExpired";

const CandidateEmailVerify = () => {
  //State values
  const [validUrl, setValidUrl] = useState();
  const [linkValidity, setLinkValidity] = useState()
  const param = useParams();


  useEffect(() => {
    const verifyEmailUrl = async () => {
      //Backend route to verify email address
      try {
        const url = `/api/candidate/${param.id}/verify/${param.token}`;
        const res = await axios.get(url);
        if (res.status == 200) {
          setValidUrl(true)
          setLinkValidity(true)
        } else if (res.status == 400 || res.status == 202 || res.status == 500) {
          setValidUrl(false)
          setLinkValidity(true)
        }
      } catch (error) {
        console.log(error, "err");
        setValidUrl(false);
        setLinkValidity(false)
      }
    };
    verifyEmailUrl();
  }, [param]);

  console.log(validUrl, linkValidity)

  //Frontend email verification success message
  return (
    <>
      {validUrl && linkValidity ? (
        <div className="verify-main-container">
          <div className="verify-container">
            <div className="img-container">
              <div className="verified-image-container">
                <img
                  src={success}
                  alt="verified-image"
                />
              </div>
              <h2 className="email-title">Email Verified !</h2>
              <p className="email-paragraph">Your email was verified Successfully!</p>
              <Link to="/login"><button className="verify-btn">Login Now</button></Link>
            </div>
          </div>
        </div>
      ) : !validUrl && linkValidity ? (
        <PageNotFound />
        // <LinkExpired />
      ) : !validUrl && !linkValidity ? (
        <LinkExpired />
      ) : ""}
    </>
  );
};

export default CandidateEmailVerify;
