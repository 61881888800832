
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import timeConvertor from "../../../utils/timeConvertor";
import { Typography } from "@material-ui/core";
import axios from "axios";
import dateFormat from 'dateformat';
// import '../../dashboard/candidateHomeRows/candidateRows.css';
// import { Link } from "react-router-dom";

const styles = (theme) => ({
    body: {
        // backgroundColor: "#C8E6C9",
        backgroundColor: "#FFFFFF",
    },
});

const divStyle = { padding: '20px' }

class InterviewAlert extends Component {
    constructor(props) {
        super(props);
        // console.log(props)
        this.state = {
            interviewList: [],
        }

        this.filterConfirmedInterviews = this.filterConfirmedInterviews.bind(this);
    }

    componentDidMount() {
        axios.get("api/event/interviews").then((response) => {
            this.setState({
                interviewList: response.data,
            });
        });

    }

    filterConfirmedInterviews(interviews) {
        return interviews.filter((interviewInfo) => interviewInfo.interviewstatus === "Schedule Confirmed");
    }

    render() {
        const { user, classes } = this.props;
        let futureInterviews = this.filterConfirmedInterviews(this.state.interviewList);
        console.log(user)
        // console.log(interviews)
        console.log(futureInterviews)

        //    console.log( futureInterviews[1].Start_Time__c.split("T")[1].split(":")[1]);
        // if (this.state.interviewList.length === 0 || futureInterviews.length === 0) {
        //     return (
        //         // <div className={`p-1 text-center ${classes.body} border-bottom-0 border-dark`}>
        //         <div style={divStyle}>             
        //             <Typography >There are no upcoming Interviews.</Typography>

        //         </div>
        //     );
        // } else {

        {/* {futureInterviews.map((alert, idx, interviewsArray) => (
                        <div style={divStyle}
                            key={`pendingInterviewAlert-${idx}-${alert.salesforceInterviewRecordId}`}
                            className={`p-1 text-center ${classes.body} ${
                                idx === interviewsArray.length - 1 ? "border-bottom-0" : "border-bottom"
                            } border-dark`}
                        >
                           
                            <b>{`Scheduled Interview on ${alert.Start_Time__c.split("T")[0]}`}</b>
                            <br />
                            <b>for candidate: </b>
                            {alert.Candidate_Full_Name__c} <b>position: </b> {alert.Position_Name__c} <br />
                            &nbsp;
                            <b>Start:</b>
                           
                            {new Date(alert.Start_Time__c ).toString()}
                            {" "}
                            <b>Finish:</b>
                         
                                 {new Date(alert.End_Time__c ).toString()}
                        
                        </div>
                    ))} */}



        if (futureInterviews.length === 0) {
            return (
                // <div className={`p-1 text-center ${classes.body} border-bottom-0 border-dark`}>
                <div style={divStyle}>
                    <Typography >There are no upcoming Interviews.</Typography>

                </div>
            );
        } else {

            return (
                <>
                    {/* {
                    (() => {
                        if (this.state.interviewList.length === 0) {
                            console.log(this.state.interviewList.length);
                            return(
                                <div style={divStyle}>
                                <Typography >There are no upcoming Interviews.</Typography>

                            </div>
                            )
                            
                        } else { */}
                    {
                        futureInterviews.map((interview) => (
                            (() => {
                                // if (this.state.interviewList.length != 0) {
                                if (interview.screener === user._id) {
                                    console.log(user._id);
                                    console.log(interview.screener);
                                    if (interview.interviewstatus === "Schedule Confirmed") {
                                        console.log(futureInterviews.length);
                                        console.log(interview.interviewstatus);

                                        return (
                                            <div>
                                                <b>Scheduled Interview On </b>
                                                {interview.startdate}<br />
                                                <b>Start Time : </b>
                                                {interview.starttime}<br />
                                                <b>End Time : </b>
                                                {interview.endtime}<br />
                                            </div>
                                        )

                                    }
                                }
                                // }else{
                                //     console.log(this.state.interviewList.length);
                                // }
                            })()
                        ))
                    }


                </>
            );
            // }
        }

    }

}

InterviewAlert.propTypes = {
    user: PropTypes.object.isRequired,
    interviews: PropTypes.array,
};

const mapStateToProps = (state) => ({
    user: state.user,
    interviews: state.interviews,
});

export default withStyles(styles)(connect(mapStateToProps)(InterviewAlert));
