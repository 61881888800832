import React from "react";
import "./loader.css";
import Logo from "./images/Logos/loaderlogo.png";
const Loader = () => {
  return (
    <div class="loader">
      <img src={Logo} alt="Loading" />
    </div>
  );
};
export default Loader;
