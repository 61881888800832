import React from 'react'
import { Card } from "@material-ui/core";
import TablePagination from "@mui/material/TablePagination";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const InterviewsTable = ({
    profileType,
    columns,
    idFilter,
    setIdFilter,
    titleFilter,
    setTitleFilter,
    screenerFilter,
    setScreenerFilter,
    candidateFilter,
    setCandidateFilter,
    interviewTimeFilter,
    setInterviewTimeFilter,
    statusFilter,
    setStatusFilter,
    loading,
    TableLoader,
    allInterviews,
    page,
    rowsPerPage,
    handleOpenEditInterview,
    CopyToClipboard,
    handleChangeRowsPerPage,
    copyTextToClipboard,
    handleChangePage,
    sidebarOpen
}) => {
    return (
        <><Card
            id="skills-edit"
            className={`add-recruiter-form-container ${profileType.toLowerCase() === "admin" ? "interviews-kanban-card" : "interviews-kanban-card"} `}
            style={{ boxShadow: "none", borderRadius: "2px", position: "relative" }}
        >
            <div className="user-list" >
                <div className="interview-list-heading">
                    {columns.map((item, index) => {
                        return (
                            <>
                                <div
                                    className="header-filter"
                                    style={{
                                        width: `${item.width}`,
                                    }}
                                >
                                    {item.title === "Interview Id" ?
                                        <p className={`${sidebarOpen == 'sidebar-open' ? "interview-heading-no-scroll-sidebar-open" : "interview-heading-no-scroll"}`}>
                                            Interview Id
                                        </p> :
                                        <p
                                            style={{
                                                background: index % 2 ? "rgba(240, 97, 97, 37%)" : "rgba(240, 97, 97, 17%)",
                                                color: "#283848",
                                            }}
                                        >
                                            {item.title}
                                        </p>}
                                    {item.title === "Interview Id" ? (
                                        <input
                                            type="text"
                                            placeholder="Filter"
                                            // style={{
                                            //     width: "97%",
                                            //     padding: "5px ",
                                            //     margin: "5px 0px 5px 5px",
                                            //     borderBottom: "1px solid lightgray",
                                            //     backgroundColor: "white",
                                            // }}
                                            className={`${sidebarOpen == "sidebar-open" ? "id-filter-sidebar-open" : "id-filter"}`}
                                            value={idFilter}
                                            onChange={(e) => setIdFilter(e.target.value)}
                                        />
                                    ) : item.title === "Interview Title" ? (
                                        <input
                                            type="text"
                                            placeholder="Filter"
                                            style={{
                                                width: "97%",
                                                padding: "5px ",
                                                margin: "5px 0px 5px 5px",
                                                borderBottom: "1px solid lightgray",
                                                backgroundColor: "white",
                                            }}
                                            value={titleFilter}
                                            onChange={(e) => setTitleFilter(e.target.value)}
                                        />
                                    ) : item.title === "Screener Name" ? (
                                        <input
                                            type="text"
                                            placeholder="Filter"
                                            style={{
                                                width: "97%",
                                                padding: "5px ",
                                                margin: "5px 0px 5px 5px",
                                                borderBottom: "1px solid lightgray",
                                                backgroundColor: "white",
                                            }}
                                            value={screenerFilter}
                                            onChange={(e) =>
                                                setScreenerFilter(e.target.value)
                                            }
                                        />
                                    ) : item.title === "Job Seeker Name" ? (
                                        <input
                                            type="text"
                                            placeholder="Filter"
                                            style={{
                                                width: "97%",
                                                padding: "5px ",
                                                margin: "5px 0px 5px 5px",
                                                borderBottom: "1px solid lightgray",
                                                backgroundColor: "white",
                                            }}
                                            value={candidateFilter}
                                            onChange={(e) => setCandidateFilter(e.target.value)}
                                        />
                                    ) : item.title === "Schedule Time" ? (
                                        <input
                                            type="text"
                                            placeholder="Filter"
                                            style={{
                                                width: "97%",
                                                padding: "5px ",
                                                margin: "5px 0px 5px 5px",
                                                borderBottom: "1px solid lightgray",
                                                backgroundColor: "white",
                                            }}
                                            value={interviewTimeFilter}
                                            onChange={(e) => setInterviewTimeFilter(e.target.value)}
                                        />
                                    ) : item.title === "Status" ? (
                                        <input
                                            type="text"
                                            placeholder="Filter"
                                            style={{
                                                width: "97%",
                                                padding: "5px ",
                                                margin: "5px 0px 5px 5px",
                                                borderBottom: "1px solid lightgray",
                                                backgroundColor: "white",
                                            }}
                                            value={statusFilter}
                                            onChange={(e) => setStatusFilter(e.target.value)}
                                        />
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </>
                        )
                    })}
                </div>

                {loading ? (
                    <TableLoader page="interview-list" />
                ) : (
                    <div className="interview-list ">
                        {allInterviews.length > 0 ? (
                            allInterviews.slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                            )
                                .map((item, index) => {
                                    let backupDate = item.interviewDate
                                    return (
                                        <>
                                            <div
                                                className={` internal-users-details ${index % 2
                                                    ? ""
                                                    : "alternate-recruiterlist-bg"
                                                    }`}
                                            >
                                                <div
                                                    className={`interview-id ${index % 2 ? `${sidebarOpen === "sidebar-open" ? "id-no-scroll-even-sidebar-open" : "id-no-scroll-even"}` : `${sidebarOpen === "sidebar-open" ? "id-no-scroll-odd-sidebar-open" : "id-no-scroll-odd"}`}`}
                                                // style={{ width: "17%", fontWeight: "bold", }}
                                                >
                                                    <p onClick={() => {
                                                        handleOpenEditInterview(
                                                            item._id,
                                                            item.interviewTitle,
                                                            item.screener?.name,
                                                            item.candidate?.name,
                                                            item.screener,
                                                            item.candidate,
                                                            item.interviewDate,
                                                            backupDate,
                                                            item.interviewTime,
                                                            item.timezone,
                                                            item.interviewLink,
                                                            item?.meetingPlatform,
                                                            item.interviewStatus,
                                                            item.scheduledBy,
                                                            item.interviewUniqueId,
                                                            item?.clientDetails,
                                                            item?.positionDetails,
                                                            item?.declinedBy,
                                                            item?.rescheduledBy,
                                                            item?.screenerConfirm,
                                                            item?.candidateConfirm,
                                                            item?.screenerStatus,
                                                            item?.candidateStatus,
                                                            item?.feedback,
                                                            item?.screenerFeedback)
                                                    }}>
                                                        {item.interviewUniqueId}
                                                    </p>
                                                </div>

                                                <div
                                                    className="internal-user-list-profileType interview-title-column"
                                                // style={{ width: "20%" }}
                                                >
                                                    <p>{item.interviewTitle}</p>
                                                </div>

                                                <div
                                                    className="internal-user-list-total-experience"
                                                    style={{ width: "21%" }}
                                                >
                                                    <p>{item.screener?.name} </p>
                                                </div>

                                                <div
                                                    className="internal-user-list-total-experience"
                                                    style={{ width: "20%" }}
                                                >
                                                    <p>{item.candidate?.name} </p>
                                                </div>

                                                <div
                                                    className="internal-user-list-total-experience"
                                                    style={{ width: "26%" }}
                                                >
                                                    {/* <p>{item.interviewTime ?
          formatDateAndTime((item.interviewDate + " " + item.interviewTime), item?.timezone)
          : dateFormat(backupDate, "dd-mmm-yy")
        }</p> */}
                                                    <p>{item.scheduledTime}</p>
                                                </div>

                                                <div
                                                    className="internal-user-list-total-experience"
                                                    style={{ width: "20%" }}
                                                >
                                                    <div className="interview-status-button">
                                                        <button className={`${item.interviewStatus === "To Schedule" ? "toSchedule" : item.interviewStatus}`}>
                                                            {item.interviewStatus}
                                                        </button>
                                                    </div>
                                                </div>

                                                <div className="internal-user-list-total-experience"
                                                    style={{ width: "6%" }}>
                                                    {item.interviewLink ?
                                                        <CopyToClipboard text={item.interviewLink} onCopy={copyTextToClipboard}>
                                                            <ContentCopyIcon
                                                                className="meet-link-icons"
                                                                fontSize='small'
                                                                sx={{ fontSize: '14px', cursor: 'pointer', top: '16px', right: '36px', fontSize: '15px', opacity: '90%' }}
                                                            />
                                                        </CopyToClipboard>
                                                        : <ContentCopyIcon
                                                            // onClick={() => copyTextToClipboard(item.interviewLink)} 
                                                            // className="meet-link-icons"
                                                            fontSize='small'
                                                            sx={{ fontSize: '14px', cursor: "not-allowed", top: '16px', right: '36px', fontSize: '15px', opacity: '50%' }}
                                                        />
                                                    }
                                                    {/* <RefreshIcon onClick={generateLinkHandler} className="meet-link-icons" fontSize='small' sx={{ fontSize: '14px', cursor: 'pointer', top: '14px', right: '9px', fontSize: '18px', opacity: '50%' }} /> */}
                                                </div>
                                            </div>
                                        </>
                                    )
                                })
                        ) : (<p>No Interviews</p>)}
                    </div>
                )}
            </div>
            <TablePagination
                id="recruiter-list-pagination"
                component="div"
                rowsPerPageOptions={[10, 25, 50, 100]}
                count={allInterviews.length}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                variant="outlined"
            />
        </Card></>
    )
}

export default InterviewsTable