import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import NavBar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Dropdown from "react-bootstrap/Dropdown";
import logo from "../../images/brand.svg";
import profileAvatar from "../../images/profile-avatar.png";
import { Link as Routerlink, NavLink, useHistory } from "react-router-dom";
// import { useLocation } from "react-router-dom";
import Cropper from "react-easy-crop";
import ChangePasswordModal from "../modals/ChangePasswordModal";
import CloseIcon from "@mui/icons-material/Close";
import Badge from '@mui/material/Badge';
import NotificationsIcon from '@mui/icons-material/Notifications';

import NotificationPopper from "./NotificationPopper";
import axios from "axios";
const ProfileNavbar = ({
  profileImage,
  firstName,
  defaultAvatar,
  croppedImg,
  interacted,
  registered
}) => {
  let history = useHistory();
  // const location = useLocation();
  // const { id, fname, lname, role } = location.state.payload;
  // const token = location.state.token;
  const id = sessionStorage.getItem("id");
  const role = sessionStorage.getItem("role");

  // Navbar collapse
  const [expanded, setExpanded] = useState(false);
  const [openAlertDialog, setOpenAlertDialog] = useState(false);
  // const [profileImg, setProfileImg] = useState(profileAvatar);
  let profileAvatar =
    "https://res.cloudinary.com/df2qsnyox/image/upload/v1681133496/Default%20Profile%20Avatar/profile-avatar_ogxozq_ranghl.png";


  const [notifications, setNotifications] = useState([])
  const [unreadMessages, setUnreadMessages] = useState(0)
  // const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null)

  // const anchorEl = React.useRef(null);

  // const [zoom1, onZoomChange] = useState(1);
  const logoutHandler = () => {
    sessionStorage.removeItem("jwtToken");
    sessionStorage.removeItem("id");
    sessionStorage.removeItem("role");
    history.push("/");
  };

  const notificationHandler = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  }

  let open = Boolean(anchorEl);


  function updateUnreadCount() {
    const unread = notifications.filter(item => !item.read)
    setUnreadMessages(unread.length)
  }

  function getNotifications() {
    console.log("Called")
    axios.get(`/api/${role}/get-notifications/${id}`)
      .then(res => {
        const unread = res.data.filter(item => !item.read)
        setUnreadMessages(unread.length)
        setNotifications(res.data.reverse())
      })
      .catch(err => {
        console.log(err)
      })
  }

  useEffect(() => {
    const fetchInterval = setInterval(() => {
      getNotifications()
    }, 5000)

    return () => clearInterval(fetchInterval)
  }, [])

  useEffect(() => {
    getNotifications()
  }, [])

  useEffect(() => {
    updateUnreadCount()
  }, [notifications])

  return (
    <div>
      {/* Screeener and Candidate Navbar */}

      <NavBar
        expand="lg"
        className={`nav-header  profile-navbar nav-bg`}
        // variant="dark"
        expanded={expanded}
      >
        <Container className="profile-nav-container">
          <NavBar.Toggle
            aria-controls="basic-navbar-nav"
            onClick={() => setExpanded(!expanded)}
            className="profile-navbar-toggler"
          />
          <NavBar.Brand href="#home" className="profile-navbar-brand-img">
            <img src={logo} alt="" class="brand-img profile-brand-img" />
          </NavBar.Brand>
          <h2 class="main-heading profile-main-heading">Screener</h2>

          <NavBar.Collapse
            className="profile-navbar-collapse"
            id="basic-navbar-nav"
          >
            <Nav className="me-auto nav-items profile-nav-items ml-auto">
              <NavLink to={`/${role}/dashboard`} smooth={true} duration={1000}>
                {" "}
                <Nav.Link
                  href={`/${role}/dashboard`}
                  eventKey="dashboard"
                  className={`mt-1 nav-font `}
                  onClick={() => setExpanded(false)}
                >
                  Dashboard
                </Nav.Link>
              </NavLink>
              <NavLink to={`/${role}/interviews/${id}`} smooth={true} duration={1000}>
                <Nav.Link
                  href={`/${role}/interviews`}
                  className={`mt-1 nav-font `}
                  eventKey="service"
                  onClick={() => setExpanded(false)}
                >
                  Interviews
                </Nav.Link>
              </NavLink>
              <NavLink to={`/${role}/profile`} smooth={true} duration={1000}>
                <Nav.Link
                  href={`/${role}/profile`}
                  className={`mt-1 nav-font`}
                  eventKey="about"
                  onClick={() => setExpanded(false)}
                >
                  {" "}
                  Profile
                </Nav.Link>
              </NavLink>

              {registered == 'normal' && <Nav.Link className=" profile-logout-btn ">
                {" "}
                <a className="mt-1 nav-font">
                  <button
                    onClick={() => setOpenAlertDialog(true)}
                    className="change-password-btn"
                  >
                    Change Password
                  </button>
                </a>
              </Nav.Link>}
              <Nav.Link className=" profile-logout-btn ">
                {" "}
                <Routerlink onClick={logoutHandler}>
                  <a className="nav-link btn-primary btn btn-navbar">Logout</a>
                </Routerlink>
              </Nav.Link>
            </Nav>
          </NavBar.Collapse>
        </Container>

        <Nav className="profile-dropdown-navbar">

          <Badge badgeContent={unreadMessages} color="primary" className="notifications-icon" onClick={notificationHandler}>
            <NotificationsIcon color="action" />
          </Badge>

          <p className="firstname-handler">{firstName}</p>
          <NavDropdown
            className="profile-dropdown"
            id="nav-dropdown-dark-example"
            title={
              <React.Fragment>
                <div className={`profile-acc-btn`}>
                  <img
                    style={{
                      objectFit: "cover",
                      width: "30px",
                      height: "30px",
                    }}
                    src={
                      defaultAvatar
                        ? profileAvatar
                        : interacted
                          ? croppedImg
                          : profileImage
                    }
                  />
                </div>
              </React.Fragment>
            }
            menuVariant="dark"
          >
            {/* Mobile and tab dropdown */}
            {registered == 'normal' && <NavDropdown.Item onClick={() => setOpenAlertDialog(true)}>
              Change Password
            </NavDropdown.Item>}
            <NavDropdown.Item onClick={logoutHandler}>Logout</NavDropdown.Item>
          </NavDropdown>
        </Nav>
      </NavBar>
      <ChangePasswordModal
        id={id}
        role={role}
        openAlertDialog={openAlertDialog}
        setOpenAlertDialog={setOpenAlertDialog}
      />

      <NotificationPopper
        open={open}
        setAnchorEl={setAnchorEl}
        anchorEl={anchorEl}
        notifications={notifications}
        setNotifications={setNotifications}
        className="popper-component" />
    </div>
  );
};

export default ProfileNavbar;
