import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
// import { makeStyles } from '@material-ui/core/styles';
import TextField from "@material-ui/core/TextField";
import React from "react";
export default function formExperience(props) {

    const { experience, handleChange } = props;
    return (
        <>
            {experience.map((exp, idx) => (
                <div className="d-flex flex-column justify-content-around p-5" key={`experience-${idx}`}>
                <div className="flex-fill d-flex flex-row">
                    <TextField
                      
                        id="job_title"
                        name="job_title"
                        value={exp.job_title}
                        label="Job Title/Position"
                        margin="normal"
                        variant="outlined"
                        onChange={handleChange(idx, "job_title")}
                        autoComplete="on"
                        className="flex-fill p-3 common-input"
                    />

                    <TextField
                       
                        id="company"
                        name="company"
                        value={exp.company}
                        className="flex-fill p-3 common-input"
                        variant="outlined"
                        onChange={handleChange(idx, "company")}
                        label="Company"
                        autoComplete="on"
                    />
                </div>
                <div className="d-flex flex-row justify-content-between mt-4">

                    <div className="flex-fill d-flex flex-column mr-2">
                        <InputLabel htmlFor="select-multiple">Time at job</InputLabel>
                        <Select
                            id="yrs"
                            name="yrs"
                            value={exp.yrs}
                            onChange={handleChange(idx, "yrs")}
                            label="Job Experience"
                            displayEmpty
                            className="flex-fill mt-2"
                            autoComplete="on"
                        >
                            <MenuItem value="">
                                <em>Select Experience</em>
                            </MenuItem>
                            <MenuItem value="0-2">0-2</MenuItem>
                            <MenuItem value="3-5">3-5</MenuItem>
                            <MenuItem value="6-10">6-10</MenuItem>
                            <MenuItem value="greater than 10">10 and above</MenuItem>
                        </Select>
                    </div>
                    <div className="flex-fill d-flex flex-column">
                        <TextField
                            
                            id="description"
                            name="description"
                            value={exp.description}
                            onChange={handleChange(idx, "description")}
                            label="Job Description"
                            multiline
                            margin="normal"
                            variant="outlined"
                            autoComplete="on"
                            className="flex-fill mt-2 p-3"
                        />
                    </div>
                </div>
            </div>
            ))}

        </>
    )
}