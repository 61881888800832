import axios from 'axios';
import React from "react";
export const RECEIVE_APPLICATION = 'RECEIVE_APPLICATION';
export const RECEIVE_APPLICATIONS = 'RECEIVE_APPLICATIONS';
export const RECEIVE_ERROR_APPLICATION = 'RECEIVE_ERROR_APPLICATION';

const receiveApplication = (application) => {
    // console.log('In the reducer send method');
    return {
        type: RECEIVE_APPLICATION,
        application,
    }
}

const receiveError = (error) => {
    return {
        type: RECEIVE_ERROR_APPLICATION,
        error,
    }
}

const receiveApplications = (applications) => {
    return {
        type: RECEIVE_APPLICATIONS,
        applications,
    }
}

export const receiveCandidateApplications = (candidateId) => dispatch => {
    axios
    .get("api/application/salesforce/" + candidateId).then((applications) => {
        dispatch(receiveApplications(applications));
    }).catch((err) => {
        dispatch(receiveError(err));
    });
}

export const receiveNewApplication = (body, enqueueSnackbar) => dispatch => {
    axios
        .post("api/application/new", body).then((application) => {
            let regMessage = <span>you have succesfully applied</span>;
            enqueueSnackbar(regMessage, {
                variant: "success",
                autoHideDuration: 3000,
            });
            dispatch(receiveApplication(application));
        }).catch((err) => {
            let regMessage = <span>Something Happend, please try again!</span>;
            let dupMessage = <span>You already applied to this position!</span>;
            console.log('application error received');

            if (err.response.data.error === ' DUPLICATE_VALUE') {
                enqueueSnackbar(dupMessage, {
                    variant: "error",
                    autoHideDuration: 3000,
                });
            } else {
                enqueueSnackbar(regMessage, {
                    variant: "error",
                    autoHideDuration: 3000,
                });
            }

            dispatch(receiveError(err.response.data.error));
        });
}