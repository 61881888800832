import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import Calendar from "react-big-calendar";
import { connect } from "react-redux";
import { receiveAllAvailabilities, updateAvailabilities } from "../../actions/availability";
import { calendarStandardStyle } from "./calendarStyle";

const localizer = Calendar.momentLocalizer(moment);

class StandardCalendar extends Component {
    // onSelectSlot = (slotInfo) => {
    //     let { start, end } = slotInfo;
    //     let { availabilities, updateAvailabilities, user } = this.props;
    //     let userName = user.name.split(' ').join("-");
    //     let copiedEvents = [];
    //     copiedEvents.push(...availabilities);
    //     let newEvent = {
    //         start: start,
    //         end: end,
    //         title: "availability",
    //         salesforeExternalMatchId: `${userName}-00${availabilities.length + 1}`
    //     };
    //     copiedEvents.push(newEvent);
    //     updateAvailabilities(copiedEvents);
    // }
    // onSelectEvent = (event, e) => {
    //     e.preventDefault();
    //     let { availabilities } = this.props;
    //     // let actionType = '';

    //     const response = {
    //         eventIdx: events.indexOf(event),
    //         action: "delete",
    //         start: event.start,
    //         end: event.end
    //     }

    //     this.respondToSelection(response);
    // }

    // respondToSelection = ({ eventIdx, action, start, end }) => {
    //     let { events } = this.props;

    //     switch (action) {
    //         case "delete":
    //             let newArray = [];
    //             let leftSide = events.slice(0, eventIdx);
    //             let rightSide = events.slice(eventIdx + 1);
    //             newArray.push(...leftSide);
    //             newArray.push(...rightSide);
    //             this.setState({ events: newArray });
    //             break;
    //         case 'resize':
    //             console.log(start + ' to ' + end);
    //             window.prompt('Want to resize');
    //             break;
    //         default:
    //             break;
    //     }
    // }

    eventStyleGetter = ({ event, start, end, title, isSelected }) => {
        var newStyle = {
            backgroundColor: "#C8E6C9",
            borderRadius: "0px",
            border: "1px solid black",
            color: "black",
            opacity: 0.8,
            display: "block",
            marginTop: "0px",
        };

        if (title === "Interview") {
            newStyle.backgroundColor = "#2e8331";
            newStyle.color = "white";
            newStyle.border = "1px solid black";
        }

        return {
            style: newStyle,
        };
    };

    normalizeInterviewEvents = (myInterviews) => {
        let normalizedInterviews = [];

        myInterviews.forEach((interviewObject) => {
            let normalizedObject = {
                start: new Date(interviewObject.Start_Time__c),
                end: new Date(interviewObject.End_Time__c),
                title: "Interview",
            };

            normalizedInterviews.push(normalizedObject);
        });

        return normalizedInterviews;
    };

    render() {
        const { hourStep, defaultView, selectable, popup, views, availabilities, interviews } = this.props;

        let calendarInterview;
        if (interviews[0] !== "No alerts for this user") {
            calendarInterview = this.normalizeInterviewEvents(interviews);
        }
        // console.log(allEvents);
        let allEvents = availabilities.concat(calendarInterview);

        return (
            <Calendar
                defaultDate={new Date()}
                defaultView={defaultView}
                // check selectable options
                selectable={selectable}
                popup={popup}
                events={allEvents}
                localizer={localizer}
                // onEventDrop={this.onEventDrop}
                step={hourStep}
                timeslots={2}
                // onEventResize={this.onEventResize}
                // resizable={true}
                // onSelectSlot={this.onSelectSlot}
                // onSelectEvent={(this.onSelectEvent)}
                // onDoubleClickEvent={(this.onDoubleClickEvent)}
                scrollToTime={new Date(1970, 1, 1, 8)}
                eventPropGetter={this.eventStyleGetter}
                style={calendarStandardStyle}
                views={views}
            />
        );
    }
}

StandardCalendar.propTypes = {
    auth: PropTypes.object.isRequired,
    availabilities: PropTypes.array.isRequired,
    interviews: PropTypes.array.isRequired,
    updateAvailabilities: PropTypes.func.isRequired,
    receiveAllAvailabilities: PropTypes.func.isRequired,
};

StandardCalendar.defaultProps = {
    availabilities: [],
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    availabilities: state.availabilities,
    interviews: state.interviews,
});

export default connect(mapStateToProps, {
    updateAvailabilities,
    receiveAllAvailabilities,
})(StandardCalendar);
