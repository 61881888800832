import React, { useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import axios from "axios";
import Button from "@material-ui/core/Button";
import { withSnackbar, useSnackbar } from "notistack";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import { makeStyles, Divider, TextareaAutosize } from "@material-ui/core";
import dateFormat from "dateformat";
import { useConfirm } from "material-ui-confirm";
import LaunchIcon from "@mui/icons-material/Launch";
import EditIcon from "@mui/icons-material/Edit";
import "../recruiters/tableList.css";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DateFnsUtils from "@date-io/date-fns";
import Grid from "@material-ui/core/Grid";

import TextField from "@material-ui/core/TextField";
import {
  DatePicker,
  MuiPickersUtilsProvider,
  TimePicker,
} from "material-ui-pickers";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
const FeedbackList = (props) => {
  const [interviewList, setInterviewList] = React.useState([]);
  const [screenerList, setScreenerList] = React.useState([]);
  const [candidateList, setCandidateList] = React.useState([]);
  const [open, setOpen] = React.useState(false);

  const [info, setInfo] = React.useState({
    cname: "",
    sname: "",
    title: "",
    interviewdate: "",
    communication_skills: "",
    comment_communication: "",
    technical_proficiency: "",
    comment_technical: "",
    project_experience: "",
    comment_project: "",
    tools_knowledge: "",
    comment_tools: "",
    team_leadership: "",
    comment_team: "",
    total_rating: "",
  });

  const useStyles = makeStyles((theme) => ({
    main: {
      width: "auto",
      display: "block", // Fix IE 11 issue.
      marginTop: theme.spacing(-2),
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
      [theme.breakpoints.up(400 + theme.spacing(6))]: {
        width: "50%",
        marginLeft: "auto",
        marginRight: "auto",
      },
    },
    paper: {
      marginTop: theme.spacing(-5),
      display: "flex",
      width: "100%",
      flexDirection: "column",
      alignItems: "center",
      padding: `${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(
        -15
      )}px`,
    },
    button: {
      padding: theme.spacing(2),
      margin: 10,
      alignItems: "center",
    },

    textField: {
      marginLeft: theme.spacing(),
      marginRight: theme.spacing(),
      width: "100px",
    },

    textFieldBox: {
      marginTop: theme.spacing() * 1,
      marginLeft: theme.spacing(),
      marginRight: theme.spacing(),
      width: "500px",
    },
    select: {
      marginTop: theme.spacing() * 1,
      marginLeft: theme.spacing(),
      marginRight: theme.spacing(),
      width: "250px",
    },
    formControl: {
      margin: theme.spacing(),
      minWidth: 120,
      maxWidth: 300,
    },
  }));

  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  useEffect(() => {
    axios.get("api/event/interviews").then((response) => {
      setInterviewList(response.data);
    });

    axios.get("api/users/screener").then((response) => {
      setScreenerList(response.data);
    });
    axios.get("api/users/candidate").then((response) => {
      setCandidateList(response.data);
    });
  }, []);
  // handleClickOpen
  const handleClickOpen = (id) => {
    const arrInterview = interviewList.filter((item) => {
      return item._id === id;
    });

    const arrCandidate = candidateList.filter((item) => {
      return item._id === arrInterview[0].candidate;
    });

    const arrScreener = screenerList.filter((item) => {
      return item._id === arrInterview[0].screener;
    });
    console.log(arrInterview, arrScreener, arrCandidate);
    setInfo({
      candidateName: arrCandidate[0].name,
      screenerName: arrScreener[0].name,
      title: arrInterview[0].title,
      interviewdate: arrInterview[0].startdate,
      communication_skills: arrInterview[0].communication_skills,
      comment_communication: arrInterview[0].comment_communication,
      technical_proficiency: arrInterview[0].technical_proficiency,
      comment_technical: arrInterview[0].comment_technical,
      project_experience: arrInterview[0].project_experience,
      comment_project: arrInterview[0].comment_project,
      tools_knowledge: arrInterview[0].tools_knowledge,
      comment_tools: arrInterview[0].comment_tools,
      team_leadership: arrInterview[0].team_leadership,
      comment_team: arrInterview[0].comment_team,
      total_rating: arrInterview[0].total_rating + " / 25",
    });
    setOpen(true);
  };

  //handleClose
  const handleClose = () => {
    setOpen(false);
  };

  const columns = [
    {
      field: "id",
      headerName: "Interview ID",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "title",
      headerName: "Title",
      minWidth: 150,
      flex: 0.5,
    },
    {
      field: "candidate",
      headerName: "Candidate Name",
      minWidth: 150,
      flex: 0.5,
    },

    {
      field: "screener",
      headerName: "Screener Name",
      minWidth: 150,
      flex: 0.5,
    },

    {
      field: "totalRating",
      headerName: "Overall Score",
      minWidth: 150,
      flex: 0.5,
    },
    {
      field: "actions",
      flex: 0.3,
      headerName: "Actions",
      minWidth: 150,
      // type: "number",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            {/* <Link
              to={`/interview/candidatefeedback/${params.getValue(
                params.id,
                "id"
              )}`}
              title="Feedback Details"
              style={{ color: "#75a478" }}
            > */}
            {/* <LaunchIcon /> */}
            <VisibilityIcon
            title="Feedback Details"
              style={{ color: "#75a478", cursor: "pointer" }}
              onClick={() => handleClickOpen(params.getValue(params.id, "id"))}
            />
            {/* </Link> */}
            &nbsp;&nbsp;&nbsp;
          </>
        );
      },
    },
  ];

  const rows = [];

  interviewList &&
    interviewList.forEach((item) => {
      // console.log(item._id);
      //   console.log(item.candidate && item.candidate.name);
      if (item.interviewstatus === "Completed With Feedback") {
        screenerList &&
          screenerList.forEach((user) => {
            if (item.screener === user._id) {
              candidateList &&
                candidateList.forEach((candidate) => {
                  if (item.candidate === candidate._id) {
                    console.log(user.name);
                    console.log(candidate.name);
                    console.log(item.candidate);
                    console.log(candidate._id);
                    console.log(item.screener);
                    console.log(user._id);
                    console.log(item.startdate);
                    rows.push({
                      id: item._id,
                      title: item.title,
                      screener: user.name + " - " + user.uniqueMatchId,
                      candidate:
                        candidate.name + " - " + candidate.uniqueMatchId,
                      totalRating: item.total_rating + "/25",
                    });
                  }
                });
            }
          });
      }
    });

  return (
    <>
      <br /> <br /> <br /> <br /> <br /> <br />
      <br />
      <div className="myList">
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={10}
          disableSelectionOnClick
          className="tableList"
          autoHeight
        />
      </div>
      {/* Dialog */}
      <Dialog
        maxWidth="md"
        fullWidth
        open={open}
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <Typography variant="h6" style={{ textAlign: "center" }}>
            Interview FeedBack Details{" "}
            <Divider style={{ width: "30%", margin: "auto" }} />
            <br />
          </Typography>
          <form className={classes.form}>
            <Grid container spacing={24} style={{ padding: "2em" }}>
              <Grid item xs={12} sm={5}>
                <div>
                  <label>Candidate Name</label>
                  <TextField
                    disabled
                    id="standard-disabled"
                    value={info.candidateName}
                    variant="standard"
                    fullWidth
                  />
                </div>
              </Grid>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Grid item xs={12} sm={5}>
                <div>
                  <label>Screener Name</label>
                  <TextField
                    disabled
                    id="standard-disabled"
                    value={info.screenerName}
                    variant="standard"
                    fullWidth
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={5}>
                <div>
                  <label>Interview Title</label>
                  <TextField
                    disabled
                    id="standard-disabled"
                    value={info.title}
                    variant="standard"
                    fullWidth
                  />
                </div>
              </Grid>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Grid item xs={12} sm={5}>
                <div>
                  <label>Interview Date</label>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      required
                      id="dob"
                      name="dob"
                      value={info.interviewdate}
                      format="dd/MM/yyyy"
                      disabled
                      fullWidth
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </Grid>
              <Grid item xs={12} sm={5}>
                <div>
                  <label>Communication Rating</label>
                  <TextField
                    disabled
                    id="standard-disabled"
                    value={info.communication_skills}
                    variant="standard"
                    fullWidth
                  />
                </div>
              </Grid>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Grid item xs={12} sm={5}>
                <div>
                  <label>Communication Feedback</label>
                  <div>
                    <TextareaAutosize
                      disabled
                      maxRows={5}
                      value={info.comment_communication}
                      variant="standard"
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>
              </Grid>
              <br />
              <Grid item xs={12} sm={5}>
                <div>
                  <label>Technical Rating</label>
                  <TextField
                    disabled
                    id="standard-disabled"
                    value={info.technical_proficiency}
                    variant="standard"
                    fullWidth
                  />
                </div>
              </Grid>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Grid item xs={12} sm={5}>
                <div>
                  <label>Technical Feedback</label>
                  <div>
                    <TextareaAutosize
                      disabled
                      maxRows={5}
                      value={info.comment_technical}
                      variant="standard"
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>
              </Grid>
              <br />
              <Grid item xs={12} sm={5}>
                <div>
                  <label>Project Experience Rating</label>
                  <TextField
                    disabled
                    id="standard-disabled"
                    value={info.project_experience}
                    variant="standard"
                    fullWidth
                  />
                </div>
              </Grid>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Grid item xs={12} sm={5}>
                <div>
                  <label>Project Feedback</label>
                  <div>
                    <TextareaAutosize
                      disabled
                      maxRows={5}
                      value={info.comment_project}
                      variant="standard"
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>
              </Grid>
              <br />
              <Grid item xs={12} sm={5}>
                <div>
                  <label>Tools Knowledge Rating</label>
                  <TextField
                    disabled
                    id="standard-disabled"
                    value={info.tools_knowledge}
                    variant="standard"
                    fullWidth
                  />
                </div>
              </Grid>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Grid item xs={12} sm={5}>
                <div>
                  <label> Tools Knowledge Feedback</label>
                  <div>
                    <TextareaAutosize
                      disabled
                      maxRows={5}
                      value={info.comment_tools}
                      variant="standard"
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>
              </Grid>
              <br />
              <Grid item xs={12} sm={5}>
                <div>
                  <label>Team Leadership Rating</label>
                  <TextField
                    disabled
                    id="standard-disabled"
                    value={info.team_leadership}
                    variant="standard"
                    fullWidth
                  />
                </div>
              </Grid>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Grid item xs={12} sm={5}>
                <div>
                  <label>Team Leadership Feedback</label>
                  <div>
                    <TextareaAutosize
                      disabled
                      maxRows={5}
                      value={info.comment_team}
                      variant="standard"
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>
              </Grid>
              <br /> <br /> <br /> <br />
              <Grid item xs={12} sm={5}>
                <div>
                  <label>Total Rating</label>
                  <TextField
                    disabled
                    id="standard-disabled"
                    value={info.total_rating}
                    variant="standard"
                    fullWidth
                  />
                </div>
              </Grid>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <br /> <br /> <br /> <br /> <br /> <br /> <br />
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            style={{
              backgroundColor: "#D73B30",
              color: "white",
              marginRight: "20px",
            }}
          >
            Back
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

FeedbackList.propTypes = {
  user: PropTypes.object.isRequired,
};

const msp = (state) => ({
  user: state.user,
});

export default connect(msp)(FeedbackList);
