import React, { useState, useRef, useEffect, useCallback } from "react";
import DialogContent from "@mui/material/DialogContent";
import { Grid, Tooltip } from "@mui/material";
import { Dialog } from "@mui/material";
import Slider from "@mui/material/Slider";
import Divider from "@mui/material/Divider";
import CloseIcon from "@mui/icons-material/Close";
import Cropper from "react-easy-crop";
import PhoneInput from "react-phone-input-2";
import Box from "@mui/material/Box";
import DeleteMessageIcon from "@mui/icons-material/ErrorOutlineOutlined";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
//Axios
import axios from "axios";

import getCroppedImg from "./CropImage";
import SaveProfileSnackBar from "../snackBars/saveProfileSnackbar";
import ErrorMessageSnackbar from "../snackBars/errorMessageSnackbar";
import { getPhonesRegex } from "../auth/PhoneValidation";

// CSS import
import "./MainCardEditModal.css";

export const ViewEditUserModal = ({
  openEditUserDetails,
  setOpenEditUserDetails,
  croppedAreaPixels,
  croppedArea,
  verified,
  status,
  profileImage,
  companyId,
  croppedImage,
  croppedImagePublicId,
  defaultAvatar,
  firstName,
  lastName,
  interacted,
  userEmail,
  phone,
  role,
  uniqueId,
  imagePublicId,
  zoom,
  profileType,
  _v,
  _id,
}) => {
  //Account Id and role
  const id = sessionStorage.getItem("role") == "admin" ? sessionStorage.getItem("id") : sessionStorage.getItem("compId");
  const profileRole = sessionStorage.getItem("role")
  console.log(role, profileRole)
  let profileAvatar =
    "https://res.cloudinary.com/df2qsnyox/image/upload/v1681133496/Default%20Profile%20Avatar/profile-avatar_ogxozq_ranghl.png";
  const [profileImg1, setProfileImg1] = useState();
  const [profileImg2, setProfileImg2] = useState();
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [croppedImage1, setCroppedImage1] = useState(null);
  const [croppedAreaPixels1, setCroppedAreaPixels1] = useState();
  const [croppedArea1, setCroppedArea1] = useState(croppedArea);
  const [zoom1, setZoom1] = useState();
  const [sliderValue, setSliderValue] = useState(1);
  const [firstName1, setFirstName1] = useState("");
  const [lastName1, setLastName1] = useState("");
  const [phone1, setPhone1] = useState("");
  const [email, setEmail] = useState();
  const [profileType1, setProfileType1] = useState("");
  const [userRole, setUserRole] = useState("myUsers");
  const [isAvatar, setIsAvatar] = useState();
  const [interaction, setInteraction] = useState();
  const [defaultAvatar1, setDefaultAvatar1] = useState();
  const [isNewImg, setIsNewImg] = useState(false);
  const [newImageUploaded, setNewImageUploaded] = useState(false);

  //array to store the profile types
  const [profileTypes, setProfileTypes] = useState([]);

  const [openAlertDialog, setOpenAlertDialog] = useState(false);
  const [openCloseDialog, setOpenCloseDialog] = useState(false);

  const [phoneIsValid, setPhoneIsValid] = useState(true);

  // store the regex of phone number patterns of all countries
  const phones = getPhonesRegex();

  let arr = [];

  useEffect(() => {
    setProfileImg1(profileImage);
    setProfileImg2(profileImage);
    setCroppedAreaPixels1(croppedAreaPixels);
    setZoom1(zoom);
    setSliderValue(1);
    setFirstName1(firstName);
    setLastName1(lastName);
    setInteraction(interacted);
    setPhone1(phone);
    setEmail(userEmail);
    setIsAvatar(defaultAvatar);
    setProfileType1(profileType);

    axios.get(`/api/admin/userRole/${id}`).then((res) => {
      if (res.data.length > 0) {
        arr = res.data[0].profileType;
        arr = arr.filter((item) => {
          return item.roleName !== "Recruiter";
        });
        setProfileTypes(arr);
      } else {
        setProfileTypes(arr);
      }
    });
  }, [openEditUserDetails]);

  //Snackbar States
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);

  //useRef
  const imageRef = useRef();

  // error handling
  const [errors, setErrors] = useState({
    imageSize: "",
    imageType: "",
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    profileType: "",
  });

  const userChangeHandler = (event, newValue) => {
    setProfileType1(newValue.roleName);
    setErrors({ profileType: "" });
  };
  // handle remove image alert dialog
  const handleRemoveImageDialog = () => {
    setOpenAlertDialog(true);
  };

  //handle close confirmation dialog
  const onCloseDialog = () => {
    setFirstName1("");
    setLastName1("");
    setEmail("");
    setPhone1("");
    setProfileType1("");
    setErrors({
      imageSize: "",
      imageType: "",
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
      profileType: "",
    });
    setProfileImg1(profileImage);
    setOpenCloseDialog(false);
    setOpenEditUserDetails(false);
  };

  // handle cancel button press of confirmation dialog
  const onCloseCancel = () => {
    setOpenCloseDialog(false);
    setOpenEditUserDetails(true);
  };

  const onCloseAlertDialog = () => {
    setOpenAlertDialog(false);
  };

  // disable the right and left arrow keys for the slider
  function preventHorizontalKeyboardNavigation(event) {
    if (event.key === "ArrowLeft" || event.key === "ArrowRight") {
      event.preventDefault();
    }
  }

  // used to get url of newly uploaded image
  function readFile(file) {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener("load", () => resolve(reader.result), false);
      reader.readAsDataURL(file);
    });
  }

  // handle profile image upload
  const handleProfileImageUpload = async (e) => {
    const fileCheck = e.target.files[0];
    const maxSize = 1024 * 1024; //1MB
    if (fileCheck.size > maxSize) {
      setErrors({ imageSize: "Please upload image less than 1MB" });
      imageRef.current.value = null;
    } else if (!fileCheck.type.startsWith("image")) {
      setErrors({ imageType: "Please upload an image file" });
      e.target.files = null;
    } else {
      let imageDataUrl = await readFile(fileCheck);
      setProfileImg1(imageDataUrl);
      setProfileImg2(fileCheck);
      setNewImageUploaded(true);
      setErrors({ imageSize: "", imageType: "" });
      setIsAvatar(false);
      setIsNewImg(true);
      setInteraction(false);
      setDefaultAvatar1(false);
      setZoom1(1);
    }
  };

  // handle profile image remove
  const handleRemoveProfileImage = (e) => {
    setProfileImg1(profileAvatar);
    setProfileImg2(profileAvatar);
    setZoom1(1);
    setErrors({ imageSize: "", imageType: "" });
    setIsAvatar(true);
    setNewImageUploaded(false);
    imageRef.current.value = null;
    setDefaultAvatar1(true);
    setOpenAlertDialog(false);
  };

  // called everytime the image is cropped
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels1(croppedAreaPixels);
    setCroppedArea1(croppedArea);
  }, []);

  // used to get cropped image
  const showCroppedImage = useCallback(async () => {
    let profileImgUrl = profileImg1;
    try {
      const croppedImage = await getCroppedImg(
        profileImgUrl,
        croppedAreaPixels1,
        0 //rotation
      );
      setCroppedImage1(croppedImage);
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels1, profileImg1]);

  //Form Validation handlers
  const firstNameHandler = (e) => {
    setFirstName1(e.target.value);
    setErrors({
      firstName: "",
    });
  };

  const lastNameHandler = (e) => {
    setLastName1(e.target.value);
    setErrors({
      lastName: "",
    });
  };

  const profileTypeHandler = (event, newValue) => {
    setProfileType1(newValue);
    setErrors({
      profileType: "",
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (firstName1 == "")
      return setErrors({ firstName: "First name is required!" });

    if (lastName1 == "")
      return setErrors({ lastName: "Last name is required!" });

    if (phone1 === "") {
      return setErrors({ phone: "Phone Number is required" });
    } else if (!phoneIsValid) {
      return setErrors({ phone: "Invalid Phone Number" });
    }

    if (profileType1 === "") {
      return setErrors({ profileType: "Profile Type is required" });
    }

    if (!profileTypes.find((item) => item.roleName === profileType1)) {
      return setErrors({
        profileType: "Please select one among the existing profile types!",
      });
    }

    const formData = new FormData();
    formData.append("id", _id);
    formData.append("firstName", firstName1);
    formData.append("lastName", lastName1);
    formData.append("email", email);
    formData.append("phone", phone1);
    formData.append("profileType", profileType1);
    formData.append("profileImage", profileImg2);
    formData.append("imagePublicId", imagePublicId);
    formData.append("croppedImage", croppedImage1);
    formData.append("croppedImagePublicId", croppedImagePublicId);
    formData.append("croppedAreaPixels", JSON.stringify(croppedAreaPixels1));
    formData.append("zoom", Number(zoom));
    formData.append("interacted", interaction);
    formData.append("uniqueId", uniqueId);
    formData.append("defaultAvatar", isAvatar);

    axios
      .post(`/api/${userRole}/edit-user`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        setOpenSnackbar(true);
        setOpenEditUserDetails(false);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 409) {
          setErrors({
            email: "Email already exists!",
          });
        } else setOpenErrorSnackbar(true);
      });

    setIsNewImg(false);
  };

  const handleCloseEditRecruiter = () => {
    setOpenCloseDialog(true);
    setOpenEditUserDetails(false);
  };

  return (
    <div className="main-dialog">
      <Dialog
        open={openEditUserDetails}
        onClose={handleCloseEditRecruiter}
        maxWidth="md"
      >
        <DialogContent>
          <Tooltip title="Close">
            <CloseIcon
              className="close-icon"
              onClick={handleCloseEditRecruiter}
              sx={{ cursor: "pointer", padding: 0, margin: "1px" }}
            />
          </Tooltip>
          <Grid container spacing={0} justifyContent="center">
            {/* Left Grid item */}
            <Grid
              item
              xs={12}
              sm={12}
              md={4.5}
              lg={5}
              xl={5}
              className="left-grid"
            >
              <div className="left-container">
                <div className="image-container">
                  <div className="crop-container">
                    <Cropper
                      className="cropper"
                      image={profileImg1}
                      crop={crop}
                      zoom={zoom1}
                      initialCroppedAreaPixels={
                        isNewImg || isAvatar ? null : croppedAreaPixels1
                      }
                      aspect={16 / 16}
                      onCropChange={setCrop}
                      onCropComplete={onCropComplete}
                      onCropSizeChange={showCroppedImage}
                      onInteractionStart={() => {
                        setInteraction(true);
                        showCroppedImage();
                      }}
                      onInteractionEnd={showCroppedImage}
                      onZoomChange={isAvatar ? null : setZoom1}
                      restrictPosition={true}
                      onMediaLoaded={showCroppedImage}
                      objectFit="auto-cover"
                    />
                  </div>
                  <div>
                    <label className="image-upload">
                      <input
                        type="file"
                        onChange={handleProfileImageUpload}
                        accept=".jpg, .png, .jpeg"
                        ref={imageRef}
                      />
                      <span>Upload</span>
                    </label>
                    <button
                      className="image-remove"
                      onClick={handleRemoveImageDialog}
                    >
                      Remove
                    </button>
                  </div>
                </div>
                <span className="text-danger">
                  {errors.imageSize} {errors.imageType}
                </span>
                <div className="name-designation">
                  <h3>
                    {firstName} {lastName}
                  </h3>
                </div>
                <div className="name-designation">
                  <h6>{profileType}</h6>
                </div>
              </div>
              <div className="slider-container">
                <Box sx={{ height: 100 }}>
                  <Slider
                    sx={{
                      '& input[type="range"]': {
                        WebkitAppearance: "slider-vertical",
                      },
                    }}
                    step={0.1}
                    type="range"
                    min={1}
                    max={3}
                    size="small"
                    value={zoom1}
                    orientation="vertical"
                    defaultValue={sliderValue}
                    aria-label="Zoom In"
                    valueLabelDisplay="auto"
                    onKeyDown={preventHorizontalKeyboardNavigation}
                    onChange={
                      isAvatar
                        ? null
                        : (e, zoom) => {
                          setZoom1(zoom);
                          setInteraction(true);
                          showCroppedImage();
                        }
                    }
                  />
                </Box>
                <div>
                  <p>Zoom in</p>
                </div>
              </div>
            </Grid>
            <Box>
              <Divider orientation="vertical" />
            </Box>

            {/* Right Grid item */}
            <Grid item xs={12} sm={12} md={7} lg={6.5} className="right-grid">
              <div>
                <form noValidate className="right-grid-form">
                  <div class="input-container">
                    <div class="input-field-modal">
                      <label className="date-label">Email*</label>
                      <input
                        disabled={true}
                        className={`${errors.email && "input-field-error"}`}
                        class="email"
                        type="email"
                        placeholder="Email Id*"
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                          setErrors({
                            email: "",
                          });
                        }}
                      />
                    </div>
                    <span className="text-danger">{errors.email}</span>
                    <div class="input-field-modal">
                      <label className="date-label">First Name*</label>
                      <input
                        type="text"
                        value={firstName1}
                        className={`${errors.firstName && "input-field-error"}`}
                        onChange={firstNameHandler}
                        placeholder="First Name"
                      />
                    </div>
                    <span className="text-danger">{errors.firstName}</span>
                    <div class="input-field-modal">
                      <label className="date-label">Last Name*</label>
                      <input
                        class="position"
                        type="text"
                        value={lastName1}
                        onChange={lastNameHandler}
                        className={`${errors.lastName && "input-field-error"}`}
                        placeholder="Last Name"
                      />
                    </div>
                    <span className="text-danger">{errors.lastName}</span>

                    <div class="input-field-modal">
                      <label className="date-label">Phone*</label>
                      <PhoneInput
                        country={"us"}
                        class="phone"
                        type="number"
                        placeholder="Phone"
                        value={phone1}
                        // className={`${errors.phone && "input-field-error"}`}
                        onChange={(phone, country) => {
                          setPhone1(phone);
                          var regex = phones[country.countryCode.toUpperCase()];
                          if (regex != undefined) {
                            if (!regex.test(phone)) {
                              setPhoneIsValid(false);
                              return setErrors({
                                phone: "Invalid Phone Number",
                              });
                            } else {
                              setPhoneIsValid(true);
                              return setErrors({ phone: "" });
                            }
                          }
                        }}
                      />
                    </div>
                    <span className="text-danger">{errors.phone}</span>

                    <div className="profile-type-dropdown">
                      <div class="input-field-modal view-user-modal">
                        <label className="date-label">Profile Type</label>

                        <Autocomplete
                          disablePortal
                          disabled={profileRole.toLowerCase() === "admin" ? false : true}
                          id="free-solo-demo"
                          ListboxProps={{ style: { maxHeight: 220 } }}
                          disableClearable={true}
                          onChange={(event, newValue) =>
                            userChangeHandler(event, newValue)
                          }
                          inputValue={profileType1}
                          options={profileTypes}
                          sx={{ width: 300, "& fieldset": { border: "none", backGroundColor: '#eee' } }}
                          getOptionLabel={(option) => option.roleName.toLowerCase() === "admin" ? "Administrator" : option.roleName.charAt(0).toUpperCase() + option.roleName.slice(1)}
                          renderInput={(params) => (
                            <TextField
                              value={profileType1}
                              className={`${errors.profileType && "input-field-error-profileType"}`}
                              onChange={(e) => {
                                setProfileType1(e.target.value);
                                setErrors({
                                  profileType: "",
                                });
                              }}
                              {...params}
                              margin="normal"
                            />
                          )}
                        />
                      </div>
                    </div>
                    <span className="text-danger">{errors.profileType}</span>

                    <div className="button-container">
                      <button
                        onClick={(e) => {
                          onSubmit(e);
                        }}
                        className="btn-style filled-btn filled-btn-animation"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      {/* Alert Dialog  */}
      <Dialog
        fullWidth
        maxWidth="xs"
        open={openAlertDialog}
        onClose={onCloseAlertDialog}
        className="experience-edit-dialog"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent className="dialog-content">
          <Grid container spacing={0} justifyContent="center">
            <Grid
              item
              xs={11.5}
              sm={11.5}
              md={12}
              lg={12}
              className="experience-delete-grid"
            >
              <div className="delete-message-icon">
                <DeleteMessageIcon fontSize="small" />
              </div>
              <div className="delete-dialog-content">
                <h5>Are you sure?</h5>
                <p>Do you want remove your profile picture?</p>
              </div>
            </Grid>
          </Grid>
        </DialogContent>
        <div className="alert-buttons-container">
          <button className="warning-btn-style filled-btn filled-btn-animation" onClick={onCloseAlertDialog}>
            {" "}
            Cancel
          </button>
          <button className="warning-btn-style outlined-btn outlined-btn-animation" onClick={handleRemoveProfileImage}>
            {" "}
            Confirm
          </button>
        </div>
      </Dialog>

      {/* Close confimation dialog */}
      <Dialog
        fullWidth
        maxWidth="xs"
        open={openCloseDialog}
        onClose={handleCloseEditRecruiter}
        className="experience-edit-dialog"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent className="dialog-content">
          <Grid container spacing={0} justifyContent="center">
            <Grid
              item
              xs={11.5}
              sm={11.5}
              md={11.5}
              lg={11.5}
              className="experience-delete-grid"
            >
              <div className="delete-message-icon">
                <DeleteMessageIcon fontSize="small" />
              </div>
              <div className="delete-dialog-content">
                <h5>Are you sure?</h5>
                <p>Do you want to go back?</p>
                <p>All your current changes will be lost!</p>
              </div>
            </Grid>
          </Grid>
        </DialogContent>
        <div className="alert-buttons-container">
          <button className="warning-btn-style filled-btn filled-btn-animation" onClick={onCloseCancel}>
            {" "}
            Cancel
          </button>
          <button className="warning-btn-style outlined-btn outlined-btn-animation" onClick={onCloseDialog}>
            {" "}
            Confirm
          </button>
        </div>
      </Dialog>

      <SaveProfileSnackBar
        openSnackbar={openSnackbar}
        setOpenSnackbar={setOpenSnackbar}
      />
      <ErrorMessageSnackbar
        openErrorSnackbar={openErrorSnackbar}
        setOpenErrorSnackbar={setOpenErrorSnackbar}
      />
    </div>
  );
};
