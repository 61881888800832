import React, { Fragment, useState, useEffect, Component } from "react";
import Button from "@material-ui/core/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  updateRecruiter,
  getRecruiterDetails,
  UPDATE_PROFILE_RESET,
} from "../../actions/userActions";
import axios from "axios";
import { withSnackbar, useSnackbar } from "notistack";
import { candidates } from "../../actions/userActions";

import DateFnsUtils from "@date-io/date-fns";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from "@material-ui/core/TextField";

import {
  DatePicker,
  MuiPickersUtilsProvider,
  TimePicker,
} from "material-ui-pickers";
import PropTypes, { string } from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { makeStyles, Divider } from "@material-ui/core";
import { Link } from "react-router-dom";

import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Dialog from "@mui/material/Dialog";

const useStyles = makeStyles((theme) => ({
  main: {
    width: "auto",
    display: "block", // Fix IE 11 issue.
    marginTop: theme.spacing(-2),
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(6))]: {
      width: "50%",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing(-5),
    display: "flex",
    width: "100%",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(
      -15
    )}px`,
  },
  button: {
    padding: theme.spacing(2),
    margin: 10,
    alignItems: "center",
  },

  textField: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    width: "100px",
  },

  textFieldBox: {
    marginTop: theme.spacing() * 1,
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    width: "500px",
  },
  select: {
    marginTop: theme.spacing() * 1,
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    width: "250px",
  },
  formControl: {
    margin: theme.spacing(),
    minWidth: 120,
    maxWidth: 300,
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const CandidateInformation = ({ history, match, props }) => {
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.getRecruiterDetail);
  const { isUpdated } = useSelector((state) => state.recruiterupdate);
  const { users } = useSelector((state) => state.candidatelist);

  //const [name, setName] = useState("");
  // const [email, setEmail] = useState("");
  // const [role, setRole] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const userId = match.params.id;

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    return setOpen(true);
  };

  //Close the dialog box
  const handleClose = () => {
    return setOpen(false);
  };

  // useEffect(() => {

  //     // if (user && user._id !== userId) {
  //     //     dispatch(getRecruiterDetails(userId));

  //     // }
  //     // else {
  //     //     setName(user.name);
  //     //     setEmail(user.email);
  //     //     setRole(user.role);
  //     // }
  //     console.log(user._id)
  //     console.log(user.name)
  //     //console.log(user.professional_details.currentTitle)
  //     console.log(user.phone)
  //     // if (isUpdated) {

  //     //     history.push("/home");

  //     //     dispatch({
  //     //         type: UPDATE_PROFILE_RESET,
  //     //     });
  //     // }
  // }, [dispatch, history, user, userId]);
  useEffect(() => {
    // if (error) {
    //   alert.error(error);
    //   dispatch(clearErrors());
    // }

    dispatch(candidates());
  }, [dispatch, history]);

  var cname,
    currentitle,
    email,
    dob,
    preferredPosition,
    skills,
    phone,
    role,
    status,
    yearsOfExperience,
    highestDegree,
    major,
    englishProficiency,
    address_line1,
    address_line2,
    city,
    state,
    country,
    postalCode;

  // var Length = 0;
  // users.data && users.data.forEach(item => {
  //     Length= Length+1;
  // })
  // console.log(Length);

  users.data &&
    users.data.forEach((item) => {
      if (item._id === userId) {
        console.log(item.name);
        console.log(item.professional_details.currentTitle);
        console.log(item.candidate_info.preferredPosition);
        console.log(item.dob);
        cname = item.name;
        currentitle = item.professional_details.currentTitle;
        email = item.email;
        dob = item.dob;
        preferredPosition = item.candidate_info.preferredPosition;
        major = item.professional_details.major;
        skills = item.skills;
        phone = item.phone;
        role = item.role;
        status = item.status;
        yearsOfExperience = item.professional_details.yearsOfExperience;
        highestDegree = item.professional_details.highestDegree;
        englishProficiency = item.professional_details.englishProficiency;
        address_line1 = item.address.address_line1;
        address_line2 = item.address.address_line2;
        city = item.address.city;
        state = item.address.state;
        country = item.address.country;
        postalCode = item.address.postalCode;

        //job_experience= item.job_experience
        //  item.job_experience.map(skill =>{
        //      console.log(skill[0]);
        //      console.log(skill[1])
        //  })
      }
    });

  const classes = useStyles();

  return (
    <>
      <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br />
      {/* <h1>Hello {userId} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{user._id}  &nbsp;&nbsp;&nbsp;{cname}
                &nbsp;&nbsp;&nbsp;{email} &nbsp;&nbsp;&nbsp;{dob} &nbsp;&nbsp;&nbsp;{currentitle}  &nbsp;&nbsp;&nbsp;{preferredPosition}
                &nbsp;&nbsp;&nbsp;{skills}  </h1> */}
      <div className={classes.main}>
        <Paper className={classes.paper}>
          <br />
          <Typography variant="h6">
            Candidate Information
            <Divider />
          </Typography>

          <div style={{ marginLeft: "-85%" }}>
            <Button
              style={{ backgroundColor: "#D73B30", color: "white" }}
              variant="contained"
              color="secondary"
              align="center"
              onClick={handleClickOpen}
            >
              Back
            </Button>
          </div>

          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Warning: Are you sure you want to leave this page?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleClose}
                style={{ backgroundColor: "#D73B30", color: "white" }}
              >
                Cancel
              </Button>
              <Link
                to="/candidate-list"
                style={{ color: "white", textDecoration: "none" }}
              >
                <Button
                  autoFocus
                  style={{
                    backgroundColor: "#75A478",
                    color: "white",
                    margin: "6px",
                  }}
                >
                  Confirm
                </Button>
              </Link>
            </DialogActions>
          </Dialog>
          <form className={classes.form}>
            <Grid container spacing={24} style={{ padding: "2em" }}>
              <Grid item xs={12} sm={8}>
                <Typography variant="subtitle1" style={{ color: "gray" }}>
                  Personal Details
                  <Divider />
                  <br />
                </Typography>
              </Grid>
              <Grid item xs={12} sm={5}>
                <div>
                  <label>Name</label>
                  <TextField
                    disabled
                    id="standard-disabled"
                    value={cname}
                    variant="standard"
                    fullWidth
                  />
                </div>
              </Grid>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Grid item xs={12} sm={5}>
                <div>
                  <label>Email</label>
                  <TextField
                    disabled
                    id="standard-disabled"
                    value={email}
                    variant="standard"
                    fullWidth
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={5}>
                <div>
                  <label>Mobile</label>
                  <TextField
                    disabled
                    id="standard-disabled"
                    value={phone}
                    variant="standard"
                    fullWidth
                  />
                </div>
              </Grid>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Grid item xs={12} sm={5}>
                <div>
                  <label>Date Of Birth</label>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      required
                      id="dob"
                      name="dob"
                      value={dob}
                      format="MM/dd/yyyy"
                      disabled
                      fullWidth
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </Grid>
              <Grid item xs={12} sm={5}>
                <div>
                  <label>Role</label>
                  <TextField
                    disabled
                    id="standard-disabled"
                    value={role}
                    variant="standard"
                    fullWidth
                  />
                </div>
              </Grid>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Grid item xs={12} sm={5}>
                <div>
                  <label>Status</label>
                  <TextField
                    disabled
                    id="standard-disabled"
                    value={status}
                    variant="standard"
                    fullWidth
                  />
                </div>
              </Grid>
              <br />
              <Grid item xs={12} sm={8}>
                <br />
                <br />
                <Typography variant="subtitle1" style={{ color: "gray" }}>
                  Professional Details
                  <Divider />
                  <br />
                </Typography>
              </Grid>
              <Grid item xs={12} sm={5}>
                <div>
                  <label>Skills</label>
                  <TextField
                    disabled
                    id="standard-disabled"
                    value={skills}
                    variant="standard"
                    fullWidth
                  />
                </div>
              </Grid>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Grid item xs={12} sm={5}>
                <div>
                  <label>Years Of Experience</label>
                  <TextField
                    disabled
                    id="standard-disabled"
                    value={yearsOfExperience}
                    variant="standard"
                    fullWidth
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={5}>
                <div>
                  <label>Current Title</label>
                  <TextField
                    disabled
                    id="standard-disabled"
                    value={currentitle}
                    variant="standard"
                    fullWidth
                  />
                </div>
              </Grid>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Grid item xs={12} sm={5}>
                <div>
                  <label>Preferred position</label>
                  <TextField
                    disabled
                    id="standard-disabled"
                    value={preferredPosition}
                    variant="standard"
                    fullWidth
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={5}>
                <div>
                  <label>Highest Degree</label>
                  <TextField
                    disabled
                    id="standard-disabled"
                    value={highestDegree}
                    variant="standard"
                    fullWidth
                  />
                </div>
              </Grid>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Grid item xs={12} sm={5}>
                <div>
                  <label>Major</label>
                  <TextField
                    disabled
                    id="standard-disabled"
                    value={major}
                    variant="standard"
                    fullWidth
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={5}>
                <div>
                  <label>English Proficiency</label>
                  <TextField
                    disabled
                    id="standard-disabled"
                    value={englishProficiency}
                    variant="standard"
                    fullWidth
                  />
                </div>
              </Grid>
              <br />
              <Grid item xs={12} sm={8}>
                <br />
                <br />
                <Typography variant="subtitle1" style={{ color: "gray" }}>
                  Contact Details
                  <Divider />
                  <br />
                </Typography>
              </Grid>
              <Grid item xs={12} sm={5}>
                <div>
                  <label>Address Line1</label>
                  <TextField
                    disabled
                    id="standard-disabled"
                    value={address_line1}
                    variant="standard"
                    fullWidth
                  />
                </div>
              </Grid>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Grid item xs={12} sm={5}>
                <div>
                  <label>Address Line2</label>
                  <TextField
                    disabled
                    id="standard-disabled"
                    value={address_line2}
                    variant="standard"
                    fullWidth
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={5}>
                <div>
                  <label>City</label>
                  <TextField
                    disabled
                    id="standard-disabled"
                    value={city}
                    variant="standard"
                    fullWidth
                  />
                </div>
              </Grid>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Grid item xs={12} sm={5}>
                <div>
                  <label>State</label>
                  <TextField
                    disabled
                    id="standard-disabled"
                    value={state}
                    variant="standard"
                    fullWidth
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={5}>
                <div>
                  <label>Country</label>
                  <TextField
                    disabled
                    id="standard-disabled"
                    value={country}
                    variant="standard"
                    fullWidth
                  />
                </div>
              </Grid>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Grid item xs={12} sm={5}>
                <div>
                  <label>Postal Code</label>
                  <TextField
                    disabled
                    id="standard-disabled"
                    value={postalCode}
                    variant="standard"
                    fullWidth
                  />
                </div>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </div>
    </>
  );
};
export default CandidateInformation;
