import React, { useEffect, useState } from "react";

// MUI Imports

import BasicDetails from "./BasicDetails";
import SkillsDetails from "./SkillsDetails";
import EducationDetails from "./EducationDetails";
import ExperienceDetails from "./ExperienceDetails";

const AddUsers = ({ setRefreshCount, role, addUserClicked, setAddUserClicked, mode }) => {


  //default Avatar from cloud
  let profileAvatar =
    "https://res.cloudinary.com/df2qsnyox/image/upload/v1681133496/Default%20Profile%20Avatar/profile-avatar_ogxozq_ranghl.png";
  const [resume, setResume] = useState();
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [croppedAreaPixels, setCroppedAreaPixels] = useState();
  const [userData, setUserData] = useState({
    role: role,
    crop: crop,
    zoom: 1,
    isAvatar: true,
    croppedAreaPixels: croppedAreaPixels,
    profileImg1: profileAvatar,
    croppedImage: profileAvatar,
    resume: resume,
    resumeFormat: "",
    imagePublicId: "",
    resumePublicId: "",
    croppedImagePublicId: "",
    firstName: "",
    lastName: "",
    profileTitle: "",
    email: "",
    phone1: "",
    phone2: "",
    salary: "",
    payType: "",
    checked: false,
    dob: "",
    gender: "",
    address1: "",
    address2: "",
    city: null,
    state: null,
    stateId: "0",
    country: null,
    countryId: "0",
    totalExperience: "",
    calculatedExp: "",
    nationality: "",
    postalCode: "",
    skills: [],
    socialMedia: {
      linkedin: "",
      github: "",
      portfolio: ""
    },
    educationDetails: [{
      school: "",
      degree: "",
      stream: "",
      percentage: "",
      startMonth: "-1",
      startYear: new Date().getFullYear(),
      endMonth: "-1",
      endYear: new Date().getFullYear(),
      currentWorking: false,
      checked: false,
    }],
    educationErrors: [{
      school: "",
      degree: "",
      stream: "",
      startMonth: "",
      startYear: "",
      endMonth: "",
      endYear: "",
    }],
    experienceDetails: [{
      position: "",
      company: "",
      startMonth: "-1",
      startYear: new Date().getFullYear(),
      endMonth: "-1",
      endYear: new Date().getFullYear(),
      currentWorking: false,
      checked: false,
    }],
    experienceErrors: [{
      position: "",
      company: "",
      startMonth: "",
      startYear: "",
      endMonth: "",
      endYear: "",
    }]
  })

  const [count, setCount] = useState(1);
  const [nextPage, setNextPage] = useState(true);
  const [previousPage, setPreviousPage] = useState(false);



  //displaying components based on count



  return (
    <div>

      {nextPage && count === 1 ? (
        <BasicDetails userData={userData} setUserData={setUserData} nextPage={nextPage} previousPage={previousPage} setPreviousPage={setPreviousPage} count={count} setCount={setCount} mode={mode} />
      )
        :
        nextPage && count === 2 ? (
          <SkillsDetails userData={userData} setUserData={setUserData} nextPage={nextPage} previousPage={previousPage} setPreviousPage={setPreviousPage} count={count} setCount={setCount} />
        ) : nextPage && count === 3 ? (
          <EducationDetails userData={userData} setUserData={setUserData} nextPage={nextPage} previousPage={previousPage} setPreviousPage={setPreviousPage} count={count} setCount={setCount} />
        ) : nextPage && count === 4 ? (
          <ExperienceDetails setAddUserClicked={setAddUserClicked} userData={userData} setUserData={setUserData} nextPage={nextPage} previousPage={previousPage} setPreviousPage={setPreviousPage} count={count} setCount={setCount} mode={mode} setRefreshCount={setRefreshCount} />) : ""}
      {/* </Slide> */}
    </div>
  );
};

export default AddUsers;
