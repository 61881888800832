import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import React from "react";
import EnhancedTableHead from "../enhancedTableHead";
import ReviewRows from "./reviewRows";

const columns = [
    {
        id: "salesforceReviewId",
        label: "Salesforce Review Id",
    },
    {
        id: "rating",
        label: "rating",
        minWidth: 170,
        numeric: true,
        disablePadding: false,
        align: "left",
    },
    {
        id: "comments",
        label: "Comments",
        maxWidth: 250,
        numeric: false,
        disablePadding: false,
        align: "left",
    },
    {
        id: "relatedInterview",
        label: "Related Interview",
        maxWidth: 250,
        numeric: false,
        disablePadding: false,
        align: "left",
    },
];

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const useStyles = makeStyles({
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1,
    },
});

export default function ReviewTables(props) {
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const [order, setOrder] = React.useState("asc");
    const [orderBy, setOrderBy] = React.useState("status");

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    // need to customize these methods, for intake of interview objects.
    function createData(salesforceReviewId, rating, comments, relatedInterview) {

        if (comments === undefined) {
            comments = "No Comment";
        }

        if (relatedInterview === undefined) {
            relatedInterview = "No Interview";
        }

        return {
            salesforceReviewId,
            rating,
            comments,
            relatedInterview
        };
    }

    const rows = [];
    if (props.reviews[0] === "There are no reviews") {
        rows.push(createData("", 3.0, ""));
    } else {
        props.reviews.forEach(({ Id, Rating_Value__c, Comments__c, 	Interview_id__c }) => {
            rows.push(createData(Id, Rating_Value__c, Comments__c, 	Interview_id__c ));
        });
    }

    return (
        <>
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="customized table">
                    <EnhancedTableHead
                        classes={classes}
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        headCells={columns.slice(1)}
                    />
                    <TableBody>
                        {stableSort(rows, getComparator(order, orderBy))
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row, idx) => {
                                return <ReviewRows key={`row-${row["salesforceReviewId"]}`} row={row} columns={columns} />;
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </>
    );
}
