import { RECEIVE_INTERVIEW, RECEIVE_INTERVIEWS, RECEIVE_INTERVIEW_ERRORS, RECEIVE_UPDATED_INTERVIEW } from "../actions/Interviews";

export default (state = ['No alerts for this user'], action) => {
    Object.freeze(state);

    switch (action.type) {
        case RECEIVE_INTERVIEWS:
            // console.log(action.interviews.data);
            if (action.interviews.data.length <= 0) return state;
            return action.interviews.data;
        case RECEIVE_INTERVIEW:
            return Object.merge([], ...state, action.interview.data);
        case RECEIVE_UPDATED_INTERVIEW:
            let updatedInterviews = state.map((interview) => {
                if (interview.Id === action.interview.data.salesforceInterviewRecordId) {
                    // console.log(interview.Id === action.interview.data.salesforceInterviewRecordId);
                    // console.log(action.interview.data);
                    // console.log('found a match');
                    interview.Status__c = action.interview.data.status;
                    // console.log(interview);
                    return interview;
                }

                return interview;
            });

            // console.log(updatedInterviews);
            return updatedInterviews;
        case RECEIVE_INTERVIEW_ERRORS:
            return state;
        default:
            return state;
    }
};
