import React, { useEffect, useState, useRef, useCallback } from 'react'


//MUI Imports
import Slide from "@mui/material/Slide";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import AddIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from "@mui/icons-material/Delete";
import SchoolIcon from '@mui/icons-material/School';
import axios from 'axios'

//Snackbar
import UserSnackbar from '../../../snackBars/UserSnackbar';

const ExperienceDetails = ({ setRefreshCount, userData, setAddUserClicked, setUserData, nextPage, previousPage, setPreviousPage, count, setCount, mode, selectedUserId }) => {

    const id = sessionStorage.getItem("id");
    const companyId = sessionStorage.getItem("companyId");
    //form states

    const thisYear = new Date().getFullYear();
    const currentYear = thisYear;
    const [experienceDetails, setExperienceDetails] = useState(userData.experienceDetails)
    let fullExperience = userData.calculatedExp;
    //snackBar states
    const [openSnackbar, setOpenSnackbar] = useState(false)
    const [message, setMessage] = useState("")
    const [openCloseDialog, setOpenCloseDialog] = useState(false);

    const [disableSaveButton, setDisableSaveButton] = useState(false)

    const options = [];
    const minOffset = 0;
    const maxOffset = 50;
    for (let i = minOffset; i <= maxOffset; i++) {
        const year = currentYear - i;
        options.push(<option value={year}>{year}</option>);
    }

    // initialization of errors
    const [errors, setErrors] = useState(userData.experienceErrors);

    const getTotalExperience = (startDate, endDate) => {
        let yearDifference, monthDifference, remainingMonths;
        let totalMonths = 0;
        let totalYears = 0;

        let startMonth = startDate.getMonth();
        let endMonth = endDate.getMonth() + 1;
        yearDifference = endDate.getFullYear() - startDate.getFullYear();
        monthDifference = startMonth - endMonth;

        totalMonths = yearDifference * 12 - monthDifference;

        totalYears = Math.floor(totalMonths / 12);
        remainingMonths = totalMonths % 12;
        const final = remainingMonths
            ? totalYears
                ? `${totalYears}` +
                (totalYears === 1 ? " year " : " years ") +
                `${remainingMonths}` +
                (remainingMonths === 1 ? " month" : " months")
                : `${remainingMonths} ` +
                (remainingMonths === 1 ? " month " : " months ")
            : `${totalYears} ` + (totalYears === 1 ? " year " : " years ");

        return final;
    };


    //Submit handler
    const submitData = () => {

        setDisableSaveButton(true)

        if (!userData.totalExperience) {
            experienceDetails.forEach(function (experience) {
                if (experience.checked) {
                    fullExperience = getTotalExperience(
                        new Date(experience.startYear, experience.startMonth),
                        new Date(new Date().getFullYear(), new Date().getMonth())
                    )
                } else {
                    fullExperience = getTotalExperience(
                        new Date(experience.startYear, experience.startMonth),
                        new Date(experience.endYear, experience.endMonth)
                    )
                }
            })
        }

        const finalData = [{
            resume: userData.resume,
            resumeFormat: userData.resumeFormat,
            role: userData.role,
            zoom: userData.zoom,
            crop: userData.crop,
            isAvatar: userData.isAvatar,
            croppedAreaPixels: userData.croppedAreaPixels,
            profileImg1: userData.profileImg1,
            croppedImage: userData.croppedImage,

            imagePublicId: userData.imagePublicId,
            resumePublicId: userData.resumePublicId,
            croppedImagePublicId: userData.croppedImagePublicId,
            firstName: userData.firstName,
            lastName: userData.lastName,
            profileTitle: userData.profileTitle,
            email: userData.email,
            phone1: userData.phone1,
            phone2: userData.phone2,
            salary: userData.salary,
            payType: userData.payType,
            checked: userData.checked,
            dob: userData.dob,
            gender: userData.gender,
            address1: userData.address1,
            address2: userData.address2,
            city: userData.city,
            state: userData.state,
            stateId: userData.stateId,
            country: userData.country,
            countryId: userData.countryId,
            totalExperience: userData.totalExperience,
            calculatedExp: fullExperience,
            nationality: userData.nationality,
            postalCode: userData.postalCode,
            skills: userData.skills,
            educationDetails: userData.educationDetails,
            experienceDetails: experienceDetails,
            linkedin: userData.linkedinURL,
            github: userData.githubURL,
            portfolio: userData.portfolioURL,
        }]
        const formData = new FormData();
        formData.append("finalData", JSON.stringify(finalData))
        if (mode == "add") {
            userData.role === "candidate" ? (
                axios.post(`/api/admin/internal-candidates`, formData, {
                    headers: { "Content-Type": "multipart/form-data" },
                }).then((res) => {
                    setMessage("Candidate Added Successfully")
                    setOpenSnackbar(true)
                    setDisableSaveButton(false)
                    setRefreshCount(prev => prev + 1)
                    setTimeout(() => {
                        setAddUserClicked(false)
                    }, 2000)
                }).catch((err) => {
                    if (err.response.status === 404) {
                        setMessage("Candidate email already exists")
                        setOpenSnackbar(true)
                        setDisableSaveButton(false)
                    }
                })
            ) : (
                axios.post(`/api/admin/internal-screeners`, formData, { headers: { "Content-Type": "multipart/form-data" }, })
                    .then((res) => {
                        setMessage("Screener Added Successfully")
                        setOpenSnackbar(true)
                        setDisableSaveButton(false)
                        setRefreshCount(prev => prev + 1)
                        setTimeout(() => {
                            setAddUserClicked(false)
                        }, 2000)
                    }).catch((err) => {
                        if (err.response.status === 404) {
                            setMessage("Screener email already exists")
                            setOpenSnackbar(true)
                            setDisableSaveButton(false)
                        }
                    })
            )
        } else {
            userData.role === "candidate" ? (
                axios.post(`/api/admin/edit-internal-candidates/${selectedUserId}`, formData, {
                    headers: { "Content-Type": "multipart/form-data" },
                }).then((res) => {
                    setMessage("Candidate Edited Successfully")
                    setOpenSnackbar(true)
                    setDisableSaveButton(false)
                    setRefreshCount(prev => prev + 1)
                    setTimeout(() => {
                        setAddUserClicked(false)
                    }, 2000)
                }).catch((err) => {
                    setDisableSaveButton(false)
                    console.log(err)
                })
            ) : (
                axios.post(`/api/admin/edit-internal-screeners/${selectedUserId}`, formData, { headers: { "Content-Type": "multipart/form-data" }, })
                    .then((res) => {
                        setMessage("Screener Edited Successfully")
                        setOpenSnackbar(true)
                        setDisableSaveButton(false)
                        setRefreshCount(prev => prev + 1)
                        setTimeout(() => {
                            setAddUserClicked(false)
                        }, 2000)
                    }).catch((err) => {
                        setDisableSaveButton(false)
                        console.log(err)
                    })
            )
        }
    }
    const handleSubmit = () => {
        let experience = [...experienceDetails];
        let error = [...errors];

        experience.map((item, index) => {

            if (item.startMonth == "-1" && item.endMonth == "-1") {
                if (!item.position && !item.company) {
                    error[index].startMonth = ""
                    error[index].endMonth = ""
                    error[index].position = ""
                    error[index].company = ""
                }
                else if (!item.position && item.company) {
                    error[index].position = "Position is required"
                    error[index].company = ""
                }
                else if (!item.company && item.position) {
                    error[index].company = "Company is required"
                    error[index].position = ""
                }
                else {
                    error[index].startMonth = ""
                    error[index].endMonth = ""
                    error[index].position = ""
                    error[index].company = ""
                }
                setErrors(error)
            }
            else if (item.startMonth !== "-1" && item.endMonth !== "-1") {
                if (!item.position && !item.company) {
                    error[index].company = "Company is required"
                    error[index].position = "Position is required"
                }
                else if (!item.position && item.company) {
                    error[index].position = "Position is required"
                    error[index].company = ""
                }
                else if (!item.company && item.position) {
                    error[index].company = "Company is required"
                    error[index].position = ""
                }
                else {
                    error[index].company = ""
                    error[index].position = ""
                }
                setErrors(error)
            }
            else if (item.startMonth !== "-1") {
                if (!item.company && !item.position) {
                    error[index].company = "Company is required"
                    error[index].position = "Position is required"
                    // error[index].endMonth = "End Month is required"
                }
                else if (!item.company) {
                    error[index].company = "Company is required"
                    // error[index].endMonth = "End Month is required"
                }
                else if (!item.position) {
                    error[index].position = "Position is required"
                    // error[index].endMonth = "End Month is required"
                }
                // else if (item.endMonth === "-1") {
                //     error[index].endMonth = "End Month is required"
                // }
                else {
                    error[index].company = ""
                    error[index].position = ""
                    error[index].startMonth = ""
                }
                setErrors(error)
            }
            else if (item.endMonth !== "-1" || item.checked === true) {
                if (!item.company && !item.position) {
                    error[index].company = "Company is required"
                    error[index].position = "Position is required"
                    // error[index].startMonth = "Start Month is required"
                }
                else if (!item.position) {
                    error[index].position = "Position is required"
                    // error[index].startMonth = "Start Month is required"
                }
                else if (!item.company) {
                    error[index].company = "Company is required"
                    // error[index].startMonth = "Start Month is required"
                }
                else if (item.startMonth === "-1") {
                    error[index].startMonth = "Start Month is required"
                }
                setErrors(error)
            }

            if (
                (item.startYear > item.endYear && item.checked === false) ||
                (item.endYear == item.startYear && item.endMonth < item.startMonth && item.checked === false)
            ) {
                error[index].endMonth = "End date cannot be less than start date"
                setErrors(error)
            }
        })

        const condition = errors.find((item) => {
            return item.position !== "" || item.company !== "" || item.startMonth !== "" || item.startYear !== "" || item.endMonth !== "" || item.endYear !== "";
        });

        if (!condition) {
            const experienceObj = {
                experienceDetails: experienceDetails,
                experienceErrors: errors
            }
            let data = { ...userData, ...experienceObj }
            setUserData(data)
            submitData();
            setPreviousPage(false)
        } else {

        }
    }


    const goToPreviousPage = () => {

        //store current entered data
        const experienceObj = {
            experienceDetails: experienceDetails,
            experienceErrors: errors
        }
        let data = { ...userData, ...experienceObj }
        setUserData(data)
        setPreviousPage(true)
        if (nextPage) {
            setCount(count - 1)
        } else {
            setCount(count + 1)
        }
    }


    // handle input field change
    const handleChange = (index, evnt) => {
        const { name, value } = evnt.target;

        const list = [...experienceDetails];
        list[index][name] = value;
        setExperienceDetails(list);

        if (name === "position") {
            errors[index].position = "";
        } else if (name === "company") {
            errors[index].company = "";
        } else if (name === "startMonth") {
            if (list[index].startMonth < list[index].endMonth || list[index].startMonth == list[index].endMonth) { errors[index].endMonth = "" }
            errors[index].startMonth = "";
        } else if (name === "startYear") {
            if (list[index].startYear < list[index].endYear || list[index].startYear == list[index].endYear) { errors[index].endMonth = "" }
            errors[index].startMonth = "";
        } else if (name === "endMonth") {
            if (list[index].startMonth < list[index].endMonth || list[index].startMonth == list[index].endMonth) { errors[index].endMonth = "" }
            errors[index].endMonth = "";
        } else if (name === "endYear") {
            errors[index].endMonth = "";
        } else {

        }
    };

    const checkHandler = (index) => {
        const list = [...experienceDetails];
        list[index].checked = !list[index].checked;
        list[index].currentWorking = !list[index].currentWorking;


        if (list[index].checked) {
            list[index].endMonth = "Present";
            list[index].endYear = "";
            errors[index].endMonth = "";
        }
        else {
            list[index].endMonth = "-1";
            list[index].endYear = thisYear;
            errors[index].endMonth = "";
        }
        setExperienceDetails(list);

    };

    //error handling
    const rowHandling = () => {
        let experience = [...experienceDetails];
        let error = [...errors];
        experience.map((item, index) => {

            if (item.startMonth == "-1" && item.endMonth == "-1") {
                if (!item.position && !item.company) {
                    // error[index].startMonth = "Start Month is required"
                    // error[index].endMonth = "End month is required"
                    error[index].position = "Position is required"
                    error[index].company = "Company is required"
                }
                else if (!item.position && item.company) {
                    error[index].position = "Position is required"
                    error[index].company = ""
                }
                else if (!item.company && item.position) {
                    error[index].company = "Company is required"
                    error[index].position = ""
                }
                else {
                    // error[index].startMonth = "Start Month is required"
                    // error[index].endMonth = "End Month is required"
                    error[index].position = ""
                    error[index].company = ""
                }
                setErrors(error)
            }
            else if (item.startMonth !== "-1" && item.endMonth !== "-1") {
                if (!item.position && !item.company) {
                    error[index].company = "Company is required"
                    error[index].position = "Position is required"
                }
                else if (!item.position && item.company) {
                    error[index].position = "Position is required"
                    error[index].company = ""
                }
                else if (!item.company && item.position) {
                    error[index].company = "Company is required"
                    error[index].position = ""
                }
                else {
                    error[index].company = ""
                    error[index].position = ""
                }
                setErrors(error)
            }
            else if (item.startMonth !== "-1") {
                if (!item.company && !item.position) {
                    error[index].company = "Company is required"
                    error[index].position = "Position is required"
                    // error[index].endMonth = "End Month is required"
                }
                else if (!item.company && item.position) {
                    error[index].company = "Company is required"
                    error[index].position = ""
                    // error[index].endMonth = "End Month is required"
                }
                else if (!item.position && item.company) {
                    error[index].position = "Position is required"
                    error[index].company = ""
                    error[index].endMonth = "End Month is required"
                }
                // else if (item.endMonth === "-1") {
                //     error[index].endMonth = "End Month is required"
                // }
                else {
                    error[index].company = ""
                    error[index].position = ""
                    error[index].startMonth = ""
                }
                setErrors(error)
            }
            else if (item.endMonth !== "-1" || item.checked === true) {
                if (!item.company && !item.position) {
                    error[index].company = "Company is required"
                    error[index].position = "Position is required"
                    // error[index].startMonth = "Start Month is required"
                }
                else if (!item.position) {
                    error[index].position = "Position is required"
                    // error[index].startMonth = "Start Month is required"
                }
                else if (!item.company) {
                    error[index].company = "Company is required"
                    // error[index].startMonth = "Start Month is required"
                }
                // else if (item.startMonth === "-1") {
                //     error[index].startMonth = "Start Month is required"
                // }
                setErrors(error)
            }


            if (
                (item.startYear > item.endYear && item.checked === false) ||
                (item.endYear == item.startYear && item.endMonth < item.startMonth && item.checked === false)
            ) {
                error[index].endMonth = "End date cannot be less than start date"
                setErrors(error)
            }
        })
        setErrors(error);
    };

    //form handler
    const formReplicationHandler = () => {
        rowHandling(); //to check for errors
        const condition = errors.find((item) => {
            return item.position !== "" || item.company !== "" || item.startMonth !== "" || item.startYear !== "" || item.endMonth !== "" || item.endYear !== "";
        });

        if (condition) {
            return;
        } else {
            setExperienceDetails([
                ...experienceDetails,
                {
                    position: "",
                    company: "",

                    startMonth: "-1",
                    startYear: thisYear,
                    endMonth: "-1",
                    endYear: thisYear,
                    currentWorking: false,
                    checked: false,
                },
            ]);
            setErrors([
                ...errors,
                {
                    position: "",
                    company: "",
                    startMonth: "",
                    startYear: "",
                    endMonth: "",
                    endYear: "",
                },
            ]);
        }
    };

    //handle delete full row
    const removeInputFields = (index) => {
        const rows = [...experienceDetails];
        rows.splice(index, 1);
        errors.splice(index, 1);
        setExperienceDetails(rows);
    };

    //months for dropdown
    const months = [
        { value: "-1", label: "Month" },
        { value: "00", label: "January" },
        { value: "01", label: "February" },
        { value: "02", label: "March" },
        { value: "03", label: "April" },
        { value: "04", label: "May" },
        { value: "05", label: "June" },
        { value: "06", label: "July" },
        { value: "07", label: "August" },
        { value: "08", label: "September" },
        { value: "09", label: "October" },
        { value: "10", label: "November" },
        { value: "11", label: "December" },
    ];

    return (
        <div>
            <Slide direction={"left"} in={true} timeout={500}>
                <Box sx={{ flexGrow: 1, background: "#faf9f6" }}>
                    <Card
                        id="main-edit"
                        className=" form-card table-card"
                        style={{
                            padding: "15px 20px 18px 20px ",
                            boxShadow: "none",
                        }}
                    >

                        <Grid
                            container
                            spacing={0}
                            direction="row"
                            className="add-recruiter-card-container"
                        >
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={5}
                                lg={12}
                            >
                                <div style={{ display: "flex", gap: "10px" }}>
                                    <SchoolIcon sx={{ color: "#2c3e50" }} />
                                    <h5 style={{ color: "#283848" }}>Experience Details</h5>
                                </div>
                            </Grid>
                            <Grid container spacing={0} justifyContent="flex-end">

                                <Grid
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}

                                >
                                    <div >
                                        <form noValidate style={{ display: "flex", flexDirection: "column" }}>
                                            {experienceDetails.map((data, index) => {
                                                const { position, company, startMonth, startYear, endMonth, endYear, currentWorking, checked } = data;
                                                return (<>

                                                    <Grid
                                                        xs={12}
                                                        sm={12}
                                                        md={11}
                                                        lg={11}
                                                        sx={{
                                                            display: "flex"
                                                        }}
                                                        className="education-details-form-container"
                                                    >
                                                        <Grid
                                                            xs={1}
                                                            sm={1}
                                                            md={0.5}
                                                            lg={0.5}
                                                            sx={{
                                                                position: "relative",
                                                                top: `${index === 0 ? "43px" : "63px"}`,
                                                                height: "fit-content"
                                                            }}

                                                        >
                                                            <div >{experienceDetails.length !== 1 ? `${index + 1}.` : ""}</div>
                                                        </Grid>
                                                        <Grid
                                                            xs={12}
                                                            sm={12}
                                                            md={11}
                                                            lg={11}

                                                        >
                                                            <div className="input-container" >
                                                                {index > 0 ?
                                                                    <>
                                                                        <br />
                                                                        <Divider
                                                                            sx={{ borderColor: "lightgray" }}
                                                                        // id="divider"
                                                                        />

                                                                    </> : ""}
                                                                <div className="split-inputs">
                                                                    <div class="input-field-modal">
                                                                        <label className="date-label">Position</label>
                                                                        <input
                                                                            className={`${errors[index]?.position && "input-field-error"}`}
                                                                            class="position"
                                                                            type="text"
                                                                            name="position"
                                                                            value={position}
                                                                            onChange={(evnt) => handleChange(index, evnt)}
                                                                            placeholder="Ex: Data Scientist"
                                                                        />
                                                                        <div className='error-div' style={{ height: "20px" }}>
                                                                            <span className="text-danger">{errors[index]?.position}</span>
                                                                        </div>
                                                                    </div>


                                                                    <div class="input-field-modal">
                                                                        <label className="date-label">Company</label>
                                                                        <input
                                                                            className={`${errors[index]?.company && "input-field-error"}`}
                                                                            class="company"
                                                                            type="text"
                                                                            name="company"
                                                                            value={company}
                                                                            onChange={(evnt) => handleChange(index, evnt)}
                                                                            placeholder="Ex: Microsoft"
                                                                        />
                                                                        <div className='error-div' style={{ height: "20px" }}>
                                                                            <span className="text-danger">{errors[index]?.company}</span>
                                                                        </div>
                                                                    </div>


                                                                </div>

                                                                <label className="date-label" >Start Date</label>
                                                                <div className="split-inputs">

                                                                    <div class="input-field-modal">

                                                                        <select name='startMonth' value={startMonth}
                                                                            onChange={(evnt) => handleChange(index, evnt)}
                                                                            className={`${errors[index]?.startMonth && "input-field-error"}`}
                                                                            style={{ outline: "none" }}>

                                                                            {months.map((option) => {
                                                                                return <option value={option.value} disabled={currentYear == startYear && Number(option.value) > new Date().getMonth()}>{option.label}</option>
                                                                            })}
                                                                        </select>

                                                                        <span className="text-danger">{errors[index]?.startMonth}</span>

                                                                    </div>
                                                                    <div class="input-field-modal">

                                                                        <select value={startYear}
                                                                            name="startYear"
                                                                            onChange={(evnt) => handleChange(index, evnt)}
                                                                            style={{ outline: "none" }}>
                                                                            {options}
                                                                        </select>
                                                                    </div>
                                                                </div>

                                                                <div className="input-field">
                                                                    <FormGroup className="input-field-checkbox">
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox
                                                                                    size="small"
                                                                                    sx={{
                                                                                        color: "gray",
                                                                                        "&.Mui-checked": {
                                                                                            color: "#F06161",
                                                                                        },
                                                                                    }}
                                                                                    checked={checked}
                                                                                    value={checked}
                                                                                    onClick={() => checkHandler(index)}
                                                                                />
                                                                            }
                                                                            label="Currently Working here"
                                                                        />
                                                                    </FormGroup>
                                                                </div>

                                                                <label className="date-label" >End Date</label>
                                                                <div className="split-inputs">

                                                                    <div class="input-field-modal">

                                                                        <select disabled={currentWorking}
                                                                            value={endMonth}
                                                                            onChange={(evnt) => handleChange(index, evnt)}
                                                                            name="endMonth"
                                                                            style={{ outline: "none" }}
                                                                            className={`${errors[index]?.endMonth && "input-field-error"}`}
                                                                        >

                                                                            {months.map((option) => {
                                                                                return <option value={option.value} disabled={currentYear == endYear && Number(option.value) > new Date().getMonth()}>{option.label}</option>
                                                                            })}
                                                                        </select>
                                                                        <span className="text-danger">{errors[index]?.endMonth}</span>
                                                                    </div>

                                                                    <div class="input-field-modal">
                                                                        <select disabled={currentWorking}
                                                                            value={endYear}
                                                                            onChange={(evnt) => handleChange(index, evnt)}
                                                                            name="endYear"
                                                                            style={{ outline: "none" }}>
                                                                            {options}
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                {experienceDetails.length !== 1 && (
                                                                    <div style={{ display: "flex", justifyContent: "flex-end", width: "100%", marginTop: "10px" }}>
                                                                        <DeleteIcon
                                                                            sx={{ marginBottom: "1px" }}
                                                                            onClick={() => removeInputFields(index)}
                                                                            fontSize="small"
                                                                            className="deleteIcon"
                                                                        />
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </Grid>

                                                    </Grid>
                                                </>)
                                            })}
                                        </form>
                                    </div>
                                </Grid>
                                <Grid
                                    xs={12}
                                    sm={12}
                                    md={0.5}
                                    lg={0.5}

                                >
                                    <AddIcon className="edit-icon" onClick={formReplicationHandler} />
                                </Grid>
                            </Grid>
                            <div className="button-container navigation-btn-container" >
                                <button className="btn-style outlined-btn outlined-btn-animation" onClick={goToPreviousPage}>
                                    {" "}
                                    Previous
                                </button>
                                <button className="btn-style filled-btn filled-btn-animation" onClick={handleSubmit} disabled={disableSaveButton}>
                                    {" "}
                                    Save
                                </button>

                            </div>
                        </Grid>
                    </Card>
                </Box>
            </Slide>

            <UserSnackbar
                openSnackbar={openSnackbar}
                setOpenSnackbar={setOpenSnackbar}
                message={message}
            />


        </div>
    )
}

export default ExperienceDetails