import React, { useState, useEffect } from 'react'
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CalendarIcon from "@mui/icons-material/CalendarMonth";
import Grid from "@mui/material/Grid";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import Tooltip from "@mui/material/Tooltip";
import Phone from "@mui/icons-material/LocalPhoneOutlined";
import LocationIcon from "@mui/icons-material/LocationOnOutlined";
import { Divider, Switch, FormGroup, FormControlLabel } from "@mui/material";
import { Dialog, DialogContent } from "@mui/material";
import DeleteMessageIcon from "@mui/icons-material/ErrorOutlineOutlined";
import Axios from 'axios';
import UserSnackbar from '../../snackBars/UserSnackbar';

function AccountSettings({ companyDetails, setTile, setSettingsView }) {
    console.log(companyDetails)

    const [activeState, setActiveState] = useState(true);

    //Dialog states
    const [openCloseDialog, setOpenCloseDialog] = useState(false)

    //snackbar states
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('')

    useEffect(() => {
        console.log("First time", companyDetails.isActive)
        setActiveState(companyDetails.isActive)
    }, [])


    const freezeHandler = (e) => {
        e.preventDefault()
        console.log("submitted", activeState)
        Axios.post(`/api/admin/companyAccount/${companyDetails._id}`, { activeState }).then(res => {
            console.log(res)
            setOpenCloseDialog(false)
            setMessage('Status saved successfully')
            setOpen(true)
            setTimeout(() => {
                setSettingsView("main")
                setTile(false)
            }, 2000)
        }

        ).catch(error => {
            console.log(error)
            setMessage('Something went wrong')
            setOpen(true)
        })
    }



    return (
        <div
            className="root-div"
            id="restrict-scroll"
            style={{ overflowY: "hidden" }}
        >
            <Box sx={{ flexGrow: 1 }}>
                <Card id="main-edit" className="main-card profile-card account-details-card" style={{ padding: "30px 20px 0px 40px", position: 'relative' }}>
                    {/* 1st Grid container */}


                    <div style={{ display: "flex", alignItems: 'center', position: 'absolute', top: '34px', right: '30px' }}>
                        <FormGroup>
                            <FormControlLabel
                                style={{ marginRight: 0 }}
                                onChange={() => setActiveState(!activeState)}
                                //   value={usersDBScreener}
                                control={<Switch defaultChecked checked={activeState} />}

                            />
                        </FormGroup>

                        <div className="user-status-button" >
                            <button className={activeState ? 'Active' : "Frozen"}>
                                {activeState ? 'Active' : "Frozen"}
                            </button>
                        </div>
                    </div>



                    <div className="button-container" style={{ position: 'absolute', right: '37px', bottom: '8px', zIndex: 1 }} >
                        <button className="btn-style filled-btn filled-btn-animation" onClick={() => setOpenCloseDialog(true)} >Save</button>
                    </div>

                    <Grid
                        container
                        spacing={0}
                        direction="row"
                        className="profile-card-container"
                    >
                        {/* <div className="edit-icon-mobile-container">
                            <Tooltip title="Edit">
                                <EditIcon
                                    className="edit-icon-mobile"
                                    onClick={mainCardEditOpenHandler}
                                />
                            </Tooltip>
                        </div> */}
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={2.5}
                            lg={2}
                            xl={1.5}
                            className="container-profile"
                        >
                            <img
                                src={
                                    // defaultAvatar
                                    //     ? profileAvatar
                                    //     : interacted
                                    //         ? croppedImg
                                    //         : profileImage
                                    companyDetails.profileImage
                                }
                                // className={`${imageWidth}`}
                                className="sidebar-open-image"
                                style={{ objectFit: "cover" }}
                            />

                            <div class="social-profile-card-container">
                                <a href="#" className="social">
                                    <i class="fab fa-google-plus-g"></i>
                                </a>

                                <a href="#" class="social">
                                    <i class="fab fa-linkedin-in"></i>
                                </a>
                                <a href="#" class="social">
                                    <i class="fab fa-facebook-f"></i>
                                </a>
                            </div>
                        </Grid>

                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={6}
                            lg={7.2}
                            xl={7.5}
                            className="container-heading-title"
                        >
                            <div>
                                <h2>{companyDetails.companyName}</h2>
                                <h5>{companyDetails.profileTitle}</h5>
                            </div>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={2.5}
                            lg={2.5}
                            className="container-heading-buttons"
                        >


                            {/* <div>
                                <Tooltip title="Edit">
                                    <EditIcon
                                        id="main-edit"
                                        className="edit-icon"
                                        onClick={mainCardEditOpenHandler}
                                    />
                                </Tooltip>
                            </div> */}
                        </Grid>
                    </Grid>

                    {/* 2nd Grid container */}
                    <Grid
                        container
                        spacing={0}
                        direction="row"
                        className="info-container company-profile"
                        justifyContent="flex-end"
                    >
                        <Grid
                            item
                            xs={0}
                            sm={0}
                            md={0}
                            lg={1.8}
                            xl={1.2}
                            className="container-profile"
                        ></Grid>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={4.5}
                            lg={3.2}
                            xl={3.1}
                            className="about-container"
                        >
                            <h4>About</h4>
                            <p>{companyDetails.about ? companyDetails.about : "Tell us about yourself"}</p>
                        </Grid>

                        <Grid
                            item
                            xs={12}
                            sm={5.5}
                            md={3}
                            lg={3}
                            xl={3}
                            className="info-container1 company-container1"
                        >
                            <div className="info-container-height">
                                <div className="info-container1-heading">
                                    <Phone className="icon" />
                                    Phone
                                </div>
                                <p>+{companyDetails.companyPhone}</p>
                            </div>
                            <div className="info-container-height">
                                <div className="info-container1-heading">
                                    <LocationIcon className="icon" />
                                    Business Address
                                </div>
                                {companyDetails.location.address ? (
                                    <p>
                                        {companyDetails.location.address}, {companyDetails.location.city}, {companyDetails.location.state}
                                        <br></br>
                                        {companyDetails.location.country} - {companyDetails.location.postalCode}
                                    </p>
                                ) : (
                                    <></>
                                )}
                            </div>
                        </Grid>

                        <Grid
                            item
                            xs={12}
                            sm={5.5}
                            md={3}
                            lg={3}
                            xl={3}
                            className="info-container2 company-container1"
                        >
                            <div className="info-container-height">
                                <div className="info-container1-heading">
                                    <CalendarIcon className="icon" />
                                    In Business Since
                                </div>
                                <p>{companyDetails.startYear}</p>
                            </div>
                            <div className="info-container-height">
                                <div className="info-container1-heading">
                                    <MailOutlineIcon className="icon" />
                                    Email
                                </div>
                                <p>{companyDetails.companyEmail}</p>
                            </div>
                        </Grid>
                    </Grid>
                    {/* 3rd container */}
                    <Grid
                        container
                        spacing={1}
                        direction="row"
                        className="profile-progress-bar"
                    >
                    </Grid>
                </Card>

                <div className="cards-list">
                    <Grid className="card-tiles" xs={12}>
                        <Card
                            id="personal-details-edit"
                            className="side-card profile-card account-settings-poc"
                        >
                            {/* 1st Grid container */}
                            <Grid
                                container
                                spacing={1}
                                direction="row"
                                className="personal-card sub-card"
                            >
                                <Grid
                                    item
                                    xxs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                    className="personal-card-title"
                                >
                                    <div className="personal-card-heading">
                                        <p>Point Of Contact</p>
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                    className="personal-details"
                                >
                                    <div className="details-block">
                                        {companyDetails.PocDetails.name ? (
                                            <>
                                                <div className="personal-info">
                                                    <p className="personal-info-heading">
                                                        Name: &nbsp;
                                                    </p>
                                                    <p className="personal-info-content">
                                                        {companyDetails.PocDetails.name}
                                                    </p>
                                                </div>
                                            </>
                                        ) : (
                                            <></>
                                        )}

                                        {companyDetails.PocDetails.email ? (
                                            <>
                                                <div className="personal-info">
                                                    <p className="personal-info-heading">
                                                        Email: &nbsp;
                                                    </p>
                                                    <p className="personal-info-content">
                                                        {companyDetails.PocDetails.email}
                                                    </p>
                                                </div>
                                            </>
                                        ) : (
                                            <></>
                                        )}

                                        {companyDetails.PocDetails.phone ? (
                                            <>
                                                <div className="personal-info">
                                                    <p className="personal-info-heading">
                                                        Phone: &nbsp;
                                                    </p>
                                                    <p className="personal-info-content">
                                                        +{companyDetails.PocDetails.phone}
                                                    </p>
                                                </div>
                                            </>
                                        ) : (
                                            <></>
                                        )}

                                        {companyDetails.PocDetails.jobTitle ? (
                                            <>
                                                <div className="personal-info">
                                                    <p className="personal-info-heading">
                                                        Job Title: &nbsp;
                                                    </p>
                                                    <p className="personal-info-content">
                                                        {companyDetails.PocDetails.jobTitle}
                                                    </p>
                                                </div>
                                            </>
                                        ) : (
                                            <></>
                                        )}
                                    </div>
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                </div>
            </Box>

            {/* Save confimation dialog */}
            <Dialog
                fullWidth
                maxWidth="xs"
                open={openCloseDialog}
                onClose={setOpenCloseDialog}
                className="experience-edit-dialog"
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent className="dialog-content">
                    <Grid container spacing={0} justifyContent="center">
                        <Grid
                            item
                            xs={11.5}
                            sm={11.5}
                            md={11.5}
                            lg={11.5}
                            className="experience-delete-grid"
                        >
                            <div className="delete-message-icon">
                                <DeleteMessageIcon fontSize="small" />
                            </div>
                            <div className="delete-dialog-content">
                                <h5>Are you sure?</h5>
                                <p>Do you want to {activeState ? "Activate" : "Freeze"} this account?</p>
                                {/* <p>All your current changes will be lost!</p> */}
                            </div>
                        </Grid>
                    </Grid>
                </DialogContent>
                <div className="alert-buttons-container">
                    <button className="warning-btn-style filled-btn filled-btn-animation" onClick={() => setOpenCloseDialog(false)}>
                        {" "}
                        Cancel
                    </button>
                    <button className="warning-btn-style outlined-btn outlined-btn-animation" onClick={freezeHandler}>
                        {" "}
                        Confirm
                    </button>
                </div>
            </Dialog>

            {/* Snackbar */}
            <UserSnackbar openSnackbar={open} setOpenSnackbar={setOpen} message={message} />
        </div>
    )
}

export default AccountSettings