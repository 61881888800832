import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Slide from "@mui/material/Slide";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import InvitesExpiryEditModal from "../../../modals/InvitesExpiryEditModal";
import TimeZoneModal from "../../../modals/TimeZoneModal";

const GeneralSettings = ({
  sidebarOpen,
  setPageName,
  mainSettings,
  setMainSettings,
}) => {
  //company id
  const id = sessionStorage.getItem("id");

  //Modal States
  const [timezoneModal, setTimezoneModal] = useState(false);
  const [invitationExpModal, setInvitationExpModal] = useState(false);

  //List of options in general settings
  const generalSettingsList = [
    {
      title: "Time zone",
      description:
        "Your default company timezone used for sending automated emails",
      icon: <i class="fa-solid fa-earth-americas"></i>,
      openModal: "timeZoneModal",
    },
    {
      title: "Invitations Expiry",
      description: "Auto deletes pending and accepted invitations",
      icon: <i class="fa-solid fa-envelope"></i>,
      openModal: "invitationsExpiry",
    },
  ];

  //back button handler
  const itemHandler = () => {
    setMainSettings({
      ...mainSettings,
      general: false,
      settings: true,
    });
    setPageName("settings");
  };

  //modal display handler
  const handleGeneralSettings = (modal) => {
    switch (modal) {
      case "invitationsExpiry":
        setInvitationExpModal(true);
        break;
      case "timeZoneModal":
        setTimezoneModal(true);
        break;
    }
  };

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));



  return (
    <div style={{ marginLeft: `${sidebarOpen === "sidebar-open" ? "200px" : "0px"}` }}>
      <Box sx={{ flexGrow: 1, background: "#faf9f6" }}>
        {/* Top Card */}
        <Card id="main-edit" className="add-recruiter-form form-card">
          <Grid
            container
            spacing={0}
            // direction="row"
            className="add-recruiter-card-container"
          >
            <Grid
              item
              xs={12}
              sm={sidebarOpen === "sidebar-open" ? 12 : 4}
              md={sidebarOpen === "sidebar-open" ? 12 : 4}
              lg={6}
              className="container-heading-title admin-table"
            >
              <h3>
                <span className="previousHighlighter" onClick={itemHandler}>
                  Settings
                </span>
                {` > General `}
              </h3>
            </Grid>

            <Grid
              container
              spacing={1}
              direction="row"
            // className="profile-progress-bar"
            >
              <Grid
                id="profile-completion-percentage"
                item
                xs={12}
                md={12}
                sx={{ textAlign: "center" }}
                className="container-profile"
              >
                <div className="table-buttons">
                  <button className="add-recruiter " onClick={itemHandler}>
                    Back
                  </button>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Card>

        {/* Bottom Card */}
        <Card id="skills-edit"
          className="add-recruiter-form-container"
          style={{ boxShadow: "none", borderRadius: "2px" }}>
          <div className="settings-card-container">
            <Grid container spacing={2}>
              {generalSettingsList.map((item) => {
                return (
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    md={3}
                    lg={3}
                    className="settings-item-grid"
                  >
                    <Item
                      className="settings-item"
                      onClick={() => handleGeneralSettings(item.openModal)}
                    >
                      <div className="settings-icon">{item.icon}</div>
                      <div className="settings-heading">
                        <h6>{item.title}</h6>
                        <p>{item.description}</p>
                      </div>
                    </Item>
                  </Grid>
                );
              })}
            </Grid>
          </div>
        </Card>
      </Box>

      {/* Modal components */}
      <TimeZoneModal
        timezoneModal={timezoneModal}
        setTimezoneModal={setTimezoneModal}
      />
      <InvitesExpiryEditModal
        invitationExpModal={invitationExpModal}
        setInvitationExpModal={setInvitationExpModal}
      />
    </div>
  );
};

export default GeneralSettings;
