import React, { useEffect, useState } from "react";
import axios from "axios";

import { styled, useTheme } from "@mui/material/styles";

import CompanyMenu from "../userProfiles/CompanyMenu";

const ScreenerList = (props) => {
  const id = sessionStorage.getItem("id");
  const role = sessionStorage.getItem("role");

  const [defaultAvatar, setDefaultAvatar] = useState();
  const [croppedImg, setCroppedImg] = useState();
  const [profileImage, setProfileImage] = useState();
  const [interacted, setInteracted] = useState();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const [open, setOpen] = useState();
  const [sidebarOpen, setsidebarOpen] = useState("");

  // Array to store list of screeners
  const [screenerList, setScreenerList] = useState([]);

  var drawerWidth = 200;
  //Main
  const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
    ({ theme, open }) => ({
      flexGrow: 1,
      padding: theme.spacing(3),
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: `-${drawerWidth}px`,
      ...(open && {
        transition: theme.transitions.create("margin", {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
      }),
    })
  );

  useEffect(() => {
    axios.get(`/api/screener/screenerList/screeners`).then((res) => {
      console.log("Screener", res.data);
    });
  }, []);
  useEffect(() => {
    const { data } = axios.get(`/api/recruiter/${id}`).then((res) => {
      console.log(res);
      const {
        defaultAvatar,
        profileImage,
        croppedImage,
        interacted,
        firstName,
        lastName,
      } = res.data.user;
      setDefaultAvatar(defaultAvatar);
      setProfileImage(profileImage);
      setCroppedImg(croppedImage);
      setInteracted(interacted);
      setFirstName(firstName);
    });
  }, []);

  return (
    <div>
      <CompanyMenu
        open={open}
        setOpen={setOpen}
        sidebarOpen={sidebarOpen}
        setsidebarOpen={setsidebarOpen}
        companyName={firstName}
        croppedImg={croppedImg}
        profileImage={profileImage}
        interacted={interacted}
        defaultAvatar={defaultAvatar}
      />

      <Main id={`${sidebarOpen}`}>
        <div
          className="root-div"
          id="restrict-scroll"
          style={{ overflowY: "hidden" }}
        >
          ScreenerList
        </div>
      </Main>
    </div>
  );
};

export default ScreenerList;
