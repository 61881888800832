import React, { useState } from 'react'
import Card from "@mui/material/Card";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import ModuleAccess from './ModuleAccess';
import AccountSettings from './AccountSettings';
import LockIcon from '@mui/icons-material/Lock';

function SubscriberSettings({ item, tile, setTile }) {

    const [settingsView, setSettingsView] = useState("main")

    const settingsList = [
        {
            icon: <LockIcon />,
            header: "Module Access",
            description: "Allow specific module access to subscribers",
        },
        {
            icon: <i class="fa-solid fa-sliders"></i>,
            header: "Account Settings",
            description: "View subscriber details and account status",
        },
    ]

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: "center",
        color: theme.palette.text.secondary,
    }));

    const itemHandler = ({ header }) => {
        console.log(header)
        setSettingsView(header)
    }

    return (
        <div>
            {settingsView === "main" ? <Card className="main-card profile-card settings-card">
                <Grid container spacing={2}>
                    {settingsList.map((item) => {
                        return (
                            <Grid
                                item
                                xs={12}
                                sm={4}
                                md={3}
                                lg={3}
                                className="settings-item-grid"
                            >
                                <Item
                                    className="settings-item"
                                    onClick={() => itemHandler(item)}
                                >
                                    <div className="settings-icon">{item.icon}</div>
                                    <div className="settings-heading">
                                        <h6>{item.header}</h6>
                                        <p>{item.description}</p>
                                    </div>
                                </Item>
                            </Grid>
                        );
                    })}
                </Grid>
            </Card> : settingsView === "Module Access" ? <ModuleAccess setTile={setTile} setSettingsView={setSettingsView} companyDetails={item} /> : settingsView === "Account Settings" ? <AccountSettings setTile={setTile} setSettingsView={setSettingsView} companyDetails={item} /> : <></>}
        </div>
    )
}

export default SubscriberSettings