import { faBrain, faCheck, faHandshake, faLightbulb, faPhoneAlt, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import withStyles from "@material-ui/core/styles/withStyles";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { Component } from "react";

const styles = () => ({
    root: {
        backgroundColor: "#ccc",
        zIndex: 1,
        color: "#fff",
        width: 50,
        height: 50,
        display: "flex",
        borderRadius: "50%",
        justifyContent: "center",
        alignItems: "center",
    },
    iconColor: {
        color: "white",
    },
    active: {
        backgroundColor: "#A5D6A7",
        boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
    },
    completed: {
        backgroundColor: "#769a78",
    },
});

class customlibCandStepIcon extends Component {
    render() {
        const { active, completed, icon, classes } = this.props;

        const icons = {
            1: <FontAwesomeIcon icon={faPhoneAlt} />,
            2: <FontAwesomeIcon icon={faUser} />,
            3: <FontAwesomeIcon icon={faBrain} />,
            4: <FontAwesomeIcon icon={faLightbulb} />,
            5: <FontAwesomeIcon icon={faHandshake} />,
            6: <FontAwesomeIcon icon={faCheck} />,
        };

        return (
            <div
                className={clsx(classes.root, {
                    [classes.active]: active,
                    [classes.completed]: completed,
                })}
            >
                {icons[String(icon)]}
            </div>
        );
    }
}

customlibCandStepIcon.propTypes = {
    /**
     * Whether this step is active.
     */
    active: PropTypes.bool,
    /**
     * Mark the step as completed. Is passed to child components.
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
};

export default withStyles(styles)(customlibCandStepIcon);
