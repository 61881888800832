import React, { useState, useEffect } from 'react'
import axios from 'axios';
import CompanyMenu from '../../userProfiles/CompanyMenu';
import { Box, Card, Grid } from "@mui/material";
import CountUp from 'react-countup';
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField, CircularProgress } from "@material-ui/core";


import PositionIcon from '@mui/icons-material/BusinessCenterOutlined';
import ClientIcon from '@mui/icons-material/CorporateFareOutlined';
import InterviewIcon from '@mui/icons-material/EventAvailableOutlined';
import InviteIcon from '@mui/icons-material/MailOutlined';

import Spinner from '../../loaders/Spinner';
//charts
import DonutChart from './Charts/DonutChart';
import PieChart from './Charts/PieChart';
import QuarterPie from './Charts/QuarterPie';
import BarChart from './Charts/BarChart';

import Footer from '../../../Footer/Footer';

const AdminDashboard = () => {

    const id = sessionStorage.getItem('id')

    //Navbar states
    const [open, setOpen] = useState();
    const [sidebarOpen, setsidebarOpen] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [interacted, setInteracted] = useState("");
    const [croppedImg, setCroppedImg] = useState("");
    const [profileImage, setProfileImage] = useState("");
    const [defaultAvatar, setDefaultAvatar] = useState("");
    let profileAvatar = "https://res.cloudinary.com/df2qsnyox/image/upload/v1681133496/Default%20Profile%20Avatar/profile-avatar_ogxozq_ranghl.png";

    const [isInternalDB, setIsInternalDB] = useState(false)
    const [interviewFilter, setInterviewFilter] = useState("Today")
    const [allInterviews, setAllInterviews] = useState({})
    const [pos, setPos] = useState([])
    const [clients, setAllClients] = useState([])
    const [interviews, setInterviews] = useState([])
    const [invitations, setInvitations] = useState([])
    const [internalDB, setInternalDB] = useState([])
    const [favorites, setFavorites] = useState([])
    const [totalPos, setTotalPos] = useState(0)
    const [totalInt, setTotalInt] = useState(0)
    const [totalInvitation, setTotalInvitation] = useState(0)
    const [totalClient, setTotalClient] = useState(0)
    const [clientsList, setClientsList] = useState([])
    const [clientName, setClientName] = useState("")
    const [clientDetails, setClientDetails] = useState({ id: '', name: '', email: '' })
    const [loading, setLoading] = useState(true)

    const handleClientName = (e) => {
        setClientName(e.target.value)
        setClientDetails({ id: '', name: '', email: '' })
    }

    const handleChangeClients = async (event, newValue) => {
        console.log(newValue)
        const year = new Date().getFullYear()
        const month = new Date().getMonth() + 1 < 10 ? '0' + (new Date().getMonth() + 1) : new Date().getMonth() + 1
        const day = new Date().getDate() < 10 ? '0' + new Date().getDate() : new Date().getDate()
        const currentDate = `${year}-${month}-${day}`

        const { value: { id: clientId } } = newValue;
        setClientDetails(newValue.value)
        setClientName(newValue.name)

        //get alll the company interviews anf filter based on client
        axios.get(`/api/schedule/getAllInterviews/${id}`).then(res => {
            let data = res.data
            if (data.length) {
                let companyInterviews = data.filter(int => int.companyId == id)
                console.log(companyInterviews)
                if (companyInterviews.length) {
                    const { scheduled, confirmed, toSchedule } = companyInterviews[0]
                    let total = [...toSchedule, ...scheduled, ...confirmed]
                    if (newValue.value.id) {
                        total = total.filter(item => item.clientDetails.id == clientId)
                    }
                    const scheduledForToday = total.filter(interview => interview.interviewDate == currentDate)

                    const scheduledDates = total.filter(interview => {
                        const { interviewDate } = interview
                        const intYear = interviewDate.slice(0, 4)
                        const intMonth = interviewDate.slice(5, 7)
                        const intDate = interviewDate.slice(8, 11)

                        if (intYear >= year && intMonth >= month && intDate >= day) {
                            return interview
                        }

                    })

                    setAllClients([{
                        type: "Scheduled for Today",
                        value: scheduledForToday.length || 0
                    },
                    {
                        type: "Total Scheduled",
                        value: scheduledDates.length || 0
                    },
                    ])
                }
                else {
                    setAllClients([{
                        type: "Scheduled for Today",
                        value: 0
                    },
                    {
                        type: "Total Scheduled",
                        value: 0
                    },
                    ])
                }
            } else {
                setAllClients([{
                    type: "Scheduled for Today",
                    value: 0
                },
                {
                    type: "Total Scheduled",
                    value: 0
                },
                ])
            }
        })
    }

    const clientOptions = clientsList?.map((client) => {
        return {
            name: client.companyName,
            label: `${client.companyName}`,
            value: {
                id: client._id,
                email: client.email,
                name: client.companyName,
            },
        };
    });

    const getAllPositions = (async () => {
        try {
            const { data } = await axios.get(`/api/admin/get-all-positions/${id}`)
            console.log(data.position)
            const open = data.position.filter(position => position.status == 'Active')
            const closed = data.position.filter(position => position.status == 'Closed')
            setTotalPos(open.length + closed.length)
            setPos([
                {
                    type: 'Open',
                    value: open.length || 0,
                },
                {
                    type: 'Closed',
                    value: closed.length || 0,
                },
            ])

        } catch (error) {
            console.log(error)
            setPos([{
                type: 'Open',
                value: 0,
            },
            {
                type: 'Closed',
                value: 0,
            },])
        }
    })


    const getAllClients = (async () => {
        const { data } = await axios.get(`/api/admin/get-all-clients/${id}`)
        setClientsList(data)
        const { data: allInterviews } = await axios.get(`/api/schedule/getAllInterviews/${id}`)
        console.log(allInterviews, data)
        let allClient = data.map(client => { return { clientName: client.companyName, counter: 0, clientId: client._id } })
        setTotalClient(allClient.length)
    })



    const getAllInterviews = async () => {
        const { data } = await axios.get(`/api/schedule/getAllInterviews/${id}`)
        let companyInterviews = data.filter(int => int.companyId == id)

        if (companyInterviews.length) {

            const { toSchedule, confirmed, completed, scheduled } = companyInterviews[0]
            setAllInterviews(companyInterviews)
            setTotalInt(toSchedule.length + confirmed.length + completed.length + scheduled.length)
            let completedNumber = companyInterviews[0].completed.length
            let todayScheduledNumber = 0
            const year = new Date().getFullYear()
            const month = new Date().getMonth() + 1 < 10 ? '0' + (new Date().getMonth() + 1) : new Date().getMonth() + 1
            const day = new Date().getDate() < 10 ? '0' + new Date().getDate() : new Date().getDate()
            const currentDate = `${year}-${month}-${day}`
            console.log(currentDate)
            companyInterviews[0].scheduled.map(interview => {
                if (interview.interviewDate == currentDate) {
                    console.log("Matched")
                    todayScheduledNumber++
                }
            })
            setInterviews([{
                type: "Completed",
                value: completedNumber
            },
            {
                type: "Scheduled for today",
                value: todayScheduledNumber
            }])
        } else {
            setInterviews([{
                type: "Completed",
                value: 0
            },
            {
                type: "Scheduled for today",
                value: 0
            }])
        }

    }

    const getAllInvitations = () => {
        axios.get(`/api/admin/get-all-invitations/${id}`)
            .then(res => {
                const { invites } = res.data
                const { users } = invites[0]
                const totalUsersNumber = users.length
                setTotalInvitation(totalUsersNumber)
                const acceptedUsersNumber = users.filter(user => user.status === "Accepted").length
                const pendingUsersNumber = totalUsersNumber - acceptedUsersNumber
                setInvitations([
                    {
                        type: "All Users",
                        value: totalUsersNumber
                    },
                    {
                        type: "Accepted Users",
                        value: acceptedUsersNumber
                    },
                    {
                        type: "Pending Users",
                        value: pendingUsersNumber
                    },
                ])
            })
            .catch(err => {
                console.log(err)
                setInvitations([
                    {
                        type: "All Users",
                        value: 0
                    },
                    {
                        type: "Accepted Users",
                        value: 0
                    },
                    {
                        type: "Pending Users",
                        value: 0
                    },
                ])
            })
    }

    const getAllInternalDB = async () => {
        const { data: internalScreeners } = await axios.get(`/api/admin/screeners/internal-screeners`)
        const { data: internalCandidates } = await axios.get(`/api/admin/candidates/internal-candidates`)
        console.log(internalScreeners, internalCandidates)
        const convertedScreeners = internalScreeners.filter(screener => screener.requestStatus === "Accepted")
        const convertedCandidates = internalCandidates.filter(candidate => candidate.requestStatus === "Accepted")

        setInternalDB([
            {
                type: "Screeners",
                value: internalScreeners.length || 0

            },
            {
                type: "Job Seekers",
                value: internalCandidates.length || 0
            },
            {
                type: "Converted Users",
                value: (convertedScreeners.length + convertedCandidates.length) || 0
            },
        ])

    }

    const getAllFavorites = async () => {
        let { data } = await axios.get(`/api/admin/favorites/${id}/screener/${id}`);
        console.log(data)
        const screenerFavoritesCount = data.screener.length
        let { data: data1 } = await axios.get(`/api/admin/favorites/${id}/candidate/${id}`);
        console.log(data1)
        const candidateFavoritesCount = data1.candidate.length

        setFavorites([
            {
                type: "Favorite Job Seekers",
                value: candidateFavoritesCount
            },
            {
                type: "Favorites Screeners",
                value: screenerFavoritesCount
            }
        ])
    }

    useEffect(() => {
        document.title = "Screener | Dashboard";
        axios.get(`/api/admin/${id}`).then((res) => {
            const {
                defaultAvatar,
                profileImage,
                croppedImage,
                interacted,
                companyName,
                isInternalDB
            } = res.data.user;
            setDefaultAvatar(defaultAvatar);
            setProfileImage(profileImage);
            setCroppedImg(croppedImage);
            setInteracted(interacted);
            setCompanyName(companyName);
            setIsInternalDB(isInternalDB)
            setLoading(false)
        });
    }, []);

    useEffect(() => {
        if (clientName == "") {
            handleChangeClients("", {
                name: "",
                label: "",
                value: {
                    id: "",
                    email: "",
                    name: "",
                },
            })
        }
    }, [clientName])

    useEffect(() => {
        getAllInternalDB()
        getAllClients()
        getAllInvitations()
        getAllPositions()
        getAllInterviews()
        getAllFavorites()
        handleChangeClients("", {
            name: "",
            label: "",
            value: {
                id: "",
                email: "",
                name: "",
            },
        })
    }, [])


    return (
        <div>
            {/* Navbar Component */}
            <CompanyMenu
                open={open}
                setOpen={setOpen}
                sidebarOpen={sidebarOpen}
                setsidebarOpen={setsidebarOpen}
                companyName={companyName}
                croppedImg={croppedImg}
                profileImage={profileImage}
                interacted={interacted}
                defaultAvatar={defaultAvatar}
            />

            {loading ? <Spinner /> : (<div style={{ marginLeft: `${sidebarOpen === "sidebar-open" ? "200px" : "0px"}`, marginTop: "5%" }}>
                <Box sx={{ flexGrow: 1, background: "#faf9f6" }}>
                    <div className='dashboard-body company'>
                        <Grid
                            className="grid-container"
                            container
                            style={{ marginLeft: "0px" }}
                            spacing={3}
                            direction="row"
                            gap={"30px"}
                            justifyContent={'space-between'}
                            width={'100%'}
                        >
                            <Grid
                                // item
                                xs={5.5} lg={2.5} md={5.2} sm={5.5}
                            >
                                <Card className="dashboard-top-cards">
                                    <div className='container'>
                                        <div className="icon-bg">
                                            <ClientIcon />
                                        </div>
                                        <div className="count-details">
                                            <p> <CountUp end={totalClient} duration={4} /></p>
                                            <span>Clients</span>
                                        </div>
                                    </div>
                                </Card>
                            </Grid>

                            <Grid
                                // item 
                                xs={5.5} lg={2.5} md={5.2} sm={5.5}
                            >
                                <Card className="dashboard-top-cards">
                                    <div className='container'>
                                        <div className="icon-bg-even">
                                            <PositionIcon />
                                        </div>
                                        <div className="count-details">


                                            <p> <CountUp end={totalPos} duration={4} /></p>
                                            <span> Positions</span>
                                        </div>
                                    </div>
                                </Card>
                            </Grid>

                            <Grid
                                // item 
                                xs={5.5} lg={2.5} md={5.2} sm={5.5}>
                                <Card className="dashboard-top-cards">
                                    <div className='container'>
                                        <div className="icon-bg">
                                            <InterviewIcon />
                                        </div>
                                        <div className="count-details">
                                            <p><CountUp end={totalInt} duration={4} /></p>
                                            <span> Interviews</span>
                                        </div>
                                    </div>
                                </Card>
                            </Grid>

                            <Grid
                                // item 
                                xs={5.5} lg={2.5} md={5.2} sm={5.5}>
                                <Card className="dashboard-top-cards">
                                    <div className='container'>
                                        <div className="icon-bg-even">
                                            <InviteIcon />
                                        </div>
                                        <div className="count-details">
                                            <p> <CountUp end={totalInvitation} duration={4} /></p>
                                            <span> Invites</span>
                                        </div>
                                    </div>
                                </Card>
                            </Grid>

                        </Grid>

                        <Grid
                            container
                            className="grid-container "
                            style={{ marginLeft: "0px" }}
                            spacing={3}
                            direction="row"
                            gap={"30px"}
                            justifyContent={"space-between"}
                            width={'100%'}
                        >
                            <Grid
                                // item 
                                xs={12} lg={3.5} md={5.5} sm={12} xl={3.5} >
                                <Card className={`dashboard-graph-cards ${open ? "custom-width" : " graph-card-width"}`} >
                                    <div className="main-chart-container">
                                        <div className="chart-heading-container ">
                                            <h5>Client Interviews</h5>
                                            <div class="input-field input-dashboard">
                                                <Autocomplete
                                                    disablePortal
                                                    disableClearable
                                                    id="combo-box-demo"
                                                    options={clientOptions}
                                                    getOptionLabel={(option) => option?.label}
                                                    sx={{ width: 300 }}
                                                    inputValue={clientName}
                                                    onChange={(event, newValue) => {
                                                        console.log(newValue)
                                                        handleChangeClients(event, newValue)
                                                    }}
                                                    renderInput={(params) => {
                                                        return (
                                                            <TextField
                                                                name="clientName"
                                                                style={{ width: "125%" }}
                                                                onChange={handleClientName}
                                                                {...params}
                                                                placeholder="Select Client"
                                                            />
                                                        );
                                                    }}
                                                />
                                            </div>

                                        </div>
                                    </div>
                                    <div className="chart-container">
                                        <PieChart clients={clients} />
                                    </div>

                                </Card>
                            </Grid>
                            <Grid
                                // item 
                                xs={12} lg={3.5} xl={3.5} md={5.5} sm={12} >
                                <Card className={`dashboard-graph-cards ${open ? "custom-width" : " graph-card-width"}`} >
                                    <div className="main-chart-container other-container">
                                        <div className="chart-heading-container">
                                            <h5>Positions</h5>
                                        </div>
                                        <div className="chart-container">
                                            <DonutChart result={pos} endAngle={0} title={true} />
                                        </div>
                                    </div>
                                </Card>
                            </Grid>
                            <Grid
                                // item 
                                xs={12} lg={3.5} xl={3.5} md={5.5} sm={12}>
                                <Card className={`dashboard-graph-cards ${open ? "custom-width" : " graph-card-width"}`}>
                                    <div className="main-chart-container other-container">
                                        <div className="chart-heading-container">
                                            <h5>Interviews</h5>
                                        </div>
                                        <div className="chart-container">
                                            <DonutChart result={interviews} endAngle={3} title={false} />
                                        </div>
                                    </div>
                                </Card>
                            </Grid>
                            <Grid
                                // item 
                                xs={12} lg={3.5} xl={3.5} md={5.5} sm={12}>
                                <Card className={`dashboard-graph-cards ${open ? "custom-width" : " graph-card-width"}`} >
                                    <div className="main-chart-container other-container">
                                        <div className="chart-heading-container">
                                            <h5>Invitations</h5>
                                        </div>
                                        <div className="chart-container">
                                            <QuarterPie invitations={invitations} />
                                        </div>
                                    </div>
                                </Card>
                            </Grid>
                            {isInternalDB && <Grid
                                // item 
                                xs={12} lg={7.7} md={5.5} sm={12} xl={7.7} className='internal-db-card'>
                                <Card className="dashboard-graph-cards " >
                                    <div className="main-chart-container other-container">
                                        <div className="chart-heading-container">
                                            <h5>Internal DB</h5>
                                        </div>
                                        <div className="chart-container">
                                            <BarChart internalDB={internalDB} />
                                        </div>
                                    </div>
                                </Card>
                            </Grid>}
                            <Grid
                                // item 
                                xs={12} lg={3.5} md={5.5} sm={12} xl={3.5}>
                                <Card className={`dashboard-graph-cards half-width-card ${open ? "custom-width" : " graph-card-width favorites-graph-card"}`}>
                                    <div className="main-chart-container other-container">
                                        <div className="chart-heading-container">
                                            <h5>Favorites</h5>
                                        </div>
                                        <div className="chart-container">
                                            <DonutChart result={favorites} endAngle={3} title={false} />
                                        </div>
                                    </div>
                                </Card>
                            </Grid>
                        </Grid>
                    </div>
                </Box>
            </div>)}

            {/* Footer */}
            <Footer sidebarOpen={sidebarOpen} />
        </div >
    )
}

export default AdminDashboard