import React, { Component } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";

const styles = theme => ({});
class screenAvailability extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: []
    };
  }
  componentDidMount() {
    const { values } = this.props;
    let skills = [];
    values.skills.forEach(val => {
      skills.push(val.value);
    });

    let state_data = [];
    //fetch screener ids on the basis of skill selected
    axios.post("/api/users/screener/skills", skills).then(response => {
      let screeners = response.data;
      let screenerIds = screeners.map(screener => screener._id);

      //fetch screener availability on the basis of selected ids
      axios
        .post("/api/availability/events/screeners", screenerIds)
        .then(res => {
          let event_data = res.data;
          event_data.forEach(({ user, events }) => {
            let screener_data = screeners.filter(
              screener => screener._id === user
            );
            let screener = screener_data.map(x => x.name)[0];

            let skills_data = screener_data.map(x => x.screener_info.skills)[0];
            let skill = skills_data.filter(skill => skills.includes(skill[0]));
            let newEvent = [];
            events.forEach(({ start, end }) => {
              start = new Date(start);
              end = new Date(end);
              newEvent.push({ start, end });
            });
            state_data.push({
              screener: screener,
              events: newEvent,
              skills: skill
            });

            this.setState({ data: state_data });
          });
        });
    });
  }

  render() {
    const { classes } = this.props;

    return (
      <div>
        <main className={classes.main}>
          <Grid container spacing={24}>
            <Grid item xs={12}>
              <Typography
                variant="h4"
                gutterBottom
                component="h4"
                align="center"
              >
                Screener Availability
              </Typography>
              <div className={classes.searchIcon}>
                <InputBase
                  placeholder="Search…"
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput
                  }}
                />
                <SearchIcon />
              </div>

              <Table className={classes.table}>
                {this.state.data.map(availability => (
                  <React.Fragment>
                    <TableHead>
                      <TableRow>
                        <TableCell />
                        <TableCell>{availability.screener}</TableCell>
                        <TableCell />
                      </TableRow>

                      {availability.skills.map(skill => (
                        <TableRow>
                          <TableCell>Skill: {skill[0]}</TableCell>
                          <TableCell />
                          <TableCell>Years: {skill[1]}</TableCell>
                        </TableRow>
                      ))}
                    </TableHead>
                    <TableBody>
                      {availability.events.map(event => (
                        <TableRow>
                          <TableCell align="right">
                            {event.start.toDateString() + " "}
                          </TableCell>
                          <TableCell align="right">
                            {("0" + event.start.getHours()).slice(-2) +
                              ":" +
                              ("0" + event.start.getMinutes()).slice(-2)}
                          </TableCell>
                          <TableCell align="right">
                            {("0" + event.end.getHours()).slice(-2) +
                              ":" +
                              ("0" + event.end.getMinutes()).slice(-2)}
                          </TableCell>
                        </TableRow>
                      ))}

                      <TableRow />
                    </TableBody>
                  </React.Fragment>
                ))}
              </Table>
            </Grid>
          </Grid>
        </main>
      </div>
    );
  }
}

screenAvailability.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  auth: state.auth
});
export default withStyles(styles)(
  connect(
    mapStateToProps,
    { logoutUser }
  )(screenAvailability)
);
