import React, { Fragment, useState, useEffect, Component } from "react";
import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from "react-redux";
import { updateRecruiter, getRecruiterDetails, UPDATE_PROFILE_RESET } from "../../actions/userActions";
import axios from "axios";
import { withSnackbar, useSnackbar } from "notistack";
import dateFormat from 'dateformat';

import DateFnsUtils from "@date-io/date-fns";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from "@material-ui/core/TextField";

import { DatePicker, MuiPickersUtilsProvider, TimePicker } from "material-ui-pickers";
import PropTypes, { string } from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { makeStyles, Divider, TextareaAutosize } from "@material-ui/core";
import { screeners, candidates, interviews } from "../../actions/userActions"
import { Link } from "react-router-dom";

import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Dialog from "@mui/material/Dialog";

const useStyles = makeStyles(theme => ({
    main: {
        width: "auto",
        display: "block", // Fix IE 11 issue.
        marginTop: theme.spacing(-2),
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        [theme.breakpoints.up(400 + theme.spacing(6))]: {
            width: "50%",
            marginLeft: "auto",
            marginRight: "auto",
        },
    },
    paper: {
        marginTop: theme.spacing(-5),
        display: "flex",
        width: "100%",
        flexDirection: "column",
        alignItems: "center",
        padding: `${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(-15)}px`,
    },
    button: {
        padding: theme.spacing(2),
        margin: 10,
        alignItems: "center",
    },

    textField: {
        marginLeft: theme.spacing(),
        marginRight: theme.spacing(),
        width: "100px",
    },

    textFieldBox: {
        marginTop: theme.spacing() * 1,
        marginLeft: theme.spacing(),
        marginRight: theme.spacing(),
        width: "500px",
    },
    select: {
        marginTop: theme.spacing() * 1,
        marginLeft: theme.spacing(),
        marginRight: theme.spacing(),
        width: "250px",
    },
    formControl: {
        margin: theme.spacing(),
        minWidth: 120,
        maxWidth: 300,
    },
}));



const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};



const FeedbackInformation = ({ history, match, props }) => {

    const [interviewList, setInterviewList] = React.useState([]);
    const [screenerList, setScreenerList] = React.useState([]);
    const [candidateList, setCandidateList] = React.useState([]);
    const [openDialog, setOpenDialog] = React.useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const interviewId = match.params.id;

    useEffect(() => {
        axios.get("/api/event/interviews").then((response) => {
            setInterviewList(response.data);
        });

        axios.get("/api/users/screener").then((response) => {
            setScreenerList(response.data);
        });
        axios.get("/api/users/candidate").then((response) => {
            setCandidateList(response.data);
        });

    }, []);




    var id, cname, sname, title, interviewdate, communication_skills, comment_communication, technical_proficiency,
        comment_technical, project_experience, comment_project, tools_knowledge, comment_tools, team_leadership, comment_team, total_rating;


    interviewList && interviewList.forEach(item => {
        if (item._id === interviewId) {
            console.log(item._id);
            console.log(interviewId);

            screenerList && screenerList.forEach(user => {
                if (item.screener === user._id) {
                    console.log(user.name)
                    candidateList && candidateList.forEach(candidate => {
                        if (item.candidate === candidate._id) {
                            console.log(candidate.name)

                            id = item._id;
                            cname = candidate.name;

                            sname = user.name;

                            title = item.title;

                            interviewdate = item.startdate;

                            communication_skills = item.communication_skills;
                            comment_communication = item.comment_communication;
                            technical_proficiency = item.technical_proficiency;
                            comment_technical = item.comment_technical;
                            project_experience = item.project_experience;
                            comment_project = item.comment_project;
                            tools_knowledge = item.tools_knowledge;
                            comment_tools = item.comment_tools;
                            team_leadership = item.team_leadership;
                            comment_team = item.comment_team;
                            total_rating = item.total_rating + " / 25";

                        }
                    })

                }
            })
        }
    })


    //Open the dialog box
    const handleClickOpen = () => {
        return setOpenDialog(true);
    };

    //Close the dialog box
    const handleClose = () => {
        return setOpenDialog(false);
    };

    const classes = useStyles();

    return (
        <>
            <br /><br /><br /> <br /><br /><br /><br /><br />
            <div className={classes.main}>
                <Paper className={classes.paper}>
                    <br />
                    <Typography variant="h6">Interview Feedback Details<Divider /></Typography>
                    
                    <Dialog
                    open={openDialog}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    >

                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Warning: Are you sure you want to leave this page?
                        </DialogContentText>
                    </DialogContent>

                    <DialogActions>
                        <Button
                            onClick={handleClose}
                            style={{ backgroundColor: "#D73B30", color: "white" }}
                        >Cancel
                        </Button>
                        <Link
                            to="/candidate-feedback"
                            style={{ color: "white", textDecoration: "none" }}
                        >
                        <Button
                            autoFocus
                            style={{
                            backgroundColor: "#75A478",
                            color: "white",
                            margin: "6px",
                            }}
                        >Confirm
                        </Button>
                        </Link>
                    </DialogActions>
                    </Dialog>
                    {/* <Link to="/candidate-feedback">Back</Link> */}
                    <div style={{ width: "100%" }}>
                        <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            marginLeft: "-85%",
                        }}
                        >
                            <Button
                            onClick={handleClickOpen}
                            variant="contained"
                            color="secondary"
                            className={classes.submit}
                            style={{
                                backgroundColor: "#D73B30",
                                color: "white",
                                
                            }}
                            >
                            Back
                            </Button>
                        </div>
                    </div>
                    <form className={classes.form} >
                        <Grid container spacing={24} style={{ padding: "2em" }}>


                            <Grid item xs={12} sm={5}>
                                <div>
                                    <label><b>Candidate Name</b></label>
                                    <TextField
                                        disabled
                                        id="standard-disabled"
                                        value={cname}
                                        variant="standard"
                                        fullWidth
                                    />

                                </div>

                            </Grid>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <Grid item xs={12} sm={5}>
                                <div>
                                    <label><b>Screener Name</b></label>
                                    <TextField
                                        disabled
                                        id="standard-disabled"
                                        value={sname}
                                        variant="standard"
                                        fullWidth
                                    />

                                </div>

                            </Grid>
                            <Grid item xs={12} sm={5}>
                                <div>
                                    <label><b>Interview Title</b></label>
                                    <TextField
                                        disabled
                                        id="standard-disabled"
                                        value={title}
                                        variant="standard"
                                        fullWidth
                                    />
                                </div>


                            </Grid>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <Grid item xs={12} sm={5}>
                                <div>
                                    <label><b>Interview Date</b></label>
                                    < MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <DatePicker
                                            required
                                            id="dob"
                                            name="dob"
                                            value={interviewdate}
                                            format="dd/MM/yyyy"
                                            disabled
                                            fullWidth
                                        />
                                    </MuiPickersUtilsProvider>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={5}>
                                <div>
                                    <label><b>Communication Rating</b></label>
                                    <TextField
                                        disabled
                                        id="standard-disabled"
                                        value={communication_skills}
                                        variant="standard"
                                        fullWidth
                                    />
                                </div>


                            </Grid>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                            <Grid item xs={12} sm={5}>
                                <div>
                                    <label><b>Communication Feedback</b></label>
                                    <div>
                                        <TextareaAutosize
                                            disabled
                                            maxRows={5}
                                            value={comment_communication}
                                            variant="standard"
                                        // style={{ width: "auto" }}
                                        />
                                    </div>

                                </div>

                            </Grid>
                            <br />

                            <Grid item xs={12} sm={5}>
                                <div>
                                    <label><b>Technical Rating</b></label>
                                    <TextField
                                        disabled
                                        id="standard-disabled"
                                        value={technical_proficiency}
                                        variant="standard"
                                        fullWidth
                                    />
                                </div>


                            </Grid>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                            <Grid item xs={12} sm={5}>
                                <div>
                                    <label><b>Technical Feedback</b></label>
                                    <div>
                                        <TextareaAutosize
                                            disabled
                                            maxRows={5}
                                            value={comment_technical}
                                            variant="standard"
                                        // style={{ width: "auto" }}
                                        />
                                    </div>
                                </div>

                            </Grid>
                            <br />

                            <Grid item xs={12} sm={5}>
                                <div>
                                    <label><b>Project Experience Rating</b> </label>
                                    <TextField
                                        disabled
                                        id="standard-disabled"
                                        value={project_experience}
                                        variant="standard"
                                        fullWidth
                                    />
                                </div>


                            </Grid>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                            <Grid item xs={12} sm={5}>
                                <div>
                                    <label><b>Project Feedback</b> </label>
                                    <div>
                                        <TextareaAutosize
                                            disabled
                                            maxRows={5}
                                            value={comment_project}
                                            variant="standard"
                                        // style={{ width: "auto" }}
                                        />
                                    </div>
                                </div>

                            </Grid>
                            <br />
                            <Grid item xs={12} sm={5}>
                                <div>
                                    <label><b>Tools Knowledge Rating </b></label>
                                    <TextField
                                        disabled
                                        id="standard-disabled"
                                        value={tools_knowledge}
                                        variant="standard"
                                        fullWidth
                                    />
                                </div>


                            </Grid>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                            <Grid item xs={12} sm={5}>
                                <div>
                                    <label> <b> Tools Knowledge Feedback</b></label>
                                    <div>
                                        <TextareaAutosize
                                            disabled
                                            maxRows={5}
                                            value={comment_tools}
                                            variant="standard"
                                        // style={{ width: "auto" }}
                                        />
                                    </div>
                                </div>

                            </Grid>
                            <br />
                            <Grid item xs={12} sm={5}>
                                <div>
                                    <label><b>Team Leadership Rating</b> </label>
                                    <TextField
                                        disabled
                                        id="standard-disabled"
                                        value={team_leadership}
                                        variant="standard"
                                        fullWidth
                                    />
                                </div>


                            </Grid>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                            <Grid item xs={12} sm={5}>
                                <div>
                                    <label><b>Team Leadership Feedback</b></label>
                                    <div>
                                        <TextareaAutosize
                                            disabled
                                            maxRows={5}
                                            value={comment_team}
                                            variant="standard"
                                        // style={{ width: "auto" }}
                                        />
                                    </div>
                                </div>

                            </Grid>
                            <br /> <br /> <br /> <br />
                            <Grid item xs={12} sm={5}>
                                <div>
                                    <label><b>Total Rating</b> </label>
                                    <TextField
                                        disabled
                                        id="standard-disabled"
                                        value={total_rating}
                                        variant="standard"
                                        fullWidth
                                    />
                                </div>


                            </Grid>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <br /> <br /> <br /> <br /> <br /> <br /> <br />

                        </Grid>
                    </form>

                </Paper>
            </div>

        </>
    )
}

export default FeedbackInformation;