import React from 'react'
import "./PageNotFound.css"
import LinkExpiredImg from "./linkExpired.png"

const LinkExpired = () => {
    return (
        <div className="link-expired-body">
            <div className="expired-container">
                <div className="image">
                    <img src={LinkExpiredImg} alt="Link Expired" />
                </div>
                <div className='content'>
                    <h3>Link is not valid any more</h3>
                    <p>The page you are looking for no longer exists.</p>
                    <p> <a href="/">Return to homepage</a> and remember: you haven't seen anything ;)</p>
                </div>
            </div>
        </div>

    )
}

export default LinkExpired