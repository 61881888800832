export default function evaluateBooleanQuery(query, data) {
    data = data.map(item => item.toLowerCase())
    let stack = [];




    function splitQuery(queryString) {
        const pattern = /\(|\)|\bAND\b|\bOR\b|\bNOT\b|"[^"]*"|\b[^()\s]+/g;
        const tokens = queryString.match(pattern);
        return tokens;
    }


    // splitting query based on space , quotes and operators
    query = splitQuery(query);
    query = query.map(item => item.replace(/['"]+/g, ""))

    let newArr = query.filter(ele => ele != 'and' && ele != 'or' && ele != '(' && ele != ')')
    console.log(query, data, "query string")

    function performOperation(operator, previousOperand) {
        if (operator.toString().toUpperCase() == "AND") {
            const operand2 = previousOperand;
            const operand1 = stack.pop();

            // checking if both op1 and op2 are boolean values
            if ((operand1 == true || operand1 == false) && (operand2 == true || operand2 == false)) {
                if (operand1 && operand2) {
                    return true
                } else {
                    return false
                }
            }
            // checking operand2 is boolean
            else if (operand2 == true || operand2 == false) {
                // finding matched data from skills array
                if (data.includes(operand1.toString().toLowerCase()) && operand2 == true) {
                    stack.push(true);
                    return true
                } else {
                    stack.push(false)
                    return false
                }
            }
            // checking operand1 is boolean
            else if (operand1 == false || operand1 == true) {
                if (data.includes(operand2.toString().toLowerCase()) && operand1 == true) {
                    stack.push(true);
                    return true
                } else {
                    stack.push(false)
                    return false
                }
            }
            // checking whether both the operands exists in the array
            else {
                if (data.includes(operand1.toString().toLowerCase()) && data.includes(operand2.toString().toLowerCase())) {
                    stack.push(true);
                    return true
                } else {
                    stack.push(false)
                    return false
                }
            }
        }
        else if (operator.toString().toUpperCase() == "OR") {
            const operand2 = previousOperand;
            const operand1 = stack.pop();

            if (operand1 == true || operand2 == true) {
                stack.push(true)
                return true
            } else {
                if (data.includes(operand1.toString().toLowerCase()) || data.includes(operand2.toString().toLowerCase())) {
                    stack.push(true);
                    return true;
                } else {
                    stack.push(false)
                    return false
                }
            }
        }
        else if (operator.toString().toUpperCase() == "NOT") {
            if (previousOperand == true) {
                return false
            } else if (previousOperand == false) {
                return true
            } else {
                if (data.includes(previousOperand.toLowerCase())) {
                    return false
                } else {
                    return true
                }
            }
        }
        else return operator
    }


    function getPrecedence(operator) {
        if (operator != true || operator != false) {
            if (operator.toString().toUpperCase() == "NOT") {
                return 3
            }
            else if (operator.toString().toUpperCase() == "AND") {
                return 2;
            } else if (operator.toString().toUpperCase() == "OR") {
                return 1;
            }
            return 0;
        } else return 0;
    }


    if (data.length > 0) {
        // empty stack


        // traversing through query 
        for (let i = 0; i < query.length; i++) {
            const token = query[i];
            if (token === "(") {
                stack.push(token);
            } else if (token === ")") {
                let previousData = ""
                while (stack.length > 0 && stack[stack.length - 1] !== "(") {
                    previousData = performOperation(stack.pop(), previousData);
                }
                stack.pop(); // Discard the opening parenthesis
                stack.push(previousData)  // pushing result to stack
            } else if (token.toUpperCase() == "AND" || token.toUpperCase === "OR" || token.toUpperCase === "NOT") {
                while (stack.length > 0 && stack[stack.length - 1] !== "(" && getPrecedence(stack[stack.length - 1]) >= getPrecedence(token)) {
                    performOperation(stack.pop());
                }
                stack.push(token); //pushing operator to stack
            } else {
                const searchTerm = token.replace(/['"]+/g, "");
                let previousData = searchTerm
                if (query[i - 1]?.toString().toUpperCase() == "NOT") {
                    previousData = performOperation(stack.pop(), previousData)
                    stack.push(previousData)
                } else {
                    stack.push(searchTerm);
                }
            }
        }

        let previousData = ''
        while (stack.length >= 1) {
            previousData = performOperation(stack.pop(), previousData);
        }
        stack.push(previousData)

        return stack.pop();
    } else return false;
}