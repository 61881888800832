import React, { useState, useEffect } from 'react'

// MUI Imports
import { Dialog, DialogContent } from '@material-ui/core';
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Divider from "@mui/material/Divider";
import CloseIcon from "@mui/icons-material/Close";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Axios from 'axios';
import { timezone } from '../../data-files/timezone';
import UserSnackbar from '../snackBars/UserSnackbar';

const TimeZoneModal = ({ timezoneModal, setTimezoneModal }) => {

    //states to store values
    const [zone, setZone] = useState("Asia/Kolkata");
    const [errors, setErrors] = useState({
        pending: "",
        accepted: ""
    })
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [message, setMessage] = useState("")

    const handleCloseModal = () => {
        setTimezoneModal(false);
    }

    const localStorageCompId = sessionStorage.getItem("role") == "admin" ? sessionStorage.getItem('id') : sessionStorage.getItem("compId");

    useEffect(() => {
        Axios.get(`/api/admin/setting/timezone/${localStorageCompId}`).then(res => {
            setZone(res.data);

        })
    }, [timezoneModal])

    const handleSubmit = (e) => {
        e.preventDefault();
        const newData = {
            zone: zone,
            companyId: localStorageCompId
        }
        Axios.post('/api/admin/setting/timezone', newData).then(res => {
            handleCloseModal();
            setMessage("Time Zone set successfully")
            setOpenSnackbar(true)
        }).catch(err => {
            console.log(err)
            setMessage("Oops.. something went wrong!")
            setOpenSnackbar(true)
        })
    }

    return (
        <div>
            <Dialog
                open={timezoneModal}
                onClose={handleCloseModal}
                className="experience-edit-dialog timezone-expiry-edit-dialog"
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent
                    className="experience-edit-dialog-content"
                >
                    <Grid container spacing={0} justifyContent="center">
                        <Grid
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            className="experience-edit-title-grid"
                        >
                            <div>
                                <div className="experience-edit-title">
                                    <p>Time-Zone</p>
                                </div>
                                <div className="experience-edit-close">
                                    <Tooltip title="Close">
                                        <CloseIcon onClick={handleCloseModal} />
                                    </Tooltip>
                                </div>
                            </div>
                            <Divider className="experience-edit-top-divider" />
                        </Grid>

                        <Grid
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            className="experience-edit-right-grid"
                        >
                            <div className="experience-edit-form-container">
                                <form
                                    className="experience-edit-form"
                                    onSubmit={handleSubmit}
                                    noValidate
                                >
                                    <div class="input-container">
                                        <div class="input-field invites-expiry">
                                            <label>Enter your Time-Zone
                                                <Tooltip title="Time-Zone feature helps to schedule tasks based on your company's time zone ">
                                                    <InfoOutlinedIcon
                                                        fontSize="small"
                                                        style={{ color: "#4c4c5d", fontSize: "16px", marginLeft: "5px", marginBottom: "1px" }}
                                                    />
                                                </Tooltip>
                                            </label>
                                            <div>

                                                <select style={{ marginTop: '17px', outline: "none" }}
                                                    value={zone} onChange={(e) => setZone(e.target.value)}
                                                >
                                                    {timezone.map((time) => {
                                                        return <option value={time.abbr}>{time.value}</option>;
                                                    })}
                                                </select>
                                            </div>
                                            <span className="text-danger">{errors.pending}</span>
                                        </div>

                                        <p className="invites-note">If no timezone is set, IST will be set as the default.</p>
                                        <div className="button-container">
                                            <button className="btn-style filled-btn filled-btn-animation" onClick={handleSubmit}>
                                                {" "}
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>

            {/* Snackbar */}
            <UserSnackbar openSnackbar={openSnackbar} setOpenSnackbar={setOpenSnackbar} message={message} />
        </div>
    )
}

export default TimeZoneModal;