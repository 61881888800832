import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";

import { DataGrid } from "@mui/x-data-grid";
import { recruiters } from "../../actions/userActions"
import axios from "axios";
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router';
import { withSnackbar, useSnackbar } from "notistack";
import "../recruiters/tableList.css"
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import PropTypes from "prop-types";
import { connect } from 'react-redux';



import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";

const CreateRecruiter = (props) => {

  const { search } = window.location;
  const input = new URLSearchParams(search).get('search');
  const [keyword, setKeyword] = React.useState(input || '');
  const BarStyling = { width: "32rem", height: '5px', boxShadow: '5px 10px #d9d9d9', background: "white", border: "none", padding: "20px", margin: '0px 0px 20px 10px', border: '1px solid' };

  const [recruiterList, setRecruiterList] = useState([]);
  const [userList,setUserList] = useState([]);


  const { enqueueSnackbar } = useSnackbar();


  const [info, setInfo] = React.useState({
    id: "",
    name: "",
  });
  const [open, setOpen] = React.useState(false);


  
  // const onDelete = (id, status) => {
  //   status = (status == "Active" ? "In-Active" : "Active");
  //   axios.put('/api/users/user/recruiter/' + status + '/' + id)
  //     .then((res) => {
  //       const index = users.data.findIndex((user) => user._id === res.data._id);
  //       users.data[index].status = status;
  //       setData(users => ({
  //         ...users,
  //         [index]: res.data.status
  //       }))

  //       status = (status == "Active" ? "Activated" : "Deactivated");
  //       //alert(status+' successfully');
  //       let regMessage = <span>{status} successfully</span>;
  //       enqueueSnackbar(regMessage, {
  //         variant: "success",
  //         // autoHideDuration: 3000,
  //       });

  //     })
  //     .catch(err => {
  //       console.log(err.response);
  //       //alert('An error occured try submitting the form again');
  //       let regMessage = <span> An error occured try submitting the again</span>;
  //       enqueueSnackbar(regMessage, {
  //         variant: "error",
  //         // autoHideDuration: 3000,
  //       });
  //     });
  // }

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = (id) => {
    const arrRecruiter = recruiterList.filter((item) => {
      return item._id === id;
    });

    setInfo({
      id: arrRecruiter[0]._id,
      name:arrRecruiter[0].name
    })
    setOpen(true);
  };

  const onDelete = (id) => {
    axios
      .delete(`/api/users/recruiterdelete/${id}`)
      .then((res) => {
        console.log(res);

        //alert('Interview Deleted successfully');
        let regMessage = <span>Recruiter Deleted successfully</span>;
        enqueueSnackbar(regMessage, {
          variant: "info",
          autoHideDuration: 4000,
          // preventDuplicate: true,
        });
        window.location.reload(false);
      })
      .catch((err) => {
        console.log(err.response);
        //alert('An error occured try submitting the form again');
        let regMessage = (
          <span> An error occured try submitting the again</span>
        );
        enqueueSnackbar(regMessage, {
          variant: "error",
          // autoHideDuration: 3000,
        });
      });
  }


  const filterRecruiters = (unfilteredrecruiters, givenInput) => {
    if (!givenInput) {
      return unfilteredrecruiters;
    }
    return unfilteredrecruiters.filter((recruiter) => {
      // console.log(candidate);
      const name = recruiter.name.toLowerCase();
      const email = recruiter.email.toLowerCase();
      const role = recruiter.role.toLowerCase();
      const date = String(recruiter.date).substr(0, 10);
      const id = String(recruiter._id).toLowerCase();

      const status = recruiter.status.toLowerCase();
      //console.log(app);
      //console.log(company);
      return name.includes(givenInput.toLowerCase()) || email.includes(givenInput.toLowerCase()) || status.includes(givenInput.toLowerCase())
        || role.includes(givenInput.toLowerCase()) || date.includes(givenInput.toLowerCase()) || id.includes(givenInput.toLowerCase());
      //return name.includes(givenInput.toLowerCase());
    });


  };

  useEffect(() => {
   
    axios.get("/api/users/recruiter").then((response) => {
      setRecruiterList(response.data);
    });

    axios.get("/api/users/users").then((response) => {
      setUserList(response.data);
    });

    
  }, []);



  const {auth :{user}}= props;
  console.log(user)
  const userId = user.id;
  console.log(userId);

  let companyID= "";

  userList && userList.forEach(item=>{
    if(item._id === userId){
      companyID = item.companyID;
      
    }
  })
console.log(companyID)
   

  const columns = [

    {
      field: "name",
      headerName: "Name",
      minWidth: 150,
      flex: 0.5,

    },

    {
      field: "id",
      headerName: "User ID",
      minWidth: 150,
      flex: 1
    },

    {
      field: "email",
      headerName: "Email",
      minWidth: 150,
      flex: 0.3,
    },

    {
      field: "role",
      headerName: "Role",

      minWidth: 150,
      flex: 0.3,
    },
    {
      field: "status",
      headerName: "Status",

      minWidth: 150,
      flex: 0.3,
    },
    {
      field: "date",
      headerName: "Created Date",

      minWidth: 150,
      flex: 0.3,
    },
    {
      field: "actions",
      flex: 0.3,
      headerName: "Actions",
      minWidth: 150,
      // type: "number",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <Link to={`/recruiter/update/${params.id}`}>
              <EditIcon />
            </Link>
            {/* <Button onClick={() => onDelete(params.id, params.getValue(params.id, "status"))}>{params.getValue(params.id, "status") == "Active" ? "Deactivate" : "Activate"}</Button> */}
            &nbsp;&nbsp;
            <Button
              onClick={() =>
                handleClickOpen(params.getValue(params.id, "id"))
              }
            >
              <DeleteIcon style={{ color: "#75a478" }} />
            </Button>
          </>
        );
      },

    },

  ];

  const rows = [];



  // users.data && users.data.forEach(item => {
  //   //  console.log(item.name)
  //   //  console.log(item._id)
  //   //  console.log(item.email)
  //   //  console.log(item.role)
  //   rows.push({
  //     name: item.name,
  //     id: item._id,
  //     email: item.email,
  //     role: item.role,
  //     status: item.status,
  //     date: String(item.date).substr(0, 10)
  //   })

  // })
  //let history = useHistory();

  const filteredRecruiters = filterRecruiters(recruiterList, keyword);

  filteredRecruiters && filteredRecruiters.forEach(item => {

    if(item.companyID === companyID){
      console.log(item.name,item.companyID)
      rows.push({
        name: item.name,
        id: item._id,
        email: item.email,
        role: item.role,
        //  expertise:item.screener_info.expertise,
  
        status: item.status,
  
        date: String(item.date).substr(0, 10)
  
      })
    }
   

  })


  


  return (
    <>
      <br /> <br /> <br /> <br /> <br /> <br />
      <div className="Container">
        <div>
          <Link to="/new/addrecruiter">Add Recruiter</Link>
        </div>

      </div>
      <br />
      <input
        style={BarStyling}
        type="text"
        key="random1"
        value={keyword}
        placeholder={'Search For Recruiters'}
        onChange={(e) => setKeyword(e.target.value)}
        name="search"
      />

      <div className="myList">

        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={10}
          disableSelectionOnClick
          className="tableList"
          autoHeight
        />

        <Dialog
          open={open}
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Warning: Are You sure you want to delete {info.name}?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              style={{ backgroundColor: "#D73B30", color: "white" }}
            >
              Cancel
            </Button>

            {/* <Link
                  style={{ textDecoration: "none", padding: "10px" }}
                  to="/interview-list"
                > */}
            <Button
              style={{
                backgroundColor: "#75A478",
                color: "white",
              }}
              onClick={() =>
                onDelete(info.id)
              }
            >
              Delete
            </Button>
            {/* </Link> */}
          </DialogActions>
        </Dialog>
      </div>
    </>

  )

}

CreateRecruiter.propTypes = {
  auth: PropTypes.object.isRequired,
};

const msp = (state) => ({
  auth: state.auth,
});


export default (connect(msp)(CreateRecruiter));