import React from "react";
import { Link } from "react-router-dom";

export default function ConfirmationButtons(props) {
    const { classes, interview, linkText } = props;
    const interviewId = interview.interviewId.split("-")[0];

    return (
        <>
            <div>
                <Link
                    className={classes.review}
                    to={{
                        pathname: `/interview/${interviewId}`,
                        state: { interviewObject: interview },
                    }}
                >
                    {linkText}
                </Link>
            </div>
        </>
    );
}
