import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import React from "react";


const styles = makeStyles(theme => ({
    main: {
        width: "auto",
        display: "block", // Fix IE 11 issue.
        marginTop: "50px",
    },
    // button: {
    //     marginBottom: "20px",
      
    //     // width:"30px"
    //     //alignItems:"center",
    //     width:"500px"
    // },
}));

export default function Agreement(props) {
    const { handleChange, agreement } = props;
    const classes = styles();
    const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    handleChange();
  };

  const handleSimpleClose = () => {
    setOpen(false);
  };

        return (
            <main className={classes.main}>
                <div  className="flex-fill" align="center">
                    <Button color="secondary" variant="contained" onClick={handleClickOpen} align="center"  >
                        Read Terms and Condition
                    </Button> <br/><br/>
                    <Typography align="center" variant="subtitle2" >
                        {agreement ? "You have accepted the agreement" : null}
                    </Typography>
                </div>
                    <Dialog open={open} onClose={handleClose}
                        scroll="paper"
                        aria-labelledby="scroll-dialog-title"
                        aria-describedby="scroll-dialog-description"
                    >
                        <DialogTitle id="scroll-dialog-title"
                        >
                            Agreement
                        </DialogTitle>
                        <DialogContent dividers>
                            <DialogContentText     id="scroll-dialog-description"
                            >
                                <b>Conditions of Use :</b> &nbsp; We will provide their services to you, which are subject to the conditions
                                stated below in this document. Every time you visit this website, use its services or make a purchase, you
                                accept the following conditions. This is why we urge you to read them carefully. <br />
                                <b>Privacy Policy :</b>&nbsp; Before you continue using our website we advise you to read our privacy policy
                                [link to privacy policy] regarding our user data collection. It will help you better understand our
                                practices. <br />
                                <b>Copyright: </b>&nbsp; Content published on this website (digital downloads, images, texts, graphics,
                                logos) is the property of [name] and/or its content creators and protected by international copyright laws.
                                The entire compilation of the content found on this website is the exclusive property of [name], with
                                copyright authorship for this compilation by [name]. <br />
                                <b>License and Site Access: </b> &nbsp; We grant you a limited license to access and make personal use of
                                this website. You are not allowed to download or modify it. This may be done only with written consent from
                                us. <br />
                                <b>User Account:</b> &nbsp; If you are an owner of an account on this website, you are solely responsible
                                for maintaining the confidentiality of your private user details (username and password). You are
                                responsible for all activities that occur under your account or password. We reserve all rights to terminate
                                accounts, edit or remove content and cancel orders in their sole discretion. <br />
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button color="secondary" onClick={handleSimpleClose}>
                                Cancel
                            </Button>


                            <Button color="secondary" onClick={handleClose}>
                                I Accept
                            </Button>
                        </DialogActions>
                    </Dialog>

            </main>
        );

}
