// import React, { Component } from "react";
// import Modal from "react-modal";
// import { Route, Switch, Redirect, HashRouter } from "react-router-dom";
// import { useEffect, useState } from "react";

// import EntryWizard from "./entryWizard/entryWizard";
// import PrivateRoute from "./guards/PrivateRoute";
// import RecruiterRoute from "./guards/RecruiterRoute";
// import ScreenerRoute from "./guards/ScreenerRoute";
// import CandidateRoute from "./guards/CandidateRoute";
// import AdminRoute from "./guards/AdminRoute";

// // import Login from "./auth/Login";
// import Homepage from "./home/Homepage";
// import GoogleHire from "./iFrameHire/iframeHire";
// import InterviewDetails from "./interview/interviewDetails";
// import FooterContainer from "./layout/FooterContainer";

// import NavbarContainer from "./layout/NavbarContainer";
// import Question from "./question/Question";
// import CalenderEvent from "./schedule/calenderEvent";
// import Schedule from "./scheduling/schedule";
// //import ScreenerList from "./screener-list/ScreenerList";
// //import Dashboardrecruiter from "./Dashboard-Recruiter/dashboard-recruiter"
// import AddRecruiter from "./auth/AddRecruiter";
// import UpdateRecruiter from "./auth/UpdateRecruiter";
// import ScheduleInterview from "./recruiters/scheduleInterview";
// import UpdateInterview from "./recruiters/updateInterview";
// import CreateReview from "./recruiters/addReview";
// import UpdateReview from "./recruiters/updateReview";
// import Assessment from "./feedback/candidateAssessment/Assessment";
// import CandidateInformation from "./recruiters/candidateInformation";
// import ScreenerInformation from "./recruiters/screenerInformation";
// import InterviewInformation from "./recruiters/interviewInformation";
// import ScreenerReviewInformation from "./recruiters/screenerReviewInformation";
// import reviewDetails from "./screenerEvents/reviewDetails";
// import FeedbackInformation from "./feedback/feedbackInformation";

// // import ScreenerList from "./recruiters/screenerList";
// import CandidateList from "./recruiters/candidateList";
// import InterviewList from "./recruiters/interviewlist";
// import ReviewList from "./recruiters/reviewList";
// import FeedbackList from "./feedback/feedbackList";
// import profilePage from "./profile/profilePage";
// import screenerInterviewList from "./screenerEvents/screenerInterviewList";
// import screenerReviewList from "./screenerEvents/screenerReviewList";
// import availableScreener from "./availablity/availableScreener";
// import candidateInterviewList from "./candidateinterviews/candidateInterviewList";
// import CreateRecruiter from "./auth/CreateRecruiter";
// import screenerReview from "./admin/screenerReview";
// import profileEdit from "./profile/profileEdit";

// import Landing from "./layout/Landing";
// import LandingPage from "./landingpage/LandingPage";
// import { GoogleOAuthProvider } from "@react-oauth/google";
// import RegisterComponent from "./auth/RegisterComponent";
// import CompanyRegistration from "./auth/CompanyRegistration";
// import CandidateProfile from "./userProfiles/CandidateProfile";
// import ScreenerProfile from "./userProfiles/ScreenerProfile";
// import CompanyProfile from "./userProfiles/CompanyProfile";
// import RecruiterProfile from "./userProfiles/RecruiterProfile";
// import CandidateEmailVerify from "./auth/CandidateEmailVerify";
// import ScreenerEmailVerify from "./auth/ScreenerEmailVerify";
// import CompanyEmailVerify from "./auth/CompanyEmailVerify";
// import ForgotPassword from "./auth/ForgotPassword";
// import CandidateResetPassword from "./auth/CandidateResetPassword";
// import ScreenerResetPassword from "./auth/ScreenerResetPassword";
// import AdminResetPassword from "./auth/AdminResetPassword";
// import RecruiterResetPassword from "./auth/RecruiterResetPassword";
// import RecruiterAddPassword from "./auth/RecruiterAddPassword";

// import PageNotFound from "./ErrorHandling/PageNotFound";
// import PdfViewer from "./userProfiles/PdfViewer";

// import RecruiterList from "./company admin/RecruiterList";
// import UserList from "./company admin/UserList";
// import UserAddPassword from "./auth/UsersAddPassword";
// import ScreenerList from "./Recruiter/ScreenerListNew";
// import CandidateListNew from "./Recruiter/CandidateListNew";
// import MainSettings from "./company admin/settings/MainSettings";
// import Invitation from "./company admin/Invitation/Invitation";
// import UsersList from "./company admin/Internal Users/UsersList";
// import InternalUsersProfile from "./userProfiles/InternalUsersProfile";
// import FavoritesList from "./company admin/Favorites/FavoritesList";
// import Interview from "./company admin/Interviews/Interview";
// import UnderDevelopment from "./ErrorHandling/UnderDevelopment";
// import AdvancedSearch from "./company admin/Internal Users/AdvancedSearch";
// import Search from "./company admin/Search/Search";
// import ClientsList from "./company admin/Clients/ClientsList";
// import PositionsList from "./company admin/Positions/PositionsList";
// import AdminDashboard from "./company admin/Dashboard/AdminDashboard";

// import InterviewPage from "./sidebar components/Interviews/Interview";
// import Subscribers from "./company admin/Subscribers/Subscribers";
// import RecruiterSearch from "./sidebar components/Search/Search";
// import RecruiterFavoritesList from "./sidebar components/Favorites/FavoritesList";
// import RecruiterMainSettings from "./sidebar components/Settings/MainSettings";
// import RecruiterInvitation from "./sidebar components/Invitation/Invitation";
// import MyRecruiterlist from "./sidebar components/MyRecruiters/RecruiterList";
// import MyUserlist from "./sidebar components/MyUsers/UserList";
// import RecruiterUsersList from "./sidebar components/Internal Users/UsersList";
// import RecruiterSubscribers from "./sidebar components/Subscribers/Subscribers";
// import RecruiterClientsList from "./sidebar components/Clients/ClientsList";
// import RecruiterPositionsList from "./sidebar components/Positions/PositionsList";
// import RecruiterDashboard from "./sidebar components/Dashboard/RecruiterDashboard";
// import CandidateInterview from "./Interviews/CandidateInterview";
// import ScreenerInterview from "./Interviews/ScreenerInterview";
// import ScreenerAddPassword from "./auth/ScreenerAddPassword";
// import CandidateAddPassword from "./auth/CandidateAddPassword";
// import ScreenerDashboard from "./Dashboards/ScreenerDashboard";
// import CandidateDashboard from "./Dashboards/CandidateDashboard";
// import InternalPdfViewer from "./company admin/Internal Users/InternalPdfViewer";
// import PublicViewProfile from "./userProfiles/PublicViewProfile";

// Modal.setAppElement(document.getElementById("root"));

// class App extends React.Component {

//   constructor(props) {

//     super(props);
//     this.events = [
//       "load",
//       "mousemove",
//       "mousedown",
//       "click",
//       "scroll",
//       "keypress",
//     ];
//     // this.warn = this.warn.bind(this);
//     this.logout = this.logout.bind(this);
//     this.resetTimeout = this.resetTimeout.bind(this);
//     for (var i in this.events) {
//       window.addEventListener(this.events[i], this.resetTimeout);
//     }
//     this.setTimeout();
//   }
//   clearTimeout() {
//     if (this.logoutTimeout) clearTimeout(this.logoutTimeout);
//   }
//   setTimeout() {
//     this.logoutTimeout = setTimeout(this.logout, 60 * 20000);
//   }
//   resetTimeout() {
//     this.clearTimeout();
//     this.setTimeout();
//   }
//   logout() {
//     // Send a logout request to the API
//     sessionStorage.removeItem("jwtToken");
//     sessionStorage.removeItem("id");
//     sessionStorage.removeItem("role");
//     sessionStorage.removeItem("companyId");
//     localStorage.clear();

//     //logout after the session is done
//     window.location.reload(false);
//   }
//   destroy() {
//     this.clearTimeout();
//     for (var i in this.events) {
//       window.removeEventListener(this.events[i], this.resetTimeout);
//     }
//   }
//   render() {
//     const user = sessionStorage.getItem("jwtToken");
//     console.log(user)
//     // const token = localStorage.getItem("jwtToken");
//     return (
//       <>
//         {/* <NavbarContainer /> */}
//         <div className="main-container">
//           <GoogleOAuthProvider clientId="458868131518-qshhdo2sc2cfkf704q76fhkctb4pgg53.apps.googleusercontent.com">
//             <Switch>
//               {/* <LandingPage/> */}
//               <Route exact path="/" component={LandingPage} />
//               <Route exact path="/login" component={Landing} />
//               <Route
//                 exact
//                 path="/register/:fName?/:lName?/:Email?/:roleType?"
//                 component={RegisterComponent}
//               />
//               <Route
//                 exact
//                 path="/company-registration"
//                 component={CompanyRegistration}
//               />
//               <Route exact path="/forgot-password" component={ForgotPassword} />

//               <Route
//                 exact
//                 path="/:id/candidate-verify/:token"
//                 component={CandidateEmailVerify}
//               />
//               <Route
//                 exact
//                 path="/:id/screener-verify/:token"
//                 component={ScreenerEmailVerify}
//               />
//               <Route
//                 exact
//                 path="/:id/company-verify/:token"
//                 component={CompanyEmailVerify}
//               />

//               <Route
//                 exact
//                 path="/:id/candidate/reset-password/:token"
//                 component={CandidateResetPassword}
//               />
//               <Route
//                 exact
//                 path="/:id/screener/reset-password/:token"
//                 component={ScreenerResetPassword}
//               />
//               <Route
//                 exact
//                 path="/:id/company/reset-password/:token"
//                 component={AdminResetPassword}
//               />
//               <Route
//                 exact
//                 path="/:id/recruiter/reset-password/:token"
//                 component={RecruiterResetPassword}
//               />
//               <Route
//                 exact
//                 path="/:id/recruiter/add-password/:token"
//                 component={RecruiterAddPassword}
//               />
//               <Route
//                 exact
//                 path="/:id/myUsers/add-password/:token"
//                 component={UserAddPassword}
//               />
//               <Route
//                 exact
//                 path="/:id/screener/add-password/:token"
//                 component={ScreenerAddPassword}
//               />
//               <Route
//                 exact
//                 path="/:id/candidate/add-password/:token"
//                 component={CandidateAddPassword}
//               />
//               <Route
//                 exact
//                 path="/:role/internal-db/profile/:userRole/:userId/:companyId"
//                 component={InternalUsersProfile}
//               />
//               <Route
//                 exact
//                 path="/profile/share/:userRole/:userId/:companyId"
//                 component={PublicViewProfile}
//               />
//               {user ? (
//                 <Route
//                   exact
//                   path="/candidate/profile"
//                   component={CandidateProfile}
//                 />
//               ) : (
//                 <Redirect to="/" />
//               )}
//               {user ? (
//                 <Route
//                   exact
//                   path="/screener/profile"
//                   component={ScreenerProfile}
//                 />
//               ) : (
//                 <Redirect to="/" />
//               )}
//               {user ? (
//                 <Route
//                   exact
//                   path="/recruiter/profile"
//                   component={RecruiterProfile}
//                 />
//               ) : (
//                 <Redirect to="/" />
//               )}

//               {user ? (
//                 <Route
//                   exact
//                   path="/candidate/interviews/:interviewId"
//                   component={CandidateInterview}
//                 />
//               ) : (
//                 <Redirect to="/" />
//               )}
//               {user ? (
//                 <Route
//                   exact
//                   path="/candidate/dashboard"
//                   component={CandidateDashboard}
//                 />
//               ) : (
//                 <Redirect to="/" />
//               )}
//               {user ? (
//                 <Route
//                   exact
//                   path="/screener/dashboard"
//                   component={ScreenerDashboard}
//                 />
//               ) : (
//                 <Redirect to="/" />
//               )}
//               {user ? (
//                 <Route
//                   exact
//                   path="/screener/interviews/:interviewId"
//                   component={ScreenerInterview}
//                 />
//               ) : (
//                 <Redirect to="/" />
//               )}
//               {user ? (
//                 <Route
//                   exact
//                   path="/recruiter/screener-list"
//                   component={ScreenerList}
//                 />
//               ) : (
//                 <Redirect to="/" />
//               )}
//               {user ? (
//                 <Route
//                   exact
//                   path="/recruiter/candidate-list"
//                   component={CandidateListNew}
//                 />
//               ) : (
//                 <Redirect to="/" />
//               )}
//               {user ? (
//                 <Route
//                   exact
//                   path="/recruiter/clients"
//                   component={RecruiterClientsList}
//                 />
//               ) : (
//                 <Redirect to="/" />
//               )}
//               {user ? (
//                 <Route exact path="/admin/profile" component={CompanyProfile} />
//               ) : (
//                 <Redirect to="/" />
//               )}

//               {user ? (
//                 <Route exact path="/admin/recruiters" component={RecruiterList} />
//               ) : (
//                 <Redirect to="/" />
//               )}
//               {user ? (
//                 <Route exact path="/admin/users" component={UserList} />
//               ) : (
//                 <Redirect to="/" />
//               )}
//               {user ? (
//                 <Route exact path="/admin/invite" component={Invitation} />
//               ) : (
//                 <Redirect to="/" />
//               )}
//               {user ? (
//                 <Route exact path="/admin/internal-users" component={UsersList} />
//               ) : (
//                 <Redirect to="/" />
//               )}
//               {user ? (
//                 <Route exact path="/admin/interviews" component={Interview} />
//               ) : (
//                 <Redirect to="/" />
//               )}
//               {user ? (
//                 <Route exact path="/admin/favorites" component={FavoritesList} />
//               ) : (
//                 <Redirect to="/" />
//               )}
//               {user ? (
//                 <Route exact path="/admin/advanced-search" component={Search} />
//               ) : (
//                 <Redirect to="/" />
//               )}

//               {user ? (
//                 <Route exact path="/admin/subscribers" component={Subscribers} />
//               ) : (
//                 <Redirect to="/" />
//               )}

//               {user ? (
//                 <Route exact path="/admin/settings" component={MainSettings} />
//               ) : (
//                 <Redirect to="/" />
//               )}

//               {user ? (
//                 <Route path="/admin/dashboard" exact component={AdminDashboard} />
//               ) : (
//                 <Redirect to="/" />
//               )}

//               {user ? (
//                 <Route path="/admin/notification" exact component={UnderDevelopment} />
//               ) : (
//                 <Redirect to="/" />
//               )}

//               {user ? (
//                 <Route path="/admin/stats" exact component={UnderDevelopment} />
//               ) : (
//                 <Redirect to="/" />
//               )}

//               {user ? (
//                 <Route path="/admin/report" exact component={UnderDevelopment} />
//               ) : (
//                 <Redirect to="/" />
//               )}

//               {user ? (
//                 <Route path="/admin/payment" exact component={UnderDevelopment} />
//               ) : (
//                 <Redirect to="/" />
//               )}
//               {user ? (
//                 <Route path="/admin/clients" exact component={ClientsList} />
//               ) : (
//                 <Redirect to="/" />
//               )}
//               {user ? (
//                 <Route path="/admin/positions" exact component={PositionsList} />
//               ) : (
//                 <Redirect to="/" />
//               )}


//               {/* Recruiter profile  */}
//               {user ? (
//                 <Route exact path="/recruiter/interviews" component={InterviewPage} />
//               ) : (
//                 <Redirect to="/" />
//               )}
//               {user ? (
//                 <Route exact path="/recruiter/advanced-search" component={RecruiterSearch} />
//               ) : (
//                 <Redirect to="/" />
//               )}
//               {user ? (
//                 <Route exact path="/recruiter/favorites" component={RecruiterFavoritesList} />
//               ) : (
//                 <Redirect to="/" />
//               )}
//               {user ? (
//                 <Route exact path="/recruiter/settings" component={RecruiterMainSettings} />
//               ) : (
//                 <Redirect to="/" />
//               )}

//               {user ? (
//                 <Route exact path="/recruiter/invite" component={RecruiterInvitation} />
//               ) : (
//                 <Redirect to="/" />
//               )}
//               {user ? (
//                 <Route exact path="/recruiter/recruiters" component={MyRecruiterlist} />
//               ) : (
//                 <Redirect to="/" />
//               )}
//               {user ? (
//                 <Route exact path="/recruiter/users" component={MyUserlist} />
//               ) : (
//                 <Redirect to="/" />
//               )}
//               {user ? (
//                 <Route exact path="/recruiter/internal-users" component={RecruiterUsersList} />
//               ) : (
//                 <Redirect to="/" />
//               )}

//               {user ? (
//                 <Route exact path="/recruiter/subscribers" component={RecruiterSubscribers} />
//               ) : (
//                 <Redirect to="/" />
//               )}
//               {user ? (
//                 <Route exact path="/recruiter/positions" component={RecruiterPositionsList} />
//               ) : (
//                 <Redirect to="/" />
//               )}
//               {user ? (
//                 <Route exact path="/recruiter/dashboard" component={RecruiterDashboard} />
//               ) : (
//                 <Redirect to="/" />
//               )}
//               {/* Pdf Viewer */}
//               <Route
//                 exact
//                 path="/:role/profile/:firstName/resume/:id"
//                 component={PdfViewer}
//               />
//               <Route
//                 exact
//                 path="/resume-viewer/:role/:id"
//                 component={InternalPdfViewer}
//               />

//               <PrivateRoute exact path="/home" component={Homepage} />

//               <ScreenerRoute
//                 exact
//                 path="/interview/:interviewId"
//                 component={InterviewDetails}
//               />

//               <Route exact path="/register-wizard" component={EntryWizard} />
//               <PrivateRoute exact path="/Google-Hire" component={GoogleHire} />
//               <PrivateRoute exact path="/question" component={Question} />

//               {/* <PrivateRoute
//               exact
//               path="/screener-list"
//               component={ScreenerList}
//             /> */}
//               <PrivateRoute
//                 exact
//                 path="/candidate-list"
//                 component={CandidateList}
//               />
//               <RecruiterRoute
//                 exact
//                 path="/interview-list"
//                 component={InterviewList}
//               />
//               <RecruiterRoute exact path="/review-list" component={ReviewList} />
//               <RecruiterRoute
//                 exact
//                 path="/candidate-feedback"
//                 component={FeedbackList}
//               />
//               <PrivateRoute exact path="/Profile" component={profilePage} />

//               <AdminRoute
//                 exact
//                 path="/admin/recruiter-list"
//                 component={CreateRecruiter}
//               />

//               {/* <AdminRoute exact path="/admin/screener-list" component={ScreenerList} />
//                         <AdminRoute exact path="/admin/candidate-list" component={CandidateList} /> */}

//               <ScreenerRoute
//                 exact
//                 path="/screener-interview-list"
//                 component={screenerInterviewList}
//               />
//               <ScreenerRoute
//                 exact
//                 path="/screener-review-list"
//                 component={screenerReviewList}
//               />
//               <ScreenerRoute
//                 exact
//                 path="/calendar"
//                 component={availableScreener}
//               />

//               <CandidateRoute
//                 exact
//                 path="/candidate-interview-list"
//                 component={candidateInterviewList}
//               />

//               <PrivateRoute exact path="/Schedule" component={CalenderEvent} />
//               <RecruiterRoute exact path="/Schedule-new" component={Schedule} />
//               {/* <Route exact path="/addInterview" component={ScheduleInterview}/>
//                         <Route exact path="/addReview" component={CreateReview}/>  
//                         <Route exact path = "/:id" component={UpdateInterview}/>
//                         <Route exact path = "/updatereview/:id" component={UpdateReview}/> */}

//               <RecruiterRoute
//                 exact
//                 path="/addInterview"
//                 component={ScheduleInterview}
//               />
//               <RecruiterRoute exact path="/addReview" component={CreateReview} />
//               <RecruiterRoute exact path="/:id" component={UpdateInterview} />
//               <RecruiterRoute
//                 exact
//                 path="/updatereview/:id"
//                 component={UpdateReview}
//               />

//               <ScreenerRoute
//                 exact
//                 path="/Assessment/:id"
//                 component={Assessment}
//               />

//               {/* <Route exact path = "/candidate/viewprofile/:id" component={CandidateInformation}/>
//                         <Route exact path = "/screener/viewprofile/:id" component={ScreenerInformation}/>
//                         <Route exact path = "/interview/interviewdetails/:id" component={InterviewInformation}/>
//                         <Route exact path = "/review/reviewdetails/:id" component={ScreenerReviewInformation}/>
//                         <Route exact path = "/screener/reviewdetails/:id" component={reviewDetails}/>
//                         <Route exact path = "/interview/candidatefeedback/:id" component={FeedbackInformation}/> */}

//               <PrivateRoute
//                 exact
//                 path="/candidate/viewprofile/:id"
//                 component={CandidateInformation}
//               />
//               <PrivateRoute
//                 exact
//                 path="/screener/viewprofile/:id"
//                 component={ScreenerInformation}
//               />
//               <RecruiterRoute
//                 exact
//                 path="/interview/interviewdetails/:id"
//                 component={InterviewInformation}
//               />
//               <RecruiterRoute
//                 exact
//                 path="/review/reviewdetails/:id"
//                 component={ScreenerReviewInformation}
//               />
//               <ScreenerRoute
//                 exact
//                 path="/screener/reviewdetails/:id"
//                 component={reviewDetails}
//               />
//               <RecruiterRoute
//                 exact
//                 path="/interview/candidatefeedback/:id"
//                 component={FeedbackInformation}
//               />

//               <PrivateRoute
//                 exact
//                 path="/profile_edit/:id"
//                 component={profileEdit}
//               />

//               <AdminRoute
//                 exact
//                 path="/recruiter/update/:id"
//                 component={UpdateRecruiter}
//               />
//               <AdminRoute
//                 exact
//                 path="/new/addrecruiter"
//                 component={AddRecruiter}
//               />
//               <AdminRoute
//                 exact
//                 path="/admin/screener-review-list"
//                 component={screenerReview}
//               />

//               <Route path="*" component={PageNotFound} />


//               {/* <RecruiterRoute exact path="dashboard-recruiter" component={Dashboardrecruiter}/> */}
//             </Switch>
//           </GoogleOAuthProvider>

//         </div>
//       </>
//     );
//   }
// }

// export default App;


import React, { Component } from "react";
import Modal from "react-modal";
import { Route, Switch, Redirect, HashRouter } from "react-router-dom";
import { useEffect, useState, useRef } from "react";

import EntryWizard from "./entryWizard/entryWizard";
import PrivateRoute from "./guards/PrivateRoute";
import RecruiterRoute from "./guards/RecruiterRoute";
import ScreenerRoute from "./guards/ScreenerRoute";
import CandidateRoute from "./guards/CandidateRoute";
import AdminRoute from "./guards/AdminRoute";

// import Login from "./auth/Login";
import Homepage from "./home/Homepage";
import GoogleHire from "./iFrameHire/iframeHire";
import InterviewDetails from "./interview/interviewDetails";
import FooterContainer from "./layout/FooterContainer";

import NavbarContainer from "./layout/NavbarContainer";
import Question from "./question/Question";
import CalenderEvent from "./schedule/calenderEvent";
import Schedule from "./scheduling/schedule";
//import ScreenerList from "./screener-list/ScreenerList";
//import Dashboardrecruiter from "./Dashboard-Recruiter/dashboard-recruiter"
import AddRecruiter from "./auth/AddRecruiter";
import UpdateRecruiter from "./auth/UpdateRecruiter";
import ScheduleInterview from "./recruiters/scheduleInterview";
import UpdateInterview from "./recruiters/updateInterview";
import CreateReview from "./recruiters/addReview";
import UpdateReview from "./recruiters/updateReview";
import Assessment from "./feedback/candidateAssessment/Assessment";
import CandidateInformation from "./recruiters/candidateInformation";
import ScreenerInformation from "./recruiters/screenerInformation";
import InterviewInformation from "./recruiters/interviewInformation";
import ScreenerReviewInformation from "./recruiters/screenerReviewInformation";
import reviewDetails from "./screenerEvents/reviewDetails";
import FeedbackInformation from "./feedback/feedbackInformation";

// import ScreenerList from "./recruiters/screenerList";
import CandidateList from "./recruiters/candidateList";
import InterviewList from "./recruiters/interviewlist";
import ReviewList from "./recruiters/reviewList";
import FeedbackList from "./feedback/feedbackList";
import profilePage from "./profile/profilePage";
import screenerInterviewList from "./screenerEvents/screenerInterviewList";
import screenerReviewList from "./screenerEvents/screenerReviewList";
import availableScreener from "./availablity/availableScreener";
import candidateInterviewList from "./candidateinterviews/candidateInterviewList";
import CreateRecruiter from "./auth/CreateRecruiter";
import screenerReview from "./admin/screenerReview";
import profileEdit from "./profile/profileEdit";

import Landing from "./layout/Landing";
import LandingPage from "./landingpage/LandingPage";
import { GoogleOAuthProvider } from "@react-oauth/google";
import RegisterComponent from "./auth/RegisterComponent";
import CompanyRegistration from "./auth/CompanyRegistration";
import CandidateProfile from "./userProfiles/CandidateProfile";
import ScreenerProfile from "./userProfiles/ScreenerProfile";
import CompanyProfile from "./userProfiles/CompanyProfile";
import RecruiterProfile from "./userProfiles/RecruiterProfile";
import CandidateEmailVerify from "./auth/CandidateEmailVerify";
import ScreenerEmailVerify from "./auth/ScreenerEmailVerify";
import CompanyEmailVerify from "./auth/CompanyEmailVerify";
import ForgotPassword from "./auth/ForgotPassword";
import CandidateResetPassword from "./auth/CandidateResetPassword";
import ScreenerResetPassword from "./auth/ScreenerResetPassword";
import AdminResetPassword from "./auth/AdminResetPassword";
import RecruiterResetPassword from "./auth/RecruiterResetPassword";
import RecruiterAddPassword from "./auth/RecruiterAddPassword";

import PageNotFound from "./ErrorHandling/PageNotFound";
import PdfViewer from "./userProfiles/PdfViewer";

import RecruiterList from "./company admin/RecruiterList";
import UserList from "./company admin/UserList";
import UserAddPassword from "./auth/UsersAddPassword";
import ScreenerList from "./Recruiter/ScreenerListNew";
import CandidateListNew from "./Recruiter/CandidateListNew";
import MainSettings from "./company admin/settings/MainSettings";
import Invitation from "./company admin/Invitation/Invitation";
import UsersList from "./company admin/Internal Users/UsersList";
import InternalUsersProfile from "./userProfiles/InternalUsersProfile";
import FavoritesList from "./company admin/Favorites/FavoritesList";
import Interview from "./company admin/Interviews/Interview";
import UnderDevelopment from "./ErrorHandling/UnderDevelopment";
import AdvancedSearch from "./company admin/Internal Users/AdvancedSearch";
import Search from "./company admin/Search/Search";
import ClientsList from "./company admin/Clients/ClientsList";
import PositionsList from "./company admin/Positions/PositionsList";
import AdminDashboard from "./company admin/Dashboard/AdminDashboard";

import InterviewPage from "./sidebar components/Interviews/Interview";
import Subscribers from "./company admin/Subscribers/Subscribers";
import RecruiterSearch from "./sidebar components/Search/Search";
import RecruiterFavoritesList from "./sidebar components/Favorites/FavoritesList";
import RecruiterMainSettings from "./sidebar components/Settings/MainSettings";
import RecruiterInvitation from "./sidebar components/Invitation/Invitation";
import MyRecruiterlist from "./sidebar components/MyRecruiters/RecruiterList";
import MyUserlist from "./sidebar components/MyUsers/UserList";
import RecruiterUsersList from "./sidebar components/Internal Users/UsersList";
import RecruiterSubscribers from "./sidebar components/Subscribers/Subscribers";
import RecruiterClientsList from "./sidebar components/Clients/ClientsList";
import RecruiterPositionsList from "./sidebar components/Positions/PositionsList";
import RecruiterDashboard from "./sidebar components/Dashboard/RecruiterDashboard";
import CandidateInterview from "./Interviews/CandidateInterview";
import ScreenerInterview from "./Interviews/ScreenerInterview";
import ScreenerAddPassword from "./auth/ScreenerAddPassword";
import CandidateAddPassword from "./auth/CandidateAddPassword";
import ScreenerDashboard from "./Dashboards/ScreenerDashboard";
import CandidateDashboard from "./Dashboards/CandidateDashboard";
import InternalPdfViewer from "./company admin/Internal Users/InternalPdfViewer";
import PublicViewProfile from "./userProfiles/PublicViewProfile";

import io from "socket.io-client";

Modal.setAppElement(document.getElementById("root"));

function App() {
  const events = [
    "load",
    "mousemove",
    "mousedown",
    "click",
    "scroll",
    "keypress",
  ];
  const logoutTimeoutRef = useRef();
  const user = sessionStorage.getItem("jwtToken");

  useEffect(() => {

    // Connect to the Socket.IO server

    const socket = io.connect();
    // const socket = io.connect(process.env.REACT_APP_URL, {
    // secure: true,
    // rejectUnauthorized: false // Use only for development, DO NOT use in production
    // });

    // Event handler for when the connection is established
    console.log("Socket connection is establishing")
    socket.on('connect', () => {
      console.log('Connected to server');
      socket.on('message', function (data) {
        console.log(data);
      });
    });

    // Cleanup function to disconnect from the server when the component unmounts
    return () => {
      socket.disconnect();
      console.log('Disconnected from server');
    };
  }, []);

  // const sendMessage = () => {
  //   socket.on('connect', () => {
  //     console.log('Connected to server');
  //   });
  // };

  // const socket = io("https://localhost:5000");

  // const socket = io.connect("https://127.0.0.1:5000", {
  //   cors: {
  //     origin: "https://127.0.0.1:3000",
  //   },
  // });

  // const socket = socketIOClient("https://127.0.0.1:5000/");
  // socket.on("message1", (data) => {
  //   console.log("data", data);
  // });

  // socket.on("data1", (res) => {
  //   console.log("Message from the server socket", res);
  // });

  //  socket.on("connect", () => {
  //    console.log("Socket connected:");
  //  });

  // const sendMessage = () => {
  //   const message = "hello from react";
  //   console.log("sending mesaage", message);

  //   socket.emit("message", message);
  // };

  // socket.on("disconnect", () => {
  //   console.log("Socket disconnected");
  // });

  const clearTimeoutAndRemoveListeners = () => {
    clearTimeout(logoutTimeoutRef.current);
    events.forEach((event) => window.removeEventListener(event, resetTimeout));
  };

  const setTimeoutAndAddListeners = () => {
    logoutTimeoutRef.current = setTimeout(logout, 60 * 20000);
    events.forEach((event) => window.addEventListener(event, resetTimeout));
  };

  const resetTimeout = () => {
    clearTimeoutAndRemoveListeners();
    setTimeoutAndAddListeners();
  };

  const logout = () => {
    // Send a logout request to the API
    sessionStorage.removeItem("jwtToken");
    sessionStorage.removeItem("id");
    sessionStorage.removeItem("role");
    sessionStorage.removeItem("companyId");
    localStorage.clear();

    // Logout after the session is done
    window.location.reload(false);
  };

  useEffect(() => {
    setTimeoutAndAddListeners();

    return () => {
      clearTimeoutAndRemoveListeners();
    };
  }, []); // Empty dependency array ensures useEffect runs only once on mount

  return (
    <>
      {/* <NavbarContainer /> */}
      <div className="main-container">
        {/* <button onClick={sendMessage}>click here</button> */}

        <GoogleOAuthProvider clientId="458868131518-qshhdo2sc2cfkf704q76fhkctb4pgg53.apps.googleusercontent.com">
          <Switch>
            {/* <LandingPage/> */}
            <Route exact path="/" component={LandingPage} />
            <Route exact path="/login" component={Landing} />
            <Route
              exact
              path="/register/:fName?/:lName?/:Email?/:roleType?"
              component={RegisterComponent}
            />
            <Route
              exact
              path="/company-registration"
              component={CompanyRegistration}
            />
            <Route exact path="/forgot-password" component={ForgotPassword} />

            <Route
              exact
              path="/:id/candidate-verify/:token"
              component={CandidateEmailVerify}
            />
            <Route
              exact
              path="/:id/screener-verify/:token"
              component={ScreenerEmailVerify}
            />
            <Route
              exact
              path="/:id/company-verify/:token"
              component={CompanyEmailVerify}
            />

            <Route
              exact
              path="/:id/candidate/reset-password/:token"
              component={CandidateResetPassword}
            />
            <Route
              exact
              path="/:id/screener/reset-password/:token"
              component={ScreenerResetPassword}
            />
            <Route
              exact
              path="/:id/company/reset-password/:token"
              component={AdminResetPassword}
            />
            <Route
              exact
              path="/:id/recruiter/reset-password/:token"
              component={RecruiterResetPassword}
            />
            <Route
              exact
              path="/:id/recruiter/add-password/:token"
              component={RecruiterAddPassword}
            />
            <Route
              exact
              path="/:id/myUsers/add-password/:token"
              component={UserAddPassword}
            />
            <Route
              exact
              path="/:id/screener/add-password/:token"
              component={ScreenerAddPassword}
            />
            <Route
              exact
              path="/:id/candidate/add-password/:token"
              component={CandidateAddPassword}
            />
            <Route
              exact
              path="/:role/internal-db/profile/:userRole/:userId/:companyId"
              component={InternalUsersProfile}
            />
            <Route
              exact
              path="/profile/share/:userRole/:userId/:companyId"
              component={PublicViewProfile}
            />
            {user ? (
              <Route
                exact
                path="/candidate/profile"
                component={CandidateProfile}
              />
            ) : (
              <Redirect to="/" />
            )}
            {user ? (
              <Route
                exact
                path="/screener/profile"
                component={ScreenerProfile}
              />
            ) : (
              <Redirect to="/" />
            )}
            {user ? (
              <Route
                exact
                path="/recruiter/profile"
                component={RecruiterProfile}
              />
            ) : (
              <Redirect to="/" />
            )}

            {user ? (
              <Route
                exact
                path="/candidate/interviews/:interviewId"
                component={CandidateInterview}
              />
            ) : (
              <Redirect to="/" />
            )}
            {user ? (
              <Route
                exact
                path="/candidate/dashboard"
                component={CandidateDashboard}
              />
            ) : (
              <Redirect to="/" />
            )}
            {user ? (
              <Route
                exact
                path="/screener/dashboard"
                component={ScreenerDashboard}
              />
            ) : (
              <Redirect to="/" />
            )}
            {user ? (
              <Route
                exact
                path="/screener/interviews/:interviewId"
                component={ScreenerInterview}
              />
            ) : (
              <Redirect to="/" />
            )}
            {user ? (
              <Route
                exact
                path="/recruiter/screener-list"
                component={ScreenerList}
              />
            ) : (
              <Redirect to="/" />
            )}
            {user ? (
              <Route
                exact
                path="/recruiter/candidate-list"
                component={CandidateListNew}
              />
            ) : (
              <Redirect to="/" />
            )}
            {user ? (
              <Route
                exact
                path="/recruiter/clients"
                component={RecruiterClientsList}
              />
            ) : (
              <Redirect to="/" />
            )}
            {user ? (
              <Route exact path="/admin/profile" component={CompanyProfile} />
            ) : (
              <Redirect to="/" />
            )}

            {user ? (
              <Route exact path="/admin/recruiters" component={RecruiterList} />
            ) : (
              <Redirect to="/" />
            )}
            {user ? (
              <Route exact path="/admin/users" component={UserList} />
            ) : (
              <Redirect to="/" />
            )}
            {user ? (
              <Route exact path="/admin/invite" component={Invitation} />
            ) : (
              <Redirect to="/" />
            )}
            {user ? (
              <Route exact path="/admin/internal-users" component={UsersList} />
            ) : (
              <Redirect to="/" />
            )}
            {user ? (
              <Route exact path="/admin/interviews" component={Interview} />
            ) : (
              <Redirect to="/" />
            )}
            {user ? (
              <Route exact path="/admin/favorites" component={FavoritesList} />
            ) : (
              <Redirect to="/" />
            )}
            {user ? (
              <Route exact path="/admin/advanced-search" component={Search} />
            ) : (
              <Redirect to="/" />
            )}

            {user ? (
              <Route exact path="/admin/subscribers" component={Subscribers} />
            ) : (
              <Redirect to="/" />
            )}

            {user ? (
              <Route exact path="/admin/settings" component={MainSettings} />
            ) : (
              <Redirect to="/" />
            )}

            {user ? (
              <Route path="/admin/dashboard" exact component={AdminDashboard} />
            ) : (
              <Redirect to="/" />
            )}

            {user ? (
              <Route
                path="/admin/notification"
                exact
                component={UnderDevelopment}
              />
            ) : (
              <Redirect to="/" />
            )}

            {user ? (
              <Route path="/admin/stats" exact component={UnderDevelopment} />
            ) : (
              <Redirect to="/" />
            )}

            {user ? (
              <Route path="/admin/report" exact component={UnderDevelopment} />
            ) : (
              <Redirect to="/" />
            )}

            {user ? (
              <Route path="/admin/payment" exact component={UnderDevelopment} />
            ) : (
              <Redirect to="/" />
            )}
            {user ? (
              <Route path="/admin/clients" exact component={ClientsList} />
            ) : (
              <Redirect to="/" />
            )}
            {user ? (
              <Route path="/admin/positions" exact component={PositionsList} />
            ) : (
              <Redirect to="/" />
            )}

            {/* Recruiter profile  */}
            {user ? (
              <Route
                exact
                path="/recruiter/interviews"
                component={InterviewPage}
              />
            ) : (
              <Redirect to="/" />
            )}
            {user ? (
              <Route
                exact
                path="/recruiter/advanced-search"
                component={RecruiterSearch}
              />
            ) : (
              <Redirect to="/" />
            )}
            {user ? (
              <Route
                exact
                path="/recruiter/favorites"
                component={RecruiterFavoritesList}
              />
            ) : (
              <Redirect to="/" />
            )}
            {user ? (
              <Route
                exact
                path="/recruiter/settings"
                component={RecruiterMainSettings}
              />
            ) : (
              <Redirect to="/" />
            )}

            {user ? (
              <Route
                exact
                path="/recruiter/invite"
                component={RecruiterInvitation}
              />
            ) : (
              <Redirect to="/" />
            )}
            {user ? (
              <Route
                exact
                path="/recruiter/recruiters"
                component={MyRecruiterlist}
              />
            ) : (
              <Redirect to="/" />
            )}
            {user ? (
              <Route exact path="/recruiter/users" component={MyUserlist} />
            ) : (
              <Redirect to="/" />
            )}
            {user ? (
              <Route
                exact
                path="/recruiter/internal-users"
                component={RecruiterUsersList}
              />
            ) : (
              <Redirect to="/" />
            )}

            {user ? (
              <Route
                exact
                path="/recruiter/subscribers"
                component={RecruiterSubscribers}
              />
            ) : (
              <Redirect to="/" />
            )}
            {user ? (
              <Route
                exact
                path="/recruiter/positions"
                component={RecruiterPositionsList}
              />
            ) : (
              <Redirect to="/" />
            )}
            {user ? (
              <Route
                exact
                path="/recruiter/dashboard"
                component={RecruiterDashboard}
              />
            ) : (
              <Redirect to="/" />
            )}
            {/* Pdf Viewer */}
            <Route
              exact
              path="/:role/profile/:firstName/resume/:id"
              component={PdfViewer}
            />
            <Route
              exact
              path="/resume-viewer/:role/:id"
              component={InternalPdfViewer}
            />

            <PrivateRoute exact path="/home" component={Homepage} />

            <ScreenerRoute
              exact
              path="/interview/:interviewId"
              component={InterviewDetails}
            />

            <Route exact path="/register-wizard" component={EntryWizard} />
            <PrivateRoute exact path="/Google-Hire" component={GoogleHire} />
            <PrivateRoute exact path="/question" component={Question} />

            {/* <PrivateRoute
              exact
              path="/screener-list"
              component={ScreenerList}
            /> */}
            <PrivateRoute
              exact
              path="/candidate-list"
              component={CandidateList}
            />
            <RecruiterRoute
              exact
              path="/interview-list"
              component={InterviewList}
            />
            <RecruiterRoute exact path="/review-list" component={ReviewList} />
            <RecruiterRoute
              exact
              path="/candidate-feedback"
              component={FeedbackList}
            />
            <PrivateRoute exact path="/Profile" component={profilePage} />

            <AdminRoute
              exact
              path="/admin/recruiter-list"
              component={CreateRecruiter}
            />

            {/* <AdminRoute exact path="/admin/screener-list" component={ScreenerList} />
                        <AdminRoute exact path="/admin/candidate-list" component={CandidateList} /> */}

            <ScreenerRoute
              exact
              path="/screener-interview-list"
              component={screenerInterviewList}
            />
            <ScreenerRoute
              exact
              path="/screener-review-list"
              component={screenerReviewList}
            />
            <ScreenerRoute
              exact
              path="/calendar"
              component={availableScreener}
            />

            <CandidateRoute
              exact
              path="/candidate-interview-list"
              component={candidateInterviewList}
            />

            <PrivateRoute exact path="/Schedule" component={CalenderEvent} />
            <RecruiterRoute exact path="/Schedule-new" component={Schedule} />
            {/* <Route exact path="/addInterview" component={ScheduleInterview}/>
                        <Route exact path="/addReview" component={CreateReview}/>  
                        <Route exact path = "/:id" component={UpdateInterview}/>
                        <Route exact path = "/updatereview/:id" component={UpdateReview}/> */}

            <RecruiterRoute
              exact
              path="/addInterview"
              component={ScheduleInterview}
            />
            <RecruiterRoute exact path="/addReview" component={CreateReview} />
            <RecruiterRoute exact path="/:id" component={UpdateInterview} />
            <RecruiterRoute
              exact
              path="/updatereview/:id"
              component={UpdateReview}
            />

            <ScreenerRoute
              exact
              path="/Assessment/:id"
              component={Assessment}
            />

            {/* <Route exact path = "/candidate/viewprofile/:id" component={CandidateInformation}/>
                        <Route exact path = "/screener/viewprofile/:id" component={ScreenerInformation}/>
                        <Route exact path = "/interview/interviewdetails/:id" component={InterviewInformation}/>
                        <Route exact path = "/review/reviewdetails/:id" component={ScreenerReviewInformation}/>
                        <Route exact path = "/screener/reviewdetails/:id" component={reviewDetails}/>
                        <Route exact path = "/interview/candidatefeedback/:id" component={FeedbackInformation}/> */}

            <PrivateRoute
              exact
              path="/candidate/viewprofile/:id"
              component={CandidateInformation}
            />
            <PrivateRoute
              exact
              path="/screener/viewprofile/:id"
              component={ScreenerInformation}
            />
            <RecruiterRoute
              exact
              path="/interview/interviewdetails/:id"
              component={InterviewInformation}
            />
            <RecruiterRoute
              exact
              path="/review/reviewdetails/:id"
              component={ScreenerReviewInformation}
            />
            <ScreenerRoute
              exact
              path="/screener/reviewdetails/:id"
              component={reviewDetails}
            />
            <RecruiterRoute
              exact
              path="/interview/candidatefeedback/:id"
              component={FeedbackInformation}
            />

            <PrivateRoute
              exact
              path="/profile_edit/:id"
              component={profileEdit}
            />

            <AdminRoute
              exact
              path="/recruiter/update/:id"
              component={UpdateRecruiter}
            />
            <AdminRoute
              exact
              path="/new/addrecruiter"
              component={AddRecruiter}
            />
            <AdminRoute
              exact
              path="/admin/screener-review-list"
              component={screenerReview}
            />

            <Route path="*" component={PageNotFound} />

            {/* <RecruiterRoute exact path="dashboard-recruiter" component={Dashboardrecruiter}/> */}
          </Switch>
        </GoogleOAuthProvider>
      </div>
    </>
  );
}

export default App;
