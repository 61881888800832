import React, { useState, useRef, useCallback } from 'react'

//MUI Imports
import Slide from "@mui/material/Slide";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CloseIcon from "@mui/icons-material/Close";
import Tooltip from "@mui/material/Tooltip";
import SchoolIcon from '@mui/icons-material/School';
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

//json file for dropdown
import data from "../../../modals/skills.json";

const SkillsDetails = ({ userData, setUserData, nextPage, previousPage, setPreviousPage, count, setCount }) => {

    //Skills 
    const [skill, setSkill] = useState("");
    const [experience, setExperience] = useState("");
    const [skillsList, setSkillsList] = useState(userData.skills);
    const [value, setValue] = useState("");
    const [clear, setClear] = useState(false); // passed inside key attribue of autocomplete to clear value on adding

    const [errors, setErrors] = useState({
        skill: "",
        skillArray: "",
    });
    const autoCompleteRef = useRef(null);
    //page handling 
    const experienceHandler = (e) => {
        const limit = 4;
        setExperience(e.target.value.slice(0, limit));
    }

    //add a new skill
    const addSkill = (e, skill) => {
        e.preventDefault();
        autoCompleteRef.current.focus();
        let comparingArray = skillsList.map((items) => {
            return items.skillName.trim().toLowerCase().replace(/ /g, "");
        });

        if (comparingArray.includes(skill.trim().toLowerCase().replace(/ /g, ""))) {
            return setErrors({
                skill: "Skill already exists",
            });
        }



        //if skill is not empty then it will add to dialog
        if (skill != "") {
            let skillObj = {
                skillName: skill,
                yearOfExperience: experience
            }
            setSkillsList([...skillsList, skillObj]);
            setSkill("");
            setExperience("");
            setValue("");
            setClear((prev) => !prev);
        }



    };

    //function  to delete
    const deleteSkill = (e, index) => {
        e.preventDefault();
        setSkillsList([
            ...skillsList.slice(0, index),
            ...skillsList.slice(index + 1, skillsList.length),
        ]);
    };

    const goToNextPage = () => {
        if (skillsList.length == 0) {
            return setErrors({
                skillArray: "Add atleast one skill"
            })
        }
        const skillObj = {
            skills: skillsList
        }
        let data = { ...userData, ...skillObj }
        setUserData(data)
        if (nextPage) {
            setCount(count + 1)
        } else {
            setCount(count - 1)
        }
        setPreviousPage(false)
    }

    const goToPreviousPage = () => {
        const skillObj = {
            skills: skillsList
        }
        let data = { ...userData, ...skillObj }
        setUserData(data)
        setPreviousPage(true)
        if (nextPage) {
            setCount(count => count - 1)
        } else {
            setCount(count => count + 1)
        }
    }


    return (
        <div>
            <Slide direction={"left"} in={true} timeout={500}>
                <Box sx={{ flexGrow: 1, background: "#faf9f6" }}>
                    <Card
                        id="main-edit"
                        className=" form-card table-card"
                        style={{
                            // margin: "40px 70px",
                            padding: "15px 20px 18px 20px ",
                            boxShadow: "none",
                        }}
                    >
                        <Grid
                            container
                            spacing={0}
                            direction="row"
                            className="add-recruiter-card-container"
                        >
                            <Grid
                                item
                                xs={8}
                                md={5}
                                lg={6}
                            >
                                <div style={{ display: "flex", gap: "10px" }}>
                                    <SchoolIcon sx={{ color: "#2c3e50" }} />
                                    <h5 style={{ color: "#283848" }}>Skills</h5>
                                </div>

                            </Grid>
                            <Grid
                                container
                                spacing={0}
                                direction="row"
                                className="add-recruiter-card-container"
                            >
                                <Grid
                                    xs={12}
                                    sm={12}
                                    md={10}
                                    lg={10}
                                    className="experience-edit-right-grid"
                                    style={{ width: "100%" }}
                                >
                                    <div className="experience-edit-form-container skills-form-container">
                                        <form
                                            className="experience-edit-form"
                                            onSubmit={addSkill}
                                            noValidate
                                        >
                                            <div class="input-container users-form">
                                                <div class="input-field users-skill">
                                                    <label>Add Skill*</label>
                                                    <div className='add-skill-container'>
                                                        <div className="input-fields-flex">
                                                            <Autocomplete
                                                                ref={autoCompleteRef}

                                                                sx={{
                                                                    "& fieldset": { border: "none" },
                                                                }}
                                                                ListboxProps={{ style: { maxHeight: 220 } }}
                                                                key={clear}
                                                                freeSolo
                                                                id="free-solo-demo"
                                                                value={value}
                                                                options={skill ? data.map((item) => item.skill) : []}
                                                                disableClearable={true}
                                                                onChange={(event, newValue) => {
                                                                    setSkill(newValue);
                                                                }}
                                                                renderInput={(params) => (
                                                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                                                        <TextField
                                                                            className={`${errors.skillArray && "input-field-error-skills"}`}
                                                                            value={skill}

                                                                            onChange={(e) => {
                                                                                setSkill(e.target.value);
                                                                                setErrors({
                                                                                    skill: "",
                                                                                    skillArray: ""
                                                                                });
                                                                            }}
                                                                            {...params}
                                                                            InputProps={{
                                                                                ...params.InputProps,
                                                                                disableUnderline: true,
                                                                            }}
                                                                            margin="normal"
                                                                        />
                                                                        <div className='error-div' style={{ height: "20px" }}>
                                                                            <span className="text-danger">{errors.skill}</span>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            />

                                                            <input
                                                                class="position"
                                                                type="number"
                                                                style={{
                                                                    height: "38px", position: "relative", top: "1px", fontSize: "12px"
                                                                }}
                                                                placeholder="Years of Experience"
                                                                value={experience}
                                                                onChange={experienceHandler}
                                                            />
                                                        </div>
                                                        <div className="button-container ">
                                                            <button
                                                                className=" btn-style outlined-btn"
                                                                disabled={false}
                                                                onClick={(e) => addSkill(e, skill)}
                                                            >
                                                                {" "}
                                                                Add
                                                            </button>

                                                        </div>

                                                    </div>




                                                </div>
                                                <div class="input-field">
                                                    <div className="skill-button-container">
                                                        <div className="skills-buttons">
                                                            {skillsList.map((item, index) => {
                                                                return (
                                                                    <Tooltip
                                                                        title={item.yearOfExperience ? `${item.skillName} - ${item.yearOfExperience} year(s)` : `${item.skillName}`}
                                                                    >
                                                                        <button
                                                                            className="outlined"
                                                                            key={index}
                                                                            onClick={(e) => deleteSkill(e, index)}
                                                                        >
                                                                            {item.skillName}
                                                                            <CloseIcon
                                                                                fontSize="small"
                                                                                onClick={(e) => deleteSkill(e, index)}
                                                                            />
                                                                        </button>
                                                                    </Tooltip>
                                                                );
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>
                                                {skillsList.length > 0 ? (
                                                    <div style={{ marginTop: "5px" }}>
                                                        <InfoOutlinedIcon
                                                            fontSize="small"
                                                            style={{ color: "#2c3e50", fontSize: "16px" }}
                                                        />{" "}
                                                        <span style={{ fontSize: "13px" }}>
                                                            Please hover over on each skill to know their individual experience.
                                                        </span>
                                                    </div>
                                                ) : ""}
                                                <span className="text-danger">{errors.skillArray}</span>
                                            </div>
                                        </form>
                                    </div>
                                </Grid>
                            </Grid>
                            <div className="button-container navigation-btn-container" >
                                <button className="btn-style outlined-btn outlined-btn-animation" onClick={goToPreviousPage}>
                                    {" "}
                                    Previous
                                </button>
                                <button className="btn-style filled-btn filled-btn-animation" onClick={goToNextPage}>
                                    {" "}
                                    Next
                                </button>

                            </div>
                        </Grid>
                    </Card>
                </Box>
            </Slide>
        </div>
    )
}

export default SkillsDetails