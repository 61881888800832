import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Pie } from '@ant-design/plots';


const PieChart = ({ clients }) => {
    console.log(clients)
    const data = clients
    const config = {
        appendPadding: 10,
        data,
        angleField: 'value',
        colorField: 'type',
        legend: 'bottom-left',
        radius: 0.8,
        color: ['#ef798a', '#3091A2'],
        label: {
            type: 'outer',
        },
        interactions: [
            {
                type: 'element-active',
            },
        ],
    };
    return <Pie {...config} />;
};

export default PieChart