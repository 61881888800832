import DateFnsUtils from "@date-io/date-fns";
import Grid from "@material-ui/core/Grid";
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from "@material-ui/core/TextField";
import axios from "axios";
import { DatePicker, MuiPickersUtilsProvider, TimePicker } from "material-ui-pickers";
import PropTypes from "prop-types";
import React, { Component } from "react";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { connect } from "react-redux";
import Select from "react-select";
import { logoutUser } from "../../actions/authActions";

const timezones = [
    { value: "EDT", label: "EDT" },
    { value: "CDT", label: "CDT" },
    { value: "PDT", label: "PDT" },
    { value: "MDT", label: "MDT" },
    { value: "IST", label: "IST" },
];

const styles = (theme) => ({
    main: {
        width: "auto",
        display: "block", // Fix IE 11 issue.
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        [theme.breakpoints.up(400 + theme.spacing(6))]: {
            width: "50%",
            marginLeft: "auto",
            marginRight: "auto",
        },
    },

    select: {
        marginTop: theme.spacing() * 1,
        marginLeft: theme.spacing(),
        marginRight: theme.spacing(),
        width: "250px",
    },
    formControl: {
        margin: theme.spacing(),
        minWidth: 120,
        maxWidth: 300,
    },
});

class screenEvent extends Component {
    onSubmit = (e) => {
        e.preventDefault();
        const recruiterData = {
            userID: this.state.userID,
            start: this.state.start,
            end: this.state.end,
            title: this.state.title,
            timeZone: this.state.timeZone,
            description: this.state.description,
            screeners: this.state.screeners,
            type: "recruiter",
        };

        axios.post("/api/schedule/new", recruiterData).then((res) => (window.location = "/Schedule"));

        this.state.screeners.forEach((element) => {
            const screenerData = {
                userID: element,
                start: this.state.start,
                end: this.state.end,
                title: this.state.title,
                timeZone: this.state.timeZone,
                description: this.state.description,
                recruiterID: this.state.userID,
                type: "screener",
            };
            axios.post("/api/schedule/new", screenerData).then((res) => (window.location = "/Schedule"));
        });
    };

    render() {
        const { values, handleInputChange, handleChange, handleStartDateChange, handleEndDateChange, classes } = this.props;

        return (
            <React.Fragment>
                <form className={classes.form} onSubmit={this.onSubmit}>
                    <Grid container spacing={24} style={{ padding: "2em" }}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="title"
                                name="title"
                                required
                                value={values.title}
                                onChange={handleInputChange("title")}
                                fullWidth
                                label="Title"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                className="event-input"
                                name="candidateName"
                                required
                                value={values.candidateName}
                                onChange={handleInputChange("candidateName")}
                                fullWidth
                                label="Candidate Name"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                className="event-input"
                                name="screenerName"
                                required
                                value={values.screenerName}
                                onChange={handleInputChange("screenerName")}
                                fullWidth
                                label="Screener Name"
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DatePicker
                                    required
                                    id="startDate"
                                    label="Start Date"
                                    name="startDate"
                                    value={values.start}
                                    onChange={handleStartDateChange("startDate")}
                                    fullWidth
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <TimePicker
                                    id="startTime"
                                    label="Start Time"
                                    name="startTime"
                                    value={values.start}
                                    onChange={handleStartDateChange("startTime")}
                                    fullWidth
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <TimePicker
                                    id="EndTime"
                                    label="end Time"
                                    name="EndTime"
                                    value={values.end}
                                    fullWidth
                                    onChange={handleEndDateChange("EndTime")}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Select
                                id="timeZone"
                                name="timeZone"
                                placeholder="Select a Timezone"
                                value={values.timeZone}
                                options={timezones}
                                fullWidth
                                onChange={handleChange("timeZone")}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                name="description"
                                multiline
                                fullWidth
                                value={values.description}
                                onChange={handleInputChange("description")}
                                label="Description"
                            />
                        </Grid>
                    </Grid>
                </form>
                <br />
            </React.Fragment>
        );
    }
}

screenEvent.propTypes = {
    logoutUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
    auth: state.auth,
});
export default withStyles(styles)(connect(mapStateToProps, { logoutUser })(screenEvent));
