import React, { useState } from "react";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { Grid } from "@mui/material";

import DeleteMessageIcon from "@mui/icons-material/ErrorOutlineOutlined";

import FrozenMessageSnackbar from "../snackBars/FrozenMessageSnackbar";
import Axios from "axios";
const FreezeModal = ({ openDelete, setOpenDelete, id, action, role }) => {
  const [openSnackBar, setOpenSnackBar] = useState(false);

  let message = "delete";

  if (action === "Active") {
    message = "freeze";
  } else if (action === "Frozen") {
    message = "unfreeze";
  }

  let routerData = "";
  if (action === "Active" || action === "Frozen") {
    routerData = "freeze";
  } else if (action === "delete") {
    routerData = "delete";
  }

  // hiding of the entire modal
  const onHide = () => {
    setOpenDelete(false);
  };

  const newData = {
    id: id,
  };
  const deleteHandler = () => {
    Axios.post(`/api/${role}/${id}/${routerData}`, { newData }).then(
      (data) => {
        setOpenDelete(false);
        setOpenSnackBar(true);
        // window.location.reload();
      }
    );
  };
  return (
    <>
      <Dialog
        open={openDelete}
        onClose={onHide}
        className="experience-edit-dialog"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent className="dialog-content">
          <Grid container spacing={0} justifyContent="center">
            <Grid
              xs={11.5}
              sm={11.5}
              md={11.5}
              lg={11.5}
              className="experience-delete-grid"
            >
              <div className="delete-message-icon">
                <DeleteMessageIcon fontSize="small" />
              </div>
              <div className="delete-dialog-content">
                <h5>Are you sure ?</h5>
                <p>
                  Are you sure you want to {message} the selected {role === 'recruiter' ? 'recruiter' : 'user'}?
                </p>
              </div>
            </Grid>
          </Grid>
        </DialogContent>
        <div className="button-container delete-btn-container">
          <button className="warning-btn-style filled-btn filled-btn-animation" onClick={onHide}>
            {" "}
            Cancel
          </button>
          <button className="warning-btn-style outlined-btn outlined-btn-animation" onClick={deleteHandler}>
            {" "}
            Confirm
          </button>
        </div>
      </Dialog>

      {/* Snackbar displayed when freezing and unfreezing accounts */}
      <FrozenMessageSnackbar
        openErrorSnackbar={openSnackBar}
        setOpenErrorSnackbar={setOpenSnackBar}
        message={
          action === "Active"
            ? "Account freezed successfully"
            : action === "Frozen"
              ? "Account unfreezed successfully"
              : "Account deleted successfully"
        }
      />
    </>
  );
};

export default FreezeModal;
