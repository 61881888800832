import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import CProfileCompletion from "../sidebarComponents/cProfileCompletion";
// import CertificationSidebar from "./sidebarComponents/certification";

function CandidateSidebar(props) {
    const { sidebar } = props;
    // console.log(sidebar);
    return (
        <div className="d-flex flex-column justify-content-between">
            <CProfileCompletion completionPercentage={sidebar.profileCompletionPercentage} />
            {/* <Grid item xs><TrainingSidebar /></Grid> */}
            {/* <div className="flex-fill maxw-250">
                <RatingsSidebar averageRating={sidebar.averageRating} />
            </div> */}
            {/* <div className="flex-fill mt-5 maxw-250"><EarningsSidebar /></div> */}
            {/* <div item xs><CertificationSidebar /></div> */}
        </div>
    );
}

CandidateSidebar.propTypes = {
    auth: PropTypes.object.isRequired,
    sidebar: PropTypes.object,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    sidebar: state.sidebar,
});

export default connect(mapStateToProps)(CandidateSidebar);
