import React, { Fragment, useState, useEffect, Component } from "react";
import Button from "@material-ui/core/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  updateRecruiter,
  getRecruiterDetails,
  UPDATE_PROFILE_RESET,
} from "../../actions/userActions";
import axios from "axios";
import { withSnackbar, useSnackbar } from "notistack";
import dateFormat from "dateformat";

import DateFnsUtils from "@date-io/date-fns";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from "@material-ui/core/TextField";

import {
  DatePicker,
  MuiPickersUtilsProvider,
  TimePicker,
} from "material-ui-pickers";
import PropTypes, { string } from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { makeStyles, Divider } from "@material-ui/core";
import { screeners, candidates, interviews } from "../../actions/userActions";
import { Link } from "react-router-dom";
//material ui

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";

const useStyles = makeStyles((theme) => ({
  main: {
    width: "auto",
    display: "block", // Fix IE 11 issue.
    marginTop: theme.spacing(-2),
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(6))]: {
      width: "50%",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing(-5),
    display: "flex",
    width: "100%",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(
      -15
    )}px`,
  },
  button: {
    padding: theme.spacing(2),
    margin: 10,
    alignItems: "center",
  },

  textField: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    width: "100px",
  },

  textFieldBox: {
    marginTop: theme.spacing() * 1,
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    width: "500px",
  },
  select: {
    marginTop: theme.spacing() * 1,
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    width: "250px",
  },
  formControl: {
    margin: theme.spacing(),
    minWidth: 120,
    maxWidth: 300,
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const InterviewInformation = ({ history, match, props }) => {
  const [interviewList, setInterviewList] = React.useState([]);
  const [screenerList, setScreenerList] = React.useState([]);
  const [candidateList, setCandidateList] = React.useState([]);
  const [open, setOpen] = React.useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const interviewId = match.params.id;
  //handleClickOpen
  const handleClickOpen = () => {
    setOpen(true);
  };
  //handleClose
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    axios.get("/api/event/interviews").then((response) => {
      setInterviewList(response.data);
    });

    axios.get("/api/users/screener").then((response) => {
      setScreenerList(response.data);
    });
    axios.get("/api/users/candidate").then((response) => {
      setCandidateList(response.data);
    });
  }, []);

  // const dispatch = useDispatch();

  // const { candidate } = useSelector((state) => state.candidatelist)
  // const { screener } = useSelector((state) => state.screenerlist)
  // const { interview } = useSelector((state) => state.allInterviews)

  // useEffect(() => {
  //     // if (error) {
  //     //   alert.error(error);
  //     //   dispatch(clearErrors());
  //     // }

  //    dispatch(candidates());
  //    dispatch(screeners());
  //     dispatch(interviews());
  // }, [dispatch, history]);

  var id, cname, sname, title, interviewdate, description, status, stage;

  // interview && interview.forEach(item => {

  //     if(item._id === interviewId){
  //         candidate.data && candidate.data.forEach(candidateList =>{
  //             if(item.candidate === candidateList._id){
  //                 screener.data && screener.data.forEach(screenerList =>{
  //                     if(item.screener === screenerList._id){
  //                         id = item._id;

  //                         cname=candidateList.name;

  //                         sname = screenerList.name;

  //                         title = item.title;

  //                         interviewdate = dateFormat(item.startdate, "mmmm dS, yyyy");
  //                         description = item.description;
  //                         status = item.status;
  //                         stage = item.stage;

  //                     }
  //                 })
  //             }
  //         })
  //     }

  // })

  interviewList &&
    interviewList.forEach((item) => {
      if (item._id === interviewId) {
        console.log(item._id);
        console.log(interviewId);

        screenerList &&
          screenerList.forEach((user) => {
            if (item.screener === user._id) {
              console.log(user.name);
              candidateList &&
                candidateList.forEach((candidate) => {
                  if (item.candidate === candidate._id) {
                    console.log(candidate.name);

                    id = item._id;
                    cname = candidate.name;

                    sname = user.name;

                    title = item.title;

                    interviewdate = item.startdate;
                    description = item.description;
                    status = item.interviewstatus;
                    stage = item.interviewstage;
                  }
                });
            }
          });
      }
    });

  const classes = useStyles();
  return (
    <>
      <br />
      <br />
      <br /> <br />
      <br />
      <br />
      <br />
      <br />
      <div className={classes.main}>
        <Paper className={classes.paper}>
          <br />
          <Typography variant="h6">
            Interview Information
            <Divider />
            <br />
          </Typography>
          <div style={{ width: "100%" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginLeft: "-85%",
              }}
            >
              <Button
                style={{ backgroundColor: "#D73B30", color: "white" }}
                onClick={handleClickOpen}
                variant="contained"
                color="secondary"
                align="center"
                className={classes.submit}
              >
                Back
              </Button>
            </div>
            <Dialog
              open={open}
              onClose={handleClose}
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                  Warning: Are you sure you want to leave this page?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleClose}
                  style={{ backgroundColor: "#D73B30", color: "white" }}
                >
                  Cancel
                </Button>

                <Link
                  style={{ textDecoration: "none", padding: "10px" }}
                  to="/interview-list"
                >
                  <Button
                    style={{ backgroundColor: "#75A478", color: "white" }}
                  >
                    Confirm
                  </Button>
                </Link>
              </DialogActions>
            </Dialog>
          </div>
          <form className={classes.form}>
            <Grid container spacing={24} style={{ padding: "2em" }}>
              <Grid item xs={12} sm={5}>
                <div>
                  <label>Candidate Name</label>
                  <TextField
                    disabled
                    id="standard-disabled"
                    value={cname}
                    variant="standard"
                    fullWidth
                  />
                </div>
              </Grid>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Grid item xs={12} sm={5}>
                <div>
                  <label>Screener Name</label>
                  <TextField
                    disabled
                    id="standard-disabled"
                    value={sname}
                    variant="standard"
                    fullWidth
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={5}>
                <div>
                  <label>Interview Title</label>
                  <TextField
                    disabled
                    id="standard-disabled"
                    value={title}
                    variant="standard"
                    fullWidth
                  />
                </div>
              </Grid>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Grid item xs={12} sm={5}>
                <div>
                  <label>Interview Date</label>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      required
                      id="dob"
                      name="dob"
                      value={interviewdate}
                      format="dd/MM/yyyy"
                      disabled
                      fullWidth
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </Grid>
              <Grid item xs={12} sm={5}>
                <div>
                  <label>Description</label>
                  <TextField
                    disabled
                    id="standard-disabled"
                    value={description}
                    variant="standard"
                    fullWidth
                  />
                </div>
              </Grid>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Grid item xs={12} sm={5}>
                <div>
                  <label>Interview Status</label>
                  <TextField
                    disabled
                    id="standard-disabled"
                    value={status}
                    variant="standard"
                    fullWidth
                  />
                </div>
              </Grid>
              <br />
              <Grid item xs={12} sm={5}>
                <div>
                  <label>Interview Stage</label>
                  <TextField
                    disabled
                    id="standard-disabled"
                    value={stage}
                    variant="standard"
                    fullWidth
                  />
                </div>
              </Grid>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {/* <Grid item xs={12} sm={5}>
                                <div>
                                <label>Years Of Experience</label>
                                <TextField
                                    disabled
                                    id="standard-disabled"
                                   // value={yearsOfExperience}
                                    variant="standard"
                                    fullWidth
                                />
                                </div>
                            

                            </Grid>
                            <Grid item xs={12} sm={5}>
                                <div>
                                <label>Current Title</label>
                                <TextField
                                    disabled
                                    id="standard-disabled"
                                   // value={currentitle}
                                    variant="standard"
                                    fullWidth
                                />
                                </div>
                            

                            </Grid>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
              {/* <Grid item xs={12} sm={5}>
                                <div>
                                <label>Preferred position</label>
                                <TextField
                                    disabled
                                    id="standard-disabled"
                                   // value={preferredPosition}
                                    variant="standard"
                                    fullWidth
                                />
                                </div>      
                            </Grid>

                            <Grid item xs={12} sm={5}>
                                <div>
                                <label>Highest Degree</label>
                                <TextField
                                    disabled
                                    id="standard-disabled"
                                   // value={highestDegree}
                                    variant="standard"
                                    fullWidth
                                />
                                </div>
                            

                            </Grid>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <Grid item xs={12} sm={5}>
                                <div>
                                <label>Major</label>
                                <TextField
                                    disabled
                                    id="standard-disabled"
                                  //  value={major}
                                    variant="standard"
                                    fullWidth
                                />
                                </div>      
                            </Grid>
                            <Grid item xs={12} sm={5}>
                                <div>
                                <label>English Proficiency</label>
                                <TextField
                                    disabled
                                    id="standard-disabled"
                                 //   value={englishProficiency}
                                    variant="standard"
                                    fullWidth
                                />
                                </div>
                                </Grid>
                            
                                <br />
                                <Grid item xs={12} sm={8}>
                                    <br/><br/>
                    <Typography variant ="subtitle1"  style= {{color:"gray"}} >Contact Details<Divider /><br/></Typography></Grid>
                            
                            <Grid item xs={12} sm={5}>
                                <div>
                                <label>Address Line1</label>
                                <TextField
                                    disabled
                                    id="standard-disabled"
                                  //  value={address_line1}
                                    variant="standard"
                                    fullWidth
                                />
                                </div>      
                            </Grid>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <Grid item xs={12} sm={5}>
                                <div>
                                <label>Address Line2</label>
                                <TextField
                                    disabled
                                    id="standard-disabled"
                                   // value={address_line2}
                                    variant="standard"
                                    fullWidth
                                />
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={5}>
                                <div>
                                <label>City</label>
                                <TextField
                                    disabled
                                    id="standard-disabled"
                                  //  value={city}
                                    variant="standard"
                                    fullWidth
                                />
                                </div>      
                            </Grid>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <Grid item xs={12} sm={5}>
                                <div>
                                <label>State</label>
                                <TextField
                                    disabled
                                    id="standard-disabled"
                                  //  value={state}
                                    variant="standard"
                                    fullWidth
                                />
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={5}>
                                <div>
                                <label>Country</label>
                                <TextField
                                    disabled
                                    id="standard-disabled"
                                 //   value={country}
                                    variant="standard"
                                    fullWidth
                                />
                                </div>      
                            </Grid>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <Grid item xs={12} sm={5}>
                                <div>
                                <label>Postal Code</label>
                                <TextField
                                    disabled
                                    id="standard-disabled"
                                  //  value={postalCode}
                                    variant="standard"
                                    fullWidth
                                />
                                </div>
                            </Grid> */}
            </Grid>
          </form>
        </Paper>
      </div>
    </>
  );
};

export default InterviewInformation;
