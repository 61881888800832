import Grid from "@material-ui/core/Grid";
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { logoutUser } from "../../../actions/authActions";
import CandidateRating from "../../common/ratings/candidateRatings";

const styles = (theme) => ({
    main: {
        width: "auto",
        display: "block", // Fix IE 11 issue.
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        [theme.breakpoints.up(400 + theme.spacing(6))]: {
            width: "50%",
            marginLeft: "auto",
            marginRight: "auto",
        },
    },
    iconButton: {
        padding: "17px",
    },
    form: {
        margin: theme.spacing(),
        minWidth: "50%",
        marginTop: theme.spacing(),
    },
    textField: {
        marginTop: theme.spacing(5),
        marginLeft: theme.spacing(),
        marginRight: theme.spacing(),
        width: "500px",
    },
});
class projectExperience extends Component {
    render() {
        const { values, handleChange, onRatingChange, classes } = this.props;
        return (
            <div>
                <main className={classes.main}>
                    <form className={classes.form} noValidate onSubmit={this.onSubmit}>
                        <Grid container spacing={10}>
                            <br />
                            <Grid item xs={12}>
                                <Typography color="textSecondary" variant="h6" gutterBottom>
                                    Project Experience Rating
                                </Typography>
                            </Grid>

                            {/* <Select
                                id="projectExperience"
                                name="projectExperience"
                                value={values.projectExperience}
                                onChange={handleChange("projectExperience")}
                                label="projectExperience"
                                fullWidth
                                displayEmpty
                                autoComplete="projectExperience"
                            >
                                <MenuItem value="">
                                    <em>select the value</em>
                                </MenuItem>
                                <MenuItem value={1}>1 - Unsatisfactory</MenuItem>
                                <MenuItem value={2}>2 - Below Average</MenuItem>
                                <MenuItem value={3}>3 - Average</MenuItem>
                                <MenuItem value={4}>4 -Above Average</MenuItem>
                                <MenuItem value={5}>5 - Exceptional</MenuItem>
                            </Select> */}

                            <CandidateRating
                                id="projectExperience"
                                name="Project Experience"
                                input="projectExperience"
                                rating={values.projectExperience}
                                onRatingChange={onRatingChange}
                            />

                            <TextField
                                id="commentProject"
                                name="commentProject"
                                value={values.commentProject}
                                onChange={handleChange("commentProject")}
                                required={true}
                                label="Reason of Rating"
                                className={classes.textField}
                                fullWidth
                                margin="normal"
                                variant="outlined"
                                autoComplete="comment"                            

                            />
                        </Grid>
                    </form>
                </main>
            </div>
        );
    }
}

projectExperience.propTypes = {
    logoutUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
    auth: state.auth,
});
export default withStyles(styles)(connect(mapStateToProps, { logoutUser })(projectExperience));
