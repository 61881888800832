export const cardStyles = {
    overflow: "auto",
    margin: "10px",
};

export const calendarCardStyle = {
    overflow: "auto",
    margin: "10px",
    height: "600px",
};

export const calendarStandardStyle = {
    height: "500px",
    overflow: "auto",
    margin: "4px",
    padding: "4px",
};

export const smallCardStyle = {
    maxHeight: "200px",
    height: "150px",
    overflow: "auto",
};

export const availabilityCalendarStyle = { height: "500px", width: "700px" };
