import React from "react";
import { Link, animateScroll as scroll } from "react-scroll";
import "./landing.css";

//Carousel
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

const Hero = () => {
  return (
    <header id="home" class="header">
      <div class="overlay"></div>

      <div
        id="header-carousel"
        class="carousel slide carousel-fade"
        data-ride="carousel"
      >
        <div class="container  hero-container">
          <div class="carousel-inner">
            <Carousel
              autoPlay={true}
              infiniteLoop={true}
              interval={4000}
              swipeable={true}
              showStatus={false}
              showArrows={false}
              showIndicators={false}
            >
              <div class="carousel-item active">
                <div class="carousel-caption d-none d-md-block">
                  <h1 class="carousel-title">
                    We Make
                    <br />
                    Recruitment Simple.
                  </h1>
                  <Link to="about" smooth={true}>
                    <button class="btn btn-primary btn-rounded">
                      <a class="anchor" style={{ color: "white" }}>
                        Learn More
                      </a>
                    </button>
                  </Link>
                </div>
              </div>
              <div class="carousel-item active">
                <div class="carousel-caption d-none d-md-block">
                  <h1 class="carousel-title">
                    Re-Discover <br />
                    Recruiting.
                  </h1>
                  <Link to="about" smooth={true}>
                    <button class="btn btn-primary btn-rounded">
                      <a class="anchor" style={{ color: "white" }}>
                        Learn More
                      </a>
                    </button>
                  </Link>
                </div>
              </div>
            </Carousel>
          </div>
        </div>
      </div>

      <div class="infos container mb-4 mb-md-2">
        <div class="title">
          <h6 class="subtitle font-weight-normal">Developed By</h6>
          <h5>Infomatics Corp</h5>
          <p class="font-small">
            23465 Rock Haven Way, Suite 100  Dulles, VA 20166
          </p>
        </div>

      </div>


    </header>
  )
}

export default Hero