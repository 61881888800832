import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import "bootstrap/dist/css/bootstrap.min.css";
import { SnackbarProvider } from "notistack";
import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import theme from "../theme";
import App from "./App";
import {ConfirmProvider} from "material-ui-confirm"
// BrowserRouter forceRefresh is set to true because
// on logout the navbar will dynamically dissappear
// force refresh makes it look more clean
export default ({ store }) => {
    const notistackRef = React.createRef();

    const onClickDismiss = (key) => () => {
        notistackRef.current.closeSnackbar(key);
    };

    return (
        <MuiThemeProvider theme={theme}>
            <SnackbarProvider
                maxSnack={3}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                preventDuplicate
                ref={notistackRef}
                action={(key) => <Button onClick={onClickDismiss(key)}>Dismiss</Button>}
            >
            <ConfirmProvider>
                <Provider store={store}>
                    <CssBaseline />
                    <BrowserRouter forceRefresh={true}>
                        <App />
                    </BrowserRouter>
                </Provider>
            </ConfirmProvider>
            </SnackbarProvider>
        </MuiThemeProvider>
    );
};
