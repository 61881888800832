import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import React, { Component } from "react";

const styles = (theme) => ({
    main: {
        width: "80%",
        marginLeft: "50px",
        display: "block", // Fix IE 11 issue.
        marginTop: "20px",
    },
    paper: {
        marginTop: theme.spacing(2),
        display: "flex",
        width: "100%",
        flexDirection: "column",
        alignItems: "center",
        padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
    },
    label: {
        width: "103%",
        marginLeft: "36px",
        [theme.breakpoints.up(400)]: {
            width: "110%",
        },
    },
    textField: {},
    formControl: {
        marginTop: "-11px",
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    AddSkillButton: {
        padding: theme.spacing(2),
        margin: 10,
        color: "#75A478",
    },
    deleteSkillButton: {
        padding: "10px",
        marginLeft: "50px",
        backgroundColor: "#75A478",
        color: "#FFFFFF",
    },
    skillContainer: {
        marginTop: "16px",
    },
    topSkillContainer: {
        marginLeft: "20px",
    },
    skillArea: {
        width: "250px",
        marginRight: "200px",
    },
});

//all skill choices
const suggestions = [
    "Java",
    "Spring Boot",
    "Python",
    "C",
    "C++",
    "HTML",
    "CSS",
    "JavaScript",
    "SQL",
    "MongoDB",
    "Ruby",
    "XML",
    "Amazon Web Sevices",
    "Microsoft Azure",
    "Hadoop",
    "Pega",
    "React JS",
    "React Native",
    "Angular",
    "Android",
    "IOS",
    "Node Js",
];

class skillSet extends Component {
    constructor(props) {
        super(props);

        this.state = {
            addSkills: this.props.values.skillSet,
            skills: this.props.values.skills,
        };

        this.handleSkillChange = this.handleSkillChange.bind(this);
        this.addSkillField = this.addSkillField.bind(this);
        this.deleteSkillField = this.deleteSkillField.bind(this);
    }

    // handles both skill and experiance change
    handleSkillChange = (idx, key) => (event) => {
        if (event.target) {
            event.preventDefault();
        }
        const { addSkills, skills } = this.state;
        var i = 0;
        var j = 1;

        switch (key) {
            case "skill":
                addSkills[idx][i] = event.target.value;
                if (!skills.includes(event.target.value)) {
                    skills.push(event.target.value);
                }
                break;
            case "skillExp":
                addSkills[idx][j] = event.target.value;
                break;
            default:
                break;
        }

        this.setState({
            addSkills,
            skills,
        });
    };

    // add a new field
    addSkillField(e) {
        e.preventDefault();
        const { addSkills } = this.state;
        addSkills[addSkills.length] = ["", ""];
        this.setState({ addSkills });
    }

    // delete chosen field
    deleteSkillField(idx) {
        return (e) => {
            e.preventDefault();
            const { addSkills, skills } = this.state;
            var removedSkill = addSkills.splice(idx, 1)[0];
            const skillsIdx = skills.indexOf(removedSkill);

            this.setState(
                {
                    addSkills,
                },
                function () {
                    skills.splice(skillsIdx, 1);
                    this.setState({
                        skills,
                    });
                }
            );
        };
    }

    render() {
        const { classes } = this.props;
        const { addSkills } = this.state;
        return (
            <>
                <main className={classes.main}>
                    <Grid container spacing={10} direction="column" alignItems="center" justify="center">
                        <Typography variant="subtitle2" gutterBottom className={classes.label}>
                            What are the major skills you are comfortable in Interviewing people?
                        </Typography>
                        <Grid item xs={12} className={classes.topSkillContainer}>
                            {addSkills.map((skill, idx) => (
                                <Grid
                                    container
                                    direction="row"
                                    justify="space-around"
                                    alignItems="center"
                                    className={classes.skillContainer}
                                    key={`skillContainer-` + idx}
                                >
                                    <Grid item xs={3} sm={3} className="mr-3">

                                        <Select
                                            required
                                            id={`skill-` + idx}
                                            name={`skill-` + idx}
                                            value={skill[0]}
                                            onChange={this.handleSkillChange(idx, "skill")}
                                            label="Skills"
                                            className={classes.skillArea}
                                            fullWidth
                                            autoComplete="on"
                                        >
                                            {suggestions.map((skillLabel, idx) => (
                                                <MenuItem key={skillLabel + idx} value={skillLabel}>
                                                    {skillLabel}
                                                </MenuItem>
                                            ))}
                                        </Select>

                                    </Grid>

                                    <Grid item xs={3} sm={3} className="ml-4">

                                            <InputLabel htmlFor="skillsExp">Experience (Years)</InputLabel>
                                            <Select
                                                id={`skillExp- ` + idx}
                                                name={`skillExp- ` + idx}
                                                value={skill[1]}
                                                className={classes.skillArea}
                                                onChange={this.handleSkillChange(idx, "skillExp")}
                                                label="SkillsExp"
                                                fullWidth
                                                autoComplete="on"
                                            >
                                                <MenuItem value="0-2">0-2</MenuItem>
                                                <MenuItem value="3-5">3-5</MenuItem>
                                                <MenuItem value="6-10">6-10</MenuItem>
                                                <MenuItem value="greater than 10">10 and above</MenuItem>
                                            </Select>

                                    </Grid>

                                    <Grid item xs={3} sm={3}>

                                            <Button onClick={this.deleteSkillField(idx)} className={classes.deleteSkillButton}>
                                                <span className={classes.deleteSpan}>X</span>
                                            </Button>

                                    </Grid>
                                </Grid>
                            ))}
                        </Grid>

                            <Button onClick={this.addSkillField} className={classes.AddSkillButton}>
                                Add More Skills!
                            </Button>

                    </Grid>
                </main>
            </>
        );
    }
}
export default withStyles(styles)(skillSet);
