import { MenuItem } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Menu from "@material-ui/core/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import AccountBox from "@material-ui/icons/AccountBox";
import Home from "@material-ui/icons/Home";
import MenuIcon from "@material-ui/icons/Menu";

import PropTypes from "prop-types";
import React, { Component } from "react";

import Logo from "../../images/logo.png";
import "./Navbar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Profile from "@mui/icons-material/AccountCircle";
import Screener from "@mui/icons-material/Groups";
import Reviews from "@mui/icons-material/Reviews";
import Feedback from "@mui/icons-material/Feedback";
import Logout from "@mui/icons-material/PowerSettingsNew";
//import Handshake from '@mui/icons-material/Handshake';
// import Calendar from '@mui/icons-material/CalendarMonth';
//import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { faHandshake, faUser } from "@fortawesome/free-solid-svg-icons";
import Calendar from "@material-ui/icons/Event";
import QuizIcon from "@mui/icons-material/Quiz";

class Navbar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      left: false,
      anchorEl: null,
    };
  }

  handleMenu = (event) => {
    this.setState({
      anchorEl: event.currentTarget,
    });
  };

  handleProfileClick = () => {
    let user = this.props.auth.user;
    let route = "/Profile";
    // if (user.role === "candidate") {
    //   route = "/Candidate-Profile";
    // } else if (user.role === "screener") {
    //   route = "/Screener-Profile";
    // }
    return route;
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
    });
  };

  toggleDrawer = (side, open) => () => {
    this.setState({
      [side]: open,
    });
  };

  onLogoutClick = (e) => {
    this.props.logoutUser();
  };

  render() {
    const { classes, location } = this.props;
    const { user } = this.props.auth;
    const openMenu = Boolean(this.state.anchorEl);

    // this allows for Navbar persistance in the pathways that we want, and
    // excluding the routes defined below.
    const nonNavRoutes = [
      "/login",
      "/register",
      "/company-registration",
      "/",
      "/register-wizard",
    ];
    let navClassName = nonNavRoutes.includes(location)
      ? "invisible"
      : `${classes.root} visible`;

    // These are the configs for the hamburger menu options
    // const sidelist_recruiter = (
    //   <div className={classes.list}>
    //     <List>
    //       {[
    //         ["/home", "Home"],
    //         ["/Recruiter-Profile", "Profile"],
    //         ["/Schedule", "Schedule"]
    //       ].map(([route, text], index) => (
    //         <ListItem button key={text} component="a" href={route}>
    //           <ListItemIcon>
    //             {index % 2 === 0 ? <Home /> : <AccountBox />}
    //           </ListItemIcon>
    //           <ListItemText primary={text} />
    //         </ListItem>
    //       ))}
    //     </List>
    //     <Divider />

    //     <List>
    //       {[
    //         ["/Google-Hire", "Google Hire"],
    //         ["/screener-list", "Screeners"],
    //         ["/View-Availability", "ViewAvailability"],
    //         ["/Assessment", "Assessment"],
    //         ["/Schedule-new", "Schedule New"],
    //         ["/question", "Question Bank"]
    //       ].map(([route, text], index) => (
    //         <ListItem button key={text} component="a" href={route}>
    //           <ListItemIcon>
    //             {index % 2 === 0 ? <Home /> : <AccountBox />}
    //           </ListItemIcon>
    //           <ListItemText primary={text} />
    //         </ListItem>
    //       ))}
    //     </List>
    //   </div>
    // );
    // const sidelist_screener = (
    //   <div className={classes.list}>
    //     <List>
    //       {[
    //         ["/home", "Home"],
    //         ["/Screener-Profile", "Profile"],
    //         ["/availability-screener", "Availability"]
    //       ].map(([route, text], index) => (
    //         <ListItem button key={text} component="a" href={route}>
    //           <ListItemIcon>
    //             {index % 2 === 0 ? <Home /> : <AccountBox />}
    //           </ListItemIcon>
    //           <ListItemText primary={text} />
    //         </ListItem>
    //       ))}
    //     </List>
    //     <Divider />

    //     <List>
    //       {[
    //         ["/Google-Hire", "Google Hire"],
    //         ["/Assessment-Landing", "Assessment"],
    //         ["/question", "Question Bank"]
    //       ].map(([route, text], index) => (
    //         <ListItem button key={text} component="a" href={route}>
    //           <ListItemIcon>
    //             {index % 2 === 0 ? <Home /> : <AccountBox />}
    //           </ListItemIcon>
    //           <ListItemText primary={text} />
    //         </ListItem>
    //       ))}
    //     </List>
    //   </div>
    // );

    const sidelist_recruiter = (
      <div className={classes.list}>
        <List>
          {[
            ["/home", "Home", <Home style={{ color: "black" }} />],
            ["/Profile", "Profile", <Profile style={{ color: "black" }} />],
            // ["/Schedule", "Schedule"]
          ].map(([route, text, icon], index) => (
            <ListItem button key={text} component="a" href={route}>
              <ListItemIcon>
                {/* {index % 2 === 0 ? <Home /> : <AccountBox />} */}
                {/* {icon} &nbsp; */}
                {icon} &nbsp;
                <Typography mt={2} style={{ color: "black" }}>
                  {text}
                </Typography>
              </ListItemIcon>
              {/* <ListItemText primary={text} /> */}
            </ListItem>
          ))}
        </List>
        <Divider />
        <List>
          {[
            [
              "/interview-list",
              "Interviews",
              <QuizIcon style={{ color: "black" }} />,
            ],
            [
              "/screener-list",
              "Screeners",
              <Screener style={{ color: "black" }} />,
            ],
            [
              "/candidate-list",
              "Candidates",
              <Screener style={{ color: "black" }} />,
            ],
            ["/review-list", "Reviews", <Reviews style={{ color: "black" }} />],
            [
              "/candidate-feedback",
              "Feedback",
              <Feedback style={{ color: "black" }} />,
            ],
            // ["/Assessment", "Assessment"],
            // ["/Schedule-new", "Schedule New"],
            // ["/question", "Question Bank"]
          ].map(([route, text, icon], index) => (
            <ListItem button key={text} component="a" href={route}>
              <ListItemIcon>
                {/* {index % 2 === 0 ? <Home /> : <AccountBox />} */}
                {icon} &nbsp;
                <Typography mt={2} style={{ color: "black" }}>
                  {text}
                </Typography>
              </ListItemIcon>
              {/* <ListItemText primary={text} /> */}
            </ListItem>
          ))}
        </List>
      </div>
    );
    const sidelist_screener = (
      <div className={classes.list}>
        <List>
          {[
            ["/home", "Home", <Home style={{ color: "black" }} />],
            ["/Profile", "Profile", <Profile style={{ color: "black" }} />],
            // ["/availability-screener", "Availability"]
          ].map(([route, text, icon], index) => (
            <ListItem button key={text} component="a" href={route}>
              <ListItemIcon>
                {/* {icon} &nbsp; */}
                {icon} &nbsp;
                <Typography mt={2} style={{ color: "black" }}>
                  {text}
                </Typography>
              </ListItemIcon>
              {/* <ListItemText primary={text} /> */}
            </ListItem>
          ))}
        </List>
        <Divider />
        <List>
          {[
            ["/calendar", "Calendar", <Calendar style={{ color: "black" }} />],
            [
              "/screener-interview-list",
              "Interviews",
              <QuizIcon style={{ color: "black" }} />,
            ],
            [
              "/screener-review-list",
              "Reviews",
              <Reviews style={{ color: "black" }} />,
            ],
            // ["/question", "Question Bank"]
          ].map(([route, text, icon], index) => (
            <ListItem button key={text} component="a" href={route}>
              <ListItemIcon>
                {/* {index % 2 === 0 ? <Home /> : <AccountBox />} */}
                {icon} &nbsp;
                <Typography mt={2} style={{ color: "black" }}>
                  {text}
                </Typography>
              </ListItemIcon>
              {/* <ListItemText primary={text} /> */}
            </ListItem>
          ))}
        </List>
      </div>
    );

    const sidelist_candidate = (
      <div className={classes.list}>
        <List>
          {[
            ["/home", "Home", <Home style={{ color: "black" }} />],
            ["/Profile", "Profile", <Profile style={{ color: "black" }} />],
            // ["/availability-screener", "Availability"]
          ].map(([route, text, icon], index) => (
            <ListItem button key={text} component="a" href={route}>
              <ListItemIcon>
                {icon} &nbsp;
                <Typography mt={2} style={{ color: "black" }}>
                  {text}
                </Typography>
              </ListItemIcon>
              {/* <ListItemText primary={text} /> */}
            </ListItem>
          ))}
        </List>
        <Divider />
        <List>
          {[
            //  ["/calendar", "Calendar"],
            [
              "/candidate-interview-list",
              "Interviews",
              <QuizIcon style={{ color: "black" }} />,
            ],
            // ["/screener-review-list", "Reviews"],
            // ["/question", "Question Bank"]
          ].map(([route, text, icon], index) => (
            <ListItem button key={text} component="a" href={route}>
              <ListItemIcon>
                {/* {index % 2 === 0 ? <Home /> : <AccountBox />} */}
                {icon} &nbsp;
                <Typography mt={2} style={{ color: "black" }}>
                  {text}
                </Typography>
              </ListItemIcon>
              {/* <ListItemText primary={text} /> */}
            </ListItem>
          ))}
        </List>
      </div>
    );

    const sidelist_admin = (
      <div className={classes.list}>
        <List>
          {[
            ["/home", "Home", <Home style={{ color: "black" }} />],
            ["/Profile", "Profile", <Profile style={{ color: "black" }} />],
            // ["/availability-screener", "Availability"]
          ].map(([route, text, icon], index) => (
            <ListItem button key={text} component="a" href={route}>
              <ListItemIcon>
                {/* {icon} &nbsp; */}
                {icon} &nbsp;
                <Typography mt={2} style={{ color: "black" }}>
                  {text}
                </Typography>
              </ListItemIcon>
              {/* <ListItemText primary={text} /> */}
            </ListItem>
          ))}
        </List>
        <Divider />
        <List>
          {[
            [
              "/admin/recruiter-list",
              "Recruiters",
              <Screener style={{ color: "black" }} />,
            ],
            [
              "/candidate-list",
              "Candidates",
              <Screener style={{ color: "black" }} />,
            ],
            [
              "/screener-list",
              "Screeners",
              <Screener style={{ color: "black" }} />,
            ],
            [
              "/admin/screener-review-list",
              "Screener Reviews",
              <Reviews style={{ color: "black" }} />,
            ],
            // ["/question", "Question Bank"]
          ].map(([route, text, icon], index) => (
            <ListItem button key={text} component="a" href={route}>
              <ListItemIcon>
                {/* {index % 2 === 0 ? <Home /> : <AccountBox />} */}
                {icon} &nbsp;
                <Typography mt={2} style={{ color: "black" }}>
                  {text}
                </Typography>
              </ListItemIcon>
              {/* <ListItemText primary={text} /> */}
            </ListItem>
          ))}
        </List>
      </div>
    );

    const sidelist_empty = (
      <div className={classes.list}>
        <List />
      </div>
    );

    return (
      <div className={navClassName}>
        <AppBar position="fixed" color="inherit">
          <Toolbar>
            <div>
              <IconButton
                onClick={this.toggleDrawer("left", true)}
                className={classes.menuButton}
                color="inherit"
                aria-label="Menu"
              >
                <MenuIcon />
              </IconButton>
              <Drawer
                open={this.state.left}
                onClose={this.toggleDrawer("left", false)}
              >
                <div
                  tabIndex={0}
                  role="button"
                  onClick={this.toggleDrawer("left", false)}
                  onKeyDown={this.toggleDrawer("left", false)}
                >
                  {(() => {
                    if (user.role === "recruiter") {
                      return sidelist_recruiter;
                    } else if (user.role === "screener") {
                      return sidelist_screener;
                    } else if (user.role === "candidate") {
                      return sidelist_candidate;
                    } else if (user.role === "admin") {
                      return sidelist_admin;
                    } else {
                      return sidelist_empty;
                    }
                  })()}
                </div>
              </Drawer>
            </div>
            <img className={classes.logo} src={Logo} alt="Screener Logo" />
            <div style={{ position: "absolute", top: "15px", right: "100px" }}>
              <Button color="inherit" onClick={this.handleMenu}>
                {user.name ? user.name : " "} &nbsp;&nbsp;{" "}
                <FontAwesomeIcon icon={faUser} />
              </Button>
              <Menu
                id="menu-appbar"
                anchorEl={this.state.anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                style={{ marginTop: "40px" }}
                open={openMenu}
                onClose={this.handleClose}
              >
                <MenuItem
                  component="a"
                  href={this.handleProfileClick()}
                  style={{ color: "black" }}
                >
                  <Profile style={{ color: "black" }} /> &nbsp; Profile
                </MenuItem>
                <MenuItem onClick={this.onLogoutClick}>
                  <Logout style={{ color: "black" }} /> &nbsp; Logout
                </MenuItem>
              </Menu>
            </div>
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}
Navbar.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default Navbar;
