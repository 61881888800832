import { withStyles } from "@material-ui/core/styles";
// import { connect } from "react-redux";
import Footer from "./Footer"

const styles = () => ({
    root: {
        width: "100%",
        background: "#75A478 0% 0% no-repeat padding-box",
        opacity: "1",
        position: "fixed",
         bottom: "0",
           width: "100%",
           height: "20px",
        display: "flex",
        
    },
    white: {
        textalign: "center",
        font: "Regular 14px/19px Nunito",
        letterspacing: "0px",
        color: "#FFFFFF",
        opacity: "1",
    },
   
              
});

export default withStyles(styles)(Footer);