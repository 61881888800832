import { faCommentsDollar } from '@fortawesome/free-solid-svg-icons';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
// import PropTypes from "prop-types";
import React from 'react';
// import { connect } from "react-redux";
// import { logoutUser } from "../../../../actions/authActions";
import { useEffect } from 'react';

const styles = makeStyles((theme) => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginTop: theme.spacing(5),
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(6))]: {
      width: '50%',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  textField: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    width: 'auto',
    maxWidth: 200,
  },
}));

export default function payment(props) {
  const classes = styles();
  const { values, handleChange } = props;

  const isLetterAndNum = (e) =>{
    const re= /[a-zA-Z0-9]$/;
    if(!re.test(e.key)){
      e.preventDefault();
    }
  }

  return (
    <main className={classes.main}>
      <Grid container spacing={24}>
        <Grid item xs={12}>
          <Typography variant="subtitle2">Paypal Id</Typography>
          <TextField
            id="payPal_id"
            name="payPal_id"
            label="Paypal Id"
            margin="normal"
            variant="outlined"
            value={values.payPal_id}
            onChange={handleChange('payPal_id')}
            onKeyPress={(e)=>isLetterAndNum(e)}
            fullWidth
            inputProps={{
              maxLength: 17
            }}
            autoComplete="paypal Id"
            
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="subtitle2">Paypal First Name</Typography>
          <TextField
            id="paypalFirstName"
            name="paypalFirstName"
            label="First Name"
            margin="normal"
            variant="outlined"
            value={values.payPal_firstName}
            onChange={handleChange('payPal_firstName')}
            fullWidth
            autoComplete="First Name"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="subtitle2">Paypal Last Name</Typography>
          <TextField
            id="paypalLastName"
            name="paypalLastName"
            label="Last Name"
            margin="normal"
            variant="outlined"
            value={values.payPal_lastName}
            onChange={handleChange('payPal_lastName')}
            fullWidth
            autoComplete="Last Name"
          />
        </Grid>
        <br />
        <Typography color="textSecondary" variant="caption">
          I understand its my responsibility to review the terms and conditions set forth by PayPal.
        </Typography>
      </Grid>
    </main>
  );
}
