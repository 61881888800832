import React, { useState, useRef } from "react"

// MUI imports
import { Box, Card, Checkbox, Grid } from "@mui/material"
import { FormGroup, FormControlLabel } from "@mui/material"
import { Dialog, DialogContent } from "@mui/material";
import { Autocomplete, TextField, Tooltip } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import DownloadIcon from "@mui/icons-material/Download";
import DeleteMessageIcon from "@mui/icons-material/ErrorOutlineOutlined";
import CircularProgress from '@mui/material/CircularProgress';

import Skills from "../../../modals/skills";
import axios from "axios";
import PhoneInput from "react-phone-input-2";
import { getPhonesRegex } from "../../../auth/PhoneValidation";
import UserSnackbar from "../../../snackBars/UserSnackbar";


const currentYear = new Date().getFullYear()

const initialState = {
    firstName: "",
    lastName: "",
    email: "",
    profileTitle: "",
    phone1: "",
    phone2: "",
    skills: [],
    salary: "",
    payType: "",
    checked: false,
    dob: "",
    gender: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    stateId: "",
    country: "",
    countryId: "",
    totalExperience: "",
    calculatedExp: "",
    nationality: "",
    postalCode: "",
    educationDetails: [
        {
            school: "",
            degree: "",
            stream: "",
            percentage: "",
            startMonth: "-1",
            startYear: currentYear,
            endMonth: "-1",
            endYear: currentYear,
            checked: false,
            currentWorking: false,
        },
    ],
    linkedin: "",
    github: "",
    portfolio: "",
    experienceDetails: [{
        checked: false,
        currentWorking: false,
        company: "",
        position: "",
        endMonth: "-1",
        endYear: currentYear,
        startMonth: "-1",
        startYear: currentYear
    }],
    resume: null,
    resumeFormat: "",
    zoom: 1,
    interacted: false,
    croppedAreaPixels: {
        width: 0,
        height: 0,
        x: 0,
        y: 0,
    },
    imagePublicId: "",
    resumePublicId: "",
    croppedImagePublicId: "",
}

//months for dropdown
const months = [
    { value: "-1", label: "Month" },
    { value: "00", label: "January" },
    { value: "01", label: "February" },
    { value: "02", label: "March" },
    { value: "03", label: "April" },
    { value: "04", label: "May" },
    { value: "05", label: "June" },
    { value: "06", label: "July" },
    { value: "07", label: "August" },
    { value: "08", label: "September" },
    { value: "09", label: "October" },
    { value: "10", label: "November" },
    { value: "11", label: "December" },
];



//years for dropdown
const years = [];
for (let i = 0; i <= 50; i++) {
    const year = currentYear - i;
    years.push(<option value={year}>{year}</option>);
}


function ParseResume({ setResumeParseClicked, setTableView }) {

    const role = localStorage.getItem("role")

    const phones = getPhonesRegex()
    const allSkillsList = Skills()

    const resumeRef = useRef(null)
    const phoneRef = useRef(null)
    //useRef for mandatory input fields
    const firstNameRef = useRef(null);
    const emailRef = useRef(null)

    const [userData, setUserData] = useState(JSON.parse(JSON.stringify(initialState)));
    const [resumeName, setResumeName] = useState("")
    const [skill, setSkill] = useState("")
    const [errors, setErrors] = useState({
        resume: "",
        resumeSize: "",
        resumeType: "",
        phone: "",
        firstName: "",
        email: "",
        skills: "",
        startDate: "",
        endDate: ""
    })

    let fullExperience = "";

    const [parseLoader, setParseLoader] = useState(false)
    const [removeResumeDialog, setRemoveResumeDialog] = useState(false)
    const [resumePreviewDialog, setOpenResumePreviewDialog] = useState(false)
    const [phoneIsValid, setPhoneIsValid] = useState(true)
    const [openSnackbar, setOpenSnackbar] = useState(false)
    const [message, setMessage] = useState("")
    const [disableSave, setDisableSave] = useState(false);

    const getTotalExperience = (startDate, endDate) => {
        let yearDifference, monthDifference, remainingMonths;
        let totalMonths = 0;
        let totalYears = 0;

        let startMonth = startDate.getMonth();
        let endMonth = endDate.getMonth() + 1;
        yearDifference = endDate.getFullYear() - startDate.getFullYear();
        monthDifference = startMonth - endMonth;

        totalMonths = yearDifference * 12 - monthDifference;

        totalYears = Math.floor(totalMonths / 12);
        remainingMonths = totalMonths % 12;
        const final = remainingMonths
            ? totalYears
                ? `${totalYears}` +
                (totalYears === 1 ? " year " : " years ") +
                `${remainingMonths}` +
                (remainingMonths === 1 ? " month" : " months")
                : `${remainingMonths} ` +
                (remainingMonths === 1 ? " month " : " months ")
            : `${totalYears} ` + (totalYears === 1 ? " year " : " years ");

        return final;
    };

    // Function to scroll to the target element
    const scrollToElement = (ref) => {
        if (ref.current) {
            ref.current.scrollIntoView({
                behavior: 'smooth', // You can change this to 'auto' for instant scrolling
                block: 'center', // You can change this to 'end' or 'center' to adjust where the element appears on the screen
            });
        }
    };

    function handleSaveProfile() {

        //check for mandatory fields
        const { firstName, email, resume, phone1, skills, experienceDetails } = userData
        const { startYear, startMonth, endMonth, endYear, checked } = experienceDetails[0]
        if (!resume) return setErrors({ resume: "Please upload a resume" })
        if (!firstName) {
            scrollToElement(firstNameRef)
            return setErrors({
                firstName: "Please enter first name"
            })
        }

        if (!email) {
            scrollToElement(emailRef)
            return setErrors({
                email: "Please enter E-mail",
            });
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
            scrollToElement(emailRef)
            return setErrors({
                email: "Email is Invalid",
            });
        }
        if (!phone1) return setErrors({ phone: "Please enter Phone number" })
        if (!phoneIsValid) return setErrors({ phone: "Please enter a valid Phone number" })
        if (!skills.length) return setErrors({ skills: "Please add atleast one skill" })
        if ((startYear > endYear && checked === false) || (startYear === endYear && startMonth > endMonth && checked === false)) return setErrors({ date: "Start year cannot be greater than end year" })
        if (startMonth == "-1") return setErrors({ startDate: "Please enter the start date" })
        if (checked == false && endMonth == "-1") return setErrors({ endDate: "Please enter the end date" })

        if (!userData.totalExperience) {
            experienceDetails.forEach(function (exp) {
                if (exp.checked) {
                    fullExperience = getTotalExperience(
                        new Date(exp.startYear, exp.startMonth),
                        new Date(new Date().getFullYear(), new Date().getMonth())
                    )
                } else {
                    fullExperience = getTotalExperience(
                        new Date(exp.startYear, exp.startMonth),
                        new Date(exp.endYear, exp.endMonth)
                    )
                }
            })
        }

        axios.get(`/api/admin/internal-database/${role}/get-all-emails/${email}`)
            .then((res) => {
                if (res.data.length > 0) {
                    setErrors({ email: "E-mail already exists" })
                }
            })
            .catch(err => {
                console.log(err)
            })

        let finalUserData = { ...userData, calculatedExp: fullExperience }


        const finalData = [finalUserData]
        const formData = new FormData();
        formData.append("finalData", JSON.stringify(finalData))
        setDisableSave(true)
        if (role === "candidate") {
            axios.post(`/api/admin/internal-candidates`, formData, {
                headers: { "Content-Type": "multipart/form-data" },
            })
                .then((res) => {
                    setOpenSnackbar(true)
                    setMessage("Candidate added successfully")
                    setTimeout(function () {
                        setResumeParseClicked(false)
                        setTableView("internalUsers")
                    }, 2000)

                    setUserData(initialState)
                    setErrors({
                        resume: "",
                        resumeSize: "",
                        resumeType: "",
                        phone: "",
                        firstName: "",
                        email: "",
                        skill: "",
                        resume: "",
                        startDate: "",
                        endDate: "",
                    })
                })
                .catch(err => {
                    console.log(err)
                    setOpenSnackbar(true)
                    setMessage("Something went wrong")
                })
                .finally(() => {
                    setDisableSave(false)
                })
        }
        else if (role === "screener") {
            axios.post(`/api/admin/internal-screeners`, formData, {
                headers: { "Content-Type": "multipart/form-data" },
            })
                .then((res) => {
                    setOpenSnackbar(true)
                    setMessage("Screener added successfully")
                    setTimeout(function () {
                        setResumeParseClicked(false)
                        setTableView("internalUsers")
                    }, 2000)
                    setUserData(initialState)
                    setErrors({
                        resume: "",
                        resumeSize: "",
                        resumeType: "",
                        phone: "",
                        firstName: "",
                        email: "",
                        skill: "",
                        resume: "",
                        startDate: "",
                        endDate: "",
                    })
                })
                .catch(err => {
                    console.log(err)
                    setOpenSnackbar(true)
                    setMessage("Something went wrong")
                })
                .finally(() => {
                    setDisableSave(false)
                })
        }
    }

    //add a new skill
    function addSkill(e, skill) {
        e.preventDefault();
        // inputRef.current.focus();
        setErrors({ skills: "" })
        let { skills } = userData
        let comparingArray = skills.map((item) => {
            return item.skillName.trim().toLowerCase().replace(/ /g, "");
        });

        if (comparingArray.includes(skill.trim().toLowerCase().replace(/ /g, ""))) {
            return setErrors({
                skill: "Skill already exists",
            });
            return
        }

        //if skill is not empty then it will add to dialog
        if (skill != "") {
            let skillObj = {
                skillName: skill,
                yearOfExperience: ""
            }
            const { skills } = userData
            skills.push(skillObj)
            setUserData({ ...userData, skills })
            setSkill("");
        }
    };

    // handle resume upload
    const handleResumeUpload = async (e) => {
        setErrors({ resumeSize: "", resumeType: "", resume: "" });

        function extractPositionAndCompany(resumeEntry) {
            let position = '';
            // const regex = /(?:\bat\s)?(.*?)(?:\s\d{2}|\s\d{4})?(?: - Present| –)/;
            // const regex = /(?:\bat\s)?(.*?)(?=\s-\sPresent|\s–|$)/;
            // const regex = /(?:\bat\s)?(.*?)(?=\s\d{2}\/\d{4}|\s\d{4}|\s–)/;
            const regex = /(?:\bat\s)?(.*?)(?=\s(?:Jan(?:uary)?|Feb(?:ruary)?|Mar(?:ch)?|Apr(?:il)?|May|Jun(?:e)?|Jul(?:y)?|Aug(?:ust)?|Sep(?:tember)?|Oct(?:ober)?|Nov(?:ember)?|Dec(?:ember)?)|\s\d{2}\/\d{4}|\s\d{4}|\s–)/;
            const match = regex.exec(resumeEntry);

            if (match) {
                position = match[1];
            }

            return position.trim();
        }

        function monthConversion(monthName) {
            const months = {
                Jan: '00', January: '00', Feb: '01', February: '01', Mar: '02', March: '02',
                Apr: '03', April: '03', May: '04', Jun: '05', June: '05',
                Jul: '06', July: '06', Aug: '07', August: '07', Sep: '08', September: '08',
                Oct: '09', October: '09', Nov: '10', November: '10', Dec: '11', December: '11'
            };
            return months[monthName];
        }

        function dateMatch(dateMatch) {
            if (dateMatch) {
                if (!dateMatch[0].includes('-') && !dateMatch[0].includes('/')) {
                    const [date] = dateMatch[0].split(' ');
                    console.log(date);
                    const month = monthConversion(date);
                    const year = dateMatch[0].split(' ')[1];
                    console.log("month and year", month, year);
                    console.log("Date: ", `${month}/${year}`);

                    return { month, year }

                }
                else {
                    console.log("Date: ", dateMatch[0]);
                    if (dateMatch[0].includes('/')) {
                        const date = dateMatch[0].split('/');
                        console.log("Date", date);
                        // if((date[0]-1)>=10){

                        // }
                        const month = `${((date[0]-1)>=10?"":"0"(date[0] - 1)).toString()}`;
                        console.log(month);
                        // `0${(startMonth-1).toString()}`
                        const year = date[1];
                        console.log("month and year", month, year);

                        return { month, year }


                    }
                    else if (dateMatch[0].includes('-')) {
                        const date = dateMatch[0].split('-');
                        console.log("Date", date);
                        // const month = `0${(date[0] - 1).toString()}`;
                        const month = `${((date[0]-1)>=10?"":"0"(date[0] - 1)).toString()}`;
                        const year = date[1];
                        console.log("month and year", month, year);
                        return { month, year }
                    }
                }
            }
        }

        e.preventDefault()
        const resumeCheck = e.target.files[0];
        const fileTypes = ['doc', "docx", "pdf"];
        let fileExtension = resumeCheck?.name.split('.')
        fileExtension = fileExtension[1]
        const maxSize = 1024 * 1024 * 1; //1MB
        //resumeCheck.size > maxSize ,
        if (resumeCheck?.size > maxSize) {
            setErrors({ resumeSize: "Please upload a file less than 1MB" });
            resumeRef.current.value = null;
        }
        else if (!fileTypes.includes(fileExtension)) {
            setErrors({ resumeType: "Please upload a pdf/doc file" });
        }
        else {
            setParseLoader(true)
            let resumeURL = await readFile(resumeCheck)
            const formData = new FormData();
            formData.append("parsedResume", resumeCheck)
            // if (fileExtension == 'docx') {
            //     try {
            //         let res = await axios.post('/api/admin/convert-resume-pdf', formData)
            //         resumeURL = `data:application/docx;base64,${res.data}`
            //     }
            //     catch (err) {
            //         console.log(err)
            //     }

            // }

            setResumeName(resumeCheck.name)
            setErrors({ resumeType: "", resumeSize: "" });

            //parse the resume
            const formData1 = new FormData();
            formData1.append("parsedResume", resumeURL)
            axios.post(`/api/admin/resume/parse`, formData1)
                .then(res => {
                    setParseLoader(false)
                    const { email, skills, languages, phone, profiles, experience } = res.data;

                    //extract skills
                    const splitString = skills ? skills : languages;
                    let updatedSkills = []
                    if (splitString) {
                        // const [skillsPart] = splitString.split('Certificates');
                        // // Extract the skills by removing the leading 'skills: ' and trimming whitespace and quotes
                        // updatedSkills = skillsPart.trim().replace(/'/g, '').split(/\n|,|;|:/).map(skill => ({ skillName: skill.trim(), yearOfExprience: "" }));

                        const [skillsPart] = splitString.split('Certificates')
                        updatedSkills = skillsPart.trim().replace(/'/g, '').split(/\n|,|;|:/).map(skill => ({ skillName: skill.trim(), yearOfExperience: "" }));
                    }

                    const list = [...userData.experienceDetails];

                    // code for extracting experience details
                    if (experience) {
                        let position, company, startMonth, startYear, endMonth, endYear;

                        let expArr = experience?.split('\n')

                        let expStr = ""
                        for (let item of expArr) {
                            expStr = expStr.concat(item + " ")
                            if (expStr.includes("20")) break
                        }


                        position = extractPositionAndCompany(expStr);
                        list[0].position = position;

                        //if experience contains 'present' keyword
                        if (expStr.toLowerCase().includes('present') || expStr.toLowerCase().includes('current')) {
                            let startDateRegex = /(?:(?:Jan(?:uary)?|Feb(?:ruary)?|Mar(?:ch)?|Apr(?:il)?|May|Jun(?:e)?|Jul(?:y)?|Aug(?:ust)?|Sep(?:tember)?|Oct(?:ober)?|Nov(?:ember)?|Dec(?:ember)?)\s\d{4})|(?:\d{2}(?:\/|-)\d{4})/

                            const startDateMatch = startDateRegex.exec(expStr);
                            if (startDateMatch) {
                                if (!startDateMatch[0].includes('-') && !startDateMatch[0].includes('/')) {
                                    const [startDate] = startDateMatch[0].split(' ');
                                    startMonth = monthConversion(startDate);
                                    startYear = startDateMatch[0].split(' ')[1];

                                }
                                else {
                                    if (startDateMatch[0].includes('/')) {
                                        const startDate = startDateMatch[0].split('/');
                                        console.log("startDate", startDate);
                                        // startMonth = `0${(startDate[0] - 1).toString()}`;
                                        startMonth = `${((startDate[0]-1)>=10?"":"0")}${(startDate[0] - 1).toString()}`;

                                        console.log(startMonth);
                                        // `0${(startMonth-1).toString()}`
                                        startYear = startDate[1];
                                    }
                                    else if (startDateMatch[0].includes('-')) {
                                        const startDate = startDateMatch[0].split('-');
                                        console.log("startDate", startDate);
                                        // startMonth = `0${(startDate[0] - 1).toString()}`;
                                        startMonth = `${((startDate[0]-1)>=10?"":"0")}${(startDate[0] - 1).toString()}`;
                                        console.log(startMonth);
                                        startYear = startDate[1];
                                    }
                                }
                            }
                            list[0].startMonth = startMonth;
                            list[0].startYear = startYear;
                            list[0].currentWorking = true;
                            list[0].checked = true;
                        }
                        //if experience contains both start date and end date
                        else {
                            let startDateRegex = /(?:(?:Jan(?:uary)?|Feb(?:ruary)?|Mar(?:ch)?|Apr(?:il)?|May|Jun(?:e)?|Jul(?:y)?|Aug(?:ust)?|Sep(?:tember)?|Oct(?:ober)?|Nov(?:ember)?|Dec(?:ember)?)\s\d{4})|(?:\d{2}(?:\/|-)\d{4})/
                            let endDateRegex = /(?:(?:Jan(?:uary)?|Feb(?:ruary)?|Mar(?:ch)?|Apr(?:il)?|May|Jun(?:e)?|Jul(?:y)?|Aug(?:ust)?|Sep(?:tember)?|Oct(?:ober)?|Nov(?:ember)?|Dec(?:ember)?)\s\d{4})|(?:\d{2}(?:\/|-)\d{4})/g


                            if (startDateRegex.exec(expStr)) {
                                const startDateMatch = startDateRegex.exec(expStr)[0];

                                if (!startDateMatch.includes('-') && !startDateMatch.includes('/')) {
                                    const [startDate] = startDateMatch.split(' ');
                                    startMonth = monthConversion(startDate);
                                    startYear = startDateMatch.split(' ')[1];
                                }
                                else {
                                    if (startDateMatch.includes('/')) {
                                        const startDate = startDateMatch.split('/');
                                        console.log("startDate", startDate);
                                        // startMonth = `0${(startDate[0] - 1).toString()}`;
                                        startMonth = `${((startDate[0]-1)>=10?"":"0")}${(startDate[0] - 1).toString()}`;
                                        console.log(startMonth);
                                        startYear = startDate[1];
                                    }
                                    else if (startDateMatch.includes('-')) {
                                        const startDate = startDateMatch.split('-');
                                        console.log("startDate", startDate);
                                        // startMonth = `0${(startDate[0] - 1).toString()}`;
                                        startMonth = `${((startDate[0]-1)>=10?"":"0")}${(startDate[0] - 1).toString()}`;
                                        console.log(startMonth);
                                        startYear = startDate[1];
                                    }
                                }
                            }

                            if (endDateRegex.exec(expStr)) {
                                const endDateMatch = endDateRegex.exec(expStr)?.[0];
                                if (!endDateMatch?.includes('-') && !endDateMatch?.includes('/') && endDateMatch) {
                                    const [endDate] = endDateMatch?.split(' ');
                                    endMonth = monthConversion(endDate);
                                    endYear = endDateMatch?.split(' ')[1];
                                }
                                else {
                                    if (endDateMatch?.includes('/')) {
                                        const endDate = endDateMatch?.split('/');
                                        console.log("endDate", endDate);
                                        // endMonth = `0${(endDate[0] - 1).toString()}`;
                                        endMonth = `${((endDate[0]-1)>=10?"":"0")}${(endDate[0] - 1).toString()}`;

                                        console.log(endMonth);
                                        endYear = endDate[1];
                                    }
                                    else if (endDateMatch?.includes('-')) {
                                        const endDate = endDateMatch?.split('-');
                                        console.log("endDate", endDate);
                                        // endMonth = `0${(endDate[0] - 1)?.toString()}`;
                                        endMonth = `${((endDate[0]-1)>=10?"":"0")}${(endDate[0] - 1).toString()}`;
                                        console.log(endMonth);
                                        endYear = endDate[1];
                                    }
                                }
                            }
                            list[0].startMonth = startMonth;
                            list[0].startYear = startYear;
                            list[0].endMonth = endMonth;
                            list[0].endYear = endYear;
                            if (list[0].endMonth && list[0].endYear) {
                                list[0].currentWorking = false;
                                list[0].checked = false;
                            }
                            // setUserData({ ...userData, experienceDetails: list })
                        }
                    }

                    setUserData({
                        ...userData,
                        resume: resumeURL,
                        resumeFormat: resumeCheck?.type.includes("pdf") ? "pdf" : "",
                        email,
                        phone1: `${phone ? phone : ""}`,
                        skills: updatedSkills || [],
                        experienceDetails: list
                    })


                })
                .catch(err => {
                    setParseLoader(false)
                    setMessage("Could not parse resume")
                    setOpenSnackbar(true)
                    console.log(err)
                })
        }
    };

    // used to get url of newly uploaded image
    function readFile(file) {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.addEventListener("load", () => resolve(reader.result), false);
            reader.readAsDataURL(file);
        });
    }

    // handle resume remove
    const handleRemoveResume = () => {
        setUserData(JSON.parse(JSON.stringify(initialState)));
        setResumeName("")
        if (resumeRef.current) {
            resumeRef.current.value = null;
        }
        setErrors({
            resume: "",
            resumeSize: "",
            resumeType: ""
        })
        setRemoveResumeDialog(false)
    };

    //function  to delete
    function deleteSkill(e, index) {
        e.preventDefault();
        let { skills } = userData
        skills = skills.filter((skill, i) => i !== index)
        setUserData({ ...userData, skills })
    };

    return <Box sx={{ flexGrow: 1, background: "#faf9f6" }}>
        <Card
            id="main-edit"
            className=" form-card table-card"
            style={{
                padding: "15px 20px 18px 20px ",
                boxShadow: "none",
            }}
        >
            <Grid
                container
                spacing={0}
                direction="row"
                className="add-recruiter-card-container"
            >
                <Grid
                    item
                    xs={8}
                    md={5}
                    lg={6}
                >
                    <div style={{ display: "flex", gap: "10px" }}>
                        {/* <SchoolIcon sx={{ color: "#2c3e50" }} /> */}
                        <h5 style={{ color: "#283848" }}>Parse Resume</h5>
                    </div>
                </Grid>


                <Grid container spacing={0} justifyContent="center">
                    {/* Left Grid Item */}
                    {/* <Grid
                        item
                        xs={12}
                        sm={12}
                        md={4}
                        lg={4}
                        sx={{ padding: "0 20px 20px 20px" }}
                        className="left-grid"
                    > */}

                    {/* </Grid> */}

                    {/* Right Grid Item */}
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={8}
                        lg={8}
                        // sx={{ padding: "0 20px 20px 20px" }}
                        className="right-grid form-view-mobile"
                    >
                        <div className="left-container" style={{ maxWidth: 'none', margin: "2rem 0" }}>
                            {/* if resume uploaded */}
                            {userData.resume ? <div className="resume-name-preview">
                                <div style={{ textAlign: "center" }}>
                                    {userData.firstName ? `${userData.firstName} ${userData.lastName}-resume` : `Resume`}
                                </div>
                                <div
                                    style={{ textAlign: "center", marginLeft: "5px" }}
                                >
                                    <Tooltip title="Remove">
                                        <CloseIcon
                                            className="close-icon2"
                                            fontSize="small"
                                            onClick={() => setRemoveResumeDialog(true)}
                                        />
                                    </Tooltip>
                                </div>

                                <div
                                    className="eye-icon"
                                    style={{
                                        textAlign: "center",
                                        padding: "0px 5px",
                                    }}
                                >
                                    {userData.resumeFormat == "application/pdf" ? <Tooltip title="View">
                                        <RemoveRedEyeOutlinedIcon
                                            fontSize="small"
                                            onClick={() => setOpenResumePreviewDialog(true)}
                                            style={{ cursor: "pointer" }}
                                        />
                                    </Tooltip> : <Tooltip title="Download">
                                        <a href={userData.resume} download >
                                            <DownloadIcon
                                                className="download-resume"
                                                fontSize="small"
                                                style={{ cursor: "pointer" }}
                                            />
                                        </a>
                                    </Tooltip>
                                    }
                                </div>
                                <div
                                    className="download-icon"
                                    style={{
                                        textAlign: "center",
                                        padding: "0px 5px",
                                    }}
                                >
                                    <a href={userData.resume} download >
                                        <DownloadIcon
                                            fontSize="small"
                                            style={{ cursor: "pointer" }}
                                        />
                                    </a>
                                </div>
                            </div>

                                /* if resume not uploaded */
                                : <div className="upload-resume">
                                    <p>Parse Resume</p>
                                    <label className="resume-file-upload">
                                        <input
                                            type="file"
                                            onChange={handleResumeUpload}
                                            accept=".pdf, .doc, .docx"
                                            ref={resumeRef}
                                        />
                                        <span>Attach</span>
                                    </label>
                                </div>}
                            <div>
                                <p class="text-danger">
                                    {errors.resumeSize} {errors.resumeType}{" "}
                                    {errors.resume}
                                </p>
                            </div>
                        </div>
                        <div className="input-container">
                            {/* FirstName and LastName */}
                            <div className="split-inputs mb">
                                <div class="input-field-modal">
                                    <label className="date-label">First Name*</label>
                                    <input
                                        className={`${errors.firstName && "input-field-error"}`}
                                        ref={firstNameRef}
                                        type="text"
                                        placeholder="First Name"
                                        value={userData.firstName}
                                        onChange={(e) => {
                                            setUserData({ ...userData, firstName: e.target.value })
                                            setErrors({ firstName: "" })
                                        }}
                                    />
                                    <div className='error-div' style={{ height: "20px" }}>
                                        <span className="text-danger">{errors.firstName}</span>
                                    </div>
                                </div>

                                <div class="input-field-modal">
                                    <label className="date-label">Last Name</label>
                                    <input
                                        className="position"
                                        type="text"
                                        placeholder="Last Name"
                                        value={userData.lastName}
                                        onChange={(e) => {
                                            setUserData({ ...userData, lastName: e.target.value })
                                        }}
                                    />
                                </div>
                            </div>

                            {/* Email and Profile Title */}
                            <div className="split-inputs mb">
                                <div className="input-field-modal">
                                    <label className="date-label">Email*</label>
                                    <input
                                        className={`${errors.email && "input-field-error"}`}
                                        ref={emailRef}
                                        type="email"
                                        placeholder="Email Id*"
                                        value={userData.email}
                                        onChange={(e) => {
                                            setUserData({ ...userData, email: e.target.value })
                                            setErrors({ email: "" })
                                        }}
                                    />
                                    <div className='error-div' style={{ height: "20px" }}>
                                        <span className="text-danger">{errors.email}</span>
                                    </div>
                                </div>

                                <div className="input-field-modal">
                                    <label className="date-label">Profile Title</label>
                                    <input
                                        type="text"
                                        placeholder="Ex: Software Developer"
                                        value={userData.profileTitle}
                                        onChange={(e) => setUserData({ ...userData, profileTitle: e.target.value })}
                                    />
                                </div>
                            </div>

                            <div class="input-field-modal" style={{ width: "49%" }}>
                                <label className="date-label">Phone*</label>
                                <PhoneInput
                                    id="phone"
                                    placeholder="Contact No*"
                                    name="phone"
                                    class="phone"
                                    className={`${errors.phone && "input-field-error"}`}
                                    ref={phoneRef}
                                    country={"us"}
                                    value={userData.phone1}
                                    onChange={(phone, country) => {
                                        setUserData({ ...userData, phone1: phone })
                                        setErrors({ phone: "" })
                                        var regex = phones[country.countryCode.toUpperCase()];
                                        if (regex != undefined) {
                                            if (!regex.test(phone)) {
                                                setPhoneIsValid(false);
                                                return setErrors({
                                                    phone: "Invalid Phone Number",
                                                });
                                            } else {
                                                setPhoneIsValid(true);
                                                return setErrors({ phone: "" });
                                            }
                                        }
                                    }}
                                />
                                <div className='error-div' style={{ height: "20px" }}>
                                    <span className="text-danger">{errors.phone}</span>
                                </div>
                            </div>

                            {/* Skills Edit */}
                            <div className="experience-edit-form-container skills-form-container" style={{ width: "100%", margin: 0 }}>
                                <form
                                    className="experience-edit-form"
                                    onSubmit={addSkill}
                                    noValidate
                                >
                                    <div class="input-container users-form mb">
                                        <div class="input-field users-skill">
                                            <label className="date-label">Add Skill*</label>
                                            <div className='add-skill-container'>
                                                <div className="input-fields-flex" style={{ padding: "none", alignItems: "baseline" }}>
                                                    <Autocomplete
                                                        // ref={inputRef}
                                                        sx={{
                                                            "& fieldset": { border: "none" },
                                                        }}
                                                        ListboxProps={{ style: { maxHeight: 220 } }}
                                                        // key={clear}
                                                        freeSolo
                                                        id="free-solo-demo"
                                                        value={skill}
                                                        options={skill ? allSkillsList.map((item) => item.skill) : []}
                                                        disableClearable={true}
                                                        onChange={(event, newValue) => {
                                                            setSkill(newValue);
                                                            setErrors({ skills: "" })
                                                        }}
                                                        renderInput={(params) => (
                                                            <div style={{ display: "flex", flexDirection: "column" }}>
                                                                <TextField
                                                                    value={userData.skills}
                                                                    onChange={(e) => {
                                                                        setSkill(e.target.value);
                                                                        setErrors({ skills: "" })

                                                                    }}
                                                                    {...params}
                                                                    InputProps={{
                                                                        ...params.InputProps,
                                                                        disableUnderline: true,
                                                                    }}
                                                                    margin="normal"
                                                                />
                                                                <div className='error-div' style={{ height: "20px" }}>
                                                                    <span className="text-danger">{errors.skills}</span>
                                                                </div>
                                                            </div>
                                                        )}
                                                    />

                                                    {/* <input
                                                        class="position"
                                                        type="number"
                                                        style={{
                                                            height: "38px", position: "relative", top: "1px", fontSize: "12px"
                                                        }}
                                                        placeholder="Years of Experience"
                                                    // value={experience}
                                                    // onChange={experienceHandler}
                                                    /> */}
                                                    <div className="button-container">
                                                        <button
                                                            className=" btn-style outlined-btn"
                                                            disabled={false}
                                                            onClick={(e) => addSkill(e, skill)}
                                                        >
                                                            {" "}
                                                            Add
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="input-field">
                                            <div className="skill-button-container">
                                                <div className="skills-buttons">
                                                    {userData?.skills?.map((item, index) => {
                                                        return (
                                                            <Tooltip
                                                                title={item.yearOfExperience ? `${item.skillName} - ${item.yearOfExperience} year(s)` : `${item.skillName}`}
                                                            >
                                                                <button
                                                                    className="outlined"
                                                                    key={index}
                                                                    onClick={(e) => deleteSkill(e, index)}
                                                                >
                                                                    {item.skillName}
                                                                    <CloseIcon
                                                                        fontSize="small"
                                                                    />
                                                                </button>
                                                            </Tooltip>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                        {/* {userData.skills.length > 0 ? (
                                            <div style={{ marginTop: "5px" }}>
                                                <InfoOutlinedIcon
                                                    fontSize="small"
                                                    style={{ color: "#2c3e50", fontSize: "16px" }}
                                                />{" "}
                                                <span style={{ fontSize: "13px" }}>
                                                    Please hover over on each skill to know their individual experience.
                                                </span>
                                            </div>
                                        ) : ""} */}
                                        {/* <span className="text-danger">{errors.skillArray}</span> */}
                                    </div>
                                </form>
                            </div>


                            {/* Experience Details Section */}
                            <div className="split-inputs mb">

                                <div class="input-field-modal">
                                    <label className="date-label">Position</label>
                                    <input
                                        type="text"
                                        placeholder="Eg: Software Developer"
                                        value={userData.experienceDetails[0].position}
                                        onChange={(e) => {
                                            const { experienceDetails } = userData
                                            experienceDetails[0].position = e.target.value
                                            setUserData({ ...userData, experienceDetails })
                                        }}
                                    />
                                    {/* <div className='error-div' style={{ height: "20px" }}>
                                            <span className="text-danger">{errors.email}</span>
                                        </div> */}
                                </div>

                                <div class="input-field-modal">
                                    <label className="date-label">Company</label>
                                    <input
                                        className="profileTitle"
                                        type="text"
                                        placeholder="Ex: Microsoft"
                                        value={userData.experienceDetails[0].company}
                                        onChange={(e) => {
                                            const { experienceDetails } = userData
                                            experienceDetails[0].company = e.target.value
                                            setUserData({ ...userData, experienceDetails })
                                        }}
                                    />
                                    {/* <div className='error-div' style={{ height: "20px" }}>
                                        <span className="text-danger">{errors.profileTitle}</span>
                                    </div> */}
                                </div>
                            </div>

                            <label className="date-label">Start Date</label>
                            <div className="split-inputs mb">
                                <div class="input-field-modal">
                                    <select
                                        name='startMonth'
                                        value={userData.experienceDetails[0].startMonth}
                                        onChange={(e) => {
                                            const { experienceDetails } = userData
                                            experienceDetails[0].startMonth = e.target.value
                                            setUserData({ ...userData, experienceDetails })
                                            setErrors({ startDate: "", endDate: "" })
                                        }}
                                        style={{ outline: "none" }}
                                    // className={`${errors[index]?.startMonth && "input-field-error"}`}
                                    >
                                        {months.map((option) => {
                                            return <option
                                                value={option.value}
                                            // disabled={currentYear == userData.experienceDetails[0].startYear && Number(option.value) > new Date().getMonth()}
                                            >
                                                {option.label}
                                            </option>
                                        })}
                                    </select>

                                    {/* <span className="text-danger">{errors[index]?.startMonth}</span> */}
                                </div>

                                <div class="input-field-modal">

                                    <select
                                        value={userData.experienceDetails[0].startYear}
                                        name="startYear"
                                        onChange={(e) => {
                                            const { experienceDetails } = userData
                                            experienceDetails[0].startYear = e.target.value
                                            setUserData({ ...userData, experienceDetails })
                                            setErrors({ startDate: "", endDate: "" })
                                        }}
                                        style={{ outline: "none" }}
                                    >
                                        {years}
                                    </select>
                                </div>
                            </div>
                            <div style={{ height: "20px" }}>
                                <span className="text-danger">{errors.startDate}</span>
                            </div>
                            <label className="date-label" >End Date</label>

                            <div className="split-inputs mb">

                                <div class="input-field-modal">

                                    <select name='endMonth'
                                        disabled={userData.experienceDetails[0].currentWorking}
                                        value={userData.experienceDetails[0].endMonth}
                                        onChange={(e) => {
                                            const { experienceDetails } = userData
                                            experienceDetails[0].endMonth = e.target.value
                                            setUserData({ ...userData, experienceDetails })
                                            setErrors({ startDate: "", endDate: "" })
                                        }}
                                        style={{ outline: "none" }}
                                    // className={`${errors[index]?.startMonth && "input-field-error"}`}
                                    >

                                        {months.map((option) => {
                                            return <option
                                                value={option.value}
                                            // disabled={currentYear == userData.experienceDetails[0].startYear && Number(option.value) > new Date().getMonth()}
                                            >{option.label}
                                            </option>
                                        })}
                                    </select>

                                    {/* <span className="text-danger">{errors[index]?.startMonth}</span> */}

                                </div>
                                <div class="input-field-modal">
                                    <select
                                        disabled={userData.experienceDetails[0].currentWorking}
                                        name="endYear"
                                        defaultValue={userData.experienceDetails[0].endYear}
                                        onChange={(e) => {
                                            const { experienceDetails } = userData
                                            experienceDetails[0].endYear = e.target.value
                                            setUserData({ ...userData, experienceDetails })
                                            setErrors({ startDate: "", endDate: "" })
                                        }}
                                        style={{ outline: "none" }}
                                    >
                                        {years}
                                    </select>
                                </div>
                            </div>
                            <div style={{ height: "20px" }}>
                                <span className="text-danger">{errors.endDate}</span>
                            </div>

                            <div className="input-field">
                                <FormGroup className="input-field-checkbox">
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                size="small"
                                                sx={{
                                                    color: "gray",
                                                    "&.Mui-checked": {
                                                        color: "#F06161",
                                                    },
                                                }}
                                                checked={userData.experienceDetails[0].currentWorking}
                                                value={userData.experienceDetails[0].currentWorking}
                                                onClick={(e) => {
                                                    const { experienceDetails } = userData
                                                    experienceDetails[0].checked = e.target.checked
                                                    experienceDetails[0].currentWorking = e.target.checked
                                                    experienceDetails[0].endMonth = "-1"
                                                    experienceDetails[0].endYear = currentYear.toString()
                                                    setUserData({ ...userData, experienceDetails })
                                                    setErrors({ startDate: "", endDate: "" })
                                                }}
                                            />
                                        }
                                        label="Currently Working here"
                                    />
                                </FormGroup>
                            </div>
                        </div>
                        <div className="button-container navigation-btn-container" >
                            <button className="btn-style filled-btn filled-btn-animation"
                                disabled={disableSave}
                                onClick={handleSaveProfile}
                            >

                                {disableSave ? 'Saving..' : 'Save'}
                            </button>

                        </div>
                    </Grid>
                </Grid>
            </Grid >

            {/*Dialogs*/}
            <Dialog
                fullWidth
                maxWidth="xs"
                open={parseLoader}
                onClose={parseLoader}
                className="experience-edit-dialog"
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent className="dialog-content">
                    <Grid container spacing={0} justifyContent="center">
                        <Grid
                            style={{ padding: "30px" }}
                            item
                            xs={11.5}
                            sm={11.5}
                            md={12}
                            lg={12}
                            className="experience-delete-grid"
                        >
                            <div style={{ width: "50px", height: "50px", display: 'flex', justifyContent: 'center', margin: "auto" }} className="delete-message-icon">
                                <CircularProgress />
                            </div>
                            <div className="delete-dialog-content">
                                <h5><strong>Parsing your resume..</strong></h5>
                                <p>Kindly wait. This will take few seconds.</p>
                            </div>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>

            <Dialog
                fullWidth
                maxWidth="xs"
                open={removeResumeDialog}
                onClose={(e) => setRemoveResumeDialog(false)}
                className="experience-edit-dialog"
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent className="dialog-content">
                    <Grid container spacing={0} justifyContent="center">
                        <Grid
                            item
                            xs={11.5}
                            sm={11.5}
                            md={12}
                            lg={12}
                            className="experience-delete-grid"
                        >
                            <div className="delete-message-icon">
                                <DeleteMessageIcon fontSize="small" />
                            </div>
                            <div className="delete-dialog-content">
                                <h5>Are you sure?</h5>
                                <p>Do you want remove your resume?</p>
                            </div>
                        </Grid>
                    </Grid>
                </DialogContent>
                <div className="alert-buttons-container">
                    <button className="warning-btn-style filled-btn filled-btn-animation" onClick={() => setRemoveResumeDialog(false)}>
                        {" "}
                        Cancel
                    </button>
                    <button className="warning-btn-style outlined-btn outlined-btn-animation" onClick={handleRemoveResume}>
                        {" "}
                        Confirm
                    </button>
                </div>
            </Dialog>

            {/* Resume Preview Dialog */}
            <Dialog open={resumePreviewDialog} onClose={() => setOpenResumePreviewDialog(false)}>
                <DialogContent>
                    <Tooltip title="Close">
                        <CloseIcon
                            className="close-icon1"
                            onClick={() => {
                                setOpenResumePreviewDialog(false);
                            }}
                            sx={{
                                cursor: "pointer",
                                padding: 0,
                                margin: "1px",
                            }}
                        />
                    </Tooltip>
                    <Grid
                        item
                        xs={11.5}
                        sm={11.5}
                        md={11.5}
                        lg={11.5}
                        sx={{
                            padding: "30px 10px 10px 10px",
                            width: "600px",
                            height: "650px",
                        }}
                    >
                        <object
                            className="pdf-preview-component"
                            style={{ width: "550px", height: "550px", margin: "15px" }}
                            data={userData.resume}
                            type="application/pdf"
                        >
                            <iframe
                                src={userData.resume}
                            ></iframe>
                        </object>
                    </Grid>
                </DialogContent>
            </Dialog>

            <UserSnackbar
                openSnackbar={openSnackbar}
                setOpenSnackbar={setOpenSnackbar}
                message={message}
            />
        </Card >
    </Box >
}

export default ParseResume