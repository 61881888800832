import React, { useEffect, useState, useRef } from "react";
import Axios from "axios";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Slide from "@mui/material/Slide";
import TablePagination from "@mui/material/TablePagination";
import { styled } from "@mui/material/styles";
import { TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import UserSnackBar from "../../../snackBars/UserSnackbar";
import { moduleList } from "../ModuleList";

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
});

const EditUserRole = ({ sidebarOpen, setPageName, selectedRole }) => {
    const classes = useStyles();

    //company id
    const id = sessionStorage.getItem("compId");

    //pagination states
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [page, setPage] = React.useState(0);


    //Form states
    const [rows, setRows] = useState([]);


    const [searched, setSearched] = useState("");
    const [count, setCount] = useState(selectedRole.count);

    const [roleName, setRoleName] = useState(selectedRole.roleName);
    const [description, setDescription] = useState(selectedRole.description);
    const [charCount, setCharCount] = useState(selectedRole.description.length);
    const [selectedId, setSelectedId] = useState(selectedRole._id);  //selected user role id

    //Snackbar states
    const [openSnackbar, setOpenSnackbar] = useState(false);

    //refs
    const roleRef = useRef(null);
    const descRef = useRef(null);
    const filterRef = useRef(null);

    //cursor position
    const [rolePosition, setRolePosition] = useState(0);
    const [descPosition, setDescPosition] = useState(0);
    const [filterPosition, setFilterPosition] = useState(0);

    //positioning cursor of input in position
    useEffect(() => {
        roleRef.current.selectionStart = rolePosition;
        roleRef.current.selectionEnd = rolePosition;
    }, [roleName]);

    useEffect(() => {
        descRef.current.selectionStart = descPosition;
        descRef.current.selectionEnd = descPosition;
    }, [description])

    useEffect(() => {
        filterRef.current.selectionStart = filterPosition;
        filterRef.current.selectionEnd = filterPosition;
    }, [searched])

    //errors
    const [errors, setErrors] = useState({
        roleName: "",
        moduleList: "",
    });

    // checking modulelist length and updating if new module is added
    useEffect(() => {
        const { data } = Axios.get(`/api/admin/${id}`).then((res) => {
            console.log(res.data)
            if (res.data.user) {
                if (!res.data.user.isInternalDB) {
                    // removing internal db from module list
                    let modules = moduleList.filter(mod => mod.title != "Internal DB")
                    // checking bavkend list with filtered modules
                    if (selectedRole.modules.length !== modules.length) {
                        // getting modules which are not saved in backend
                        const filteredArray = modules.filter(
                            (obj1) => !selectedRole.modules.some((obj2) => obj2.title === obj1.title)
                        );
                        console.log(filteredArray, "module")
                        // adding extra modules at the specified location in the array
                        filteredArray.map(mod => {
                            selectedRole.modules.splice(mod.index, 0, mod)
                        })
                        // updating index
                        for (let i = 0; i < selectedRole.modules.length; i++) {
                            selectedRole.modules[i].index = i
                        }
                    }
                    console.log(selectedRole.modules)
                    setRows(selectedRole.modules)
                } else {

                    if (selectedRole.modules.length !== moduleList.length) {
                        const filteredArray = moduleList.filter(
                            (obj1) => !selectedRole.modules.some((obj2) => obj2.title === obj1.title)
                        );
                        console.log(filteredArray, "module")
                        filteredArray.map(mod => {
                            selectedRole.modules.splice(mod.index, 0, mod)
                        })
                        for (let i = 0; i < rows.length; i++) {
                            selectedRole.modules[i].index = i
                        }
                    }
                    setRows(selectedRole.modules)
                }
            }
        })

        console.log("module", rows)

    }, [])

    //module list checkbox handler
    const checkHandler = (index) => {
        setErrors({
            moduleList: "",
        });
        var updatedModules = [...rows];
        updatedModules = rows.map((item) => {
            if (item.index == index) {
                if (item.checked) {
                    setCount((count) => count - 1);
                } else {
                    setCount((count) => count + 1);
                }
                if (item.checked && item.view) {
                    return { ...item, checked: false, view: false, edit: false };
                } else {
                    return {
                        ...item,
                        checked: !item.checked,
                        view: false,
                        edit: !item.edit,
                    };
                }
            } else {
                return item;
            }
        });
        setRows(updatedModules);
    };

    //View radio button handler
    const viewHandler = (index) => {
        var updatedModules = [...rows];
        updatedModules = rows.map((item) => {
            if (item.index == index) {
                return { ...item, checked: item.checked, view: true, edit: false };
            } else {
                return item;
            }
        });
        setRows(updatedModules);
    };

    //Edit radio button handler
    const editHandler = (index) => {
        var updatedModules = [...rows];
        updatedModules = rows.map((item) => {
            if (item.index == index) {
                return { ...item, checked: item.checked, view: false, edit: true };
            } else {
                return item;
            }
        });
        setRows(updatedModules);
    };

    //form Handlers
    const roleNameHandler = (e) => {
        setRoleName(e.target.value);
        setRolePosition(e.target.selectionStart);
        setErrors({
            roleName: "",
        });
    };

    const descriptionHandler = (e) => {
        if (description.length < 120) {
            setDescription(e.target.value);
            setCharCount(e.target.value.length)
        }
        setErrors({
            roleName: "",
        });
        setDescPosition(e.target.selectionStart);
    };

    const searchHandler = (e) => {
        setSearched(e.target.value.trim());
        setFilterPosition(e.target.selectionStart);
    }

    //pagination handler
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    //submit handler
    const onSubmit = (e) => {
        e.preventDefault();
        if (roleName === "") {
            return setErrors({
                roleName: "Role Name is required!",
            });
        }
        if (count === 0) {
            return setErrors({
                moduleList: "Add atleast one module!",
            });
        }

        //state to send data to backend
        const newData = {
            description,
            roleName,
            rows,
            selectedId,
            count,
        };

        Axios.post(`/api/admin/edit/userRole/${id}`, newData)
            .then((res) => {
                setOpenSnackbar(true);
                setTimeout(() => {
                    setPageName("userRole")
                }, 2000);
            })
            .catch((err) => {
                if (err.response.status == 404) {
                    return setErrors({
                        roleName: err.response.data.message,
                    });
                }
            });
    };

    //back handler
    const itemHandler = () => {
        setPageName("userRole")
    };



    return (
        <div style={{ marginLeft: `${sidebarOpen === "sidebar-open" ? "200px" : "0px"}` }}>
            <Box sx={{ flexGrow: 1, background: "#faf9f6" }}>
                <Card id="main-edit" className="add-recruiter-form form-card">
                    <Grid
                        container
                        spacing={0}
                        // direction="row"
                        className="add-recruiter-card-container"
                    >
                        <Grid
                            item
                            xs={12}
                            sm={sidebarOpen === "sidebar-open" ? 12 : 4}
                            md={sidebarOpen === "sidebar-open" ? 12 : 4}
                            lg={6}
                            className="container-heading-title admin-table"
                        >
                            <h3>
                                <span className="previousHighlighter" onClick={itemHandler}>
                                    Settings
                                </span>
                                {` > User Role `}
                            </h3>
                        </Grid>
                        <Grid
                            container
                            spacing={1}
                            direction="row"
                        // className="profile-progress-bar"
                        >
                            <Grid
                                id="profile-completion-percentage"
                                item
                                xs={12}
                                md={12}
                                sx={{ textAlign: "center" }}
                                className="container-profile"
                            >
                                <div className="table-buttons">
                                    <button className="add-recruiter " onClick={itemHandler}>
                                        Back
                                    </button>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Card>

                <div className="add-recruiter-form-container bs-none profile-card">
                    <div className="add-user-form-container">
                        <form className="add-user-form" noValidate>
                            <div class="input-container">
                                <Card
                                    style={{ marginBottom: "70px", marginTop: "40px" }}
                                    id="skills-edit"
                                    className=" profile-card"
                                >
                                    <div style={{ margin: "20px" }}>
                                        <div class="input-field">
                                            <label className="date-label">Role Name*</label>
                                            <input
                                                class="position"
                                                type="text"
                                                value={roleName}
                                                onChange={roleNameHandler}
                                                placeholder="Role Name* "
                                                ref={el => (roleRef.current = el)}
                                                autoFocus={
                                                    roleRef.current === document.activeElement
                                                }
                                            />
                                            <span className="text-danger">{errors.roleName}</span>
                                        </div>

                                        <div class="input-field">
                                            <label className="date-label">Description</label>
                                            <textarea
                                                maxRows={5}
                                                style={{ fontSize: "13px" }}
                                                value={description}
                                                onChange={descriptionHandler}
                                                variant="standard"
                                                placeholder="Description"
                                                maxLength={120}
                                                autoFocus={
                                                    descRef.current === document.activeElement
                                                }
                                                ref={descRef}
                                            />
                                            <p style={{ fontSize: '12px', float: 'right' }}>{charCount}/120</p>
                                        </div>
                                    </div>
                                </Card>
                                <Card
                                    style={{ marginBottom: "20px" }}
                                    id="skills-edit"
                                    className=" profile-card"
                                >
                                    <div style={{ margin: "20px" }}>
                                        <div class="input-field">
                                            <label className="date-label">Modules*</label>
                                            <Paper>
                                                <TextField
                                                    className="search-field"
                                                    variant="standard"
                                                    InputProps={{
                                                        disableUnderline: true
                                                    }}
                                                    style={{ width: "40%", marginBottom: "10px" }}
                                                    value={searched}
                                                    onChange={searchHandler}
                                                    inputRef={filterRef}
                                                    autoFocus={
                                                        filterRef.current === document.activeElement
                                                    }
                                                />
                                                <TableContainer>
                                                    <Table
                                                        className={classes.table}
                                                        aria-label="simple table"
                                                    >
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell
                                                                    style={{
                                                                        paddingLeft: "5%",
                                                                        fontFamily: "Raleway",
                                                                    }}
                                                                >
                                                                    Modules
                                                                </TableCell>
                                                                <TableCell
                                                                    style={{ fontFamily: "Raleway" }}
                                                                    align="center"
                                                                    colSpan={2}
                                                                >
                                                                    Privileges
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {rows
                                                                .filter((row) => {
                                                                    return row.title
                                                                        .toLowerCase()
                                                                        .includes(searched.toLowerCase());
                                                                })
                                                                .slice(
                                                                    page * rowsPerPage,
                                                                    page * rowsPerPage + rowsPerPage
                                                                )
                                                                .map((row) => (
                                                                    <TableRow key={row.index}>
                                                                        <TableCell
                                                                            align="left"
                                                                            scope="row"
                                                                            component="th"
                                                                            size="small"
                                                                            padding="normal"
                                                                            style={{ width: "80%" }}
                                                                        >
                                                                            <input
                                                                                style={{
                                                                                    width: "5%",
                                                                                    marginTop: "9px",
                                                                                    accentColor: "#f06161",
                                                                                }}
                                                                                type="checkbox"
                                                                                checked={row.checked}
                                                                                onChange={(e) =>
                                                                                    checkHandler(row.index)
                                                                                }
                                                                            />
                                                                            <label style={{ fontSize: "13px" }}>
                                                                                {row.title}{" "}
                                                                            </label>
                                                                        </TableCell>

                                                                        <TableCell align="right">
                                                                            {" "}
                                                                            <input
                                                                                style={{
                                                                                    width: "40%",
                                                                                    marginTop: "9px",
                                                                                    accentColor: "#f06161",
                                                                                }}
                                                                                disabled={!row.checked}
                                                                                type="radio"
                                                                                checked={row.edit}
                                                                                value="edit"
                                                                                onChange={(e) =>
                                                                                    editHandler(row.index)
                                                                                }
                                                                                id="edit"
                                                                            />
                                                                            <label
                                                                                htmlFor="edit"
                                                                                style={{
                                                                                    fontSize: "13px",
                                                                                    color: `${row.checked
                                                                                        ? "black"
                                                                                        : "lightgray"
                                                                                        }`,
                                                                                }}
                                                                            >
                                                                                Edit
                                                                            </label>
                                                                        </TableCell>

                                                                        <TableCell align="right">
                                                                            {" "}
                                                                            <input
                                                                                style={{
                                                                                    width: "40%",
                                                                                    marginTop: "9px",
                                                                                    accentColor: "#f06161",
                                                                                }}
                                                                                disabled={!row.checked}
                                                                                type="radio"
                                                                                checked={row.view}
                                                                                value="view"
                                                                                onChange={(e) =>
                                                                                    viewHandler(row.index)
                                                                                }
                                                                                id="view"
                                                                            />
                                                                            <label
                                                                                htmlFor="view"
                                                                                style={{
                                                                                    fontSize: "13px",
                                                                                    color: `${row.checked
                                                                                        ? "black"
                                                                                        : "lightgray"
                                                                                        }`,
                                                                                }}
                                                                            >
                                                                                View
                                                                            </label>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                ))}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </Paper>
                                            <div style={{ marginTop: "10px" }}>
                                                <span className="text-danger">
                                                    {errors.moduleList}
                                                </span>
                                            </div>

                                            <TablePagination
                                                id="recruiter-list-pagination"
                                                component="div"
                                                rowsPerPageOptions={[10, 25, 50, 100]}
                                                count={rows.length}
                                                page={page}
                                                onPageChange={handleChangePage}
                                                rowsPerPage={rowsPerPage}
                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                                variant="outlined"
                                            />
                                        </div>

                                        <div className="button-container navigation-btn-container next-btn-container" style={{ justifyContent: "flex-end" }}>
                                            <button
                                                className="btn-style filled-btn filled-btn-animation"
                                                type="submit"
                                                onClick={onSubmit}
                                            >
                                                {" "}
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                </Card>
                            </div>
                        </form>
                    </div>
                </div>
            </Box>

            {/* Snackbar modal */}
            < UserSnackBar
                openSnackbar={openSnackbar}
                setOpenSnackbar={setOpenSnackbar}
                message="User Role Edited Succesfully!"
            />
        </div >
    );
};

export default EditUserRole;
