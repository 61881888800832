import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { makeStyles } from '@material-ui/core/styles';
import React from "react";

const styles = makeStyles((theme) => ({
    main: {
        width: "auto",
        display: "block", // Fix IE 11 issue.
        marginTop: theme.spacing(5),
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        [theme.breakpoints.up(400 + theme.spacing(6))]: {
            marginLeft: "auto",
            marginRight: "auto",
        },
        [theme.breakpoints.up(1000)]: {
            marginLeft: "69px",
        },
        [theme.breakpoints.up(1400)]: {
            marginLeft: "173px",
        },
    },
    textField: {
        marginLeft: theme.spacing(),
        marginRight: theme.spacing(),
        width: "auto",
        maxWidth: 200,
    },
}));

const ExpertiseLabelsOne = [
    "Application/Software Development",
    "Big Data, Data Science",
    "Blockchain",
    "Business Intelligence, & Analytics",
    "Cloud",
];

const ExpertiseLabelsTwo = [
    "Database Development & Management",
    "Development Operations",
    "ERP, CRM, SCM, PLM",
    "Infrastructure & Data Centers",
    "Internet of Things",
    "UI/UX",
];

const ExpertiseLabelsThree = [
    "Mobile",
    "Network, Voice, & Data Communication",
    "Project Management",
    "Quality Assurance",
    "Security",
];
export default function formAreaExpertise(props) {
        const classes = styles();
        const { handleChange, expertise } = props;

        var checkExpertise = (areaOExp) => {
            return expertise.includes(areaOExp);
        };

        return (
            <>
                <main className={classes.main}>
                    <label className="" align="left">
                        <b>Select up to 5 areas of expertise</b>
                    </label>{" "}
                    <br />
                    <div className="d-flex flex-row">
                        <div className="flex-fill" id="exper-col-one">
                            {ExpertiseLabelsOne.map((areaOfExp) => (
                                <div className="" key={areaOfExp}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={checkExpertise(areaOfExp)}
                                                disabled={false}
                                                onChange={handleChange()}
                                                value={areaOfExp}
                                            />
                                        }
                                        label={areaOfExp}
                                    />
                                </div>
                            ))}
                        </div>
                        <div className="flex-fill" id="exper-col-two">
                            {ExpertiseLabelsTwo.map((areaOfExp) => (
                                <div className="" key={areaOfExp}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={checkExpertise(areaOfExp)}
                                                disabled={false}
                                                onChange={handleChange()}
                                                value={areaOfExp}
                                            />
                                        }
                                        label={areaOfExp}
                                    />
                                </div>
                            ))}
                        </div>
                        <div className="flex-fill" id="exper-col-three">
                            {ExpertiseLabelsThree.map((areaOfExp) => (
                                <div className="" key={areaOfExp}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={checkExpertise(areaOfExp)}
                                                disabled={false}
                                                onChange={handleChange()}
                                                value={areaOfExp}
                                            />
                                        }
                                        label={areaOfExp}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                </main>
            </>
        );

}

