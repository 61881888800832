import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

const SearchBar = ({ keyword, setKeyword }) => {
  // const BarStyling = {
  //   width: '25rem',
  //   background: 'white',

  //   height: '5px',
  //   cursor: 'pointer',
  //   color: ' black',
  //   padding: '5px 10px',
  //   float: 'right',
  //   padding: '20px',
  //   margin: '0px 0px 20px 10px',
  //   borderRadius: '30px',
  //   outline: 'none',
  //   border: '1px solid',
  //   boxShadow: '5px 10px #d9d9d9',
  // };
  const BarStyling = { width: "30rem",height: '5px', boxShadow: '5px 10px #d9d9d9',background: "white", border: "none", padding: "20px", margin: '0px 0px 20px 10px', border: '1px solid' };
  return (
  <>
      <input
    style={BarStyling}
        type="text"
        key="random1"
        value={keyword}
        placeholder={'Search For Candidates'}
        onChange={(e) => setKeyword(e.target.value)}
        name="search"
      />
  </>
  );
};

export default SearchBar;
