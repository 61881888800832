import React from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import SimpleCards from "../../cards/SimpleCards";
import { customprogress, ratingBoxStyle, ratingCardStyles } from "./sidebarStyles";
import {Grid, Paper, Typography ,} from "@material-ui/core";


const paperStyle = { height: '85px', width: '104%',margin:'0px 0px 0px -45px'}
export default function CProfileCompletion(props) {
    
    const { completionPercentage } = props;
    return (
        // <SimpleCards cardStyle={ratingCardStyles} title="Profile Completed">
            // <div style={ratingBoxStyle}>
            <div>
                  <Grid container spacing={3}>
                 <Grid item xs={12}  cardStyle={ratingCardStyles}>
                            <Paper elevation={5} style={paperStyle} > 
                            <Typography className="typographyProfile">Profile Completed</Typography>
                            <ProgressBar
                    style={customprogress}
                    label={`${completionPercentage}%`}
                     animated
                    variant="success"
                     striped
                    now={completionPercentage}
                />
                </Paper>
                </Grid>
                </Grid>

                                
            </div>
        // </SimpleCards>
    );
}
