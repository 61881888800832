import React from 'react'
import "./PageNotFound.css"
const PageNotFound = () => {
  return (
    <div class="error-container">
    <div id="notfound">
        <div class="notfound">
            <div class="notfound-404">
                <h1>404</h1>
            </div>
            <h3>Oops! You weren't supposed to see this.</h3>
            <p>The page you are looking for no longer exists.</p>
            <p> <a href="/">Return to homepage</a> and remember: you haven't seen anything ;)</p>
            
        </div>
    </div>
</div>
  )
}

export default PageNotFound