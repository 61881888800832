import React, { useState, useEffect } from 'react'
import axios from 'axios';

import { Card, CardContent } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";

import DeleteIcon from '@mui/icons-material/Delete';
import ArchiveIcon from '@mui/icons-material/Archive';
import UnarchiveIcon from '@mui/icons-material/Unarchive';

import { useInView } from "react-intersection-observer";

export default function Notification({ role, userId, item, index, formatDistanceToNow, deleteNotifHandler, archiveNotifHandler, unarchiveNotifHandler }) {
    const [ref, inView] = useInView({
        triggerOnce: true, // Mark as read once in view
    });

    const [deleteAnimation, setDeleteAnimation] = useState(false)

    const labelStyles = {
        'confirmed': {
            color: 'green',
            backgroundColor: '#cdefcd',
        },
        'completed': {
            color: '#06468b',
            backgroundColor: '#5ca3ef4f'
        },
        'declined': {
            color: 'red',
            backgroundColor: '#ff00001f',
        },
        'scheduled': {
            color: '#72541d',
            backgroundColor: '#ffa50033',
        },
        'rescheduled': {
            color: 'rgb(116, 75, 21)',
            backgroundColor: '#f3cd9c',
        },
        'action needed': {
            color: 'rgb(116, 75, 21)',
            backgroundColor: '#f3cd9c',
        },
        'reschedule request': {
            color: 'rgb(116, 75, 21)',
            backgroundColor: '#f3cd9c',
        },
        'partially confirmed': {
            color: 'green',
            backgroundColor: '#cdefcd',
        }
    }

    // delete notification
    const deleteHandler = async (id) => {
        setDeleteAnimation(true)
        const response = await deleteNotifHandler(id)
        if (response) {
            setDeleteAnimation(false)
        }
    }

    // archive notification
    const archiveHandler = async (id) => {
        setDeleteAnimation(true)
        const response = await archiveNotifHandler(id)
        if (response) {
            setDeleteAnimation(false)
        }
    }

    // unarchive notification
    const unArchiveHandler = async (id) => {
        setDeleteAnimation(true)
        const response = await unarchiveNotifHandler(id)
        if (response) {
            setDeleteAnimation(false)
        }
    }

    async function makeNotificationRead() {
        try {
            const { _id } = item
            const newData = {
                role, inView, index
            }
            await axios.post(`/api/admin/update-notif/${userId}/${_id}`, newData)
        } catch (err) {
            console.log(err)
        }

    }

    useEffect(function () {
        makeNotificationRead()
    }, [inView])

    return (
        <Card
            ref={ref}
            className={`${deleteAnimation ? 'delete-archive-animation' : 'no-delete'}  ${item.read ? "read-notif" : "unread-notif"}`}
        >
            <CardContent>
                <div className="total-container">
                    <div className={`interview-label`} style={labelStyles[item.label]}>
                        {item.label}
                    </div>
                    <div className='notif-title-container'>
                        <span className="unread-dot" style={{ display: `${item.read && 'none'}` }}></span>
                        <h6 className='notification-title' >
                            {item?.title}
                        </h6>
                    </div>
                </div>

                <p style={{ marginBottom: "5px", marginRight: "10px" }}>
                    {item.message}
                </p>

                <div className="notification-bottom-container">

                    <p style={{ fontSize: "9px", marginBottom: "10px", marginTop: "15px" }}>
                        {formatDistanceToNow(new Date(item.createdAt), { addSuffix: true })}
                    </p>

                    <div className="notification-action-container">
                        {item?.isArchived ? <Tooltip title="Unarchive" placement='top'>
                            <UnarchiveIcon
                                className='notification-action-btn'
                                fontSize='small'
                                onClick={() => unArchiveHandler(item._id)}
                            />
                        </Tooltip> : <Tooltip title="Archive" placement='top'>
                            <ArchiveIcon
                                fontSize='small'
                                className='notification-action-btn'
                                onClick={() => archiveHandler(item._id)}
                            />
                        </Tooltip>}
                        <Tooltip title="Delete" placement='top'>
                            <DeleteIcon
                                className='notification-action-btn'
                                fontSize='small'
                                onClick={() => deleteHandler(item._id)}
                            />
                        </Tooltip>
                    </div>
                </div>
            </CardContent>
        </Card>
    )
}
