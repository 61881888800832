import { connect } from "react-redux";
import { receiveAllInterviews, sendConfirmationInterview, sendRescheduleInterview,sendCompleteInterview } from "../../actions/Interviews";
import { receiveAllReviews } from "../../actions/reviewActions";
import SalesforceInterviews from "./salesforceInterviews";

const mapStateToProps = (state) => ({
    auth: state.auth,
    interviews: state.interviews,
    reviews: state.reviews,
});

const mapDispatchToProps = (dispatch) => ({
    getInterviews: (screenerId) => dispatch(receiveAllInterviews(screenerId)),
    getReviews: (screenerId) => dispatch(receiveAllReviews(screenerId)),
    updateConfirmStatus: (idx, enqueueSnackbar) => dispatch(sendConfirmationInterview(idx, enqueueSnackbar)),
    updateRescheduleStatus: (idx, enqueueSnackbar) => dispatch(sendRescheduleInterview(idx, enqueueSnackbar)),
    updateCompleteStatus: (idx, enqueueSnackbar) => dispatch(sendCompleteInterview(idx, enqueueSnackbar)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SalesforceInterviews);