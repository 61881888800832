import React, { useEffect, useState, useRef, useDebugValue } from "react";
import axios from "axios";
import * as XLSX from "xlsx";
import { useHistory } from 'react-router-dom';


import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import TablePagination from "@mui/material/TablePagination";
import Tooltip from "@mui/material/Tooltip";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import DeleteMessageIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { Dialog, DialogContent } from "@mui/material";

import DeleteIcon from '@mui/icons-material/Delete';
import InActiveIcon from '@mui/icons-material/DomainDisabled';
import ActiveIcon from '@mui/icons-material/Business';
import CompanyMenu from "../../userProfiles/CompanyMenu";
import TableLoader from "../../loaders/TableLoader";
import AddPositions from "./Add Positions/AddPositions";
import UserSnackBar from "../../snackBars/UserSnackbar";
import EditPositions from "./Add Positions/EditPositions";
import Footer from "../../../Footer/Footer";



const PositionsList = () => {
    //table heading
    const columns = [
        {
            title: "Position ID",
            width: "15%",
        },
        {
            title: "Position Title",
            width: "25%",
        },
        {
            title: "Client",
            width: "25%",
        },
        // {
        //     title: "Number of positions",
        //     width: "15%",
        // },
        {
            title: "Skills",
            width: "30%",
        },
        {
            title: "Pay Rate",
            width: "15%",
        },
        {
            title: "Status",
            width: "15%",
        },
        // {
        //     title: "Action",
        //     width: "15%",
        // },


    ];


    //Account Id and role
    const id = sessionStorage.getItem("id");
    const companyId = sessionStorage.getItem("companyId");  //ref id
    const role = sessionStorage.getItem("role");


    //Navbar states
    const [open, setOpen] = useState();
    const [sidebarOpen, setsidebarOpen] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [interacted, setInteracted] = useState("");
    const [croppedImg, setCroppedImg] = useState("");
    const [profileImage, setProfileImage] = useState("");
    const [defaultAvatar, setDefaultAvatar] = useState("");
    let profileAvatar =
        "https://res.cloudinary.com/df2qsnyox/image/upload/v1681133496/Default%20Profile%20Avatar/profile-avatar_ogxozq_ranghl.png";
    //handle states
    const [addPosition, setAddPosition] = useState(false);
    const [mode, setMode] = useState("");
    const [selectedPositionId, setSelectedPositionId] = useState("")
    const [selectedPositionStatus, setSelectedPositionStatus] = useState(null)
    const [selectedPositionData, setSelectedPositionData] = useState({})


    // filter states
    const [statusSelected, setStatusSelected] = useState(true);
    const [filter, setFilter] = useState("");


    // array to store list of clients
    const [allPositions, setAllPositions] = useState([]);
    const [filteredPositions, setFilteredPositions] = useState([])


    // snackbar
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [message, setMessage] = useState("")
    const [companyEmail, setCompanyEmail] = useState("")
    //Loader
    const [tableLoading, setTableLoading] = useState(true);


    //pagination states
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [page, setPage] = React.useState(0);

    //Confirmation dialog state
    const [openCloseDialog, setOpenCloseDialog] = useState(false)


    useEffect(() => {
        document.title = "Screener | Positions";
        const { data } = axios.get(`/api/admin/${id}`).then((res) => {
            const {
                defaultAvatar,
                profileImage,
                croppedImage,
                interacted,
                companyName, companyEmail
            } = res.data.user;
            setDefaultAvatar(defaultAvatar);
            setProfileImage(profileImage);
            setCroppedImg(croppedImage);
            setInteracted(interacted);
            setCompanyEmail(companyEmail)
            setCompanyName(companyName);
        });
    }, []);


    // fetching all clients
    useEffect(() => {
        axios.get(`/api/admin/get-all-positions/${id}`).then((res) => {
            console.log(res)
            setTableLoading(false);
            setAllPositions(res.data.position)
        })
            .catch(err => {
                console.log(err)
            })
    }, [addPosition, selectedPositionId])



    console.log(allPositions)


    //post text to backend
    const sendFilter = async () => {
        console.log("Called")
        const { data } = await axios.get(`/api/admin/get-all-positions/${id}`)
        console.log(data)
        if (data.position.length > 0) {

            let filteredArray = [...data.position]
            console.log(filter, filteredArray, "Filter")
            filter && (filteredArray = filteredArray.filter(item => (item.position.toLowerCase().includes(filter.toLowerCase()) || (item.client.name.toLowerCase().includes(filter.toLowerCase())))))
            console.log(filter, filteredArray, "Filter")


            setAllPositions(filteredArray)
        }


    };


    useEffect(() => {
        setPage(0);
        sendFilter();
    }, [filter]);


    //pagination handler
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    // handler to open add client page
    const handleOpenAddPosition = () => {
        setAddPosition(true)
        setMode("Add");
    }


    const handleOpenEditPosition = (itemId) => {
        setAddPosition(true);
        setMode("Edit");
        setSelectedPositionId(itemId);
    }


    const backHandler = () => {
        setAddPosition(false);
        setMode("");
        setSelectedPositionId("");
        setSelectedPositionData({})
    };

    const onCloseCancel = () => {
        setOpenCloseDialog(false)
        setSelectedPositionId("")
        setSelectedPositionStatus(null)
    }

    return (
        <div>
            {/* Navbar Component */}
            <CompanyMenu
                open={open}
                setOpen={setOpen}
                sidebarOpen={sidebarOpen}
                setsidebarOpen={setsidebarOpen}
                companyName={companyName}
                croppedImg={croppedImg}
                profileImage={profileImage}
                interacted={interacted}
                defaultAvatar={defaultAvatar}
            />
            <div style={{ marginLeft: `${sidebarOpen === "sidebar-open" ? "200px" : "0px"}` }}>
                <Box sx={{ flexGrow: 1, background: "#faf9f6" }}>
                    <Card id="main-edit" className="add-recruiter-form form-card">
                        <Grid
                            container
                            spacing={0}
                            // direction="row"
                            className="add-recruiter-card-container add-clients-container"
                        >
                            <Grid
                                item
                                xs={4}
                                sm={4}
                                md={6}
                                lg={6}
                                className="container-heading-title admin-table"
                            >
                                <h1>Positions</h1>
                            </Grid>
                            <Grid
                                item
                                xs={7}
                                sm={7}
                                md={5}
                                lg={5}
                                className="container-heading-buttons add-recruiter-button"
                                style={{ width: "100%" }}
                            >
                                <div style={{ display: "flex", gap: "10px", alignItems: "center", width: "100%", justifyContent: "flex-end" }}>


                                    {addPosition ?
                                        <button className="add-recruiter add-multiple" onClick={backHandler}>
                                            Back
                                        </button> :
                                        <>
                                            <button
                                                className="add-recruiter"
                                                onClick={handleOpenAddPosition}
                                            >
                                                Add Position
                                            </button>
                                        </>
                                    }
                                </div>
                            </Grid>
                        </Grid>
                        {addPosition ? "" :
                            <Grid
                                container
                                spacing={1}
                                direction="row"
                            // className="profile-progress-bar"
                            >
                                <Grid
                                    id="profile-completion-percentage"
                                    item
                                    xs={12}
                                    sm={5}
                                    md={5}
                                    lg={4}
                                    sx={{ textAlign: "center" }}
                                    className="container-profile"
                                >
                                    <form className="experience-edit-form" noValidate>
                                        <div class="add-recruiter-input-container">
                                            <div class="input-field">
                                                <input
                                                    class="position"
                                                    type="text"
                                                    defaultValue={filter}
                                                    onChange={(e) => setFilter(e.target.value)}
                                                    placeholder="Search by Client or Position"
                                                />
                                            </div>


                                        </div>
                                    </form>
                                </Grid>
                            </Grid>
                        }
                    </Card>


                    {addPosition ?
                        mode === "Add" ?
                            <AddPositions setAddPosition={setAddPosition} companyName={companyName} companyEmail={companyEmail} setAllPositions={setAllPositions} /> :



                            <EditPositions setAllPositions={setAllPositions} setAddPosition={setAddPosition} selectedPositionId={selectedPositionId} id={id} />
                        :
                        // table view of clients list
                        (<Card
                            id="skills-edit"
                            className="add-recruiter-form-container"
                            style={{ boxShadow: "none", borderRadius: "2px", position: "relative" }}
                        >
                            <div className="position-list">
                                <div
                                    className="position-list-heading"
                                >
                                    {columns.map((item, index) => {
                                        return (
                                            <>
                                                {item.title === "Position ID" ?

                                                    <p
                                                        className={`${sidebarOpen == 'sidebar-open' ? "name-heading-positions-no-scroll-sidebar-open" : "name-heading-positions-no-scroll"}`}
                                                    >
                                                        {item.title}
                                                    </p> :
                                                    <p style={{
                                                        width: `${item.width}`,
                                                        background: index % 2 ? "rgba(240, 97, 97, 37%)" : "rgba(240, 97, 97, 17%)",
                                                        color: "#283848",
                                                        marginLeft: `${item.title === "Position Title" ? "9.7%" : ""}`
                                                    }}
                                                    >
                                                        {item.title}
                                                    </p>}
                                            </>
                                        );
                                    })}
                                </div>
                                {tableLoading ? (
                                    <TableLoader page="subscriberList" />
                                ) : (<div className="position-details-list">
                                    {allPositions.length > 0 ? (
                                        allPositions
                                            .slice(
                                                page * rowsPerPage,
                                                page * rowsPerPage + rowsPerPage
                                            )
                                            .map((item, index) => {
                                                return (
                                                    <>
                                                        <div
                                                            style={{
                                                                justifyContent: 'normal'
                                                            }}
                                                            className={` recruiter-details positions-row ${index % 2
                                                                ? ""
                                                                : "alternate-recruiterlist-bg"
                                                                }`}
                                                        >
                                                            <div
                                                                className={`interview-id position-id ${index % 2
                                                                    ? `${sidebarOpen == 'sidebar-open' ? "name-positions-no-scroll-even-sidebar-open" : "name-positions-no-scroll-even"}`
                                                                    : `${sidebarOpen == 'sidebar-open' ? "name-positions-no-scroll-odd-sidebar-open" : "name-positions-no-scroll-odd"}`
                                                                    }`}
                                                                style={{ fontWeight: "bold" }}
                                                            >
                                                                <p style={{ textAlign: 'center' }} onClick={() => handleOpenEditPosition(item._id)}>POS-{item._id.slice(19, item._id.length)}</p>
                                                            </div>

                                                            <div className="position-name" style={{
                                                                width: '26%',
                                                                marginLeft: "16%"
                                                            }}>
                                                                {item.position.length > 24 ? (
                                                                    <Tooltip title={`${item.position || "NA"}`}>
                                                                        <p >{item.position || "Name NA"}</p>
                                                                    </Tooltip>
                                                                ) :
                                                                    <p >{item.position}</p>
                                                                }
                                                            </div>


                                                            <div className="position-client-name" style={{ width: '20%' }}>
                                                                {item.client.name.length > 24 ? (
                                                                    <Tooltip title={item.client.name}>
                                                                        <p>{item.client.name}</p>
                                                                    </Tooltip>
                                                                ) :
                                                                    <p>{item.client.name}</p>
                                                                }
                                                            </div>

                                                            <div className="position-skills" style={{ width: '24%' }}>

                                                                <p style={{ display: 'flex', flexWrap: 'wrap' }}>
                                                                    {item.skills.map((el, i) => {
                                                                        return <p style={{ marginRight: '3px' }}>{el}{i != item.skills.length - 1 && <span>{", "}</span>}</p>
                                                                    })}
                                                                </p>

                                                            </div>

                                                            <div className="position-rate" style={{ width: '15%' }}>
                                                                <p>$ {item.rate}</p>
                                                            </div>


                                                            <div className="position-status" style={{ width: '7%' }}>
                                                                <div className="user-status-button" >
                                                                    <button className={item.status === "Active" ? "Active" : "Frozen"}>
                                                                        {item.status}
                                                                    </button>
                                                                </div>
                                                            </div>

                                                            {/* <div className="action-buttons" style={{ marginRight: '0px', justifyContent: 'center', gap: '10px' }}>
                                                                <div className="action-button-icon">
                                                                    {item.status ? (
                                                                        <Tooltip title="Inactive">
                                                                            <ActiveIcon
                                                                                fontSize="small"
                                                                            // onClick={() =>
                                                                            //     actionHandler(item._id, item.status, 'status')
                                                                            // }
                                                                            />
                                                                        </Tooltip>
                                                                    ) : (
                                                                        <Tooltip title="Active">
                                                                            <InActiveIcon
                                                                                fontSize="small"
                                                                            // onClick={() =>
                                                                            //     actionHandler(item._id, item.status, 'status')
                                                                            // }
                                                                            />
                                                                        </Tooltip>
                                                                    )}
                                                                </div>
                                                                <div className="action-button-icon">
                                                                    <Tooltip title="Delete">
                                                                        <DeleteIcon
                                                                            fontSize="small"
                                                                        // onClick={() =>
                                                                        //     actionHandler(item._id, item.status, 'delete')
                                                                        // }
                                                                        />
                                                                    </Tooltip>
                                                                </div>
                                                            </div> */}
                                                        </div>


                                                    </>
                                                );
                                            })
                                    ) : (
                                        <p>No Positions</p>
                                    )}
                                </div>
                                )}
                            </div>
                            <TablePagination
                                id="recruiter-list-pagination"
                                component="div"
                                rowsPerPageOptions={[10, 25, 50, 100]}
                                count={allPositions.length}
                                page={page}
                                onPageChange={handleChangePage}
                                rowsPerPage={rowsPerPage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                variant="outlined"
                            />
                        </Card>
                        )}
                </Box>



            </div >
            < UserSnackBar
                openSnackbar={openSnackbar}
                setOpenSnackbar={setOpenSnackbar}
                message={message}
            />

            <Footer sidebarOpen={sidebarOpen} />
        </div >
    )
}


export default PositionsList

