import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";
import CreateEvent from "./createEvent";
import Calendar from "react-big-calendar";
import moment from "moment";
import withStyles from "@material-ui/core/styles/withStyles";
import "react-big-calendar/lib/css/react-big-calendar.css";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import axios from "axios";

const localizer = Calendar.momentLocalizer(moment);

const styles = theme => ({
  card_calendar: {
    margin: "auto",
    width: "auto",
    height: "120%"
  },
  card_event: {
    margin: "auto",
    width: "auto",
    height: "120%"
  },
  cardHeader: {
    background: "#90a4ae"
  },
  title: {
    color: "white"
  }
});

class CalendarEvent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      events: [
        {
          start: "",
          end: "",
          title: "",
          screeners: "",
          timeZone: "",
          description: ""
        }
      ]
    };
  }

  componentDidMount() {
    axios
      .get("/api/schedule/events/" + this.props.auth.user.id)
      .then(response => {
        const newEvent = [];
        response.data.events.forEach(
          ({ start, end, title, timeZone, description }) => {
            start = new Date(start);
            end = start;
            newEvent.push({ start, end, title, timeZone, description });
          }
        );
        this.setState({
          events: newEvent
        });
      });
  }

  onLogoutClick = e => {
    e.preventDefault();
    this.props.logoutUser();
  };
  render() {
    const { classes } = this.props;
    return (
      <div>
        <br />
        <main className={classes.main}>
          <Grid container spacing={24}>
            <Grid item xs={1} />
            <Grid item xs={5}>
              <Card className={classes.card_event}>
                <CardHeader
                  align="center"
                  className={classes.cardHeader}
                  classes={{ title: classes.title }}
                  title="Add New Event"
                />{" "}
                &nbsp;
                <CreateEvent />
              </Card>
            </Grid>


            <Grid item xs={5} align="center">
              <Card className={classes.card_calendar}>
                <CardHeader
                  align="center"
                  className={classes.cardHeader}
                  classes={{ title: classes.title }}
                  title="Schedule"
                />{" "}
                &nbsp;
                <Calendar
                  localizer={localizer}
                  defaultDate={new Date()}
                  defaultView="month"
                  events={this.state.events}
                  style={{ height: "50vh" }}
                />
              </Card>
            </Grid>
          </Grid>
        </main>
      </div>
    );
  }
}
CalendarEvent.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  auth: state.auth
});

export default withStyles(styles)(
  connect(
    mapStateToProps,
    { logoutUser }
  )(CalendarEvent)
);
