import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import axios from "axios";
import Button from "@material-ui/core/Button";
import { withSnackbar, useSnackbar } from "notistack";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import { Divider, makeStyles } from "@material-ui/core";
import dateFormat from "dateformat";
import { useConfirm } from "material-ui-confirm";
import "../recruiters/tableList.css";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Stack from "@mui/material/Stack";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { id } from "date-fns/locale";
import gmeet from "../../images/gmeetLogo.png";
import zoom from "../../images/zoomLogo.png";

const ScreenerInterviewList = (props) => {
  useEffect(() => {
    axios.get("api/event/interviews").then((response) => {
      setInterviewList(response.data);
    });

    axios.get("api/users/screener").then((response) => {
      setScreenerList(response.data);
    });

    axios.get("api/users/candidate").then((response) => {
      setCandidateList(response.data);
    });
  }, []);

  const [interviewList, setInterviewList] = useState([]);
  const [screenerList, setScreenerList] = useState([]);
  const [candidateList, setCandidateList] = useState([]);
  const [data, setData] = useState(interviewList);

  const confirm = useConfirm();
  const { enqueueSnackbar } = useSnackbar();
  // console.log("InterviewList", interviewList)
  // console.log("ScreenerList", screenerList)
  // this.state = { showSubmission: true };
  //const [showButton, setShowButton] = React.useState(true);
  // const { user } = props;
  // const screenerid = user._id;
  // console.log(screenerid);
  // console.log(user);

  const useStyles = makeStyles((theme) => ({
    main: {
      width: "auto",
      display: "block", // Fix IE 11 issue.
      marginTop: theme.spacing(-2),
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
      [theme.breakpoints.up(400 + theme.spacing(6))]: {
        width: "50%",
        marginLeft: "auto",
        marginRight: "auto",
      },
    },
    paper: {
      marginTop: theme.spacing(-5),
      display: "flex",
      width: "100%",
      flexDirection: "column",
      alignItems: "center",
      padding: `${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(
        -15
      )}px`,
    },
    button: {
      padding: theme.spacing(2),
      margin: 10,
      alignItems: "center",
    },

    textField: {
      marginLeft: theme.spacing(),
      marginRight: theme.spacing(),
      width: "100px",
    },

    textFieldBox: {
      marginTop: theme.spacing() * 1,
      marginLeft: theme.spacing(),
      marginRight: theme.spacing(),
      width: "500px",
    },
    select: {
      marginTop: theme.spacing() * 1,
      marginLeft: theme.spacing(),
      marginRight: theme.spacing(),
      width: "250px",
    },
    formControl: {
      margin: theme.spacing(),
      minWidth: 120,
      maxWidth: 300,
    },
  }));

  const {
    auth: { user },
  } = props;
  console.log(user);
  const userId = user.id;
  console.log(userId);

  const [infoReschedule, setInfoReschedule] = useState({
    id: "",
    status: "",
  });

  const [infoDecline, setInfoDecline] = useState({
    id: "",
    status: "",
  });

  const [infoConfirm, setInfoConfirm] = useState({
    id: "",
    status: "",
    stage: "",
    interviewId: "",
    title: "",
    description: "",
    screenerName: "",
    screenerEmail: "",
    interviewDate: "",
    interviewStartTime: "",
    interviewEndTime: "",
    formattedDate: "",
    formattedStartTime: "",
    formattedEndTime: "",
    interviewStatus: "",
    interviewStage: "",
    meetLink: "",
    candidateName: "",
    candidateEmail: "",
  });

  const [infoComplete, setInfoComplete] = useState({
    id: "",
    status: "",
  });

  const [openReschedule, setOpenReschedule] = useState(false);
  const [openDecline, setOpenDecline] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openComplete, setOpenComplete] = useState(false);
  const [interviewId, setInterviewId] = useState();
  // const [setInfoConfirm, setsetInfoConfirm] = useState({
  //   id: "",
  //   title: "",
  //   description: "",
  //   screenerName: "",
  //   screenerMail: "",
  //   interviewDate: "",
  //   interviewStartTime: "",
  //   interviewEndTime: "",
  //   interviewStatus: "",
  //   interviewStage: ""
  // })
  const classes = useStyles();

  const handleCloseReschedule = () => {
    setOpenReschedule(false);
  };

  const handleCloseDecline = () => {
    setOpenDecline(false);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const handleCloseComplete = () => {
    setOpenComplete(false);
  };

  const handleClose = () => {
    setOpenConfirm(false);
  };

  const handleClickOpenReschedule = (id) => {
    const arrInterview = interviewList.filter((item) => {
      return item._id === id;
    });

    setInfoReschedule({
      id: arrInterview[0]._id,
      status: arrInterview[0].interviewstatus,
    });
    setOpenReschedule(true);
  };

  const handleClickOpenDecline = (id) => {
    const arrInterview = interviewList.filter((item) => {
      return item._id === id;
    });

    setInfoDecline({
      id: arrInterview[0]._id,
      status: arrInterview[0].interviewstatus,
    });
    setOpenDecline(true);
  };

  const handleClickOpenConfirm = async (id) => {
    const arrInterview = interviewList.filter((item) => {
      return item._id === id;
    });

    console.log(arrInterview[0]);

    // console.log(arrInterview[0].candidate);
    const arrCandidate = candidateList.filter((item) => {
      return item._id === arrInterview[0].candidate;
    });

    console.log(arrCandidate);
    const selectedInterview = rows.filter((item) => {
      return item.id === id;
    });
    console.log(selectedInterview[0]);
    // console.log(id, selectedInterview[0].id, selectedInterview[0].title,selectedInterview[0].description,selectedInterview[0].screener)
    setInfoConfirm({
      id: selectedInterview[0].id,
      interviewId: selectedInterview[0].interviewId,
      title: selectedInterview[0].title,
      description: selectedInterview[0].description || "N/A",
      screenerName: selectedInterview[0].screener,
      screenerEmail: selectedInterview[0].email,
      interviewDate: arrInterview[0].startdate,
      interviewStartTime: arrInterview[0].starttime,
      interviewEndTime: arrInterview[0].endtime,
      formattedDate: selectedInterview[0].startdate,
      formattedStartTime: selectedInterview[0].starttime,
      formattedEndTime: selectedInterview[0].endtime,
      interviewStatus: selectedInterview[0].interviewstatus,
      interviewStage: selectedInterview[0].interviewstage,
      candidateName: arrCandidate[0].name,
      candidateEmail: arrCandidate[0].email,
    });
    setOpenConfirm(true);
    // setInfoConfirm({
    //   id: arrInterview[0]._id,
    //   status: arrInterview[0].interviewstatus,
    //   stage: arrInterview[0].interviewstage,
    // })
  };
  console.log(infoConfirm);

  const handleClickOpenComplete = (id) => {
    const arrInterview = interviewList.filter((item) => {
      return item._id === id;
    });
    setInfoComplete({
      id: arrInterview[0]._id,
      status: arrInterview[0].interviewstatus,
    });
    setOpenComplete(true);
  };

  const onReschedule = (id, interviewstatus) => {
    //var result = window.confirm("Are you sure you want to reschedule the interview?");
    // confirm({ description: `You want to Reschedule the interview ` })

    //   .then(() => {
    interviewstatus =
      interviewstatus == "Schedule Awaiting Confirmation"
        ? "Reschedule"
        : "Schedule Awaiting Confirmation";
    var interview = {
      interviewstatus,
    };
    axios
      .put("/api/event/statuschange/" + interviewstatus + "/" + id, interview)
      .then((res) => {
        let regMessage = <span>Reschedule sent successfully</span>;
        enqueueSnackbar(regMessage, {
          variant: "success",
          autoHideDuration: 3000,
        });
        window.location.reload(false);
      })
      .catch((err) => {
        console.log(err.response);
        //alert('An error occured try submitting the form again');
        let regMessage = <span> An error occured try submitting again</span>;
        enqueueSnackbar(regMessage, {
          variant: "error",
          // autoHideDuration: 3000,
        });
      });
    // }).catch(() => console.log("Rescheduled"));

    //setOpen(false);

    //window.location.reload(false);
  };

  const onConfirm = (id, interviewstatus, interviewstage, infoConfirm) => {
    // confirm({ description: `You want to Confirm the interview ` })
    //   .then(() => {
    console.log(infoConfirm.meetLink);

    interviewstatus =
      interviewstatus == "Schedule Awaiting Confirmation"
        ? "Schedule Confirmed"
        : "Schedule Awaiting Confirmation";
    interviewstage = "Scheduled Screening";
    var interview = {
      interviewstatus,
      interviewstage,
    };
    if (!infoConfirm.meetLink) {
      let regMessage = <span>Please fill the meeting link.</span>;
      enqueueSnackbar(regMessage, {
        variant: "error",
        autoHideDuration: 3000,
      });
      setOpenConfirm(true);
    } else {
      axios
        .put(
          "/api/event/statuschange/" +
            interviewstatus +
            "/" +
            interviewstage +
            "/" +
            id,
          { interview, infoConfirm }
        )
        .then((res) => {
          // const index = interviewList.data.findIndex((interview) => interview._id === res.data._id);
          // interviewList.data[index].interviewstatus = interviewstatus;
          // setData(interviewList=>({
          //   ...interviewList,
          //   [index]: res.data.interviewstatus
          // }))

          // interviewstatus = (interviewstatus == "Schedule Confirmed"? "Schedule Confirmed" : "Schedule Awaiting Confirmation");
          //alert('Confirmed successfully');
          let regMessage = <span>Confirmed successfully</span>;
          enqueueSnackbar(regMessage, {
            variant: "success",
            autoHideDuration: 3000,
          });
          window.location.reload(false);
        })
        .catch((err) => {
          console.log(err.response);
          //alert('An error occured try submitting the form again');
          let regMessage = (
            <span> An error occured try submitting the again</span>
          );
          enqueueSnackbar(regMessage, {
            variant: "error",
            autoHideDuration: 3000,
          });
        });
      setInterviewId(id);
    }
    // }).catch(() => console.log("Confirmed"));
    // window.location.reload(false);
  };

  const onDecline = (id, interviewstatus) => {
    //var result = window.confirm("Are you sure you want to reschedule the interview?");
    // confirm({ description: `Do you want to decline the interview instead of trying to reschedule? ` })

    //   .then(() => {
    interviewstatus =
      interviewstatus == "Schedule Awaiting Confirmation"
        ? "Interview Declined"
        : "Schedule Awaiting Confirmation";
    var interview = {
      interviewstatus,
    };
    axios
      .put("/api/event/statuschange/" + interviewstatus + "/" + id, interview)
      .then((res) => {
        // const index = interviewList.data.findIndex((interview) => interview._id === res.data._id);
        // interviewList.data[index].interviewstatus = interviewstatus;
        // setData(interviewList=>({
        //   ...interviewList,
        //   [index]: res.data.interviewstatus
        // }))

        //interviewstatus = (interviewstatus == "Schedule Awaiting Confirmation"? "Reschedule" : "");
        //alert('Reschedule sent successfully');
        let regMessage = <span>Declined Interview successfully</span>;
        enqueueSnackbar(regMessage, {
          variant: "success",
          autoHideDuration: 3000,
        });
        window.location.reload(false);
      })
      .catch((err) => {
        console.log(err.response);
        //alert('An error occured try submitting the form again');
        let regMessage = <span> An error occured try submitting again</span>;
        enqueueSnackbar(regMessage, {
          variant: "error",
          // autoHideDuration: 3000,
        });
      });
    // }).catch(() => console.log("Declined"));

    //setOpen(false);

    //window.location.reload(false);
  };

  const onComplete = (id, interviewstatus) => {
    //var result = window.confirm("Are you sure you want to reschedule the interview?");
    // confirm({ description: `You want to Complete the interview ` })

    //   .then(() => {
    interviewstatus =
      interviewstatus == "Schedule Confirmed"
        ? "Completed"
        : "Schedule Confirmed";
    var interview = {
      interviewstatus,
    };
    axios
      .put("/api/event/statuschange/" + interviewstatus + "/" + id, interview)
      .then((res) => {
        // const index = interviewList.data.findIndex((interview) => interview._id === res.data._id);
        // interviewList.data[index].interviewstatus = interviewstatus;
        // setData(interviewList=>({
        //   ...interviewList,
        //   [index]: res.data.interviewstatus
        // }))

        //interviewstatus = (interviewstatus == "Schedule Awaiting Confirmation"? "Reschedule" : "");
        //alert('Reschedule sent successfully');
        let regMessage = <span>Completed Interview successfully</span>;
        enqueueSnackbar(regMessage, {
          variant: "success",
          autoHideDuration: 3000,
        });
        window.location.reload(false);
      })
      .catch((err) => {
        console.log(err.response);
        //alert('An error occured try submitting the form again');
        let regMessage = <span> An error occured try submitting again</span>;
        enqueueSnackbar(regMessage, {
          variant: "error",
          // autoHideDuration: 3000,
        });
      });
    // }).catch(() => console.log("Completed"));

    //setOpen(false);

    //window.location.reload(false);
  };

  const columns = [
    {
      field: "id",
      width: -1,
      flex: -1,
      hide: true,
    },
    {
      field: "interviewId",
      headerName: "Interview ID",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "title",
      headerName: "Title",
      minWidth: 150,
      flex: 0.5,
    },
    {
      field: "description",
      headerName: "Description",
      minWidth: 150,
      flex: 0.5,
    },

    {
      field: "screener",
      headerName: "Screener Name",
      minWidth: 150,
      flex: 0.5,
    },
    {
      field: "email",
      headerName: "Screener Email",
      minWidth: 150,
      flex: 0.5,
    },
    {
      field: "startdate",
      headerName: "Interview Date",
      minWidth: 150,
      flex: 0.5,
    },
    {
      field: "starttime",
      headerName: "Interview StartTime",
      minWidth: 150,
      flex: 0.5,
    },
    {
      field: "endtime",
      headerName: "Interview EndTime",
      minWidth: 150,
      flex: 0.5,
    },
    // {
    //   field: "timezone",
    //   headerName: "TimeZone",
    //   minWidth: 150,
    //   flex: 0.5,
    // },

    {
      field: "interviewstatus",
      headerName: "Interview Status",
      minWidth: 350,
      flex: 0.5,
    },
    {
      field: "interviewstage",
      headerName: "Interview Stage",
      minWidth: 250,
      flex: 0.5,
    },

    {
      field: "actions",
      flex: 0.3,
      headerName: "Actions",
      minWidth: 600,
      // type: "number",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <span>
              {params.getValue(params.id, "interviewstatus") == "Reschedule"
                ? "Wait untill we reschedule the interview"
                : null}
            </span>
            <span>
              {params.getValue(params.id, "interviewstatus") ==
              "Interview Declined"
                ? "Interview is Declined"
                : null}
            </span>
            <Link to={`/Assessment/${params.getValue(params.id, "id")}`}>
              {params.getValue(params.id, "interviewstatus") == "Completed"
                ? "Review"
                : null}
            </Link>
            <Link to={`/Assessment/${params.getValue(params.id, "id")}`}>
              {params.getValue(params.id, "interviewstatus") ==
              "Completed With Feedback"
                ? "Update Review"
                : null}
            </Link>
            {/* <Button onClick={() => onReschedule(params.id, params.getValue(params.id, "interviewstatus"))}>{params.getValue(params.id, "interviewstatus") == "Schedule Awaiting Confirmation" ? "Reschedule" : null}</Button>&nbsp;&nbsp;&nbsp;&nbsp; */}

            <Stack spacing={2} direction="row">
              {params.getValue(params.id, "interviewstatus") ==
              "Schedule Awaiting Confirmation" ? (
                <Button
                  style={{
                    backgroundColor: "#75A478",
                    color: "white",
                  }}
                  size="small"
                  onClick={() =>
                    handleClickOpenReschedule(params.getValue(params.id, "id"))
                  }
                >
                  Reschedule
                </Button>
              ) : null}
              {/* onReschedule(params.id, params.getValue(params.id, "interviewstatus"))} */}

              {params.getValue(params.id, "interviewstatus") ==
              "Schedule Awaiting Confirmation" ? (
                <Button
                  style={{
                    backgroundColor: "#75A478",
                    color: "white",
                  }}
                  size="small"
                  onClick={() =>
                    handleClickOpenDecline(params.getValue(params.id, "id"))
                  }
                >
                  Decline
                </Button>
              ) : null}
              {/* onDecline(params.id, params.getValue(params.id, "interviewstatus"))} */}
              {params.getValue(params.id, "interviewstatus") ==
              "Schedule Awaiting Confirmation" ? (
                <Button
                  style={{
                    backgroundColor: "#75A478",
                    color: "white",
                  }}
                  size="small"
                  onClick={() =>
                    handleClickOpenConfirm(params.getValue(params.id, "id"))
                  }
                >
                  Confirm
                </Button>
              ) : null}
              {/* onConfirm(params.id, params.getValue(params.id, "interviewstatus", "interviewstage")) */}
              {/* <Button onClick={() => onConfirm(params.id, params.getValue(params.id, "interviewstatus", "interviewstage"))}
            >{params.getValue(params.id, "interviewstatus") == "Schedule Awaiting Confirmation" ? "Confirm" : null}</Button>&nbsp;&nbsp;&nbsp;&nbsp; */}
              {/* <Button onClick={() => onDecline(params.id, params.getValue(params.id, "interviewstatus"))}>{params.getValue(params.id, "interviewstatus") == "Schedule Awaiting Confirmation" ? "Decline" : ""}</Button> */}

              {/* <span>{ params.getValue(params.id, "interviewstatus") == "Schedule Confirmed"? "Interview is confirmed" :null }</span> */}
            </Stack>
            {params.getValue(params.id, "interviewstatus") ==
            "Schedule Confirmed" ? (
              <Button
                style={{
                  backgroundColor: "#75A478",
                  color: "white",
                }}
                size="small"
                onClick={() =>
                  handleClickOpenComplete(params.getValue(params.id, "id"))
                }
              >
                Mark as completed
              </Button>
            ) : null}
            {/* <Button style={{ width: "fit-content"}} variant="contained" color="inherit" onClick={() => onComplete(params.id, params.getValue(params.id, "interviewstatus"))}>{params.getValue(params.id, "interviewstatus") == "Schedule Confirmed" ? "Interview Conducted" : ""}</Button> */}
            {/* onComplete(params.id, params.getValue(params.id, "interviewstatus")) */}
          </>
        );
      },
    },
  ];

  const rows = [];
  let startFormat = "";
  let endFormat = "";
  console.log(infoConfirm);
  interviewList &&
    interviewList.forEach((item) => {
      // if (screenerid === item.screener) {
      screenerList &&
        screenerList.forEach((screener) => {
          if (item.screener === userId && screener._id === userId) {
            //deviceRegion
            const deviceRegion = Intl.DateTimeFormat().resolvedOptions()
              .timeZone;
            if (deviceRegion === "America/New_York") {
              startFormat = dateFormat(item.starttime, "hh:MM TT ") + " EST";
              endFormat = dateFormat(item.endtime, "hh:MM TT ") + " EST";
            } else {
              startFormat = dateFormat(item.starttime, "hh:MM TT ") + " IST";
              endFormat = dateFormat(item.endtime, "hh:MM TT ") + " IST";
            }
            console.log(item._id);
            console.log(user._id);
            console.log(screener.name);
            console.log(item.title);
            console.log(item.screener);
            console.log(item.interviewstatus);
            console.log(item.startdate);
            rows.push({
              id: item._id,
              interviewId: item.interviewId,
              title: item.title,

              // timezone: (item.timezone).toUpperCase(),
              description: item.description,
              screener: screener.name,
              email: screener.email,
              startdate: dateFormat(item.startdate, "mmmm d, yyyy"),
              starttime: startFormat,
              endtime: endFormat,
              interviewstatus: item.interviewstatus,
              interviewstage: item.interviewstage,
            });
            // } else {
            //   console.log("No interviews")
            // }
          }
        });
    });

  console.log(infoConfirm);
  return (
    <>
      <br /> <br /> <br /> <br /> <br /> <br />
      <br />
      <Typography variant="h6" style={{ alignItems: "center" }}>
        Scheduled Interviews
      </Typography>
      <Divider />
      <br />
      {/* {!hidden && <button onClick={() => setHidden(true)}>Click Me</button>} */}
      <br />
      <Dialog
        maxWidth="lg"
        fullWidth
        open={openConfirm}
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          {/* {interviewId} */}
          <form className={classes.form}>
            <Grid container spacing={24} style={{ padding: "2em" }}>
              <Grid item xs={12} sm={5}>
                <div>
                  <label>Interview Id</label>
                  <TextField
                    disabled
                    id="standard-disabled"
                    value={infoConfirm.interviewId}
                    variant="standard"
                    fullWidth
                  />
                </div>
              </Grid>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Grid item xs={12} sm={5}>
                <div>
                  <label>Interview Title</label>
                  <TextField
                    disabled
                    id="standard-disabled"
                    value={infoConfirm.title}
                    variant="standard"
                    fullWidth
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={5}>
                <div>
                  <label>Interview Description</label>
                  <TextField
                    disabled
                    id="standard-disabled"
                    value={infoConfirm.description}
                    variant="standard"
                    fullWidth
                  />
                </div>
              </Grid>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Grid item xs={12} sm={5}>
                <div>
                  <label>Screener Name</label>
                  <TextField
                    disabled
                    id="standard-disabled"
                    value={infoConfirm.screenerName}
                    variant="standard"
                    fullWidth
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={5}>
                <div>
                  <label>Screener Email</label>
                  <TextField
                    disabled
                    id="standard-disabled"
                    value={infoConfirm.screenerEmail}
                    variant="standard"
                    fullWidth
                  />
                </div>
              </Grid>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Grid item xs={12} sm={5}>
                <div>
                  <label>Interview Date</label>
                  <TextField
                    disabled
                    id="standard-disabled"
                    value={infoConfirm.formattedDate}
                    variant="standard"
                    fullWidth
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={5}>
                <div>
                  <label>Interview Start Time</label>
                  <TextField
                    disabled
                    id="standard-disabled"
                    value={infoConfirm.formattedStartTime}
                    variant="standard"
                    fullWidth
                  />
                </div>
              </Grid>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Grid item xs={12} sm={5}>
                <div>
                  <label>Interview End Time</label>
                  <TextField
                    disabled
                    id="standard-disabled"
                    value={infoConfirm.formattedEndTime}
                    variant="standard"
                    fullWidth
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={5}>
                <div>
                  <label>Interview Status</label>
                  <TextField
                    disabled
                    id="standard-disabled"
                    value={infoConfirm.interviewStatus}
                    variant="standard"
                    fullWidth
                  />
                </div>
              </Grid>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Grid item xs={12} sm={5}>
                <div>
                  <label>Interview Stage</label>
                  <TextField
                    disabled
                    id="standard-disabled"
                    value={infoConfirm.interviewStage}
                    variant="standard"
                    fullWidth
                  />
                </div>
              </Grid>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Grid item xs={12} sm={5}>
                <div>
                  <label>Select Platform*</label>
                  <div>
                    <a
                      href="https://apps.google.com/meet/"
                      target="_blank"
                      style={{ margin: "2px" }}
                    >
                      <img src={gmeet} alt="gmeet" />
                    </a>
                    <a href="https://zoom.us/" target="_blank">
                      <img src={zoom} alt="zoom" />
                    </a>
                  </div>
                  <TextField
                    // required
                    id="standard"
                    onChange={(e) => {
                      setInfoConfirm({
                        ...infoConfirm,
                        meetLink: e.target.value,
                      });
                    }}
                    variant="standard"
                    fullWidth
                  />
                </div>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            style={{ backgroundColor: "#D73B30", color: "white" }}
          >
            Back
          </Button>

          <Button
            onClick={() => {
              onConfirm(
                infoConfirm.id,
                infoConfirm.interviewStatus,
                infoConfirm.interviewStage,
                infoConfirm
              );
            }}
            style={{
              backgroundColor: "#75A478",
              color: "white",
              margin: "6px",
            }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <div className="myList">
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={10}
          disableSelectionOnClick
          className="tableList"
          autoHeight
        />

        {/* Reschedule Pop up */}
        <Dialog
          open={openReschedule}
          onClose={handleCloseReschedule}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Warning: Are You sure you want to reschedule the interview ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleCloseReschedule}
              style={{ backgroundColor: "#D73B30", color: "white" }}
            >
              Cancel
            </Button>

            {/* <Link
                  style={{ textDecoration: "none", padding: "10px" }}
                  to="/interview-list"
                > */}
            <Button
              style={{
                backgroundColor: "#75A478",
                color: "white",
              }}
              onClick={() =>
                onReschedule(infoReschedule.id, infoReschedule.status)
              }
            >
              Reschedule Interview
            </Button>
            {/* </Link> */}
          </DialogActions>
        </Dialog>

        {/* //Decline Popup */}
        <Dialog
          open={openDecline}
          onClose={handleCloseDecline}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Warning: Are You sure you want to decline the interview ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleCloseDecline}
              style={{ backgroundColor: "#D73B30", color: "white" }}
            >
              Cancel
            </Button>

            {/* <Link
                  style={{ textDecoration: "none", padding: "10px" }}
                  to="/interview-list"
                > */}
            <Button
              style={{
                backgroundColor: "#75A478",
                color: "white",
              }}
              onClick={() => onDecline(infoDecline.id, infoDecline.status)}
            >
              Decline Interview
            </Button>
            {/* </Link> */}
          </DialogActions>
        </Dialog>

        {/* Confirm Pop up */}
        {/* <Dialog
          open={openConfirm}
          onClose={handleCloseConfirm}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Warning: Are You sure you want to confirm the interview ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleCloseConfirm}
              style={{ backgroundColor: "#D73B30", color: "white" }}
            >
              Cancel
            </Button>


            <Button
              style={{
                backgroundColor: "#75A478",
                color: "white",
              }}
              onClick={() =>
                onConfirm(infoConfirm.id, infoConfirm.status, infoConfirm.stage)
              }
            >
              Confirm Interview
            </Button>
           
          </DialogActions>
        </Dialog> */}

        {/* Complete Pop up */}
        <Dialog
          open={openComplete}
          onClose={handleCloseComplete}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Warning: Are you sure you want to mark this interview as
              completed?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleCloseComplete}
              style={{ backgroundColor: "#D73B30", color: "white" }}
            >
              Cancel
            </Button>

            <Link
              style={{ textDecoration: "none", margin: "5px" }}
              to={`/Assessment/${infoComplete.id}`}
            >
              <Button
                style={{
                  backgroundColor: "#75A478",
                  color: "white",
                }}
                onClick={() => {
                  onComplete(infoComplete.id, infoComplete.status);
                }}
              >
                Complete & Review
              </Button>
            </Link>
            {/* </Link> */}
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

ScreenerInterviewList.propTypes = {
  auth: PropTypes.object.isRequired,
};

const msp = (state) => ({
  auth: state.auth,
});

export default connect(msp)(ScreenerInterviewList);
