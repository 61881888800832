import React, { useEffect, useState } from "react";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import { useParams } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Tooltip from "@mui/material/Tooltip";
import axios from "axios";
import '../CompanyAdmin.css'

//custom pdf viewer
const InternalPdfViewer = ({ history }) => {
    let { id, role } = useParams(); // fetching id and role from the parameters
    const [resume, setResume] = useState("");

    // useEffect(() => {
    //     if (firstName) document.title = `Screener |  ${firstName}'s Resume`;
    // }, [firstName]);

    function fetchUsers() {
        axios.get(`/api/admin/get-resume/${role}/${id}`)
            .then(res => {
                console.log(res)
                setResume(res.data)
            })
            .catch(err => {
                console.log(err)
                setResume("")
            })
    }


    useEffect(() => {
        fetchUsers()
    }, []);
    const docs = [
        {
            //url of the resume that is fetched from backend and send to docs
            uri: resume,
        },
    ];
    return (
        <>
            <div className="skills-button">
                <Tooltip title="Back">
                    <button className="pdf-button" onClick={() => history.go(-1)}>
                        <ArrowBackIosIcon />
                    </button>
                </Tooltip>
            </div>
            <DocViewer
                pluginRenderers={DocViewerRenderers}
                documents={docs}
                config={{
                    header: {
                        disableHeader: false,
                        disableFileName: true,
                        retainURLParams: true,
                    },
                }}
                // setting height for the view
                style={{ height: "100%" }}
            />
        </>
    );
};

export default InternalPdfViewer;
