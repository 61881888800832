import React, { Component } from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar';
import SimpleCards from '../../cards/SimpleCards';
import { customprogress, ratingCardStyles } from './sidebarStyles';
import { Grid, Paper, Typography, Divider, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Rating } from '@material-ui/lab';
import 'react-circular-progressbar/dist/styles.css';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';

 import ChangingProgressProvider from '../ChangingProgressProvider';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  // container: {
  //   display: 'grid',
  //   gridTemplateColumns: 'repeat(12, 1fr)',
  //   gridGap: theme.spacing(3),
  // },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    //   whiteSpace: 'nowrap',
    marginBottom: theme.spacing(1),
    height: '127px',
    // maxHeight: 580,
    overflow: 'auto',
    border: '2px solid #75a478 ',
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
}));

export default function ProfileCompletion(props) {
  const classes = useStyles();
  const { completionPercentage } = props;
  // const { sidebar } = props;

  return (
    // <SimpleCards cardStyle={sidebarCardStyles} title="Profile Completed">
    //     <div style={progressBoxStyle}>
    //         <ProgressBar
    //             style={customprogress}
    //             label={`${completionPercentage}%`}
    //             animated
    //             variant="success"
    //             striped
    //             now={completionPercentage}
    //         />
    //     </div>
    // </SimpleCards>
    <div className={classes.root}>
      {/*-------------------------------------------------------  */}
      <Grid container spacing={3}>
        <Grid item xs={12} sm={8}>
          <Paper className={classes.paper} elevation={5}>
            <Typography component="legend">My Ratings</Typography>
            <Divider className={classes.divider} />
            <Typography component="legend">Recruiter Rating</Typography>
            <Rating name="Recruiter Rating" value={props.averageRating} readOnly max={5} precision={0.1} />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={4} cardStyle={ratingCardStyles}>
          <Paper elevation={5} className={classes.paper}>
            <Typography> Profile Completed</Typography>
             <div style={{ height: '20%', width: '20%', marginLeft: '140px' }}>
              <ChangingProgressProvider values={[0, 20, 40, 60, 80, 100]}>
                {(percentage) => (
                  <CircularProgressbar
                    value={completionPercentage}
                    value={percentage}
                    variant="success"
                    text={`${completionPercentage}%`}
                    styles={buildStyles({
                      pathTransitionDuration: 0.15,
                      trailColor: 'white',
                      pathColor: '#75a478',
                    })}
                  />
                )}
              </ChangingProgressProvider>
            </div> 
            {/* <ProgressBar
              style={customprogress}
              label={`${completionPercentage}%`}
              animated
              variant="success"
              // color="#75a478"
              striped
              now={completionPercentage}
            /> */}
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}
