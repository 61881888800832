import React, { useEffect, useState } from "react";
import axios from "axios";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import { styled, useTheme } from "@mui/material/styles";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import CalendarIcon from "@mui/icons-material/CalendarMonth";
import Phone from "@mui/icons-material/LocalPhoneOutlined";
import EditIcon from "@mui/icons-material/CreateOutlined";
import Tooltip from "@mui/material/Tooltip";

import CompanyMenu from "./CompanyMenu";
import { ViewEditUserModal } from "../modals/ViewEditUserModal";
import { ViewEditRecruiterModal } from "../modals/ViewEditRecruiterModal";
import Footer from "../../Footer/Footer";

import LinkedInIcon from '@mui/icons-material/LinkedIn';
import PublicIcon from '@mui/icons-material/Public';

const RecruiterProfile = (props) => {
  const id = sessionStorage.getItem("id");  //profile user id
  const role = sessionStorage.getItem("role");   // user role based on login
  const profileType = sessionStorage.getItem("profileType");  //role of user logged in
  const compId = sessionStorage.getItem("compId");  //company id
  const companyId = sessionStorage.getItem("companyId");  //company ref id
  console.log(role);

  //Profile Page States
  let profileAvatar =
    "https://res.cloudinary.com/df2qsnyox/image/upload/v1681133496/Default%20Profile%20Avatar/profile-avatar_ogxozq_ranghl.png";
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  // const [companyId, setCompanyId] = useState("");
  const [profileImage, setProfileImage] = useState(profileAvatar);
  const [defaultAvatar, setDefaultAvatar] = useState();
  const [croppedImg, setCroppedImg] = useState();
  const [interacted, setInteracted] = useState();
  const [croppedImagePublicId, setCroppedImagePublicId] = useState("");
  const [zoom, setZoom] = useState("");
  const [croppedAreaPixels, setCroppedAreaPixels] = useState("");
  const [croppedArea, setCroppedArea] = useState("");
  const [imagePublicId, setImagePublicId] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [PocDetails, setPocDetails] = useState({});
  const [linkedinURL, setLinkedinURL] = useState("")
  const [websiteURL, setWebsiteURL] = useState("")

  //Modal states
  const [openEditUserDetails, setOpenEditUserDetails] = useState(false);
  const [openEditRecruiterDetails, setOpenEditRecruiterDetails] = useState(false);
  const [open, setOpen] = useState();
  const [sidebarOpen, setsidebarOpen] = useState("");

  // moduleList access
  const [moduleAccess, setModuleAccess] = useState({})
  //Edit recruiter form states
  const [userData, setUserData] = useState({});

  //Loader
  const [isLoading, setIsLoading] = useState(true);

  //dynamic className
  const [imageWidth, setImageWidth] = useState("");

  var drawerWidth = 200;
  //Main
  const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
    ({ theme, open }) => ({
      flexGrow: 1,
      padding: theme.spacing(3),
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: `-${drawerWidth}px`,
      ...(open && {
        transition: theme.transitions.create("margin", {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
      }),
    })
  );


  useEffect(() => {
    // fetching data based on profiletype
    if (profileType === "Recruiter") {
      const { data } = axios.get(`/api/recruiter/${id}`).then((res) => {
        console.log(res);
        const {
          firstName,
          lastName,
          recruiterEmail,
          phone,
          profileImage,
          defaultAvatar,
          croppedImage,
          interacted,
          croppedImagePublicId,
          zoom,
          croppedAreaPixels,
          croppedArea,
          imagePublicId,
          socialMedia: {
            linkedin,
            website
          }
        } = res.data.user;
        setFirstName(firstName);
        setLastName(lastName);
        setEmail(recruiterEmail);
        setPhone(phone);
        setProfileImage(profileImage);
        setDefaultAvatar(defaultAvatar);
        setCroppedImg(croppedImage);
        setInteracted(interacted);
        setCroppedImagePublicId(croppedImagePublicId);
        setZoom(zoom);
        setCroppedAreaPixels(croppedAreaPixels);
        setCroppedArea(croppedArea);
        setImagePublicId(imagePublicId);
        setUserData(res.data.user)
        setLinkedinURL(linkedin)
        setWebsiteURL(website)
      });
    } else {
      const { data } = axios.get(`/api/myUsers/${id}`).then((res) => {
        const {
          firstName,
          lastName,
          userEmail,
          phone,
          profileImage,
          defaultAvatar,
          croppedImage,
          interacted,
          croppedImagePublicId,
          zoom,
          croppedAreaPixels,
          croppedArea,
          imagePublicId,
          verified,
          status,
          companyId,
          role,
          uniqueId,
          _id
        } = res.data.user;
        setFirstName(firstName);
        setLastName(lastName);
        setEmail(userEmail);
        setPhone(phone);
        setProfileImage(profileImage);
        setDefaultAvatar(defaultAvatar);
        setCroppedImg(croppedImage);
        setInteracted(interacted);
        setCroppedImagePublicId(croppedImagePublicId);
        setZoom(zoom);
        setCroppedAreaPixels(croppedAreaPixels);
        setCroppedArea(croppedArea);
        setImagePublicId(imagePublicId);
        setUserData(res.data.user)
        console.log(res.data.user);
      });
    }


    const { companyData } = axios
      .get(`/api/admin/recruiter-profile/${companyId}`)
      .then((res) => {
        const { companyName, PocDetails } = res.data.user[0];
        setCompanyName(companyName);
        setPocDetails(PocDetails)
      });
    setIsLoading(false);

    const moduleList = axios.get(`/api/admin/userRole/side-list/${companyId}`)
      .then(res => {
        // getting profile module access
        let data = res.data[0].profileType.filter(item => item.roleName === profileType)
        data = data[0].modules.filter(item => item.title == "Profile")
        setModuleAccess(data[0])
      }).catch(error => {
        console.log(error)
      })
  }, [openEditUserDetails, openEditRecruiterDetails]);

  console.log(PocDetails)
  useEffect(() => {
    open
      ? setImageWidth("sidebar-open-image")
      : setImageWidth("sidebar-close-image");
  }, [open]);

  const handleOpenEditModal = () => {
    if (profileType.toLowerCase() == "recruiter") {
      setOpenEditRecruiterDetails(true)
    } else { setOpenEditUserDetails(true) }
  }


  return (
    <div>
      <CompanyMenu
        open={open}
        setOpen={setOpen}
        sidebarOpen={sidebarOpen}
        setsidebarOpen={setsidebarOpen}
        companyName={firstName}
        croppedImg={croppedImg}
        profileImage={profileImage}
        interacted={interacted}
        defaultAvatar={defaultAvatar}
      />

      <Main id={`${sidebarOpen}`}>
        <div
          className="root-div"
          id="restrict-scroll"
          style={{ overflowY: "hidden" }}
        >
          <Box sx={{ flexGrow: 1 }}>
            <Card
              id="main-edit"
              className="main-card profile-card recruiter-profile-card "
            >
              {/* 1st Grid container */}
              <Grid
                container
                spacing={0}
                direction="row"
                className="recruiter profile-card-container"
              >
                <div className="edit-icon-mobile-container">
                  {moduleAccess?.edit ? <EditIcon
                    className="edit-icon-mobile"
                    onClick={handleOpenEditModal}
                  /> : ""}
                </div>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={2.5}
                  lg={2}
                  xl={1.5}
                  className="container-profile"
                >
                  <img
                    src={
                      defaultAvatar
                        ? profileAvatar
                        : interacted
                          ? croppedImg
                          : profileImage
                    }
                    className={`${imageWidth}`}
                    style={{ objectFit: "cover" }}
                  />

                  <div class="social-profile-card-container">
                    {linkedinURL && <a href={linkedinURL} className="social" target="_blank">
                      <LinkedInIcon />
                    </a>}

                    {websiteURL && <a href={websiteURL} class="social" target="_blank">
                      <PublicIcon />
                    </a>}

                  </div>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={7.2}
                  xl={7.5}
                  className="container-heading-title"
                >
                  <div>
                    <h2>
                      {firstName} {lastName}
                    </h2>
                    <h5>{profileType === "admin" ? "Administrator" : profileType.charAt(0).toUpperCase() + profileType.slice(1)}</h5>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={2.5}
                  lg={2.5}
                  className="container-heading-buttons"
                >
                  <div>
                    {moduleAccess?.edit ? <EditIcon
                      id="main-edit"
                      className="edit-icon"
                      onClick={handleOpenEditModal}
                    /> : ""}
                  </div>
                </Grid>
              </Grid>

              {/* 2nd Grid container */}
              <Grid
                container
                spacing={0}
                direction="row"
              // className="info-container"
              // justifyContent="flex-end"
              >
                <Grid
                  item
                  xs={0}
                  sm={0}
                  md={2.9}
                  lg={2.1}
                  xl={1.6}
                  className="container-profile"
                ></Grid>

                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={8.9}
                  lg={9.5}
                  xl={10}
                  className="recruiter info-container1"
                  direction="row"
                >
                  <div className="recruiter-info-container-height">
                    <div className="info-container1-heading">
                      <Phone className="icon" />
                      Phone
                    </div>
                    <p>+{phone}</p>
                  </div>
                  <div className="recruiter-info-container-height">
                    <div className="info-container1-heading">
                      <MailOutlineIcon className="icon" />
                      Email
                    </div>
                    <p>{email}</p>
                  </div>

                  <div className="recruiter-info-container-height">
                    <div className="info-container1-heading">
                      <MailOutlineIcon className="icon" />
                      Company Name
                    </div>
                    <p>{companyName}</p>
                  </div>
                </Grid>
              </Grid>
              {/* <Grid
                container
                spacing={0}
                direction="row"
                className="info-container company-profile recruiter-info-container"
                justifyContent="flex-end"
              >
                <Grid
                  item
                  xs={0}
                  sm={0}
                  md={0}
                  lg={1.8}
                  xl={1.2}
                  className="container-profile"
                ></Grid>

                <Grid
                  item
                  xs={12}
                  sm={5.5}
                  md={3}
                  lg={3}
                  xl={3}
                  className="info-container1 company-container1"
                >
                  <div className="info-container-height">
                    <div className="info-container1-heading">
                      <Phone className="icon" />
                      Phone
                    </div>
                    <p>+{phone}</p>
                  </div>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={5.5}
                  md={3}
                  lg={3}
                  xl={3}
                  className="info-container2 company-container1"
                >
                  <div className="info-container-height">
                    <div className="info-container1-heading">
                      <MailOutlineIcon className="icon" />
                      Email
                    </div>
                    <p>{email}</p>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={5.5}
                  md={3}
                  lg={3}
                  xl={3}
                  className="info-container2 company-container2"
                >
                  <div className="info-container-height">
                    <div className="info-container1-heading">
                      <MailOutlineIcon className="icon" />
                      Company Name
                    </div>
                    <p>{companyName}</p>
                  </div>
                </Grid>
              </Grid> */}
            </Card>

            {profileType.toLowerCase() === "admin" && <div className="cards-list">
              <Grid className="card-tiles" xs={12}>
                <Card
                  id="personal-details-edit"
                  className="side-card profile-card"
                >
                  {/* 1st Grid container */}
                  <Grid
                    container
                    spacing={1}
                    direction="row"
                    className="personal-card sub-card"
                  >
                    <Grid
                      item
                      xxs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      className="personal-card-title"
                    >
                      <div className="personal-card-heading">
                        <p>Point Of Contact</p>
                      </div>

                      {/* <div className="personal-card-editIcon">
                        <Tooltip title="Edit">
                          <EditIcon
                            className="edit-icon"
                          onClick={pocEditOpenHandler}
                          />
                        </Tooltip>
                      </div> */}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      className="personal-details"
                    >
                      <div className="details-block">
                        {PocDetails.name ? (
                          <>
                            <div className="personal-info">
                              <p className="personal-info-heading">
                                Name: &nbsp;
                              </p>
                              <p className="personal-info-content">
                                {PocDetails.name}
                              </p>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}

                        {PocDetails.email ? (
                          <>
                            <div className="personal-info">
                              <p className="personal-info-heading">
                                Email: &nbsp;
                              </p>
                              <p className="personal-info-content">
                                {PocDetails.email}
                              </p>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}

                        {PocDetails.phone ? (
                          <>
                            <div className="personal-info">
                              <p className="personal-info-heading">
                                Phone: &nbsp;
                              </p>
                              <p className="personal-info-content">
                                +{PocDetails.phone}
                              </p>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}

                        {PocDetails.jobTitle ? (
                          <>
                            <div className="personal-info">
                              <p className="personal-info-heading">
                                Job Title: &nbsp;
                              </p>
                              <p className="personal-info-content">
                                {PocDetails.jobTitle}
                              </p>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>

                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            </div>
            }
          </Box>
        </div>

      </Main>
      {profileType.toLowerCase() == "recruiter" ?
        <ViewEditRecruiterModal
          openEditRecruiterDetails={openEditRecruiterDetails}
          setOpenEditRecruiterDetails={setOpenEditRecruiterDetails}
          {...userData}
        />
        : <ViewEditUserModal
          openEditUserDetails={openEditUserDetails}
          setOpenEditUserDetails={setOpenEditUserDetails}
          {...userData}
        />
      }
      <Footer sidebarOpen={"sidebar-close"} />
    </div>
  );
};

export default RecruiterProfile;
