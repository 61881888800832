import DateFnsUtils from "@date-io/date-fns";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from "@material-ui/core/TextField";
import axios from "axios";
import {
  DatePicker,
  MuiPickersUtilsProvider,
  TimePicker,
} from "material-ui-pickers";
import PropTypes, { string } from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { withSnackbar, useSnackbar } from "notistack";
import { Link } from "react-router-dom";
import { Divider } from "@material-ui/core";

//material ui

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";

const styles = (theme) => ({
  main: {
    width: "auto",
    display: "block", // Fix IE 11 issue.
    marginTop: theme.spacing(5),
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(6))]: {
      width: "50%",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },

  paper: {
    marginTop: theme.spacing(2),
    display: "flex",
    width: "100%",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(
      3
    )}px`,
  },
  button: {
    padding: theme.spacing(2),
    margin: 10,
    alignItems: "center",
  },

  textField: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    width: "100px",
  },

  textFieldBox: {
    marginTop: theme.spacing() * 1,
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    width: "500px",
  },
  select: {
    marginTop: theme.spacing() * 1,
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    width: "250px",
  },
  formControl: {
    margin: theme.spacing(),
    minWidth: 120,
    maxWidth: 300,
  },
});

const interviewStatus = [
  {
    value: "Schedule Awaiting Confirmation",
    label: "Schedule Awaiting Confirmation",
  },
  { value: "Reschedule", label: "Reschedule" },
  { value: "Schedule Confirmed", label: "Schedule Confirmed" },
  { value: "Completed", label: "Completed" },
  { value: "Completed With Feedback", label: "Completed Reviewed" },
  { value: "Interview Declined", label: "Decline Interview" },
];

const interviewStage = [
  { value: "To Be Interviewed", label: "To Be Interviewed" },
  { value: "Scheduled Screening", label: "Scheduled Screening" },
  { value: "Phone Screening", label: "Phone Screening" },
  { value: "Video Screening", label: "Video Screening" },
  { value: "Final Screening", label: "Final Screening" },
];

const timeZones = [
  { value: "EDT", label: "Eastern Time zone(EDT)" },
  { value: "CDT", label: "Central Daylight Time(CDT)" },
  { value: "PDT", label: "Pacific Daylight Time(PDT)" },
  { value: "MDT", label: "Mountain Daylight Time(MDT)" },
  { value: "IST", label: "India Standard Time(IST)" },
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

class updateInterview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      _id: this.props.match.params.id,
      startdate: new Date(),
      starttime: new Date(),
      endtime: new Date(),
      title: string,
      timezone: string,
      description: string,
      interviewstatus: "Schedule Awaiting Confirmation",
      interviewstage: "To Be Interviewed",
      screener: string,
      screenerList: [],
      candidate: string,
      candidateList: [],
      loading: true,
      open: false,
      openDialog: false,
    };
    console.log(this.props.match.params.id);
  }
  //handleClickOpen for cancel button
  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleCliclOpenDialog = () => {
    this.setState({ openDialog: true });
  };

  //handleClose
  handleClose = () => {
    this.setState({ open: false });
    this.setState({ openDialog: false });
  };

  componentDidMount() {
    axios.get("/api/users/screener").then((response) => {
      this.setState({
        screenerList: response.data,
      });
    });
    axios.get("/api/users/candidate").then((response) => {
      this.setState({
        candidateList: response.data,
      });
    });

    axios.get("/api/event/interview/" + this.state._id).then(
      (res) => {
        var newState = {
          startdate: res.data.startdate,
          starttime: res.data.starttime,
          endtime: res.data.endtime,
          title: res.data.title,
          timezone: "EDT",
          description: res.data.description,
          interviewstatus: res.data.interviewstatus,
          interviewstage: res.data.interviewstage,
          screener: res.data.screener,
          candidate: res.data.candidate,
        };

        this.setState(newState);
        console.log(newState.starttime + " StartTime");
        this.setState({ loading: false });
      },
      (err) => {
        alert("An error occured! Try refreshing the page.", err);
      }
    );
  }

  // handleScreenerChange = (event) => {

  //     this.setState({ screeners: event.target.value });
  //     // console.log(JSON.stringify(this.state.screeners));

  // };

  handleCandidateChange = (event) => {
    this.setState({ candidate: event.target.value });
  };

  handleScreenerChange = (event) => {
    this.setState({ screener: event.target.value });
  };

  handleStatusChange = (event) => {
    this.setState({ interviewstatus: event.target.value });
  };

  handleStageChange = (event) => {
    this.setState({ interviewstage: event.target.value });
  };

  // handleCandidateChange = (event) => {

  //     this.setState({ candidate: event.target.value });
  // };

  handleTimezoneChange = (event) => {
    this.setState({ timezone: event.target.value });
  };

  handleInputChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleStartDateChange = (date) => {
    this.setState({ startdate: date });
  };

  handleStartTimeChange = (date) => {
    this.setState({ starttime: date });
  };

  handleEndTimeChange = (date) => {
    this.setState({ endtime: date });
  };

  handleTimeZoneChange = (event) => {
    this.setState({ timezone: event.target.value });
  };

  onSubmit = (e) => {
    e.preventDefault();
    const {
      _id,
      title,
      description,
      startdate,
      starttime,
      endtime,
      timezone,
      interviewstatus,
      interviewstage,
      candidate,
      screener,
    } = this.state;
    console.log(title);
    var interview = {
      _id,
      title,
      description,
      startdate,
      starttime,
      endtime,
      timezone,
      interviewstatus,
      interviewstage,
      candidate,
      screener,
    };
    if (this.state.title) {
      axios
        .put("/api/event/interviewupdate/" + this.state._id, interview)

        .then((res) => {
          //alert('Updated successfully');
          let regMessage = <span>Updated Interview Successfully</span>;

          this.props.enqueueSnackbar(regMessage, {
            variant: "success",
            autoHideDuration: 3000,
          });

          this.props.history.push("/interview-list");
        })
        .catch((err) => {
          console.log(err.response);
          // alert('An error occured try submitting the form again');
          let regMessage = (
            <span>An error occured try submitting the form again</span>
          );
          this.props.enqueueSnackbar(regMessage, {
            variant: "error",
            autoHideDuration: 3000,
          });
        });
    } else {
      let regMessage = <span>Fill in required fields.</span>;

      this.props.enqueueSnackbar(regMessage, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  render() {
    const { classes } = this.props;

    const { startdate, endtime, starttime } = this.state;

    let screenername = "";
    let candidatename = "";

    return (
      <React.Fragment>
        <div
          className={classes.main}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <Paper className={classes.paper}>
            <br />
            <Typography variant="h6">
              Edit Interview <Divider />
            </Typography>

            <Dialog
              open={this.state.open}
              onClose={this.handleClose}
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                  Warning: All your current changes will be lost when you leave.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={this.handleClose}
                  style={{ backgroundColor: "#D73B30", color: "white" }}
                >
                  Cancel
                </Button>

                <Link
                  style={{ textDecoration: "none", padding: "10px" }}
                  to="/interview-list"
                >
                  <Button
                    style={{
                      backgroundColor: "#75A478",
                      color: "white",
                    }}
                  >
                    Confirm
                  </Button>
                </Link>
              </DialogActions>
            </Dialog>

            {/* Save Diaglog */}
            <Dialog
              open={this.state.openDialog}
              onClose={this.handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Warning: Do you want to update the current changes and exit?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={this.handleClose}
                  style={{ backgroundColor: "#D73B30", color: "white" }}
                >
                  Cancel
                </Button>
                <Button
                  autoFocus
                  type="submit"
                  // className={classes.submit}
                  onClick={this.onSubmit}
                  style={{
                    backgroundColor: "#75A478",
                    color: "white",
                    margin: "6px",
                  }}
                  // onClick={this.onSubmit}
                  // href={this.handleProfileEdit()}
                >
                  Update
                </Button>
              </DialogActions>
            </Dialog>

            <form className={classes.form} onSubmit={this.onSubmit}>
              <Grid container spacing={24} style={{ padding: "2em" }}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    className="event-input"
                    name="title"
                    onChange={this.handleInputChange}
                    fullWidth
                    label="Title*"
                    value={this.state.title}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl className={classes.select}>
                    {/* {this.state.screenerList && this.state.screenerList.forEach(user => {
                                    if (this.state.screener === user._id) {
                                        console.log(user.name);
                                        screenername=user.name;

                                    }
                            })} */}

                    <InputLabel htmlFor="select-multiple-checkbox">
                      Screener
                    </InputLabel>
                    <Select
                      // multiple
                      value={this.state.screener}
                      onChange={this.handleScreenerChange}
                      // input={<Input id="select-multiple-checkbox" />}
                      // renderValue={(selected) => "Selected"}
                      autoComplete="on"
                      MenuProps={MenuProps}
                    >
                      {this.state.screenerList.map((screenerListItem) => (
                        <MenuItem
                          key={screenerListItem._id}
                          value={screenerListItem._id}
                        >
                          {/* <Checkbox checked={this.state.screenerList.indexOf(screenerList) >-1} /> */}
                          <ListItemText
                            primary={screenerListItem.name}
                            secondary={screenerListItem.uniqueMatchId}
                          />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormControl className={classes.select}>
                    {/* {this.state.candidateList && this.state.candidateList.forEach(user => {
                                    if (this.state.candidate === user._id) {
                                        console.log(user.name);
                                        candidatename=user.name;
                                    
                                    }
                            })} */}
                    <InputLabel htmlFor="select-multiple-checkbox">
                      Candidate
                    </InputLabel>
                    <Select
                      // multiple

                      value={this.state.candidate}
                      onChange={this.handleCandidateChange}
                      // input={<Input id="select-multiple-checkbox" />}
                      // renderValue={(selected) => "Selected"}
                      autoComplete="on"
                      MenuProps={MenuProps}
                    >
                      {this.state.candidateList.map((candidateListItem) => (
                        <MenuItem
                          key={candidateListItem._id}
                          value={candidateListItem._id}
                        >
                          {/* <Checkbox checked={this.state.screenerList.indexOf(screenerList) >-1} /> */}
                          <ListItemText
                            primary={candidateListItem.name}
                            secondary={candidateListItem.uniqueMatchId}
                          />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                {/* <Grid item xs={12} sm={6}>
                                {
                                    (() => {
                                        if(this.state.interviewstatus==="Schedule Confirmed"){
                                            return (
                                                
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <DatePicker
                                                        required
                                                        id="startDate"
                                                        label="Date"
                                                        name="startdate"
                                                        format="dd/MM/yyyy"
                                                        value={startdate}
                                                        minDate={new Date()}
                                                        disabled={true}
                                                        onChange={this.handleStartDateChange}
                                                        fullWidth
                                                    />
                                                </MuiPickersUtilsProvider>
                                           
                    
                                            )
                                        }else{
                                            return(
                                               
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <DatePicker
                                                        required
                                                        id="startDate"
                                                        label="Date"
                                                        name="startdate"
                                                        format="dd/MM/yyyy"
                                                        value={startdate}
                                                        minDate={new Date()}
                                                       // disabled={true}
                                                        onChange={this.handleStartDateChange}
                                                        fullWidth
                                                    />
                                                </MuiPickersUtilsProvider>

                    
                                            )
                                        }
                                    })}

                                    </Grid> */}

                {console.log(this.state.interviewstatus)}
                <Grid item xs={12} sm={6}>
                  {this.state.interviewstatus === "Schedule Confirmed" ||
                  this.state.interviewstatus === "Completed" ? (
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker
                        required
                        id="startDate"
                        label="Date (dd/mm/yyyy)"
                        name="startdate"
                        format="dd/MM/yyyy"
                        value={startdate}
                        //   minDate={new Date()}
                        disabled={true}
                        onChange={this.handleStartDateChange}
                        fullWidth
                      />
                    </MuiPickersUtilsProvider>
                  ) : (
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker
                        required
                        id="startDate"
                        label="Date (dd/mm/yyyy)"
                        name="startdate"
                        format="dd/MM/yyyy"
                        value={startdate}
                        clearable
                        //  minDate={new Date()}
                        //disabled={true}
                        onChange={this.handleStartDateChange}
                        fullWidth
                      />
                    </MuiPickersUtilsProvider>
                  )}
                </Grid>
                {/* <Grid item xs={12} sm={6}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DatePicker
                                    required
                                    id="startDate"
                                    label="Date"
                                    name="startdate"
                                    format="dd/MM/yyyy"
                                    value={startdate}
                                    minDate={new Date()}
                                    disabled={true}
                                    onChange={this.handleStartDateChange}
                                    fullWidth
                                />
                            </MuiPickersUtilsProvider>
                        </Grid> */}

                <Grid item xs={12} sm={6}>
                  {this.state.interviewstatus === "Schedule Confirmed" ||
                  this.state.interviewstatus === "Completed" ? (
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <TimePicker
                        id="startTime"
                        label="Start Time"
                        name="starttime"
                        value={starttime}
                        disabled={true}
                        onChange={this.handleStartTimeChange}
                        fullWidth
                      />
                    </MuiPickersUtilsProvider>
                  ) : (
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <TimePicker
                        id="startTime"
                        label="Start Time"
                        name="starttime"
                        value={starttime}
                        //disabled={true}
                        onChange={this.handleStartTimeChange}
                        fullWidth
                      />
                    </MuiPickersUtilsProvider>
                  )}
                </Grid>

                <Grid item xs={12} sm={6}>
                  {this.state.interviewstatus === "Schedule Confirmed" ||
                  this.state.interviewstatus === "Completed" ? (
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <TimePicker
                        id="EndTime"
                        label="end Time"
                        name="endtime"
                        value={endtime}
                        disabled={true}
                        fullWidth
                        onChange={this.handleEndTimeChange}
                      />
                    </MuiPickersUtilsProvider>
                  ) : (
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <TimePicker
                        id="EndTime"
                        label="end Time"
                        name="endtime"
                        value={endtime}
                        fullWidth
                        onChange={this.handleEndTimeChange}
                      />
                    </MuiPickersUtilsProvider>
                  )}
                </Grid>
                {/* <Grid item xs={12} sm={6}>
                            <TextField
                                className="event-input"
                                name="timezone"
                                required
                                value={this.state.timezone}
                                onChange={this.handleTimeZoneChange}
                                fullWidth
                                label="TimeZone"
                            />
                </Grid> */}
                {/* <Grid item xs={12} sm={6}>
                  <FormControl className={classes.select}>
                    <InputLabel htmlFor="select-multiple-checkbox">
                      TimeZone*
                    </InputLabel> */}
                {/* <Select
                      // id="timezone"
                      name="timezone"
                      value={this.state.timezone}
                      // options={interviewStatus.values}
                      autoComplete="on"
                      MenuProps={MenuProps}
                      onChange={this.handleTimezoneChange}
                    >
                      <MenuItem value="">
                        <em>Select Timezone</em>
                      </MenuItem>
                      {timeZones.map((status) => (
                        // <option value={option.value}>{option.label}</option>
                        <MenuItem value={status.value}>{status.label}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid> */}
                <Grid item xs={12} sm={8}>
                  <TextField
                    name="description"
                    onChange={this.handleInputChange}
                    fullWidth
                    multiline
                    className={classes.textFieldBox}
                    value={this.state.description}
                    margin="normal"
                    variant="outlined"
                    label="Description"
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormControl className={classes.select}>
                    <InputLabel htmlFor="select-multiple-checkbox">
                      Interview Status
                    </InputLabel>
                    <Select
                      id="interviewstatus"
                      name="interviewstatus"
                      placeholder="Select a Interview Status"
                      value={this.state.interviewstatus}
                      // options={interviewStatus.values}
                      fullWidth
                      onChange={this.handleStatusChange}
                    >
                      {interviewStatus.map((status) => (
                        // <option value={option.value}>{option.label}</option>
                        <MenuItem value={status.value}>{status.label}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormControl className={classes.select}>
                    <InputLabel htmlFor="select-multiple-checkbox">
                      Interview Stage
                    </InputLabel>
                    <Select
                      id="interviewstage"
                      name="interviewstage"
                      placeholder="Select a Interview Stage"
                      value={this.state.interviewstage}
                      // options={interviewStatus.values}
                      fullWidth
                      onChange={this.handleStageChange}
                    >
                      {interviewStage.map((status) => (
                        // <option value={option.value}>{option.label}</option>
                        <MenuItem value={status.value}>{status.label}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={12}>
                  <br />
                  <br />
                  <br />

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      flexWrap: "wrap",
                      float: "left",
                    }}
                  >
                    {" "}
                    <Button
                      onClick={this.handleClickOpen}
                      variant="contained"
                      color="secondary"
                      className={classes.submit}
                      style={{
                        marginRight: "12px",
                        backgroundColor: "#D73B30",
                        color: "white",
                      }}
                    >
                      Back
                    </Button>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      flexWrap: "wrap",
                    }}
                  >
                    <Button
                      style={{ color: "white" }}
                      // type="submit"
                      variant="contained"
                      color="secondary"
                      // className={classes.submit}
                      onClick={this.handleCliclOpenDialog}
                    >
                      Update Event
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </form>
            <br />
          </Paper>
        </div>
      </React.Fragment>
    );
  }
}

updateInterview.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default withSnackbar(
  withStyles(styles)(connect(mapStateToProps, { logoutUser })(updateInterview))
);
