
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Draggable from 'react-draggable';
import Board, { moveCard, changeCard } from "@asseinfo/react-kanban";
import "@asseinfo/react-kanban/dist/styles.css";
import moment from 'moment-timezone';
import RefreshIcon from '@mui/icons-material/Refresh';
import TablePagination from "@mui/material/TablePagination";
import dateFormat from "dateformat";
import DeleteMessageIcon from "@mui/icons-material/ErrorOutlineOutlined";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SubscriberSettings from "./SubscriberSettings";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Tooltip from "@mui/material/Tooltip";
import Grid from "@mui/material/Grid";


import CompanyMenu from "../../userProfiles/CompanyMenu";

import TableLoader from "../../loaders/TableLoader";

import { CopyToClipboard } from 'react-copy-to-clipboard';
import PageNotFound from "../../ErrorHandling/PageNotFound";
import Footer from "../../../Footer/Footer";

const Subscribers = ({ history }) => {

    //Session storage values
    const id = sessionStorage.getItem("id");  //company id
    const companyId = sessionStorage.getItem("companyId");  //company ref id
    const role = sessionStorage.getItem("role");  //profile role

    //navbar states
    const [open, setOpen] = useState();
    const [sidebarOpen, setsidebarOpen] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [interacted, setInteracted] = useState("");
    const [croppedImg, setCroppedImg] = useState("");
    const [profileImage, setProfileImage] = useState("");
    const [defaultAvatar, setDefaultAvatar] = useState("");

    //Loader
    const [tableLoading, setTableLoading] = useState(true);

    //pagination states
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [page, setPage] = React.useState(0);

    //snackbar and loader states
    const [message, setMessage] = useState("")
    const [openSnackbar, setOpenSnackbar] = useState(false)
    const [loading, setLoading] = useState(true);
    const [tile, setTile] = useState(false)
    const [companyDetails, setCompanyDetails] = useState({})
    // array to store list of companies registered
    const [allCompanies, setAllCompanies] = useState([]);
    const [allCompanies1, setAllCompanies1] = useState([]);

    //filter state
    const [companyFilter, setCompanyFilter] = useState("")

    const [hasSubscriberAccess, setHasSubscriberAccess] = useState(true)



    //table heading
    const columns = [
        {
            title: "Company Name",
            width: "20%",
        },
        {
            title: "POC Name",
            width: "30%",
        },
        {
            title: "POC Email",
            width: "30%",
        },
        {
            title: "POC Phone",
            width: "15%",
        },
        {
            title: "Status",
            width: "15%",
        }
    ];


    const handleTileOpen = (path, item) => {
        console.log(item, path)
        setCompanyDetails(item)
        setTile(path)
        //path=> true = > tiles, else back
        console.log("Go to tiles")
    }

    const filterHandler = () => {

        let filteredCompanies = [...allCompanies1]
        filteredCompanies = filteredCompanies.filter(comp => comp.companyName.toLowerCase().startsWith(companyFilter.toLowerCase()))
        setAllCompanies(filteredCompanies)
        console.log(filteredCompanies)
    }


    console.log(companyFilter, allCompanies)
    // change document title
    useEffect(() => {
        document.title = "Screener | Subscribers";
    }, []);


    //fetching navbar data
    useEffect(() => {
        axios.get(`/api/admin/subscriber-access/${id}`)
            .then(res => {
                console.log(res)
                if (!res.data.msg) {
                    setHasSubscriberAccess(false)
                } else {
                    axios.get(`/api/admin/${id}`).then((res) => {
                        const {
                            defaultAvatar,
                            profileImage,
                            croppedImage,
                            interacted,
                            companyName,
                        } = res.data.user;
                        setDefaultAvatar(defaultAvatar);
                        setProfileImage(profileImage);
                        setCroppedImg(croppedImage);
                        setInteracted(interacted);
                        setCompanyName(companyName);
                    });
                }
            })
    }, [])


    useEffect(() => {
        // fetching all companies 
        getAllCompanies()
        setCompanyFilter("")
    }, [tile]);


    useEffect(() => {
        setPage(0)
        if (companyFilter)
            filterHandler()
        else getAllCompanies()
    }, [companyFilter])

    function getAllCompanies() {
        // fetching all companies 
        axios.get(`/api/admin/get-all-companies/${id}`)
            .then(res => {
                console.log("data received", res.data)
                setAllCompanies(res.data)
                setAllCompanies1(res.data)
                setTableLoading(false)
            })
            .catch(err => {
                console.log(err)
            })
    }

    //pagination handler
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    console.log(allCompanies)


    return (
        <>
            {hasSubscriberAccess ? <div>
                {/* Navbar Component */}
                <CompanyMenu
                    open={open}
                    setOpen={setOpen}
                    sidebarOpen={sidebarOpen}
                    setsidebarOpen={setsidebarOpen}
                    companyName={companyName}
                    croppedImg={croppedImg}
                    profileImage={profileImage}
                    interacted={interacted}
                    defaultAvatar={defaultAvatar}
                />

                {/* Main Page Component */}
                <div
                    style={{
                        marginLeft: `${sidebarOpen === "sidebar-open" ? "200px" : "0px"}`,
                    }}
                >
                    <Box sx={{ flexGrow: 1, background: "#faf9f6" }}>
                        {/* Title Card */}
                        <Card id="main-edit" className="add-recruiter-form form-card">
                            <Grid
                                container
                                spacing={0}
                                // direction="row"
                                className="add-recruiter-card-container"
                            >
                                <Grid
                                    item
                                    xs={12}
                                    sm={sidebarOpen === "sidebar-open" ? 12 : 4}
                                    md={sidebarOpen === "sidebar-open" ? 12 : 4}
                                    lg={6}
                                    className="container-heading-title admin-table"
                                >

                                    {!tile ? <h3>Subscribers</h3> : <div style={{ display: 'inline-flex' }}> <h3 style={{ cursor: 'pointer', marginRight: '8px' }} className="subscriber-link" onClick={() => setTile(false)}>Subscribers </h3>
                                        <h3 >{` > ${companyDetails.companyName}`}</h3></div>}

                                    {tile && <Grid
                                        id="profile-completion-percentage"
                                        item
                                        xs={12}
                                        md={12}
                                        sx={{ textAlign: "center" }}
                                        className="container-profile"
                                    >
                                        <div className="table-buttons">
                                            <button className="add-recruiter" onClick={() => {
                                                setTile(false)
                                            }}>
                                                Back
                                            </button>
                                        </div>
                                    </Grid>}

                                    {/* Companies Filter Input field */}
                                    {!tile && <div class="add-recruiter-input-container myUsers">
                                        <div class="input-field companies-search">
                                            <input
                                                // style={{ width: '37%', paddingLeft: '10px' }}
                                                class="position"
                                                type="text"
                                                value={companyFilter}
                                                // defaultValue={debouncedTerm}
                                                onChange={(e) => setCompanyFilter(e.target.value)}
                                                // onFocus={handleFocus}
                                                placeholder="Search by company name"
                                            // ref={el => (inputRef.current = el)}
                                            // autoFocus={inputRef.current === document.activeElement}
                                            />
                                        </div>
                                    </div>}


                                </Grid>


                            </Grid>
                        </Card>

                        {!tile ? <Card
                            id="skills-edit"
                            className="add-recruiter-form-container "
                            style={{ boxShadow: "none", borderRadius: "2px" }}
                        >
                            <div className="recruiter-list">
                                <Grid
                                    item
                                    xs={12}
                                    md={12}
                                    lg={12}
                                    className="recruiter-list-heading"
                                >
                                    {columns.map((item, index) => {
                                        return (
                                            <h6
                                                style={{
                                                    width: `${item.width}`,
                                                    background: index % 2 ? "rgba(240, 97, 97, 37%)" : "rgba(240, 97, 97, 17%)",
                                                    color: "#283848",
                                                }}
                                            >
                                                {item.title}
                                            </h6>
                                        );
                                    })}
                                </Grid>
                                {tableLoading ? (
                                    <TableLoader page="subscriberList" />
                                ) : (<div className="recruiter-details-list">
                                    {allCompanies.length > 0 ? (
                                        allCompanies
                                            .slice(
                                                page * rowsPerPage,
                                                page * rowsPerPage + rowsPerPage
                                            )
                                            .map((item, index) => {
                                                let firstElement = 1;
                                                return (
                                                    <>
                                                        <div

                                                            style={{
                                                                justifyContent: 'normal'
                                                            }}
                                                            className={` recruiter-details ${index % 2
                                                                ? ""
                                                                : "alternate-recruiterlist-bg"
                                                                }`}
                                                        >
                                                            <div className="interview-id user-role" style={{ width: '18%', fontWeight: "bold" }}>
                                                                <p style={{ textAlign: 'start', marginLeft: "15px" }} onClick={() => handleTileOpen(true, item)}>{item.companyName}</p>
                                                            </div>

                                                            <div className="user-role-description " style={{ width: '28%' }}>
                                                                {item.PocDetails.name.length > 24 ? (
                                                                    <Tooltip title={`${item.PocDetails.name || "NA"}`}>
                                                                        <p>{item.PocDetails.name || "Name NA"}</p>
                                                                    </Tooltip>
                                                                ) :
                                                                    <p>{item.PocDetails.name || "Not Specified"}</p>
                                                                }
                                                            </div>

                                                            <div className="user-role-description " style={{ width: '30%' }}>
                                                                {item.PocDetails.name.length > 24 ? (
                                                                    <Tooltip title={`${item.PocDetails.email || "NA"}`}>
                                                                        <p>{item.PocDetails.email || "Not Specified"}</p>
                                                                    </Tooltip>
                                                                ) :
                                                                    <p>{item.PocDetails.email || "Not Specified"}</p>
                                                                }
                                                            </div>


                                                            <div className="user-role-description " style={{ width: '15%' }}>
                                                                {item.PocDetails.name.length > 24 ? (
                                                                    <Tooltip title={`${item.PocDetails.phone || "Not Specified"}`}>
                                                                        <p>{item.PocDetails.phone || "Not Specified"}</p>
                                                                    </Tooltip>
                                                                ) :
                                                                    <p>{item.PocDetails.phone || "Not Specified"}</p>
                                                                }
                                                            </div>

                                                            <div className="user-role-description " style={{ width: '9%' }}>
                                                                <div className="user-status-button" >
                                                                    <button className={item.isActive ? 'Active' : "Frozen"}>
                                                                        {item.isActive ? 'Active' : "Frozen"}
                                                                    </button>
                                                                </div>
                                                            </div>

                                                        </div>

                                                    </>
                                                );
                                            })
                                    ) : (
                                        <p>No Subscribers</p>
                                    )}
                                </div>
                                )}
                            </div>
                            <TablePagination
                                id="recruiter-list-pagination"
                                component="div"
                                rowsPerPageOptions={[10, 25, 50, 100]}
                                count={allCompanies.length}
                                page={page}
                                onPageChange={handleChangePage}
                                rowsPerPage={rowsPerPage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                variant="outlined"
                            />
                        </Card> : <SubscriberSettings item={companyDetails} setTile={setTile} tile={tile} />}
                    </Box>
                </div>
                <Footer sidebarOpen={sidebarOpen} />
            </div > : <PageNotFound />}

        </>
    )
}

export default Subscribers
