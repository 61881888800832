export const progressBoxStyle = {
    // backgroundColor: "#75a478",
    height: "55px",
    padding: "10px",
    borderRadius: "10px"
};

export const customprogress = {
    height: "1.0rem",
    // backgroundColor: "#75a478",
    margin:"25px 15px 15px 15px",
    // border: "3px solid #75A478"
   
};

export const progressBar = {
    height: "1.4rem",
    padding:"30px"
    
}

export const progressLabel = {
    color: "#212121",
    letterSpacing: "0px",
    opacity: "1",
    textAlign: "left",
    marginBottom: "4px"
}

export const sidebarCardStyles = {
    height: "169px",
    overflow: "auto",
    margin: "10px"
};

export const ratingCardStyles = {
    width: "250px",
    height: "302px",
    overflow: "auto",
    margin: "10px"
};

export const ratingBoxStyle = {
    margin: "3px",
    textAlign: "left",
    padding: "10px",
    borderRadius: "5px"
}

