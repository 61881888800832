import DateFnsUtils from "@date-io/date-fns";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from "@material-ui/core/TextField";
import Card from "@material-ui/core/Card";
import Divider from "@material-ui/core/Divider";
import Loader from "../../Loader";
import axios from "axios";
import {
  DatePicker,
  MuiPickersUtilsProvider,
  TimePicker,
} from "material-ui-pickers";
import PropTypes, { string } from "prop-types";
//import React, { Component } from "react";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { withSnackbar, useSnackbar } from "notistack";
import Rating from "material-ui-rating";
import { v4 as uuid } from "uuid";
import { addUserInformation } from "../../actions/userActions";
import { updateProfileInformation } from "../../actions/userActions";
import Avatar from "@material-ui/core/Avatar";
import React, { Fragment, useState, useEffect, Component } from "react";
// import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from "react-redux";
import {
  updateRecruiter,
  getRecruiterDetails,
  UPDATE_PROFILE_RESET,
} from "../../actions/userActions";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import PhoneIcon from "@material-ui/icons/Phone";
import dateFormat from "dateformat";
import WorkIcon from "@material-ui/icons/Work";
import PaymentIcon from "@material-ui/icons/Payment";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CakeIcon from "@material-ui/icons/Cake";
import MuiPhoneNumber from "material-ui-phone-number";
import { Link } from "react-router-dom";

import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Dialog from "@mui/material/Dialog";

const styles = (theme) => ({
  bigAvatar: {
    width: "120px",
    height: "120px",
    // borderRadius: '25px',
    opacity: "100% ",
    position: "inherit",
    background: "#75a478",
    // border: '3px solid #A5D6A7',
  },
  imageOverlay: {
    marginBottom: "-135px",
  },
  card: {
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "3px solid #A5D6A7",
    borderRadius: "10px",
    padding: "100px",
    paddingTop: "40px",
  },
  detail: {
    fontSize: "1.0rem",
  },
  detailLabel: {
    fontSize: "1.0rem",
  },
  button: {
    padding: theme.spacing(2),
    margin: "auto",
    align: "center",
    "&:hover": {
      color: "black",
    },
  },
});

const exp = [
  { value: "0-2", label: "0-2" },
  { value: "3-5", label: "3-5" },
  { value: "6-10", label: "6-10" },
  { value: "greater than 10", label: "10 and above" },
];

const degree = [
  { value: "High School", label: "High School/GED" },
  { value: "Associate degree", label: "Associate degree" },
  {
    value: "Some college coursework completed",
    label: "Some college coursework completed",
  },
  { value: "Bachelor's Degree", label: "Bachelor's Degree" },
  { value: "Masters Degree", label: "Masters Degree" },
  { value: "Doctorate", label: "Doctorate" },
  { value: "Doctoral Degree", label: "Doctoral Degree" },
];

class ProfileEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      _id: this.props.match.params.id,
      name: "",
      email: "",
      role: "",
      title: "",
      skills: [],
      paypalid: "",
      payPalFirstName: "",
      payPalLastName: "",
      bio: "",
      dob: "",
      phone: "",
      city: "",
      state: "",
      country: "",
      zipcode: "",
      address_line1: "",
      address_line2: "",
      experience: "",
      highestDegree: "",
      major: "",
      jobDuties: "",
      expertise: [],
      openDialog: false,
      open: false,
      isLoading: true,
    };
  }
  componentDidMount() {
    axios.get("/api/users/user/" + this.state._id).then(
      (res) => {
        var newState = {
          name: res.data.name,
          email: res.data.email,
          phone: res.data.phone,
          role: res.data.role,
          title: res.data.professional_details.currentTitle,
          bio: res.data.professional_details.bio,
          dob: res.data.dob,
          city: res.data.address.city,
          state: res.data.address.state,
          country: res.data.address.country,
          zipcode: res.data.address.postalCode,
          address_line1: res.data.address.address_line1,
          address_line2: res.data.address.address_line2,
          skills: res.data.skillSet,
          experience: res.data.professional_details.yearsOfExperience,
          highestDegree: res.data.professional_details.highestDegree,
          major: res.data.professional_details.major,
          jobDuties: res.data.professional_details.jobDuties,
          paypalid:
            res.data.role === "screener" ? res.data.screener_info.payPalId : "",
          payPalFirstName:
            res.data.role === "screener"
              ? res.data.screener_info.payPalFirstName
              : "",
          payPalLastName:
            res.data.role === "screener"
              ? res.data.screener_info.payPalLastName
              : "",
          expertise:
            res.data.role === "screener"
              ? res.data.screener_info.expertise
              : [],
          isLoading: false,
        };

        this.setState(newState);
        // console.log(newState.interviewStatus)
        // this.setState({ loading: false });
      },
      (err) => {
        alert("An error occured! Try refreshing the page.", err);
      }
    );
  }

  //Open cancel dialog box
  handleClickOpen = () => {
    return this.setState({ openDialog: true });
  };

  //Open save dialog box
  handleOpeDialog = () => {
    return this.setState({ open: true });
  };

  //Close the dialog box
  handleClose = () => {
    this.setState({ openDialog: false });
    this.setState({ open: false });
  };

  handleNameChange = (event) => {
    this.setState({ name: event.target.value });
  };
  handleTitleChange = (event) => {
    this.setState({ title: event.target.value });
  };
  handleBioChange = (event) => {
    this.setState({ bio: event.target.value });
  };
  handleDateChange = (date) => {
    this.setState({ dob: date });
  };

  handlePhoneChange = (number) => {
    this.setState({ phone: number });
  };
  handleAddressChange = (event) => {
    this.setState({ address_line1: event.target.value });
  };
  handleCityChange = (event) => {
    this.setState({ city: event.target.value });
  };
  handleStateChange = (event) => {
    this.setState({ state: event.target.value });
  };
  handleCountryChange = (event) => {
    this.setState({ country: event.target.value });
  };
  handleZipcodeChange = (event) => {
    this.setState({ zipcode: event.target.value });
  };

  handleExpChange = (event) => {
    this.setState({ experience: event.target.value });
  };
  handleDegreeChange = (event) => {
    this.setState({ highestDegree: event.target.value });
  };
  handleMajorChange = (event) => {
    this.setState({ major: event.target.value });
  };
  handleJobDutiesChange = (event) => {
    this.setState({ jobDuties: event.target.value });
  };

  handlePayidChange = (event) => {
    this.setState({ paypalid: event.target.value });
  };
  handlePayfnameChange = (event) => {
    this.setState({ payPalFirstName: event.target.value });
  };
  handlePaylnameChange = (event) => {
    this.setState({ payPalLastName: event.target.value });
  };

  handleProfileEdit = () => {
    let route = "/Profile";
    return route;
  };

  onSubmit = (e) => {
    e.preventDefault();
    const {
      _id,
      name,
      title,
      bio,
      dob,
      phone,
      address_line1,
      address_line2,
      city,
      state,
      country,
      zipcode,
      experience,
      highestDegree,
      major,
      jobDuties,
      paypalid,
      payPalFirstName,
      payPalLastName,
      expertise,
    } = this.state;
    console.log(title);
    var profile = {
      _id,
      name,
      title,
      bio,
      dob,
      phone,
      address_line1,
      address_line2,
      city,
      state,
      country,
      zipcode,
      experience,
      highestDegree,
      major,
      jobDuties,
      paypalid,
      payPalFirstName,
      payPalLastName,
      expertise,
    };
    axios
      .put("/api/users/profileupdate/" + this.state._id, profile)

      .then((res) => {
        //alert('Updated successfully');
        let regMessage = <span>Updated Profile Successfully</span>;
        this.props.enqueueSnackbar(regMessage, {
          variant: "success",
          autoHideDuration: 3000,
        });
        this.props.history.push("/profile");
      })
      .catch((err) => {
        console.log(err.response);
        // alert('An error occured try submitting the form again');
        let regMessage = (
          <span>An error occured try submitting the form again</span>
        );
        this.props.enqueueSnackbar(regMessage, {
          variant: "error",
          autoHideDuration: 3000,
        });
      });
  };

  render() {
    const { classes } = this.props;

    var i = this.state.name.indexOf(" ");
    var firstName = this.state.name.slice(0, i).trim();
    var lastName = this.state.name.slice(i + 1, this.state.name.length).trim();

    let lastnameupper = lastName.toUpperCase();
    let firstnameupper = firstName.toUpperCase();

    const paperStyle = { padding: 10, display: "flex", flexDirection: "row" };
    const divstyleleft = { width: "60%", float: "left" };
    const divstyleright = { width: "40%", float: "right" };

    if (this.state.isLoading) return <Loader />;
    else
      return (
        <div className="profile-component" style={{ padding: "140px" }}>
          <Grid
            container
            className={classes.imageOverlay}
            id="outside-img-container"
            direction="column"
            justify="center"
            alignItems="center"
          >
            <Avatar
              alt="profile"
              // src={profilePic}
              className={`${classes.bigAvatar}`}
              variant="circle"
              // style= {{backgroundcolor:"black"}}
            >
              <h1>
                {" "}
                <b> {firstnameupper[0] + lastnameupper[0]}</b>
              </h1>
            </Avatar>
            <span className="headers">{this.state.name}</span>
          </Grid>

          <Card className={classes.card}>
            <div
              className="mart-100 d-flex flex-column p-2"
              id="card-parent-flex"
            >
              <div className="flex-fill mb-5">
                <div className="flex-fill mt-4 mb-4">
                  <AccountCircleIcon />{" "}
                  <TextField
                    required
                    className="text-field"
                    id="name"
                    name="name"
                    value={this.state.name}
                    onChange={this.handleNameChange}
                    label="Name"
                    placeholder="Enter Name"
                    autoComplete="name"
                    //  onKeyPress={(e) => isLetter(e)}
                  />
                  &nbsp; &nbsp; &nbsp; &nbsp;
                  <CakeIcon />{" "}
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      required
                      id="dob"
                      label="Birthday"
                      name="dob"
                      format="MM/dd/yyyy"
                      value={this.state.dob}
                      // onChange={handleDateChange('contact_info.dob')}
                      onChange={this.handleDateChange}
                      className="dob-field"
                    />
                  </MuiPickersUtilsProvider>
                  &nbsp;&nbsp;&nbsp;
                  <WorkIcon />
                  <TextField
                    required
                    className="text-field"
                    id="current Title"
                    name="current Title"
                    value={this.state.title}
                    //   onChange={handleProfessionalChange('professional_details.currentTitle')}
                    onChange={this.handleTitleChange}
                    label="title"
                    //  placeholder="Current Title"
                    //autoComplete="name"
                  />
                </div>

                <div
                  style={{ width: "600px" }}
                  className="flex-fill d-flex flex-row"
                >
                  <TextField
                    style={{ flex: 1, flexDirection: "row", flexWrap: "wrap" }}
                    id="bio"
                    name="bio"
                    value={this.state.bio}
                    //  onChange={handleProfessionalChange('professional_details.bio')}
                    onChange={this.handleBioChange}
                    label="Professional Summary "
                    multiline
                    margin="normal"
                    variant="outlined"
                    autoComplete="on"
                    fullWidth
                    // className="flex-fill mt-2 p-3"
                  />
                </div>
              </div>

              <Dialog
                open={this.state.openDialog}
                onClose={this.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Warning: All your current changes will be lost when you
                    leave.
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={this.handleClose}
                    style={{ backgroundColor: "#D73B30", color: "white" }}
                  >
                    Cancel
                  </Button>
                  <Button
                    autoFocus
                    style={{
                      backgroundColor: "#75A478",
                      color: "white",
                      margin: "6px",
                    }}
                    href={this.handleProfileEdit()}
                  >
                    Confirm
                  </Button>
                </DialogActions>
              </Dialog>

              {/* Save Diaglog */}
              <Dialog
                open={this.state.open}
                onClose={this.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Warning: Do you want to save the current changes and exit?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={this.handleClose}
                    style={{ backgroundColor: "#D73B30", color: "white" }}
                  >
                    Cancel
                  </Button>
                  <Button
                    autoFocus
                    style={{
                      backgroundColor: "#75A478",
                      color: "white",
                      margin: "6px",
                    }}
                    onClick={this.onSubmit}
                    // href={this.handleProfileEdit()}
                  >
                    Save
                  </Button>
                </DialogActions>
              </Dialog>
              <Typography variant="h6" align="left">
                <PhoneIcon /> &nbsp; Contact Details:
              </Typography>

              <div>
                <MuiPhoneNumber
                  required
                  id="phone"
                  name="phone"
                  label="Primary Number "
                  value={this.state.phone}
                  defaultCountry={"us"}
                  margin="normal"
                  className="text-field"
                  // onChange={handleDateChange('contact_info.phone')}
                  onChange={this.handlePhoneChange}
                />
                &nbsp; &nbsp; &nbsp; &nbsp;
                <TextField
                  required
                  id="email"
                  name="email"
                  value={this.state.email}
                  //  onChange={this.handleInputChange}
                  //   onChange={handleContactInfoChange(' contact_info.email')}
                  placeholder="Email"
                  label="Email"
                  margin="normal"
                  className="text-field"
                  autoComplete="on"
                  disabled
                />
                &nbsp; &nbsp; &nbsp; &nbsp;
                <TextField
                  required
                  id="address_line1"
                  name="address_line1"
                  value={this.state.address_line1}
                  onChange={this.handleAddressChange}
                  //   onChange={handleAddressChange('address.address_line1')}
                  label="Street address:"
                  margin="normal"
                  autoComplete="name"
                />
                &nbsp; &nbsp; &nbsp; &nbsp;
                <TextField
                  required
                  id="city"
                  name="city"
                  value={this.state.city}
                  onChange={this.handleCityChange}
                  //   onChange={handleAddressChange('address.city')}
                  label="City:"
                  margin="normal"
                  autoComplete="name"
                  // onKeyPress={(e) => isLetter(e)}
                />
                &nbsp; &nbsp; &nbsp; &nbsp;
                <TextField
                  required
                  id="state"
                  name="state"
                  value={this.state.state}
                  onChange={this.handleStateChange}
                  //   onChange={handleAddressChange('address.state')}
                  label="State:"
                  margin="normal"
                  autoComplete="on"
                  //onKeyPress={(e) => isLetter(e)}
                />
                &nbsp; &nbsp; &nbsp; &nbsp;
                <TextField
                  required
                  id="country"
                  name="country"
                  value={this.state.country}
                  onChange={this.handleCountryChange}
                  //   onChange={handleAddressChange('address.country')}
                  label="Country:"
                  margin="normal"
                  //  onKeyPress={(e) => isLetter(e)}
                  autoComplete="on"
                />
                &nbsp; &nbsp; &nbsp; &nbsp;
                <TextField
                  required
                  type="number"
                  id="postalCode"
                  name="postalCode"
                  value={this.state.zipcode}
                  onChange={this.handleZipcodeChange}
                  //   onChange={handleAddressChange('address.postalCode')}
                  label="Zip Code:"
                  margin="normal"
                  autoComplete="on"
                  onInput={(e) => {
                    e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, 6);
                  }}
                />
              </div>
              <br />
              <br />
              <Typography variant="h6" align="left">
                <WorkIcon />
                &nbsp; Professional Details:
              </Typography>
              <div className="d-flex flex-row mt-3" id="title-row">
                <TextField
                  id="currentTitle"
                  name="currentTitle"
                  value={this.state.title}
                  onChange={this.handleTitleChange}
                  // onChange={handleDetailChange('professional_details.currentTitle')}
                  margin="normal"
                  label="Current Title"
                  autoComplete="on"
                />
              </div>
              <div className="d-flex flex-row mt-3" id="title-row">
                <TextField
                  id="Job Duties"
                  name="Job Duties"
                  value={this.state.jobDuties}
                  onChange={this.handleJobDutiesChange}
                  // onChange={handleDetailChange('professional_details.currentTitle')}
                  margin="normal"
                  label="Job Duties"
                  fullWidth
                  autoComplete="on"
                />
              </div>

              <div className="d-flex flex-column mt-3">
                <InputLabel htmlFor="select-multiple">Time at job</InputLabel>
                <Select
                  id="yrs"
                  name="yrs"
                  value={this.state.experience}
                  onChange={this.handleExpChange}
                  // onChange={handleDetailChange('professional_details.yearsOfExperience')}
                  label="Job Experience"
                  displayEmpty
                  className="flex-fill mt-2"
                  autoComplete="on"
                >
                  <MenuItem value="">
                    <em>Select Experience</em>
                  </MenuItem>
                  {exp.map((experience) => (
                    // <option value={option.value}>{option.label}</option>
                    <MenuItem value={experience.value}>
                      {experience.label}
                    </MenuItem>
                  ))}
                </Select>
              </div>

              <div className="d-flex flex-column mt-3">
                <InputLabel shrink htmlFor="HighestDegree">
                  Highest Degree
                </InputLabel>
                <Select
                  id="highestDegree"
                  name="highestDegree"
                  label="Highest Degree"
                  value={this.state.highestDegree}
                  onChange={this.handleDegreeChange}
                  // onChange={handleDetailChange('professional_details.highestDegree')}
                  className="mt-2"
                  autoComplete="on"
                  displayEmpty
                >
                  <MenuItem value="">
                    <em>Select Highest Degree</em>
                  </MenuItem>
                  {degree.map((degree) => (
                    // <option value={option.value}>{option.label}</option>
                    <MenuItem value={degree.value}>{degree.label}</MenuItem>
                  ))}
                </Select>
              </div>

              <div className="flex-fill d-flex flex-column mt-3">
                <InputLabel shrink>Major</InputLabel>
                <TextField
                  id="major"
                  name="major"
                  value={this.state.major}
                  onChange={this.handleMajorChange}
                  className="mt-2 mr-5"
                  // onChange={handleDetailChange('professional_details.major')}
                  fullWidth
                  autoComplete="on"
                />
              </div>
              {this.state.role === "screener" ? (
                <>
                  <div className="flex-fill mb-5">
                    <br /> <br /> <br />
                    <Typography variant="h6" align="left">
                      <PaymentIcon />
                      &nbsp; Paypal Details:
                    </Typography>
                    <div className="d-flex flex-row mt-3" id="title-row">
                      <TextField
                        required
                        id="PaypalID"
                        name="PaypalID"
                        value={this.state.paypalid}
                        onChange={this.handlePayidChange}
                        // onChange={handlepaymentChange("payment_info.payPalId")}
                        //onChange={handlepaymentChange('payment_info.payPalId')}
                        margin="normal"
                        label="PaypalID"
                        fullWidth
                        autoComplete="on"
                      />
                    </div>
                    <div className="d-flex flex-row mt-3" id="title-row">
                      <TextField
                        required
                        id="Paypal First Name"
                        name="Paypal FirstName"
                        value={this.state.payPalFirstName}
                        onChange={this.handlePayfnameChange}
                        // onChange={handlepaymentChange("payment_info.payPalFirstName")}
                        //onChange={handlepaymentChange('payment_info.payPalFirstName')}
                        margin="normal"
                        label="Paypal FirstName"
                        fullWidth
                        autoComplete="on"

                        //onKeyPress={(e) => isLetter(e)}
                      />
                    </div>
                    <div className="d-flex flex-row mt-3" id="title-row">
                      <TextField
                        required
                        id="Paypal LastName"
                        name="Paypal LastName"
                        value={this.state.payPalLastName}
                        onChange={this.handlePaylnameChange}
                        // onChange={handlepaymentChange("payment_info.payPalLastName")}
                        // onChange={handlepaymentChange('payment_info.payPalLastName')}
                        margin="normal"
                        label="Paypal LastName"
                        fullWidth
                        autoComplete="on"

                        //onKeyPress={(e) => isLetter(e)}
                      />
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>

            <div>
              <div className="float-right">
                <Button
                  variant="contained"
                  color="secondary"
                  size="small"
                  className={classes.button}
                  // onClick={this.onSubmit}
                  onClick={this.handleOpeDialog}
                  style={{
                    color: "white",
                  }}
                >
                  Save
                </Button>
              </div>

              <Button
                onClick={this.handleClickOpen}
                variant="contained"
                color="secondary"
                size="small"
                className={classes.button}
                // href={this.handleProfileEdit()}
                style={{
                  backgroundColor: "#D73B30",
                  color: "white",
                }}
              >
                Back
              </Button>
            </div>
          </Card>
        </div>
      );
  }
}

export default withSnackbar(withStyles(styles)(ProfileEdit));
