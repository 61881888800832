import React, { useState, useEffect } from 'react'
import skillers from './skills.json'

function Skills() {
    const [skills, setSkills] = useState([])
    // let skills = [];

    const fetchAllData = async () => {
        console.log('fetching')

        const data = await fetch('/api/schedule/skills/allskills')
        const result = await data.json()
        // console.log(result, skillers)
        //Fetching from Backend
        // return skills;

        const jsonSkills = skillers.map(el => el.skill);
        const backendSkills = result.skills.map(el => el.skill)
        // console.log(jsonSkills, backendSkills)
        const newArray = [...new Set([...backendSkills, ...jsonSkills])];
        console.log(newArray)
        const skillsArray = newArray.map(skill => {
            return { skill: skill }
        })
        // console.log(skillsArray)
        // skills = skillsArray
        // console.log(skills)
        setSkills(skillsArray)

    }

    console.log(skills)

    useEffect(() => {
        fetchAllData()
    }, [])
    return skills;


}

export default Skills