import React, { Component } from "react";
import classNames from "classnames";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

class Footer extends Component {
    render() {
        const { classes } = this.props;
        console.log(this.props)
        return (
            <div className={classes.root} id="footer">
                <Grid
                    container
                    spacing={0}
                    className={classNames(classes.footerText, classes.footerSections)}
                >
                    <Grid item xs={12}>
                        <Typography
                            className={classes.white}
                            variant="caption"
                            gutterBottom
                            align="center"
                        >
                         Copyright &#169;  {(new Date().getFullYear())} Infomatics Corp.All rights reserved. Confidential and Proprietary Information of Infomatics Corporation.
                        </Typography>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default Footer;