import React, { useState, useRef } from 'react'


//MUI Imports
import Slide from "@mui/material/Slide";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import BusinessIcon from '@mui/icons-material/Business';
import PhoneInput from "react-phone-input-2";


import axios from 'axios'


//Snackbar
import UserSnackbar from '../../../snackBars/UserSnackbar';
import { getPhonesRegex } from '../../../auth/PhoneValidation';


const PocDetails = ({ clientData, setAddClient, setClientData, nextPage, previousPage, setPreviousPage, count, setCount, mode, selectedClientId }) => {

    const saveRef = useRef(null)

    const id = sessionStorage.getItem("id");
    const companyId = sessionStorage.getItem("companyId");
    //form states


    const [pocDetails, setPocDetails] = useState(clientData.pocDetails)


    // store the regex of phone number patterns of all countries
    const [phone, setPhone] = useState(clientData.pocDetails.pocPhone)
    const phones = getPhonesRegex();
    const [phoneIsValid, setPhoneIsValid] = useState(true);


    //snackBar states
    const [openSnackbar, setOpenSnackbar] = useState(false)
    const [message, setMessage] = useState("")


    const [error, setError] = useState({
        email: '',
        phone: ''
    })


    //Submit handler
    const submitData = () => {
        let finalData = {}
        if (mode === "Edit") {
            finalData = [{
                about: clientData.about,
                id: selectedClientId,
                zoom: clientData.zoom,
                crop: clientData.crop,
                website: clientData.website,
                status: clientData.status,
                isAvatar: clientData.isAvatar,
                croppedAreaPixels: clientData.croppedAreaPixels,
                profileImage: clientData.profileImage,
                croppedImage: clientData.croppedImage,
                imagePublicId: clientData.imagePublicId,
                croppedImagePublicId: clientData.croppedImagePublicId,
                companyName: clientData.companyName,
                email: clientData.email,
                phone: clientData.phone,
                address1: clientData.address1,
                address2: clientData.address2,
                city: clientData.city,
                state: clientData.state,
                stateId: clientData.stateId,
                country: clientData.country,
                countryId: clientData.countryId,
                postalCode: clientData.postalCode,
                pocDetails: pocDetails,
                interacted: clientData.interacted
            }]
        } else {
            finalData = [{
                about: clientData.about,
                zoom: clientData.zoom,
                crop: clientData.crop,
                website: clientData.website,
                status: clientData.status,
                isAvatar: clientData.isAvatar,
                croppedAreaPixels: clientData.croppedAreaPixels,
                profileImage: clientData.profileImage,
                croppedImage: clientData.croppedImage,
                imagePublicId: clientData.imagePublicId,
                croppedImagePublicId: clientData.croppedImagePublicId,
                companyName: clientData.companyName,
                email: clientData.email,
                phone: clientData.phone,
                address1: clientData.address1,
                address2: clientData.address2,
                city: clientData.city,
                state: clientData.state,
                stateId: clientData.stateId,
                country: clientData.country,
                countryId: clientData.countryId,
                postalCode: clientData.postalCode,
                pocDetails: pocDetails,
                interacted: clientData.interacted
            }]
        }


        const formData = new FormData();
        formData.append("finalData", JSON.stringify(finalData))


        console.log(formData.get("finalData"))


        axios.post(`/api/admin/${mode === "Edit" ? "edit-client" : "add-client"}/${id}/${companyId}`, formData, {
            headers: { "Content-Type": "multipart/form-data" },
        }).then((res) => {
            if (mode === "Edit") {
                setMessage("Client Updated Successfully")
            } else {
                setMessage("Client Added Successfully")
            }
            setOpenSnackbar(true)
            setTimeout(() => {
                setAddClient(false)
            }, 2000)
        }).catch((err) => {
            if (err.response.status === 404) {
                console.log(err)
                setMessage("Client already exists")
                setOpenSnackbar(true)
            }
        })
    }




    const handleSubmit = () => {
        if (pocDetails.pocEmail) {
            if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(pocDetails.pocEmail)) {
                return setError({ email: "Email is Invalid" })
            }
        }
        if (!phoneIsValid) {
            return setError({
                phone: "Invalid Phone Number",
            })
        }


        // setPocDetails({ ...pocDetails, pocPhone: phone })
        let data = { ...clientData, pocDetails }
        console.log(data)
        setClientData(data)
        submitData();
        saveRef.current.disabled = true //disable the save button
        setPreviousPage(false)
    }




    const goToPreviousPage = () => {


        //store current entered data
        const pocObj = {
            pocDetails: pocDetails,
        }
        let data = { ...clientData, ...pocObj }
        setClientData(data)
        setPreviousPage(true)
        if (nextPage) {
            setCount(count - 1)
        } else {
            setCount(count + 1)
        }
    }




    // handle input field change
    const handleChange = (evnt) => {
        const { name, value } = evnt.target;
        switch (name) {
            case 'PocName': setPocDetails({ ...pocDetails, pocName: value })
                break;
            case 'PocEmail': setPocDetails({ ...pocDetails, pocEmail: value })
                setError({ email: "" })
                break;
            case 'PocTitle': setPocDetails({ ...pocDetails, pocTitle: value })
                break;
        }




    };


    return (
        <div>
            <Slide direction={"left"} in={true} timeout={500}>
                <Box sx={{ flexGrow: 1, background: "#faf9f6" }}>
                    <Card
                        id="main-edit"
                        className=" form-card table-card"
                        style={{
                            // margin: "40px 70px",
                            padding: "15px 20px 18px 20px ",
                            boxShadow: "none",
                        }}
                    >


                        <Grid
                            container
                            spacing={0}
                            direction="row"
                            className="add-recruiter-card-container"
                        >
                            <Grid
                                item
                                xs={12}
                                sm={7}
                                md={6}
                                lg={12}
                            >
                                <div style={{ display: "flex", gap: "10px" }}>
                                    <BusinessIcon sx={{ color: "#2c3e50" }} />
                                    <h5 style={{ color: "#283848" }}>POC Details</h5>
                                </div>
                            </Grid>
                            <Grid container spacing={0} justifyContent="flex-end">


                                <Grid
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}


                                >
                                    <div >
                                        <form noValidate >
                                            <Grid
                                                xs={12}
                                                sm={12}
                                                md={12}
                                                lg={12}
                                                sx={{
                                                    display: "flex"
                                                }}
                                            >


                                                <Grid
                                                    xs={12}
                                                    sm={12}
                                                    md={12}
                                                    lg={12}


                                                >
                                                    <div className="input-container" >


                                                        <div className="split-inputs">
                                                            <div class="input-field-modal">
                                                                <label className="date-label">Name</label>
                                                                <input
                                                                    class="position"
                                                                    type="text"
                                                                    name="PocName"
                                                                    value={pocDetails.pocName}
                                                                    onChange={(evnt) => handleChange(evnt)}
                                                                    placeholder="POC Name"
                                                                />
                                                                <div style={{ height: "20px" }}>


                                                                </div>
                                                            </div>




                                                            <div class="input-field-modal">
                                                                <label className="date-label">Email</label>
                                                                <input
                                                                    class="company"
                                                                    type="text"
                                                                    name="PocEmail"
                                                                    value={pocDetails.pocEmail}
                                                                    onChange={(evnt) => handleChange(evnt)}
                                                                    placeholder="POC Email"
                                                                />
                                                                <div style={{ height: "20px" }}>
                                                                    <span className="text-danger">{error.email}</span>
                                                                </div>
                                                            </div>




                                                        </div>


                                                        <div className="split-inputs">
                                                            <div class="input-field-modal">
                                                                <label className="date-label">Title</label>
                                                                <input
                                                                    class="position"
                                                                    type="text"
                                                                    name="PocTitle"
                                                                    value={pocDetails.pocTitle}
                                                                    onChange={(evnt) => handleChange(evnt)}
                                                                    placeholder="POC Title"
                                                                />
                                                                <div style={{ height: "20px" }}>
                                                                </div>
                                                            </div>




                                                            <div class="input-field-modal">
                                                                <label className="date-label"> Phone</label>
                                                                <PhoneInput
                                                                    id="phone"
                                                                    placeholder="Contact No*"
                                                                    name="PocPhone"
                                                                    class="phone"
                                                                    setPhoneIsValid country={"us"}
                                                                    value={pocDetails.pocPhone}
                                                                    onChange={(phone, country) => {
                                                                        setPocDetails({ ...pocDetails, pocPhone: phone })

                                                                        // handlePhone(phone)
                                                                        var regex =
                                                                            phones[country.countryCode?.toUpperCase()];
                                                                        console.log(regex, "phoneinput")
                                                                        if (regex != undefined) {
                                                                            if (!regex.test(phone)) {
                                                                                setPhoneIsValid(false);
                                                                                return setError({
                                                                                    phone: "Invalid Phone Number",
                                                                                });
                                                                            } else {
                                                                                setPhoneIsValid(true);
                                                                                return setError({ phone: "" });
                                                                            }
                                                                        }
                                                                    }}


                                                                />
                                                                <div style={{ height: "20px" }}>
                                                                    <span className="text-danger">{error.phone}</span>
                                                                </div>
                                                            </div>




                                                        </div>
                                                    </div>
                                                </Grid>


                                            </Grid>


                                        </form>
                                    </div>
                                </Grid>


                            </Grid>
                            <div className="button-container navigation-btn-container" >
                                <button className="btn-style outlined-btn outlined-btn-animation" onClick={goToPreviousPage}>
                                    {" "}
                                    Previous
                                </button>
                                <button ref={saveRef} className="btn-style filled-btn filled-btn-animation"
                                    onClick={handleSubmit}
                                >
                                    {" "}
                                    {mode === "Edit" ? "Edit" : "Save"}
                                </button>


                            </div>
                        </Grid>
                    </Card>
                </Box>
            </Slide>


            <UserSnackbar
                openSnackbar={openSnackbar}
                setOpenSnackbar={setOpenSnackbar}
                message={message}
            />




        </div>
    )
}


export default PocDetails

