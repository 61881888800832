import { makeStyles } from '@material-ui/core/styles';
// import Table from "@material-ui/core/Table";
// import TableBody from "@material-ui/core/TableBody";
// import TableCell from "@material-ui/core/TableCell";
// import TableRow from "@material-ui/core/TableRow";
//import Typography from "@material-ui/core/Typography";
import React from "react";
import { Grid, Paper, Typography } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";


// const styles = makeStyles((theme) => ({
//     main: {
//         display: "block", // Fix IE 11 issue.
//         marginLeft: "107px",
//         [theme.breakpoints.up(400)]: {
//             marginLeft: "0px",
//         },
//         [theme.breakpoints.up(700)]: {
//             marginLeft: "107px",
//         },
//     },
//     textField: {
//         marginLeft: theme.spacing(),
//         marginRight: theme.spacing(),
//         width: "auto",
//         maxWidth: 200,
//     },
//     // the same css as in screener profile
//     table: {
//         marginLeft: "-115px",
//         marginRight: "10px",
//         width: "73%",
//         [theme.breakpoints.up(400)]: {
//             marginLeft: "-20px",
//         },
//         [theme.breakpoints.up(750)]: {
//             width: "73%",
//             marginLeft: "-115px",
//         },
//         [theme.breakpoints.up(1000)]: {
//             marginLeft: "52px",
//         },
//     },
//     leftCell: {
//         padding: "4px 0px 4px 24px",
//         width: "100%",
//         [theme.breakpoints.up(1020)]: {
//             width: "50%",
//         },
//     },
//     rightCell: {
//         padding: "4px 0px 4px 0px",
//         width: "100%",
//         [theme.breakpoints.up(1020)]: {
//             width: "50%",
//         },
//     },
//     expCell: {
//         padding: "4px 0px 4px 0px",
//         width: "100%",
//         [theme.breakpoints.up(1020)]: {
//             width: "50%",
//         },
//     },
//     profCell: {
//         borderBottom: "none",
//     },
//     detail: {
//         fontSize: "1.0rem",
//         marginLeft: "-20px",
//         margin: "8px",
//         width: "199px",
//         [theme.breakpoints.up(620)]: {
//             marginLeft: "0px",
//             width: "300px",
//         },
//         [theme.breakpoints.up(1020)]: {
//             width: `${82 - theme.spacing()}%`,
//             fontSize: "1.2rem",
//         },
//         [theme.breakpoints.up(1420)]: {
//             width: "56%",
//         },
//     },
//     profDetail: {
//         width: "214%",
//         [theme.breakpoints.up(400)]: {
//             width: "165%",
//             marginLeft: "15px",
//         },
//         [theme.breakpoints.up(620)]: {
//             width: "190%",
//             marginLeft: "15px",
//         },
//         [theme.breakpoints.up(750)]: {
//             width: "145%",
//             marginLeft: "15px",
//         },
//         [theme.breakpoints.up(1020)]: {
//             width: "190%",
//         },
//     },
//     detailLabel: {
//         fontSize: "1.0rem",
//         [theme.breakpoints.up(1020)]: {
//             fontSize: "1.3rem",
//         },
//     },
//     expDetailLabel: {
//         width: "122px",
//         marginLeft: "27px",
//     },
//     longDetailLabel: {
//         width: "160px",
//         [theme.breakpoints.up(620)]: {
//             width: "200px",
//         },
//     },
//     extraLongDetailLabel: {
//         width: "216px",
//         [theme.breakpoints.up(1020)]: {
//             width: "296px",
//         },
//     },
// }));


// const useStyles = makeStyles((theme) => ({
//     root: {
//         flexGrow: 1,
//     },
//     paper: {
//         padding: theme.spacing(2),
//         textAlign: 'center',
//         color: theme.palette.text.secondary,
//          height:'400px',
//         // maxHeight: 580,
//          overflow: 'auto',
//          marginTop:'20px',
//     },
//     paper1:{
//         padding: theme.spacing(2),
//         textAlign: 'center',
//         color: theme.palette.text.secondary,
//         height:'195px',
//         overflow:'auto',
//         padding:'15px',
//         marginTop:'2px',
//         marginBottom:'5px',
//         // border: '2px solid #75a478 '

//     }
// }));

export default function Confirm(props) {
        // const classes = styles();
        const {
            values: {
                contact_info,
                job_experience,
                professional_details,
                expertise,
                skills,
                role
                // agreement,
                // payment_info,
            },
        } = props;

        const address = contact_info.address;

        let dob_formatted = new Date(contact_info.dob);
        const paperStyle = { padding: 10,  width: '60%', margin: '0px 0px 20px 350px' ,display:'flex', flexDirection:'row'  }

        return (
            <>
            <div>
                {/* <main className={classes.main}> */}
                {/* <Paper className={classes.paper1} elevation={5}>
                                <Typography className="typographyStyle">Confirm Profile Details</Typography>
                                
                </Paper> */}
                    {/* <Table className={classes.table}>
                        <TableBody>
                            <TableRow>
                                <TableCell className={classes.leftCell}>
                                    
                                    <Typography className={classes.detailLabel} variant="subtitle2">
                                        Name:
                                    </Typography>
                                </TableCell>
                                <TableCell className={classes.rightCell}>
                                    <Typography variant="body1" className={classes.detail} color="textPrimary">
                                        {contact_info.name}
                                    </Typography>
                                </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell className={classes.leftCell}>
                                    <Typography className={classes.detailLabel} variant="subtitle2">
                                        Email:
                                    </Typography>
                                </TableCell>
                                <TableCell className={classes.rightCell}>
                                    <Typography variant="body1" className={classes.detail} color="textPrimary">
                                        {contact_info.email}
                                    </Typography>
                                </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell className={classes.leftCell}>
                                    <Typography className={classes.detailLabel} variant="subtitle2">
                                        Date of Birth:
                                    </Typography>
                                </TableCell>
                                <TableCell className={classes.rightCell}>
                                    <Typography variant="body1" className={classes.detail} color="textPrimary">
                                        {dob_formatted.toDateString()}
                                    </Typography>
                                </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell className={classes.leftCell}>
                                    <Typography className={`${classes.detailLabel} ${classes.longDetailLabel}`} variant="subtitle2">
                                        Phone Number:
                                    </Typography>
                                </TableCell>
                                <TableCell className={classes.rightCell}>
                                    <Typography variant="body1" className={classes.detail} color="textPrimary">
                                        {contact_info.phone}
                                    </Typography>
                                </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell className={classes.leftCell}>
                                    <Typography className={classes.detailLabel} variant="subtitle2">
                                        Address:
                                    </Typography>
                                </TableCell>
                                <TableCell className={classes.rightCell}>
                                    <Typography variant="body1" className={classes.detail} color="textPrimary">
                                        {address.address_line1} <br />
                                        {address.address_line2.length > 0 ? address.address_line2 : null} <br />
                                        {address.city}, {address.state} - {address.postalCode}
                                        <br />
                                        {address.country !== "United States" ? address.country : null}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.leftCell}>
                                    <Typography className={classes.detailLabel} variant="subtitle2">
                                        Current Title:
                                    </Typography>
                                </TableCell>
                                <TableCell className={classes.rightCell}>
                                    <Typography className={classes.detail} variant="body1" color="textPrimary">
                                        {professional_details.currentTitle}
                                    </Typography>
                                </TableCell>
                            </TableRow>

                            {role === 'screener' ? (<TableRow>
                                <TableCell className={classes.expCell}>
                                    <Typography className={`${classes.detailLabel} ${classes.expDetailLabel}`} variant="subtitle2">
                                        Area of Expertise
                                    </Typography>
                                </TableCell>
                                <TableCell className={classes.rightCell}>
                                    {expertise.map((aOE, idx) => (
                                        <Typography variant="body1" className={classes.detail} color="textPrimary" key={`aOE ` + idx}>
                                            {aOE}
                                        </Typography>
                                    ))}
                                </TableCell>
                            </TableRow>)
                            :
                            (<TableRow>
                                <TableCell className={classes.expCell}>
                                    <Typography className={`${classes.detailLabel} ${classes.expDetailLabel}`} variant="subtitle2">
                                        Experience
                                    </Typography>
                                </TableCell>
                                <TableCell className={classes.rightCell}>
                                    {job_experience.map((experience, idx) => (
                                        <Typography variant="body1" className={classes.detail} color="textPrimary" key={`Experience-` + idx}>
                                            <span>Company: {experience.company}</span>
                                            <br/>
                                            <span>Job Title: {experience.job_title}</span>
                                            <br/>
                                            <span>Time at the company: {experience.yrs}</span>
                                            <br/>
                                            <span>Job Description: {experience.description}</span>
                                        </Typography>
                                    ))}
                                </TableCell>
                            </TableRow>)}


                            <TableRow>
                                <TableCell className={classes.leftCell}>
                                    <Typography className={`${classes.detailLabel} ${classes.extraLongDetailLabel}`} variant="subtitle2">
                                        Highest Education:
                                    </Typography>
                                </TableCell>
                                <TableCell className={classes.rightCell}>
                                    <Typography variant="body1" className={classes.detail} color="textPrimary">
                                        {professional_details.highestDegree}
                                    </Typography>
                                </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell className={classes.leftCell}>
                                    <Typography className={classes.detailLabel} variant="subtitle2">
                                        Skills:
                                    </Typography>
                                </TableCell>
                                <TableCell className={classes.rightCell}>
                                    {skills.skillSet.map((skill, idx) => (
                                        <Typography key={idx} variant="body1" color="textPrimary" className={classes.detail}>
                                            <b>{skill[0]}: </b>
                                            {` ` + skill[1]} years
                                            <br />
                                        </Typography>
                                    ))}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.leftCell}>
                                    <Typography className={`${classes.detailLabel} ${classes.extraLongDetailLabel}`} variant="subtitle2">
                                        Years of Experience:
                                    </Typography>
                                </TableCell>
                                <TableCell className={classes.rightCell}>
                                    <Typography variant="body1" className={classes.detail} color="textPrimary">
                                        {professional_details.yearsOfExperience === "" ? 0 : professional_details.yearsOfExperience} years
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={`${classes.leftCell} ${classes.profCell}`}>
                                    <Typography className={`${classes.detailLabel} ${classes.extraLongDetailLabel}`} variant="subtitle2">
                                        Professional Summary:
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Typography
                                        align="center"
                                        variant="body1"
                                        className={`${classes.detail} ${classes.profDetail}`}
                                        color="textPrimary"
                                    >
                                        {professional_details.bio}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table> */}
                {/* </main> */}
                
                
                <Grid container spacing={3}>
                        <Grid item xs={12} sm={12}>
                            <Paper elevation={10} style={paperStyle}>
                        {/* <h5 >Contact Details</h5><br/><br/> */}
                            <Typography><b>CONTACT DETAILS</b></Typography>
                            <Divider variant="middle" /><br></br>    

                           

                       <div>
                       <br/><Typography  > FullName : {contact_info.name} </Typography>
                       <br/><Typography  > Email ID :{contact_info.email} </Typography>
                       <br/><Typography  > Date of Birth :{dob_formatted.toDateString()} </Typography>
                       <br/><Typography  > Phone Number :{contact_info.phone} </Typography>
                       <br/><Typography  > Address : {address.address_line1} <br />
                                        {address.address_line2.length > 0 ? address.address_line2 : null} <br />
                                        {address.city}, {address.state} - {address.postalCode}
                                        <br />
                                        {address.country !== "United States" ? address.country : null} </Typography>
                      
                                       
                       
                        </div>
                        
                        {/* <Paper elevation={10} style={paperStyle}>
                        <Typography  variant="subtitle2">
                                        Name: {contact_info.name}
                        </Typography>
                        </Paper> */}
                        
                        </Paper>
                       
                        </Grid>


                        <Grid item xs={12}>
                            <Paper elevation={10} style={paperStyle}>
                        {/* <h5 >Contact Details</h5><br/><br/> */}
                            <Typography><b>PROFESSIONAL DETAILS</b></Typography>
                            <Divider variant="middle" /><br></br>    

                           

                       <div>
                       <br/><Typography  > Current Title : {professional_details.currentTitle} </Typography>
                       <br/>{role === 'screener' ? ( <Typography >Area of Expertise : {expertise.map((aOE, idx) => (
                                        <Typography  key={`aOE ` + idx}>
                                            {aOE}
                                        </Typography>
                                    ))}</Typography>
                       ) : (
                        <Typography >
                                Experience : {job_experience.map((experience, idx) => (
                                        <Typography  key={`Experience-` + idx}>
                                             <br/><span>Company: {experience.company}</span>
                                            <br/>
                                            <br/><span>Job Title: {experience.job_title}</span>
                                            <br/>
                                            <br/><span>Time at the company: {experience.yrs}</span>
                                            <br/>
                                            <br/><span>Job Description: {experience.description}</span>
                                        </Typography>
                                    ))}
                        </Typography>
                       )} 

                        <br/><Typography >  Highest Education : {professional_details.highestDegree}</Typography>
                        <br/><Typography >  Skills: {skills.skillSet.map((skill, idx) => (
                                        <Typography key={idx} >
                                           {skill[0]}: 
                                            {` ` + skill[1]} years
                                           
                                        </Typography>
                        ))}</Typography>

                        <br/><Typography >  Years of Experience : {professional_details.yearsOfExperience === "" ? 0 : professional_details.yearsOfExperience} years </Typography>
                        <br/><Typography >  Professional Summary : {professional_details.bio}</Typography>
                        <br/><Typography >  Job Duties : {professional_details.jobDuties}</Typography>             
                        
                                                        
                       
                        </div>
                        
                        {/* <Paper elevation={10} style={paperStyle}>
                        <Typography  variant="subtitle2">
                                        Name: {contact_info.name}
                        </Typography>
                        </Paper> */}
                        
                        </Paper>
                       
                        </Grid>              
                        
                     
        </Grid>

                </div>
            </>
        );
    }
