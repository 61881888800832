import withStyles from "@material-ui/core/styles/withStyles";
import { withSnackbar } from "notistack";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { registerUser } from "../../actions/authActions";
import AddRecruiterForm from "./AddRecruiterForm";
import axios from "axios";

const styles = (theme) => ({
    // form: {
    //     width: "720px", // Fix IE 11 issue.
    //     marginTop: "35px",
    // },
    formToppper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "3px",
        marginBottom: "15px",
    },
    submit: {
        marginTop: theme.spacing(3),
    },
    button: {
        padding: theme.spacing(2),
        margin: 10,
        align: "center",
    },
    logo: {
        height: "180px",
        width: "600px",
    },
});

class AddRecruiter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            email: "",
            password: "",
            password2: "",
            role: "recruiter",
            companyID:"",
            userList: [],
            errors: {},
            isErrors: {
                name: false,
                email: false,
                password: false,
                password2: false,
                misc: false,
            },
        };

        // this.snackbar = useSnackbar().bind(this);
        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        // this.handlePhoneChange = this.handlePhoneChange.bind(this);
    }

    componentDidMount() {
        // const { user } = this.props.auth;
       

          axios.get("/api/users/users").then((response) => {
            this.setState({
              userList: response.data,
              
            });
          });
        // If logged in and user navigates to Register page, should redirect them to dashboard
        // this should be handled with the Gaurd routes. Will look at it.ddddddddd7
        // if (this.props.auth.isAuthenticated && user.role === "recruiter") {
        //     this.props.history.push("/dashboard-recruiter");
        // }
        // if (this.props.auth.isAuthenticated && user.role === "screener") {
        //     this.props.history.push("/dashboard-screener");
        // }
        
        

        let message = "Please add recruiter";
        this.props.enqueueSnackbar(message, {
            variant: "info",
            autoHideDuration: 3000,
        });
        // if (this.props.auth.isAuthenticated && user.role === "recruiter") {
        //     this.props.history.push("/dashboard-recruiter");
        // }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const user = nextProps.auth.user;
        // if (this.props.auth.isAuthenticated && user.role === "recruiter") {
        //     this.props.history.push("/recruiter-dashboard");
        // }

        if (nextProps.errors) {
            let isErrorName = Boolean(nextProps.errors.response.data.name);
            let isErrorEmail = Boolean(nextProps.errors.response.data.email);
            let isErrorPass = Boolean(nextProps.errors.response.data.password);
            let isErrorPass2 = Boolean(nextProps.errors.response.data.password2);
            let isErrorMisc = Boolean(nextProps.errors.response.data.message);

            this.setState({
                errors: nextProps.errors.response.data,
                isErrors: {
                    name: isErrorName,
                    email: isErrorEmail,
                    password: isErrorPass,
                    password2: isErrorPass2,
                    misc: isErrorMisc,
                },
            });

            let nameMessage = nextProps.errors.response.data.name;
            let emailMessage = nextProps.errors.response.data.email;
            let passMessage = nextProps.errors.response.data.password;
            let pass2Message = nextProps.errors.response.data.password2;
            let miscMessage = 'The server has failed us';
            let errormessage='Fill in required fields';
            let notifMessage = `Fill in required fields: ${isErrorName ? nameMessage + ", " : ""}${
                isErrorEmail ? emailMessage + ", " : ""
            }${isErrorPass ? passMessage + ", " : ""}
            ${isErrorPass2 ? pass2Message + " " : ""} ${isErrorMisc ? miscMessage + " " : ""}`;

            if (isErrorName || isErrorEmail || isErrorPass || isErrorPass2 || isErrorMisc) {
                this.props.enqueueSnackbar(errormessage, {
                    variant: "error",
                });
            }
        }
    }
    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };
    // handlePhoneChange = input => event => {
    //   this.setState({ [input]: event });
    // };
    onSubmit = (e) => {
        e.preventDefault();
        // let preNotifMessage = "Please wait while we add the recruiter profile";
        // this.props.enqueueSnackbar(preNotifMessage, {
        //     variant: "default",
        //     autoHideDuration: 1000,
        // });

        const {
            auth: { user },
          } = this.props;
          const userId = user.id;
          console.log(userId)
          console.log(user)
        

        this.state.userList.forEach((item) => {
            if(item._id === userId){
                this.state.companyID = item.companyID;
            }
        })

        const newUser = {
            name: this.state.name,
            email: this.state.email,
            password: this.state.password,
            password2: this.state.password2,
            role: this.state.role,
            companyID: this.state.companyID
        };
       
            // this.props.history.push("/home");
    

        this.props.registerUser(newUser, this.props.history, this.props.enqueueSnackbar);
       
    };

    render() {
        const { errors, isErrors, email, password, password2, name, role } = this.state;
        const { classes } = this.props;

        const {
            auth: { user },
          } = this.props;
          const userId = user.id;
          console.log(userId)
          console.log(user)
        
          let company = ""
        this.state.userList.forEach((item) => {
            if(item._id === userId){
               this.state.companyID = item.companyID
            }
        })

        console.log(this.state.companyID)

        return (
            <main className="register-component">
                <div className="container-fluid p-4">
                    <AddRecruiterForm
                        name={name}
                        email={email}
                        password={password}
                        password2={password2}
                        role={role}
                        onChange={this.onChange}
                        // onPhoneChange={this.handlePhoneChange}
                        onSubmit={this.onSubmit}
                        errors={errors}
                        isErrors={isErrors}
                        classes={classes}
                    />
                </div>
            </main>
        );
    }
}

AddRecruiter.propTypes = {
    registerUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    errors: state.errors,
});

export default withSnackbar(withStyles(styles)(connect(mapStateToProps, { registerUser })(withRouter(AddRecruiter))));