import React, { useState, useEffect } from "react";
import axios from "axios";
import CompanyMenu from "../../userProfiles/CompanyMenu";
import Settings from "./Settings";

// CSS import
import "../../company admin/CompanyAdmin.css";
import UserRole from "./UserRole/UserRole";
import General from "./General/General";
import AddUserRole from "./UserRole/AddUserRole";
import EditUserRole from "./UserRole/EditUserRole";
import PageNotFound from "../../ErrorHandling/PageNotFound";
import Footer from "../../../Footer/Footer";

const RecruiterMainSettings = () => {

    //company id and role
    const id = sessionStorage.getItem("id"); //user id
    const compId = sessionStorage.getItem("compId") //company id
    const companyId = sessionStorage.getItem("companyId"); //company ref id
    const role = sessionStorage.getItem("role");  //profile login role
    const profileType = sessionStorage.getItem("profileType") // type of user

    //Navbar states
    const [open, setOpen] = useState();
    const [sidebarOpen, setsidebarOpen] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [interacted, setInteracted] = useState("");
    const [croppedImg, setCroppedImg] = useState("");
    const [profileImage, setProfileImage] = useState("");
    const [defaultAvatar, setDefaultAvatar] = useState("");

    //settings components
    const [mainSettings, setMainSettings] = useState({
        settings: true,
        general: false,
        userroles: false,
        notifications: false,
        payments: false,
        billings: false,
    });

    //to display different pages
    const [pageName, setPageName] = useState("settings")
    const [selectedRole, setSelectedRole] = useState({});

    const [hasSettingsAccess, setHasSettingsAccess] = useState(true)

    //fetching data for navbar
    useEffect(() => {

        axios.get(`/api/admin/userRole/side-list/${companyId}`)
            .then(res => {
                console.log(res.data[0].profileType)
                let modules = res.data[0].profileType.filter(item => item.roleName.toLowerCase() === profileType.toLowerCase())
                console.log(modules)
                modules = modules[0].modules.filter(item => item.title === "Settings")
                console.log(modules)
                if (!modules[0].checked) setHasSettingsAccess(false)
            })
            .catch(err => {
                console.log(err)
            })

        if (profileType.toLowerCase() == "recruiter") {
            const { data } = axios.get(`/api/recruiter/${id}`).then((res) => {
                const {
                    defaultAvatar,
                    profileImage,
                    croppedImage,
                    interacted,
                    firstName,
                } = res.data.user;
                setDefaultAvatar(defaultAvatar);
                setProfileImage(profileImage);
                setCroppedImg(croppedImage);
                setInteracted(interacted);
                setCompanyName(firstName);
            });
        } else {
            const { data } = axios.get(`/api/myUsers/${id}`).then((res) => {
                const {
                    defaultAvatar,
                    profileImage,
                    croppedImage,
                    interacted,
                    firstName,
                } = res.data.user;
                setDefaultAvatar(defaultAvatar);
                setProfileImage(profileImage);
                setCroppedImg(croppedImage);
                setInteracted(interacted);
                setCompanyName(firstName);
            });
        }
    }, []);

    return (
        <>
            {hasSettingsAccess ? <div>{/* Navbar Component */}
                <CompanyMenu
                    open={open}
                    setOpen={setOpen}
                    sidebarOpen={sidebarOpen}
                    setsidebarOpen={setsidebarOpen}
                    companyName={companyName}
                    croppedImg={croppedImg}
                    profileImage={profileImage}
                    interacted={interacted}
                    defaultAvatar={defaultAvatar}
                />

                {/* Recruiter List Component */}
                {mainSettings.settings && (
                    pageName === "settings" ?
                        <Settings
                            sidebarOpen={sidebarOpen}
                            mainSettings={mainSettings}
                            setMainSettings={setMainSettings}
                            setPageName={setPageName}
                        /> : ""
                )}

                {mainSettings.general && (
                    pageName === "general" ?
                        <General
                            setPageName={setPageName}
                            sidebarOpen={sidebarOpen}
                            mainSettings={mainSettings}
                            setMainSettings={setMainSettings}
                        /> : ""

                )}

                {mainSettings.userroles && (
                    pageName === "userRole" ?
                        <UserRole
                            setPageName={setPageName}
                            pageName={pageName}
                            sidebarOpen={sidebarOpen}
                            mainSettings={mainSettings}
                            setMainSettings={setMainSettings}
                            setSelectedRole={setSelectedRole}
                        /> : pageName === "addUserRole" ?
                            <AddUserRole
                                sidebarOpen={sidebarOpen}
                                setPageName={setPageName} /> : pageName === "editUserRole" ?
                                <EditUserRole
                                    sidebarOpen={sidebarOpen}
                                    setPageName={setPageName}
                                    selectedRole={selectedRole} /> : ""
                )}
                <Footer sidebarOpen={sidebarOpen} />
            </div> : <PageNotFound />}
        </>
    )
}

export default RecruiterMainSettings