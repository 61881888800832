import { Step, StepLabel, Stepper } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import axios from "axios";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";
import ScreenAvailabilty from "./screenAvailability";
import ScreenEvent from "./screenEvent";
import ScreenSkills from "./screenSkills";

const styles = (theme) => ({
    main: {
        width: "100%",
        display: "block", // Fix IE 11 issue.
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        [theme.breakpoints.up(400 + theme.spacing(6))]: {
            width: "50%",
            marginLeft: "auto",
            marginRight: "auto",
        },
    },
    paper: {
        marginTop: theme.spacing(2),
        display: "flex",
        width: "100%",
        flexDirection: "column",
        alignItems: "center",
        padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
    },
    button: {
        padding: theme.spacing(2),
        margin: 10,
        align: "center",
    },
});

class Schedule extends Component {
    constructor(props) {
        super(props);
        this.state = {
            skills: [],
            selectedOptions: [],
            start: new Date(),
            end: new Date(),
            title: "",
            timeZone: "",
            candidateName: "",
            screenerName: "",
            description: "",
            activeStep: 0,
        };
    }

    // Handle fields change
    handleChange = (input) => (event) => {
        this.setState({ [input]: event });
    };

    handleInputChange = (input) => (event) => {
        this.setState({ [event.target.name]: event.target.value });
    };

    handleStartDateChange = (input) => (date) => {
        this.setState({ start: date });
    };

    handleEndDateChange = (input) => (date) => {
        this.setState({ end: date });
    };

    // Proceed to next step
    nextStep = () => {
        const { activeStep } = this.state;
        this.setState({
            activeStep: activeStep + 1,
        });
    };

    // Go back to prev step
    prevStep = () => {
        const { activeStep } = this.state;
        this.setState({
            activeStep: activeStep - 1,
        });
    };
    /*   onSubmit = () => {
     axios
      .post("/api/candidateSchedule/new", this.state)
      .then(() => (window.location = "/Schedule-Landing"));
  }; */

    onSubmit = (e) => {
        e.preventDefault();
        const recruiterData = {
            userID: this.state.userID,
            start: this.state.start,
            end: this.state.end,
            title: this.state.title,
            timeZone: this.state.timeZone,
            description: this.state.description,
            screeners: this.state.screeners,
            type: "recruiter",
        };

        axios.post("/api/schedule/new", recruiterData).then((res) => (window.location = "/Schedule"));

        this.state.screeners.forEach((element) => {
            const screenerData = {
                userID: element,
                start: this.state.start,
                end: this.state.end,
                title: this.state.title,
                timeZone: this.state.timeZone,
                description: this.state.description,
                recruiterID: this.state.userID,
                type: "screener",
            };
            axios.post("/api/schedule/new", screenerData).then((res) => (window.location = "/Schedule"));
        });
    };

    getSteps = () => {
        return ["Select Skills", "Screener Availability", "Schedule Event"];
    };

    getStepContent = (stepIndex) => {
        const { skills, start, end, title, timeZone, candidateName, screenerName, description } = this.state;

        const values = {
            skills,
            start,
            end,
            title,
            timeZone,
            candidateName,
            screenerName,
            description,
        };
        switch (stepIndex) {
            case 0:
                return (
                    <ScreenSkills
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        onSubmit={this.onSubmit}
                        handleChange={this.handleChange("skills")}
                        values={values}
                    />
                );
            case 1:
                return (
                    <ScreenAvailabilty
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        onSubmit={this.onSubmit}
                        handleChange={this.handleChange}
                        values={values}
                    />
                );
            case 2:
                return (
                    <ScreenEvent
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        onSubmit={this.onSubmit}
                        handleChange={this.handleChange}
                        handleInputChange={this.handleInputChange}
                        handleStartDateChange={this.handleStartDateChange}
                        handleEndDateChange={this.handleEndDateChange}
                        values={values}
                    />
                );

            default:
                return "Unknown stepIndex";
        }
    };

    render() {
        const { classes } = this.props;
        const steps = this.getSteps();
        const { activeStep } = this.state;
        // console.log(this.state);

        return (
            <div className={classes.main}>
                <Paper className={classes.paper}>
                    <br />
                    <Typography variant="h6">Candidate Schedule</Typography>
                    <Stepper activeStep={activeStep} alternativeLabel className={classes.stepper}>
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    <div>
                        {this.state.activeStep === steps.length ? (
                            <div>
                                <Typography className={classes.instructions}>Profile Completed</Typography>
                            </div>
                        ) : (
                            <React.Fragment>
                                {this.getStepContent(activeStep)}
                                <br /> <br />
                                <div>
                                    <Grid container spacing={24}>
                                        <Grid item xs={3}>
                                            <Button disabled={activeStep === 0} onClick={this.prevStep} className={classes.backButton}>
                                                Back
                                            </Button>
                                        </Grid>
                                        <Grid item xs={6} />
                                        <Grid item xs={3}>
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                onClick={activeStep === steps.length - 1 ? this.onSubmit : this.nextStep}
                                            >
                                                {activeStep === steps.length - 1 ? "Confirm" : "Next"}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </div>
                            </React.Fragment>
                        )}
                    </div>
                </Paper>
                <br />
                <br />
                <br />
            </div>
        );
    }
}
Schedule.propTypes = {
    logoutUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default withStyles(styles)(connect(mapStateToProps, { logoutUser })(Schedule));
