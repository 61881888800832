import { Typography } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import timeConvertor from "../../utils/timeConvertor";

const styles = (theme) => ({
    body: {
        backgroundColor: "#C8E6C9",
    },
});

const divStyle = {padding:'20px'}

// import link later to add a link to a full interview page.
// import { Link } from "react-router-dom";
class PositionRequests extends Component {
    filterReviewEvents(interviews) {
        return interviews.filter((interviewInfo) => interviewInfo.Status__c === "Completed" && interviewInfo.title !== "availability");
    }

    render() {
        const { classes, interviews } = this.props;
        let InterviewsInReview = this.filterReviewEvents(interviews);
        if (InterviewsInReview.length === 0) {
            return (
                // <div className={`p-1 text-center ${classes.body} border-bottom-0 border-dark`}>
                //     <h1>There are no pending reviews.</h1>
                // </div>
                <div style={divStyle} >
                    <Typography>There are no pending reviews</Typography>
                </div>
            );
        } else {
            return (
                <>
                    {InterviewsInReview.map((alert, idx, interviewsArray) => (
                        <div
                            key={`pendingInterviewAlert-${idx}-${alert.salesforceInterviewRecordId}`}
                            className={`p-1 text-center ${classes.body} ${
                                idx === interviewsArray.length - 1 ? "border-bottom-0" : "border-bottom"
                            } border-dark`}
                        >
                            {/* <br />
                            <b>{`Pending Review ${alert.Start_Time__c.split("T")[0]} at ${timeConvertor(
                                alert.Start_Time__c.split("T")[1].split(":")[0],
                                alert.Start_Time__c.split("T")[1].split(":")[1]
                            )}`}</b> */}
                            <br />
                            <b className="text-capitalize">Candidate Name: </b> {alert.Candidate_Full_Name__c}{" "}
                            <br />
                            <b>Position: </b> {alert.Position_Name__c} <br />
                            &nbsp;
                        </div>
                    ))}
                </>
            );
        }
    }
}

PositionRequests.propTypes = {
    auth: PropTypes.object.isRequired,
    classes: PropTypes.object,
    interviews: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    interviews: state.interviews,
});

export default withStyles(styles)(connect(mapStateToProps)(PositionRequests));
