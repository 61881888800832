
//Mapped TimeZone 
export const timezone = [
    {
        abbr: "GMT",
        value: "Greenwich Mean Time (GMT)"
    },
    {
        abbr: "UTC",
        value: "Universal Coordinated Time (UTC)"
    },
    {
        abbr: "Europe/Berlin",
        value: "Central European Time (CET)"
    },
    {
        abbr: "Africa/Cairo",
        value: "(Arabic) Egypt Standard Time (ART)"
    },
    {
        abbr: "Africa/Djibouti",
        value: "Eastern African Time (EAT)"
    },
    {
        abbr: "Europe/Berlin",
        value: "Middle European Time (MET)"
    },

    {
        abbr: "Asia/Karachi",
        value: "Pakistan Lahore Time (PLT)"
    },
    {
        abbr: "Asia/Kolkata",
        value: "India Standard Time (IST)"
    },
    {
        abbr: "Asia/Dhaka",
        value: "Bangladesh Standard Time (BST)"
    },
    {
        abbr: "Asia/Jakarta",
        value: "Vietnam Standard Time (VST)"
    },

    {
        abbr: "Asia/Tokyo",
        value: "Japan Standard Time (JST)"
    },

    {
        abbr: "Australia/Sydney",
        value: "Australia Eastern Daylight Time (AEDT)"
    },

    {
        abbr: "Canada/Newfoundland",
        value: "Newfoundland Standard Time (NST)"
    },
    {
        abbr: "Pacific/Johnston",
        value: "Hawaii Standard Time (HST)"
    },


    {
        abbr: "Canada/Pacific",
        value: "Pacific Standard Time (PST)"
    },


    {
        abbr: "Canada/Central",
        value: "Central Standard Time (CST)"
    },
    {
        abbr: "Canada/Eastern",
        value: "Eastern Standard Time (EST)"
    },

    {
        abbr: "Canada/Newfoundland",
        value: "Canada Newfoundland Time (CNT)"
    },

];
