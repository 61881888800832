import React, { useEffect, useState, useRef, useCallback } from 'react'

//MUI Imports
import Slide from "@mui/material/Slide";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import AddIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from "@mui/icons-material/Delete";
import SchoolIcon from '@mui/icons-material/School';

const EducationDetails = ({ userData, setUserData, nextPage, previousPage, setPreviousPage, count, setCount }) => {

  console.log(userData, "education data")
  //form states
  const thisYear = new Date().getFullYear();
  const currentYear = thisYear;

  //educational Details
  const [educationDetails, setEducationDetails] = useState(userData.educationDetails);

  const options = [];
  const minOffset = 0;
  const maxOffset = 50;
  for (let i = minOffset; i <= maxOffset; i++) {
    const year = currentYear - i;
    options.push(<option value={year}>{year}</option>);
  }

  // initialization of errors
  const [errors, setErrors] = useState(userData.educationErrors);

  //Next button handler
  const goToNextPage = () => {
    let education = [...educationDetails];
    let error = [...errors];
    education.map((item, index) => {

      if (item.startMonth == "-1" && item.endMonth == "-1") {
        if (!item.degree && !item.percentage && !item.school) {
          error[index].startMonth = ""
          error[index].endMonth = ""
          error[index].degree = ""
          error[index].stream = ""
          error[index].school = ""
        }
        // else if ((item.degree || item.school) && !item.stream) {
        //   error[index].stream = "Stream is required"
        // }
        else if ((item.degree || item.school) && !item.percentage) {
          error[index].stream = "Percentage is required"
        }
        else if ((item.percentage || item.school) && !item.degree) {
          error[index].degree = "Degree is required"
        }
        else if (!item.school) {
          error[index].school = "School is required"
        }
        else {
          error[index].startMonth = ""
          error[index].endMonth = ""
          error[index].degree = ""
          error[index].stream = ""
          error[index].school = ""
        }
        setErrors(error)
      }
      else if (item.startMonth !== "-1" && item.endMonth !== "-1") {
        if (!item.degree && !item.percentage && !item.school) {
          error[index].stream = "Percentage is required"
          error[index].degree = "Degree is required"
          error[index].school = "School is required"
        }
        else if (!item.degree) {
          error[index].degree = "Degree is required"
        }
        else if (!item.school) {
          error[index].school = "School is required"
        }
        else if (!item.percentage) {
          error[index].stream = "Percentage is required"
        }
        setErrors(error)
      }
      else if (item.startMonth !== "-1") {
        if (!item.degree && !item.percentage && !item.school) {
          error[index].stream = "Percentage is required"
          error[index].degree = "Degree is required"
          error[index].school = "School is required"
          // error[index].endMonth = "End Month is required"
        }
        else if (!item.degree) {
          error[index].degree = "Degree is required"
          // error[index].endMonth = "End Month is required"
        }
        else if (!item.percentage) {
          error[index].stream = "Percentage is required"
          // error[index].endMonth = "End Month is required"
        }
        else if (!item.school) {
          error[index].school = "School is required"
          // error[index].endMonth = "End Month is required"
        }
        // else if (item.endMonth === "-1") {
        //   // error[index].endMonth = "End Month is required"
        // }
        setErrors(error)
      }
      else if (item.endMonth !== "-1" || item.checked === true) {
        if (!item.degree && !item.percentage && !item.school) {
          error[index].stream = "Percentage is required"
          error[index].degree = "Degree is required"
          error[index].school = "School is required"
          // error[index].startMonth = "Start Month is required"
        }
        else if (!item.degree) {
          error[index].degree = "Degree is required"
          // error[index].startMonth = "Start Month is required"
        }

        else if (!item.school) {
          error[index].school = "School is required"
          // error[index].startMonth = "Start Month is required"
        }
        else if (!item.percentage) {
          error[index].stream = "Percentage is required"
          // error[index].startMonth = "Start Month is required"
        }
        // else if (item.startMonth === "-1") {
        //   error[index].startMonth = "Start Month is required"
        // }
        setErrors(error)
      }

      if (
        (item.startYear > item.endYear && item.checked === false) ||
        (item.endYear == item.startYear && item.endMonth < item.startMonth && item.checked === false)
      ) {
        error[index].endMonth = "End date cannot be less than start date"
        setErrors(error)
      }

    })

    const condition = errors.find((item) => {
      return item.school !== "" || item.degree !== "" || item.stream !== "" || item.startMonth !== "" || item.startYear !== "" || item.endMonth !== "" || item.endYear !== "";
    });

    if (!condition) {
      const educationObj = {
        educationDetails: educationDetails,
        educationErrors: errors,
      }
      let data = { ...userData, ...educationObj }
      setUserData(data)

      if (nextPage) {
        setCount(count + 1)
      } else {
        setCount(count - 1)
      }
    } else {

    }
  }



  const goToPreviousPage = () => {
    //store current entered data
    const educationObj = {
      educationDetails: educationDetails,
      educationErrors: errors,
    }
    let data = { ...userData, ...educationObj }
    setUserData(data)

    // go to previous page
    setPreviousPage(true)
    if (nextPage) {
      setCount(count => count - 1)
    } else {
      setCount(count => count + 1)
    }
  }

  // handle input field change
  const handleChange = (index, evnt) => {
    const { name, value } = evnt.target;

    const list = [...educationDetails];
    list[index][name] = value;
    setEducationDetails(list);

    if (name === "school") {
      errors[index].school = "";
    } else if (name === "degree") {
      errors[index].degree = "";
    } else if (name === "stream") {
      errors[index].stream = "";
    } else if (name === "percentage") {
      errors[index].stream = "";
    } else if (name === "startMonth") {
      if (list[index].startMonth < list[index].endMonth || list[index].startMonth == list[index].endMonth) { errors[index].endMonth = "" }
      errors[index].startMonth = "";
    } else if (name === "startYear") {
      if (list[index].startYear < list[index].endYear || list[index].startYear == list[index].endYear) { errors[index].endMonth = "" }
      errors[index].startMonth = "";
    } else if (name === "endMonth") {
      if (list[index].startMonth < list[index].endMonth || list[index].startMonth == list[index].endMonth) { errors[index].endMonth = "" }
      errors[index].endMonth = "";
    } else if (name === "endYear") {
      errors[index].endMonth = "";
    } else {

    }
  };

  const checkHandler = (index) => {
    const list = [...educationDetails];
    list[index].checked = !list[index].checked;
    list[index].currentWorking = !list[index].currentWorking;


    if (list[index].checked) {
      list[index].endMonth = "Present";
      list[index].endYear = "";
      errors[index].endMonth = "";
    }
    else {
      list[index].endMonth = "-1";
      list[index].endYear = thisYear;
      errors[index].endMonth = "";
    }
    setEducationDetails(list);

  };

  //error handling for all fields
  const rowHandling = () => {
    let education = [...educationDetails];
    let error = [...errors];
    education.map((item, index) => {

      if (item.startMonth === "-1" && item.endMonth === "-1") {
        if (!item.degree && !item.percentage && !item.school) {
          // error[index].startMonth = "Start month is required"
          // error[index].endMonth = "End month is required"
          error[index].degree = "Degree is required"
          error[index].stream = "Percentage is required"
          error[index].school = "School is required"
        }
        else if ((item.degree || item.school) && !item.percentage) {
          error[index].stream = "Percentage is required"
          // error[index].startMonth = "Start month is required"
          // error[index].endMonth = "End month is required"
        }
        else if ((item.percentage || item.school) && !item.degree) {
          error[index].degree = "Degree is required"
          // error[index].startMonth = "Start month is required"
          // error[index].endMonth = "End month is required"
        }
        else if (!item.school) {
          error[index].school = "School is required"
          // error[index].startMonth = "Start month is required"
          // error[index].endMonth = "End month is required"
        }
        else {
          // error[index].startMonth = "Start month is required"
          // error[index].endMonth = "End month is required"
          error[index].degree = ""
          error[index].stream = ""
          error[index].school = ""
        }
        setErrors(error)
      }


      else if (item.startMonth !== "-1" && item.endMonth !== "-1") {

        if (!item.degree && !item.percentage && !item.school) {
          error[index].stream = "Percentage is required"
          error[index].degree = "Degree is required"
          error[index].school = "School is required"
        }
        else if (!item.degree) {
          error[index].degree = "Degree is required"
        }

        else if (!item.school) {
          error[index].school = "School is required"
        }
        else if (!item.percentage) {
          error[index].stream = "Percentage is required"
        }
        setErrors(error)
      }
      else if (item.startMonth !== "-1") {
        if (!item.degree && !item.percentage && !item.school) {
          error[index].stream = "Percentage is required"
          error[index].degree = "Degree is required"
          error[index].school = "School is required"
          // error[index].endMonth = "End Month is required"
        }
        else if (!item.degree) {
          error[index].degree = "Degree is required"
        }

        else if (!item.school) {
          error[index].school = "School is required"
        }
        else if (!item.percentage) {
          error[index].stream = "Percentage is required"
        }
        // else if (item.endMonth === "-1") {
        //   error[index].endMonth = "End Month is required"
        // }
        setErrors(error)
      }
      else if (item.endMonth !== "-1" || item.checked === true) {
        if (!item.degree && !item.percentage) {
          error[index].stream = "Percentage is required"
          error[index].degree = "Degree is required"
          // error[index].startMonth = "Start Month is required"
        }
        else if (!item.degree) {
          error[index].degree = "Degree is required"

        }

        else if (!item.school) {
          error[index].school = "School is required"

        }
        else if (!item.percentage) {
          error[index].stream = "Percentage is required"

        }
        // else if (item.startMonth === "-1") {
        //   error[index].startMonth = "Start Month is required"
        // }
        setErrors(error)
      }



      if (
        (item.startYear > item.endYear && item.checked === false) ||
        (item.endYear == item.startYear && item.endMonth < item.startMonth && item.checked === false)
      ) {
        error[index].endMonth = "End date cannot be less than start date"
        setErrors(error)
      }
    })
    setErrors(error);
  };

  //form handler
  const formReplicationHandler = () => {
    rowHandling(); //to check for errors

    const condition = errors.find((item) => {
      return item.school !== "" || item.degree !== "" || item.stream !== "" || item.startMonth !== "" || item.startYear !== "" || item.endMonth !== "" || item.endYear !== "";
    });

    if (condition) {
      return;
    } else {
      setEducationDetails([
        ...educationDetails,
        {
          school: "",
          degree: "",
          percentage: "",
          // stream: "",
          startMonth: "-1",
          startYear: thisYear,
          endMonth: "-1",
          endYear: thisYear,
          currentWorking: false,
          checked: false,
        },
      ]);
      setErrors([
        ...errors,
        {
          school: "",
          degree: "",
          stream: "",
          startMonth: "",
          startYear: "",
          endMonth: "",
          endYear: "",
        },
      ]);
    }
  };

  //handle delete full row
  const removeInputFields = (index) => {
    const rows = [...educationDetails];
    rows.splice(index, 1);
    errors.splice(index, 1);
    setEducationDetails(rows);
  };


  //months for DropDown
  const months = [
    { value: "-1", label: "Month" },
    { value: "00", label: "January" },
    { value: "01", label: "February" },
    { value: "02", label: "March" },
    { value: "03", label: "April" },
    { value: "04", label: "May" },
    { value: "05", label: "June" },
    { value: "06", label: "July" },
    { value: "07", label: "August" },
    { value: "08", label: "September" },
    { value: "09", label: "October" },
    { value: "10", label: "November" },
    { value: "11", label: "December" },
  ];

  return (
    <div>
      <Slide direction={"left"} in={true} timeout={500}>
        <Box sx={{ flexGrow: 1, background: "#faf9f6" }}>
          <Card
            id="main-edit"
            className=" form-card table-card"
            style={{
              padding: "15px 20px 18px 20px ",
              boxShadow: "none",
            }}
          >

            <Grid
              container
              spacing={0}
              direction="row"
              className="add-recruiter-card-container"
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={5}
                lg={12}
              >
                <div style={{ display: "flex", gap: "10px" }}>
                  <SchoolIcon sx={{ color: "#2c3e50" }} />
                  <h5 style={{ color: "#283848" }}>Education Details</h5>
                </div>
              </Grid>
              <Grid container spacing={0} justifyContent="flex-end">

                <Grid
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}

                >
                  <div >
                    <form noValidate style={{ display: "flex", flexDirection: "column" }}>
                      {educationDetails.map((data, index) => {
                        const { school, degree, stream, percentage, startMonth, startYear, endMonth, endYear, currentWorking, checked } = data;
                        return (<>

                          <Grid
                            xs={12}
                            sm={12}
                            md={11}
                            lg={11}
                            sx={{
                              display: "flex"
                            }}
                            className="education-details-form-container"
                          >
                            <Grid
                              xs={1}
                              sm={1}
                              md={0.5}
                              lg={0.5}

                              sx={{
                                position: "relative",
                                top: `${index === 0 ? "43px" : "63px"}`,
                                height: "fit-content"
                              }}

                            >
                              <div >{educationDetails.length !== 1 ? `${index + 1}.` : ""}</div>
                            </Grid>
                            <Grid
                              xs={12}
                              sm={12}
                              md={11}
                              lg={11}

                            >
                              <div className="input-container" >
                                {index > 0 ?
                                  <>
                                    <br />
                                    <Divider
                                      sx={{ borderColor: "lightgray" }}
                                    // id="divider"
                                    />

                                  </> : ""}
                                <div className="split-inputs">
                                  <div class="input-field-modal">
                                    <label className="date-label">School</label>
                                    <input
                                      class="position"
                                      className={`${errors[index]?.school && "input-field-error"}`}
                                      type="text"
                                      name="school"
                                      value={school}
                                      onChange={(evnt) => handleChange(index, evnt)}
                                      placeholder="Ex: Boston University"
                                    />
                                    <div className='error-div' style={{ height: "20px" }}>
                                      <span className="text-danger">{errors[index]?.school}</span>
                                    </div>
                                  </div>


                                  <div class="input-field-modal">
                                    <label className="date-label">Degree</label>
                                    <input
                                      class="company"
                                      className={`${errors[index]?.degree && "input-field-error"}`}
                                      type="text"
                                      name="degree"
                                      value={degree}
                                      onChange={(evnt) => handleChange(index, evnt)}
                                      placeholder="Ex: Master's"
                                    />
                                    <div className='error-div' style={{ height: "20px" }}>
                                      <span className="text-danger">{errors[index]?.degree}</span>
                                    </div>
                                  </div>

                                  {/* <div class="input-field-modal">
                                    <label className="date-label">Field of Study</label>
                                    <input
                                      class="company"
                                      type="text"
                                      name="stream"
                                      value={stream}
                                      onChange={(evnt) => handleChange(index, evnt)}
                                      placeholder="Ex: Computer Science"
                                    />
                                    <div style={{ height: "20px" }}>
                                      <span className="text-danger">{errors[index]?.stream}</span>
                                    </div>
                                  </div> */}

                                  <div class="input-field-modal">
                                    <label className="date-label">Percentage</label>
                                    <input
                                      class="company"
                                      className={`${errors[index]?.stream && "input-field-error"}`}
                                      type="text"
                                      name="percentage"
                                      value={percentage}
                                      onChange={(evnt) => handleChange(index, evnt)}
                                      placeholder="Ex: 75%"
                                    />
                                    <div className='error-div' style={{ height: "20px" }}>
                                      <span className="text-danger">{errors[index]?.stream}</span>
                                    </div>
                                  </div>
                                </div>

                                <label className="date-label" >Start Date</label>
                                <div className="split-inputs">

                                  <div class="input-field-modal">

                                    <select
                                      name='startMonth'
                                      value={startMonth}
                                      className={`${errors[index]?.startMonth && "input-field-error"}`}
                                      onChange={(evnt) => handleChange(index, evnt)}
                                      style={{ outline: "none" }}>
                                      {months.map((option) => {
                                        return <option value={option.value} disabled={currentYear == startYear && Number(option.value) > new Date().getMonth()}>{option.label}</option>
                                      })}
                                    </select>

                                    <span className="text-danger">{errors[index]?.startMonth}</span>

                                  </div>

                                  <div class="input-field-modal">
                                    <select value={startYear}
                                      name="startYear"
                                      // className={`${errors[index]?.startMonth && "input-field-error"}`}
                                      onChange={(evnt) => handleChange(index, evnt)}
                                      style={{ outline: "none" }}>
                                      {options}
                                    </select>
                                  </div>
                                </div>

                                <div className="input-field">
                                  <FormGroup className="input-field-checkbox">
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          size="small"
                                          sx={{
                                            color: "gray",
                                            "&.Mui-checked": {
                                              color: "#F06161",
                                            },
                                          }}
                                          checked={checked}
                                          value={checked}
                                          onClick={() => checkHandler(index)}
                                        />
                                      }
                                      label="Currently Studying here"
                                    />
                                  </FormGroup>
                                </div>

                                <label className="date-label" >End Date</label>
                                <div className="split-inputs">

                                  <div class="input-field-modal">

                                    <select disabled={currentWorking}
                                      value={endMonth}
                                      className={`${errors[index]?.endMonth && "input-field-error"}`}
                                      onChange={(evnt) => handleChange(index, evnt)}
                                      name="endMonth"
                                      style={{ outline: "none" }}>

                                      {months.map((option) => {
                                        return <option value={option.value} disabled={currentYear == endYear && Number(option.value) > new Date().getMonth()}>{option.label}</option>
                                      })}
                                    </select>

                                    <span className="text-danger">{errors[index]?.endMonth}</span>

                                  </div>

                                  <div class="input-field-modal">

                                    <select disabled={currentWorking}
                                      value={endYear}
                                      onChange={(evnt) => handleChange(index, evnt)}
                                      name="endYear"
                                      style={{ outline: "none" }}>
                                      {options}
                                    </select>
                                  </div>
                                </div>
                                {educationDetails.length !== 1 && (
                                  <div style={{ display: "flex", justifyContent: "flex-end", width: "100%", marginTop: "10px" }}>
                                    <DeleteIcon
                                      sx={{ marginBottom: "1px" }}
                                      onClick={() => removeInputFields(index)}
                                      fontSize="small"
                                      className="deleteIcon"
                                    />
                                  </div>
                                )}
                              </div>
                            </Grid>

                          </Grid>
                        </>)
                      })}
                    </form>
                  </div>
                </Grid>
                <Grid
                  xs={12}
                  sm={12}
                  md={0.5}
                  lg={0.5}

                >
                  <AddIcon className="edit-icon" onClick={formReplicationHandler} />
                </Grid>
              </Grid>
              <div className="button-container navigation-btn-container" >
                <button className="btn-style outlined-btn outlined-btn-animation" onClick={goToPreviousPage}>
                  {" "}
                  Previous
                </button>
                <button className="btn-style filled-btn filled-btn-animation" onClick={goToNextPage}>
                  {" "}
                  Next
                </button>

              </div>
            </Grid>
          </Card>
        </Box>
      </Slide>
    </div>
  )
}

export default EducationDetails