import React, { useEffect, useState } from "react";
import ClientDetails from "./ClientDetails";
import PocDetails from "./PocDetails";
import axios from "axios";


const EditClient = ({ setAddClient, selectedClientId, id, mode }) => {


    //default Avatar from cloud
    let profileAvatar =
        "https://res.cloudinary.com/df2qsnyox/image/upload/v1681133496/Default%20Profile%20Avatar/profile-avatar_ogxozq_ranghl.png";
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [croppedAreaPixels, setCroppedAreaPixels] = useState();
    const [clientData, setClientData] = useState({
        crop: crop
    })


    const [count, setCount] = useState(1);
    const [nextPage, setNextPage] = useState(true);
    const [previousPage, setPreviousPage] = useState(false);


    const [dataLoaded, setDataLoaded] = useState(false)


    useEffect(() => {
        axios.get(`/api/admin/get-all-clients/${id}`).then((res) => {
            if (res.data.length > 0) {
                const selectedClient = res.data.filter(client => {
                    return client._id === selectedClientId
                })
                console.log(selectedClient[0])
                setClientData({
                    ...clientData, ...selectedClient[0],
                    ...selectedClient[0].location
                })
            }
            setDataLoaded(true)
        })
    }, [])
    console.log(clientData, "client data")


    return (
        <div>


            {dataLoaded && nextPage && count === 1 ? (
                <ClientDetails clientData={clientData} setClientData={setClientData} nextPage={nextPage} previousPage={previousPage} setPreviousPage={setPreviousPage} count={count} setCount={setCount} />
            ) : nextPage && count === 2 ? (
                <PocDetails clientData={clientData} setClientData={setClientData} setAddClient={setAddClient} nextPage={nextPage} previousPage={previousPage} setPreviousPage={setPreviousPage} count={count} setCount={setCount} mode={mode} selectedClientId={selectedClientId} />
            ) : ""}
        </div>
    )
}


export default EditClient

