import React from 'react'

// mui imports
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";

// icons imports
import DeleteMessageIcon from "@mui/icons-material/ErrorOutlineOutlined";

const RemoveFavoritesDialog = ({ removeFavoritesHandler, setOpenAlertDialog, openAlertDialog }) => {
    //dialog handlers
    const onCloseAlertDialog = () => {
        setOpenAlertDialog(false);
    };
    return (
        <div>
            <Dialog
                fullWidth
                maxWidth="xs"
                open={openAlertDialog}
                onClose={onCloseAlertDialog}
                className="experience-edit-dialog"
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent className="dialog-content">
                    <Grid container spacing={0} justifyContent="center">
                        <Grid
                            item
                            xs={11.5}
                            sm={11.5}
                            md={12}
                            lg={12}
                            className="experience-delete-grid"
                        >
                            <div className="delete-message-icon">
                                <DeleteMessageIcon fontSize="small" />
                            </div>
                            <div className="delete-dialog-content">
                                <h5>Are you sure?</h5>
                                <p>Do you want remove this profile from Favorites?</p>
                            </div>
                        </Grid>
                    </Grid>
                </DialogContent>
                <div className="alert-buttons-container">
                    <button className="warning-btn-style filled-btn filled-btn-animation" onClick={onCloseAlertDialog}>
                        {" "}
                        Cancel
                    </button>
                    <button className="warning-btn-style outlined-btn outlined-btn-animation" onClick={removeFavoritesHandler}>
                        {" "}
                        Confirm
                    </button>
                </div>
            </Dialog>
        </div>
    )
}

export default RemoveFavoritesDialog