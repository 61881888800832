import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

const styles = makeStyles(theme => ({
    cardHeader: {
        background: "#FFFFFF"
    },
    title: {
        color: "#212121",
        textAlign: "left",
        letterSpacing: "0px",
        font: "regular 32px",
        fontWeight: "500"
    },
    standard: {
        background: "#FFFFFF 0% 0% no-repeat padding-box",
        border: "3px solid #A5D6A7",
        borderRadius: "10px",
        maxHeight: "400px",
        opacity: "1",
        minWidth: "250px"
    }
}));

export default function SimpleCards(props) {
        const classes = styles();
        const { title, children } = props;
        return (
            <>
                <Card className={classes.standard}>
                    <CardHeader
                        align="center"
                        className={classes.cardHeader}
                        classes={{ title: classes.title }}
                        title={title}
                    />{" "}
                    {children}
                </Card>
            </>
        )

}
