import { CardHeader } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React from "react";
import ReviewTable from "../tables/ScreenrReviews/reviewTable";

// import { Link } from "react-router-dom";

const useStyles = makeStyles({
    card: {
        background: "#FFFFFF 0% 0% no-repeat padding-box",
        border: "3px solid #A5D6A7",
        borderRadius: "10px",
        opacity: "1",
        padding: "20px",
    },
});

export default function SalesforceReviews(props) {
    const classes = useStyles();
    let { reviews } = props;
    const screenerId = props.auth.user.id;

    if (reviews.length <= 0 && reviews[0] !== "There are no reviews") {
        props.getReviews(screenerId);
    }

    return (
        <div className="unfinPage-component">
            <Card className={classes.card}>
                <CardHeader align="center" title="Reviews" />
                <ReviewTable reviews={reviews} />
            </Card>
        </div>
    );
}

SalesforceReviews.propTypes = {
    auth: PropTypes.object.isRequired,
    reviews: PropTypes.array,
};
