import React, { useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import axios from "axios";
import Button from '@material-ui/core/Button';
import { withSnackbar, useSnackbar } from "notistack";
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import Divider from "@material-ui/core/Divider";
import dateFormat from 'dateformat';
import { useConfirm } from "material-ui-confirm";
import { Container, Grid, Paper, Box } from "@material-ui/core";
// import LaunchIcon from "@mui/icons-material/Launch";
import LaunchIcon from '@mui/icons-material/Visibility';
import "../recruiters/tableList.css";
import { makeStyles, TextareaAutosize } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";


import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Dialog from "@mui/material/Dialog";

const useStyles = makeStyles(theme => ({
    main: {
        width: "auto",
        display: "block", // Fix IE 11 issue.
        marginTop: theme.spacing(-2),
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        [theme.breakpoints.up(400 + theme.spacing(6))]: {
            width: "50%",
            marginLeft: "auto",
            marginRight: "auto",
        },
    },
    paper: {
        marginTop: theme.spacing(-5),
        display: "flex",
        width: "100%",
        flexDirection: "column",
        alignItems: "center",
        padding: `${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(-15)}px`,
    },
    button: {
        padding: theme.spacing(2),
        margin: 10,
        alignItems: "center",
    },

    textField: {
        marginLeft: theme.spacing(),
        marginRight: theme.spacing(),
        width: "100px",
    },

    textFieldBox: {
        marginTop: theme.spacing() * 1,
        marginLeft: theme.spacing(),
        marginRight: theme.spacing(),
        width: "500px",
    },
    select: {
        marginTop: theme.spacing() * 1,
        marginLeft: theme.spacing(),
        marginRight: theme.spacing(),
        width: "250px",
    },
    formControl: {
        margin: theme.spacing(),
        minWidth: 120,
        maxWidth: 300,
    },
}));

const ScreenerReviewList = (props) => {
    const [reviewList, setreviewList] = React.useState([]);
    const [recruiterList, setRecruiterList] = React.useState([]);
    const [screenerList, setScreenerList] = React.useState([]);
    const [open, setOpen] = React.useState(false);
    const [info, setInfo] = React.useState({
        id:"", 
        sname:"", 
        sid:"",
        numberRating:"", 
        comments:"", 
    })
    //screenerId
    let screenerId;
    const confirm = useConfirm();

    const { enqueueSnackbar } = useSnackbar();

    // this.state = { showSubmission: true };
    //const [showButton, setShowButton] = React.useState(true);
    // const { user } = props;
    // const screenerid = user._id;
    // console.log(screenerid);
    // console.log(user);

    const { auth: { user }, } = props;
    console.log(user)
    const userId = user.id;
    console.log(userId);
    console.log(screenerList);


    useEffect(() => {

        axios.get("/api/review/myreviews").then((response) => {
            setreviewList(response.data);
        });
        axios.get("/api/users/recruiter").then((response) => {
            setRecruiterList(response.data);
        });
        axios.get("/api/users/screener").then((response) => {
            setScreenerList(response.data);
        });

    }, []);

    // console.log(reviewList[0]);
    // console.log(recruiterList[0]);

    const uId ="";
    
    // console.log(uId);

    //Open the dialog box
    const handleClickOpen = (id) => {
        // console.log(id);
        const reviewInfo = reviewList.filter(item=>{
            return item._id === id;
        })

        setInfo({
            id: reviewInfo[0].reviewId,
            sname:user.name , 
            sid:reviewInfo[0].screener,
            numberRating:reviewInfo[0].numberRating, 
            comments:reviewInfo[0].comments,
        })
        setOpen(true);
    };

    //Close the dialog box
    const handleClose = () => {
        setOpen(false);
    };

    const columns = [
        {
            field: "id",
            width: -1,
            flex: -1,
            hide: true
        },
        {
            field: "reviewId",
            headerName: "Review ID",
            minWidth: 150,
            flex: 1,

        },
        // {
        //     field: "recruiter",
        //     headerName: "Rated By",
        //     minWidth: 150,
        //     flex: 0.5,
        // },

        // {
        //     field: "email",
        //     headerName: "Recruiter Email",
        //     minWidth: 250,
        //     flex: 0.5,
        // },
        {
            field: "screener",
            headerName: "Screener Name",
            minWidth: 250,
            flex: 0.5,

        },
        {
            field: "numberRating",
            headerName: "Rating",
            minWidth: 100,
            flex: 0.5,

        },
        {
            field: "comments",
            headerName: "Comment",
            minWidth: 150,
            flex: 0.5,
        },
        {
            field: "actions",
            flex: 0.3,
            headerName: "Actions",
            minWidth: 150,
            // type: "number",
            sortable: false,
            renderCell: (params) => {
                // console.log(params.id);

                return (
                    <>
                        {/* <Link to={`/${params.id}`}> */}
                        {/* <Link to={`/screener/reviewdetails/${params.getValue(params.id, "id")}`} title="Review Details" style={{ color: "#75a478" }}>
                        </Link> */}
                            <LaunchIcon  style={{ color: "#75a478", cursor: "pointer" }} onClick={()=>handleClickOpen(params.id)} />
                        &nbsp;&nbsp;&nbsp;

                    </>
                );
            },

        },
    ]


    const rows = [];

    reviewList && reviewList.forEach(item => {
  
            // console.log(item);
        screenerList && screenerList.forEach(screener => {
 
            // console.log(screenerId);
            if (item.screener === userId && screener._id === userId) {
                recruiterList && recruiterList.forEach(User => {
                    if (item.recruiter === User._id) {
                        screenerId =screener.uniqueMatchId;
                        
                        rows.push({
                            id: item._id,
                            reviewId: item.reviewId,
                            //recruiter: User.name,
                            // email: User.email,
                            screener: screener.name + ' - ' + screener.uniqueMatchId,
                            numberRating: item.numberRating,
                            comments: item.comments,
                        })
                    }
                })
            }
            // setUniqueId(screener.uniqueMatchId);
        })
    })

    const classes = useStyles();

    return (
        <>
        
            <br /> <br /> <br /> <br /> <br /> 
            
            {/* <Typography variant="h6" style={{ alignItems: "center" }} >Screener Reviews</Typography> */}
            {/* <Container>
                

                <Paper component={Box} width={1} height={700}> */}
                    {/* <br /> <br /> */}
                    <div className="Container">
                        <div>
                            <Typography variant="h6" style={{ alignItems: "center" }} >Screener Reviews</Typography>
                           
                        </div>
                        

                    </div>
                    <br/>
                  
                    <div className="myList">
                        

                        <DataGrid
                            rows={rows}
                            columns={columns}
                            pageSize={10}
                            disableSelectionOnClick
                            className="tableList"
                            autoHeight
                        />
                        
                    </div>
                    <Dialog
                        open={open}
                        fullWidth
                        maxWidth='lg'
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        >
                        <DialogContent>
                        <Typography variant="h6" style={{textAlign: "center" }}>Review Information<Divider style={{ width: "25%", margin: "auto" }} /></Typography>
                    {/* <Link to="/screener-review-list">Back</Link> */}
                    <form className={classes.form} >
                        <Grid container spacing={24} style={{ padding: "2em" }}>
                            <Grid item xs={12} sm={5}>
                                <div>
                                    <label>Review Id</label>
                                    <TextField
                                        disabled
                                        id="standard-disabled"
                                        value={info.id}
                                        variant="standard"
                                        fullWidth
                                    />

                                </div>

                            </Grid>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            {/* <Grid item xs={12} sm={5}>
                                <div>
                                    <label>Recruiter Name</label>
                                    <TextField
                                        disabled
                                        id="standard-disabled"
                                        value={rname}
                                        variant="standard"
                                        fullWidth
                                    />

                                </div>

                            </Grid>
                            <Grid item xs={12} sm={5}>
                                <div>
                                    <label>Recruiter Email</label>
                                    <TextField
                                        disabled
                                        id="standard-disabled"
                                        value={email}
                                        variant="standard"
                                        fullWidth
                                    />
                                </div>


                            </Grid>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
                            <Grid item xs={12} sm={5}>
                                <div>
                                    <label>Screener Name</label>
                                    <TextField
                                        disabled
                                        id="standard-disabled"
                                        value={`${info.sname} - ${screenerId}`}
                                        variant="standard"
                                        fullWidth
                                    />
                                </div>
                            </Grid>

                            <Grid item xs={12} sm={5}>
                                <div>
                                    <label>Screener Id</label>
                                    <TextField
                                        disabled
                                        id="standard-disabled"
                                        value={info.sid}
                                        variant="standard"
                                        fullWidth
                                    />
                                </div>
                            </Grid>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <Grid item xs={12} sm={5}>
                                <div>
                                    <label>Comment</label>
                                    <div>
                                        <TextareaAutosize
                                            disabled
                                            maxRows={5}
                                            value={info.comments}
                                            variant="standard"
                                            style={{ width: "100%" }}
                                        // style={{ width: "auto" }}
                                        />
                                    </div>
                                </div>


                            </Grid>

                            <Grid item xs={12} sm={5}>
                                <div>
                                    <label>Rating</label>
                                    <TextField
                                        disabled
                                        id="standard-disabled"
                                        value={info.numberRating}
                                        variant="standard"
                                        fullWidth
                                    />
                                </div>

                            </Grid>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <br />

                        </Grid>
                    </form>
                        </DialogContent>
                        <DialogActions>
                            <Button
                            onClick={handleClose}
                            style={{ backgroundColor: "#D73B30", color: "white" }}
                            >
                            Back
                            </Button>
                            {/* <Link
                            to="/review-list"
                            style={{ color: "white", textDecoration: "none" }}
                            >
                            <Button
                                autoFocus
                                style={{
                                backgroundColor: "#75A478",
                                color: "white",
                                margin: "6px",
                                }}
                            >
                                Confirm
                            </Button>
                            </Link> */}
                        </DialogActions>
                    </Dialog>
                {/* </Paper>
                
            </Container> */}
            
            {/* <br /> */}
            
        </>
    )
}

ScreenerReviewList.propTypes = {
    auth: PropTypes.object.isRequired,
};

const msp = (state) => ({
    auth: state.auth,
});

export default (connect(msp)(ScreenerReviewList));