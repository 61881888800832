const defaultDob = new Date();

const isValidDate = (date) => {
    return date && Object.prototype.toString.call(date) === "[object Date]" && !isNaN(date);
}
export const checkContactFields = (fields, userRole) => {
    const maxPoints = userRole ? 20 : 25;
    const indivPoints = userRole ? 4 : 5;
    const { dob, address } = fields;
    let percentageEmpty;
    // console.log(fields);

    // const numberOfAdressFields = address.keys().length - 1;
    let emptyAdressFields = Object.entries(address).filter(([addressFieldKey, addressFieldValue]) => {
        if (addressFieldKey === 'address_line2') {
            return false;
        }
        return !(!!addressFieldValue.trim() || 0 !== addressFieldValue.trim().length);
    }).length;


    // const numberOfContactFields = fields.keys().length - 2;
    let emptyContactFields = Object.values(fields).filter(contactField => {
        if (typeof contactField === "object" || isValidDate(contactField)) {
            return false;
        }
        return !(!!contactField.trim() || 0 !== contactField.trim().length);
    }).length;

    // how many points are lost out of total (max)
    let contactCheckPoints = (emptyContactFields) * indivPoints;
    let dobCheckPoints = dob !== defaultDob && isValidDate(dob) ? 0 : indivPoints;
    let addressCheckPoints = emptyAdressFields;
    // console.log('date match: ' + dob !== defaultDob);
    // console.log(`The contact_Info section had ${emptyContactFields} empty fields excluding dob and address. points: ${contactCheckPoints}. the dob was ${dob !== defaultDob && isValidDate(dob) ? "changed" : "not changed"}. points: ${dobCheckPoints}., the address had ${emptyAdressFields} empty fields. points: ${emptyAdressFields}.`)

    percentageEmpty = contactCheckPoints + dobCheckPoints + addressCheckPoints;
    return (maxPoints - percentageEmpty) < 0 ? 0 : (maxPoints - percentageEmpty);
}


export const checkProfessionalFields = (fields, userRole) => {
    const maxPoints = userRole ? 20 : 25;

    let emptyFields = Object.values(fields).filter(field => {
        if (typeof field === "object" || isValidDate(field)) {
            return false;
        }
        return !(!!field.trim() || 0 !== field.trim().length);
    }).length;

    let indivPoints = maxPoints / emptyFields;
    return maxPoints - indivPoints;
}