import Divider from "@material-ui/core/Divider";
import React from "react";
import SimpleCards from "../../cards/SimpleCards";

export default function CandidateRowTwo(props) {
    return (
        <>
            <SimpleCards title="Candidate Social Feed">
                <h1 style={{ fontSize: "30px", marginLeft: "15px", textAlign: "center" }}>Keep updated with new information on the latest oppertunities and advancements and see what others have to say about their career and companies</h1>

                <Divider variant="middle" />

                <h2 style={{ fontSize: "20px", marginLeft: "15px" }}>Many things</h2>
            </SimpleCards>
        </>
    )
}