import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { receiveCandidateApplications } from "../../actions/applicationActions";
import { receiveAllAvailabilities } from "../../actions/availability";
import { receiveHomeComponentAction } from "../../actions/homepage";
import { receiveAllInterviews } from "../../actions/Interviews";
import { receiveAllPositions } from "../../actions/Positions";
import { receiveAllReviews } from "../../actions/reviewActions";
import { addUserInformation } from "../../actions/userActions";
import ApplicationsListContainer from "../applications/applicationsListContainer";
import AvailableScreener from "../availablity/availableScreener";
import CandidateHome from "../dashboard/candidateHome";
// import DashboardRecruiter from "../dashboard/dashboardRecruiter";
import DashboardScreener from "../dashboard/dashboardScreener";
import SalesforceInterviewsContainer from "../interview/salesforceInterviewsContainer";
import JobBoard from "../jobBoard/jobBoard";
import ProfilePage from "../profile/profilePage";
import SalesforceReviewsContainer from "../screenerReviews/salesforceReviewsContainer";
import CreateRecruiter from "../auth/CreateRecruiter";
import { recruiters,candidates,screeners,interviews} from "../../actions/userActions";
import CandidateList from "../recruiters/candidateList"
import ScreenerList from "../recruiters/screenerList"
import InterviewList from "../recruiters/interviewlist"
import ReviewList from "../recruiters/reviewList"
import CandidateInterviewList from "../candidateinterviews/candidateInterviewList";
import ScreenerInterviewList from "../screenerEvents/screenerInterviewList";
import ScreenerReviewList from "../screenerEvents/screenerReviewList";
import FeedbackList from "../feedback/feedbackList";
import ScreenerReview from "../admin/screenerReview";
class Homepage extends Component {
    
    constructor(props) {
        super(props);

        this.whichComponent = this.whichComponent.bind(this);
    }
    // receive all props that are needed for the dashboard
    // look into making this into a worker thread
    componentDidMount() {
        const { auth: { user } } = this.props;
        const userId = user.id;

        this.props.receiveAllAvailabilities(userId);
        this.props.addUserInformation(userId);

        if (user.role === 'admin') {
            //this.props.receiveAllInterviews(userId);
            this.props.receiveHomeComponentAction("Home");
            this.props.recruiters();
          

           // this.props.receiveAllReviews(userId);
        } else 
        if (user.role === 'screener') {
            this.props.receiveAllInterviews(userId);
            this.props.receiveHomeComponentAction("Dashboard");
            this.props.receiveAllReviews(userId);
        }else if(user.role === 'recruiter') {
            this.props.receiveHomeComponentAction("Home");
            this.props.screeners();
            this.props.candidates();
            this.props.interviews();
        } else {
            this.props.receiveHomeComponentAction("Home");
            this.props.receiveAllPositions();
            this.props.receiveCandidateApplications(userId);
        }
    }

    whichComponent() {
        const { homeTab } = this.props;
        switch (homeTab) {
            case "Dashboard":
                return <DashboardScreener />;
            case "Home":
                return <CandidateHome />;
            case "Interviews":
                // return <SalesforceInterviewsContainer />;
                return <ScreenerInterviewList/>;
            // case "Jobs":
            //     return <JobBoard />;
            case "Reviews":
                // return <SalesforceReviewsContainer />;
                return <ScreenerReviewList/>;
            // case "Applications":
            //     return <ApplicationsListContainer />
            case "Calendar":
                return <AvailableScreener />;
            case "Profile":
                return <ProfilePage />;
            case "Recruiters":
                return <CreateRecruiter/>;
            case "Candidates":
                return <CandidateList/>;
            case "Screeners":
                    return <ScreenerList/>;
            case "Interview":
                return <InterviewList/>;
            case "ScheduledInterviews":
                return <CandidateInterviewList/>;
            // case "Events":
            //     return <ScreenerInterviewList/>;
            case "Review":
                return <ReviewList/>;
            case "Feedback":
                return<FeedbackList/>;
            case "Screener Reviews":
                return <ScreenerReview/>; 
            default:
                break;
        }
    }

    render() {
        return <>{this.whichComponent()}</>;
    }
}

Homepage.propTypes = {
    auth: PropTypes.object.isRequired,
    homeTab: PropTypes.string.isRequired,
    receiveHomeComponentAction: PropTypes.func.isRequired,
    receiveAllPositions: PropTypes.func.isRequired,
    receiveAllInterviews: PropTypes.func.isRequired,
    receiveAllAvailabilities: PropTypes.func.isRequired,
    addUserInformation: PropTypes.func.isRequired,
    receiveAllReviews: PropTypes.func.isRequired,
    recruiters:PropTypes.func.isRequired,
    screeners:PropTypes.func.isRequired,
    candidates:PropTypes.func.isRequired,
    interviews:PropTypes.func.isRequired,
   // updateRecruiter:PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    homeTab: state.homeTab,
});

export default connect(mapStateToProps, {
    receiveHomeComponentAction,
    receiveAllPositions,
    receiveAllInterviews,
    receiveAllAvailabilities,
    addUserInformation,
    receiveAllReviews,
    receiveCandidateApplications,
    recruiters,
    screeners,
    candidates,
    interviews,
    //updateRecruiter
})(Homepage);