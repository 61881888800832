// import { CardHeader } from "@material-ui/core";
// import Card from "@material-ui/core/Card";
import React, { useEffect } from 'react';
// import ApplicationTable from "../tables/applications/applicationTable";
import ApplicationListView from "./applicationListView";

// import { Link } from "react-router-dom";


export default function ApplicationsList(props) {
    let { applications, getApplications } = props;
    const candidateId = props.auth.user.id;
    // const applications = [];

    // if (applications.length <= 0 && applications[0] !== "There are no applications") {
    //     props.getApplications(candidateId);
    // }

    useEffect(()=>{
        getApplications(candidateId);
    }, [getApplications, candidateId]);




    return (
        <div className="unfinPage-component">
            {/* <Card className={classes.card}>
                <CardHeader align="center" title="Applications" />
                <ApplicationTable applications={applications} />
            </Card> */}
            <ApplicationListView applications={applications}/>
        </div>
    );
}

