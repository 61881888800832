import React, { useState, useEffect, useRef } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import TablePagination from "@mui/material/TablePagination";
import axios from "axios";
import ViewIcon from "@mui/icons-material/RemoveRedEye";
import FrozenIcon from "@mui/icons-material/AcUnit";
import DeleteIcon from "@mui/icons-material/Delete";
import FireIcon from "@mui/icons-material/Whatshot";
import { IconButton } from "@mui/material";
// CSS import
import CompanyMenu from "../../userProfiles/CompanyMenu";
import FreezeModal from "../../modals/FreezeModal";
import "../../company admin/CompanyAdmin.css";
import FrozenMessageSnackbar from "../../snackBars/FrozenMessageSnackbar";

import { ViewEditRecruiterModal } from "../../modals/ViewEditRecruiterModal";
import TableLoader from "../../loaders/TableLoader";
import AddRecruiterModal from "../../modals/AddRecruiterModal";
import PageNotFound from "../../ErrorHandling/PageNotFound";
import Footer from "../../../Footer/Footer";

const columns = [
    {
        title: "Name",
        width: "28%",
    },
    {
        title: "Email",
        width: "29%",
    },
    {
        title: "Status",
        width: "21%",
    },
    {
        title: "Action",
        width: "22%",
    },
];

const MyRecruiterlist = (props) => {
    //Account Id and role
    const id = sessionStorage.getItem("id");
    const compId = sessionStorage.getItem("compId")
    const companyId = sessionStorage.getItem("companyId");
    const role = sessionStorage.getItem("role");
    const profileType = sessionStorage.getItem("profileType");
    //Recruiter Id and role
    const [recruiterId, setRecruiterId] = useState("");

    //Refs
    const inputRef = useRef(null);
    const [cursorPosition, setCursorPosition] = useState(0);

    //Navbar states
    const [open, setOpen] = useState();
    const [sidebarOpen, setsidebarOpen] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [interacted, setInteracted] = useState("");
    const [croppedImg, setCroppedImg] = useState("");
    const [profileImage, setProfileImage] = useState("");
    const [defaultAvatar, setDefaultAvatar] = useState("");

    //Loader
    const [tableLoading, setTableLoading] = useState(true);

    //Form States
    const [filter, setFilter] = useState("");
    const [userFilter, setUserFilter] = useState("users");

    //Edit recruiter form states
    const [selectedRecruiterData, setSelectedRecruiterData] = useState({});

    //Modal States
    const [openAddUserForm, setOpenAddUserForm] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [openEditRecruiterDetails, setOpenEditRecruiterDetails] =
        useState(false);

    //SnackBar states
    const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);

    //List States
    let profileAvatar = "https://res.cloudinary.com/df2qsnyox/image/upload/v1681133496/Default%20Profile%20Avatar/profile-avatar_ogxozq_ranghl.png";
    const [action, setAction] = useState("");
    const [recruiterList, setRecruiterList] = useState([]);
    const [debouncedTerm, setDebouncedTerm] = useState("");

    //pagination states
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [page, setPage] = React.useState(0);
    // moduleList access
    const [moduleAccess, setModuleAccess] = useState({})

    //store internaldb acces state of the company
    const [hasInternalDbAccess, setHasInternalDbAccess] = useState(false)

    //store recruiterlist access of the recruiter and users
    const [hasRecruitersAccess, setHasRecruitersAccess] = useState(true)

    //title
    useEffect(() => {
        document.title = "Screener | My Recruiters";
    }, []);

    useEffect(() => {

        axios.get(`/api/admin/userRole/side-list/${companyId}`)
            .then(res => {
                console.log(res.data[0].profileType)
                let modules = res.data[0].profileType.filter(item => item.roleName.toLowerCase() === profileType.toLowerCase())
                console.log(modules)
                modules = modules[0].modules.filter(item => item.title === "My Recruiters")
                console.log(modules)
                if (!modules[0].checked) setHasRecruitersAccess(false)
            })
            .catch(err => {
                console.log(err)
            })


        if (profileType.toLowerCase() == "recruiter") {
            const { data } = axios.get(`/api/recruiter/${id}`).then((res) => {
                const {
                    defaultAvatar,
                    profileImage,
                    croppedImage,
                    interacted,
                    firstName,
                } = res.data.user;
                setDefaultAvatar(defaultAvatar);
                setProfileImage(profileImage);
                setCroppedImg(croppedImage);
                setInteracted(interacted);
                setCompanyName(firstName);
            });
        } else {
            const { data } = axios.get(`/api/myUsers/${id}`).then((res) => {
                const {
                    defaultAvatar,
                    profileImage,
                    croppedImage,
                    interacted,
                    firstName,
                } = res.data.user;
                setDefaultAvatar(defaultAvatar);
                setProfileImage(profileImage);
                setCroppedImg(croppedImage);
                setInteracted(interacted);
                setCompanyName(firstName);
            });
        }

        const moduleList = axios.get(`/api/admin/userRole/side-list/${companyId}`)
            .then(res => {
                // getting profile module access
                let data = res.data[0].profileType.filter(item => item.roleName === profileType)
                data = data[0].modules.filter(item => item.title == "My Recruiters")
                setModuleAccess(data[0])
            }).catch(error => {
                console.log(error)
            })
    }, []);

    console.log(moduleAccess)

    //post text to backend
    const sendFilter = async () => {
        let { data } = await axios.get(
            `/api/recruiter/myfilteredRecruiters/${companyId}/?userFilter=${userFilter}&filter=${filter}`
        );
        setTableLoading(false);
        data = data.filter(item => item._id != id)
        const reverseData = data.reverse();
        setRecruiterList(reverseData);
    };

    useEffect(() => {
        setPage(0);
        sendFilter();
    }, [
        filter,
        userFilter,
        openDelete,
        openEditRecruiterDetails,
        openAddUserForm,
    ]);

    //Debouncing
    useEffect(() => {
        const timer = setTimeout(() => setFilter(debouncedTerm), 500);
        return () => clearTimeout(timer);
    }, [debouncedTerm]);

    //Main
    var drawerWidth = 200;
    const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
        ({ theme, open }) => ({
            flexGrow: 1,
            padding: theme.spacing(3),
            transition: theme.transitions.create("margin", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            marginLeft: `-${drawerWidth}px`,
            ...(open && {
                transition: theme.transitions.create("margin", {
                    easing: theme.transitions.easing.easeOut,
                    duration: theme.transitions.duration.enteringScreen,
                }),
                marginLeft: 0,
            }),
        })
    );

    //Add recruiter Dialog handler
    const handleOpenAddUser = () => {
        setOpenAddUserForm(true);
    };

    //List handler
    const filterHandler = (e) => {
        setDebouncedTerm(e.target.value);
        setCursorPosition(e.target.selectionStart);
    }

    const handleFocus = (e) => {
        e.target.setSelectionRange(cursorPosition, cursorPosition)
    }

    const freezeHandler = (id, action) => {
        if (action !== "Pending") {
            setAction(action);
            setOpenDelete(true);
            setRecruiterId(id);
        } else {
            setOpenErrorSnackbar(true);
        }
    };



    const handleOpenRecruiterDetails = (recruiterId) => {
        var arr = recruiterList.filter((item) => {
            return item._id === recruiterId;
        });
        setSelectedRecruiterData(arr[0]);
        setOpenEditRecruiterDetails(true);
    };


    //pagination handler
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    return (
        <>{hasRecruitersAccess ? <div>
            {/* Navbar Component */}
            < CompanyMenu
                open={open}
                setOpen={setOpen}
                sidebarOpen={sidebarOpen}
                setsidebarOpen={setsidebarOpen}
                companyName={companyName}
                croppedImg={croppedImg}
                profileImage={profileImage}
                interacted={interacted}
                defaultAvatar={defaultAvatar}
            />

            {/* Recruiter List Component */}
            <div style={{ marginLeft: `${sidebarOpen === "sidebar-open" ? "200px" : "0px"}` }}>
                <Box sx={{ flexGrow: 1, background: "#faf9f6" }}>
                    <Card id="main-edit" className="add-recruiter-form form-card">
                        <Grid
                            container
                            spacing={0}
                            direction="row"
                            justifyContent={"space-between"}
                        // className="add-recruiter-card-container"

                        >
                            <Grid
                                item
                                xs={5}
                                sm={4}
                                md={4}
                                lg={6}
                                className="container-heading-title admin-table"
                            >
                                <h1>Recruiters</h1>
                            </Grid>
                            <Grid
                                item
                                xs={6}
                                sm={7}
                                md={7}
                                lg={4}
                                className="container-heading-buttons add-recruiter-button"
                                style={{ width: "100%" }}
                            >
                                {moduleAccess.edit ? <div className="add-user-button-container" style={{ justifyContent: "flex-end" }}>
                                    <button className="add-recruiter" onClick={handleOpenAddUser}>
                                        Add Recruiter
                                    </button>
                                </div> : ""}
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            spacing={1}
                            direction="row"
                            style={{ paddingTop: "10px" }}
                        // className="profile-progress-bar"
                        >
                            <Grid
                                id="profile-completion-percentage"
                                item
                                xs={12}
                                md={6}
                                sx={{ textAlign: "center" }}
                                className="container-profile"
                            >
                                <form className="experience-edit-form" noValidate>
                                    <div class="add-recruiter-input-container">
                                        <div class="input-field">
                                            <input
                                                class="position"
                                                type="text"
                                                defaultValue={debouncedTerm}
                                                onChange={filterHandler}
                                                onFocus={handleFocus}
                                                placeholder="Search"
                                                ref={el => (inputRef.current = el)}
                                                autoFocus={inputRef.current === document.activeElement}
                                            />
                                        </div>
                                        <div className="date-dropdown-picker">
                                            <div class="input-field">
                                                <select
                                                    value={userFilter}
                                                    onChange={(e) => {
                                                        setUserFilter(e.target.value);
                                                    }}
                                                >
                                                    <option value="users" selected>
                                                        All Users
                                                    </option>
                                                    <option value="Pending">Pending</option>
                                                    <option value="Active">Active</option>
                                                    <option value="Frozen">Frozen</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </Grid>
                        </Grid>
                    </Card>

                    {/* Recruiter list */}
                    <Card
                        id="skills-edit"
                        className="add-recruiter-form-container"
                        style={{ boxShadow: "none", borderRadius: "2px" }}
                    >
                        <div className="recruiter-list">
                            <Grid
                                item
                                xs={12}
                                md={12}
                                lg={12}
                                className="recruiter-list-heading"
                            >
                                {columns.map((item, index) => {
                                    return (
                                        <h6
                                            style={{
                                                width: `${item.width}`,
                                                background: index % 2 ? "rgba(240, 97, 97, 37%)" : "rgba(240, 97, 97, 17%)"
                                            }}
                                        >
                                            {item.title}
                                        </h6>
                                    );
                                })}
                            </Grid>
                            {tableLoading ? (
                                <TableLoader page="recruiterList" />
                            ) : (
                                <div className="recruiter-details-list">
                                    {recruiterList.length > 0 ? (
                                        recruiterList
                                            .slice(
                                                page * rowsPerPage,
                                                page * rowsPerPage + rowsPerPage
                                            )
                                            .map((item, index) => {
                                                let nameLength =
                                                    item.firstName.length + item.lastName.length;
                                                return (
                                                    <>
                                                        <div
                                                            className={` recruiter-details ${index % 2 ? "" : "alternate-recruiterlist-bg"
                                                                }`}
                                                        >
                                                            <div className="recruiter-list-name">
                                                                <img
                                                                    style={{ objectFit: "cover" }}
                                                                    src={
                                                                        item.defaultAvatar
                                                                            ? profileAvatar
                                                                            : item.interacted
                                                                                ? item.croppedImage
                                                                                : item.profileImage
                                                                    }
                                                                    className="recruiter-list-profile-image"
                                                                />

                                                                {nameLength > 23 ? (
                                                                    <Tooltip
                                                                        title={`${item.firstName} ${item.lastName}`}
                                                                    >
                                                                        <p>
                                                                            {item.firstName} {item.lastName}
                                                                        </p>
                                                                    </Tooltip>
                                                                ) : (
                                                                    <p>
                                                                        {item.firstName} {item.lastName}
                                                                    </p>
                                                                )}
                                                            </div>

                                                            <div className="recruiter-list-email">
                                                                {item.recruiterEmail.length > 24 ? (
                                                                    <Tooltip title={`${item.recruiterEmail}`}>
                                                                        <p>{item.recruiterEmail}</p>
                                                                    </Tooltip>
                                                                ) : (
                                                                    <p>{item.recruiterEmail}</p>
                                                                )}
                                                            </div>
                                                            <div className="status-button">
                                                                <button className={` ${item.status}`}>
                                                                    {item.status}
                                                                </button>
                                                            </div>
                                                            <div className="action-buttons">
                                                                <div className="action-button-icon">
                                                                    <Tooltip title="View">
                                                                        <IconButton className="user-role-action-button" disabled={
                                                                            moduleAccess.edit ?
                                                                                false :
                                                                                true
                                                                        }>
                                                                            <ViewIcon
                                                                                style={{ color: moduleAccess.edit ? "#2c3e50" : "" }}
                                                                                fontSize="small"
                                                                                onClick={() => {
                                                                                    handleOpenRecruiterDetails(item._id);
                                                                                }}

                                                                            />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </div>
                                                                <div className="action-button-icon">
                                                                    {item.status !== "Frozen" ? (
                                                                        <Tooltip title="Freeze">
                                                                            <IconButton className="user-role-action-button" disabled={
                                                                                moduleAccess.edit ?
                                                                                    false :
                                                                                    true
                                                                            }>
                                                                                <FrozenIcon
                                                                                    style={{ color: moduleAccess.edit ? "#2c3e50" : "" }}
                                                                                    fontSize="small"
                                                                                    onClick={() =>
                                                                                        freezeHandler(item._id, item.status)
                                                                                    }
                                                                                />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    ) : (
                                                                        <Tooltip title="Unfreeze">
                                                                            <IconButton className="user-role-action-button" disabled={
                                                                                moduleAccess.edit ?
                                                                                    false :
                                                                                    true
                                                                            }>
                                                                                <FireIcon
                                                                                    style={{ color: moduleAccess.edit ? "#2c3e50" : "" }}
                                                                                    fontSize="small"
                                                                                    onClick={() =>
                                                                                        freezeHandler(item._id, item.status)
                                                                                    }
                                                                                />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    )}
                                                                </div>
                                                                <div className="action-button-icon">
                                                                    <Tooltip title="Delete">
                                                                        <IconButton className="user-role-action-button" disabled={
                                                                            moduleAccess.edit ?
                                                                                false :
                                                                                true
                                                                        }>
                                                                            <DeleteIcon
                                                                                style={{ color: moduleAccess.edit ? "#2c3e50" : "" }}
                                                                                fontSize="small"
                                                                                onClick={() =>
                                                                                    freezeHandler(item._id, "delete")
                                                                                }
                                                                            />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                );
                                            })
                                    ) : (
                                        <p>No Recruiters</p>
                                    )}
                                </div>
                            )}
                        </div>
                        <TablePagination
                            id="recruiter-list-pagination"
                            component="div"
                            rowsPerPageOptions={[10, 25, 50, 100]}
                            count={recruiterList.length}
                            page={page}
                            onPageChange={handleChangePage}
                            rowsPerPage={rowsPerPage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            variant="outlined"
                        />
                    </Card>
                </Box>
            </div>

            <AddRecruiterModal
                id={compId}
                role={role}
                companyId={companyId}
                hasInternalDbAccess={hasInternalDbAccess}
                openAddUserForm={openAddUserForm}
                setOpenAddUserForm={setOpenAddUserForm}
                addUserType="recruiter"
            />
            <FreezeModal
                openDelete={openDelete}
                action={action}
                setOpenDelete={setOpenDelete}
                id={recruiterId}
                role="recruiter"
            />
            <ViewEditRecruiterModal
                openEditRecruiterDetails={openEditRecruiterDetails}
                setOpenEditRecruiterDetails={setOpenEditRecruiterDetails}
                {...selectedRecruiterData}
            />
            <FrozenMessageSnackbar
                openErrorSnackbar={openErrorSnackbar}
                setOpenErrorSnackbar={setOpenErrorSnackbar}
                message="You cannot freeze a Pending account"
            />
            <Footer sidebarOpen={sidebarOpen} />
        </div > : <PageNotFound />}
        </>
    );
};

export default MyRecruiterlist;
