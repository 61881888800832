import axios from "axios";
import React from "react";

export const RECEIVE_USER_INFORMATION = "RECEIVE_USER_INFORMATION";
export const RECEIVE_USER_ERRORS = "RECEIVE_USER_ERRORS";

export const RECEIVE_ALL_RECRUITER = "RECEIVE_ALL_RECRUITER";
export const RECEIVE_ALL_CANDIDATE = "RECEIVE_ALL_CANDIDATE";
export const RECEIVE_ALL_SCREENER = "RECEIVE_ALL_SCREENER";
export const RECEIVE_ALL_INTERVIEW = "RECEIVE_ALL_INTERVIEW";

export const UPDATE_PROFILE_REQUEST = "UPDATE_PROFILE_REQUEST";
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
export const UPDATE_PROFILE_RESET = "UPDATE_PROFILE_RESET";
export const UPDATE_PROFILE_FAIL = "UPDATE_PROFILE_FAIL";

export const USER_DETAILS_REQUEST = "USER_DETAILS_REQUEST";
export const USER_DETAILS_SUCCESS = "USER_DETAILS_SUCCESS";
export const USER_DETAILS_FAIL = "USER_DETAILS_FAIL";


export const addUserInformation = (userId) => (dispatch) => {
    console.log('we are fetching user information after login');
    axios
        .get("/api/users/user/" + userId)
        .then((user) =>{
            console.log(user);
            dispatch({
                type: RECEIVE_USER_INFORMATION,
                payload: user,
            });
        }).catch((err) =>{
            dispatch({
                type: RECEIVE_USER_ERRORS,
                payload: err,
            });
        });
};

export const updateProfileInformation = (user, enqueueSnackbar) => (dispatch) => {
    axios
        .put("/api/users/update", user)
        .then((res) => {
            let regMessage = <span>you have succesfully updated {res.data.name}, You will now be redirected the Profile Page!</span>;
            enqueueSnackbar(regMessage, {
                variant: "success",
                autoHideDuration: 3000,
            });
            dispatch({
                type: RECEIVE_USER_INFORMATION,
                payload: res,
            });

        })
        .catch((err) => dispatch({
            type: RECEIVE_USER_ERRORS,
            payload: err,
        }));
}


export const recruiters = () => (dispatch) => {
   axios
        .get("/api/users/recruiter")
        .then((users) =>{
            console.log(users);
            dispatch({
                type: RECEIVE_ALL_RECRUITER,
                payload: users,
            });
        }).catch((err) =>{
            dispatch({
                type: RECEIVE_USER_ERRORS,
                payload: err,
            });
        });

} 

export const interviews = () => (dispatch) => {
    axios
         .get("/api/event/interviews")
         .then((interviews) =>{
             console.log(interviews);
             dispatch({
                 type: RECEIVE_ALL_INTERVIEW,
                 payload: interviews,
             });
         }).catch((err) =>{
             dispatch({
                 type: RECEIVE_USER_ERRORS,
                 payload: err,
             });
         });
 
 } 

export const candidates = () => (dispatch) => {
    axios
         .get("/api/users/candidate")
         .then((users) =>{
             console.log(users);
             dispatch({
                 type: RECEIVE_ALL_CANDIDATE,
                 payload: users,
             });
         }).catch((err) =>{
             dispatch({
                 type: RECEIVE_USER_ERRORS,
                 payload: err,
             });
         });
 
 } 

 export const screeners = () => (dispatch) => {
    axios
         .get("/api/users/screener")
         .then((users) =>{
             console.log(users);
             dispatch({
                 type: RECEIVE_ALL_SCREENER,
                 payload: users,
             });
         }).catch((err) =>{
             dispatch({
                 type: RECEIVE_USER_ERRORS,
                 payload: err,
             });
         });
 
 } 


// Update Single recruiter
export const updateRecruiter = (id, userData) => async(dispatch) => {
    
   console.log("id: "+id+", userData: "+JSON.stringify(userData));
    await axios
        .put("/api/users/" +id, userData)
        
        .then((user)=>{
            console.log(user);
            dispatch({ type: UPDATE_PROFILE_SUCCESS, payload: user });
        }).catch((error) =>{
            console.log(id);
            dispatch({
                type: UPDATE_PROFILE_FAIL,
                payload: error,
            });      
        });  
}







// Get recruiter Details
export const getRecruiterDetails = (id) => (dispatch) => {
  axios
    .get(`/api/users/user/${id}`)
    .then((res)=>{
        console.log(res);
        dispatch({
            type: RECEIVE_USER_INFORMATION,
            payload: res,
        });
    }).catch((err) => dispatch({
        type: RECEIVE_USER_ERRORS,
        payload: err,
}));  
}




  





