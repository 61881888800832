// import Button from '@material-ui/core/Button';
// import Divider from '@material-ui/core/Divider';
// import withStyles from '@material-ui/core/styles/withStyles';
// import { withSnackbar } from 'notistack';
// // import axios from "axios";
// import PropTypes from 'prop-types';
// import React, { Component } from 'react';
// import { connect } from 'react-redux';
// import { receiveNewApplication } from '../../actions/applicationActions';
// import { receiveAllPositions } from '../../actions/Positions';
import Button from "@material-ui/core/Button";
//import Divider from '@material-ui/core/Divider';
import withStyles from "@material-ui/core/styles/withStyles";
import { withSnackbar } from "notistack";
// import axios from "axios";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { receiveNewApplication } from "../../actions/applicationActions";
import { receiveAllPositions } from "../../actions/Positions";
import { Paper,Grid,Divider, Typography } from '@material-ui/core';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {  faBriefcase,faInfoCircle, faUniversity, faMoneyBill, faHistory, faInfo, faGraduationCap, faAddressCard, faSuitcase, faUsers,faSearch  } from "@fortawesome/free-solid-svg-icons";


// const styles = (theme) => ({
//   card: {
//     background: '#FFFFFF 0% 0% no-repeat padding-box',
//     border: '3px solid #A5D6A7',
//     borderRadius: '10px',
//     opacity: '1',
//   },
//   positionDetailBox: {
//     width: '95%',
//     padding: '1%',
//     textAlign: 'left',
//     border: '1px solid #c7bfbf',
//     display: 'flex',
//     flexDirection: 'column',
//     backgroundColor: '#fff',
//   },
//   selectedPositionDetailBox: {
//     width: '97%',
//     textAlign: 'center',
//     border: '2px solid #000000',
//     display: 'flex',
//     flexDirection: 'column',
//   },
//   positionDescriptionBox: {
//     padding: '1%',
//     boxShadow: '0px 0px 0px 3px rgba(97, 186,103,1)',
//     textAlign: 'center',
//     width: '50%',
//     backgroundColor: '#fff',
//   },
//   divider: {
//     marginRight: '1%',
//     width: '4px',
//     marginBottom: '25px',
//     backgroundColor: '#0b4d1a',
//   },
// });


const styles = (theme) => ({
  // card: {
  //     background: "#FFFFFF 0% 0% no-repeat padding-box",
  //     border: "3px solid #A5D6A7",
  //     borderRadius: "10px",
  //     opacity: "1",
  // },

  positionDetailBox: {
      width: "95%",
      padding: "1%",
      textAlign: "left",
      border: "1px solid #c7bfbf",
      display: "flex",
      flexDirection: "column",
      backgroundColor: "#fff"
  },
  selectedPositionDetailBox: {
      width: "97%",
      textAlign: "center",
      border: "2px solid #000000",
      display: "flex",
      flexDirection: "column",
  },
  positionDescriptionBox: {
      padding: "1%",
      //boxShadow: "0px 0px 0px 3px rgba(97, 186,103,1)",
      textAlign: "center",
      width: "50%",
      //  backgroundColor: "#fff"
      backgroundColor:'#DCDCDC'
  },
  divider: {
      marginRight: "1%",
      width: "4px",
      marginBottom: "25px",
      backgroundColor: "#0b4d1a"
  },
  // Grid:{
     
  //     background: "white",
  //     boxshadow: "inset 0px 0px 0px 1px  $border-light",
  //     //+ translate(0,0),
  //     transition: "transform 0.14s ease-in, text-shadow 0.1s ease-in",
  //     willchange: "transform",
  //     cursor: "pointer",
    
  //     },

  //    Paper:{
  //     background: "white",
  //     boxshadow: "inset 0px 0px 0px 1px  $border-light",
  //     //+ translate(0,0),
  //     transition: "transform 0.14s ease-in, text-shadow 0.1s ease-in",
  //     willchange: "transform",
  //     cursor: "pointer",
     

  //    },
});


class JobBoard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedId: '',
      selectedPosition: {},
      cantApply: false,
      search: '',
    };

    this.handleClickEvent = this.handleClickEvent.bind(this);
  }

  handleClickEvent = (idx) => (e) => {
    e.preventDefault();
    // console.log(idx);

    this.setSelectedPosition(idx);
  };

  setSelectedPosition = (positionId) => {
    const selectedPosition = this.props.positions.find((position) => position.Id === positionId);

    this.setState({
      selectedId: selectedPosition.Id,
      selectedPosition: selectedPosition,
    });
  };

  handleClickApply = (e) => {
    e.preventDefault();

    let body = { position: this.state.selectedId, user: this.props.user };

    let preNotifMessage = 'Please wait while we send your application';
    this.props.enqueueSnackbar(preNotifMessage, {
      variant: 'info',
      autoHideDuration: 1000,
    });

    this.props.sendApplication(body, this.props.enqueueSnackbar);
    // console.log(this.state.selectedId);
  };

  componentDidMount() {
    if (this.props.positions.length === 0) {
      this.props.getPositions();
    } else if (this.state.selectedId === '') {
      let unappliedPositions = this.filterAppliedPositions(this.props.positions);
      this.setSelectedPosition(unappliedPositions[0].Id);
    }
  }

  filterAppliedPositions(positions) {
    let applicationIdsArray = this.props.applications.map((application) => {
      return application.Related_Position__c;
    });

    let unappliedPositions = positions.filter((position) => !applicationIdsArray.includes(position.Id));

    return unappliedPositions;
    // let searchPositions = unappliedPositions.filter((position,search) => {
    //         if(!search){
    //             return unappliedPositions;
    //         }else{

    //             // const company = application.related_company_name__c.toLowerCase();
    //             const company = position.Company_Name__c.toString().toLowerCase();
    //             return company.includes(search.toString().toLowerCase()) ;

    //         }

    //    return searchPositions;
  }

  searchPositions(positions, search) {
    if (!search) {
      return positions;
    }
    return positions.filter((position) => {
      // const positionname = position.Name.toString.toLowerCase();
      const company = position.Company_Name__c.toString().toLowerCase();
      const positionname = position.Name.toString().toLowerCase();
      console.log(position.Name);
      console.log(company);
      return company.includes(search.toString().toLowerCase()) || positionname.includes(search.toLowerCase());
    });
  }

  onChange = (e) => {
    this.setState({ search: e.target.value });
  };

  render() {
    // let { positions, classes } = this.props;
    // const { selectedId } = this.state;
    // const { search } = this.state;

    // const positionView = [];

    // let filteredPositions = this.filterAppliedPositions(positions, search);
    // if (positions.length <= 0) {
    //   positionView.push('Sorry there do not seem to be any open positions at the moment, Please check back later.');
    // } else {
    //   // check for filters and then send positions to sorting method!!
    //   filteredPositions.forEach((position) => {
    //     positionView.push(
    //       <div
    //         key={position.Id}
    //         className={selectedId === position.Id ? classes.selectedPositionDetailBox : classes.positionDetailBox}
    //         onClick={this.handleClickEvent(position.Id)}
    //         datavalue={position.Id}
    //       >
    //         <h4 style={{ color: 'rgba(97, 186,103,1)' }}>{`${position.Name}`}</h4>
    //         <span>
    //           <strong>{`${position.Company_Name__c}`}</strong>
    //         </span>
    //         <span>{`${position.Age_of_Job__c}`} Old</span>
    //       </div>
    //     );
    //   });
    // }
    // // const filteredsearchpositions = positionView.filter((position ,search)=> {
    // //     return position.Name.toLowerCase().indexOf(search.toLowerCase() )!== -1
    // // })

    // let searchPositions = this.searchPositions(filteredPositions, search);
    // const searchView = [];

    // if (positions.length <= 0) {
    //   searchView.push('Sorry there do not seem to be any open positions at the moment,Please check back later.');
    // } else {
    //   searchPositions.forEach((position) => {
    //     searchView.push(
    //       <div
    //         key={position.Id}
    //         className={selectedId === position.Id ? classes.selectedPositionDetailBox : classes.positionDetailBox}
    //         onClick={this.handleClickEvent(position.Id)}
    //         datavalue={position.Id}
    //       >
    //         <h4 style={{ color: 'rgba(97, 186,103,1)' }}>{`${position.Name}`}</h4>
    //         <span>
    //           <strong>{`${position.Company_Name__c}`}</strong>
    //         </span>
    //         <span>{`${position.Age_of_Job__c}`} Old</span>
    //       </div>
    //     );
    //     // console.log(position.Name)
    //   });
    // }
    let { positions, classes } = this.props;
    const { selectedId } = this.state;
    const {search} = this.state;
    const paperStyle = { padding: 10, height: '100%',  display:'flex', flexDirection:'row'  }

    const positionView = [];

    
    let filteredPositions = this.filterAppliedPositions(positions,search);
    if (positions.length <= 0) {
        positionView.push('Sorry there do not seem to be any open positions at the moment, Please check back later.');
    } else {
        // check for filters and then send positions to sorting method!!
        filteredPositions.forEach(position => {
            positionView.push(
                // <div key={position.Id} className={selectedId === position.Id ? classes.selectedPositionDetailBox : classes.positionDetailBox} onClick={this.handleClickEvent(position.Id)} datavalue={position.Id}>
                        <div key={position.Id} onClick={this.handleClickEvent(position.Id)} datavalue={position.Id}>
                        
                        <Grid item xs={12} sm={12} className={classes.item}> 
                            <Paper elevation={10} style={paperStyle}> 
                          
                        {/* <h4 style={{color: "rgba(97, 186,103,1)"}} >{`${position.Name}`}</h4>
                        <br/><br/><span><strong>{`${position.Company_Name__c}`}</strong></span>
                        <br/><br/><span>{`${position.Age_of_Job__c}`} Old</span> */}
                         <Typography style={{color: "rgba(97, 186,103,1)"}} >{`${position.Name}`}<br/></Typography><br/>
                        <br></br><Typography   >{`${position.Company_Name__c}`}<br/></Typography><br/>
                        <br/><Typography> {`${position.Age_of_Job__c}`} Old</Typography>
                       
                        </Paper>
                        </Grid>
                </div>
            )
        });
    }

    // const filteredsearchpositions = positionView.filter((position ,search)=> {
    //     return position.Name.toLowerCase().indexOf(search.toLowerCase() )!== -1
    // })

    let searchPositions = this.searchPositions(filteredPositions,search);
    const searchView = [];

    if(positions.length<=0){
      
        searchView.push('Sorry there do not seem to be any open positions at the moment,Please check back later.');
        
    }else{
        
        searchPositions.forEach(position =>{
            searchView.push(
                // <div key={position.Id} className={selectedId === position.Id ? classes.selectedPositionDetailBox : classes.positionDetailBox} onClick={this.handleClickEvent(position.Id)} datavalue={position.Id}>
                        
                // <div  onClick={this.handleClickEvent(position.Id)} datavalue={position.Id}>
                //           <Grid item xs={12} sm={12}> 
                //           <Paper elevation={10} style={paperStyle}>
                //         {/* <h4 style={{color: "rgba(97, 186,103,1)"}} >{`${position.Name}`}</h4>
                //         <br/><br/><span><strong>{`${position.Company_Name__c}`}</strong></span>
                //         <br/><br/><span>{`${position.Age_of_Job__c}`} Old</span> */}

                //         <Typography style={{color: "rgba(97, 186,103,1)"}} >{`${position.Name}`}<br/></Typography>
                //         <br></br><Typography variant="subtitle2"
                //     className="flex-fill maxw-219">{`${position.Company_Name__c}`}<br/></Typography><br/>
                //         <br/><Typography>{`${position.Age_of_Job__c}`} Old</Typography>
                //         </Paper>
                //         </Grid>
                // </div>

            
                <>
                <br/>
                <div>
                <Grid item xs={12} sm={12} key={position.Id} onClick={this.handleClickEvent(position.Id)} datavalue={position.Id} className={classes.Grid}>
                    
                    <Paper elevation={10} style={paperStyle} className={classes.Paper}>

                    <Typography
                
                    className="align-self-left flex-fill mb-2" style= {{color:"black"}}
                    >
                       <b> {position.Name}</b>
                      
                <br/><Typography   variant="body2"
                    className="align-self-left flex-fill mb-2"
                    style= {{color:"gray"}}>
                       {`${position.Company_Name__c}`}
                    </Typography>
                   {/* <Typography   variant="body2"
                    className="align-self-left flex-fill mb-2"
                   > */}
                   <Typography 
                       variant="body2"
                       className="align-self-left flex-fill mb-2"><FontAwesomeIcon icon={faBriefcase}/> &nbsp;
                       {`${position.Years_of_Experience__c}`} Experience 
                        &nbsp;&nbsp;
                       {`${position.Number_of_Positions__c}`} Open Position
                       </Typography>

                       <Button size="small" variant="outlined" color="secondary" style={{position:"inherit"}} ><FontAwesomeIcon icon={faHistory} /> &nbsp;{`${position.Age_of_Job__c}`} OLD </Button>
                      
                    {/* </Typography> */}
                       
                    </Typography><br/>
                   <br/> 
                   <br/>
                    </Paper>
                </Grid>
                </div>
                </>
            )
           // console.log(position.Name)
        });
       
        
    }

    const maxScrollHeight = { height: '800px' };
    const BarStyling = {
      width: '25rem',
      background: 'white',
      //   border: 'none',
      height: '5px',
      cursor: 'pointer',
      color: ' black',
      padding: '5px 10px',
      float: 'right',
      padding: '20px',
      margin: '0px 0px 20px 10px',
      borderRadius: '30px',
      outline: 'none',
      border: '1px solid',
      boxShadow: '5px 10px #d9d9d9',
      marginTop: '70px'
    };

    return (
      <div className="d-flex flex-row align-items-center" style={{ padding: '30px' }}>
        <div className="d-flex flex-column w-50 overflow-auto" style={maxScrollHeight}>
          {/* <input
            style={BarStyling}
            type="text"
            key="random1"
            value={this.search}
            placeholder={'Search by Position or Company'}
            onChange={this.onChange}
            name="search"
          />
          <FontAwesomeIcon style={{ marginTop: '-50px', marginLeft: '425px' }} size="2x" icon={faSearch} /> */}

          {/* {filteredsearchpositions.map((positions,index) => */}

          <br />
          <input
            style={BarStyling}
            type="text"
            key="random1"
            value={this.search}
            placeholder={'Search by Position or Company'}
            onChange={this.onChange}
            name="search"
          />
          <FontAwesomeIcon style={{ marginTop: '-50px', marginLeft: '425px' }} size="2x" icon={faSearch} />
          {searchView} 
          {/* )}; */}
        </div>

        <Divider orientation="vertical" flexItem className={classes.divider}/>

                        {/* <div className={classes.positionDescriptionBox}> */}
                        <div className={classes.positionDescriptionBox}>
                            {/* <h4><strong>Position Name:</strong> {this.state.selectedPosition.Name}</h4>
                            <h4><strong>Company:</strong> {this.state.selectedPosition.Company_Name__c}</h4>
                            <br/>
                            <br/>
                            <span>Number of Open Positions: {this.state.selectedPosition.Number_of_Positions__c}</span>
                            <br/>
                            <span>Estimated Pay: {this.state.selectedPosition.Pay_Rate__c}</span>
                            <br/>
                            <Divider variant="middle" style={{ height: "3px", backgroundColor: "#0b4d1a" }}/>

                            <div>
                                {this.state.selectedPosition.Description__c}
                                <br/>
                                <br/>
                                <span>looking for: <strong>{this.state.selectedPosition.Years_of_Experience__c} of experience</strong></span>
                                <br/>
                                <span>Skills And Qualifications preferred:  <strong>{this.state.selectedPosition.Skills__c}</strong></span>
                                <br/>
                                <span>Candidate Work Authorization Types accepted:  <strong>{this.state.selectedPosition.Work_Authorization__c}</strong></span>
                            </div>
                            <br/>
                            <br/>
                            <br/>
                            <Button variant="contained" color="secondary" size="small" className={classes.button} onClick={this.handleClickApply}>
                                Apply to position
                            </Button> */}


                            <Grid item xs={12} sm={12}>
                                <Paper elevation={10} style={paperStyle}>
                                    <Typography className="align-self-left flex-fill mb-2" style= {{color:"black"}}> 
                                        <b>JOB DESCRIPTION &nbsp;<FontAwesomeIcon icon={faInfoCircle}/></b> 
                                        <br/><br/><Divider variant= "middle"/>
                                      <Typography   variant="body2"
                                            className="align-self-left flex-fill mb-2"
                                            style= {{color:"gray"}}>
                                           Position Name &nbsp; <FontAwesomeIcon icon={faBriefcase}/>
                                           <br/>{this.state.selectedPosition.Name}
                                      </Typography >

                                      <Typography variant="body2"
                                            className="align-self-left flex-fill mb-2"
                                            style= {{color:"gray"}}> 
                                            Company &nbsp;<FontAwesomeIcon icon={faUniversity}/>
                                            <br/> {this.state.selectedPosition.Company_Name__c}
                                      </Typography>

                                      <Typography variant="body2"
                                            className="align-self-left flex-fill mb-2"
                                            style= {{color:"gray"}}> 
                                            Number of Open Positions &nbsp;<FontAwesomeIcon icon={faUsers}/>
                                            <br/> {this.state.selectedPosition.Number_of_Positions__c}
                                      </Typography>

                                      <Typography variant="body2"
                                            className="align-self-left flex-fill mb-2"
                                            style= {{color:"gray"}}> 
                                            Estimated Pay &nbsp;<FontAwesomeIcon icon={faMoneyBill}/>
                                            <br/> {this.state.selectedPosition.Pay_Rate__c}
                                      </Typography>

                                      <Divider variant="middle" style={{ height: "3px" }}/>

                                      <Typography variant="body2"
                                            className="align-self-left flex-fill mb-2"
                                            style= {{color:"gray"}}> 
                                            Position Description &nbsp;<FontAwesomeIcon icon={faInfo}/>
                                            <br/>  {this.state.selectedPosition.Description__c}
                                      </Typography>

                                      <Typography variant="body2"
                                            className="align-self-left flex-fill mb-2"
                                            style= {{color:"gray"}}> 
                                            Experience Required &nbsp;<FontAwesomeIcon icon={faSuitcase}/>
                                            <br/>Looking for {this.state.selectedPosition.Years_of_Experience__c} of Experience
                                      </Typography>

                                      <Typography variant="body2"
                                            className="align-self-left flex-fill mb-2"
                                            style= {{color:"gray"}}> 
                                            Skills And Qualifications Preferred &nbsp;<FontAwesomeIcon icon={faGraduationCap}/>
                                            <br/>{this.state.selectedPosition.Skills__c}
                                      </Typography>

                                      <Typography variant="body2"
                                            className="align-self-left flex-fill mb-2"
                                            style= {{color:"gray"}}> 
                                            Candidate Work Authorization Types Accepted &nbsp;<FontAwesomeIcon icon={faAddressCard}/>
                                            <br/>{this.state.selectedPosition.Work_Authorization__c}
                                      </Typography>

                                <br/>
                                     
                            <Button variant="contained" color="secondary" size="small" className={classes.button} onClick={this.handleClickApply}>
                                Apply to position
                            </Button>

                             </Typography>
                                
                                </Paper>
                            </Grid>


                        </div>
                    </div>



            //     <br />
            //     <br />
            //     <Link to="" href="https://zstest-dev-ed.lightning.force.com/lightning/o/Position__c/list?filterName=00B4T000002mfmsUAA" label="Salesforce Positions">Take a look at the positions in salesforce</Link>

        );
    }
}

JobBoard.propTypes = {
    auth: PropTypes.object.isRequired,
    positions: PropTypes.array.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    applications: state.applications,
    positions: state.positions,
    user: state.user
});

const mapDispatchToProps = dispatch => ({
    getPositions: () => dispatch(receiveAllPositions()),
    sendApplication: (body, enqueueSnackbar) => dispatch(receiveNewApplication(body, enqueueSnackbar)),
})

export default withSnackbar(withStyles(styles)(connect(
    mapStateToProps,
    mapDispatchToProps
)(JobBoard)));